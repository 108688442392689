
import React, { useState, useEffect, useRef } from "react"
import Moment from "react-moment"
import classNames from "classnames"
import api from "../../services/api"
import App from "../../layout/App"

//PrimeReact
import { Avatar } from "primereact/avatar"
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { FilterMatchMode, FilterOperator } from "primereact/api"
import { Image } from "primereact/image"
import { InputMask } from "primereact/inputmask"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import { Password } from "primereact/password"
import { RadioButton } from "primereact/radiobutton"
import { Toast } from "primereact/toast"
import { Toolbar } from "primereact/toolbar"

//Components
import { LocalUpload } from "../../components/crm/whatsapp/LocalUpload"

//Interface
import { PermissionGroup } from "../../interfaces/PermissionGroupInterface"
import { UserInterface } from "../../interfaces/UserInterface"
import { Permission } from "../../interfaces/PermissionInterface"
import { Dropdown } from "primereact/dropdown"
import ReactTooltip from "react-tooltip"
import { useAuth } from "../../hooks/AuthContext"

//Interfaces
interface DataCreateUser {
    id: string
    name: string
    email: string
    phone: string
    status: boolean
    password: string
    password_confirmation: string
}

export const CrudUser: React.FC = () => {
    const toast: any = useRef(null)
    const dt: any = useRef(null)
    const dtPermissions: any = useRef(null)
    const dtPermissionsGroup: any = useRef(null)

    let [createValues, setCreateValues] = useState<DataCreateUser>({} as DataCreateUser)
    let [submitted, setSubmitted] = useState<boolean>(false)
    let [user, setUser] = useState<UserInterface>({} as UserInterface)
    let [userDialog, setUserDialog] = useState<boolean | undefined>(false)
    let [userEditDialog, setUserEditDialog] = useState<boolean | undefined>(false)
    let [users, setUsers] = useState<UserInterface[]>([])

    //Dialogs
    let [editSign, setEditSign] = useState(false)
    let [userPermissionDialog, setUserPermissionDialog] = useState<boolean>(false)

    //Filters
    let [auxUsersFiltered, setAuxUserFiltered] = useState<UserInterface[]>([])
    let [globalFilter, setGlobalFilter] = useState<string | null>(null)

    //Loading
    let [loadingCreate, setLoadingCreate] = useState<boolean>(false)
    let [loadingEdit, setLoadingEdit] = useState<boolean>(false)
    let [loadingGroup, setLoadingGroup] = useState<boolean>(true)
    let [loadingList, setLoadingList] = useState<boolean>(true)
    let [loadingPermition, setLoadingPermition] = useState<boolean>(true)
    let [loadingSign, setLoadignSign] = useState<boolean>(false)

    //Permissions
    let [globalFilterPermissions, setGlobalFilterPermissions] = useState<string | null>(null)
    let [globalFilterPermissionsGroup, setGlobalFilterPermissionsGroup] = useState<string | null>(null)
    let [permissions, setPermissions] = useState<Permission[]>([])
    let [permissionsGroup, setPermissionsGroup] = useState<PermissionGroup[]>([])
    let [userPermissionGroupDialog, setUserPermissionGroupDialog] = useState<boolean>(false)
    let [userSignDialog, setUserSignDialog] = useState<boolean>(false)
    let [signDialog, setSignDialog] = useState<boolean>(false)
    let [profilePictureDialog, setProfilePictureDialog] = useState<boolean>(false)

    let [listPhoneNumber, setListPhoneNumber] = useState([{ name: "Vendas +55 (16) 37124102", number: "551637124102" }])
    let [menuSelected, setMenuSelected] = useState<any>("")

    let { editUser } = useAuth()

    //Filters
    let [globalFilterValue, setGlobalFilterValue] = useState("")
    let [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        email: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        phone: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    })

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value
        let _filters = { ...filters }
        _filters["global"].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    let [globalFilterValueGroup, setGlobalFilterValueGroup] = useState("")

    let [filtersGroup, setFiltersGroup] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    })

    let onGlobalFilterChangeGroup = (e: any) => {
        const value = e.target.value
        let _filters = { ...filtersGroup }
        _filters["global"].value = value

        setFiltersGroup(_filters)
        setGlobalFilterValueGroup(value)
    }

    let [globalFilterValuePermission, setGlobalFilterValuePermission] = useState("")

    let [filtersPermission, setFiltersPermission] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        description: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    })

    let onGlobalFilterChangePermission = (e: any) => {
        const value = e.target.value
        let _filters = { ...filtersPermission }
        _filters["global"].value = value

        setFiltersPermission(_filters)
        setGlobalFilterValuePermission(value)
    }

    //List Users
    const allUsers = async () => {
        try {
            const header = {
                headers: {
                    route: "list_users",
                },
            }

            const response = await api.get("users", header)
            const userData = response.data

            setLoadingList(false)
            setUsers(userData)
            setAuxUserFiltered(userData)
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao listar!",
                    detail: error.response.data.message,
                    life: 3000,
                })
            } else {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao listar!",
                    detail: "Erro desconhecido ao obter a lista de usuários.",
                    life: 3000,
                })
            }
        }
    }

    const onSubmitCreateUser = (event: any) => {
        event.preventDefault()
        setSubmitted(false)
        setLoadingCreate(true)
        try {
            const { name, email, phone, password, password_confirmation } = createValues
            if (
                name === "" ||
                email === "" ||
                password === "" ||
                password_confirmation === "" ||
                !validateEmail(email) ||
                password !== password_confirmation ||
                password_confirmation !== password
            ) {
                setLoadingCreate(false)
                setSubmitted(true)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao cadastrar",
                    detail: "Por favor, verificar os dados preenchidos!",
                    life: 3000,
                })
            } else {
                if (phone !== null || phone !== "") {
                    let header = {
                        headers: {
                            route: "create_user",
                        },
                    }

                    api.post(
                        "users",
                        {
                            name,
                            email,
                            password,
                            password_confirmation,
                            phone: null,
                        },
                        header
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                setUsers([...users, res.data])
                                setLoadingCreate(false)
                                toast.current?.show({
                                    severity: "success",
                                    summary: "Sucesso",
                                    detail: "Usuário Criado",
                                    life: 3000,
                                })
                                hideDialog()
                            } else {
                                setLoadingCreate(false)
                                toast.current?.show({
                                    severity: "error",
                                    summary: "Erro ao cadastar!",
                                    detail: res.data.message,
                                    life: 3000,
                                })
                            }
                        })
                        .catch((error) => {
                            setLoadingCreate(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao cadastar!",
                                detail: error.response.data.message,
                                life: 3000,
                            })
                        })
                } else {
                    let header = {
                        headers: {
                            route: "create_user",
                        },
                    }

                    api.post(
                        "users",
                        {
                            name,
                            email,
                            password,
                            password_confirmation,
                            phone,
                        },
                        header
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                hideDialog()
                                setUsers([...users, res.data])
                                setLoadingCreate(false)
                                toast.current?.show({
                                    severity: "success",
                                    summary: "Sucesso",
                                    detail: "Usuário Criado",
                                    life: 3000,
                                })
                            } else {
                                setLoadingCreate(false)
                                toast.current?.show({
                                    severity: "error",
                                    summary: "Erro ao cadastar!",
                                    detail: res.data.message,
                                    life: 3000,
                                })
                            }
                        })
                        .catch((error) => {
                            setLoadingCreate(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao cadastar!",
                                detail: error.response.data.message,
                                life: 3000,
                            })
                        })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    // Update user.
    const onSubmitEditUser = (event: any) => {
        event.preventDefault()
        setSubmitted(false)
        setLoadingEdit(true)

        let whatsapp_phone_number = menuSelected.number

        try {
            const { id, name, email, status, phone } = user
            if (name === "" || email === "") {
                setSubmitted(true)
                setLoadingEdit(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao Editar",
                    detail: "Preencha todos os campos para continuar!",
                    life: 3000,
                })
            } else {
                if (!validateEmail(email)) {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao Editar",
                        detail: "Email invalido!",
                        life: 3000,
                    })
                } else {
                    let header = {
                        headers: {
                            route: "update_user",
                        },
                    }

                    api.put(
                        "users",
                        {
                            id,
                            name,
                            email,
                            status,
                            phone,
                            whatsapp_phone_number,
                        },
                        header
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                setUsers((prevState) => {
                                    const index = prevState.findIndex((x) => x.id === res.data.id)
                                    prevState[index] = res.data
                                    return [...prevState]
                                })

                                setAuxUserFiltered((prevState) => {
                                    const index = prevState.findIndex((x) => x.id === res.data.id)
                                    prevState[index] = res.data
                                    return [...prevState]
                                })

                                editUser(res.data)
                                hideDialog()

                                setLoadingEdit(false)
                                toast.current?.show({
                                    severity: "success",
                                    summary: "Sucesso na alteração",
                                    detail: "Usuário alterado com sucesso!",
                                    life: 3000,
                                })
                            } else {
                                setLoadingEdit(false)
                                toast.current?.show({
                                    severity: "error",
                                    summary: "Erro ao Editar",
                                    detail: res.data.message,
                                    life: 3000,
                                })
                            }
                        })
                        .catch((error) => {
                            setLoadingEdit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao Editar",
                                detail: error.response.data.message,
                                life: 3000,
                            })
                        })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const validateEmail = (email: string) => {
        var re = /\S+@\S+\.\S+/
        return re.test(email)
    }

    const onChangeCreateUser = (event: any) => {
        const { value, name } = event.target

        setCreateValues({
            ...createValues,
            [name]: value,
        })
    }

    const openNew = () => {
        setUser({} as UserInterface)
        setUserDialog(true)
    }

    const openNewSign = () => {
        setEditSign(true)
        setUserSignDialog(false)
    }

    const hideDialog = () => {
        setCreateValues({} as DataCreateUser)
        setSubmitted(false)
        setUserDialog(false)
        setUserEditDialog(false)
        setSignDialog(false)
        setProfilePictureDialog(false)
        setUserPermissionDialog(false)
        setUserPermissionGroupDialog(false)
        setUserSignDialog(false)
        setUser({} as UserInterface)
        setGlobalFilter(null)
        setGlobalFilterPermissions(null)
        setGlobalFilterPermissionsGroup(null)
        setEditSign(false)
        setMenuSelected("")
    }

    const editUserFuncion = (user: UserInterface) => {
        setUser({ ...user })
        setUserEditDialog(true)
    }

    ///DataTable Formater
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-user-plus" className="p-button" onClick={openNew} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button
                    data-tip
                    data-for="menuGroups"
                    icon="pi pi-user-edit"
                    className="p-button-rounded p-button p-mr-2"
                    onClick={() => editUserFuncion(rowData)}
                />
                <ReactTooltip className="toolTip" id="menuGroups" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Editar Usuário</p>
                </ReactTooltip>
                <Button
                    data-tip
                    data-for="menuPermission"
                    icon="pi pi-check-circle"
                    className="p-button-rounded p-button-warning p-mr-2"
                    onClick={() => actionPermissionUser(rowData)}
                />
                <ReactTooltip className="toolTip" id="menuPermission" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Editar Permissões</p>
                </ReactTooltip>
                <Button
                    data-tip
                    data-for="menugroups"
                    icon="pi pi-users"
                    className="p-button-rounded p-button-success p-mr-2"
                    onClick={() => actionPermissionGroup(rowData)}
                />
                <ReactTooltip className="toolTip" id="menugroups" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Editar Grupos</p>
                </ReactTooltip>
                {rowData.sign_url !== null && (
                    <Button
                        data-tip
                        data-for="assinatura"
                        icon="pi pi-images"
                        className="p-button-rounded p-button-help  p-mr-2"
                        onClick={() => actionUserSign(rowData)}
                    />
                )}

                {rowData.sign_url === null && (
                    <Button
                        data-tip
                        data-for="assinatura"
                        icon="pi pi-images"
                        className="p-button-rounded p-button-secondary  p-mr-2"
                        onClick={() => actionUserSignWithOut(rowData)}
                    />
                )}
                <ReactTooltip className="toolTip" id="assinatura" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Assinatura</p>
                </ReactTooltip>
            </div>
        )
    }

    const placeHolder = (rowData: UserInterface) => {
        if (rowData.whatsapp_phone_number === "551637124102") {
            return "Vendas  +55 (16) 37124102"
        } else if (rowData.whatsapp_phone_number === "551637124180") {
            return "Suprimentos +55 (16) 37124180"
        } else {
            return "Selecione um número"
        }
    }

    const actionPermissionUser = (user: UserInterface) => {
        setUser({ ...user })

        try {
            let header = {
                headers: {
                    route: "show_routes_user",
                },
            }

            api.get(`permission/routes/${user.id}`, header)
                .then((res) => {
                    setLoadingPermition(false)
                    let permissionsAll: Permission[] = []
                    res.data.forEach((p: any) => {
                        permissionsAll.push({
                            route_id: p.route.id,
                            user_id: user.id,
                            name: p.route.name,
                            description: p.route.description,
                            status: p.route.status,
                            have_permissions: p.havePermission,
                        })
                    })

                    setPermissions(permissionsAll)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro!!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }

        setUserPermissionDialog(true)
    }

    const actionPermissionGroup = async (userGroup: UserInterface) => {
        setUser({ ...userGroup })
        setUserPermissionGroupDialog(true)

        try {
            let header = {
                headers: {
                    route: "show_user_groups_with_groups",
                },
            }

            await api.get(`user_group/groups/${userGroup.id}`, header)
                .then((res) => {
                    setLoadingGroup(false)
                    let permissionsGroupAll: PermissionGroup[] = []
                    res.data.forEach((p: any) => {
                        permissionsGroupAll.push({
                            id: p.group.id,
                            name: p.group.name,
                            userFromGroup: p.userFromGroup,
                            user_id: userGroup.id,
                        })
                    })

                    setPermissionsGroup(permissionsGroupAll)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro!!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const actionUserSign = (userGroup: UserInterface) => {
        setUser({ ...userGroup })
        setUserSignDialog(true)
    }

    const actionUserSignWithOut = (userGroup: UserInterface) => {
        setUser({ ...userGroup })
        setUserSignDialog(false)
        setEditSign(true)
    }

    const onUploadSign = (files: any) => {
        const data = new FormData()
        data.append("sign", files[0].file)
        hideDialog()
        setLoadignSign(true)

        toast.current?.show({
            severity: "info",
            summary: "Enviando arquivo, aguarde...",
            life: 3000,
        })

        let header = {
            headers: {
                route: "update_user_sign",
            },
        }

        api.patch(`users/sign/${user.id}`, data, header)

            .then((res) => {
                if (res.status === 200) {
                    let _users = [...auxUsersFiltered]
                    let _user = res.data
                    let index = findIndexById(res.data.id)
                    _users[index] = _user

                    users = _users
                    auxUsersFiltered = _users

                    setUsers(_users)
                    setAuxUserFiltered(_users)
                    hideDialog()
                    setLoadignSign(false)
                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Arquivo enviado com sucesso",
                        life: 3000,
                    })
                } else {
                    setLoadignSign(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao enviar arquivo!",
                        detail: res.data.message,
                        life: 3000,
                    })
                }
            })

            .catch((error) => {
                setLoadignSign(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao enviar arquivo!",
                    detail: error.response.data.message,
                    life: 3000,
                })
            })
    }

    ////////////////////////////////////////////////////////////////////
    const updatePermissionUser = async (permissionUser: Permission) => {
        try {
            let header = {
                headers: {
                    route: "change_user_route",
                },
            }

            await api
                .post(
                    `permission`,
                    {
                        route_id: permissionUser.route_id,
                        user_id: permissionUser.user_id,
                    },
                    header
                )
                .then((res) => {
                    let _permissions = [...permissions]
                    let index = findIndexByIdPermission(permissionUser.route_id)
                    _permissions[index].have_permissions = !permissionUser.have_permissions

                    setPermissions(_permissions)

                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Permissão alterada com sucesso!",
                        life: 4000,
                    })
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro!!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const updatePermissionUserGroup = (permissionUserGroup: PermissionGroup) => {
        try {
            let header = {
                headers: {
                    route: "change_user_group",
                },
            }

            api.post(
                `user_group`,
                {
                    group_id: permissionUserGroup.id,
                    user_id: permissionUserGroup.user_id,
                },
                header
            )
                .then((res) => {
                    let _permissionsGroup = [...permissionsGroup]
                    let index = findIndexByIdPermissionGroup(permissionUserGroup.id)
                    _permissionsGroup[index].userFromGroup = !permissionUserGroup.userFromGroup

                    setPermissionsGroup(_permissionsGroup)

                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Grupo pertencente alterado com sucesso!",
                        life: 4000,
                    })
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro!!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const imageBodyTemplate = (rowData: any) => {
        return (
            <>
                <div
                    onClick={() => {
                        setUser(rowData)
                        setProfilePictureDialog(true)
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <Avatar icon={rowData.avatar_url === null ? "pi pi-user" : ""} image={rowData.avatar_url} className="p-mr-2" size="xlarge" shape="circle" />
                </div>
            </>
        )
    }

    const ativoDesativo = (rowData: any) => {
        const statusClass = rowData.status ? "instock" : "outofstock"
        return (
            <>
                <span className={`product-badge status-${statusClass}`}>{rowData.status ? "Ativo" : "Inativo"}</span>
            </>
        )
    }

    const signFormater = (rowData: any) => {
        let sign
        if (rowData.sign_url) {
            sign = (
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        setUser(rowData)
                        setSignDialog(true)
                    }}
                >
                    <Image src={rowData.sign_url} alt="Image" width="80" />
                </div>
            )
        } else {
            sign = "SEM ASSINATURA"
        }
        return <>{sign}</>
    }

    const ativoDesativoPermission = (rowData: any) => {
        return (
            <>
                <InputSwitch checked={rowData.have_permissions} onChange={() => updatePermissionUser(rowData)} />
            </>
        )
    }

    const ativoDesativoPermissionGroup = (rowData: any) => {
        return (
            <>
                <InputSwitch checked={rowData.userFromGroup} onChange={() => updatePermissionUserGroup(rowData)} />
            </>
        )
    }

    const dateFormatCreate = (rowData: any) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.created_at}</Moment>
            </>
        )
    }

    const dateFormatUpdate = (rowData: any) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.updated_at}</Moment>
            </>
        )
    }

    const onInputChange = (e: any, name: string) => {
        const val = (e.target && e.target.value) || ""
        let _user = { ...user }

        switch (name) {
            case "name":
                _user["name"] = val
                break

            case "email":
                _user["email"] = val
                break

            case "phone":
                _user["phone"] = val
                break

            default:
                break
        }

        setUser(_user)
    }

    const onStatusChange = (e: any) => {
        let _user = { ...user }
        _user["status"] = e.value
        setUser(_user)
    }

    const findIndexById = (id: string) => {
        let index = -1
        for (let i = 0; i < auxUsersFiltered.length; i++) {
            if (auxUsersFiltered[i].id === id) {
                index = i
                break
            }
        }

        return index
    }

    const findIndexByIdPermission = (id: string) => {
        let index = -1
        for (let i = 0; i < permissions.length; i++) {
            if (permissions[i].route_id === id) {
                index = i
                break
            }
        }

        return index
    }

    const findIndexByIdPermissionGroup = (id: string) => {
        let index = -1
        for (let i = 0; i < permissionsGroup.length; i++) {
            if (permissionsGroup[i].id === id) {
                index = i
                break
            }
        }

        return index
    }

    const telephoneConcatenate = (rowData: any) => {
        return rowData.phone === null ? <>-</> : rowData.phone
    }

    const whatsappConcatenate = (rowData: any) => {
        if (rowData.whatsapp_phone_number) {
            let aux = rowData.whatsapp_phone_number.slice(2)
            var formatNumber
            formatNumber = aux.length === 11 ? aux.match(/(\d{2})(\d{5})(\d{4})/) : aux.match(/(\d{2})(\d{4})(\d{4})/)
            var finalNumber = "(" + formatNumber[1] + ") " + formatNumber[2] + "-" + formatNumber[3]
        } else {
            finalNumber = "WhatsApp não vinculado"
        }

        return finalNumber
    }

    //Footers
    const userDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" loading={loadingCreate} className="p-button-success p-button-text" onClick={onSubmitCreateUser} />
        </>
    )

    const renderFooter = (name: any) => {
        return (
            <div>
                <Button label="Fechar" icon="pi pi-times" onClick={hideDialog} className="p-button-danger" />
                <Button label="Mudar Assinatura" icon="pi pi-check" onClick={openNewSign} loading={loadingSign} autoFocus />
            </div>
        )
    }

    const userEditDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" loading={loadingEdit} className="p-button-success p-button-text" onClick={onSubmitEditUser} />
        </>
    )

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Gerenciar usuários</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Pesquisar..." />
            </span>
        </div>
    )

    const headerUpdatePermission = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValuePermission} onChange={onGlobalFilterChangePermission} placeholder="Pesquisar..." />
            </span>
        </div>
    )

    const headerUpdatePermissionGroup = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValueGroup} onChange={onGlobalFilterChangeGroup} placeholder="Pesquisar..." />
            </span>
        </div>
    )

    useEffect(() => {
        allUsers()
    }, [])

    return (
        <App>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />

                        <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}></Toolbar>

                        <DataTable
                            ref={dt}
                            value={users}
                            dataKey="id"
                            paginator
                            stripedRows
                            loading={loadingList}
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            // className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} usuarios"
                            globalFilter={globalFilter}
                            filters={filters}
                            globalFilterFields={["name", "email", "phone"]}
                            emptyMessage="Não há Usuários"
                            header={header}
                            responsiveLayout="scroll"
                        >
                            <Column align="center" alignHeader="center" header="Avatar" body={imageBodyTemplate}></Column>

                            <Column align="center" alignHeader="center" field="name" sortField="name" header="Nome" sortable></Column>

                            <Column align="center" alignHeader="center" field="email" sortField="email" header="Email" sortable></Column>

                            <Column align="center" alignHeader="center" sortField="phone" body={telephoneConcatenate} header="Telefone" sortable></Column>

                            <Column
                                align="center"
                                alignHeader="center"
                                header="Data de Criação"
                                body={dateFormatCreate}
                                sortField="created_at"
                                sortable
                            ></Column>

                            <Column
                                align="center"
                                alignHeader="center"
                                header="Data Alteração"
                                body={dateFormatUpdate}
                                sortField="updated_at"
                                sortable
                            ></Column>
                            <Column
                                align="center"
                                alignHeader="center"
                                sortField="whatsapp_phone_number"
                                body={whatsappConcatenate}
                                header="WhatsApp Vinculado"
                                sortable
                            ></Column>
                            <Column align="center" alignHeader="center" body={ativoDesativo} header="Status" sortField="status" sortable></Column>
                            <Column align="center" alignHeader="center" body={signFormater} header="Assinatura" sortField="sign_url" sortable></Column>
                            <Column
                                align="center"
                                alignHeader="center"
                                body={actionBodyTemplate}
                                style={{
                                    width: "18rem",
                                }}
                            ></Column>
                        </DataTable>

                        <Dialog
                            visible={userDialog}
                            style={{ width: "450px" }}
                            header="Adicionar Usuário"
                            modal
                            draggable={false}
                            className="p-fluid"
                            footer={userDialogFooter}
                            onHide={hideDialog}
                        >
                            <form>
                                <div className="p-field">
                                    <label htmlFor="name">Nome</label>
                                    <InputText
                                        id="name"
                                        name="name"
                                        required
                                        autoFocus
                                        type="String"
                                        maxLength={45}
                                        className={
                                            "p-field p-text-capitalize " +
                                            classNames({
                                                "p-invalid": submitted && createValues.name === "",
                                            })
                                        }
                                        value={createValues.name || ""}
                                        style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                        onChange={onChangeCreateUser}
                                    />
                                </div>

                                <div className="p-field">
                                    <label htmlFor="email">Email</label>
                                    <InputText
                                        id="email"
                                        name="email"
                                        required
                                        type="String"
                                        maxLength={55}
                                        className={
                                            "p-field p-text-lowercase " +
                                            classNames({
                                                "p-invalid": submitted && createValues.email === "",
                                            })
                                        }
                                        value={createValues.email || ""}
                                        onChange={onChangeCreateUser}
                                    />
                                </div>

                                <div className="p-field">
                                    <label htmlFor="phone">Telefone</label>
                                    <InputMask
                                        id="phone"
                                        name="phone"
                                        mask="(99) 99999-9999"
                                        required
                                        type="String"
                                        maxLength={13}
                                        className={
                                            "p-field p-text-lowercase " +
                                            classNames({
                                                "p-invalid": submitted && createValues.phone === "",
                                            })
                                        }
                                        value={createValues.phone || ""}
                                        onChange={onChangeCreateUser}
                                    ></InputMask>
                                </div>

                                <div className="p-field">
                                    <label htmlFor="confirmPassword">Senha</label>
                                    <Password
                                        id="password"
                                        promptLabel="Digite sua senha"
                                        name="password"
                                        required
                                        feedback={true}
                                        weakLabel="Senha fraca"
                                        mediumLabel="Senha media"
                                        strongLabel="Senha forte"
                                        className={
                                            "p-field " +
                                            classNames({
                                                "p-invalid": submitted && createValues.password_confirmation === "",
                                            })
                                        }
                                        value={createValues.password || ""}
                                        onChange={onChangeCreateUser}
                                    />
                                </div>

                                <div className="p-field">
                                    <label htmlFor="confirmPassword">Confirmação da Senha</label>
                                    <Password
                                        weakLabel="Senha fraca"
                                        mediumLabel="Senha media"
                                        strongLabel="Senha forte"
                                        id="confirmPassword"
                                        name="password_confirmation"
                                        required
                                        promptLabel="Digite sua senha"
                                        feedback={false}
                                        className="p-field"
                                        value={createValues.password_confirmation}
                                        onChange={onChangeCreateUser}
                                    />
                                </div>
                            </form>
                        </Dialog>

                        <Dialog
                            visible={userEditDialog}
                            style={{ width: "450px" }}
                            header="EDITAR USUÁRIO"
                            modal
                            draggable={false}
                            className="p-fluid"
                            footer={userEditDialogFooter}
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <label htmlFor="name">NOME</label>

                                <InputText
                                    id="name"
                                    name="name"
                                    required
                                    autoFocus
                                    type="String"
                                    maxLength={35}
                                    onChange={(e) => onInputChange(e, "name")}
                                    value={user.name || ""}
                                    className="p-field"
                                    style={{ minWidth: "20rem" }}
                                />
                            </div>

                            <div className="p-field">
                                <label htmlFor="phone">TELEFONE</label>
                                <InputMask
                                    id="phone"
                                    name="phone"
                                    mask="(99) 99999-9999"
                                    value={user.phone || ""}
                                    required
                                    onChange={(e) => onInputChange(e, "phone")}
                                    className="p-field"
                                ></InputMask>
                            </div>

                            <div className="p-field">
                                <label htmlFor="email">EMAIL</label>
                                <InputText
                                    id="email"
                                    name="email"
                                    type="email"
                                    maxLength={50}
                                    onChange={(e) => onInputChange(e, "email")}
                                    value={user.email || ""}
                                    required
                                    className="p-field"
                                />
                            </div>
                            <div className="p-field">
                                <label htmlFor="email">WHATSAPP VINCULADO</label>
                                <Dropdown
                                    onChange={(e) => {
                                        setMenuSelected(e.value)
                                    }}
                                    value={menuSelected}
                                    options={listPhoneNumber}
                                    optionLabel="name"
                                    placeholder={placeHolder(user)}
                                />
                            </div>
                            <div className="p-field">
                                <label className="p-mb-3">STATUS</label>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field-radiobutton p-col-6">
                                        <RadioButton
                                            inputId="ativado"
                                            name="status"
                                            onChange={(e) => onStatusChange(e)}
                                            value={true}
                                            checked={user.status || undefined}
                                        />
                                        <label htmlFor="ativado">ATIVO</label>
                                    </div>
                                    <div className="p-field-radiobutton p-col-6">
                                        <RadioButton
                                            inputId="desativado"
                                            name="status"
                                            onChange={(e) => onStatusChange(e)}
                                            value={false}
                                            checked={!user.status || undefined}
                                        />
                                        <label htmlFor="desativado">INATIVO</label>
                                    </div>
                                </div>
                            </div>
                        </Dialog>

                        <Dialog
                            visible={userPermissionDialog}
                            style={{ width: "700px" }}
                            modal
                            draggable={false}
                            className="p-fluid"
                            onHide={hideDialog}
                            header={"Permissões - " + user.name}
                        >
                            <DataTable
                                stripedRows
                                ref={dtPermissions}
                                value={permissions}
                                dataKey="id"
                                loading={loadingPermition}
                                paginator
                                rows={4}
                                filters={filtersPermission}
                                globalFilterFields={["name", "description"]}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} de {last} Total {totalRecords} permissões"
                                globalFilter={globalFilterPermissions}
                                emptyMessage="Não há rotas."
                                header={headerUpdatePermission}
                            >
                                <Column align="center" alignHeader="center" sortField="name" field="name" header="Rotina" sortable></Column>

                                <Column align="center" alignHeader="center" field="description" header="Descrição" sortable></Column>

                                <Column
                                    align="center"
                                    alignHeader="center"
                                    header="Ativo / Inativo"
                                    sortable
                                    sortField="have_permissions"
                                    body={ativoDesativoPermission}
                                ></Column>
                            </DataTable>
                        </Dialog>
                        {/* //////////////////////////////////////////////////////////////////////////////////////////////////// */}

                        <Dialog
                            visible={userPermissionGroupDialog}
                            style={{ width: "700px" }}
                            modal
                            draggable={false}
                            className="p-fluid"
                            onHide={hideDialog}
                            header={`Grupos -  ${user.name}`}
                        >
                            <DataTable
                                ref={dtPermissionsGroup}
                                value={permissionsGroup}
                                dataKey="id"
                                stripedRows
                                paginator
                                loading={loadingGroup}
                                rows={4}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} de {last} Total {totalRecords} permissões"
                                globalFilter={globalFilterPermissionsGroup}
                                emptyMessage="Não há grupos."
                                filters={filtersGroup}
                                globalFilterFields={["name"]}
                                header={headerUpdatePermissionGroup}
                            >
                                <Column align="center" alignHeader="center" field="name" header="Grupos" sortable></Column>

                                <Column
                                    align="center"
                                    alignHeader="center"
                                    header="Ativo / Inativo"
                                    body={ativoDesativoPermissionGroup}
                                    sortable
                                    sortField="userFromGroup"
                                ></Column>
                            </DataTable>
                        </Dialog>

                        <Dialog
                            draggable={false}
                            visible={userSignDialog}
                            modal
                            className="p-fluid"
                            onHide={hideDialog}
                            header={`Assinatura -  ${user.name}`}
                            footer={renderFooter("displayModal")}
                        >
                            <Image src={user.sign_url} alt="Image Text" />
                        </Dialog>

                        <Dialog
                            visible={editSign}
                            style={{ width: "750px" }}
                            header={"Enviar assinatura de: " + user.name}
                            modal
                            draggable={false}
                            className="p-fluid"
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <LocalUpload onSubmit={onUploadSign} types={".png, .jpg, .jpeg"} size={80000000} />
                            </div>
                        </Dialog>

                        <Dialog
                            visible={signDialog}
                            style={{ width: "750px" }}
                            header={"Assinatura: " + user.name}
                            modal
                            draggable={false}
                            className="p-fluid"
                            dismissableMask
                            onHide={hideDialog}
                        >
                            <div style={{ textAlign: "center" }}>
                                <a href={user.sign_url} target="_blank">
                                    <Image src={user.sign_url} alt="Image" width="700" />
                                </a>
                            </div>
                        </Dialog>

                        <Dialog
                            visible={profilePictureDialog}
                            style={{ width: "400px" }}
                            header={"Foto: " + user.name}
                            modal
                            draggable={false}
                            className="p-fluid"
                            dismissableMask
                            onHide={hideDialog}
                        >
                            <div style={{ textAlign: "center" }}>
                                <a href={user.avatar_url} target="_blank">
                                    <Image src={user.avatar_url} alt="Image" width="300" />
                                </a>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </App>
    )
}
