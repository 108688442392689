import React, { useRef, useState, useEffect, useCallback } from "react"
import api from "../../services/api"
import App from "../../layout/App"
import Cropper from "react-easy-crop"
import Moment from "react-moment"
import { useHistory } from "react-router-dom"
import { useHotkeys } from "react-hotkeys-hook"
import { useParams } from "react-router-dom"

//PrimeReact component
import { Avatar } from "primereact/avatar"
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { Chip } from "primereact/chip"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { Image } from "primereact/image"
import { InputText } from "primereact/inputtext"
import { MultiSelect } from "primereact/multiselect"
import { ProgressSpinner } from "primereact/progressspinner"
import { Toast } from "primereact/toast"

//Interface
import { Company } from "../../components/crm/forms/Company"
import { CompanyInterface } from "../../interfaces/CompanyInterface"
import { FunnelInterface } from "../../interfaces/FunnelInterface"
import { IEmpresaInterface } from "../../interfaces/IEmpresaInterface"
import { OpportunityInterface } from "../../interfaces/OpportunityInterface"
import { SaveCompanyData } from "../../interfaces/SaveCompanyInterface"

//Components
import { CrmRecords } from "../../components/crm/CrmRecords"
import getCroppedImg from "../../components/cropper/cropperCut"
import { HotKeys } from "../../components/crm/HotKeys"
import { LocalUpload } from "../../components/crm/whatsapp/LocalUpload"

//CSS
import "../../components/cropper/cropper.css"
import "../../styles/ClientCompany.scss"
import ReactTooltip from "react-tooltip"
import { CompanyOpportunity } from "../../components/crm/opportunity/CompanyOpportunity"

export const ClientCompany = () => {
    let toast = useRef<Toast>(null)
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    let params: any = useParams()
    let { companyParam, informationParam } = params

    let [auxCompaniesFiltered, setAuxCompaniesFiltered] = useState<CompanyInterface[]>([])
    let [companies, setCompanies] = useState<CompanyInterface[]>([])
    let [companiesOpportunity, setCompaniesOpportunity] = useState<OpportunityInterface[]>([])
    let [companyClient, setCompanyClient] = useState<CompanyInterface>({} as CompanyInterface)
    let [funnel, setFunnel] = useState<FunnelInterface>({} as FunnelInterface)
    let [funnels, setFunnels] = useState<FunnelInterface[]>([])
    let [interest, setInterest] = useState<any[]>([])
    let [opportunities, setOpportunities] = useState<OpportunityInterface[]>([])
    let [selectedProduct1, setSelectedProduct1] = useState(null)

    let [accountable, setAccountable] = useState<any[]>([])
    let [city, setCity] = useState<any[]>([])
    let [selectedAccountable, setSelectedAccountable] = useState<any[]>([])
    let [selectedCity, setSelectedCity] = useState<any[]>([])
    let [selectedState, setSelectedState] = useState<any[]>([])
    let [states, setStates] = useState<any[]>([])

    let [saveCompanyData, setSaveCompanyData] = useState<SaveCompanyData>({} as SaveCompanyData)
    let [auxSaveCompanyData] = useState<SaveCompanyData>({} as SaveCompanyData)
    let [requestApiCompany, setRequestApiCompany] = useState<IEmpresaInterface>({} as IEmpresaInterface)
    let [existingCompany, setExistingCompany] = useState<CompanyInterface>({} as CompanyInterface)
    let [companyClientId, setCompanyClientId] = useState<CompanyInterface>({} as CompanyInterface)

    //files
    let [imageSrc, setImageSrc] = useState<string>()
    let [crop, setCrop] = useState({ x: 0, y: 0 })
    let [rotation, setRotation] = useState(0)
    let [zoom, setZoom] = useState(1)
    let [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    //Dialog
    let [companyOpportunityDialog, setCompanyOpportunityDialog] = useState(false)
    let [companyWithPhotoDialog, setCompanyWithPhotoDialog] = useState(false)
    let [editDialog, setEditDialog] = useState(false)
    let [fileEdit, setFileEdit] = useState(false)
    let [hotKeysDialog, setHotKeysDialog] = useState(false)
    let [uploadFileDialog, setUploadFileDialog] = useState(false)

    //Filtros
    let [advancedSearchFilter, setAdvancedSearchFilter] = useState("")
    let [dateFinal, setDateFinal] = useState<any>(new Date())
    let [dateInit, setDateInit] = useState<any>("")
    let [displayBasicMobile, setDisplayBasicMobile] = useState(false)
    let [selectedInterest, setSelectedInterest] = useState<any[]>([])

    //Loading
    let [loadingCleanFilters, setLoadingCleanFilters] = useState(false)
    let [loadingCreateCompany, setLoadingCreateCompany] = useState(false)
    let [loadingEdit, setLoadingEdit] = useState<boolean>(false)
    let [loadingList, setLoadingList] = useState<boolean>(true)
    let [loadingOppotunity, setLoadingOppotunity] = useState<boolean>(false)

    //HotKeys
    useHotkeys("shift+i", () => setHotKeysDialog(true))

    // progressSpinnerDialod
    let [progressSpinnerDialod, setProgressSpinnerDialod] = useState(false)

    let history = useHistory()

    const isEmptyObj = (obj: any) => !Object.keys(obj).length

    const filter = () => {
        if (
            dateInit !== null ||
            dateFinal !== null ||
            selectedState.length > 0 ||
            selectedCity.length > 0 ||
            selectedAccountable.length > 0 ||
            selectedInterest.length > 0 ||
            !(advancedSearchFilter === "")
        ) {
            var listCompaniesFiltered: any[] = []
            for (let atualLine of auxCompaniesFiltered) {
                var addLine = true

                if (dateFinal !== "" && dateInit !== "" && addLine) {
                    let newDateInitGetTime = new Date(dateInit).getTime()
                    let newDateFinalGetTime = new Date(dateFinal).getTime()
                    let newAtualLineGetTime = new Date(atualLine.created_at).getTime()

                    if ((newAtualLineGetTime >= newDateInitGetTime && newAtualLineGetTime <= newDateFinalGetTime) || dateInit === undefined) {
                    } else {
                        addLine = false
                    }
                }

                if (selectedState.length > 0 && addLine) {
                    const validateSearch = selectedState.findIndex((filter) => filter.name === atualLine.state)
                    if (validateSearch === -1) {
                        addLine = false
                    }
                }
                if (selectedCity.length > 0 && addLine) {
                    const validateSearch = selectedCity.findIndex((filter) => filter.name === atualLine.city)
                    if (validateSearch === -1) {
                        addLine = false
                    }
                }
                if (selectedAccountable.length > 0 && addLine) {
                    const validateSearch = selectedAccountable.findIndex((filter) => filter.code === atualLine.user.id)
                    if (validateSearch === -1) {
                        addLine = false
                    }
                }

                if (selectedInterest.length > 0 && selectedInterest !== undefined && selectedInterest !== null && addLine) {
                    if (!isEmptyObj(atualLine.interests)) {
                        let validate: any = []
                        atualLine.interests.map((interests) => {
                            const findInterest = selectedInterest.findIndex((filter) => filter.code === interests.id)
                            validate.push(findInterest)
                        })
                        const validateSearch = validate.findIndex((validation: any) => validation === 0 || validation === 1)
                        if (validateSearch === -1) {
                            addLine = false
                        }
                    }
                }

                if (!(advancedSearchFilter === "") && addLine) {
                    addLine = false
                    var word = advancedSearchFilter !== undefined ? advancedSearchFilter.toLowerCase().split(" ") : null
                    if (advancedSearchFilter === "") {
                        setAuxCompaniesFiltered(companies)
                    }
                    if (!atualLine.city) atualLine.city = ""
                    if (!atualLine.cnpj) atualLine.cnpj = ""
                    if (!atualLine.state) atualLine.state = ""
                    if (!atualLine.address) atualLine.address = ""
                    if (!atualLine.name) atualLine.name = ""
                    if (!atualLine.id) atualLine.id = ""
                    if (!atualLine.fantasy_name) atualLine.fantasy_name = ""
                    if (!atualLine.address) atualLine.address = ""
                    if (!atualLine.user.name) atualLine.user.name = ""
                    if (!atualLine.user.id) atualLine.user.id = ""
                    for (var oneWord in word) {
                        oneWord = oneWord.normalize("NFD").replace(/-/g, "")
                        if (
                            !atualLine.city.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.cnpj.includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.state.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.address.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.name.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.id.toLowerCase().replaceAll("-", "").includes(advancedSearchFilter.toLowerCase().replaceAll("-", "")) &&
                            !atualLine.fantasy_name.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.address.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.user.name.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.user.id.toLowerCase().includes(advancedSearchFilter.toLowerCase())
                        ) {
                            addLine = false
                            break
                        } else {
                            addLine = true
                        }
                    }
                }

                if (addLine) {
                    listCompaniesFiltered.push(atualLine)
                }
            }

            setCompanies(listCompaniesFiltered)
        } else {
            setCompanies(auxCompaniesFiltered)
        }
    }

    const hideDialog = () => {
        setEditDialog(false)
        setHotKeysDialog(false)
        setSaveCompanyData({} as SaveCompanyData)
        setRequestApiCompany({} as IEmpresaInterface)
        setExistingCompany({} as CompanyInterface)
        setUploadFileDialog(false)
        setCompanyWithPhotoDialog(false)
        setFileEdit(false)
        setCompanyOpportunityDialog(false)
        setCompaniesOpportunity([])
    }

    const ClientCompanyDialogWithPhoto = (companyClient: CompanyInterface) => {
        setCompanyClient({ ...companyClient })
        setCompanyWithPhotoDialog(true)
    }

    //Requisitions
    const allCompanies = async () => {
        let header = {
            headers: {
                route: "list_companies",
            },
        }

        try {
            const res = await api.get("companies", header)
            setLoadingList(false)
            companies = res.data
            setCompanies(companies)
            auxCompaniesFiltered = res.data
            setAuxCompaniesFiltered(res.data)

            if (informationParam === "changeCompanyAvatar") {
                changeAvatarCompanyPerParameter(informationParam)
            }

            if (companyParam !== undefined) {
                advancedSearchFilter = companyParam
                setAdvancedSearchFilter(companyParam)
                filter()
            }
        } catch (error: any) {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar!",
                detail: error.response.data.message,
                life: 3000,
            })
        }
    }

    const allOpportunity = async (companyParam: CompanyInterface): Promise<void> => {
        opportunities = []
        setOpportunities([])

        try {
            const header = {
                headers: {
                    route: "list_company_opportunity",
                },
            }

            const response = await api.get(`companies/opportunities/${companyParam.id}`, header)
            const data = response.data
            opportunities = data
            setOpportunities(opportunities)
            setCompaniesOpportunity(opportunities)
        } catch (error: any) {
            console.error(error)
            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar!",
                detail: error.response?.data?.message || "Erro desconhecido",
                life: 3000,
            })
        }
    }

    const allFunnels = async () => {
        if (funnels.length === 0) {
            try {
                let header = {
                    headers: {
                        route: "list_funnels",
                    },
                }

                const { data: funnels } = await api.get("funnels", header)
                setFunnels(funnels)
                let funnel = funnels.find((item: any) => item.is_default)
                if (funnel) setFunnel(funnel)
            } catch (error: any) {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao listar!",
                    detail: error.response.data.message,
                    life: 3000,
                })
            }
        }
    }

    const updateCompany = (item: any) => {
        setCompanies([...companies, item])
    }

    const setEditCompanies = (company: CompanyInterface) => {
        setCompanyClient(company)
        setEditDialog(true)
    }

    const showCompanyOpportunities = (company: CompanyInterface) => {
        if (company) {
            allFunnels()
            allOpportunity(company)
            setCompanyClient(company)
            setCompanyOpportunityDialog(true)
        }

        setLoadingOppotunity(false)
    }

    const handleSaveCompanyData = (item: any) => {
        const { value, name } = item.target
        setSaveCompanyData({ ...saveCompanyData, [name]: value })
    }

    const handleRequestApiCompany = (item: IEmpresaInterface) => {
        if (!isEmptyObj(item) && item.simples !== null) {
            setRequestApiCompany({ ...requestApiCompany, [`razao_social`]: item.estabelecimento.nome_fantasia })
        }

        setRequestApiCompany(item)
    }

    const handleExistingCompany = (item: CompanyInterface) => {
        setExistingCompany(item)
    }

    const handleProcessCreateCompany = (auxSaveCompanyData: any) => {
        let header = {
            headers: {
                route: "create_company",
            },
        }
        setLoadingCreateCompany(true)
        api.put("companies", auxSaveCompanyData, header)
            .then((res) => {
                if (res.status === 200) {
                    let _contacts = [...auxCompaniesFiltered]
                    let _contact = res.data
                    let index = auxCompaniesFiltered.findIndex((item) => item.id === _contact.id)
                    _contacts[index] = _contact

                    companies = _contacts
                    auxCompaniesFiltered = _contacts

                    setCompanies(_contacts)
                    setAuxCompaniesFiltered(_contacts)
                    filter()
                    hideDialog()

                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso!",
                        detail: "Sucesso na alteração da empresa",
                        life: 3000,
                    })
                    setLoadingCreateCompany(false)
                    hideDialog()
                } else {
                    hideDialog()
                    setLoadingCreateCompany(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao editar empresa!",
                        detail: res.data.message,
                        life: 3000,
                    })
                }
            })
            .catch((error) => {
                hideDialog()
                setLoadingCreateCompany(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao editar empresa!",
                    detail: error.response.data.message,
                    life: 3000,
                })
            })
    }

    const handleCreatedCompanyComponent = (event: any) => {
        event.preventDefault()
        setLoadingCreateCompany(true)

        if (isEmptyObj(existingCompany)) {
            if (companyClient.cnpj === null && !isEmptyObj(requestApiCompany)) {
                let auxSaveCompanyDataRequest = {
                    interests: saveCompanyData.interests,
                    id: companyClient.id,
                    company: requestApiCompany,
                }
                hideDialog()
                handleProcessCreateCompany(auxSaveCompanyDataRequest)
            } else if (companyClient.name !== auxSaveCompanyData.name) {
                let auxSaveCompanyDataRequest = {
                    interests: saveCompanyData.interests,
                    id: companyClient.id,
                    company: {
                        name: saveCompanyData.name,
                        razao_social: saveCompanyData.name,
                        estabelecimento: {
                            cnpj: saveCompanyData.cnpj ? saveCompanyData.cnpj : null,
                            logradouro: saveCompanyData.neighborhood ? saveCompanyData.neighborhood : null,
                            numero: saveCompanyData.number ? saveCompanyData.number : null,
                            bairro: saveCompanyData.neighborhood ? saveCompanyData.neighborhood : null,
                            estado: {
                                id: null,
                                nome: saveCompanyData.state,
                                sigla: null,
                                ibge_id: null,
                            },
                            cidade: {
                                id: null,
                                nome: saveCompanyData.city,
                                ibge_id: null,
                                siafi_id: null,
                            },
                        },
                    },
                }
                hideDialog()
                handleProcessCreateCompany(auxSaveCompanyDataRequest)
            }
        }
        if (!isEmptyObj(existingCompany)) {
            if (companyClient.cnpj !== null) {
                if (existingCompany.interests !== companyClient.interests) {
                    let auxSaveCompanyData = {
                        interests: existingCompany.interests,
                        id: companyClient.id,
                        company: {
                            razao_social: companyClient.name,
                            estabelecimento: {
                                cnpj: companyClient.cnpj.replaceAll("-", "").replaceAll("/", "").replaceAll(".", ""),
                            },
                        },
                    }

                    hideDialog()
                    handleProcessCreateCompany(auxSaveCompanyData)
                }
            } else if (companyClient.cnpj === null && !isEmptyObj(requestApiCompany)) {
                let auxSaveCompanyData = {
                    interests: existingCompany.interests,
                    id: companyClient.id,
                    company: {
                        name: requestApiCompany.estabelecimento.nome_fantasia,
                        razao_social: requestApiCompany.razao_social,
                        estabelecimento: {
                            cnpj: requestApiCompany.estabelecimento.cnpj,
                        },
                    },
                }
                hideDialog()
                handleProcessCreateCompany(auxSaveCompanyData)
            } else {
                setLoadingCreateCompany(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro!",
                    detail: "Empresa já cadastrada",
                    life: 3000,
                })
            }
        }

        setLoadingCreateCompany(false)
    }

    //Files Upload
    const uploadPresentaiton = (company: CompanyInterface) => {
        setCompanyClient(company)
        setUploadFileDialog(true)
    }

    const uploadPresentaitonPicture = () => {
        setCompanyClient(companyClient)
        setCompanyWithPhotoDialog(false)
        setUploadFileDialog(true)
    }

    const onUploadFile = (files: any) => {
        setImageSrc(files[0].meta.previewUrl)
        setZoom(1)
        setRotation(-1)
        setFileEdit(true)
        setUploadFileDialog(false)
    }

    const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const onUploadFileEditaded = async () => {
        setLoadingEdit(true)
        try {
            const croppingImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation)

            let updatePictureCompany = {
                company_id: companyClient.id,
                base64_picture: croppingImage,
            }

            toast.current?.show({
                severity: "info",
                summary: "Enviando arquivo, aguarde...",
                life: 3000,
            })

            let header = {
                headers: {
                    route: "update_company_picture",
                },
            }

            api.patch(`companies/picture`, updatePictureCompany, header).then((res) => {
                if (res.status === 200) {
                    hideDialog()
                    setLoadingEdit(false)

                    // Pegando a empresa atualizada e atualizando a lista
                    setCompanies((prevState) => {
                        const index = prevState.findIndex((x) => x.id === companyClient.id)
                        prevState[index] = res.data
                        return [...prevState]
                    })

                    if (informationParam === "changeCompanyAvatar") {
                        history.push("/crm/empresa-cliente")
                        informationParam = ""
                        filter()
                    }

                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Arquivo enviado com sucesso",
                        life: 3000,
                    })
                } else {
                    setLoadingEdit(false)
                    setLoadingList(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao enviar arquivo!",
                        detail: res.data.message,
                        life: 3000,
                    })
                }
            })
        } catch (e) {
            setLoadingEdit(false)
            setLoadingList(false)
            toast.current?.show({
                severity: "error",
                summary: "Erro ao enviar arquivo!",
                detail: "Verifique o arquivo selecionado",
                life: 3000,
            })
        }
    }

    //Table formater
    const CNPJ = (rowData: CompanyInterface): string => {
        return rowData.cnpj ?? "-"
    }

    const Type = (rowData: CompanyInterface): string => {
        return rowData.type === "PF" ? "Pessoa Física" : "Pessoa Jurídica"
    }

    const dateFormat = (rowData: CompanyInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY HH:mm">{rowData.updated_at}</Moment>
            </>
        )
    }

    const MainActive = (rowData: any) => {
        return rowData.main_activity_description === null ? <>-</> : rowData.main_activity_description
    }

    const City = (rowData: any) => {
        return rowData.city === null ? <>-</> : rowData.city
    }

    const AdressFormatter = (rowData: any) => {
        return rowData?.address?.replace(/undefined/g, "") || "-"
    }

    const activeInactive = (rowData: any) => {
        const state = rowData.state_registration_status === true ? "instock" : "outofstock"
        const name = rowData.state_registration_status === true ? "Ativo" : "Inativo"
        return <span className={`product-badge status-${state}`}>{name}</span>
    }

    const concatenateInterests = (rowData: CompanyInterface) => {
        return rowData.interests?.length === 0
            ? "-"
            : rowData.interests?.map((interest) => <Chip style={{ margin: "5px" }} label={interest.name} key={interest.id} />)
    }

    const State = (rowData: any) => {
        return rowData.state === null ? <>-</> : rowData.state
    }

    const imageAvatarDatable = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Imagem</span>
                <Avatar
                    icon={rowData.picture_url === null ? "pi pi-user" : ""}
                    onClick={rowData.picture_url !== null ? () => ClientCompanyDialogWithPhoto(rowData) : () => uploadPresentaiton(rowData)}
                    image={rowData.picture_url}
                    className="p-mr-2"
                    size="xlarge"
                    shape="circle"
                />
            </>
        )
    }

    const onClickMobileDialog = (name: any, position: any) => {
        dialogFuncMapMobile[`${"displayBasic"}`](true)
    }

    const onHideDialogMobile = (name: any) => {
        dialogFuncMapMobile[`${"displayBasic"}`](false)
    }

    //Buttons
    const buttonsFromDataTable = (rowData: CompanyInterface) => {
        return (
            <div className="actions">
                <Button
                    data-tip
                    data-for="egoiButton"
                    icon="pi pi-user-edit"
                    className="p-button-rounded p-button"
                    onClick={() => {
                        hideDialog()
                        setCompanyClientId(rowData)
                        setEditCompanies(rowData)
                    }}
                />
                <ReactTooltip className="toolTip" id="egoiButton" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Editar Empresa</p>
                </ReactTooltip>
                &nbsp;&nbsp;
                <Button
                    data-tip
                    data-for="opportunityButton"
                    icon="pi pi-list"
                    className="p-button-rounded p-button-success"
                    onClick={() => {
                        hideDialog()
                        setCompanyClientId(rowData)
                        setLoadingOppotunity(true)
                        showCompanyOpportunities(rowData)
                    }}
                />
                <ReactTooltip className="toolTip" id="opportunityButton" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Oportunidades da Empresa</p>
                </ReactTooltip>
                &nbsp;&nbsp;
                {rowData.picture_url !== null && (
                    <Button
                        icon="pi pi-upload"
                        data-tip
                        data-for="pictureButton"
                        onClick={() => ClientCompanyDialogWithPhoto(rowData)}
                        className="p-button-rounded p-button-warning"
                    />
                )}
                {rowData.picture_url === null && (
                    <Button
                        data-tip
                        data-for="pictureButton"
                        icon="pi pi-upload"
                        onClick={() => uploadPresentaiton(rowData)}
                        className="p-button-rounded p-button-warning"
                    />
                )}
                <ReactTooltip className="toolTip" id="pictureButton" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Enviar foto da Empresa</p>
                </ReactTooltip>
            </div>
        )
    }

    //Footer
    const changePictureFooter = (rowData: any) => {
        return (
            <div>
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    onClick={() => {
                        hideDialog()
                    }}
                    className="p-button-danger p-button-text"
                />
                <Button
                    label="Alterar Foto"
                    icon="pi pi-check"
                    onClick={() => uploadPresentaitonPicture()}
                    autoFocus
                    className="p-button-success p-button-text"
                />
            </div>
        )
    }

    const presentationDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingEdit} onClick={onUploadFileEditaded} />
        </>
    )

    const renderFooter = (name: any) => {
        return (
            <div>
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    onClick={() => {
                        onHideDialogMobile(name)
                        onLoadingCleanFilters()
                    }}
                    className="p-button-danger p-button-text"
                />
                <Button label="Aplicar" icon="pi pi-check" onClick={() => onHideDialogMobile(name)} autoFocus className="p-button-success p-button-text" />
            </div>
        )
    }

    const header = (
        <div>
            {/* Desktop */}
            <div className="desktop-opportunity-filter-menu">
                <div className="p-d-flex p-jc-between">
                    <h5 className="">Empresa / Cliente </h5>
                    <div className="p-d-flex p-jc-end">
                        <span className="p-input-icon-left p-mr-2">
                            <i className="pi pi-search" />
                            <InputText
                                className="searchFilter p-d-flex p-jc-end"
                                placeholder="PESQUISAR..."
                                value={advancedSearchFilter}
                                onKeyUp={() => (advancedSearchFilter === "" ? filter() : "" || advancedSearchFilter !== "" ? filter() : "")}
                                onChange={(e) => {
                                    setAdvancedSearchFilter(e.target.value)
                                    filter()
                                }}
                            />
                        </span>
                    </div>
                </div>
            </div>
            {/* Mobile */}
            <div className="mobile-opportunity-filter-menu">
                <div className="table-header">
                    <h5 className="">Empresa / Cliente </h5>
                    <div className="p-d-flex p-jc-between">
                        <div>
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" />
                                <InputText
                                    className="searchFilter"
                                    placeholder="PESQUISAR..."
                                    value={advancedSearchFilter}
                                    onKeyUp={() => (advancedSearchFilter === "" ? filter() : "" || advancedSearchFilter !== "" ? filter() : "")}
                                    onChange={(e) => {
                                        setAdvancedSearchFilter(e.target.value)
                                        filter()
                                    }}
                                />
                            </span>
                        </div>
                        <i
                            className="iconForMobileFilter pi pi-filter"
                            style={{ fontSize: "1.5em" }}
                            onClick={() => onClickMobileDialog("displayBasic", "center")}
                        ></i>
                    </div>
                </div>
            </div>
        </div>
    )

    //Filters
    const dialogFuncMapMobile = {
        displayBasic: setDisplayBasicMobile,
    }

    const onLoadingCleanFilters = () => {
        selectedAccountable = []
        setSelectedAccountable(selectedAccountable)
        selectedInterest = []
        setSelectedInterest(selectedInterest)
        selectedAccountable = []
        setAccountable(selectedAccountable)

        advancedSearchFilter = ""
        setAdvancedSearchFilter(advancedSearchFilter)
        selectedState = []
        setSelectedState(selectedState)

        selectedCity = []
        setSelectedCity(selectedCity)

        dateInit = ""
        setDateInit(dateInit)

        dateFinal = new Date()
        setDateFinal(dateFinal)

        // Remova esta chamada extra da função filter()
        // filter()

        setLoadingCleanFilters(true)
        setTimeout(() => {
            setLoadingCleanFilters(false)

            // Chame a função filter() depois que o estado do filtro tiver sido atualizado
            filter()
        }, 1000)
    }

    const changeAvatarCompanyPerParameter = (param: any) => {
        uploadFileDialog = true
        setUploadFileDialog(true)

        companies.forEach((company) => {
            if (company.id === companyParam) {
                companyClient = company
                setCompanyClient(company)
                advancedSearchFilter = company.id
                setAdvancedSearchFilter(company.id)

                // setAuxCompaniesFiltered(company)
                filter()
            }
        })
    }

    useEffect(() => {
        allCompanies()
    }, [])

    useEffect(() => {
        if (advancedSearchFilter !== "") {
            filter()
        }
        const accountableList: any[] = []
        const interestFullList: any[] = []
        const interestList: any[] = []
        const cityList: any[] = []
        const statestList: any[] = []

        auxCompaniesFiltered.forEach((item, index) => {
            const findAccountable = accountableList.find((user) => user.name === item.user.name)
            const findStates = statestList.find((user) => user.name === item.state)
            const findCity = cityList.find((user) => user.name === item.city)

            //responsible filter
            if (!findAccountable) {
                accountableList.push({ name: item.user.name, code: item.user.id })
            }

            if (!findStates) {
                statestList.push({ name: item.state, code: item.state })
            }
            if (!findCity) {
                cityList.push({ name: item.city, code: item.city })
            }

            //interest filter
            if (item.interests !== null) {
                let findInterest
                item.interests?.forEach((interests) => {
                    interestFullList.push({ name: interests.name, code: interests.id })
                    findInterest = interestList.find((user) => user.name === interests.name)
                    if (!findInterest) {
                        interestList.push({ name: interests.name, code: interests.id })
                    }
                })
            }
        })
        setCity(cityList)
        setStates(statestList)
        setInterest(interestList)
        setAccountable(accountableList)
    }, [auxCompaniesFiltered])

    useEffect(() => {
        if (companyParam !== undefined) {
            setProgressSpinnerDialod(true)
            if (companies.length > 0) {
                companies.forEach((company) => {
                    if (company.name === companyParam) {
                        if (opportunities.length > 0) {
                            setProgressSpinnerDialod(false)
                            setCompanyClientId(company)
                            showCompanyOpportunities(company)
                            opportunities.forEach((status) => {
                                if (company.id === status.company_id) {
                                    companiesOpportunity.push(status)
                                }
                            })
                            setCompanyOpportunityDialog(true)
                            setCompaniesOpportunity(companiesOpportunity)
                        }
                    }
                })
            }
        }
    }, [opportunities])

    return (
        <App>
            <CrmRecords updateCompany={updateCompany} />
            <div className="p-grid crud-demo ">
                <div className="p-col-12 p-md-12 p-lg-12">
                    {/* Filters Method */}
                    <div className="desktop-opportunity-filter-menu">
                        <div className="card">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="p-d-flex p-jc-between">
                                    <div>
                                        <Calendar
                                            className="calendarx p-mr-2 p-mb-1"
                                            dateFormat="dd/mm/yy"
                                            id="icon"
                                            value={dateInit}
                                            locale="pt"
                                            style={{ width: "10rem" }}
                                            onMonthChange={() => ""}
                                            onChange={(e) => {
                                                dateInit = e.value
                                                setDateInit(e.value)
                                                filter()
                                            }}
                                            showIcon
                                            placeholder={"INÍCIO"}
                                        />
                                        <Calendar
                                            className="calendarx p-mr-2 p-mb-1"
                                            dateFormat="dd/mm/yy"
                                            id="icon"
                                            locale="pt"
                                            value={dateFinal}
                                            style={{ width: "10rem" }}
                                            onMonthChange={() => ""}
                                            onChange={(e) => {
                                                dateFinal = e.value
                                                setDateFinal(e.value)
                                                filter()
                                            }}
                                            showIcon
                                            placeholder={"FIM"}
                                        />
                                        <MultiSelect
                                            className="multiselectx p-mr-2 p-mb-1"
                                            value={selectedAccountable}
                                            options={accountable}
                                            disabled={!isEmptyObj(accountable) ? false : true}
                                            onChange={(e) => {
                                                selectedAccountable = e.value
                                                setSelectedAccountable(e.value)
                                                filter()
                                            }}
                                            filter={true}
                                            emptyFilterMessage={"Não há dados"}
                                            optionLabel="name"
                                            placeholder={!isEmptyObj(accountable) ? "RESPONSÁVEL" : "NÃO HÁ RESPONSÁVEL"}
                                        />

                                        <MultiSelect
                                            className="multiselectx p-mr-2 p-mb-1"
                                            value={selectedInterest}
                                            options={interest}
                                            disabled={!isEmptyObj(interest) ? false : true}
                                            onChange={(e) => {
                                                selectedInterest = e.value
                                                setSelectedInterest(e.value)
                                                filter()
                                            }}
                                            filter={true}
                                            emptyFilterMessage={"Não há dados"}
                                            optionLabel="name"
                                            placeholder={!isEmptyObj(accountable) ? "INTERESSE" : "NÃO HÁ INTERESSES"}
                                        />

                                        <MultiSelect
                                            className="multiselectx p-mr-2 p-mb-1"
                                            value={selectedCity}
                                            options={city}
                                            disabled={!isEmptyObj(states) ? false : true}
                                            onChange={(e) => {
                                                selectedCity = e.value
                                                setSelectedCity(e.value)
                                                filter()
                                            }}
                                            filter={true}
                                            emptyFilterMessage={"Não há dados"}
                                            optionLabel="name"
                                            placeholder={!isEmptyObj(accountable) ? "CIDADE" : "NÃO HÁ CIDADES"}
                                        />

                                        <MultiSelect
                                            className="multiselectx p-mr-2 p-mb-1"
                                            value={selectedState}
                                            options={states}
                                            disabled={!isEmptyObj(states) ? false : true}
                                            onChange={(e) => {
                                                selectedState = e.value
                                                setSelectedState(e.value)
                                                filter()
                                            }}
                                            filter={true}
                                            emptyFilterMessage={"Não há dados"}
                                            optionLabel="name"
                                            placeholder={!isEmptyObj(accountable) ? "ESTADO" : "NÃO HÁ ESTADOS"}
                                        />
                                    </div>

                                    <div className="">
                                        <Button
                                            className="p-button-rounded p-jc-end"
                                            label="LIMPAR FILTROS"
                                            icon="pi pi-times"
                                            style={{ width: "13rem" }}
                                            loading={loadingCleanFilters}
                                            onClick={onLoadingCleanFilters}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />

                    {/* Data Table */}
                    <div className="card">
                        <Toast ref={toast} />

                        <DataTable
                            style={{ paddingBottom: "70px" }}
                            stripedRows
                            value={companies}
                            header={header}
                            loading={loadingList}
                            selectionMode="checkbox"
                            dataKey="id"
                            selection={selectedProduct1}
                            onSelectionChange={(e) => setSelectedProduct1(e.value)}
                            rows={10}
                            rowsPerPageOptions={[10, 15, 20]}
                            // className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} Empresas"
                            paginator
                            emptyMessage="Não há Empresas/Clientes"
                            responsiveLayout="scroll"
                        >
                            <Column body={imageAvatarDatable} header="FOTO" align="center" alignHeader="center" />
                            <Column field="name" header="Empresas/Clientes" align="center" alignHeader="center" sortable />
                            <Column body={CNPJ} sortField="cnpj" header="CNPJ" sortable align="center" alignHeader="center" />
                            <Column body={Type} sortField="type" header="TIPO" sortable align="center" alignHeader="center" />

                            <Column
                                body={MainActive}
                                sortField="main_activity_description"
                                header="Atividade Principal"
                                style={{ width: "15rem" }}
                                sortable
                                align="center"
                                alignHeader="center"
                            />

                            <Column body={AdressFormatter} sortField="address" header="Endereço" sortable align="center" alignHeader="center" />
                            <Column body={City} sortField="city" header="Cidade" sortable align="center" alignHeader="center" />
                            <Column body={State} sortField="state" header="Estado" sortable align="center" alignHeader="center" />
                            <Column header="Interesses" body={concatenateInterests} sortable sortField="interests" align="center" alignHeader="center" />
                            <Column
                                sortable
                                sortField="state_registration_status"
                                align="center"
                                alignHeader="center"
                                body={activeInactive}
                                header="SITUAÇÃO CADASTRAL"
                            />

                            <Column header="Último contato" body={dateFormat} sortable sortField="created_at" align="center" alignHeader="center" />
                            <Column align="center" alignHeader="center" style={{ width: "12rem" }} body={buttonsFromDataTable}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

            {/* Edit  Method */}
            {companyClient.cnpj === null && (
                <Dialog
                    visible={editDialog}
                    breakpoints={{ "1200px": "75vw", "640px": "100vw" }}
                    modal
                    className="p-fluid"
                    onHide={hideDialog}
                    header="Editar Empresa"
                    draggable={false}
                >
                    <form onSubmit={handleCreatedCompanyComponent}>
                        <Company
                            allCompanies={companies}
                            existingCompany={existingCompany}
                            saveCompanyData={saveCompanyData}
                            requestApiCompany={requestApiCompany}
                            setSaveCompanyData={handleSaveCompanyData}
                            setRequestApiCompany={handleRequestApiCompany}
                            setExistingCompany={handleExistingCompany}
                            type="update"
                            company={companyClientId}
                        />
                        <div className="p-dialog-footer">
                            <Button label="Cancelar" type="button" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                            <Button label="Salvar" type="submit" loading={loadingCreateCompany} icon="pi pi-check" className="p-button-success p-button-text" />
                        </div>
                    </form>
                </Dialog>
            )}
            {companyClient.cnpj !== null && (
                <Dialog
                    visible={editDialog}
                    breakpoints={{ "1200px": "75vw", "640px": "100vw" }}
                    modal
                    className="p-fluid"
                    onHide={hideDialog}
                    header="Editar Empresa"
                    draggable={false}
                >
                    <form onSubmit={handleCreatedCompanyComponent}>
                        <Company
                            allCompanies={companies}
                            existingCompany={existingCompany}
                            saveCompanyData={saveCompanyData}
                            requestApiCompany={requestApiCompany}
                            setSaveCompanyData={handleSaveCompanyData}
                            setRequestApiCompany={handleRequestApiCompany}
                            setExistingCompany={handleExistingCompany}
                            type="updateInterests"
                            company={companyClientId}
                        />

                        <div className="p-dialog-footer">
                            <Button label="Cancelar" type="button" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                            <Button label="Salvar" type="submit" loading={loadingCreateCompany} icon="pi pi-check" className="p-button-success p-button-text" />
                        </div>
                    </form>
                </Dialog>
            )}

            {/* Show the company picture */}
            <Dialog
                visible={companyWithPhotoDialog}
                modal
                style={{ width: "40rem", height: "40rem" }}
                draggable={false}
                className="p-fluid"
                onHide={hideDialog}
                header={`Foto -  ${companyClient.name}`}
                footer={changePictureFooter}
            >
                <div className="p-d-flex p-jc-center">
                    <Image src={companyClient.picture_url} width="350rem" height="350rem" alt="Image Text" />
                </div>
            </Dialog>

            {/* Upload Method */}
            <Dialog
                visible={uploadFileDialog}
                style={{ maxWidth: "1200px" }}
                draggable={false}
                header={"Enviar foto de " + companyClient.name}
                modal
                className="p-fluid"
                onHide={hideDialog}
            >
                <div className="p-field">
                    <LocalUpload onSubmit={onUploadFile} types={".png, .jpg, .jpeg"} size={80000000} />
                </div>
            </Dialog>

            {/* Edit image Method */}
            <Dialog
                visible={fileEdit}
                style={{ width: "750px", height: "650px" }}
                modal
                footer={presentationDialogFooter}
                className="p-fluid"
                onHide={hideDialog}
            >
                <div className="crop-container">
                    <Cropper
                        image={imageSrc}
                        crop={crop}
                        rotation={rotation}
                        zoom={zoom}
                        aspect={5 / 5}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        disableAutomaticStylesInjection={false}
                    />
                </div>
                <div className="controls">
                    <h5>
                        Zoom
                        <input
                            type="range"
                            value={zoom}
                            min={0}
                            max={7}
                            step={0.5}
                            aria-labelledby="Zoom"
                            onChange={(e: any) => {
                                setZoom(Number(e.target.value))
                                setRotation(1)
                            }}
                            className="zoom-range"
                        />
                    </h5>
                </div>
            </Dialog>

            {/* CompanyDataTable */}
            <Dialog visible={companyOpportunityDialog} modal className="p-fluid" onHide={hideDialog} draggable={false}>
                <CompanyOpportunity
                    companiesOpportunity={companiesOpportunity}
                    funnels={funnels}
                    loadingOppotunity={loadingOppotunity}
                    toast={toast}
                    opportunities={opportunities}
                />
            </Dialog>

            {/* Filtros Method */}
            <Dialog
                header="Filtros"
                footer={renderFooter("displayBasic")}
                visible={displayBasicMobile}
                style={{ width: "90%", height: "90%" }}
                onHide={() => onHideDialogMobile("displayBasic")}
            >
                <div className="p-col-12 p-md-12 p-lg-12">
                    <div className="p-d-flex p-flex-column p-jc-center">
                        <Calendar
                            className="p-mb-2"
                            dateFormat="dd/mm/yy"
                            id="icon"
                            locale="pt"
                            value={dateInit}
                            onMonthChange={() => ""}
                            onChange={(e) => {
                                dateInit = e.value
                                setDateInit(e.value)
                                filter()
                            }}
                            showIcon
                            placeholder={"INÍCIO"}
                        />
                        <Calendar
                            className="p-mb-2"
                            dateFormat="dd/mm/yy"
                            id="icon"
                            locale="pt"
                            value={dateFinal}
                            onMonthChange={() => ""}
                            onChange={(e) => {
                                dateFinal = e.value
                                setDateFinal(e.value)
                                filter()
                            }}
                            showIcon
                            placeholder={"FIM"}
                        />
                        <MultiSelect
                            className="p-mb-2"
                            value={selectedAccountable}
                            options={accountable}
                            disabled={!isEmptyObj(accountable) ? false : true}
                            onChange={(e) => {
                                selectedAccountable = e.value
                                setSelectedAccountable(e.value)
                                filter()
                            }}
                            filter={true}
                            optionLabel="name"
                            placeholder={!isEmptyObj(accountable) ? "RESPONSÁVEL" : "NÃO HÁ RESPONSÁVEL"}
                            emptyFilterMessage={"Não há dados"}
                        />

                        <MultiSelect
                            className="p-mb-2"
                            value={selectedInterest}
                            options={interest}
                            disabled={!isEmptyObj(interest) ? false : true}
                            onChange={(e) => {
                                selectedInterest = e.value
                                setSelectedInterest(e.value)
                                filter()
                            }}
                            filter={true}
                            optionLabel="name"
                            placeholder={!isEmptyObj(accountable) ? "INTERESSE" : "NÃO HÁ INTERESSES"}
                            emptyFilterMessage={"Não há dados"}
                        />
                        <InputText
                            className="p-mb-2"
                            placeholder="ENDEREÇO"
                            value={advancedSearchFilter}
                            onChange={(e) => {
                                setAdvancedSearchFilter(e.target.value)
                                filter()
                            }}
                        />

                        <Button
                            className="p-button-rounded p-jc-end"
                            label="LIMPAR FILTROS"
                            icon="pi pi-times"
                            loading={loadingCleanFilters}
                            onClick={onLoadingCleanFilters}
                        />
                    </div>
                </div>
            </Dialog>

            {/* HotKeys Dialog */}
            <Dialog
                visible={hotKeysDialog}
                style={{ width: "850px" }}
                modal
                className="p-fluid"
                onHide={hideDialog}
                header={`Atalhos - Empresa / Cliente`}
                draggable={false}
            >
                <HotKeys />
            </Dialog>

            {/* Loading Dialog */}
            {progressSpinnerDialod && (
                <div
                    className="p-dialog-mask p-dialog-center p-component-overlay p-component-overlay-enter p-dialog-visible p-dialog-resizable"
                    style={{ zIndex: "1101" }}
                >
                    <ProgressSpinner animationDuration="1s" />
                </div>
            )}
        </App>
    )
}
