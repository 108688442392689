import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import React, { useState } from "react"
import { Funnel } from "../../funnelStages/Funnel"
import { Avatar } from "primereact/avatar"
import { Rating } from "primereact/rating"
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import Moment from "react-moment"
import { FunnelStageInterface } from "../../../../interfaces/FunnelStageInterface"
import { FunnelInterface } from "../../../../interfaces/FunnelInterface"
import { OpportunityInterface } from "../../../../interfaces/OpportunityInterface"
import api from "../../../../services/api"

interface Props {
    opportunities: OpportunityInterface[]
    funnels: FunnelInterface[]
    toast: any
    loadingOppotunity: boolean
    companiesOpportunity: any
}

interface ValueChangeRating {
    value: string
    id: string
}

export const CompanyOpportunity = (props: Props) => {
    let [displayDialogConfirmChangeRating, setDisplayDialogConfirmChangeRating] = useState(false)
    let [newValueRating, setNewValueRating] = useState<any>(0)
    let [opportunity, setOpportunity] = useState<OpportunityInterface>({} as OpportunityInterface)

    const rating = (rowData: any) => {
        let findOpportunity = props.opportunities.findIndex((opportunity) => opportunity.id === rowData.id)

        return (
            <div className="centralizeFromDesktopRating">
                <Rating
                    value={props.opportunities[findOpportunity].qualification}
                    cancel={false}
                    tooltip={replaceQualification(rowData.qualification)?.toUpperCase()}
                    tooltipOptions={{
                        position: "bottom",
                    }}
                    onChange={(e: any) => {
                        if (rowData.qualification !== e.value) {
                            newValueRating = { value: e.value, id: rowData.id }
                            setNewValueRating(newValueRating)
                            setDisplayDialogConfirmChangeRating(true)
                            setOpportunity(rowData)
                        }
                    }}
                />
            </div>
        )
    }

    const imageBodyTemplate = (rowData: any) => {
        return (
            <>
                {" "}
                <div className="p-d-flex">
                    <div>
                        <Avatar image={rowData.user.avatar_url} className="p-mr-2" size="normal" shape="circle" />
                        <div className="p-text-nowrap p-text-truncate" style={{ width: "8rem" }}>
                            {rowData.user.name}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const cnpjOpportunityColumns = (rowData: any) => {
        let cnpj = rowData.company.cnpj
        if (cnpj !== null && cnpj !== undefined) {
            cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")
        }

        return <div>{cnpj !== null && cnpj !== undefined ? cnpj : "-"}</div>
    }

    const lastInterationOfListining = (rowData: any) => {
        if (rowData.dateLastInteraction !== null) {
            const { dateLastInteraction, daysWithoutInteraction } = rowData

            if (daysWithoutInteraction !== null) {
                return (
                    <div>
                        <div>
                            <Moment format="DD/MM/YYYY">{dateLastInteraction}</Moment>
                        </div>
                        <div style={{ fontSize: "0.85rem" }}>(HÁ {Math.round(daysWithoutInteraction)} DIA(S))</div>
                    </div>
                )
            } else {
                return null
            }
        } else {
            return <div>-</div>
        }
    }

    const timelineFunnelListing = (rowData: any) => {
        const funnelStage = rowData.funnelStage
        let chipColor

        switch (funnelStage.name) {
            case "Primeiro Contato":
                chipColor = "#E3455E" // Laranja
                break
            case "Atendimento / Proposta":
                chipColor = "#FDAB3F" // Verde
                break
            case "Engenharia de Aplicação":
                chipColor = "#FFC900" // Amarelo
                break
            case "Follow-UP":
                chipColor = "#569BFA" // Rosa
                break
            case "Formalização de Pedido":
                chipColor = "#01C877" // Roxo
                break
            default:
                chipColor = "#03A9F4" // Azul (cor padrão)
                break
        }
        let chipStyle = {
            backgroundColor: chipColor,
            marginRight: "8px",
            color: "#fff",
            fontSize: "0.9rem",
            fontWeight: "600",
            padding: "0.5rem",
            borderRadius: "0.5rem",
        }

        return (
            <div className="funnelOnTable">
                <span style={chipStyle}>{funnelStage.name}</span>
            </div>
        )
    }

    const delayedDaysOfListining = (rowData: any) => {
        const state = rowData.late ? "outofstock" : "instock"
        const name = rowData.late ? `HÁ ${Math.round(rowData.delayedDays)} DIA(S)` : "Em dia"
        return <span className={`product-badge status-${state}`}>{name}</span>
    }

    const valueProductsAndServices = (rowData: any) => {
        return (
            <>
                <div>
                    <div className="p-d-flex" style={{ fontSize: "1rem", display: "flex", justifyContent: "center" }}>
                        <div>
                            {rowData.totalValueMachines !== null
                                ? rowData.totalValueMachines.toLocaleString("pt-br", {
                                      style: "currency",
                                      currency: "BRL",
                                  })
                                : ""}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const replaceQualification = (qualification: any) => {
        switch (qualification) {
            case 1:
                return "baixa"
            case 2:
                return "média baixa"
            case 3:
                return "média"
            case 4:
                return "média alta"
            case 5:
                return "alta"
            default:
                return ""
        }
    }

    const colorStatusOpportunity = (rowData: string) => {
        const statusColors: any = {
            open: "#03A9F4",
            gain: "#689F38",
            loss: "#D32F2F",
            stopped: "#FBC02D",
            deleted: "#607D8B",
        }

        return statusColors[rowData]
    }

    const replaceStatus = (status: string) => {
        switch (status) {
            case "open":
                return "ABERTA"
            case "gain":
                return "GANHA"
            case "loss":
                return "PERDIDA"
            case "stopped":
                return "PAUSADA"
            case "deleted":
                return "EXCLUÍDA"
            case "disqualified":
                return "DESQUALIFICADA"
            default:
                return status
        }
    }

    const statusOpportunityColumns = (rowData: OpportunityInterface) => {
        return (
            <>
                <div style={{ color: `${colorStatusOpportunity(rowData.status)}` }}>{replaceStatus(rowData.status)}</div>
            </>
        )
    }

    const nameOpportunity = (rowData: OpportunityInterface) => {
        const linkId = `/crm/leads/${rowData.id}`
        const isNew = rowData.new || rowData.new_on_funnel

        return (
            <div className="p-d-flex">
                <Avatar
                    icon={!rowData.company.picture_url && "pi pi-user"}
                    image={rowData.company.picture_url}
                    className="p-d-flex p-as-center p-mr-2"
                    size="large"
                    shape="circle"
                />
                <div>
                    {isNew && (
                        <div className="p-d-flex p-jc-center" style={{ color: "#689F38" }}>
                            <div style={{ fontSize: "0.7rem", fontWeight: "600" }}>NOVA OPORTUNIDADE!&nbsp;</div>
                            <i className="pi pi-star-fill" style={{ fontSize: "0.7rem" }} />
                        </div>
                    )}
                    <div className="numberCompany p-d-flex p-text-nowrap p-text-truncate">
                        <a href={linkId}>
                            <p style={{ color: colorStatusOpportunity(rowData.status) }}>
                                <strong>{("000000" + rowData.number).slice(-6)} - </strong>
                            </p>
                        </a>
                        <a href={linkId}>
                            <p style={{ fontWeight: "600", fontSize: "1.1rem", color: "rgba(41, 50, 65, 0.8)" }}>{rowData.name}</p>
                        </a>
                    </div>
                    <a href={linkId}>
                        <div className="nameCompany p-text-nowrap p-text-truncate">{rowData.company.name}</div>
                    </a>
                </div>
            </div>
        )
    }

    const handleRating = (event: any, id: string) => {
        let qualification = event

        try {
            let header = {
                headers: {
                    route: "update_opportunity_qualification",
                },
            }

            api.put("opportunities/qualification", { id, qualification }, header)
                .then((res: any) => {
                    if (res.status === 200) {
                        props.opportunities.map((opportunity: OpportunityInterface) => {
                            if (opportunity.id === id) {
                                opportunity.qualification = qualification
                            }
                        })
                        props.toast.current?.show({
                            severity: "success",
                            summary: "Qualificação alterada com sucesso!",
                            detail: res.data.message,
                            life: 3000,
                        })
                        setDisplayDialogConfirmChangeRating(false)
                    } else {
                        props.toast.current?.show({
                            severity: "error",
                            summary: "Erro na alteração da qualificação!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error: any) => {
                    props.toast.current?.show({
                        severity: "error",
                        summary: "Erro na alteração da qualificação!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <DataTable
                value={props.companiesOpportunity}
                dataKey="id"
                paginator
                stripedRows
                loading={props.loadingOppotunity}
                rows={10}
                size="small"
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} oportunidades"
                emptyMessage="Não há Oportunidades"
            >
                {/* AVATAR */}

                <Column align="center" alignHeader="center" body={imageBodyTemplate}></Column>

                {/* OPORTUNIDADES */}
                <Column align="center" alignHeader="center" sortField="name" sortable header="OPORTUNIDADES" body={nameOpportunity}></Column>

                {/* CNPJ */}
                <Column align="center" alignHeader="center" header="CNPJ" sortField="company.cnpj" sortable body={cnpjOpportunityColumns}></Column>

                {/* OPORTUNIDADES */}
                <Column align="center" alignHeader="center" header="STATUS" sortField="status" sortable body={statusOpportunityColumns}></Column>

                {/*  DIAS EM ATRASO */}
                <Column align="center" alignHeader="center" header="ATRASO" sortField="delayedDays" sortable body={delayedDaysOfListining}></Column>

                {/* DATA DA ULTIMA INTERAÇÃO */}
                <Column
                    align="center"
                    alignHeader="center"
                    header="ULT. INTERAÇÃO"
                    sortField="dateLastInteraction"
                    sortable
                    body={lastInterationOfListining}
                ></Column>

                {/* QUALIFICAÇÃO */}
                <Column align="center" alignHeader="center" body={rating} header="TEMPERATURA" sortField="qualification" sortable></Column>

                {/* ETAPA FUNIL */}
                <Column
                    align="center"
                    alignHeader="center"
                    body={timelineFunnelListing}
                    header="ETAPA DE ATENDIMENTO"
                    sortable
                    sortField="funnelStage.sequence"
                ></Column>

                {/* VALORES */}
                <Column align="center" alignHeader="center" body={valueProductsAndServices} header="VALORES" sortField="totalValueMachines" sortable></Column>
            </DataTable>
            <Dialog
                visible={displayDialogConfirmChangeRating}
                style={{}}
                draggable={false}
                onHide={() => setDisplayDialogConfirmChangeRating(false)}
                modal
                className="p-fluid"
                header={opportunity.name}
            >
                <div>
                    <p>
                        Deseja alterar a temperatura da oportunidade de {replaceQualification(opportunity.qualification)} para{" "}
                        {replaceQualification((newValueRating as ValueChangeRating).value)}?
                    </p>
                    <div></div>
                    <div className="p-d-flex p-jc-center">
                        <Button
                            label="Cancelar"
                            className="p-button-danger p-mr-2"
                            style={{ width: "10rem" }}
                            onClick={() => setDisplayDialogConfirmChangeRating(false)}
                        />
                        <Button
                            label="Continuar"
                            className="p-button-success"
                            style={{ width: "10rem" }}
                            onClick={() => {
                                handleRating((newValueRating as ValueChangeRating).value, (newValueRating as ValueChangeRating).id)
                            }}
                        />
                    </div>
                </div>
            </Dialog>
        </>
    )
}
