import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { Card } from "primereact/card"
import { Dropdown } from "primereact/dropdown"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import Moment from "react-moment"
import { OpportunityUpdateInterface } from "../../../../interfaces/OpportunityUpdateInterface"
import { UserInterface } from "../../../../interfaces/UserInterface"
import { FunnelStageInterface } from "../../../../interfaces/FunnelStageInterface"
import moment from "moment"
import { OpportunityInterface } from "../../../../interfaces/OpportunityInterface"
import { typesMoney } from "../../../../listsArrays/ListsArrays"

interface I_TabPanelOpportunity {
    handleEditOpportunity: (e: any) => void
    onChangeEditOpportunity: (e: any) => void
    setStage: (e: string) => void
    setChangeResponsible: (e: any) => void
    setEditOpportunity: (e: OpportunityUpdateInterface) => void
    opportunity: OpportunityInterface
    editOpportunity: OpportunityUpdateInterface
    loadingHandleEditOpportunity: boolean
    funnelStages: FunnelStageInterface[]
    users: UserInterface[]
    stages: string[]
    stage: string
}

export const TabPanelOpportunity = ({
    handleEditOpportunity,
    onChangeEditOpportunity,
    setChangeResponsible,
    setStage,
    setEditOpportunity,
    opportunity,
    editOpportunity,
    users,
    stages,
    stage,
    funnelStages,
    loadingHandleEditOpportunity,
}: I_TabPanelOpportunity) => {
    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }
    return (
        <>
            {!isEmptyObj(editOpportunity) && (
                <Card>
                    <form onSubmit={handleEditOpportunity}>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6 p-lg-6">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6 p-lg-6">
                                        <div className="p-field">
                                            <label htmlFor="nameOpportunity">Descrição:</label>
                                            <InputText
                                                style={{ width: "100%" }}
                                                id="nameOpportunity"
                                                name="name"
                                                maxLength={120}
                                                value={editOpportunity.name || ""}
                                                onChange={(e: any) => onChangeEditOpportunity(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-6 p-lg-6">
                                        <div className="p-field">
                                            <label htmlFor="responsible">Responsável:</label>

                                            <Dropdown
                                                style={{ width: "100%" }}
                                                id="responsible"
                                                name="responsible"
                                                filter={true}
                                                options={users}
                                                emptyMessage="Não há dados"
                                                emptyFilterMessage="Não há dados"
                                                disabled={true}
                                                optionLabel="name"
                                                value={editOpportunity.responsible}
                                                onChange={(e: any) => onChangeEditOpportunity(e)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6 p-lg-6">
                                        <div className="p-field">
                                            <label htmlFor="salesFunnelOpportunity">Nível de Consciência:</label>
                                            <br />
                                            <Dropdown
                                                id="funnel"
                                                style={{ width: "100%" }}
                                                value={stage}
                                                options={stages}
                                                onChange={(e) => {
                                                    stage = e.value
                                                    setStage(stage)
                                                }}
                                                placeholder="Nível de Consciência"
                                            />
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-6 p-lg-6">
                                        <div className="p-field">
                                            <label htmlFor="stateFunnel">Etapa de Atendimento:</label>
                                            <Dropdown
                                                style={{ width: "100%" }}
                                                id="stateFunnel"
                                                name="stage_funnel"
                                                filter={true}
                                                emptyMessage="Não há dados"
                                                emptyFilterMessage="Não há dados"
                                                options={funnelStages}
                                                optionLabel="name"
                                                value={
                                                    funnelStages.filter((a: FunnelStageInterface) => {
                                                        return a.id === editOpportunity?.stage_funnel.id
                                                    })[0]
                                                }
                                                onChange={(e: any) => onChangeEditOpportunity(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-md-6 p-lg-6">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6 p-lg-6">
                                        <div className="p-field">
                                            <label htmlFor="sourceOpportunity">Fonte de Chegada:</label>

                                            <InputText
                                                style={{ width: "100%" }}
                                                id="nameOpportunity"
                                                disabled
                                                name="name"
                                                maxLength={120}
                                                value={editOpportunity.media.name}
                                                onChange={(e: any) => onChangeEditOpportunity(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-6 p-lg-6">
                                        <div className="p-field">
                                            <label htmlFor="sourceOpportunity">Previsão de Fechamento:</label>

                                            <Calendar
                                                style={{ width: "100%" }}
                                                locale="pt"
                                                id="forecast_closing_date"
                                                name="forecast_closing_date"
                                                showIcon
                                                value={editOpportunity.forecast_closing_date}
                                                placeholder={moment(opportunity.forecast_closing_date).format("DD/MM/YYYY")}
                                                dateFormat="dd/mm/yy"
                                                onMonthChange={() => ""}
                                                onChange={(e: any) => onChangeEditOpportunity(e)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6 p-lg-6">
                                        <div className="p-field">
                                            <label htmlFor="sourceOpportunity">Tipo de Moeda:</label>
                                            <Dropdown
                                                style={{ width: "100%" }}
                                                emptyMessage="Não há dados"
                                                emptyFilterMessage="Não há dados"
                                                name="money"
                                                filter={true}
                                                options={typesMoney}
                                                optionLabel="currency_symbol"
                                                value={editOpportunity.money}
                                                onChange={(e: any) => onChangeEditOpportunity(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-6 p-lg-6">
                                        <div className="p-field">
                                            <label htmlFor="sourceOpportunity">Taxa da Moeda:</label>
                                            <InputNumber
                                                style={{ width: "100%" }}
                                                value={editOpportunity.currency_tax}
                                                onChange={(e: any) => {
                                                    editOpportunity.currency_tax = e.value
                                                    setEditOpportunity(editOpportunity)
                                                }}
                                                min={0.1}
                                                minFractionDigits={2}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-grid p-ai-center">
                            <div className="p-col-12 p-md-6 p-lg-6">
                                <Button
                                    style={{ minWidth: "10rem" }}
                                    type="submit"
                                    label="Atualizar Oportunidade"
                                    className="p-button-success"
                                    disabled={opportunity.status === "gain" ? true : false}
                                    loading={loadingHandleEditOpportunity}
                                />
                                &nbsp;&nbsp;
                                <Button
                                    style={{ minWidth: "10rem" }}
                                    type="button"
                                    label="Transferir Oportunidade"
                                    className="p-button-help"
                                    disabled={opportunity.status === "gain" ? true : false}
                                    onClick={() => setChangeResponsible(true)}
                                />
                            </div>

                            <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: "right" }}>
                                <strong>
                                    <span>Data de Criação: </span>
                                    <Moment format="DD/MM/YYYY HH:mm" style={{ color: "red" }}>
                                        {opportunity.created_at}
                                    </Moment>
                                </strong>
                            </div>
                        </div>
                    </form>
                </Card>
            )}
        </>
    )
}
