import React, { useRef, useState, useEffect } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import { Dropdown } from "primereact/dropdown"
import { Tag } from "primereact/tag"
import App from "../../layout/App"
import api from "../../services/api"
import Moment from "react-moment"
import classNames from "classnames"

//PrimeReact components
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { ConfirmDialog } from "primereact/confirmdialog"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Toast } from "primereact/toast"
import { Toolbar } from "primereact/toolbar"
import { FilterMatchMode, FilterOperator } from "primereact/api"

//Components
import { CrmRecords } from "../../components/crm/CrmRecords"

//Interface
import { InterestInterface } from "../../interfaces/InterestInterface"
import { HotKeys } from "../../components/crm/HotKeys"
import ReactTooltip from "react-tooltip"

export const Interests = () => {
    let toast = useRef<Toast>(null)
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    let [createValues, setCreateValues] = useState<InterestInterface>({} as InterestInterface)
    let [interest, setInterest] = useState<InterestInterface>({} as InterestInterface)
    let [interests, setInterests] = useState<InterestInterface[]>([])
    let [submitted, setSubmitted] = useState<boolean>(false)

    const [selectedStatus, setSelectedStatus] = useState<any>({ name: "Ativo", code: 1 })

    //Loadings
    let [loadingDelete, setLoadingDelete] = useState<boolean>(false)
    let [loadingEdit, setLoadingEdit] = useState<boolean>(false)
    let [loadingList, setLoadingList] = useState<boolean>(true)
    let [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    //Dialogs
    let [deleteDialog, setDeleteDialog] = useState(false)
    let [hotKeysDialog, setHotKeysDialog] = useState(false)
    let [interestEditDialog, setInterestEditDialog] = useState(false)
    let [interestsDialog, setInterestsDialog] = useState<boolean | undefined>(false)

    //Filters
    let [auxInteresstsFiltered, setAuxinteresstsFiltered] = useState<InterestInterface[]>([])

    //HotKeys
    useHotkeys("shift+n", () => openNew())
    useHotkeys("shift+i", () => setHotKeysDialog(true))

    let [globalFilterValue, setGlobalFilterValue] = useState("")
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    })

    const statusOp = [
        { name: "Inativo", code: 0 },
        { name: "Ativo", code: 1 },
        { name: "Ativo para criação manual", code: 2 },
    ]

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value
        let _filters = { ...filters }
        _filters["global"].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    //Headers
    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Interesses </h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Pesquisar..." />
            </span>
        </div>
    )

    //Buttons
    const createInterestButton = () => {
        return (
            <React.Fragment>
                <Button
                    label="Novo"
                    icon="pi pi-user-plus"
                    className="p-button"
                    onClick={() => {
                        openNew()
                    }}
                />
            </React.Fragment>
        )
    }

    const dataTableButtons = (rowData: InterestInterface) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-user-edit"
                    data-tip
                    data-for="editInterests"
                    className="p-button-rounded p-button"
                    onClick={() => dialogEditInterest(rowData)}
                />
                <ReactTooltip className="toolTip" id="editInterests" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Editar Interesse</p>
                </ReactTooltip>
                &nbsp;&nbsp;
                <Button
                    data-tip
                    data-for="deleteInterests"
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => deleteInterest(rowData)}
                />
                <ReactTooltip className="toolTip" id="deleteInterests" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Apagar Interesses</p>
                </ReactTooltip>
            </div>
        )
    }

    //Formtater DataTable field
    const dateFormatCreate = (rowData: InterestInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.created_at}</Moment>
            </>
        )
    }

    const dateFormatUpdate = (rowData: InterestInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.updated_at}</Moment>
            </>
        )
    }

    //Filter
    const findIndexById = (id: string) => {
        let index = -1
        for (let i = 0; i < auxInteresstsFiltered.length; i++) {
            if (auxInteresstsFiltered[i].id === id) {
                index = i
                break
            }
        }

        return index
    }

    //OnChange
    const onChangeCreateInterest = (event: any) => {
        const { value, name } = event.target

        setCreateValues({
            ...createValues,
            [name]: value,
        })
    }

    const onChangeEdit = (e: any, name: string) => {
        const val = (e.target && e.target.value) || ""
        let _interest = { ...interest }

        switch (name) {
            case "name":
                _interest["name"] = val
                break

            default:
                break
        }

        setInterest(_interest)
    }

    //Dialogs
    const hideDialog = () => {
        setSubmitted(false)
        setInterestsDialog(false)
        setInterestEditDialog(false)
        setDeleteDialog(false)
        setCreateValues({} as any)
        setHotKeysDialog(false)
    }

    const dialogEditInterest = (interest: InterestInterface) => {
        setInterest(interest)
        setInterestEditDialog(true)

        const findStatus = statusOp.find((item) => item.code === interest.status)
        setSelectedStatus(findStatus)

        setCreateValues({
            ...createValues,
            "status": findStatus!.code,
        })
    }

    //Requisitions
    const allInterests = async () => {
        try {
            let header = {
                headers: {
                    route: "list_interests",
                },
            }
            await api.get("interests", header)
                .then((res) => {
                    setLoadingList(false)
                    setInterests(res.data)
                    setAuxinteresstsFiltered(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const onSubitInterst = (event: any) => {
        setLoadingEdit(true)
        event.preventDefault()
        setSubmitted(false)

        try {
            const { name, status } = createValues
            if (name === "") {
                setLoadingEdit(false)
                setSubmitted(true)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao cadastrar",
                    detail: "Por favor, verificar os dados preenchidos!",
                    life: 3000,
                })
            } else {
                let header = {
                    headers: {
                        route: "create_interest",
                    },
                }

                api.post(
                    "interests",
                    {
                        name,
                        status,
                    },
                    header
                )
                    .then((res) => {
                        if (res.status === 200) {
                            hideDialog()
                            setLoadingEdit(false)
                            setInterests([...interests, res.data])
                            setAuxinteresstsFiltered([...interests, res.data])
                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso",
                                detail: "Interesse Criado",
                                life: 3000,
                            })
                        } else {
                            setLoadingEdit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao cadastar!",
                                detail: "Por favor, verificar os dados preenchidos!",
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingEdit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao cadastar!",
                            detail: "Por favor, verificar os dados preenchidos!",
                            life: 3000,
                        })
                    })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const openNew = () => {
        setInterestsDialog(true)

        setCreateValues({
            ...createValues,
            "status": 1,
        })
        setSelectedStatus({ name: "Ativo", code: 1 })
    }

    const deleteMessage = () => {
        return (
            <>
                {" "}
                Deseja realmente excluir o interesse <strong>{interest.name}</strong>
            </>
        )
    }

    const onSubmitEditInterest = () => {
        setLoadingSubmit(true)

        try {
            const { name, id, status } = interest

            let header = {
                headers: {
                    route: "update_interest",
                },
            }

            api.put("interests", { id, name, status }, header)

                .then((res) => {
                    if (res.status === 200) {
                        setLoadingSubmit(false)
                        let _interests = [...auxInteresstsFiltered]
                        let _interest = res.data
                        let index = findIndexById(res.data.id)

                        _interests[index] = _interest

                        interests = _interests
                        auxInteresstsFiltered = _interests

                        setInterests(_interests)
                        setAuxinteresstsFiltered(_interests)
                        hideDialog()

                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na alteração",
                            detail: "Interesse alterado com sucesso!",
                            life: 3000,
                        })
                    } else {
                        setLoadingSubmit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Editar",
                            detail: "Por favor, verificar os dados preenchidos!",
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingSubmit(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao Editar",
                        detail: "Por favor, verificar os dados preenchidos!",
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const deleteInterest = (item: InterestInterface) => {
        setInterest(item)
        setDeleteDialog(true)
    }

    const onDeleteInterest = () => {
        setLoadingDelete(true)
        try {
            api.delete("interests", {
                headers: {
                    route: "delete_interest",
                },
                data: {
                    id: interest.id,
                },
            })

                .then((res) => {
                    setLoadingDelete(false)
                    if (res.status === 200) {
                        let auxDeleteContact = interests.filter((item) => interest.id !== item.id)
                        interests = auxDeleteContact
                        auxInteresstsFiltered = auxDeleteContact

                        hideDialog()
                        setInterests(auxDeleteContact)
                        setAuxinteresstsFiltered(auxDeleteContact)

                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na exclusão",
                            detail: "Interesse excluído com sucesso!",
                            life: 3000,
                        })
                    } else {
                        setLoadingDelete(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao excluir!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingDelete(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao excluir!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    //Footers
    const interestDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingEdit} onClick={onSubitInterst} />
        </>
    )

    const interestEditFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingSubmit} onClick={onSubmitEditInterest} />
        </>
    )

    const deleteDialogFooter = () => {
        return (
            <>
                <Button label="Não" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingDelete} onClick={onDeleteInterest} />
            </>
        )
    }

    const statusColumnFormat = (data: any) => {
        switch (data.status) {
            case 0:
                return <Tag severity="warning" value="Inativo"></Tag>
            case 1:
                return <Tag severity="success" value="Ativo"></Tag>
            case 2:
                return <Tag value="Ativo para criação manual"></Tag>
        }
    }

    const onStatusCreate = (e: { value: any }) => {
        setSelectedStatus(e.value)

        setCreateValues({
            ...createValues,
            "status": e.value.code,
        })
    }

    const onStatusUpdate = (e: { value: any }) => {
        setSelectedStatus(e.value)

        setInterest({
            ...interest,
            "status": e.value.code,
        })
    }

    useEffect(() => {
        allInterests()
    }, [])

    return (
        <App>
            <CrmRecords />
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="p-mb-4 p-toolbar" left={createInterestButton}></Toolbar>

                        {/* DataTable */}
                        <DataTable
                            value={interests}
                            loading={loadingList}
                            stripedRows
                            selectionMode="checkbox"
                            filters={filters}
                            globalFilterFields={["name"]}
                            dataKey="id"
                            rows={10}
                            rowsPerPageOptions={[10, 15, 20]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} interesses"
                            paginator
                            header={header}
                            emptyMessage="Não há Interesses cadastrados"
                            responsiveLayout="scroll"
                        >
                            <Column field="name" header="Nome" sortable align="center" alignHeader="center" />
                            <Column
                                body={statusColumnFormat}
                                field="status"
                                header="Status"
                                sortable
                                align="center"
                                alignHeader="center"
                            />
                            <Column body={dateFormatCreate} header="Data de Criação" sortable sortField="created_at" align="center" alignHeader="center" />
                            <Column body={dateFormatUpdate} sortField="update_at" header="Data de Atualização" sortable align="center" alignHeader="center" />
                            <Column align="center" alignHeader="center" body={dataTableButtons}></Column>
                        </DataTable>

                        {/* Create Method */}
                        <Dialog
                            visible={interestsDialog}
                            style={{ width: "450px" }}
                            draggable={false}
                            header="Adicionar Interesse"
                            modal
                            className="p-fluid"
                            footer={interestDialogFooter}
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <label htmlFor="name">Nome</label>
                                <InputText
                                    id="name"
                                    name="name"
                                    required
                                    style={{ minWidth: "20rem" }}
                                    type="String"
                                    maxLength={45}
                                    className={
                                        "p-field p-text-capitalize " +
                                        classNames({
                                            "p-invalid": submitted && createValues.name === "",
                                        })
                                    }
                                    value={createValues.name || ""}
                                    onChange={onChangeCreateInterest}
                                />

                                <label className="p-mt-3" htmlFor="name">
                                    Status
                                </label>
                                <Dropdown
                                    value={selectedStatus}
                                    options={statusOp}
                                    onChange={onStatusCreate}
                                    optionLabel="name"
                                    placeholder="Selecione o status"
                                />
                            </div>
                        </Dialog>

                        {/* Edit Method */}
                        <Dialog
                            visible={interestEditDialog}
                            style={{ width: "450px" }}
                            draggable={false}
                            header={`Editar Interesse:`}
                            modal
                            className="p-fluid"
                            footer={interestEditFooter}
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <label htmlFor="name">Nome</label>
                                <InputText
                                    style={{ minWidth: "20rem" }}
                                    id="name"
                                    name="name"
                                    maxLength={120}
                                    onChange={(e: any) => onChangeEdit(e, "name")}
                                    value={interest.name || ""}
                                />

                                <label className="p-mt-3" htmlFor="name">
                                    Status
                                </label>
                                <Dropdown
                                    value={selectedStatus}
                                    options={statusOp}
                                    onChange={onStatusUpdate}
                                    optionLabel="name"
                                    placeholder="Selecione o status"
                                />
                            </div>
                        </Dialog>

                        {/* Delete Method */}
                        <ConfirmDialog
                            style={{ width: "400px", borderRadius: "16px" }}
                            draggable={false}
                            visible={deleteDialog}
                            onHide={hideDialog}
                            message={deleteMessage}
                            acceptLabel={"Sim"}
                            rejectLabel={"Não"}
                            header="Confirmação de exclusão"
                            icon="pi pi-exclamation-triangle"
                            accept={onDeleteInterest}
                            acceptIcon="pi pi-check"
                            rejectIcon="pi pi-times"
                            reject={hideDialog}
                            dismissableMask={true}
                            footer={deleteDialogFooter}
                        />

                        {/* HotKeys Dialog */}
                        <Dialog
                            visible={hotKeysDialog}
                            style={{ width: "800px" }}
                            modal
                            className="p-fluid"
                            onHide={hideDialog}
                            header={`Atalhos - Tela de Interesses`}
                            draggable={false}
                        >
                            <HotKeys create={"Criar um novo interesse"} />
                        </Dialog>
                    </div>
                </div>
            </div>
        </App>
    )
}
