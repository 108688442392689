import React, { useRef, useState, useEffect } from "react"

import api from "../../services/api"
import App from "../../layout/App"
import moment from "moment"
import Moment from "react-moment"
import { HotKeys } from "../../components/crm/opportunity/hotKeys"
import { useAuth } from "../../hooks/AuthContext"
import { useHotkeys } from "react-hotkeys-hook"

//PrimeReact Components
import { Avatar } from "primereact/avatar"
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { Dialog } from "primereact/dialog"
import { Divider } from "primereact/divider"
import { Dropdown } from "primereact/dropdown"
import { Image } from "primereact/image"
import { InputText } from "primereact/inputtext"
import { MultiSelect } from "primereact/multiselect"
import { Rating } from "primereact/rating"
import { Skeleton } from "primereact/skeleton"
import { Tag } from "primereact/tag"
import { Toast } from "primereact/toast"

//Components
import { AnnotaionOpportunity } from "../../components/crm/opportunity/annotation"
import { CrmRecords } from "../../components/crm/CrmRecords"
import { selectionPriorities } from "../../listsArrays/ListsArrays"

//Interface
import FilterPropsInterface from "../../components/crm/opportunity/filters/index"

//CSS
import "../../styles/Opportunity.scss"
import "primeicons/primeicons.css"
import "primereact/resources/themes/saga-blue/theme.css"
import "primereact/resources/primereact.css"
import "primeflex/primeflex.css"

//Interface
import { CompanyInterface } from "../../interfaces/CompanyInterface"
import { FunnelInterface } from "../../interfaces/FunnelInterface"
import { OpportunityInterface } from "../../interfaces/OpportunityInterface"
import { ShowOpportunityDetailInterface } from "../../interfaces/ShowOpportunityDetailInterface"
import { TaskInterface } from "../../interfaces/TaskInterface"
import { UserInterface } from "../../interfaces/UserInterface"
import { ContactInterface } from "../../interfaces/ContactInterface"
import { WhatsappConversationInterface } from "../../interfaces/WhatsappConversationInterface"
import { CleanTaskInterface } from "../../interfaces/CleanTaskInterface"
import TaskSideRightPanel from "../../components/crm/opportunity/taskSideRightPanel"
import Opportunities from "../../components/crm/opportunity/opportunities"
import { ProgressSpinner } from "primereact/progressspinner"
import { FunnelStageInterface } from "../../interfaces/FunnelStageInterface"

interface OpportunityDataInterface extends OpportunityInterface {
    nextTask: TaskInterface
}

interface ShowOpportunityDetailDataInterface extends ShowOpportunityDetailInterface {
    last_proposal: LastProposalInterface
    contact: ContactShowOpportunityDetailInterface
}

interface WorkViewFilterInterface {
    name: string
    code: number
}

interface ContactShowOpportunityDetailInterface {
    name: string
    email: string
    phone: string
}

interface LastProposalInterface {
    status: string
    url: string
}

interface ValueChangeRating {
    value: string
    id: string
}

export const Opportunity = () => {
    let toast = useRef<Toast>(null)
    let dt: any = useRef(null)
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    //Interface
    let [opportunities, setOpportunities] = useState<OpportunityDataInterface[]>([])
    let [auxOpportunities, setAuxOpportunities] = useState<OpportunityDataInterface[]>([])
    let [auxOpportunitiesTwo, setAuxOpportunitiesTwo] = useState<OpportunityDataInterface[]>([])
    let [companies, setCompanies] = useState<CompanyInterface[]>([])
    let [users, setUsers] = useState<UserInterface[]>([])
    let [workViewFilter, setWorkViewFilter] = useState<WorkViewFilterInterface>({} as WorkViewFilterInterface)
    let [funnel, setFunnel] = useState<FunnelInterface>({} as FunnelInterface)
    let [stage, setStage] = useState<string>("")
    let [opportunity, setOpportunity] = useState<OpportunityDataInterface>({} as OpportunityDataInterface)
    let [showOpportunityDetailState, setShowOpportunityDetailState] = useState<ShowOpportunityDetailDataInterface>({} as ShowOpportunityDetailDataInterface)
    let [funnels, setFunnels] = useState<FunnelInterface[]>([])
    let [cleanTasks, setCleanTasks] = useState<CleanTaskInterface[]>([])
    let stages = ["Fundo", "Meio", "Topo"]
    let [selectedFunnelFilter, setSelectedFunnelFilter] = useState<any[]>([])

    //Loading
    let [loadingSkeletonTotalPage, setLoadingSkeletonTotalPage] = useState(true)
    let [loadingSkeletonFiltering, setLoadingSkeletonFiltering] = useState(false)
    let [loadingCreateAnnotations, setLoadingCreateAnnotations] = useState(false)
    let [loadingUpdateAsOpportunities, setLoadingUpdateAsOpportunities] = useState(false)
    let [loadingList, setLoadingList] = useState(true)
    let [loadingExcludedFilter] = useState(false)
    let [loadingCleanFilters, setLoadingCleanFilters] = useState(false)
    let [loadingButtonChangeRating, setLoadingButtonChangeRating] = useState(false)
    let [loadingButtonChangeAvatarCompany, setLoadingButtonChangeAvatarCompany] = useState(false)
    let [loadingReturnTask, setLoadingReturnTask] = useState(false)
    let [loadingConsciousnessLevel, setLoadingConsciousnessLevel] = useState(false)
    let [loadingConclude, setLoadingConclude] = useState(false)
    //Lists
    let [listOfSellers, setListOfSellers] = useState<any[]>([])
    let [listOfStatus, setListOfStatus] = useState<any[]>([])
    let [listOfStage, setListOfStage] = useState<any[]>([])
    let [listOfFunnel, setListOfFunnel] = useState<any[]>([])
    let [listOfState, setListOfState] = useState<any[]>([])
    let [listOfMedia, setListOfMedia] = useState<any[]>([])
    let [listOfInterest, setListOfInterest] = useState<any[]>([])

    //Filter
    let [selectedSellerFilter, setSelectedSellerFilter] = useState<any[]>([])
    let [selectedPriorityFilter, setSelectedPriorityFilter] = useState<any[]>([])
    let [selectedStatusFilter, setSelectedStatusFilter] = useState<any[]>([])
    let [selectedStageFilter, setSelectedStageFilter] = useState<any[]>([])
    let [selectedStateFilter, setSelectedStateFilter] = useState<any[]>([])
    let [selectedMediaFilter, setSelectedMediaFilter] = useState<any[]>([])
    let [selectedInterestFilter, setSelectedInterestFilter] = useState<any[]>([])
    let [advancedSearchFilter, setAdvancedSearchFilter] = useState("")
    let [dateFinal, setDateFinal] = useState("")
    let [dateInit, setDateInit] = useState("")
    let [phoneValidation, setPhoneValidation] = useState<any>("")
    let [onTaskFilters, setOnTaskFilters] = useState(false)

    //Dialog
    let [displayPositionDialogInformation, setDisplayPositionDialogInformation] = useState(false)
    let [displayBasicMobile, setDisplayBasicMobile] = useState(false)
    let [displayBasicNextTaskMobile, setDisplayBasicNextTaskMobile] = useState(false)
    let [displayDialogBasicAnottation, setDisplayDialogBasicAnottation] = useState(false)
    let [displayDialogAccountableUserInformationsAvatar, setDisplayDialogAccountableUserInformationsAvatar] = useState(false)
    let [displayDialogChangeAccountable, setDisplayDialogChangeAccountable] = useState(false)
    let [displayDialogCompanyInformationsAvatar, setDisplayDialogCompanyInformationsAvatar] = useState(false)
    let [displayDialogConfirmChangeRating, setDisplayDialogConfirmChangeRating] = useState(false)
    let [displayDialogJSONtoXML, setDisplayDialogJSONtoXML] = useState(false)
    let [displayDialogReturnTask, setDisplayDialogReturnTask] = useState(false)
    let [displayDialogChangeFunnel, setDisplayDialogChangeFunnel] = useState(false)
    let [displayFulfillmentStepDialogBox, setDisplayFulfillmentStepDialogBox] = useState(false)
    let [changeSelectedAccountable, setChangeSelectedAccountable] = useState<any[]>([])
    let [hotKeysDialog, setHotKeysDialog] = useState(false)
    let [newValueRating, setNewValueRating] = useState<{}>({} as ValueChangeRating)
    let [loadingDialogOfTasks, setLoadingDialogOfTasks] = useState(true)
    let [taskSelected, setTaskSelected] = useState<{}>({} as CleanTaskInterface)

    //Alteration state
    let [anotation, setAnotation] = useState("")
    let [taskAnotation, setTaskAnotation] = useState("")
    let [handleButtonNewOpportunities, setHandleButtonNewOpportunities] = useState(false)
    let [handleButtonExcluded, setHandleButtonExcluded] = useState(false)
    let [handleViewLate, setHandleViewLate] = useState(false)
    let [tooltipCleanFilter, setTooltipCleanFilter] = useState(false)
    let [concludeTaskState, setConcludeTaskState] = useState(false)
    let [nameOFclassCentralizeFromDesktop, setNameOFclassCentralizeFromDesktop] = useState("centralizeFromDesktop p-d-flex")

    let [funnelStages, setFunnelStages] = useState<FunnelStageInterface[]>([])
    let [funnelStage, setFunnelStage] = useState({} as FunnelStageInterface)

    let [countOfNewOpportunities, setCountOfNewOpportunities] = useState(0)
    let [permission, setPermission] = useState(false)
    let [phoneConversation, setPhoneConversation] = useState<WhatsappConversationInterface[]>([])

    let [date1, setDate1] = useState<any>(new Date())

    let [nameOfClassTaksButtonFilter, setNameOfClassTaksButtonFilter] = useState("pi pi-arrow-left")

    let opportunitiesNotDeleted: any[] = []
    let count = [1, 2, 3, 4, 5]

    const { user } = useAuth()

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    //Hotkeys
    useHotkeys("ctrl+alt+a", () => setHotKeysDialog(true))
    useHotkeys("ctrl+shift+alt+f", () => autoFocusAndCleanInputSearchBar())
    useHotkeys("ctrl+shift+alt+l", () => onLoadingCleanFilters())
    useHotkeys("ctrl+shift+alt+o", () => {})
    useHotkeys("ctrl+shift+alt+e", () => {
        handleButtonExcluded = !handleButtonExcluded
        setHandleButtonExcluded(handleButtonExcluded)
        filter()
    })
    useHotkeys("ctrl+shift+alt+n", () => {
        handleButtonNewOpportunities = !handleButtonNewOpportunities
        setHandleButtonNewOpportunities(handleButtonNewOpportunities)
        filter()
    })
    useHotkeys("esc", () => {
        onHide()
    })

    const allOpportunity = async () => {
        try {
            setLoadingList(true)
            const header = {
                headers: {
                    route: "list_opportunities",
                },
            }

            const res = await api.get("opportunities", header)
            const opportunitiesData = res.data

            opportunities = opportunitiesData
            auxOpportunities = opportunitiesData
            auxOpportunitiesTwo = opportunitiesData

            setAuxOpportunities(opportunitiesData)
            setOpportunities(opportunitiesData)

            setAuxOpportunitiesTwo(opportunitiesData)
            setLoadingList(false)

            setLoadingUpdateAsOpportunities(false)

            const opportunitiesNotDeleted = opportunitiesData.filter((op: any) => op.status !== "deleted")
            setAuxOpportunities(opportunitiesNotDeleted)
            filter()
            setLoadingSkeletonTotalPage(false)
        } catch (error: any) {
            setLoadingSkeletonTotalPage(false)
            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar!",
                detail: error.message,
                life: 3000,
            })
            console.log(error)
        }
    }

    const allFunnels = async () => {
        try {
            const res = await api.get("funnels")
            const funnelsData = res.data

            setFunnels(funnelsData)

            setFunnelStages(funnelsData.find((item: any) => item.is_default).stages)

            if (localStorage.getItem("@esquadros:filterOpportunity") === null || isEmptyObj(dataLocalStorage.funnel)) {
                const defaultFunnel = funnelsData.find((item: any) => item.is_default)

                if (defaultFunnel) {
                    setFunnel(defaultFunnel)
                }
            }
        } catch (error: any) {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar!",
                detail: error.response.data.message,
                life: 3000,
            })
            console.log(error)
        }
    }

    const allCompanies = async () => {
        try {
            const header = {
                headers: {
                    route: "list_companies",
                },
            }

            const res = await api.get("companies", header)
            const companiesData = res.data

            setCompanies(companiesData)
            companies = companiesData
        } catch (error: any) {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar!",
                detail: error.response?.data?.message || error.message,
                life: 3000,
            })
            console.log(error)
        }
    }

    const allCleanTasks = async () => {
        try {
            const header = {
                headers: {
                    route: "list_tasks",
                },
            }

            const res = await api.get("tasks/clean", header)
            cleanTasks = res.data
            setCleanTasks(cleanTasks)
            loadingDialogOfTasks = false
            setLoadingDialogOfTasks(loadingDialogOfTasks)
        } catch (error: any) {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar!",
                detail: error.response.data.message,
                life: 3000,
            })
        }
    }

    const allUsers = async () => {
        try {
            const header = {
                headers: {
                    route: "show_crm_configuration_sellers",
                },
            }

            const res = await api.get("crm_configurations/sellers", header)
            const usersData = res.data

            users = usersData
            setUsers(usersData)
        } catch (error: any) {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar usuários!",
                detail: error.response?.data?.message || error.message,
                life: 3000,
            })
            console.log(error)
        }
    }

    const showOpportunityDetail = async (rowData?: any) => {
        try {
            let header = {
                headers: {
                    route: "show_opportunity_details",
                },
            }

            const res = await api.get(`opportunities/detail/${rowData.id}`, header)
            setShowOpportunityDetailState(res.data)
        } catch (error: any) {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar!",
                detail: error.response?.data?.message || error.message,
                life: 3000,
            })
            console.log(error)
        }
    }

    const createAnotation = async () => {
        let result = anotation.replace(/^\s+|\s+$/gm, "")
        if (!isEmptyObj(anotation) && result.length > 0) {
            let auxConfirmAnotation = {
                description: anotation,
                opportunity_id: opportunity.id,
            }

            setLoadingCreateAnnotations(true)
            let header = {
                headers: {
                    route: "create_annotation",
                },
            }

            try {
                await api
                    .post("annotations", auxConfirmAnotation, header)
                    .then((res: any) => {
                        if (res.status === 200) {
                            setLoadingCreateAnnotations(false)
                            onHideAnottation()
                            setDisplayDialogBasicAnottation(false)
                            setDisplayPositionDialogInformation(false)
                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso na Criação",
                                detail: "Anotação criada com sucesso!",
                                life: 3000,
                            })
                        } else {
                            setLoadingCreateAnnotations(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao criar Anotação!",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error: any) => {
                        setLoadingCreateAnnotations(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao criar Anotação!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        } else {
            toast.current?.show({
                severity: "warn",
                summary: "Atenção!",
                detail: "Digite algo antes de salvar a anotação",
                life: 3000,
            })
        }
    }

    const handleRating = async (event: any, id: string) => {
        let qualification = event

        try {
            let header = {
                headers: {
                    route: "update_opportunity_qualification",
                },
            }

            const response = await api.put("opportunities/qualification", { id, qualification }, header)

            if (response.status === 200) {
                filter()
                onHide()
                setLoadingButtonChangeRating(false)
                opportunities.map((opportunity: any) => {
                    if (opportunity.id === id) {
                        opportunity.qualification = qualification
                    }
                })
                toast.current?.show({
                    severity: "success",
                    summary: "Sucesso na alteração da qualificação!",

                    detail: "Qualificação alterada com sucesso!",
                    life: 3000,
                })

                setOpportunities(opportunities)
            } else {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro na alteração da qualificação!",
                    detail: response.data.message,
                    life: 3000,
                })
                onHide()
                setLoadingButtonChangeRating(false)
            }
        } catch (error: any) {
            toast.current?.show({
                severity: "error",
                summary: "Erro na alteração da qualificação!",
                detail: error.response?.data?.message || "Erro desconhecido",
                life: 3000,
            })
            onHide()
            setLoadingButtonChangeRating(false)
        }
    }

    const onConfirmTask = () => {
        if (taskAnotation !== null) {
            let auxConfirmTask = {
                id: opportunity.nextTask.id,
                annotation: taskAnotation,
            }

            setLoadingConclude(true)

            let header = {
                headers: {
                    route: "complete_task",
                },
            }

            try {
                api.put("tasks/complete", auxConfirmTask, header)
                    .then((res) => {
                        if (res.status === 200) {
                            setLoadingConclude(false)

                            setOpportunities((prevOpportunities: any) => {
                                return prevOpportunities.map((prevOpportunity: any) => {
                                    if (prevOpportunity.id === opportunity.id) {
                                        return { ...opportunity, nextTask: null }
                                    }
                                    return prevOpportunity
                                })
                            })

                            setTaskAnotation("")
                            onHide()

                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso na Conclusão",
                                detail: "Tarefa concluida com sucesso!",
                                life: 3000,
                            })
                        } else {
                            setLoadingConclude(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao concluir!",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingConclude(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao concluir!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        } else {
            setLoadingConclude(false)
            toast.current?.show({
                severity: "warn",
                summary: "OPS!",
                detail: "Você deve adicionar uma anotação para concluir está tarefa.",
                life: 8000,
            })
        }
    }

    const onChangeFunnel = async (event: any) => {
        setLoadingReturnTask(true)
        // Switch case para mudar o nome do stage.name para ingles
        let funnelLevel = stage

        switch (stage) {
            case "Fundo":
                funnelLevel = "bottom"
                break
            case "Meio":
                funnelLevel = "middle"
                break
            case "Topo":
                funnelLevel = "top"
                break
        }

        let auxOpportunities = {
            id: opportunity.id,
            funnel_level: funnelLevel,
        }

        try {
            let header = {
                headers: {
                    route: "update_opportunity_funnel",
                },
            }

            const response = await api.put("opportunities/funnel_level", auxOpportunities, header)

            if (response.status === 200) {
                filter()
                onHide()
                setLoadingButtonChangeRating(false)
                setLoadingReturnTask(false)
                opportunities.map((opportunity: any) => {
                    if (opportunity.id === auxOpportunities.id) {
                        opportunity.funnel_level = funnelLevel
                    }
                })
                toast.current?.show({
                    severity: "success",
                    summary: "Sucesso na Alteração do Nivel de Consciência!",
                    detail: "Nivel de Consciência Alterado com sucesso!",
                    life: 3000,
                })

                setOpportunities(opportunities)
            } else {
                setLoadingReturnTask(false)
                setLoadingButtonChangeRating(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro na alteração do Nivel de Consciência!",
                    detail: response.data.message,
                    life: 3000,
                })
                onHide()
                setLoadingButtonChangeRating(false)
            }
        } catch (error: any) {
            setLoadingReturnTask(false)
            setLoadingButtonChangeRating(false)

            toast.current?.show({
                severity: "error",
                summary: "Erro na alteração do funil!",
                detail: error.response?.data?.message || "Erro desconhecido",
                life: 3000,
            })
        }
    }

    const onChangeConsciousnessLevel = async (event: any) => {
        setLoadingConsciousnessLevel(true)

        try {
            const response = await api.put("opportunities/stage", {
                id: opportunity.id,
                funnel_stages_id: funnelStage.id,
            })

            if (response.status === 200) {
                filter()
                onHide()
                setLoadingConsciousnessLevel(false)
                setLoadingButtonChangeRating(false)

                console.log(response.data)

                opportunities.map((item, index) => {
                    if (item.id === opportunity.id) {
                        opportunities[index].funnelStage = funnelStage
                    }
                })

                setOpportunities(opportunities)
                setFunnelStage({} as FunnelStageInterface)

                toast.current?.show({
                    severity: "success",
                    summary: "Sucesso na alteração do etapa!",
                    detail: "Etapa de atendimento alterado com sucesso!",
                    life: 3000,
                })
            } else {
                setLoadingConsciousnessLevel(false)
                setLoadingButtonChangeRating(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro na alteração da Etapa de atendimento!",
                    detail: response.data.message,
                    life: 3000,
                })
                onHide()
                setLoadingButtonChangeRating(false)
            }
        } catch (error: any) {
            setLoadingConsciousnessLevel(false)
            setLoadingButtonChangeRating(false)

            toast.current?.show({
                severity: "error",
                summary: "Erro na alteração do funil!",
                detail: error.response?.data?.message || "Erro desconhecido",
                life: 3000,
            })
        }
    }

    const onSubmitHandleCreateReplyTask = async (event: any) => {
        setLoadingReturnTask(true)
        event.preventDefault()

        let auxOpportunities = {
            type: "LG",
            subject: `Retornar Contato`,
            date: date1,
            opportunity_id: opportunity.id,
            users_id: [opportunity.user_id],
        }

        try {
            await api
                .post("tasks", auxOpportunities)
                .then((res) => {
                    if (res.status === 200) {
                        setOpportunities((prevOpportunities) => {
                            return prevOpportunities.map((opportunity) => {
                                if (opportunity.id === res.data.opportunity_id) {
                                    return { ...opportunity, nextTask: res.data }
                                }
                                return opportunity
                            })
                        })

                        setLoadingReturnTask(false)
                        onHide()

                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "Retorno de tarefa criado com sucesso",
                            life: 3000,
                        })

                        // // Se a data da nextTask for menor que a tarefa criada, a nextTask recebe a tarefa criada
                        // if (opportunity.nextTask !== null && opportunity.nextTask.date > res.data.date) {
                        //     setOpportunities(prevState => {
                        //         return prevState.map(opportunity => {
                        //             if (opportunity.id === res.data.id) {
                        //                 return { ...opportunity, nextTask: res.data };
                        //             }
                        //             return opportunity;
                        //         });
                        //     });
                        // }
                    } else {
                        setLoadingReturnTask(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao criar retorno de tarefa!",
                            detail: "Por favor, verificar os dados preenchidos!",
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingReturnTask(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao criar retorno de tarefa!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            setLoadingReturnTask(false)
            console.log(error)
        }
    }

    const updateAccountableUser = (opportunity: any, changeSelectedAccountableId: any) => {
        let qualification = {
            id: opportunity.id,
            user_id: changeSelectedAccountableId.id,
        }

        try {
            let header = {
                headers: {
                    route: "update_opportunity_user",
                },
            }

            api.put("opportunities/user", qualification, header)
                .then((res: any) => {
                    if (res.status === 200) {
                        allOpportunity()
                        setDisplayDialogChangeAccountable(false)
                        setDisplayDialogAccountableUserInformationsAvatar(true)
                        toast.current?.show({
                            severity: "success",
                            summary: "Responsável alterado com sucesso!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    } else {
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro na alteração do responsável!",
                            detail: res.data.message,
                            life: 3000,
                        })
                        onHide()
                    }
                })
                .catch((error: any) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro na alteração do responsável!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                    onHide()
                })
        } catch (error) {
            console.log(error)
            onHide()
        }
    }

    const handleButtonClick = (hours: number) => {
        let newDate = moment(date1).add(hours, "hours").toDate()
        if (newDate.getDay() === 0) {
            newDate = moment(newDate).add(24, "hours").toDate()
        } else if (newDate.getDay() === 6) {
            newDate = moment(newDate).add(48, "hours").toDate()
        }
        setDate1(newDate)
    }

    const onPermissionRoute = () => {
        try {
            let header = {
                headers: {
                    route: "list_all_whatsapp_messages",
                },
            }

            api.post("permission/check", {}, header).then((res) => {
                permission = true
                setPermission(permission)
            })
        } catch (error) {
            toast.current?.show({
                severity: "error",
                summary: `Erro ao listar list_all_whatsapp_messages!`,
                detail: "Você não tem permissão para executar esta ação.",
                life: 3000,
            })
        }
    }

    const verifyPhone = async (param: any) => {
        try {
            const res = await api.get(`whatsapp_conversation/conversation?number=${param}&whatsapp_phone_number=${user.whatsapp_phone_number}`, {})
            const phoneConversation = res.data
            setPhoneConversation(phoneConversation)

            if (phoneConversation) {
                if (permission || phoneConversation[0]?.user_id === user.id) {
                    window.open(`/crm/atendimento/&/${phoneValidation}`)
                } else {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao acessar essa conversa!",
                        detail: "Outro vendedor já está em contato com esse cliente",
                        life: 3000,
                    })
                }
            } else {
                window.open(`/crm/atendimento/&/${parseInt(param)}`)
            }
        } catch (error) {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar LIST_ALL_WHATSAPP_MESSAGES!",
                detail: "Você não tem permissão para executar esta ação.",
                life: 3000,
            })
        }
    }

    //Dialog
    const onHide = () => {
        setDisplayBasicNextTaskMobile(false)
        setDisplayPositionDialogInformation(false)
        setDisplayDialogAccountableUserInformationsAvatar(false)
        setDisplayDialogCompanyInformationsAvatar(false)
        setDisplayDialogConfirmChangeRating(false)
        setDisplayDialogChangeAccountable(false)
        setDisplayDialogReturnTask(false)
        setDisplayDialogChangeFunnel(false)
        setHotKeysDialog(false)
        setDisplayDialogJSONtoXML(false)
        setLoadingReturnTask(false)
        setDisplayFulfillmentStepDialogBox(false)
        date1 = new Date()
        setDate1(date1)
    }

    const headerDialogOpportunityInformationDialog = (rowData: any) => {
        return (
            <>
                <div className="p-d-flex">
                    <i className="pi pi-exclamation-circle p-mr-2 p-as-center"></i>
                    <p>{`Informações adicionais - (${rowData.name})`}</p>
                </div>
            </>
        )
    }

    const renderFooterDialogMobile = (name: any) => {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => onHideDialogMobile(name)} className="p-button-danger p-button-text" />
                <Button label="Aplicar" icon="pi pi-check" onClick={() => onHideDialogMobile(name)} autoFocus className="p-button-success p-button-text" />
            </div>
        )
    }

    const concludeTask = () => {
        return (
            <div>
                <Button
                    label="Concluir Tarefa"
                    icon="pi pi-check"
                    onClick={() => {
                        setConcludeTaskState(true)
                    }}
                    autoFocus
                    className="p-button-success p-button-text"
                />
            </div>
        )
    }

    const concludeTaskFooter = () => {
        return (
            <div>
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    onClick={() => {
                        setConcludeTaskState(false)
                    }}
                    className="p-button-danger p-button-text"
                />
                <Button
                    label="Salvar"
                    loading={loadingConclude}
                    icon="pi pi-check"
                    onClick={onConfirmTask}
                    autoFocus
                    className="p-button-success p-button-text"
                />
            </div>
        )
    }

    const onHideAnottation = () => {
        setDisplayDialogBasicAnottation(false)
        setAnotation("")
    }

    const onHideDialogMobile = (name: any) => {
        dialogFuncMapMobile[`${"displayBasic"}`](false)
    }

    const onClickMobileDialog = (name: any, position: any) => {
        dialogFuncMapMobile[`${"displayBasic"}`](true)
    }

    const onClickDialogOpportunityInformation = (name: any, position: any) => {
        dialogFuncMapDetails[`${"displayPosition"}`](true)
    }

    const informationNextTask = (opportunity: OpportunityDataInterface) => {
        return (
            <>
                {opportunity.nextTask !== null && (
                    <div>
                        <div className="p-d-flex p-jc-center" style={{ fontWeight: "500" }}>
                            <a href={`/crm/leads/${opportunity?.id}/${"task"}`}>{replaceIconType(opportunity?.nextTask?.type)}</a>
                        </div>
                        <div>
                            <p style={{ fontSize: "1.1rem" }} className="p-d-flex p-jc-center p-mb-2">
                                Assunto: {opportunity?.nextTask?.subject}
                            </p>
                            <Moment format="DD/MM/YYYY HH:mm" className="p-d-flex p-jc-center p-mb-2">
                                {opportunity?.nextTask?.date}
                            </Moment>
                        </div>
                    </div>
                )}
            </>
        )
    }

    //Replace
    const replaceType = (type: string) => {
        switch (type) {
            case "LG":
                return "Ligação"
            case "EM":
                return "E-mail"
            case "RE":
                return "Reunião"
            case "VI":
                return "Visita"
            case "TA":
                return "Tarefa"
            case "AL":
                return "Almoço"
            case "WP":
                return "WhatsApp"
            default:
                return type
        }
    }

    const replaceStatus = (status: string) => {
        switch (status) {
            case "open":
                return "ABERTA"
            case "gain":
                return "GANHA"
            case "loss":
                return "PERDIDA"
            case "stopped":
                return "PAUSADA"
            case "deleted":
                return "EXCLUÍDA"
            case "disqualified":
                return "DESQUALIFICADA"
            default:
                return status
        }
    }

    const replaceIconType = (type: string) => {
        const typeMap: Record<string, string> = {
            LG: "LIGAÇÃO",
            EM: "E-MAIL",
            RE: "REUNIÃO",
            VI: "VISITA",
            TA: "TAREFA",
            AL: "ALMOÇO",
            WP: "WHATSAPP",
        }

        const iconMap: Record<string, string> = {
            LG: "pi pi-phone",
            EM: "pi pi-envelope",
            RE: "pi pi-briefcase",
            VI: "pi pi-user",
            TA: "pi pi-tag",
            AL: "pi pi-comments",
            WP: "pi pi-mobile",
        }

        const text = typeMap[type] || ""
        const iconClass = iconMap[type] || ""

        return (
            <div style={{ fontSize: "1.4rem", marginBottom: "0.5rem" }}>
                <i className={iconClass}></i>&nbsp;&nbsp;{text}
            </div>
        )
    }

    const replaceIconTypeToTask = (type: string) => {
        const typeMap: Record<string, string> = {
            LG: "LIGAÇÃO",
            EM: "E-MAIL",
            RE: "REUNIÃO",
            VI: "VISITA",
            TA: "TAREFA",
            AL: "ALMOÇO",
            WP: "WHATSAPP",
        }

        return typeMap[type] || ""
    }

    const replaceIconTypeForCardOpportunity = (type: string) => {
        const iconMap: { [key: string]: string } = {
            LG: "pi pi-phone",
            EM: "pi pi-envelope",
            RE: "pi pi-briefcase",
            VI: "pi pi-user",
            TA: "pi pi-tag",
            AL: "pi pi-comments",
            WP: "pi pi-mobile",
        }

        return iconMap[type as keyof typeof iconMap] || ""
    }

    const replaceQualification = (qualification: any) => {
        switch (qualification) {
            case 0:
                return "nenhuma"
            case 1:
                return "baixa"
            case 2:
                return "média baixa"
            case 3:
                return "média"
            case 4:
                return "média alta"
            case 5:
                return "alta"
            default:
                return qualification
        }
    }

    //Filter
    const filter = () => {
        if (
            handleButtonExcluded ||
            handleViewLate ||
            dateInit !== "" ||
            dateFinal !== "" ||
            selectedInterestFilter.length > 0 ||
            selectedSellerFilter.length > 0 ||
            selectedPriorityFilter.length > 0 ||
            selectedStatusFilter.length > 0 ||
            selectedStageFilter.length > 0 ||
            selectedStateFilter.length > 0 ||
            selectedMediaFilter.length > 0 ||
            !isEmptyObj(funnel) ||
            !(advancedSearchFilter === "")
        ) {
            var listOpportunitiesFiltered: any[] = []

            //localStorage Filters
            let localStorageFilters = {
                handleButtonExcluded: handleButtonExcluded,
                handleButtonNewOpportunities: handleButtonNewOpportunities,
                handleViewLate: handleViewLate,
                selectedInterestFilter: selectedInterestFilter,
                selectedSellerFilter: selectedSellerFilter,
                selectedPriorityFilter: selectedPriorityFilter,
                selectedStatusFilter: selectedStatusFilter,
                selectedStageFilter: selectedStageFilter,
                selectedStateFilter: selectedStateFilter,
                selectedMediaFilter: selectedMediaFilter,
                advancedSearchFilter: advancedSearchFilter,
                workViewFilter: workViewFilter,
                funnel: funnel,
            }
            localStorage.setItem("@esquadros:filterOpportunity", JSON.stringify(localStorageFilters))
            //localStorage Filters

            for (let atualLine of !isEmptyObj(opportunitiesNotDeleted) ? opportunitiesNotDeleted : opportunities) {
                if (atualLine.funnelStage.funnel_id === funnel.id) {
                    var addLine = true

                    selectedStatusFilter.forEach((status) => {
                        if (status.code === "deleted") {
                            handleButtonExcluded = true
                            setHandleButtonExcluded(true)
                        }
                    })

                    if (atualLine.status === "deleted" && !handleButtonExcluded) {
                        addLine = false
                    }

                    if (selectedStatusFilter.length > 0 && addLine) {
                        const validateSearch = selectedStatusFilter.findIndex((filter) => filter.code === atualLine.status)
                        if (validateSearch === -1) {
                            addLine = false
                        }
                    }

                    if (selectedFunnelFilter.length > 0 && addLine) {
                        const validateSearch = selectedFunnelFilter.findIndex((filter) => filter.code === atualLine.funnel_level)
                        if (validateSearch === -1) {
                            addLine = false
                        }
                    }

                    let newAtualLineGetTime = new Date(
                        new Date(atualLine.created_at).getFullYear(),
                        new Date(atualLine.created_at).getMonth(),
                        new Date(atualLine.created_at).getDate()
                    )

                    if (dateInit !== "" && addLine) {
                        let newDateInitGetTime = new Date(new Date(dateInit).getFullYear(), new Date(dateInit).getMonth(), new Date(dateInit).getDate())
                        if (newAtualLineGetTime.getTime() < newDateInitGetTime.getTime()) {
                            addLine = false
                        } else {
                        }
                    }

                    if (dateFinal !== "" && addLine) {
                        let newDateFinalGetTime = new Date(new Date(dateFinal).getFullYear(), new Date(dateFinal).getMonth(), new Date(dateFinal).getDate())
                        if (newAtualLineGetTime.getTime() > newDateFinalGetTime.getTime()) {
                            addLine = false
                        } else {
                        }
                    }

                    if (handleButtonNewOpportunities) {
                        if (atualLine.new_on_funnel !== true) {
                            addLine = false
                        }
                    }

                    if (handleViewLate) {
                        if (atualLine.late !== true) {
                            addLine = false
                        }
                    }

                    if (selectedInterestFilter.length > 0 && addLine) {
                        let validate: any = []
                        atualLine.company.interests.forEach((interest: any) => {
                            const findInterest = selectedInterestFilter.findIndex((filter) => filter.code === interest.id)
                            validate.push(findInterest)
                        })
                        const validateSearch = validate.findIndex((validation: any) => validation === 0 || validation === 1)
                        if (validateSearch === -1) {
                            addLine = false
                        }
                    }
                    if (selectedSellerFilter.length > 0 && addLine) {
                        const validateSearch = selectedSellerFilter.findIndex((filter) => filter.code === atualLine.user.id)
                        if (validateSearch === -1) {
                            addLine = false
                        }
                    }
                    if (selectedMediaFilter.length > 0 && addLine) {
                        const validateMedia = selectedMediaFilter.findIndex((filter) => filter.code === atualLine.media.id)
                        if (validateMedia === -1) {
                            addLine = false
                        }
                    }
                    if (selectedPriorityFilter.length > 0 && addLine) {
                        const validateSearch = selectedPriorityFilter.findIndex((filter) => filter.code === atualLine.qualification)
                        if (validateSearch === -1) {
                            addLine = false
                        }
                    }

                    if (selectedStageFilter.length > 0 && addLine) {
                        const validateSearch = selectedStageFilter.findIndex((filter) => filter.code === atualLine.funnelStage.id)
                        if (validateSearch === -1) {
                            addLine = false
                        }
                    }

                    if (selectedStateFilter.length > 0 && addLine) {
                        const validateSearch = selectedStateFilter.findIndex((filter) => filter.code === atualLine.company.state)
                        if (validateSearch === -1) {
                            addLine = false
                        }
                    }

                    if (!(advancedSearchFilter === "") && addLine) {
                        addLine = false

                        var word = advancedSearchFilter.toLowerCase().split(" ") || ""
                        if (!atualLine.user.name) atualLine.user.name = ""
                        if (!atualLine.company.name) atualLine.company.name = ""
                        if (!atualLine.company.city) atualLine.company.city = ""
                        if (!atualLine.number) atualLine.number = ""
                        if (!atualLine.id) atualLine.id = ""
                        for (var oneWord in word) {
                            oneWord = oneWord.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

                            if (
                                !atualLine.user.name.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                                !("000000" + atualLine.number.toString()).slice(-6).includes(advancedSearchFilter) &&
                                !atualLine.name.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                                !atualLine.company.name.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                                !atualLine.company.city.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                                !atualLine.id.toLowerCase().includes(advancedSearchFilter.toLowerCase())
                            ) {
                                addLine = false
                                break
                            } else {
                                addLine = true
                            }
                        }
                    }

                    if (addLine) {
                        listOpportunitiesFiltered.push(atualLine)
                    }
                }
            }
            auxOpportunities = listOpportunitiesFiltered
            setAuxOpportunities(listOpportunitiesFiltered)
        } else {
            auxOpportunities = opportunities
            setAuxOpportunities(opportunities)
        }
        setTimeout(() => {
            setTooltipCleanFilter(false)
            setLoadingCleanFilters(false)
            setLoadingSkeletonFiltering(false)
        }, 300)
    }

    const autoFocusAndCleanInputSearchBar = () => {
        var hh = document.getElementById("autofocs")
        setTimeout(() => {
            hh?.focus()
        }, 500)
    }

    const setAnotationProp = (prop: any) => {
        setAnotation(prop)
    }

    const cadastralSituation = (rowData: any) => {
        return (
            <Tag
                className="p-mr-2"
                severity={rowData.cadastral_situation === "Ativa" ? "success" : "warning"}
                style={{ fontSize: "0.9rem" }}
                value={rowData.cadastral_situation}
            />
        )
    }

    const imageBodyTemplate = (rowData: any) => {
        const handleClick = () => {
            setDisplayDialogAccountableUserInformationsAvatar(true)
            setOpportunity(rowData)
            if (!(users.length > 0)) {
                allUsers()
            }
        }

        return (
            <div className="p-d-flex">
                <div>
                    <a href="#" onClick={handleClick}>
                        <Avatar
                            icon={rowData.user.avatar_url === null ? "pi pi-user" : ""}
                            image={rowData.user.avatar_url}
                            className="p-mr-2"
                            size="normal"
                            shape="circle"
                        />
                    </a>
                    <div className="p-text-nowrap p-text-truncate" style={{ width: "8rem" }}>
                        {rowData.user.name}
                    </div>
                </div>
            </div>
        )
    }

    //Rating
    const rating = (rowData: any) => {
        let findOpportunity = opportunities.findIndex((opportunity) => opportunity.id === rowData.id)

        return (
            <div className="centralizeFromDesktopRating">
                <Rating
                    value={opportunities[findOpportunity].qualification}
                    tooltip={replaceQualification(rowData.qualification)?.toUpperCase()}
                    tooltipOptions={{
                        position: "bottom",
                    }}
                    onChange={(e: any) => {
                        if (rowData.qualification !== e.value) {
                            //Se for null vai ser 0

                            if (e.value === null) {
                                e.value = 0
                            }

                            newValueRating = { value: e.value, id: rowData.id }
                            setNewValueRating(newValueRating)

                            setDisplayDialogConfirmChangeRating(true)
                        }
                    }}
                />
            </div>
        )
    }

    //Oportunity Details
    const informationNoTask = (rowData: any) => {
        return (
            <>
                <Button
                    icon="pi pi-thumbs-down"
                    tooltip={"Não há tarefas"}
                    tooltipOptions={{
                        position: "bottom",
                    }}
                    className=" p-button-rounded p-button-text "
                    onClick={() => {
                        opportunity = rowData
                        setOpportunity(rowData)
                        setDisplayDialogReturnTask(true)
                    }}
                />
            </>
        )
    }

    const informationYesTask = (rowData: any) => {
        return (
            <>
                <Button
                    icon={rowData.nextTask !== null ? replaceIconTypeForCardOpportunity(rowData.nextTask.type) : ""}
                    className=" p-button-rounded p-button-text "
                    tooltip={"Há tarefas"}
                    tooltipOptions={{
                        position: "bottom",
                    }}
                    onClick={() => {
                        setOpportunity(rowData)
                        showOpportunityDetail(rowData)
                        setDisplayBasicNextTaskMobile(true)
                    }}
                />
            </>
        )
    }

    //Alteration state
    const colorStatusOpportunity = (rowData: string) => {
        switch (rowData) {
            case "open":
                return "#03A9F4"
            case "gain":
                return "#689F38"
            case "loss":
                return "#D32F2F"
            case "stopped":
                return "#FBC02D"
            case "deleted":
                return "#607D8B"
            case "disqualified":
                return "#FF6400"
            default:
                return ""
        }
    }

    const colorExcludedCard = () => {
        return handleButtonExcluded ? "#607D8B" : ""
    }

    const colorLateCard = () => (handleViewLate ? "red" : "")

    const colorNewCardOpportunity = () => (handleButtonNewOpportunities ? "#22C55E" : "")

    const countOpportunity = (idFunnel: any) => {
        let countOpportunities = 0

        auxOpportunities.forEach((opportunityItem) => {
            if (opportunityItem.funnelStage.id === idFunnel && opportunityItem.status !== "deleted") {
                countOpportunities++
            }
        })

        return countOpportunities > 1 ? (
            <div style={{ fontSize: "0.8rem" }}>{countOpportunities} OPORTUNIDADES</div>
        ) : countOpportunities !== 0 ? (
            <div style={{ fontSize: "0.8rem" }}>{countOpportunities} OPORTUNIDADE</div>
        ) : null
    }

    const handleOnDragEnd = (result: any, id: any) => {
        if (result.destination) {
            const opportunityIndex = opportunities.findIndex((opportunity) => opportunity.id === result.draggableId)
            let idStage = opportunities[opportunityIndex].funnel_stages_id
            opportunities[opportunityIndex].funnel_stages_id = result.destination.droppableId

            setLoadingUpdateAsOpportunities(false)
            countOpportunity(result.draggableId)

            try {
                let header = {
                    headers: {
                        route: "update_opportunity_stage",
                    },
                }

                let auxUpdateStageFunnel = {
                    id: result.draggableId,
                    funnel_stages_id: result.destination.droppableId,
                }

                api.put("opportunities/stage", auxUpdateStageFunnel, header)
                    .then((res) => {})
                    .catch((error) => {
                        opportunities[opportunityIndex].funnel_stages_id = idStage
                        setLoadingUpdateAsOpportunities(false)
                        countOpportunity(result.draggableId)
                        filter()
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Editar !",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        }
        filter()
    }

    const countTotalValueOpportunities = () => {
        let totalOpportunitiesOfFilter = 0
        let totalValueOfFilter = 0
        auxOpportunities.map((filter) => {
            totalValueOfFilter += filter.totalValueMachines
            totalOpportunitiesOfFilter++
        })
        return (
            <>
                <div className="totalValuesInAllOportunities" style={{ marginBottom: "0.8rem" }}>
                    <div className="p-d-flex" style={{ marginTop: "0.5rem", marginBottom: "-0.2rem", fontWeight: "500" }}>
                        <div>
                            <p style={{ color: "rgb(104, 159, 56)" }}>Valor Total: &nbsp;</p>
                        </div>
                        <div>
                            {totalValueOfFilter.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                            })}
                        </div>
                    </div>
                    <div className="p-d-flex" style={{ fontWeight: "500" }}>
                        <div>
                            <p style={{ color: "rgb(104, 159, 56)" }}> Total de oportunidades: &nbsp;</p>
                        </div>
                        <div>{totalOpportunitiesOfFilter}</div>
                    </div>
                </div>
            </>
        )
    }

    //Requisitions
    const handleUpdateAsOpportunities = () => {
        allOpportunity()
        setLoadingUpdateAsOpportunities(true)
        setAuxOpportunities(auxOpportunities)
        setTimeout(() => {
            setLoadingUpdateAsOpportunities(false)
        }, 1000)
    }

    const onLoadingCleanFilters = () => {
        dateInit = ""
        setDateInit("")
        dateFinal = ""
        setDateFinal("")
        setSelectedSellerFilter([])
        selectedSellerFilter = []
        setSelectedPriorityFilter([])
        selectedPriorityFilter = []
        setSelectedStatusFilter([])
        selectedStatusFilter = []
        setSelectedStageFilter([])
        selectedStageFilter = []
        setSelectedStateFilter([])
        selectedStateFilter = []
        setSelectedInterestFilter([])
        selectedInterestFilter = []
        setSelectedMediaFilter([])
        selectedMediaFilter = []
        setHandleButtonNewOpportunities(false)
        handleButtonNewOpportunities = false
        setHandleViewLate(false)
        handleViewLate = false
        setHandleButtonExcluded(false)
        handleButtonExcluded = false
        setAdvancedSearchFilter("")
        advancedSearchFilter = ""
        localStorage.removeItem("@esquadros:filterOpportunity")
        setLoadingCleanFilters(true)

        filter()
    }

    const changeAvatarImage = (opportunity: any) => {
        return (
            <div>
                {opportunity.company.picture_url === null ? (
                    <div className="p-d-flex p-jc-center">
                        <div>
                            <Avatar
                                icon={opportunity.company.picture_url === null ? "pi pi-user" : ""}
                                image={opportunity.company.picture_url}
                                className="p-avatar-xl p-mr-2"
                                shape="circle"
                            />
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <br />
                <div className="p-d-flex p-jc-center">
                    <a href={`/crm/empresa-cliente/${opportunity.company.id}/${`changeCompanyAvatar`}`} target="_blank" rel="noreferrer">
                        <Button className="p-button-rounded p-button-text" style={{ fontSize: "0.8rem" }}>
                            {opportunity.company.picture_url === null ? "ADICIONAR AVATAR" : "ALTERAR AVATAR"} &nbsp;
                            <i className="pi pi-pencil" />
                        </Button>
                    </a>
                </div>
            </div>
        )
    }

    const changeAccountableOpportunity = (opportunity: any) => {
        return (
            <div>
                <div>
                    <Dropdown
                        value={changeSelectedAccountable}
                        options={users}
                        onChange={(e: any) => setChangeSelectedAccountable(e.value)}
                        optionLabel="name"
                        placeholder="Usuários"
                    />
                </div>
                <br />
                <div>
                    <Button
                        label="Salvar"
                        aria-label="Submit"
                        onClick={() => {
                            updateAccountableUser(opportunity, changeSelectedAccountable)
                        }}
                    />
                </div>
            </div>
        )
    }

    const dialogFuncMapMobile = {
        displayBasic: setDisplayBasicMobile,
    }

    const dialogFuncMapDetails = {
        displayPosition: setDisplayPositionDialogInformation,
    }

    const replyMessageFooter = (
        <>
            <Button
                label="Não"
                icon="pi pi-times"
                className="p-button-danger p-button-text"
                onClick={() => {
                    onHide()
                    date1 = new Date()
                    setDate1(date1)
                }}
            />
            <Button
                label="Sim"
                icon="pi pi-check"
                className="p-button-success p-button-text"
                loading={loadingReturnTask}
                onClick={onSubmitHandleCreateReplyTask}
            />
        </>
    )

    const changeFunnelFooter = (
        <>
            <Button
                label="Não"
                icon="pi pi-times"
                className="p-button-danger p-button-text"
                onClick={() => {
                    setDisplayDialogChangeFunnel(false)
                    setStage({} as any)
                }}
            />
            <Button label="Sim" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingReturnTask} onClick={onChangeFunnel} />
        </>
    )

    const consciousnessLevelFooter = (
        <>
            <Button
                label="Não"
                icon="pi pi-times"
                className="p-button-danger p-button-text"
                onClick={() => {
                    setDisplayDialogChangeFunnel(false)
                    setStage({} as any)
                }}
            />
            <Button
                label="Sim"
                icon="pi pi-check"
                className="p-button-success p-button-text"
                loading={loadingConsciousnessLevel}
                onClick={onChangeConsciousnessLevel}
            />
        </>
    )

    const listWorkViewFilter = [
        { name: "PADRÃO", code: 1 },
        { name: "LISTAGEM", code: 2 },
        { name: "POR TEMPERATURA", code: 3 },
    ]

    const filterByQualification = (qualification: number) => {
        return (
            <div className="opportunityContent_CardsInCard_Scroll p-d-flex p-jc-center">
                <div className="">
                    {auxOpportunities.map((opportunityItem, indexOpportunityItem) => {
                        const avatar = opportunityItem.user.avatar_url
                        if (opportunityItem.qualification === qualification) {
                            return (
                                <div
                                    className="cardOpportunity"
                                    key={indexOpportunityItem}
                                    style={{
                                        boxShadow: `${opportunityItem.late === true ? "0 8px 8px -0.72rem red" : ""}`,
                                        opacity: `${opportunityItem.status === "deleted" ? "0.6" : "1"}`,
                                        marginBottom: "0.5rem",
                                    }}
                                >
                                    <div className="p-d-flex" style={{ width: "100%", height: "11rem" }}>
                                        <div
                                            className="colorStatusOpportunityXX"
                                            style={{
                                                width: "1.5%",
                                                height: "auto",
                                                backgroundColor: `${colorStatusOpportunity(opportunityItem.status)}`,
                                            }}
                                        />

                                        <div
                                            style={{
                                                width: "98.5%",
                                                height: "auto",
                                                position: "relative",
                                            }}
                                        >
                                            <div style={{ marginLeft: "0.3rem", marginTop: "0.3rem" }}>
                                                <div className="p-d-flex">
                                                    <div className="p-mr-2">
                                                        <a
                                                            href="#"
                                                            onClick={() => {
                                                                setDisplayDialogCompanyInformationsAvatar(true)
                                                                opportunity = opportunityItem
                                                                setOpportunity(opportunityItem)
                                                            }}
                                                        >
                                                            <Avatar
                                                                image={avatar}
                                                                className="p-mr-2"
                                                                style={{
                                                                    marginTop: "0.7rem",
                                                                    color: "#ffffff",
                                                                    border: "white",
                                                                }}
                                                                shape="circle"
                                                            />
                                                        </a>
                                                    </div>
                                                    <a
                                                        href={`/crm/leads/${opportunityItem.id}`}
                                                        style={{
                                                            fontWeight: "600",
                                                            fontSize: "1.3rem",
                                                            color: "rgba(41, 50, 65, 0.8)",
                                                            marginBottom: "0rem",
                                                        }}
                                                    >
                                                        <div className="p-mr-2">
                                                            <div className="p-d-flex p-jc-between">
                                                                <div className="p-d-flex p-jc-start p-ai-center">
                                                                    <p
                                                                        style={{
                                                                            fontSize: "0.8rem",
                                                                            color: `${colorStatusOpportunity(opportunityItem.status)}`,
                                                                        }}
                                                                    >
                                                                        <strong>{("000000" + opportunityItem.number).slice(-6)}</strong>
                                                                    </p>
                                                                </div>

                                                                {opportunityItem.new === true || opportunityItem.new_on_funnel === true ? (
                                                                    <div
                                                                        className="p-d-flex p-jc-end p-ai-center"
                                                                        style={{
                                                                            color: "#689F38",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                fontSize: "0.7rem",
                                                                                fontWeight: "600",
                                                                            }}
                                                                        >
                                                                            NOVA OPORTUNIDADE!&nbsp;
                                                                        </div>

                                                                        <i
                                                                            className="pi pi-star-fill"
                                                                            style={{
                                                                                fontSize: "0.7rem",
                                                                            }}
                                                                        ></i>
                                                                    </div>
                                                                ) : (
                                                                    <div></div>
                                                                )}
                                                            </div>

                                                            <p
                                                                className="p-text-nowrap p-text-truncate"
                                                                style={{
                                                                    width: "15rem",
                                                                }}
                                                            >
                                                                {opportunityItem.name}
                                                            </p>

                                                            <div
                                                                className="p-text-nowrap p-text-truncate"
                                                                style={{
                                                                    marginTop: "-1rem",
                                                                    width: "13rem",
                                                                    fontSize: "1rem",
                                                                }}
                                                            >
                                                                {opportunityItem.company.name}
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="p-d-flex p-jc-center" style={{ margin: "0.3rem", height: "3rem" }}>
                                                <div className="p-d-flex p-as-center">{rating(opportunityItem)}</div>
                                            </div>

                                            <div>
                                                <div className="p-d-flex p-jc-between">
                                                    <div
                                                        className="p-d-flex  p-as-center"
                                                        style={{
                                                            position: "absolute",
                                                            bottom: "0",
                                                            marginLeft: "0.4rem",
                                                        }}
                                                    >
                                                        <div
                                                            className="p-mb-2 p-as-center"
                                                            style={{
                                                                width: "0.6rem",
                                                                height: "0.6rem",
                                                                borderRadius: "90%",
                                                                backgroundColor: "#03A9F4",
                                                                marginRight: "0.2em",
                                                                marginLeft: "0.5rem",
                                                            }}
                                                        />
                                                        <div className="p-mb-2">
                                                            {opportunityItem.totalValueMachines !== null
                                                                ? opportunityItem.totalValueMachines.toLocaleString("pt-br", {
                                                                      style: "currency",
                                                                      currency: "BRL",
                                                                  })
                                                                : ""}
                                                        </div>
                                                    </div>
                                                    <div className="adjustIconsForMobile" style={{ position: "absolute", bottom: "0", right: "0" }}>
                                                        {opportunityItem.nextTask !== null
                                                            ? informationYesTask(opportunityItem)
                                                            : informationNoTask(opportunityItem)}

                                                        <Button
                                                            icon="pi pi-exclamation-circle"
                                                            className=" p-button-rounded p-button-text "
                                                            onClick={() => {
                                                                onClickDialogOpportunityInformation("displayPosition", "bottom")
                                                                showOpportunityDetail(opportunityItem)
                                                                setOpportunity(opportunityItem)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        )
    }

    const replaceFunnelLevel = (rowData: any) => {
        switch (rowData) {
            case "bottom":
                return "Fundo"

            case "middle":
                return "Meio"

            case "top":
                return "Topo"
            default:
                return "-"
        }
    }

    async function JSONToCSVConvertor(data: OpportunityDataInterface[]) {
        let header = {
            headers: {
                route: "list_contacts",
            },
        }

        const res = await api.get("contacts", header)

        let contacts: ContactInterface[] = res.data

        const rows: any[] = []

        data.map((data) => {
            let emailsToCompany: string[] = []
            let phoneToCompany: string[] = []
            let companyInterests: string[] = []

            companies.map((company) => {
                if (company.id === data.company.id) {
                    if (company.interests.length > 0) {
                        company.interests.map((interest) => {
                            companyInterests.push(interest.name)
                        })
                    } else if (company.interests.length === 0) {
                        companyInterests.push("NENHUM INTERESSE SELECIONADO")
                    }
                }
            })

            contacts.map((contacts) => {
                if (contacts.company?.id === data.company.id) {
                    if (contacts.phones !== null && contacts.phones?.length > 0) {
                        contacts.phones.map((phone) => {
                            phoneToCompany.push(" " + phone.number + " ")
                        })
                    }

                    if (contacts.emails !== null && contacts.emails?.length > 0) {
                        contacts.emails.map((email) => {
                            emailsToCompany.push(" " + email.email + " ")
                        })
                    }
                }
            })

            let auxRow = {
                company_name: data.company.name,
                cnpj: data.company.cnpj,
                opportunity_user: data.user.name,

                email_contact: emailsToCompany,
                phones_contact: phoneToCompany,
                company_interests: companyInterests || "",

                qualification: `${replaceQualification(data.qualification)}`,
                status: `${replaceStatus(data.status)}`,
                delayed_days: `${Math.round(data.delayedDays)} DIAS`,
                funnelStage: data.funnelStage.name,
                utm_source: data.utm_source ? data.utm_source : "NÃO INFORMADO",
                utm_medium: data.utm_medium ? data.utm_medium : "NÃO INFORMADO",
                utm_campaign: data.utm_campaign ? data.utm_campaign : "NÃO INFORMADO",
                utm_term: data.utm_term ? data.utm_term : "NÃO INFORMADO",
                utm_content: data.utm_content ? data.utm_content : "NÃO INFORMADO",
                media: data.media.name,

                created_at: `${new Date(data.created_at).toLocaleDateString("pt-br")}`,
                updated_at: `${new Date(data.updated_at).toLocaleDateString("pt-br")}`,
                totalValueMachines: data.totalValueMachines.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                }),
            }

            rows.push(auxRow)
        })

        const columns = [
            { field: "company_name", headerName: "NOME DA EMPRESA", width: 5000 },
            { field: "cnpj", headerName: "CNPJ", width: 5000 },
            { field: "opportunity_user", headerName: "VENDEDOR", width: 5000 },
            { field: "email_contact", headerName: "EMAIL", width: 5000 },
            { field: "phones_contact", headerName: "TELEFONE", width: 5000 },
            { field: "company_interests", headerName: "INTERESSES", width: 5000 },
            { field: "qualification", headerName: "QUALIFICAÇÃO", width: 5000 },
            { field: "status", headerName: "STATUS", width: 5000 },
            { field: "delayed_days", headerName: "ATRASO", width: 5000 },
            { field: "funnelStage", headerName: "ETAPA DE ATENDIMENTO", width: 5000 },
            { field: "utm_source", headerName: "ORIGEM", width: 5000 },
            { field: "utm_medium", headerName: "MÍDIA", width: 5000 },
            { field: "utm_campaign", headerName: "CAMPANHA", width: 5000 },
            { field: "utm_term", headerName: "TERMOS", width: 5000 },
            { field: "utm_content", headerName: "CONTEÚDO", width: 5000 },
            { field: "media", headerName: "FONTE", width: 5000 },
            { field: "created_at", headerName: "DATA DE CRIAÇÃO", width: 5000 },
            { field: "updated_at", headerName: "DATA DE ATUALIZAÇÃO", width: 5000 },
            { field: "totalValueMachines", headerName: "VALOR", width: 5000 },
        ]

        var rowData = typeof rows != "object" ? JSON.parse(rows) : rows
        var colData = typeof columns != "object" ? JSON.parse(columns) : columns

        var CSV = "sep=," + "\r\n"

        // 1st loop is to extract each row
        for (var i = 0; i < colData.length; i++) {
            var row = ""

            row += '"' + colData[i].headerName + '",'

            row.slice(0, row.length - 1)

            //add a line break after each row
            CSV += row
        }

        CSV += "\r"

        //1st loop is to extract each row
        for (var i = 0; i < rowData.length; i++) {
            var row = ""

            //2nd loop will extract each column and convert it in string comma-seprated
            for (var index in rowData[i]) {
                row += '"' + rowData[i][index] + '",'
            }

            row.slice(0, row.length - 1)

            //add a line break after each row
            CSV += row + "\r\n"
        }

        if (CSV === "") {
            alert("Invalid data")
            return
        }

        var fileName = `PLANILHA_OPORTUNIDADES_CRM_${new Date().toLocaleDateString()}`

        var uri = "data:text/csv;charset=utf-8," + escape(CSV)

        var link = document.createElement("a")
        link.href = uri

        //set the visibility hidden so it will not effect on your web-layout
        link.download = fileName + ".xls"

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const toXML = (data: OpportunityDataInterface[]) => {
        JSONToCSVConvertor(data)
    }

    const filterFindNewOpportunities = () => {
        let valueOfcountOfNewOpportunities = 0
        let findNewOpportunities = opportunities.map((opportunities) => {
            if (opportunities.new === true && funnel.name === "QUALIFICAÇÃO") {
                valueOfcountOfNewOpportunities++
            }
        })
        setCountOfNewOpportunities((state) => valueOfcountOfNewOpportunities)
    }

    const searchOpportunityById = (id: string) => {
        opportunities.forEach((opportunity) => {
            if (opportunity.id === id) {
                advancedSearchFilter = opportunity.number.toString()
                setAdvancedSearchFilter(advancedSearchFilter)
            }
        })

        filter()
    }

    const onRightSidePanelTasks = () => {
        document.getElementById("taskSideRightPanel")!.style.transform = "translate3d(0px, 0px, 0px)"
        document.getElementById("taskSideRightPanel")!.style.padding = "1rem"
        nameOfClassTaksButtonFilter = "pi pi-arrow-right"
        setNameOfClassTaksButtonFilter(nameOfClassTaksButtonFilter)
    }

    const closeRightSidePanelTasks = () => {
        document.getElementById("taskSideRightPanel")!.style.transform = "translate3d(100%, 0px, 0px)"
        document.getElementById("taskSideRightPanel")!.style.padding = "none"
        nameOfClassTaksButtonFilter = "pi pi-arrow-left"
        setNameOfClassTaksButtonFilter(nameOfClassTaksButtonFilter)
    }

    //localStorage Filters
    let dataLocalStorage: any
    let widthWindow = window.innerWidth

    useEffect(() => {
        if (localStorage.getItem("@esquadros:filterOpportunity") !== null) {
            dataLocalStorage = JSON.parse(localStorage.getItem("@esquadros:filterOpportunity") || "") || []
            if (
                dataLocalStorage.handleButtonExcluded ||
                dataLocalStorage.handleViewLate ||
                dataLocalStorage.selectedInterestFilter.length > 0 ||
                dataLocalStorage.selectedSellerFilter.length > 0 ||
                dataLocalStorage.selectedPriorityFilter.length > 0 ||
                dataLocalStorage.selectedStatusFilter.length > 0 ||
                dataLocalStorage.selectedStageFilter.length > 0 ||
                dataLocalStorage.selectedStateFilter.length > 0 ||
                dataLocalStorage.selectedMediaFilter.length > 0 ||
                dataLocalStorage.workViewFilter !== null ||
                !(advancedSearchFilter === "")
            ) {
                handleButtonExcluded = dataLocalStorage.handleButtonExcluded
                setHandleButtonExcluded(dataLocalStorage.handleButtonExcluded)
                handleViewLate = dataLocalStorage.handleViewLate
                setHandleViewLate(dataLocalStorage.handleViewLate)
                dateInit = dataLocalStorage.dateInit
                setDateInit(dataLocalStorage.dateInit)
                selectedInterestFilter = dataLocalStorage.selectedInterestFilter
                setSelectedInterestFilter(dataLocalStorage.selectedInterestFilter)
                selectedSellerFilter = dataLocalStorage.selectedSellerFilter
                setSelectedSellerFilter(dataLocalStorage.selectedSellerFilter)
                selectedPriorityFilter = dataLocalStorage.selectedPriorityFilter
                setSelectedPriorityFilter(dataLocalStorage.selectedPriorityFilter)
                selectedStatusFilter = dataLocalStorage.selectedStatusFilter
                setSelectedStatusFilter(dataLocalStorage.selectedStatusFilter)
                selectedStageFilter = dataLocalStorage.selectedStageFilter
                setSelectedStageFilter(dataLocalStorage.selectedStageFilter)
                selectedStateFilter = dataLocalStorage.selectedStateFilter
                setSelectedStateFilter(dataLocalStorage.selectedStateFilter)
                selectedMediaFilter = dataLocalStorage.selectedMediaFilter
                setSelectedMediaFilter(dataLocalStorage.selectedMediaFilter)
                workViewFilter = dataLocalStorage.workViewFilter
                setWorkViewFilter(dataLocalStorage.workViewFilter)
                handleButtonNewOpportunities = dataLocalStorage.handleButtonNewOpportunities
                setHandleButtonNewOpportunities(dataLocalStorage.handleButtonNewOpportunities)
                advancedSearchFilter = dataLocalStorage.advancedSearchFilter
                setAdvancedSearchFilter(dataLocalStorage.advancedSearchFilter)
                funnel = dataLocalStorage.funnel
                setFunnel(dataLocalStorage.funnel)
            }
        }
    }, [])
    //localStorage Filters

    useEffect(() => {
        changeAccountableOpportunity(opportunity)
        allFunnels()
        allOpportunity()

        if (localStorage.getItem("@esquadros:filterOpportunity") === null || isEmptyObj(dataLocalStorage.workViewFilter)) {
            setWorkViewFilter({ name: "LISTAGEM", code: 2 })
        }

        setLoadingUpdateAsOpportunities(false)

        window.addEventListener("resize", function () {
            widthWindow = window.innerWidth

            if (widthWindow >= 2133) {
                setNameOFclassCentralizeFromDesktop(" centralizeFromDesktop p-d-flex p-jc-center ")
            } else {
                setNameOFclassCentralizeFromDesktop(" centralizeFromDesktop p-d-flex ")
            }
        })
    }, [])

    useEffect(() => {
        const interestList: any[] = []
        const mediaList: any[] = []
        const sellerList: any[] = []
        const statusList: any[] = []
        const stageList: any[] = []
        const stateList: any[] = []
        const stageFunnels: any[] = []

        const funnelLevelsSet = new Set()

        auxOpportunitiesTwo.forEach((item) => {
            const findSeller = sellerList.find((user) => user.code === item.user.id)
            const findStatus = statusList.find((user) => user.code === item.status)
            const findStage = stageList.find((user) => user.code === item.funnelStage.id)
            const findState = stateList.find((user) => user.code === item.company.state)
            const findMedia = mediaList.find((user) => user.code === item.media.id)
            const findFunnel = stageFunnels.find((user) => user.code === item.funnelStage.id)

            item.company.interests.map((interest) => {
                const findInterest = interestList.find((user) => user.code === interest.id)
                if (!findInterest) {
                    interestList.push({ name: interest.name, code: interest.id })
                }
            })

            if (!findMedia) {
                mediaList.push({ name: item.media.name, code: item.media.id })
            }

            if (!findSeller) {
                sellerList.push({ name: item.user.name, code: item.user.id })
            }

            if (!findStatus) {
                if (item.status === "open") statusList.push({ name: item.status.replace("open", "ABERTA"), code: item.status })
                if (item.status === "gain") statusList.push({ name: item.status.replace("gain", "GANHA"), code: item.status })
                if (item.status === "loss") statusList.push({ name: item.status.replace("loss", "PERDIDO"), code: item.status })
                if (item.status === "disqualified") statusList.push({ name: item.status.replace("disqualified", "DESQUALIFICADO"), code: item.status })
                if (item.status === "stopped") statusList.push({ name: item.status.replace("stopped", "SUSPENSO"), code: item.status })
                if (item.status === "deleted") statusList.push({ name: item.status.replace("deleted", "EXCLUÍDA"), code: item.status })
            }

            if (!findFunnel && item.funnel_level && !funnelLevelsSet.has(item.funnel_level)) {
                let funnelName = ""
                if (item.funnel_level === "bottom") funnelName = "FUNDO"
                else if (item.funnel_level === "middle") funnelName = "MEIO"
                else if (item.funnel_level === "top") funnelName = "TOPO"

                if (funnelName !== "") {
                    stageFunnels.push({ name: funnelName, code: item.funnel_level })
                    // Adicionar o código ao conjunto para evitar duplicatas
                    funnelLevelsSet.add(item.funnel_level)
                }
            }

            if (!findStage) {
                stageList.push({ name: item.funnelStage.name.toUpperCase(), code: item.funnelStage.id, sequence: item.funnelStage.sequence })
                stageList.sort((a, b) => {
                    return a.sequence - b.sequence
                })
            }
            if (!findState) {
                if (item.company.state !== null) {
                    stateList.push({ name: item.company.state.toUpperCase(), code: item.company.state })
                }
            }
        })
        setListOfInterest(interestList)
        setListOfMedia(mediaList)
        setListOfSellers(sellerList)
        setListOfStatus(statusList)
        setListOfStage(stageList)
        setListOfFunnel(stageFunnels)
        setListOfState(stateList)
        filterFindNewOpportunities()
    }, [auxOpportunities])

    useEffect(() => {
        if (!isEmptyObj(opportunity)) {
            opportunities.forEach((op) => {
                if (op.id === opportunity.id) {
                    setOpportunity(op)
                    setLoadingButtonChangeAvatarCompany(false)
                }
            })
        }
        filter()
    }, [opportunities])

    return (
        <App>
            <Toast ref={toast} style={{ zIndex: "9999" }} />
            {loadingSkeletonTotalPage ? (
                <div>
                    <div className="p-d-flex">
                        <Skeleton className="mb-2 p-mr-4" width="80vw" height="10vh" />
                        <Skeleton className="mb-2" width="20vw" height="10vh" />
                    </div>
                    <br />
                    <Skeleton className="mb-2" height={`${widthWindow <= 1280 ? "66vh" : "75vh"}`}></Skeleton>
                </div>
            ) : (
                <div className="desktopOpportunityPage p-d-flex p-jc-center p-ai-center">
                    <div className="desktopOpportunityContent">
                        <>
                            <FilterPropsInterface
                                listOfFunnel={listOfFunnel}
                                selectedFunnelFilter={selectedFunnelFilter}
                                setSelectedFilter={(e: any) => {
                                    selectedFunnelFilter = e
                                    setSelectedFunnelFilter(selectedFunnelFilter)
                                }}
                                allCompanies={() => allCompanies()}
                                auxOpportunities={auxOpportunities}
                                dateInit={dateInit}
                                countOfNewOpportunities={countOfNewOpportunities}
                                setDateInit={(e: any) => {
                                    dateInit = e
                                    setDateInit(dateInit)
                                }}
                                filter={() => filter()}
                                dateFinal={dateFinal}
                                setDateFinal={(e: any) => {
                                    dateFinal = e
                                    setDateFinal(dateFinal)
                                }}
                                selectedSellerFilter={selectedSellerFilter}
                                setSelectedSellerFilter={(e: any) => {
                                    selectedSellerFilter = e
                                    setSelectedSellerFilter(selectedSellerFilter)
                                }}
                                listOfSellers={listOfSellers}
                                selectedPriorityFilter={selectedPriorityFilter}
                                setSelectedPriorityFilter={(e: any) => {
                                    selectedPriorityFilter = e
                                    setSelectedPriorityFilter(selectedPriorityFilter)
                                }}
                                selectionPriorities={selectionPriorities}
                                selectedStatusFilter={selectedStatusFilter}
                                setSelectedStatusFilter={(e: any) => {
                                    selectedStatusFilter = e
                                    setSelectedStatusFilter(selectedStatusFilter)
                                }}
                                listOfStatus={listOfStatus}
                                selectedStageFilter={selectedStageFilter}
                                listOfStage={listOfStage}
                                setSelectedStageFilter={(e: any) => {
                                    selectedStageFilter = e
                                    setSelectedStageFilter(selectedStageFilter)
                                }}
                                selectedStateFilter={selectedStateFilter}
                                setSelectedStateFilter={(e: any) => {
                                    selectedStateFilter = e
                                    setSelectedStateFilter(selectedStateFilter)
                                }}
                                listOfState={listOfState}
                                selectedMediaFilter={selectedMediaFilter}
                                setSelectedMediaFilter={(e: any) => {
                                    selectedMediaFilter = e
                                    setSelectedMediaFilter(selectedMediaFilter)
                                }}
                                listOfMedia={listOfMedia}
                                selectedInterestFilter={selectedInterestFilter}
                                listOfInterest={listOfInterest}
                                advancedSearchFilter={advancedSearchFilter}
                                setAdvancedSearchFilter={(e: any) => {
                                    advancedSearchFilter = e
                                    setAdvancedSearchFilter(advancedSearchFilter)
                                }}
                                handleUpdateAsOpportunities={() => handleUpdateAsOpportunities()}
                                loadingUpdateAsOpportunities={loadingUpdateAsOpportunities}
                                colorExcludedCard={() => colorExcludedCard()}
                                loadingExcludedFilter={loadingExcludedFilter}
                                handleButtonExcluded={handleButtonExcluded}
                                setHandleButtonExcluded={(e: any) => {
                                    handleButtonExcluded = e
                                    setHandleButtonExcluded(handleButtonExcluded)
                                }}
                                colorLateCard={() => colorLateCard()}
                                handleViewLate={handleViewLate}
                                setHandleViewLate={(e: any) => {
                                    handleViewLate = e
                                    setHandleViewLate(handleViewLate)
                                }}
                                colorNewCardOpportunity={() => colorNewCardOpportunity()}
                                handleButtonNewOpportunities={handleButtonNewOpportunities}
                                setHandleButtonNewOpportunities={(e: any) => {
                                    handleButtonNewOpportunities = e
                                    setHandleButtonNewOpportunities(handleButtonNewOpportunities)
                                }}
                                setTooltipCleanFilter={(e: any) => {
                                    tooltipCleanFilter = e
                                    setTooltipCleanFilter(tooltipCleanFilter)
                                }}
                                loadingCleanFilters={loadingCleanFilters}
                                onLoadingCleanFilters={() => onLoadingCleanFilters()}
                                funnel={funnel}
                                setFunnel={(value: any) => {
                                    setLoadingSkeletonFiltering(true)
                                    funnel = value
                                    setFunnel(funnel)
                                }}
                                funnels={funnels}
                                workViewFilter={workViewFilter}
                                setWorkViewFilter={(value: any) => {
                                    setLoadingSkeletonFiltering(true)
                                    workViewFilter = value
                                    setWorkViewFilter(workViewFilter)
                                }}
                                listWorkViewFilter={listWorkViewFilter}
                                countTotalValueOpportunities={() => countTotalValueOpportunities()}
                                onClickMobileDialog={(e: any, x: any) => onClickMobileDialog(e, x)}
                                setSelectedInterestFilter={(e: any) => setSelectedInterestFilter((state) => e)}
                                setLoadingSkeletonFiltering={(e: any) => setLoadingSkeletonFiltering((state) => e)}
                                toXML={(e: any) => {
                                    setDisplayDialogJSONtoXML(true)
                                }}
                                dialogOfTasks={(e) => {
                                    if (e) {
                                        onRightSidePanelTasks()
                                    } else {
                                        closeRightSidePanelTasks()
                                    }

                                    // setDialogOfTasks(e)
                                }}
                                allCleanTasks={() => {
                                    if (!(cleanTasks.length > 0)) {
                                        allCleanTasks()
                                    }
                                }}
                                onTaskFilters={onTaskFilters}
                                setOnTaskFilters={(e) => {
                                    onTaskFilters = e
                                    setOnTaskFilters(onTaskFilters)
                                }}
                                nameOfClassTaksButtonFilter={nameOfClassTaksButtonFilter}
                            />
                        </>

                        {loadingSkeletonFiltering ? (
                            <div style={{ marginTop: "0.5rem" }}>
                                <Skeleton className="mb-2" height={`${widthWindow <= 1280 ? "66vh" : "75vh"}`}></Skeleton>
                            </div>
                        ) : (
                            <div className="workViewFilter" style={{ height: "100%", marginTop: "0.5rem" }}>
                                <Opportunities
                                    opportunities={opportunities}
                                    auxOpportunities={auxOpportunities}
                                    workViewFilter={workViewFilter}
                                    funnel={funnel}
                                    nameOFclassCentralizeFromDesktop={nameOFclassCentralizeFromDesktop}
                                    handleOnDragEnd={handleOnDragEnd}
                                    toast={toast}
                                    dt={dt}
                                    loadingList={loadingList}
                                    imageBodyTemplate={imageBodyTemplate}
                                    setDisplayDialogCompanyInformationsAvatar={(e: boolean) => setDisplayDialogCompanyInformationsAvatar(e)}
                                    setOpportunity={(e: any) => setOpportunity(e)}
                                    colorStatusOpportunity={(e: any) => colorStatusOpportunity(e)}
                                    replaceStatus={(e: any) => replaceStatus(e)}
                                    rating={rating}
                                    onClickDialogOpportunityInformation={(name: string, position: string) =>
                                        onClickDialogOpportunityInformation(name, position)
                                    }
                                    showOpportunityDetail={(e: any) => showOpportunityDetail(e)}
                                    informationYesTask={(e: any) => informationYesTask(e)}
                                    informationNoTask={(e: any) => informationNoTask(e)}
                                    setStage={(e: any) => setStage(e)}
                                    setDisplayDialogReturnTask={(e: boolean) => setDisplayDialogReturnTask(e)}
                                    setDisplayDialogChangeFunnel={(e: boolean) => setDisplayDialogChangeFunnel(e)}
                                    count={count}
                                    filterByQualification={(e: any) => filterByQualification(e)}
                                    countOpportunity={(e: any) => countOpportunity(e)}
                                    setDisplayFulfillmentStepDialogBox={setDisplayFulfillmentStepDialogBox}
                                    setFunnelStage={setFunnelStage}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}

            <TaskSideRightPanel
                loadingDialogOfTasks={loadingDialogOfTasks}
                closeRightSidePanelTasks={() => closeRightSidePanelTasks()}
                cleanTasks={cleanTasks}
                loadingList={loadingList}
                replaceIconTypeToTask={(e) => replaceIconTypeToTask(e)}
                replaceStatus={(e) => replaceStatus(e)}
                taskSelected={taskSelected}
                setTaskSelected={(e) => {
                    setTaskSelected(e)
                    searchOpportunityById(e.opportunity_id)
                }}
                onTaskFilters={onTaskFilters}
            />

            {/* Anotattion */}
            <Dialog
                visible={displayDialogBasicAnottation}
                style={{ width: "50rem" }}
                draggable={false}
                onHide={() => onHideAnottation()}
                modal
                className="p-fluid"
                header="CRIAR ANOTAÇÃO"
            >
                <div>
                    <>
                        <AnnotaionOpportunity
                            createAnotation={createAnotation}
                            setAnotation={setAnotationProp}
                            loadingCreateAnnotations={loadingCreateAnnotations}
                            anotation={anotation}
                        />
                    </>
                </div>
            </Dialog>

            {/* // Dialog to convert XML */}
            <Dialog visible={displayDialogJSONtoXML} style={{}} draggable={false} onHide={() => onHide()} modal className="p-fluid" header={opportunity.name}>
                <div>
                    <p>Deseja baixar os dados das {auxOpportunities.length} oportunidades selecionadas em XML?</p>
                    <div></div>
                    <div className="p-d-flex p-jc-center">
                        <Button label="Cancelar" className="p-button-danger p-mr-2" style={{ width: "10rem" }} onClick={() => onHide()} />
                        <Button
                            label="Baixar"
                            // loading={loadingButtonChangeRating}
                            className="p-button-success"
                            style={{ width: "10rem" }}
                            onClick={() => {
                                toXML(auxOpportunities)
                                onHide()
                            }}
                        />
                    </div>
                </div>
            </Dialog>

            {/* // Dialog change rating */}
            <Dialog
                visible={displayDialogConfirmChangeRating}
                style={{}}
                draggable={false}
                onHide={() => onHide()}
                modal
                className="p-fluid"
                header={opportunity.name}
            >
                <div>
                    <p>
                        Deseja alterar a qualificação da oportunidade de {replaceQualification(opportunity.qualification)} para
                        {replaceQualification((newValueRating as ValueChangeRating).value)}?
                    </p>
                    <div></div>
                    <div className="p-d-flex p-jc-center">
                        <Button label="Cancelar" className="p-button-danger p-mr-2" style={{ width: "10rem" }} onClick={() => onHide()} />
                        <Button
                            label="Continuar"
                            loading={loadingButtonChangeRating}
                            className="p-button-success"
                            style={{ width: "10rem" }}
                            onClick={() => {
                                setLoadingButtonChangeRating(true)
                                handleRating((newValueRating as ValueChangeRating).value, (newValueRating as ValueChangeRating).id)
                            }}
                        />
                    </div>
                </div>
            </Dialog>

            <Dialog
                visible={displayDialogReturnTask}
                draggable={false}
                onHide={() => {
                    onHide()
                    date1 = new Date()
                    setDate1(date1)
                }}
                modal
                className="p-fluid"
                header={`Retorno para a oportunidade - ${opportunity.name}`}
                footer={replyMessageFooter}
            >
                <div>
                    <br />
                    <div className="p-d-flex p-jc-center">
                        <Button
                            label="3 DIAS"
                            className="p-button-outlined p-button-secondary"
                            onClick={() => handleButtonClick(72)}
                            style={{ width: "10rem", margin: "0.8rem" }}
                        />
                        <Button
                            label="1 MÊS"
                            className="p-button-outlined p-button-secondary"
                            style={{ width: "10rem", margin: "0.8rem" }}
                            onClick={() => handleButtonClick(730)}
                        />
                    </div>
                    <div className="p-d-flex p-jc-center">
                        <Button
                            label="7 DIAS"
                            className="p-button-outlined p-button-secondary"
                            style={{ width: "10rem", margin: "0.8rem" }}
                            onClick={() => handleButtonClick(168)}
                        />
                        <Button
                            label="3 MESES"
                            className="p-button-outlined p-button-secondary"
                            style={{ width: "10rem", margin: "0.8rem" }}
                            onClick={() => handleButtonClick(2190)}
                        />
                    </div>
                    <div className="p-d-flex p-jc-center">
                        <Button
                            label="14 DIAS"
                            className="p-button-outlined p-button-secondary"
                            style={{ width: "10rem", margin: "0.8rem" }}
                            onClick={() => handleButtonClick(336)}
                        />
                        <Button
                            label="6 MESES"
                            className="p-button-outlined p-button-secondary"
                            style={{ width: "10rem", margin: "0.8rem" }}
                            onClick={() => handleButtonClick(4380)}
                        />
                    </div>
                    <br />
                    <div className="p-d-flex p-jc-center">
                        <Calendar
                            className="calendarTask p-mr-2 p-mb-1"
                            dateFormat="dd/mm/yy"
                            id="icon"
                            showTime
                            value={date1}
                            onMonthChange={() => ""}
                            onChange={(e) => setDate1(e.value)}
                            showIcon
                        />
                    </div>
                </div>
            </Dialog>

            <Dialog
                visible={displayDialogChangeFunnel}
                style={{
                    width: "35rem",
                }}
                draggable={false}
                onHide={() => onHide()}
                modal
                className="p-fluid"
                header={opportunity.name}
                footer={changeFunnelFooter}
                onShow={() => {
                    setStage(replaceFunnelLevel(opportunity.funnel_level))
                }}
            >
                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="funnel">Nível de Consciência</label>
                    <Dropdown
                        id="funnel"
                        value={stage}
                        options={stages}
                        onChange={(e) => {
                            stage = e.value
                            setStage(stage)
                        }}
                        placeholder="Selecione o Nível de Consciência"
                    />
                </div>
            </Dialog>

            {/* Display fulfillment step dialog box */}
            <Dialog
                visible={displayFulfillmentStepDialogBox}
                style={{ width: "35rem" }}
                draggable={false}
                onHide={() => onHide()}
                modal
                className="p-fluid"
                header={opportunity.name}
                footer={consciousnessLevelFooter}
            >
                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="funnel">Etapa de Atendimento</label>

                    <Dropdown
                        id="funnel"
                        value={funnelStage}
                        options={funnelStages}
                        optionLabel="name"
                        onChange={(e) => {
                            setFunnelStage(e.value)
                        }}
                        placeholder="Selecione a Etapa de Atendimento"
                    />
                </div>
            </Dialog>

            {/* // Dialog Avatar User */}
            {!isEmptyObj(opportunity) && (
                <Dialog
                    visible={displayDialogAccountableUserInformationsAvatar}
                    style={{}}
                    draggable={false}
                    onHide={() => onHide()}
                    modal
                    className="p-fluid"
                    header="RESPONSÁVEL"
                >
                    <div>
                        <div className="p-d-flex p-jc-center  p-mb-4">
                            {opportunity.user.avatar_url !== null ? (
                                <Image src={opportunity.user.avatar_url} alt="Image Text" width="200rem" height="auto" />
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="p-d-flex p-jc-center p-mb-3" style={{ fontSize: "1.3rem", fontWeight: "500" }}>
                            {opportunity.user.name !== null ? <>Nome: {opportunity.user.name}</> : ""}
                        </div>
                        <div className="p-d-flex p-jc-center p-mb-3" style={{ fontSize: "1.3rem", fontWeight: "500" }}>
                            {opportunity.user.email !== null ? <> Email: {opportunity.user.email} </> : ""}
                        </div>
                        <div className="p-d-flex p-jc-center" style={{ fontSize: "1.3rem", fontWeight: "500" }}>
                            {opportunity.user.phone !== null ? <> Telefone: {opportunity.user.phone} </> : ""}
                        </div>
                        <div></div>
                        <br />
                        <div>
                            <Button
                                label="Alterar responsável"
                                aria-label="Submit"
                                onClick={() => {
                                    setDisplayDialogAccountableUserInformationsAvatar(false)
                                    setDisplayDialogChangeAccountable(true)
                                }}
                            />
                        </div>
                    </div>
                </Dialog>
            )}

            <Dialog
                visible={displayDialogChangeAccountable}
                style={{ width: "30rem" }}
                draggable={false}
                onHide={() => onHide()}
                modal
                className="p-fluid"
                header="Responsável"
            >
                <div>{changeAccountableOpportunity(opportunity)}</div>
            </Dialog>

            {/* // Dialog Avatar Company */}
            {!isEmptyObj(opportunity) && (
                <Dialog
                    visible={displayDialogCompanyInformationsAvatar}
                    style={{ minWidth: "20rem" }}
                    draggable={false}
                    onHide={() => onHide()}
                    modal
                    className="p-fluid"
                    header="EMPRESA"
                >
                    <div>
                        <div className="p-d-flex p-jc-center  p-mb-4">
                            {opportunity.company.picture_url !== null ? (
                                <div>
                                    <Image src={opportunity.company.picture_url} alt="Image Text" width="200rem" height="auto" />
                                    {changeAvatarImage(opportunity)}
                                </div>
                            ) : (
                                <div>{changeAvatarImage(opportunity)}</div>
                            )}
                        </div>

                        <div className="p-d-flex p-jc-center p-mb-3" style={{ fontSize: "1.3rem", fontWeight: "500" }}>
                            Nome: {opportunity.company.name}
                        </div>

                        {opportunity.company.fantasy_name !== null ? (
                            <div className="p-d-flex p-jc-center p-mb-3" style={{ fontSize: "1.3rem", fontWeight: "500" }}>
                                <> Nome fantasia: {opportunity.company.fantasy_name} </>
                            </div>
                        ) : (
                            ""
                        )}

                        {opportunity.company.email !== null ? (
                            <>
                                <div className="p-d-flex p-jc-center p-mb-3" style={{ fontSize: "1.3rem", fontWeight: "500" }}>
                                    Email: {opportunity.company.email}
                                </div>
                            </>
                        ) : (
                            ""
                        )}

                        {opportunity.company.main_activity_description !== null ? (
                            <>
                                <div className="">
                                    <div className="p-d-flex p-jc-center" style={{ fontSize: "1.3rem", fontWeight: "500" }}>
                                        Principais atividades:
                                    </div>
                                    <div className="p-d-flex p-jc-center p-mb-3" style={{ fontSize: "1.2rem" }}>
                                        {opportunity.company.main_activity_description}
                                    </div>
                                </div>
                            </>
                        ) : (
                            ""
                        )}

                        {opportunity.company.address !== null ? (
                            <>
                                <div className="p-d-flex p-jc-center p-mb-1" style={{ fontSize: "1.3rem", fontWeight: "500" }}>
                                    Endereço: {opportunity.company.address}
                                </div>
                            </>
                        ) : (
                            ""
                        )}

                        {opportunity.company.city !== null || opportunity.company.state !== null || opportunity.company.country !== null ? (
                            <>
                                <div className="p-d-flex p-jc-center p-mb-3" style={{ fontSize: "1.2rem", fontWeight: "500" }}>
                                    {opportunity.company.city}
                                    {opportunity.company.state} {opportunity.company.country}
                                </div>
                            </>
                        ) : (
                            ""
                        )}

                        {opportunity.company.cadastral_situation !== null ? (
                            <>
                                <div className="p-d-flex p-jc-center p-mb-1" style={{ fontSize: "1.3rem", fontWeight: "500" }}>
                                    Situação cadastral: &nbsp; {cadastralSituation(opportunity.company)}
                                </div>
                            </>
                        ) : (
                            ""
                        )}

                        <br />
                        <div className="p-d-flex p-jc-center">
                            <Button
                                icon=""
                                label="Atualizar"
                                loading={loadingButtonChangeAvatarCompany}
                                className="p-button"
                                onClick={() => {
                                    setLoadingButtonChangeAvatarCompany(true)
                                    allOpportunity()
                                }}
                            />
                        </div>
                    </div>
                </Dialog>
            )}

            {!isEmptyObj(opportunity) && (
                <Dialog
                    header={headerDialogOpportunityInformationDialog(opportunity)}
                    visible={displayPositionDialogInformation}
                    position="bottom"
                    style={{
                        width: "100%",
                        height: "auto",
                    }}
                    onHide={() => {
                        onHide()
                        showOpportunityDetailState = {} as ShowOpportunityDetailDataInterface
                        setShowOpportunityDetailState(showOpportunityDetailState)
                    }}
                    closeOnEscape={true}
                    draggable={false}
                    resizable={false}
                >
                    {isEmptyObj(showOpportunityDetailState) ? (
                        <div style={{ width: "100%", height: "20vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <ProgressSpinner style={{ width: "50px", height: "50px", stroke: "blue !important" }} strokeWidth="8" fill="rgba(0,0,0,0)" />
                        </div>
                    ) : (
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6 p-lg-6">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6 p-lg-6">
                                        <p
                                            style={{
                                                marginBottom: "-1.5rem",
                                                color: "rgba(34,46,59,0.68)",
                                                marginRight: "1rem",
                                            }}
                                        >
                                            Nome
                                        </p>
                                        <h5>
                                            {showOpportunityDetailState.contact !== null
                                                ? showOpportunityDetailState.contact.name !== null
                                                    ? showOpportunityDetailState.contact.name
                                                    : ""
                                                : ""}
                                        </h5>
                                    </div>
                                    <div className="p-col-12 p-md-6 p-lg-6">
                                        <p
                                            style={{
                                                marginBottom: "-1.5rem",
                                                color: "rgba(34,46,59,0.68)",
                                            }}
                                        >
                                            CNPJ
                                        </p>
                                        <h5 style={{ fontSize: "1rem" }}>
                                            {/* {showOpportunityDetailState.cnpj !== null ? showOpportunityDetailState.cnpj : ""} */}
                                        </h5>
                                    </div>
                                </div>
                                <Divider style={{ marginTop: "-0.5rem" }} />
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6 p-lg-6">
                                        <p
                                            style={{
                                                marginBottom: "0rem",
                                                color: "rgba(34,46,59,0.68)",
                                                marginRight: "1rem",
                                            }}
                                        >
                                            Contato
                                        </p>

                                        <div
                                            onClick={() => {
                                                onPermissionRoute()
                                                phoneValidation =
                                                    showOpportunityDetailState.contact !== null
                                                        ? showOpportunityDetailState.contact.phone !== null
                                                            ? showOpportunityDetailState.contact.phone.replaceAll("-", "").substring(0, 1) === "0"
                                                                ? showOpportunityDetailState.contact.phone
                                                                      .replaceAll("-", "")
                                                                      .substring(1, showOpportunityDetailState.contact.phone.length)
                                                                : showOpportunityDetailState.contact.phone.replaceAll("-", "")
                                                            : ""
                                                        : ""
                                                setPhoneValidation(
                                                    showOpportunityDetailState.contact !== null
                                                        ? showOpportunityDetailState.contact.phone !== null
                                                            ? showOpportunityDetailState.contact.phone.replaceAll("-", "").substring(0, 1) === "0"
                                                                ? showOpportunityDetailState.contact.phone
                                                                      .replaceAll("-", "")
                                                                      .substring(1, showOpportunityDetailState.contact.phone.length)
                                                                : showOpportunityDetailState.contact.phone.replaceAll("-", "")
                                                            : ""
                                                        : ""
                                                )
                                                verifyPhone(
                                                    showOpportunityDetailState.contact !== null
                                                        ? showOpportunityDetailState.contact.phone !== null
                                                            ? showOpportunityDetailState.contact.phone.replaceAll("-", "").substring(0, 1) === "0"
                                                                ? showOpportunityDetailState.contact.phone
                                                                      .replaceAll("-", "")
                                                                      .substring(1, showOpportunityDetailState.contact.phone.length)
                                                                : showOpportunityDetailState.contact.phone.replaceAll("-", "")
                                                            : ""
                                                        : ""
                                                )
                                            }}
                                        >
                                            <h5
                                                style={{
                                                    color: "#495057",
                                                    marginRight: "1rem",
                                                    fontSize: "1rem",
                                                }}
                                            >
                                                {showOpportunityDetailState.contact !== null && showOpportunityDetailState.contact.phone !== null ? (
                                                    <>
                                                        {showOpportunityDetailState.contact.phone}
                                                        <a
                                                            href={`/crm/atendimento/&/${showOpportunityDetailState.contact.phone}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <i
                                                                className="pi pi-whatsapp"
                                                                style={{
                                                                    marginLeft: "1rem",
                                                                    color: "#02d14b",
                                                                    fontSize: "0.8rem",
                                                                    cursor: "pointer",
                                                                }}
                                                            ></i>
                                                        </a>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-6 p-lg-6">
                                        <p
                                            style={{
                                                marginBottom: "-1.5rem",
                                                color: "rgba(34,46,59,0.68)",
                                            }}
                                        >
                                            Email
                                        </p>
                                        <h5 style={{ fontSize: "1rem" }}>
                                            {showOpportunityDetailState.contact !== null
                                                ? showOpportunityDetailState.contact.email !== null
                                                    ? showOpportunityDetailState.contact.email
                                                    : ""
                                                : ""}
                                        </h5>
                                    </div>
                                </div>
                                <Divider style={{ marginTop: "-0.5rem" }} />
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-4 p-lg-4">
                                        <p
                                            style={{
                                                marginBottom: "-1.5rem",
                                                color: "rgba(34,46,59,0.68)",
                                            }}
                                        >
                                            Equipamento
                                        </p>
                                        <h5>{showOpportunityDetailState.machine !== null ? showOpportunityDetailState.machine.description : <>-</>}</h5>
                                    </div>

                                    <div className="p-col-12 p-md-2 p-lg-2">
                                        <p
                                            style={{
                                                marginBottom: "0rem",
                                                color: "rgba(34,46,59,0.68)",
                                                marginRight: "4rem",
                                            }}
                                        >
                                            Quantidade
                                        </p>
                                        <div style={{ fontWeight: "600", marginRight: "4rem" }}>
                                            {showOpportunityDetailState.machine !== null ? showOpportunityDetailState.machine.quantity : <>-</>}
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-4">
                                        <p
                                            style={{
                                                marginBottom: "0rem",
                                                color: "rgba(34,46,59,0.68)",
                                            }}
                                        >
                                            Valor
                                        </p>
                                        <div style={{ fontWeight: "600", marginRight: "4rem" }}>
                                            {showOpportunityDetailState.machine !== null ? (
                                                showOpportunityDetailState.machine.price.toLocaleString("pt-br", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })
                                            ) : (
                                                <>-</>
                                            )}
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-2 p-lg-2">
                                        <p
                                            style={{
                                                color: "rgba(34,46,59,0.68)",
                                                marginBottom: "0rem",
                                            }}
                                        >
                                            Código
                                        </p>
                                        <div style={{ fontWeight: "600" }}>
                                            {showOpportunityDetailState.machine !== null ? showOpportunityDetailState.machine.code : <>-</>}
                                        </div>
                                    </div>
                                </div>
                                <Divider style={{ marginTop: "-0.5rem" }} />

                                <div className="p-grid">
                                    <div className="p-col-12 p-md-4 p-lg-4">
                                        <p
                                            style={{
                                                marginBottom: "-1.5rem",
                                                color: "rgba(34,46,59,0.68)",
                                            }}
                                        >
                                            Data de criação
                                        </p>
                                        <h5>
                                            {showOpportunityDetailState.created_at !== null ? (
                                                <>
                                                    <Moment format="DD/MM/YYYY HH:mm">{showOpportunityDetailState.created_at}</Moment>
                                                </>
                                            ) : (
                                                <>-</>
                                            )}
                                        </h5>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-4">
                                        <p
                                            style={{
                                                marginBottom: "-1.5rem",
                                                color: "rgba(34,46,59,0.68)",
                                            }}
                                        >
                                            Previsão de fechamento
                                        </p>

                                        <h5>
                                            {showOpportunityDetailState.forecast_closing_date !== null ? (
                                                <>
                                                    <Moment format="DD/MM/YYYY HH:mm">{showOpportunityDetailState.forecast_closing_date}</Moment>
                                                </>
                                            ) : (
                                                <>-</>
                                            )}
                                        </h5>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-4">
                                        <p
                                            style={{
                                                marginBottom: "-1.5rem",
                                                color: "rgba(34,46,59,0.68)",
                                            }}
                                        >
                                            Último contato
                                        </p>
                                        <h5>
                                            {showOpportunityDetailState.last_contact !== null ? (
                                                <>
                                                    <Moment format="DD/MM/YYYY HH:mm">{showOpportunityDetailState.last_contact}</Moment>
                                                </>
                                            ) : (
                                                <>-</>
                                            )}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <Divider layout="vertical" />
                            <div className="p-col-12 p-md-5 p-lg-5">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-4 p-lg-4">
                                        <p
                                            style={{
                                                marginBottom: "-1.5rem",
                                                color: "rgba(34,46,59,0.68)",
                                            }}
                                        >
                                            Próxima tarefa
                                        </p>
                                        <h5>{showOpportunityDetailState.next_task !== null ? showOpportunityDetailState.next_task.subject : <>-</>}</h5>
                                    </div>

                                    <div className="p-col-12 p-md-4 p-lg-4">
                                        <p
                                            style={{
                                                marginBottom: "-1.5rem",
                                                color: "rgba(34,46,59,0.68)",
                                            }}
                                        >
                                            Tipo
                                        </p>
                                        <h5>
                                            {showOpportunityDetailState.next_task !== null ? replaceType(showOpportunityDetailState.next_task.type) : <>-</>}
                                        </h5>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-4">
                                        <p
                                            style={{
                                                marginBottom: "-1.5rem",
                                                color: "rgba(34,46,59,0.68)",
                                            }}
                                        >
                                            Data
                                        </p>
                                        <h5>
                                            {showOpportunityDetailState.next_task !== null ? (
                                                <Moment format="DD/MM/YYYY HH:mm">{showOpportunityDetailState.next_task.date}</Moment>
                                            ) : (
                                                <>-</>
                                            )}
                                        </h5>
                                    </div>
                                </div>
                                <Divider style={{ marginTop: "-0.5rem" }} />
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-4 p-lg-4">
                                        <p
                                            style={{
                                                marginBottom: "-1.5rem",
                                                color: "rgba(34,46,59,0.68)",
                                            }}
                                        >
                                            Fonte de chegada
                                        </p>
                                        <h5>{showOpportunityDetailState.media !== null ? showOpportunityDetailState.media : ""}</h5>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-4">
                                        <div className="p-d-flex">
                                            <p
                                                style={{
                                                    marginBottom: "0.3rem",
                                                    color: "rgba(34,46,59,0.68)",
                                                }}
                                            >
                                                Última anotação
                                            </p>
                                            &nbsp;
                                            <Button
                                                className="p-button-rounded p-button-text"
                                                style={{ marginTop: "-0.3rem" }}
                                                onClick={() => setDisplayDialogBasicAnottation(true)}
                                            >
                                                <i className="pi pi-pencil" />
                                            </Button>
                                        </div>
                                        <div style={{ fontWeight: "600" }}>
                                            {showOpportunityDetailState.last_annotation !== null ? showOpportunityDetailState.last_annotation : <>-</>}
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-4">
                                        <div>
                                            <p
                                                style={{
                                                    marginBottom: "-1.5rem",
                                                    color: "rgba(34,46,59,0.68)",
                                                }}
                                            >
                                                Situação Cadastral da Empresa
                                            </p>
                                            <h5>
                                                {showOpportunityDetailState.cadastral_situation !== null ? showOpportunityDetailState.cadastral_situation : ""}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <Divider style={{ marginTop: "-0.5rem" }} />
                            </div>
                        </div>
                    )}
                </Dialog>
            )}

            {!isEmptyObj(showOpportunityDetailState.next_task) && (
                <Dialog
                    visible={displayBasicNextTaskMobile}
                    style={{ width: "30rem" }}
                    draggable={false}
                    onHide={() => onHide()}
                    modal
                    className="p-fluid"
                    header="Próxima tarefa"
                    footer={!concludeTaskState ? concludeTask : concludeTaskFooter}
                >
                    <>
                        {informationNextTask(opportunity)}
                        {concludeTaskState && (
                            <>
                                <br />
                                <label htmlFor="postPone">Anotação de Conclusão:</label>
                                <InputText
                                    style={{ minWidth: "26rem" }}
                                    value={taskAnotation || ""}
                                    onChange={(e: any) => {
                                        setTaskAnotation(e.target.value)
                                    }}
                                />
                            </>
                        )}
                    </>
                </Dialog>
            )}

            {/* //FILTERS MOBILE */}
            <Dialog
                visible={hotKeysDialog}
                style={{ width: "850px" }}
                modal
                className="p-fluid"
                onHide={onHide}
                header={`Atalhos - Oportunidades`}
                draggable={false}
            >
                <HotKeys />
            </Dialog>

            <Dialog
                header="Filtros"
                visible={displayBasicMobile}
                style={{ width: "90%", height: "90%" }}
                footer={renderFooterDialogMobile("displayBasic")}
                onHide={() => onHideDialogMobile("displayBasic")}
            >
                <div style={{ width: "100%", height: "1.55rem", backgroundColor: "#eee", marginTop: "2rem" }}>
                    <p style={{ fontSize: "1rem", height: "700" }} className="p-d-flex p-jc-center p-as-center">
                        FILTROS AVANÇADOS
                    </p>
                </div>
                <div className="advanced_Filters" style={{ marginTop: "1.5rem" }}>
                    <div className="p-d-flex p-jc-center" style={{ marginTop: "1rem" }}>
                        <MultiSelect
                            style={{ width: "50%" }}
                            className="p-mr-2"
                            value={selectedStatusFilter}
                            options={listOfStatus}
                            onChange={(e: any) => {
                                selectedStatusFilter = e.value
                                setSelectedStatusFilter(e.value)
                                filter()
                            }}
                            emptyFilterMessage={"Não há dados"}
                            filter={true}
                            optionLabel="name"
                            selectedItemsLabel="{0} itens selecionados"
                            placeholder="STATUS"
                        />

                        <MultiSelect
                            style={{ width: "50%" }}
                            className=""
                            value={selectedPriorityFilter}
                            options={selectionPriorities}
                            onChange={(e: any) => {
                                selectedPriorityFilter = e.value
                                setSelectedPriorityFilter(e.value)
                                filter()
                            }}
                            emptyFilterMessage={"Não há dados"}
                            optionLabel="name"
                            selectedItemsLabel="{0} itens selecionados"
                            placeholder="TEMPERATURA"
                        />
                    </div>
                    <div className="p-d-flex p-jc-center" style={{ marginTop: "1rem" }}>
                        <MultiSelect
                            className="p-mr-2"
                            style={{ width: "50%" }}
                            value={selectedStageFilter}
                            options={listOfStage}
                            selectedItemsLabel="{0} itens selecionados"
                            onChange={(e: any) => {
                                selectedStageFilter = e.value
                                setSelectedStageFilter(e.value)
                                filter()
                            }}
                            emptyFilterMessage={"Não há dados"}
                            filter={true}
                            optionLabel="name"
                            placeholder="ETAPA"
                        />
                        <MultiSelect
                            style={{ width: "50%" }}
                            className=""
                            selectedItemsLabel="{0} itens selecionados"
                            value={selectedStateFilter}
                            options={listOfState}
                            onChange={(e: any) => {
                                setSelectedStateFilter(e.value)
                                filter()
                            }}
                            emptyFilterMessage={"Não há dados"}
                            optionLabel="name"
                            placeholder="ESTADO"
                        />
                    </div>
                    <MultiSelect
                        style={{ width: "100%", marginTop: "1rem" }}
                        className="p-mr-2"
                        selectedItemsLabel="{0} itens selecionados"
                        value={selectedSellerFilter}
                        options={listOfSellers}
                        onChange={(e: any) => {
                            selectedSellerFilter = e.value
                            setSelectedSellerFilter(e.value)
                            filter()
                        }}
                        emptyFilterMessage={"Não há dados"}
                        filter={true}
                        optionLabel="name"
                        placeholder="VENDEDOR"
                    />

                    <div className="" style={{ margin: "0.01rem", marginTop: "1rem" }}>
                        <InputText
                            className="p-mr-6"
                            value={advancedSearchFilter}
                            onChange={(e: any) => {
                                setAdvancedSearchFilter(e.target.value)
                                filter()
                            }}
                            placeholder="PESQUISA AVANÇADA..."
                        />
                        <div style={{ marginTop: "1rem" }} className="p-d-flex p-jc-center">
                            <Button
                                tooltip="ATUALIZAR"
                                icon="pi pi-refresh"
                                className="p-button-rounded p-mr-2"
                                onClick={handleUpdateAsOpportunities}
                                loading={loadingUpdateAsOpportunities}
                            />

                            <Button
                                className="p-button-rounded p-mr-2"
                                //label={"APENAS ATRASADAS"}
                                style={{ backgroundColor: `${colorLateCard()}`, border: "none" }}
                                loading={loadingExcludedFilter}
                                icon="pi pi-clock"
                                tooltip="VISUALIZAR APENAS ATRASADAS"
                                onClick={() => {
                                    handleViewLate = !handleViewLate
                                    setHandleViewLate(handleViewLate)
                                    filter()
                                }}
                            />

                            <Button
                                className="p-button-rounded p-mr-2"
                                style={{ backgroundColor: `${colorNewCardOpportunity()}`, border: "none" }}
                                loading={false}
                                icon="pi pi-star"
                                tooltip="APENAS NOVAS OPORTUNIDADES"
                                onClick={() => {
                                    handleButtonNewOpportunities = !handleButtonNewOpportunities
                                    setHandleButtonNewOpportunities(handleButtonNewOpportunities)
                                    filter()
                                }}
                            />

                            <Button
                                className="p-button-rounded p-mr-2"
                                icon="pi pi-times"
                                tooltip="LIMPAR FILTROS"
                                loading={loadingCleanFilters}
                                onClick={onLoadingCleanFilters}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>

            <CrmRecords />
        </App>
    )
}
