import moment from "moment"
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { ProgressSpinner } from "primereact/progressspinner"
import React, { memo, ReactNode, useState } from "react"
import { useEffect } from "react"
import { date } from "yup"
import { CleanTaskInterface } from "../../../../interfaces/CleanTaskInterface"
import "../../../../styles/Opportunity.scss"

interface CleanTasksPropsInterface {
    loadingDialogOfTasks: boolean
    closeRightSidePanelTasks: () => void
    cleanTasks: CleanTaskInterface[]
    loadingList: boolean
    replaceIconTypeToTask: (e: any) => ReactNode
    replaceStatus: (e: any) => ReactNode
    taskSelected: {}
    setTaskSelected: (e: CleanTaskInterface) => void
    onTaskFilters: boolean
}

const TaskSideRightPanel = (props: CleanTasksPropsInterface) => {
    let [cleanTasksAux, setCleanTasksAux] = useState<CleanTaskInterface[]>([])
    let [loadingList, setLoadingList] = useState(false)
    let [dateInit, setDateInit] = useState<any>(new Date())
    let [dateFinal, setDateFinal] = useState<any>(new Date())

    const filterTasks = () => {
        if (dateInit !== "" || dateFinal !== "") {
            var listTasksFiltered: any[] = []

            let tasks = props.cleanTasks
            for (let atualLine of tasks) {
                var addLine = true

                let newAtualLineGetTime = new Date(
                    new Date(atualLine.date).getFullYear(),
                    new Date(atualLine.date).getMonth(),
                    new Date(atualLine.date).getDate()
                )

                if (dateInit !== "" && addLine) {
                    let newDateInitGetTime = new Date(new Date(dateInit).getFullYear(), new Date(dateInit).getMonth(), new Date(dateInit).getDate())
                    if (newAtualLineGetTime.getTime() < newDateInitGetTime.getTime()) {
                        addLine = false
                    }
                }
                if (dateFinal !== "" && addLine) {
                    let newDateFinalGetTime = new Date(new Date(dateFinal).getFullYear(), new Date(dateFinal).getMonth(), new Date(dateFinal).getDate())
                    if (newAtualLineGetTime.getTime() > newDateFinalGetTime.getTime()) {
                        addLine = false
                    }
                }

                if (addLine) {
                    listTasksFiltered.push(atualLine)
                }
            }
            cleanTasksAux = listTasksFiltered
            setCleanTasksAux(listTasksFiltered)
        } else {
            cleanTasksAux = props.cleanTasks
            setCleanTasksAux(cleanTasksAux)
        }
        setLoadingList(false)
    }

    const linkOpen = (item: any) => {
        return <a href={`/crm/leads/${item.opportunity_id}`} target="_blank" >Link</a>
    }

    useEffect(() => {
        cleanTasksAux = props.cleanTasks
        setCleanTasksAux(cleanTasksAux)
        filterTasks()
    }, [props.cleanTasks])

    // useEffect(() => {
    //     dateInit = new Date()
    //     setDateInit(dateInit)
    //     dateFinal = new Date()
    //     setDateFinal(dateFinal)
    //     filterTasks()
    // }, [props.onTaskFilters])

    return (
        <>
            <div id="taskSideRightPanel" className="taskSideRightPanel">
                {props.loadingDialogOfTasks ? (
                    <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <ProgressSpinner style={{ width: "50px", height: "50px", stroke: "blue !important" }} strokeWidth="8" fill="rgba(0,0,0,0)" />
                    </div>
                ) : (
                    <div>
                        <i
                            className="closeTaskSideRightPanel pi pi-times p-mb-2"
                            onClick={() => {
                                // dateInit = new Date()
                                // setDateInit(dateInit)
                                // dateFinal = new Date()
                                // setDateFinal(dateFinal)
                                // filterTasks()
                                props.closeRightSidePanelTasks()
                            }}
                        ></i>
                        <br />
                        <div className="p-col-12 p-md-12 p-lg-12 p-d-flex p-jc-center">
                            <Calendar
                                style={{ width: "10rem" }}
                                className="filterPattern p-mr-3 p-mb-2"
                                dateFormat="dd/mm/yy"
                                id="icon"
                                locale="pt"
                                value={new Date(dateInit)}
                                onMonthChange={() => ""}
                                onChange={(e: any) => {
                                    setLoadingList(true)
                                    dateInit = e.value
                                    setDateInit(dateInit)
                                    filterTasks()
                                }}
                                showIcon
                                placeholder={"INÍCIO"}
                                showButtonBar
                            />
                            <Calendar
                                style={{ width: "10rem" }}
                                locale="pt"
                                className="filterPattern p-mr-2 p-mb-2"
                                dateFormat="dd/mm/yy"
                                id="icon"
                                value={new Date(dateFinal)}
                                onMonthChange={() => ""}
                                onChange={(e: any) => {
                                    setLoadingList(true)
                                    dateFinal = e.value
                                    setDateFinal(dateFinal)
                                    filterTasks()
                                }}
                                showIcon
                                placeholder={"FIM"}
                                showButtonBar
                            />
                            <Button
                                className="p-button-rounded p-mr-2"
                                icon="pi pi-times"
                                tooltip="LIMPAR FILTROS"
                                loading={loadingList}
                                onClick={() => {
                                    dateFinal = ""
                                    setDateFinal(dateFinal)
                                    dateInit = ""
                                    setDateInit(dateInit)
                                    setLoadingList(false)
                                    filterTasks()
                                }}
                            />
                        </div>
                        <DataTable
                            value={cleanTasksAux}
                            loading={props.loadingList || loadingList}
                            stripedRows
                            selectionMode="single"
                            onSelectionChange={(e) => props.setTaskSelected(e.value)}
                            dataKey="id"
                            rows={6}
                            rowsPerPageOptions={[10, 15, 20]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} Tarefas"
                            paginator
                            emptyMessage="Não há Tarefas"
                            responsiveLayout="scroll"
                        >
                            <Column sortable align="center" alignHeader="center" field="subject" header="Tarefa" />

                            <Column align="center" alignHeader="center" field="type" body={linkOpen} header="Oportunidade" sortField="" sortable></Column>

                            <Column
                                align="center"
                                alignHeader="center"
                                field="type"
                                body={(e) => props.replaceIconTypeToTask(e.type)}
                                header="TIPO"
                                sortField="totalValueMachines"
                                sortable
                            ></Column>

                            <Column
                                align="center"
                                alignHeader="center"
                                field="type"
                                body={(e) => <div>{moment(e.date).format("DD/MM/YYYY hh:mm")}</div>}
                                header="DATA"
                                sortField="type"
                                sortable
                            ></Column>

                            <Column
                                align="center"
                                alignHeader="center"
                                field="status"
                                body={(e) => props.replaceStatus(e.status)}
                                header="STATUS"
                                sortField="status"
                                sortable
                            ></Column>
                        </DataTable>
                    </div>
                )}
            </div>
        </>
    )
}

export default memo(TaskSideRightPanel)
