import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { MultiSelect } from "primereact/multiselect"
import { Rating } from "primereact/rating"
import React, { ReactNode, useEffect, useState } from "react"
import { memo, useCallback } from "react"
import ReactTooltip from "react-tooltip"
import { FunnelInterface } from "../../../../interfaces/FunnelInterface"
import { OpportunityInterface } from "../../../../interfaces/OpportunityInterface"
import { TaskInterface } from "../../../../interfaces/TaskInterface"
import api from "../../../../services/api"

interface OpportunityDataInterface extends OpportunityInterface {
    nextTask: TaskInterface
}

interface FilterPropsInterface {
    auxOpportunities: OpportunityDataInterface[]
    countOfNewOpportunities: number
    dateInit: string
    setDateInit: (e: any) => void
    dateFinal: string
    setDateFinal: (e: any) => void
    selectedSellerFilter: any[]
    setSelectedSellerFilter: (e: any) => void
    listOfSellers: any[]
    selectedPriorityFilter: any[]
    setSelectedPriorityFilter: (e: any) => void
    selectionPriorities: any[]
    selectedStatusFilter: any[]
    setSelectedStatusFilter: (e: any) => void
    listOfStatus: any[]
    selectedStageFilter: any[]
    listOfStage: any[]
    listOfFunnel: any[]
    selectedFunnelFilter: any[]
    setSelectedFilter: (e: any) => void
    setSelectedStageFilter: (e: any) => void
    selectedStateFilter: any[]
    setSelectedStateFilter: (e: any) => void
    listOfState: any[]
    selectedMediaFilter: any[]
    setSelectedMediaFilter: (e: any) => void
    listOfMedia: any[]
    selectedInterestFilter: any[]
    listOfInterest: any[]
    advancedSearchFilter: string
    setAdvancedSearchFilter: (e: any) => void
    handleUpdateAsOpportunities: () => void
    loadingUpdateAsOpportunities: boolean
    colorExcludedCard: () => void
    loadingExcludedFilter: boolean
    handleButtonExcluded: boolean
    setHandleButtonExcluded: (e: any) => void
    colorLateCard: () => void
    handleViewLate: boolean
    setHandleViewLate: (e: any) => void
    colorNewCardOpportunity: () => void
    handleButtonNewOpportunities: boolean
    setHandleButtonNewOpportunities: (e: any) => void
    setTooltipCleanFilter: (e: any) => void
    loadingCleanFilters: boolean
    onLoadingCleanFilters: () => void
    funnel: {}
    setFunnel: (e: any) => void
    funnels: FunnelInterface[]
    workViewFilter: {}
    setWorkViewFilter: (e: any) => void
    listWorkViewFilter: { name: string; code: number }[]
    countTotalValueOpportunities: () => ReactNode
    onClickMobileDialog: (e: any, x: any) => void
    setSelectedInterestFilter: (e: any) => void
    setLoadingSkeletonFiltering: (e: any) => void
    toXML: (e: any) => void
    allCompanies: () => void
    dialogOfTasks: (e: boolean) => void
    filter: () => void
    allCleanTasks: () => void
    onTaskFilters: boolean
    setOnTaskFilters: (e: boolean) => void
    nameOfClassTaksButtonFilter: string
}

const FilterPropsInterface = (props: FilterPropsInterface) => {
    let [dialogTasks, setDialogTasks] = useState(true)
    const groupedItemTemplate = (option: any) => {
        return (
            <div className="p-d-flex p-ai-center country-item">
                <Rating value={option.code} cancel={false} />
            </div>
        )
    }

    let [permissionButton, setPermissionButton] = useState(false)

    const onPermissionRouteButton = async () => {
        try {
            let header = {
                headers: {
                    route: "export_opportunities_data",
                },
            }

            await api.post("permission/check", {}, header).then((res) => {
                permissionButton = true
                setPermissionButton(permissionButton)
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        onPermissionRouteButton()
    }, [])

    return (
        <>
            <div className="opportunityContent_Filters p-d-flex">
                <div className="opportunityContent_Filters_desktop" style={{ paddingLeft: "7px", width: "75%", height: "100%" }}>
                    <div style={{ width: "100%", height: "auto" }}>
                        <Calendar
                            style={{ width: "10rem" }}
                            className="filterPattern p-mr-2 p-mb-2"
                            dateFormat="dd/mm/yy"
                            id="icon"
                            locale="pt"
                            value={new Date(props.dateInit)}
                            onMonthChange={() => ""}
                            onChange={(e: any) => {
                                props.setLoadingSkeletonFiltering(true)
                                props.setDateInit(e.value)
                                props.filter()
                            }}
                            showIcon
                            placeholder={"INÍCIO"}
                        />
                        <Calendar
                            style={{ width: "10rem" }}
                            className="filterPattern p-mr-2 p-mb-2"
                            dateFormat="dd/mm/yy"
                            id="icon"
                            value={new Date(props.dateFinal)}
                            onMonthChange={() => ""}
                            onChange={(e: any) => {
                                props.setLoadingSkeletonFiltering(true)
                                props.setDateFinal(e.value)
                                props.filter()
                            }}
                            showIcon
                            placeholder={"FIM"}
                        />
                        <MultiSelect
                            className="filterPattern p-mr-2 p-mb-2"
                            value={props.selectedSellerFilter}
                            style={{ width: "10rem" }}
                            options={props.listOfSellers}
                            onChange={(e: any) => {
                                props.setLoadingSkeletonFiltering(true)
                                props.setSelectedSellerFilter(e.value)
                                props.filter()
                            }}
                            emptyFilterMessage={"Não há dados"}
                            filter={true}
                            optionLabel="name"
                            placeholder="VENDEDOR"
                            selectedItemsLabel="{0} itens selecionados"
                        ></MultiSelect>
                        <MultiSelect
                            className="filterPattern p-mr-2 p-mb-2"
                            value={props.selectedPriorityFilter}
                            style={{ width: "10rem" }}
                            options={props.selectionPriorities}
                            onChange={(e: any) => {
                                props.setLoadingSkeletonFiltering(true)
                                props.setSelectedPriorityFilter(e.value)
                                props.filter()
                            }}
                            emptyFilterMessage={"Não há dados"}
                            itemTemplate={groupedItemTemplate}
                            optionLabel="name"
                            placeholder="TEMPERATURA"
                        />

                        <MultiSelect
                            className="filterPattern p-mr-2 p-mb-2"
                            value={props.selectedStatusFilter}
                            style={{ width: "10rem" }}
                            options={props.listOfStatus}
                            onChange={(e: any) => {
                                props.setLoadingSkeletonFiltering(true)
                                props.setSelectedStatusFilter(e.value)
                                props.filter()
                            }}
                            emptyFilterMessage={"Não há dados"}
                            filter={true}
                            optionLabel="name"
                            selectedItemsLabel="{0} itens selecionados"
                            placeholder="STATUS"
                        />
                        <MultiSelect
                            className="filterPattern p-mr-2 p-mb-2"
                            value={props.selectedStageFilter}
                            style={{ width: "10rem" }}
                            options={props.listOfStage}
                            onChange={(e: any) => {
                                props.setLoadingSkeletonFiltering(true)
                                props.setSelectedStageFilter(e.value)
                                props.filter()
                            }}
                            emptyFilterMessage={"Não há dados"}
                            filter={true}
                            optionLabel="name"
                            placeholder="ETAPA"
                            selectedItemsLabel="{0} itens selecionados"
                        />

                        <MultiSelect
                            className="filterPattern p-mr-2 p-mb-2"
                            value={props.selectedStateFilter}
                            style={{ width: "10rem" }}
                            options={props.listOfState}
                            onChange={(e: any) => {
                                props.setLoadingSkeletonFiltering(true)
                                props.setSelectedStateFilter(e.value)
                                props.filter()
                            }}
                            emptyFilterMessage={"Não há dados"}
                            filter={true}
                            optionLabel="name"
                            placeholder="ESTADO"
                            selectedItemsLabel="{0} itens selecionados"
                        />
                        <MultiSelect
                            className="filterPattern p-mr-2 p-mb-2"
                            value={props.selectedMediaFilter}
                            style={{ width: "10rem" }}
                            options={props.listOfMedia}
                            onChange={(e: any) => {
                                props.setLoadingSkeletonFiltering(true)
                                props.setSelectedMediaFilter(e.value)
                                props.filter()
                            }}
                            emptyFilterMessage={"Não há dados"}
                            filter={true}
                            optionLabel="name"
                            placeholder="FONTE"
                            selectedItemsLabel="{0} itens selecionados"
                        />
                        <MultiSelect
                            className="filterPattern p-mr-2 p-mb-2"
                            value={props.selectedInterestFilter}
                            style={{ width: "10rem" }}
                            options={props.listOfInterest}
                            onChange={(e: any) => {
                                props.setLoadingSkeletonFiltering(true)
                                props.setSelectedInterestFilter(e.value)
                                props.filter()
                            }}
                            emptyFilterMessage={"Não há dados"}
                            filter={true}
                            optionLabel="name"
                            placeholder="INTERESSE"
                            selectedItemsLabel="{0} itens selecionados"
                        />
                        <Button
                            icon={props.nameOfClassTaksButtonFilter}
                            label="TAREFAS"
                            onClick={() => {
                                props.dialogOfTasks(dialogTasks)
                                props.allCleanTasks()
                                props.setOnTaskFilters(dialogTasks)

                                dialogTasks = !dialogTasks
                                setDialogTasks(dialogTasks)
                            }}
                            className="p-button p-mr-2 p-mb-2"
                        />

                        <Button
                            data-tip
                            data-for="ATUALIZAR"
                            icon="pi pi-refresh"
                            className="p-button-rounded p-mr-2 p-mb-2"
                            onClick={() => {
                                props.setLoadingSkeletonFiltering(true)
                                props.handleUpdateAsOpportunities()
                            }}
                            loading={props.loadingUpdateAsOpportunities}
                        />
                        <ReactTooltip className="toolTip" id="ATUALIZAR" place="right" effect="solid">
                            <p style={{ fontSize: "1rem", fontWeight: "400" }}>ATUALIZAR</p>
                        </ReactTooltip>

                        <Button
                            className="p-button-rounded p-mr-2 p-mb-2"
                            icon="pi pi-trash"
                            data-tip
                            data-for="VISUALIZAR-EXCLUÍDAS"
                            style={{ backgroundColor: `${props.colorExcludedCard()}`, border: "none" }}
                            loading={props.loadingExcludedFilter}
                            onClick={() => {
                                props.setLoadingSkeletonFiltering(true)

                                let value = props.handleButtonExcluded
                                value = !value
                                props.setHandleButtonExcluded(value)
                                props.filter()
                            }}
                        />
                        <ReactTooltip className="toolTip" id="VISUALIZAR-EXCLUÍDAS" place="right" effect="solid">
                            <p style={{ fontSize: "1rem", fontWeight: "400" }}>VISUALIZAR EXCLUÍDAS</p>
                        </ReactTooltip>

                        <Button
                            className="p-button-rounded p-mr-2 p-mb-2"
                            style={{ backgroundColor: `${props.colorLateCard()}`, border: "none" }}
                            loading={props.loadingExcludedFilter}
                            icon="pi pi-clock"
                            data-tip
                            data-for="VISUALIZAR-APENAS-ATRASADAS"
                            onClick={() => {
                                props.setLoadingSkeletonFiltering(true)

                                let value = props.handleViewLate
                                value = !value

                                props.setHandleViewLate(value)
                                props.filter()
                            }}
                        />
                        <ReactTooltip className="toolTip" id="VISUALIZAR-APENAS-ATRASADAS" place="right" effect="solid">
                            <p style={{ fontSize: "1rem", fontWeight: "400" }}>VISUALIZAR APENAS ATRASADAS</p>
                        </ReactTooltip>

                        <Button
                            className="p-button-rounded p-mr-2 p-mb-2"
                            style={{ backgroundColor: `${props.colorNewCardOpportunity()}`, border: "none" }}
                            loading={false}
                            icon="pi pi-star"
                            data-tip
                            data-for="APENAS-NOVAS-OPORTUNIDADES"
                            onClick={() => {
                                props.setLoadingSkeletonFiltering(true)
                                let value = props.handleButtonNewOpportunities
                                value = !value

                                props.setHandleButtonNewOpportunities(value)
                                props.filter()
                            }}
                        ></Button>
                        {props.countOfNewOpportunities > 0 ? (
                            <div
                                className="p-d-flex p-jc-center p-ai-center"
                                style={{
                                    display: "flex",
                                    position: "absolute",
                                    width: "1.3rem",
                                    height: "1.3rem",
                                    zIndex: "2",
                                    backgroundColor: "rgb(34, 197, 94)",
                                    borderRadius: "100%",
                                    top: "-1px",
                                    right: "0",
                                    color: "white",
                                    fontWeight: "500",
                                    boxShadow: "rgba(0, 0, 0, 0.45) 0px 2px 2px",
                                    userSelect: "none",
                                }}
                            >
                                {props.countOfNewOpportunities}
                            </div>
                        ) : (
                            ""
                        )}
                        <ReactTooltip className="toolTip" id="APENAS-NOVAS-OPORTUNIDADES" place="right" effect="solid">
                            <p style={{ fontSize: "1rem", fontWeight: "400" }}>APENAS NOVAS OPORTUNIDADES</p>
                        </ReactTooltip>

                        <Button
                            className="p-button-rounded p-mr-2 p-mb-2"
                            icon="pi pi-times"
                            onMouseOver={() => props.setTooltipCleanFilter(true)}
                            data-tip
                            data-for="LIMPAR-FILTRO"
                            loading={props.loadingCleanFilters}
                            onClick={() => {
                                props.setLoadingSkeletonFiltering(true)
                                props.onLoadingCleanFilters()
                            }}
                        />
                        <ReactTooltip className="toolTip" id="LIMPAR-FILTRO" place="right" effect="solid">
                            <p style={{ fontSize: "1rem", fontWeight: "400" }}>LIMPAR FILTRO</p>
                        </ReactTooltip>

                        {permissionButton && (
                            <>
                                <Button
                                    className="p-button-rounded p-mr-2 p-mb-2"
                                    icon="pi pi-download"
                                    data-tip
                                    data-for="BAIXAR DADOS"
                                    onClick={() => {
                                        props.allCompanies()
                                        props.toXML(props.auxOpportunities)
                                    }}
                                />
                                <ReactTooltip className="toolTip" id="BAIXAR DADOS" place="right" effect="solid">
                                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>BAIXAR DADOS</p>
                                </ReactTooltip>
                            </>
                        )}

                        <InputText
                            style={{ width: "40%" }}
                            className="filterPattern advancedSearch p-mr-3"
                            value={props.advancedSearchFilter}
                            onChange={(e: any) => {
                                props.setAdvancedSearchFilter(e.target.value)
                                setTimeout(() => {
                                    props.filter()
                                }, 1000)
                            }}
                            id="autofocs"
                            placeholder="PESQUISA AVANÇADA..."
                        />
                    </div>
                </div>
                <div className="opportunityContent_Filters_desktop_right" style={{ width: "25%", height: "100%" }}>
                    <div className=" p-d-flex p-jc-end p-ai-center">
                        {/* <div className="p-mr-2">
                            <div className="p-d-flex p-ai-center p-flex-column">
                                <span
                                    style={{
                                        fontSize: "0.7rem",
                                        marginLeft: "0.5rem",
                                        marginBottom: "0.5rem",
                                    }}
                                >
                                    Funil de Vendas
                                </span>

                                <Dropdown
                                    className="filterPattern"
                                    id="WorkViewFilterId"
                                    value={props.funnel || ""}
                                    options={props.funnels}
                                    emptyMessage="Não há dados"
                                    emptyFilterMessage="Não há dados"
                                    onChange={(e: any) => {
                                        props.setFunnel(e.value)
                                        props.filter()
                                    }}
                                    optionLabel="name"
                                    placeholder="Funil de vendas"
                                />
                            </div>
                        </div> */}
                        <div className="p-mr-4">
                            <div className="p-d-flex p-ai-center p-flex-column">
                                <span
                                    style={{
                                        fontSize: "0.7rem",
                                        marginLeft: "0.5rem",
                                        marginBottom: "0.5rem",
                                    }}
                                >
                                    Visão de trabalho
                                </span>
                                <Dropdown
                                    className="filterPattern"
                                    id="testeId"
                                    value={props.workViewFilter}
                                    options={props.listWorkViewFilter}
                                    emptyMessage="Não há dados"
                                    emptyFilterMessage="Não há dados"
                                    onChange={(e: any) => {
                                        props.setWorkViewFilter(e.value)
                                        props.filter()
                                    }}
                                    optionLabel="name"
                                    placeholder=" Visão de trabalho"
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className=" p-d-flex p-jc-end p-ai-center p-mr-4" style={{ marginBottom: "-1rem" }}>
                        {props.countTotalValueOpportunities()}
                    </div>
                </div>

                <div className="opportunityContent_Filters_mobile">
                    <div className="p-d-flex p-jc-between">
                        <h3>Oportunidades</h3>

                        <div className="p-d-flex p-jc-end">
                            <i
                                className="pi pi-filter p-mr-5"
                                style={{ fontSize: "1.5em" }}
                                onClick={() => props.onClickMobileDialog("displayBasic", "center")}
                            ></i>
                        </div>
                    </div>

                    <div className="p-d-flex">
                        <div className="p-mr-3">
                            <span
                                style={{
                                    fontSize: "0.8rem",
                                    marginLeft: "0.5rem",
                                    marginBottom: "0.2rem",
                                    marginTop: "0.5rem",
                                }}
                            >
                                Funil de Vendas
                            </span>
                            <Dropdown
                                id="WorkViewFilterId"
                                value={props.funnel || ""}
                                emptyMessage="Não há dados"
                                emptyFilterMessage="Não há dados"
                                options={props.funnels}
                                onChange={(e: any) => {
                                    props.setFunnel(e.value)
                                }}
                                optionLabel="name"
                                placeholder="Funil de vendas"
                                style={{ marginBottom: "1rem", width: "100%" }}
                            />
                        </div>
                        <div>
                            <span
                                style={{
                                    fontSize: "0.8rem",
                                    marginTop: "0.5rem",
                                    marginLeft: "0.5rem",
                                }}
                            >
                                Visão de trabalho
                            </span>
                            <Dropdown
                                id="testeId"
                                value={props.workViewFilter}
                                options={props.listWorkViewFilter}
                                emptyMessage="Não há dados"
                                emptyFilterMessage="Não há dados"
                                onChange={(e: any) => props.setWorkViewFilter(e.value)}
                                optionLabel="name"
                                placeholder="Funil de vendas"
                                style={{ width: "100%" }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(FilterPropsInterface)
