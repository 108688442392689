
import React, { useRef, useState, useEffect } from "react"
import App from "../../layout/App"
import api from "../../services/api"
import moment from "moment"
import classNames from "classnames"
import Moment from "react-moment"
import { useHotkeys } from "react-hotkeys-hook"

//PrimeReact
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { ConfirmDialog } from "primereact/confirmdialog"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { FilterMatchMode, FilterOperator } from "primereact/api"
import { InputText } from "primereact/inputtext"
import { MachineApresentationInterface } from "../../interfaces/MachineApresentationInterface"
import { Toast } from "primereact/toast"
import { Toolbar } from "primereact/toolbar"

//Components
import { CrmRecords } from "../../components/crm/CrmRecords"
import { LocalUpload } from "../../components/crm/whatsapp/LocalUpload"
import AllPagesPDFViewer from "../../components/PDF/all-pages"
import "../../components/PDF/index.scss"
import "../../components/PDF/all-pages"

//CSS
import "react-dropzone-uploader/dist/styles.css"
import { HotKeys } from "../../components/crm/HotKeys"

export const Presentation = () => {
    const toast: any = useRef(null)
    let [createValues, setCreateValues] = useState<MachineApresentationInterface>({} as MachineApresentationInterface)
    let [presentation, setPresentation] = useState<MachineApresentationInterface>({} as MachineApresentationInterface)
    let [presentations, setPresentations] = useState<MachineApresentationInterface[]>([])
    let [submitted, setSubmitted] = useState<boolean>(false)

    //Loading
    let [loadingDelete, setLoadingDelete] = useState<boolean>(false)
    let [loadingEdit, setLoadingEdit] = useState<boolean>(false)
    let [loadingList, setLoadingList] = useState<boolean>(true)
    let [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    //Dialog
    let [deleteDialog, setDeleteDialog] = useState(false)
    let [hotKeysDialog, setHotKeysDialog] = useState(false)
    let [presentationDialog, setPresentationDialog] = useState<boolean | undefined>(false)
    let [presentationEditDialog, setPresentationEditDialog] = useState(false)
    let [uploadFileDialog, setUploadFileDialog] = useState(false)
    let [viewFileDialog, setViewFileDialog] = useState(false)

    //Filters
    let [auxPresentationFilter, setAuxPresentationFilter] = useState<MachineApresentationInterface[]>([])

    const dt: any = useRef(null)
    const token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    useHotkeys("shift+n", () => openNew())
    useHotkeys("shift+i", () => setHotKeysDialog(true))

    //Filters
    let [globalFilterValue, setGlobalFilterValue] = useState("")
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        description: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    })

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value
        let _filters = { ...filters }
        _filters["global"].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Apresentações de Equipamentos </h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Pesquisar..." />
            </span>
        </div>
    )

    //Button
    const dataTableButton = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-user-edit" className="p-button-rounded p-button" onClick={() => dialogEditPresentation(rowData)} />
                &nbsp;&nbsp;
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => deletePresentation(rowData)} />
                &nbsp;&nbsp;
                {rowData.file === null && (
                    <Button icon="pi pi-upload" className="p-button-rounded p-button-success" onClick={() => uploadPresentaiton(rowData)} />
                )}
                {rowData.file != null && (
                    <Button icon="pi pi-upload" className="p-button-rounded p-button-warning" onClick={() => uploadPresentaiton(rowData)} />
                )}
            </div>
        )
    }

    const createPresentationButton = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-user-plus" className="p-button" onClick={openNew} />
            </React.Fragment>
        )
    }

    //OnChange
    const onChangeCreatePresentation = (event: any) => {
        const { value, name } = event.target

        setCreateValues({
            ...createValues,
            [name]: value,
        })
    }

    const onChangeEdit = (event: any) => {
        const { value, name } = event.target
        setPresentation({
            ...presentation,
            [name]: value,
        })
    }

    //Dialogs
    const hideDialog = () => {
        setCreateValues({} as MachineApresentationInterface)
        setSubmitted(false)
        setPresentationDialog(false)
        setPresentationEditDialog(false)
        setDeleteDialog(false)
        setUploadFileDialog(false)
        setHotKeysDialog(false)
        setViewFileDialog(false)
    }

    const deletePresentationMenssage = () => {
        return (
            <>
                <h4>
                    <strong>Confirmação de Exclusão:</strong>
                </h4>
                <br />
                <h5>
                    <strong>Nome: </strong>
                    <span>{presentation.name}</span>
                </h5>
                {presentation.file !== null && presentation.file !== undefined && presentation.file !== "" && (
                    <h5>
                        <strong>Nome do Arquivo: </strong>
                        <span>{presentation.file}</span>
                    </h5>
                )}
                <h5>
                    <strong>Data Inclusão: </strong>
                    <span>
                        <Moment format="DD-MM-YYYY">{presentation.created_at}</Moment>
                    </span>
                </h5>
                <h5>
                    <strong>Data Exclusão: </strong>
                    <span>
                        <Moment format="DD-MM-YYYY">{moment()}</Moment>
                    </span>
                </h5>
            </>
        )
    }

    const linkPDF = (rowData: MachineApresentationInterface) => {
        return (
            <>
                <div>
                    <a
                        onClick={() => {
                            setViewFileDialog(true)
                            setPresentation(rowData)
                        }}
                        style={{ fontWeight: "600", fontSize: "1.1rem", color: "rgba(1, 1, 122, 1)" }}
                    >
                        {rowData.file}
                    </a>
                </div>
            </>
        )
    }

    const dialogEditPresentation = (presentated: MachineApresentationInterface) => {
        setPresentation(presentated)
        setPresentationEditDialog(true)
    }

    //Requisitons
    const allPresentations = async () => {
        try {
            let header = {
                headers: {
                    route: "list_machines_apresentations",
                },
            }

            await api
                .get("machines_apresentations", header)
                .then((res) => {
                    setLoadingList(false)
                    setPresentations(res.data)
                    setAuxPresentationFilter(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const uploadPresentaiton = (presentated: MachineApresentationInterface) => {
        setPresentation(presentated)
        setUploadFileDialog(true)
    }

    const onSubmitEditPresentation = () => {
        if (presentation.description === null || presentation.description === "" || presentation.name === null || presentation.name === "") {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao Editar",
                detail: "Revise os dados inseridos",
                life: 3000,
            })
            setPresentationEditDialog(true)
        } else {
            setLoadingSubmit(true)
            let auxConfirPresentation = {
                id: presentation.id,
                name: presentation.name,
                description: presentation.description,
            }
            try {
                let header = {
                    headers: {
                        route: "update_machine_apresentation",
                    },
                }

                api.put("machines_apresentations", auxConfirPresentation, header)

                    .then((res) => {
                        if (res.status === 200) {
                            setLoadingSubmit(false)
                            let _presentations = [...auxPresentationFilter]
                            let _presentation = res.data
                            let index = findIndexById(res.data.id)
                            _presentations[index] = _presentation
                            presentations = _presentations
                            auxPresentationFilter = _presentations

                            setPresentations(_presentations)
                            setAuxPresentationFilter(_presentations)
                            hideDialog()

                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso na alteração",
                                detail: "Apresetação alterada com sucesso!",
                                life: 3000,
                            })
                        } else {
                            setUploadFileDialog(true)
                            setLoadingSubmit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao Editar",
                                detail: "Por favor verifique os dados inseridos",
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setUploadFileDialog(true)
                        setLoadingSubmit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Editar",
                            detail: "Por favor verifique os dados inseridos",
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        }
    }

    const deletePresentation = (item: MachineApresentationInterface) => {
        setPresentation(item)
        setDeleteDialog(true)
    }

    const onDeletePresentation = () => {
        setLoadingDelete(true)
        try {
            api.delete("machines_apresentations", {
                headers: {
                    route: "delete_machine_apresentation",
                },
                data: {
                    id: presentation.id,
                },
            })

                .then((res) => {
                    setLoadingDelete(false)
                    if (res.status === 200) {
                        let auxDeleteLPresentation = auxPresentationFilter.filter((item) => presentation.id !== item.id)
                        presentations = auxDeleteLPresentation
                        auxPresentationFilter = auxDeleteLPresentation
                        hideDialog()
                        setPresentations(auxDeleteLPresentation)
                        setAuxPresentationFilter(auxDeleteLPresentation)
                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na exclusão",
                            detail: "Apresentação excluída com sucesso!",
                            life: 3000,
                        })
                    } else {
                        setLoadingDelete(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao excluir!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingDelete(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao excluir!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmitPresentation = (event: any) => {
        setLoadingEdit(true)
        event.preventDefault()
        setSubmitted(false)

        try {
            const { name, description } = createValues
            if (name === "") {
                setSubmitted(true)
                setLoadingEdit(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao cadastrar",
                    detail: "Por favor, verificar os dados preenchidos!",
                    life: 3000,
                })
            } else {
                let header = {
                    headers: {
                        route: "create_machine_apresentation",
                    },
                }

                api.post(
                    "machines_apresentations",
                    {
                        name,
                        description,
                    },
                    header
                )
                    .then((res) => {
                        if (res.status === 200) {
                            hideDialog()
                            setLoadingEdit(false)
                            setPresentations([...presentations, res.data])
                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso",
                                detail: "Apresentação criada com sucesso",
                                life: 3000,
                            })
                        } else {
                            setLoadingEdit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao cadastar!",
                                detail: "Por favor, verificar os dados preenchidos!",
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingEdit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao cadastar!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const openNew = () => {
        setPresentationDialog(true)
    }

    const findIndexById = (id: string) => {
        let index = -1
        for (let i = 0; i < auxPresentationFilter.length; i++) {
            if (auxPresentationFilter[i].id === id) {
                index = i
                break
            }
        }

        return index
    }

    //Datatble formater
    const dateFormatCreate = (rowData: MachineApresentationInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.created_at}</Moment>
            </>
        )
    }

    const dateFormatUpdate = (rowData: MachineApresentationInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.updated_at}</Moment>
            </>
        )
    }

    //File
    const onUploadFile = (files: any) => {
        const data = new FormData()
        data.append("file", files[0].file)
        hideDialog()
        setLoadingList(true)

        toast.current?.show({
            severity: "info",
            summary: "Enviando arquivo, aguarde...",
            life: 3000,
        })

        let header = {
            headers: {
                route: "update_file_machine_apresentation",
            },
        }

        api.patch(`machines_apresentations/file/${presentation.id}`, data, header)

            .then((res) => {
                if (res.status === 200) {
                    setLoadingList(false)
                    setPresentations((prevState) => {
                        return prevState.map((item) => {
                            if (item.id === presentation.id) {
                                item.file_url = res.data.file_url
                            }
                            return item
                        })
                    })

                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Arquivo enviado com sucesso",
                        life: 3000,
                    })
                } else {
                    setLoadingList(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao enviar arquivo!",
                        detail: res.data.message,
                        life: 3000,
                    })
                }
            })

            .catch((error) => {
                setLoadingList(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao enviar arquivo!",
                    detail: error.response.data.message,
                    life: 3000,
                })
            })
    }

    //Footer
    const presentationDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingEdit} onClick={onSubmitPresentation} />
        </>
    )

    const presentationEditDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingSubmit} onClick={onSubmitEditPresentation} />
        </>
    )

    const deleteDialogFooter = () => {
        return (
            <>
                <Button label="Não" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingDelete} onClick={onDeletePresentation} />
            </>
        )
    }

    useEffect(() => {
        allPresentations()
    }, [])

    return (
        <App>
            <CrmRecords />

            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="p-mb-4 p-toolbar" left={createPresentationButton}></Toolbar>
                        {/* DataTable */}
                        <DataTable
                            ref={dt}
                            value={presentations}
                            loading={loadingList}
                            stripedRows
                            selectionMode="checkbox"
                            filters={filters}
                            globalFilterFields={["description", "name"]}
                            dataKey="id"
                            rows={10}
                            rowsPerPageOptions={[10, 15, 20]}
                            // className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} apresentações"
                            paginator
                            header={header}
                            emptyMessage="Não há Apresentações"
                            responsiveLayout="scroll"
                        >
                            <Column field="name" header="Nome" sortable align="center" alignHeader="center" />
                            <Column field="description" header="Descrição" sortable align="center" alignHeader="center" />
                            <Column body={dateFormatCreate} header="Data de Criação" sortable sortField="created_at" align="center" alignHeader="center" />
                            <Column body={dateFormatUpdate} sortField="update_at" header="Data de Atualização" sortable align="center" alignHeader="center" />
                            <Column body={linkPDF} header="Arquivo" sortable align="center" alignHeader="center" />
                            <Column align="center" alignHeader="center" body={dataTableButton}></Column>
                        </DataTable>

                        {/* Create Method */}
                        <Dialog
                            visible={presentationDialog}
                            style={{ width: "450px" }}
                            header="Adicionar Apresentação de Equipamento"
                            modal
                            className="p-fluid"
                            draggable={false}
                            footer={presentationDialogFooter}
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <label htmlFor="name">Nome</label>
                                <InputText
                                    id="name"
                                    name="name"
                                    required
                                    type="String"
                                    maxLength={45}
                                    style={{ minWidth: "20rem" }}
                                    className={
                                        "p-field p-text-capitalize " +
                                        classNames({
                                            "p-invalid": submitted && createValues.name === "",
                                        })
                                    }
                                    value={createValues.name || ""}
                                    onChange={onChangeCreatePresentation}
                                />
                            </div>
                            <div className="p-field">
                                <label htmlFor="description">Descrição</label>
                                <InputText
                                    id="description"
                                    name="description"
                                    required
                                    type="String"
                                    style={{ minWidth: "20rem" }}
                                    maxLength={45}
                                    className={
                                        "p-field p-text-capitalize " +
                                        classNames({
                                            "p-invalid": submitted && createValues.description === "",
                                        })
                                    }
                                    value={createValues.description || ""}
                                    onChange={onChangeCreatePresentation}
                                />
                            </div>
                        </Dialog>

                        {/* Edit Method */}
                        <Dialog
                            visible={presentationEditDialog}
                            style={{ width: "450px" }}
                            header={`Editar Apresentação:`}
                            draggable={false}
                            modal
                            className="p-fluid"
                            footer={presentationEditDialogFooter}
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <label htmlFor="name">Nome</label>
                                <InputText
                                    style={{ minWidth: "20rem" }}
                                    id="name"
                                    name="name"
                                    maxLength={120}
                                    onChange={(e: any) => onChangeEdit(e)}
                                    value={presentation.name || ""}
                                />
                            </div>
                            <div className="p-field">
                                <label htmlFor="description">Descrição</label>
                                <InputText
                                    id="description"
                                    name="description"
                                    required
                                    type="String"
                                    style={{ minWidth: "20rem" }}
                                    maxLength={45}
                                    className={
                                        "p-field p-text-capitalize " +
                                        classNames({
                                            "p-invalid": submitted && createValues.description === "",
                                        })
                                    }
                                    value={presentation.description || ""}
                                    onChange={(e: any) => onChangeEdit(e)}
                                />
                            </div>
                        </Dialog>

                        {/* Upload Method */}
                        <Dialog
                            visible={uploadFileDialog}
                            style={{ width: "750px" }}
                            header={"Enviar arquivos de: " + presentation.name}
                            modal
                            draggable={false}
                            className="p-fluid"
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <LocalUpload onSubmit={onUploadFile} types={".pdf"} size={100000000} />
                            </div>
                        </Dialog>

                        <Dialog
                            visible={viewFileDialog}
                            style={{ height: "80%" }}
                            header={"Arquivo: " + presentation.name}
                            modal
                            className="p-fluid"
                            draggable={false}
                            onHide={hideDialog}
                        >
                            <Button
                                label="Baixar"
                                icon="pi pi-link"
                                className="p-button"
                                onClick={() => window.open(presentation.file_url)}
                                style={{ width: "100%", marginBottom: "1rem" }}
                            />
                            <div
                                className="p-field"
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <AllPagesPDFViewer pdf={presentation.file_url} />
                            </div>
                        </Dialog>

                        {/* HotKeys Dialog */}
                        <Dialog
                            visible={hotKeysDialog}
                            style={{ width: "800px" }}
                            modal
                            className="p-fluid"
                            onHide={hideDialog}
                            header={`Atalhos - Tela de Apresentações de Equipamentos`}
                            draggable={false}
                        >
                            <HotKeys create={"Criar uma nova Apresentação"} />
                        </Dialog>

                        {/* Delete Method */}
                        <ConfirmDialog
                            visible={deleteDialog}
                            style={{ width: "40rem" }}
                            message={deletePresentationMenssage}
                            footer={deleteDialogFooter}
                            onHide={hideDialog}
                        ></ConfirmDialog>
                    </div>
                </div>
            </div>
        </App>
    )
}
