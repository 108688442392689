import { Avatar } from "primereact/avatar"
import { Button } from "primereact/button"
import { Chip } from "primereact/chip"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { InputText } from "primereact/inputtext"
import { useRef, useState } from "react"
import { ContactInterface } from "../../../../interfaces/ContactInterface"
import { WhatsappConversationInterface } from "../../../../interfaces/WhatsappConversationInterface"

interface Props {
    auxContactsFiltered: ContactInterface[]
    setContacts: (contacts: ContactInterface[]) => void
    contacts: ContactInterface[]
    setAuxContactsFiltered: (contacts: ContactInterface[]) => void
    loadingListContacts: boolean
    phoneContact: string
    nameContact: string
    setNameContact: (e: any) => void
    setPhoneContact: (e: any) => void
    conversations: WhatsappConversationInterface[]
    setNewMessageDialog: (e: any) => void
    setConversationSelected: (e: any) => void
    openContactFunction: (e: any) => void
    openANonExistingConversation: (e:any) => void
    
}

export const DataTableContacts = (props: Props) => {
    let dataTable: any = useRef(null)
    let [advancedSearchFilter, setAdvancedSearchFilter] = useState("")
    let [autoFocus, setAutofocus] = useState(false)

    let noPictureURL = "/assets/pages/icons/no-avatar.png"

    const filterOfNewContact = () => {
        if (advancedSearchFilter !== "") {
            const filteredContacts = props.auxContactsFiltered.filter((atualLine) => {
                const word = advancedSearchFilter
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                    .split(" ")

                const contactFields = [
                    atualLine.id,
                    atualLine.name,
                    atualLine.company?.name,
                    atualLine.company?.id,
                    atualLine.company?.cnpj,
                    atualLine.job,
                    atualLine.company?.fantasy_name,
                ]

                const phones = atualLine.phones?.map((phone) => phone.number) || []
                const emails = atualLine.emails?.map((email) => email.email) || []

                const searchFields = [...contactFields.filter((field) => field !== null && field !== undefined), ...phones, ...emails]

                return word.every((searchWord) =>
                    searchFields.some(
                        (field) =>
                            field !== null &&
                            field !== undefined &&
                            field
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                                .toLowerCase()
                                .includes(searchWord)
                    )
                )
            })

            props.setContacts(filteredContacts)
        } else {
            props.setContacts(props.auxContactsFiltered)
        }
    }

    const footerDataTable = (
        <>
            <div style={{ textAlign: "center" }}>
                <p>Não há Contatos</p>
                {advancedSearchFilter.replace(/[^0-9]/g, "").length >= 12 && advancedSearchFilter.replace(/[^0-9]/g, "").length <= 13 && (
                    <Button
                        label={`Iniciar conversa com ${advancedSearchFilter.replace(/[^0-9]/g, "")}`}
                        style={{ width: "20rem" }}
                        icon="pi pi-send"
                        className="p-button"
                        onClick={() => {
                            props.openContactFunction(advancedSearchFilter.replace(/[^0-9]/g, ""))
                        }}
                    />
                )}
            </div>
        </>
    )

    const ConcatenateTelephone = (rowData: any) => {
        let cell = []
        let vazio = "-"
        if (rowData.phones.number !== "") {
            for (let phone of rowData.phones) {
                cell.push(
                    <div
                        onClick={() => {
                            let existChat = false
                            let company: WhatsappConversationInterface = {} as WhatsappConversationInterface

                            props.conversations.map((conversation) => {
                                if (conversation.contact?.id === rowData.id) {
                                    existChat = true
                                    company = conversation
                                } else {
                                    if (phone.number.length > 8) {
                                        const numberEnds = phone.number.substring(phone.number.length - 8, phone.number.length)
                                        if (conversation.phone_number.endsWith(numberEnds)) {
                                            existChat = true
                                            company = conversation
                                        }
                                    } else {
                                        if (conversation.phone_number.endsWith(phone.number)) {
                                            existChat = true
                                            company = conversation
                                        }
                                    }
                                }
                            })

                            if (existChat) {
                                props.setNewMessageDialog(false)
                                props.setConversationSelected(company)
                            } else {
                                let splitNumberLet = phone.number.split("-")
                                let formatedNumber = parseInt(splitNumberLet[0]) + splitNumberLet[1]
                                props.phoneContact = formatedNumber
                                props.setPhoneContact(props.phoneContact)
                                props.nameContact = rowData.name
                                props.setNameContact(props.nameContact)
                                props.openANonExistingConversation(formatedNumber)
                                props.setNewMessageDialog(false)
                            }
                        }}
                    >
                        <Chip style={{ margin: "5px", color: "white", backgroundColor: "#3574db" }} label={phone.number} key={phone.id} />{" "}
                    </div>
                )
            }
        } else {
            cell = [vazio]
        }
        return cell
    }

    const headerNewContactDataTable = (
        <div>
            {/* Desktop */}
            <div className="desktop-opportunity-filter-menu">
                <div className="p-d-flex p-jc-between">
                    <h5 className=""> </h5>
                    <div className="p-d-flex p-jc-end">
                        <span className="p-input-icon-left p-mr-2">
                            <i className="pi pi-search" />
                            <InputText
                                className="searchFilter"
                                placeholder="Pesquisar Contato..."
                                value={advancedSearchFilter}
                                autoFocus={autoFocus}
                                onKeyUp={() =>
                                    advancedSearchFilter === "" ? filterOfNewContact() : "" || advancedSearchFilter !== "" ? filterOfNewContact() : ""
                                }
                                onChange={(e) => {
                                    setAdvancedSearchFilter(e.target.value)
                                    filterOfNewContact()
                                }}
                            />
                        </span>
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="mobile-opportunity-filter-menu">
                <div className="p-d-flex">
                    <div className="table-header">
                        <h5 className="p-mb-2">Contatos</h5>
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText
                                className="searchFilter"
                                placeholder="Pesquisar Contato..."
                                value={advancedSearchFilter}
                                onKeyUp={() =>
                                    advancedSearchFilter === "" ? filterOfNewContact() : "" || advancedSearchFilter !== "" ? filterOfNewContact() : ""
                                }
                                onChange={(e) => {
                                    setAdvancedSearchFilter(e.target.value)
                                    filterOfNewContact()
                                }}
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )

    const companyFormaterDataTable = (rowData: any) => {
        return rowData.company.name === null ? <>-</> : rowData.company.name
    }

    const companyImageDataTable = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Imagem</span>
                <Avatar
                    image={rowData.company.picture_url === null ? noPictureURL : rowData.company.picture_url}
                    className="p-mr-2"
                    size="xlarge"
                    shape="circle"
                />
            </>
        )
    }

    return (
        <DataTable
            ref={dataTable}
            value={props.contacts}
            loading={props.loadingListContacts}
            stripedRows
            dataKey="id"
            header={headerNewContactDataTable}
            rows={25}
            rowsPerPageOptions={[25, 50, 100]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} contatos"
            paginator
            emptyMessage={footerDataTable}
        >
            <Column body={companyImageDataTable} header="" align="center" alignHeader="center" />
            <Column sortable field="name" header="Nome" align="center" alignHeader="center" />
            <Column sortable align="center" alignHeader="center" body={companyFormaterDataTable} header="Empresa/Cliente" sortField="company.name" />
            <Column
                body={ConcatenateTelephone}
                style={{ cursor: "pointer" }}
                header="TELEFONE"
                sortable
                sortField="phones"
                align="center"
                alignHeader="center"
            />
        </DataTable>
    )
}
