
import React, { useState, useRef } from "react"
import api from "../services/api"
import { useAuth } from "../hooks/AuthContext"
import { useHistory } from "react-router-dom"

//PrimeReact
import { Button } from "primereact/button"
import { Password } from "primereact/password"
import { Toast } from "primereact/toast"

//Interface
import { NewPassword } from "../interfaces/NewPassowrd"

export const ResetPassword: React.FC = () => {
    const history = useHistory()
    const { signIn } = useAuth()
    const [loading, setLoading] = useState<boolean>(false)
    const [changeValues, setChangeValues] = useState<NewPassword>({} as NewPassword)

    const toast: any = useRef()

    const onChangeNewPassword = (event: any) => {
        const { value, name } = event.target

        setChangeValues({
            ...changeValues,
            [name]: value,
        })
    }

    const onSubmitNewPassword = async (event: any) => {
        event.preventDefault()
        setLoading(true)

        var params = window.location.search.substring(1).split("&")
        var paramArray: any = {}
        for (var i = 0; i < params.length; i++) {
            var param = params[i].split("=")

            paramArray[param[0]] = param[1]
        }

        try {
            const { password, password_confirmation } = changeValues

            if (password === "" || password_confirmation === "" || password !== password_confirmation) {
                setLoading(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao cadastrar",
                    detail: "Por favor, verificar os dados preenchidos!",
                    life: 3000,
                })
            } else {
                let header = {
                    headers: {
                        route: "reset_password",
                    },
                }

                api.post(
                    "password/reset",
                    {
                        token: paramArray["token"],
                        password,
                        password_confirmation,
                    },
                    header
                )
                    .then(async (res) => {
                        setLoading(false)
                        if (res.status === 200) {
                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso",
                                detail: "Senha alterada com sucesso.",
                                life: 3000,
                            })

                            const email = res.data.email

                            try {
                                await signIn({ email, password })
                                history.push("/")
                            } catch (error) {
                                toast.current?.show({
                                    severity: "error",
                                    summary: "Erro ao Resetar Senha!",
                                    detail: "Email ou senha invalidos!!",
                                    life: 3000,
                                })
                            }
                        } else {
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao Resetar Senha!",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoading(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao cadastar!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="banner">
            <Toast ref={toast} />
            <video autoPlay muted loop className="image">
                <source src="https://esquadros-bi.s3.amazonaws.com/front-end-crm/video_Trim.mp4" type="video/mp4" />
            </video>

            <div className="animationBackground login-body" id="animationBackground">
                <Toast ref={toast} />

                <div className="leftContent">
                    <div className="animatedLeft" style={{}}>
                        <img className="logoEsq" src="/assets/layout/images/logoWt.png" alt="Logotipo Esquadros" />
                        <p className="text">
                            <span style={{ fontWeight: "700" }}>INOVAÇÃO E TECNOLOGIA</span> PARA REVOLUCIONAR O PROCESSAMENTO DE{" "}
                            <span style={{ fontWeight: "700" }}>BOBINAS.</span>
                        </p>
                    </div>
                </div>

                <div className="login-wrapper">
                    <div>
                        <form className="login-panel">
                            <img className="iconLogo" src="/assets/layout/images/esq-white-logo.svg" alt="Logotipo Esquadros" />
                            <p className="text">
                                {" "}
                                <span style={{ fontWeight: "700", color: "white" }}>RECUPERAÇÃO DE SENHA</span>
                            </p>
                            <br />

                            <Password
                                id="password"
                                onChange={onChangeNewPassword}
                                name="password"
                                promptLabel="Digite uma senha"
                                feedback={true}
                                weakLabel="Senha Fraca"
                                mediumLabel="Senha média"
                                strongLabel="Senha forte"
                                placeholder="Nova Senha"
                                required
                                value={changeValues.password}
                            />

                            <Password
                                id="password_confirmation"
                                onChange={onChangeNewPassword}
                                name="password_confirmation"
                                promptLabel="Digite uma senha"
                                placeholder="Confirmar Senha"
                                required
                                feedback={false}
                                value={changeValues.password_confirmation}
                            />
                            <Button label="Alterar senha" className="p-button" loading={loading} onClick={onSubmitNewPassword} />
                        </form>
                    </div>
                    <div className="login-footer">
                        <h4 style={{ fontSize: "1rem" }}>Esquadros</h4>
                        <h6 style={{ fontSize: "0.8rem ", color: "white" }}>Copyright Ⓒ Inovação tecnológica</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}
