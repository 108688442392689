import { Avatar } from "primereact/avatar"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import React, { useState } from "react"
import { useEffect } from "react"
import Moment from "react-moment"
import ReactPlayer from "react-player"
import { MessagesWhatsappConversation } from "../../../../interfaces/MessagesWhatsappConversation"
import { UserInterface } from "../../../../interfaces/UserInterface"
import { WhatsappConversationInterface } from "../../../../interfaces/WhatsappConversationInterface"
import { AudioRecorder } from "../AudioRecorder"
import { ReplyMessageChat } from "../ReplyMessageChat"

import "../../../../styles/Attendance.scss"

type PropsInterface = {
    conversationSelected: WhatsappConversationInterface
    conversations: WhatsappConversationInterface[]
    selectReplyMessageChat: MessagesWhatsappConversation
    profileUser: UserInterface
    chatDialogMobile: string
    onSearchMessageChatMobile: boolean
    viewButtonsSearchMessageUpOrDown: boolean
    countIndexSearchMessageFilter: number
    chatMessagesSearch: string[]
    inputTextSearchMessagesChat: string
    inputTextSendMessage: string
    verification24: boolean
    marginTopWindowInput: number
    statusClose: boolean
    isActive: boolean
    indexSended: number
    idReplyMessage: string
    widthWindow: number
    idMessageForOnMouseOver: string
    replyMessageUse: MessagesWhatsappConversation
    myRefCardMessageMobile: React.MutableRefObject<HTMLDivElement | null>

    updatesetMicRecprder: (e: any) => void
    updateSetRecording: (e: any) => void
    updateIsActive: (e: any) => void
    updateSetStatusClose: (e: any) => void
    updateSetSendFileAudio: (e: any) => void
    onSendAudio: (e: any) => void
    toastTrue: (e: any) => void
    setOnSearchMessageChatMobile: (e: boolean) => void
    setChatDialogMobile: (e: string) => void
    setScreenMobile: (e: string) => void
    downScrollSearch: () => void
    handleIdSearchMessageDiv: (e: any) => void
    upScrollSearch: () => void
    handleKeyPressSearchMessage: (e: any) => void
    setInputTextSearchMessagesChat: (e: string) => void
    chatMessageFilter: (x: any, y: any) => void
    setViewButtonsSearchMessageUpOrDown: (e: boolean) => void
    setChatMessagesSearch: () => void
    valueIndexMessageDate: (e: number) => void
    setSelectReplyMessageChat: (e: MessagesWhatsappConversation) => void
    dividerDataInChatMessages: (x: MessagesWhatsappConversation, y: any, z: number) => void
    setUploadFromFilesDialog: (e: boolean) => void
    setInputTextSendMessage: (e: any) => void
    handleKeyPress: (e: any) => void
    onSendMessagetoContact: (e: any) => void
    executeScroll: () => void
    setIdMessageForOnMouseOver: (e: string) => void
    setViewImageMessage: (e: boolean) => void
    setSelectedViewImageMessage: (e: MessagesWhatsappConversation) => void
    setInformationDetailMessageVisualition: (e: string) => void
    setInformationDetailMessageChat: (e: MessagesWhatsappConversation) => void
    messageReadConfirmation: (e: MessagesWhatsappConversation) => void
    setIdReplyMessage: (e: string) => void
    setReplyMessageUse: (e: MessagesWhatsappConversation) => void
    executeScrollMobile: () => void
    setImageUploadDialog: (e: boolean) => void
}

export const ChatConversationMobile = ({
    conversationSelected,
    conversations,
    chatDialogMobile,
    onSearchMessageChatMobile,
    viewButtonsSearchMessageUpOrDown,
    countIndexSearchMessageFilter,
    chatMessagesSearch,
    inputTextSearchMessagesChat,
    inputTextSendMessage,
    selectReplyMessageChat,
    verification24,
    marginTopWindowInput,
    statusClose,
    isActive,
    indexSended,
    idReplyMessage,
    profileUser,
    widthWindow,
    idMessageForOnMouseOver,
    replyMessageUse,
    myRefCardMessageMobile,
    updatesetMicRecprder,
    updateSetRecording,
    updateIsActive,
    updateSetStatusClose,
    updateSetSendFileAudio,
    onSendAudio,
    toastTrue,
    setOnSearchMessageChatMobile,
    setChatDialogMobile,
    setScreenMobile,
    downScrollSearch,
    handleIdSearchMessageDiv,
    upScrollSearch,
    handleKeyPressSearchMessage,
    setInputTextSearchMessagesChat,
    chatMessageFilter,
    setViewButtonsSearchMessageUpOrDown,
    setChatMessagesSearch,
    valueIndexMessageDate,
    setSelectReplyMessageChat,
    dividerDataInChatMessages,
    setUploadFromFilesDialog,
    setInputTextSendMessage,
    handleKeyPress,
    onSendMessagetoContact,
    executeScroll,
    setIdMessageForOnMouseOver,
    setViewImageMessage,
    setSelectedViewImageMessage,
    setInformationDetailMessageVisualition,
    setInformationDetailMessageChat,
    messageReadConfirmation,
    setIdReplyMessage,
    setReplyMessageUse,
    executeScrollMobile,
    setImageUploadDialog,
}: PropsInterface) => {
    let noPictureURL = "/assets/pages/icons/no-avatar.png"

    let [colorDivSeachedMessage, setColorDivSeachedMessage] = useState("")

    const handleSetReplyMessage = () => {
        setSelectReplyMessageChat({} as MessagesWhatsappConversation)
    }

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    const replyMessage = (replyMessage: MessagesWhatsappConversation) => {
        conversationSelected.messages.forEach((message) => {
            if (replyMessage.context_message_id === message.whatsapp_id) {
                replyMessageUse = message
            }
        })
        if (!isEmptyObj(replyMessageUse)) {
            return (
                <div>
                    <a
                        href={widthWindow > 420 ? `#${replyMessageUse.id}` : `#${replyMessageUse.id}mobile`}
                        onClick={() => {
                            setIdReplyMessage(replyMessageUse.id)
                            setTimeout(() => {
                                let auxReply = {} as MessagesWhatsappConversation
                                replyMessageUse = auxReply
                                setReplyMessageUse(auxReply)
                                setIdReplyMessage(replyMessageUse.id)
                            }, 2500)
                        }}
                    >
                        <div>
                            {replyMessageUse.type === "text" ? (
                                <div
                                    className="p-d-flex p-jc-center"
                                    style={{
                                        width: `${widthWindow <= 414 ? "20rem" : "auto"}`,
                                        maxWidth: `${widthWindow <= 414 ? "18rem" : "50rem"}`,
                                    }}
                                >
                                    {replyMessageUse.text}
                                </div>
                            ) : (
                                <></>
                            )}
                            {replyMessageUse.type === "image" ? (
                                <div
                                    className="p-d-flex p-jc-center"
                                    style={{
                                        width: `${widthWindow <= 414 ? "20rem" : "auto"}`,
                                        maxWidth: `${widthWindow <= 414 ? "20rem" : "20rem"}`,
                                    }}
                                >
                                    <img
                                        src={!isEmptyObj(replyMessageUse.attachment) ? replyMessageUse.attachment.file_url : ""}
                                        width="100"
                                        height="auto"
                                        alt="Imagem"
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                            {replyMessageUse.type === "document" ? (
                                <div
                                    className="p-d-flex p-jc-center"
                                    style={{
                                        width: `${widthWindow <= 414 ? "20rem" : "auto"}`,
                                        maxWidth: `${widthWindow <= 414 ? "20rem" : "20rem"}`,
                                    }}
                                >
                                    <div style={{ width: "7rem" }}>{archiveTypeForDocuments(replyMessageUse)}</div>
                                </div>
                            ) : (
                                <></>
                            )}
                            {replyMessageUse.type === "audio" ? (
                                <div style={{ width: `${widthWindow <= 414 ? "15.5rem" : "20rem"}` }}>
                                    <audio
                                        src={replyMessageUse.attachment !== null ? replyMessageUse.attachment.file_url : ""}
                                        className="audio-1"
                                        controls
                                        playsInline={true}
                                        style={{
                                            width: "100%",
                                            height: "2.3rem",
                                            resize: "none",
                                            outline: "none",
                                            borderRadius: "5px",
                                            textDecoration: "bold",
                                            fontSize: "1rem",
                                        }}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                            {replyMessageUse.type === "video" ? (
                                <div
                                    className="p-d-flex p-jc-center"
                                    style={{
                                        width: `${widthWindow <= 414 ? "20rem" : "auto"}`,
                                        maxWidth: `${widthWindow <= 414 ? "20rem" : "20rem"}`,
                                    }}
                                >
                                    <ReactPlayer
                                        autoPlay
                                        url={replyMessageUse.attachment !== null ? replyMessageUse.attachment.file_url : ""}
                                        width="90%"
                                        height="90%"
                                        controls={true}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                            {replyMessageUse.type === "sticker" ? (
                                <div
                                    className="p-d-flex p-jc-center"
                                    style={{
                                        width: `${widthWindow <= 414 ? "20rem" : "auto"}`,
                                        maxWidth: `${widthWindow <= 414 ? "20rem" : "20rem"}`,
                                    }}
                                >
                                    <img
                                        src={!isEmptyObj(replyMessageUse.attachment) ? replyMessageUse.attachment.file_url : ""}
                                        width="100"
                                        height="auto"
                                        alt="Imagem"
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>

                        <div className="p-d-flex p-jc-center p-as-end" style={{ marginTop: "0.2rem" }}>
                            <a
                                onClick={() => {
                                    if (replyMessageUse.status !== "failed") {
                                        // setInformationDetailMessageVisualition("viewInformationMessage")
                                        setInformationDetailMessageChat(replyMessageUse)
                                    }
                                }}
                                className="p-d-flex p-as-end"
                                style={{ cursor: "pointer" }}
                            >
                                <div className="p-d-flex p-as-end" style={{ fontSize: "0.65rem" }}>
                                    <>
                                        {/* <div style={{ color: "#797E88", marginLeft: "8rem" }}>
                                            <Moment format="HH:mm">{replyMessageUse.created_at}</Moment>
                                        </div>{" "}
                                        &nbsp;
                                        {replyMessageUse.status !== "received" ? (
                                            <div>
                                                {messageReadConfirmation(replyMessageUse)}
                                                &nbsp;
                                            </div>
                                        ) : (
                                            ""
                                        )} */}
                                    </>
                                </div>
                            </a>
                        </div>
                    </a>
                </div>
            )
        }
    }

    const typeOfMessageSentForMobile = (
        contactMessageWhatsapp: MessagesWhatsappConversation,
        index: number,
        classNameForAnimation: any,
        colorBackground: any,
        textForJSX: any
    ) => {
        if (chatMessagesSearch[countIndexSearchMessageFilter] === contactMessageWhatsapp.id) {
            colorDivSeachedMessage = "rgba(141, 142, 143, 0.1)"
        } else {
            colorDivSeachedMessage = repliedMessageMarker(contactMessageWhatsapp)
        }

        const formatText = (text: string) => {
            // Format *Bold*
            text = text.replace(/\*([^*]+)\*/g, "<strong>$1</strong>")

            // Format _Italic_
            text = text.replace(/_([^_]+)_/g, "<em>$1</em>")

            // Format ~Strikethrough~
            text = text.replace(/~([^~]+)~/g, "<del>$1</del>")

            // Format ```Monospace```
            text = text.replace(/```([^`]+)```/g, "<code>$1</code>")

            // Format _*Bold Italic*_
            text = text.replace(/_\*\s*([^*_]+)\s*\*_/g, "<em><strong>$1</strong></em>")

            // Format *_~Strikethrough Bold~_*
            text = text.replace(/\*\*_~([^~]+)~_\*\*/g, "<strong><em><del>$1</del></em></strong>")

            return text
        }

        const formattedText = formatText(textForJSX)

        return (
            <>
                <div
                    id={`${contactMessageWhatsapp.id}mobile`}
                    ref={myRefCardMessageMobile}
                    key={contactMessageWhatsapp.id}
                    style={{
                        padding: "0.8rem 0rem 0.8rem 0rem",
                        backgroundColor: `${colorDivSeachedMessage}`,
                    }}
                    onMouseOver={() => setIdMessageForOnMouseOver(contactMessageWhatsapp.whatsapp_id)}
                >
                    <div className={`${classNameForAnimation}`} style={{ marginLeft: "0rem", marginTop: "1rem" }}>
                        <div className="p-grid p-d-flex p-jc-end" style={{ marginRight: "1rem" }}>
                            <div className="" key="1">
                                <p className="p-d-flex p-jc-end" style={{ marginBottom: "-0.1rem", fontSize: "0.8rem" }}>
                                    {contactMessageWhatsapp.user !== null ? contactMessageWhatsapp.user.name : ""} &nbsp;&nbsp;&nbsp;
                                </p>
                                <div className="p-d-flex">
                                    <div className="p-d-flex p-jc-end">
                                        {widthWindow <= 454 ? (
                                            <>
                                                <a
                                                    onClick={() => {
                                                        setSelectReplyMessageChat(contactMessageWhatsapp)
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <i className="pi pi-arrow-circle-down" style={{ opacity: "0.4", margin: "0.3rem" }} />
                                                </a>
                                            </>
                                        ) : (
                                            <div>
                                                {idMessageForOnMouseOver === contactMessageWhatsapp.whatsapp_id ? (
                                                    <div style={{ margin: "0.3rem" }}>
                                                        <a
                                                            onClick={() => {
                                                                setSelectReplyMessageChat(contactMessageWhatsapp)
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <i className="iconReplyMessage pi pi-arrow-circle-down" />
                                                        </a>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        )}

                                        <div
                                            style={{
                                                backgroundColor: `${
                                                    contactMessageWhatsapp.type === "image" || contactMessageWhatsapp.type === "sticker" ? "" : "#D9FDD3"
                                                }`,
                                                padding: "0rem 0.3rem 0rem 0.3rem",
                                                border: "0.01rem solid #e4e5e7",
                                                borderRadius: "0.8rem 0.8rem 0rem 0.8rem",
                                                boxShadow: "0.04rem 0.04rem 0.06rem #d7d8db",
                                            }}
                                        >
                                            {!isEmptyObj(contactMessageWhatsapp.context_message_id) ? (
                                                <div
                                                    className="p-d-flex p-jc-center"
                                                    style={{
                                                        padding: "0.5rem",
                                                        margin: "0.5rem",
                                                        borderRadius: "0.5rem",
                                                        backgroundColor: "rgba(100, 105, 98, 0.2)",
                                                    }}
                                                >
                                                    {replyMessage(contactMessageWhatsapp)}
                                                </div>
                                            ) : (
                                                <></>
                                            )}

                                            <div className="p-d-flex p-jc-end p-ai-center">
                                                <div style={{ padding: "0.2rem" }}>
                                                    {contactMessageWhatsapp.attachment && (
                                                        <div>
                                                            {contactMessageWhatsapp.attachment.type_filter === "image" && (
                                                                <a
                                                                    onClick={() => {
                                                                        setViewImageMessage(true)
                                                                        setSelectedViewImageMessage(contactMessageWhatsapp)
                                                                    }}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            !isEmptyObj(contactMessageWhatsapp.attachment)
                                                                                ? contactMessageWhatsapp.attachment.file_url
                                                                                : ""
                                                                        }
                                                                        width={`${widthWindow <= 414 ? "200" : "300"}`}
                                                                        height="auto"
                                                                        alt="Imagem"
                                                                    />
                                                                </a>
                                                            )}

                                                            {contactMessageWhatsapp.attachment.type_filter === "document" && (
                                                                <div>
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <div>{archiveTypeForDocuments(contactMessageWhatsapp)}</div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="p-d-flex p-jc-center">
                                                                        <a
                                                                            href={
                                                                                contactMessageWhatsapp.attachment !== null
                                                                                    ? contactMessageWhatsapp.attachment.file_url
                                                                                    : ""
                                                                            }
                                                                            rel="noopener noreferrer"
                                                                            target="_blank"
                                                                        >
                                                                            <div className="dowloadFileDocument">
                                                                                <strong>DOWNLOAD</strong>
                                                                                &nbsp;&nbsp;
                                                                                <i className="pi pi-external-link"></i>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {contactMessageWhatsapp.attachment.type_filter === "video" && (
                                                                <div style={{ maxWidth: "75vw" }}>
                                                                    <ReactPlayer
                                                                        styles={{ backgroundColor: "red" }}
                                                                        autoPlay
                                                                        url={
                                                                            contactMessageWhatsapp.attachment !== null
                                                                                ? contactMessageWhatsapp.attachment.file_url
                                                                                : ""
                                                                        }
                                                                        width="100%"
                                                                        height="90%"
                                                                        controls={true}
                                                                    />
                                                                </div>
                                                            )}

                                                            {contactMessageWhatsapp.attachment.type_filter === "audio" && (
                                                                <div style={{ width: "20rem" }}>
                                                                    <audio
                                                                        src={
                                                                            contactMessageWhatsapp.attachment !== null
                                                                                ? contactMessageWhatsapp.attachment.file_url
                                                                                : ""
                                                                        }
                                                                        className="audio-1"
                                                                        controls
                                                                        playsInline={true}
                                                                        style={{
                                                                            width: "90%",
                                                                            height: "2.3rem",
                                                                            resize: "none",
                                                                            outline: "none",
                                                                            borderRadius: "5px",
                                                                            textDecoration: "bold",
                                                                            fontSize: "1rem",
                                                                            marginLeft: "0.3rem",
                                                                            marginRight: "0.3rem",
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}

                                                            {contactMessageWhatsapp.attachment.type_filter === "sticker" && (
                                                                <a
                                                                    onClick={() => {
                                                                        setViewImageMessage(true)
                                                                        setSelectedViewImageMessage(contactMessageWhatsapp)
                                                                    }}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            !isEmptyObj(contactMessageWhatsapp.attachment)
                                                                                ? contactMessageWhatsapp.attachment.file_url
                                                                                : ""
                                                                        }
                                                                        width="170"
                                                                        alt="Imagem"
                                                                    />
                                                                </a>
                                                            )}
                                                        </div>
                                                    )}

                                                    {contactMessageWhatsapp.type === "text" && (
                                                        <div
                                                            className="Container"
                                                            style={{
                                                                fontSize: "1.1rem",
                                                                textRendering: "optimizeLegibility",
                                                                maxWidth: `${widthWindow <= 414 ? "20rem" : "45rem"}`,
                                                                wordBreak: "normal",
                                                            }}
                                                            dangerouslySetInnerHTML={{ __html: formattedText }}
                                                        ></div>
                                                    )}

                                                    <div className="p-d-flex p-jc-end">
                                                        <a
                                                            onClick={() => {
                                                                if (contactMessageWhatsapp.status !== "failed") {
                                                                    setInformationDetailMessageVisualition("viewInformationMessage")
                                                                    setInformationDetailMessageChat(contactMessageWhatsapp)
                                                                }
                                                            }}
                                                            className="p-d-flex p-as-end"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <div className="p-d-flex p-as-end" style={{ fontSize: "0.65rem" }}>
                                                                <>
                                                                    <div style={{ color: "#797E88" }}>
                                                                        <Moment format="HH:mm">{contactMessageWhatsapp.created_at}</Moment>
                                                                    </div>{" "}
                                                                    &nbsp;
                                                                    {messageReadConfirmation(contactMessageWhatsapp)}
                                                                    &nbsp;
                                                                </>
                                                            </div>
                                                            <p style={{ display: "none" }}>{contactMessageWhatsapp.status}</p>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    &nbsp;
                                    <Avatar
                                        image={contactMessageWhatsapp.user.avatar_url !== null ? contactMessageWhatsapp.user.avatar_url : noPictureURL}
                                        className="p-mr-2"
                                        shape="circle"
                                        style={{ position: "unset" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const typeOfMessageReceivedForMobile = (
        contactMessageWhatsapp: MessagesWhatsappConversation,
        index: number,
        classNameForAnimation: any,
        colorBackground: any,
        textForJSX: any
    ) => {
        if (chatMessagesSearch[countIndexSearchMessageFilter] === contactMessageWhatsapp.id) {
            colorDivSeachedMessage = "rgba(141, 142, 143, 0.1)"
        } else {
            colorDivSeachedMessage = repliedMessageMarker(contactMessageWhatsapp)
        }

        const formatText = (text: string) => {
            // Format *Bold*
            text = text.replace(/\*([^*]+)\*/g, "<strong>$1</strong>")

            // Format _Italic_
            text = text.replace(/_([^_]+)_/g, "<em>$1</em>")

            // Format ~Strikethrough~
            text = text.replace(/~([^~]+)~/g, "<del>$1</del>")

            // Format ```Monospace```
            text = text.replace(/```([^`]+)```/g, "<code>$1</code>")

            // Format _*Bold Italic*_
            text = text.replace(/_\*\s*([^*_]+)\s*\*_/g, "<em><strong>$1</strong></em>")

            // Format *_~Strikethrough Bold~_*
            text = text.replace(/\*\*_~([^~]+)~_\*\*/g, "<strong><em><del>$1</del></em></strong>")

            return text
        }

        const formattedText = formatText(textForJSX)

        return (
            <>
                <div
                    id={`${contactMessageWhatsapp.id}mobile`}
                    ref={myRefCardMessageMobile}
                    className={`${classNameForAnimation}`}
                    style={{ marginLeft: "1.9rem", marginTop: "1rem" }}
                    onMouseOver={() => setIdMessageForOnMouseOver(contactMessageWhatsapp.whatsapp_id)}
                >
                    <div
                        className={`${classNameForAnimation}`}
                        style={{ padding: "0.8rem 0.8rem 0.8rem 0.8rem", backgroundColor: `${colorDivSeachedMessage}` }}
                    >
                        <div key={index} className="p-grid">
                            <div className="" key="1">
                                <div className="p-d-flex">
                                    <div
                                        className="p-d-flex p-ai-center"
                                        style={{
                                            backgroundColor: `${
                                                contactMessageWhatsapp.type === "image" || contactMessageWhatsapp.type === "sticker" ? "" : "white"
                                            }`,
                                            // maxWidth: `${widthWindow <= 414 ? "15rem" : "45rem"}`,
                                            padding: "0rem 0.3rem 0.1rem 0.3rem",
                                            border: "0.01rem solid #e4e5e7",
                                            borderRadius: "0.8rem 0.8rem 0.8rem 0rem",
                                            boxShadow: "0.04rem 0.04rem 0.06rem #d7d8db",
                                        }}
                                    >
                                        <div>
                                            {!isEmptyObj(contactMessageWhatsapp.context_message_id) ? (
                                                <div
                                                    className="p-d-flex p-jc-center"
                                                    style={{
                                                        padding: "0.5rem",
                                                        margin: "0.5rem",
                                                        backgroundColor: "rgba(100, 105, 98, 0.2)",
                                                        maxWidth: `${widthWindow <= 414 ? "20rem" : "45rem"}`,
                                                        borderRadius: "0.5rem",
                                                    }}
                                                >
                                                    {replyMessage(contactMessageWhatsapp)}
                                                </div>
                                            ) : (
                                                <></>
                                            )}

                                            <div style={{ padding: "0.2rem" }}>
                                                {contactMessageWhatsapp.attachment && (
                                                    <div>
                                                        {contactMessageWhatsapp.attachment.type_filter === "image" && (
                                                            <a
                                                                onClick={() => {
                                                                    setViewImageMessage(true)
                                                                    setSelectedViewImageMessage(contactMessageWhatsapp)
                                                                }}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <img
                                                                    src={
                                                                        !isEmptyObj(contactMessageWhatsapp.attachment)
                                                                            ? contactMessageWhatsapp.attachment.file_url
                                                                            : ""
                                                                    }
                                                                    width={`${widthWindow <= 414 ? "200" : "300"}`}
                                                                    height="auto"
                                                                    alt="Imagem"
                                                                />
                                                            </a>
                                                        )}

                                                        {contactMessageWhatsapp.attachment.type_filter === "document" && (
                                                            <div>
                                                                <div style={{ textAlign: "center" }}>
                                                                    <div>{archiveTypeForDocuments(contactMessageWhatsapp)}</div>
                                                                </div>
                                                                <br />
                                                                <div className="p-d-flex p-jc-center">
                                                                    <a
                                                                        href={
                                                                            contactMessageWhatsapp.attachment !== null
                                                                                ? contactMessageWhatsapp.attachment.file_url
                                                                                : ""
                                                                        }
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                    >
                                                                        <div className="dowloadFileDocument">
                                                                            <strong>DOWNLOAD</strong>
                                                                            &nbsp;&nbsp;
                                                                            <i className="pi pi-external-link"></i>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {contactMessageWhatsapp.attachment.type_filter === "video" && (
                                                            <div style={{ maxWidth: "75vw" }}>
                                                                <ReactPlayer
                                                                    styles={{ backgroundColor: "red" }}
                                                                    autoPlay
                                                                    url={
                                                                        contactMessageWhatsapp.attachment !== null
                                                                            ? contactMessageWhatsapp.attachment.file_url
                                                                            : ""
                                                                    }
                                                                    width="100%"
                                                                    height="90%"
                                                                    controls={true}
                                                                />
                                                            </div>
                                                        )}

                                                        {contactMessageWhatsapp.attachment.type_filter === "audio" && (
                                                            <div style={{ width: "20rem" }}>
                                                                <audio
                                                                    src={
                                                                        contactMessageWhatsapp.attachment !== null
                                                                            ? contactMessageWhatsapp.attachment.file_url
                                                                            : ""
                                                                    }
                                                                    className="audio-1"
                                                                    controls
                                                                    playsInline={true}
                                                                    style={{
                                                                        width: "90%",
                                                                        height: "2.3rem",
                                                                        resize: "none",
                                                                        outline: "none",
                                                                        borderRadius: "5px",
                                                                        textDecoration: "bold",
                                                                        fontSize: "1rem",
                                                                        marginLeft: "0.3rem",
                                                                        marginRight: "0.3rem",
                                                                    }}
                                                                />
                                                            </div>
                                                        )}

                                                        {contactMessageWhatsapp.attachment.type_filter === "sticker" && (
                                                            <a
                                                                onClick={() => {
                                                                    setViewImageMessage(true)
                                                                    setSelectedViewImageMessage(contactMessageWhatsapp)
                                                                }}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <img
                                                                    src={
                                                                        !isEmptyObj(contactMessageWhatsapp.attachment)
                                                                            ? contactMessageWhatsapp.attachment.file_url
                                                                            : ""
                                                                    }
                                                                    width="170"
                                                                    alt="Imagem"
                                                                />
                                                            </a>
                                                        )}
                                                    </div>
                                                )}

                                                {contactMessageWhatsapp.type === "text" && (
                                                    <div
                                                        className="Container"
                                                        style={{
                                                            fontSize: "1.1rem",
                                                            textRendering: "optimizeLegibility",
                                                            maxWidth: `${widthWindow <= 414 ? "20rem" : "45rem"}`,
                                                            wordBreak: "normal",
                                                        }}
                                                        dangerouslySetInnerHTML={{ __html: formattedText }}
                                                    ></div>
                                                )}
                                            </div>
                                            <div className="p-d-flex p-jc-end">
                                                <a
                                                    onClick={() => {
                                                        if (contactMessageWhatsapp.status !== "failed") {
                                                            setInformationDetailMessageVisualition("viewInformationMessage")
                                                            setInformationDetailMessageChat(contactMessageWhatsapp)
                                                        }
                                                    }}
                                                    className="p-d-flex p-as-end"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <div
                                                        className="p-d-flex p-as-end"
                                                        style={{
                                                            fontSize: "0.65rem",
                                                            color: "#797E88",
                                                            marginLeft: `${contactMessageWhatsapp.context_message_id !== null ? "-2rem" : "0"}`,
                                                        }}
                                                    >
                                                        <Moment
                                                            format="HH:mm"
                                                            style={{
                                                                marginRight: `${contactMessageWhatsapp.context_message_id !== null ? "0rem" : "0.1rem"}`,
                                                            }}
                                                        >
                                                            {contactMessageWhatsapp.created_at}
                                                        </Moment>{" "}
                                                        &nbsp;&nbsp;
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {widthWindow <= 454 ? (
                                        <>
                                            <a
                                                onClick={() => {
                                                    setSelectReplyMessageChat(contactMessageWhatsapp)
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <i className="pi pi-arrow-circle-down" style={{ opacity: "0.4", margin: "0.3rem" }} />
                                            </a>
                                        </>
                                    ) : (
                                        <div>
                                            {idMessageForOnMouseOver === contactMessageWhatsapp.whatsapp_id ? (
                                                <div style={{ margin: "0.3rem" }}>
                                                    <a
                                                        onClick={() => {
                                                            setSelectReplyMessageChat(contactMessageWhatsapp)
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <i className="iconReplyMessage pi pi-arrow-circle-down" />
                                                    </a>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const archiveTypeForDocuments = (contactMessageWhatsapp: MessagesWhatsappConversation) => {
        let splitArchive = contactMessageWhatsapp.attachment.file.split(".")
        let lengthSplit = splitArchive.length
        if (splitArchive[lengthSplit - 1] === "pdf") {
            return (
                <iframe
                    src={contactMessageWhatsapp.attachment !== null ? contactMessageWhatsapp.attachment.file_url : ""}
                    frameBorder="0"
                    seamless={false}
                    title="document"
                    scrolling="no"
                    width="100%"
                    style={{ border: "none", overflow: "hidden" }}
                    height="98%"
                />
            )
        } else {
            return (
                <div>
                    <br />
                    <div className="p-d-flex p-jc-center">
                        <i className="pi pi-file" style={{ fontSize: "3em", color: "#54656f" }}></i>
                    </div>
                    <br />
                    <div className="p-d-flex p-jc-center" style={{ color: "#54656f" }}>
                        {contactMessageWhatsapp.attachment.name}
                    </div>
                </div>
            )
        }
    }

    const repliedMessageMarker = (contactMessageWhatsapp: MessagesWhatsappConversation) => {
        if (!isEmptyObj(selectReplyMessageChat)) {
            if (contactMessageWhatsapp.whatsapp_id === selectReplyMessageChat.whatsapp_id) {
                return "rgba(141, 142, 143, 0.1)"
            }
        }

        if (!isEmptyObj(idReplyMessage)) {
            if (contactMessageWhatsapp.id === idReplyMessage) {
                return "rgba(141, 142, 143, 0.1)"
            }
        }
        return ""
    }

    useEffect(() => {
        setTimeout(() => {
            executeScrollMobile()
        }, 50)
    }, [conversationSelected])

    return (
        <div>
            <div className={`${chatDialogMobile}`}>
                <div>
                    <div
                        className={`${chatDialogMobile}`}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100vw",
                            height: "calc(100% - 60px)",
                            backgroundColor: "white",
                            position: "fixed",
                        }}
                    >
                        {/* HEADER */}
                        <div className="headerChatMobile">
                            {!onSearchMessageChatMobile && (
                                <div
                                    style={{ width: "100%", height: "8vh", paddingLeft: "1.5rem", paddingRight: "1.5rem" }}
                                    className="p-d-flex p-jc-between p-ai-center"
                                >
                                    <div className="p-d-flex p-ai-center">
                                        <Avatar
                                            image={
                                                !isEmptyObj(conversationSelected.contact)
                                                    ? conversationSelected.contact.company
                                                        ? conversationSelected.contact.company.picture_url !== null
                                                            ? conversationSelected.contact.company.picture_url
                                                            : noPictureURL
                                                        : noPictureURL
                                                    : noPictureURL
                                            }
                                            className="avatarChat p-mr-3"
                                            shape="circle"
                                        />
                                        <p className="p-text-nowrap p-text-truncate" style={{ fontSize: "1.5rem", fontWeight: "700", width: "55vw" }}>
                                            {!isEmptyObj(conversationSelected.contact) ? conversationSelected.contact.name : conversationSelected.phone_number}
                                        </p>
                                    </div>

                                    <div className="p-d-flex p-ai-center">
                                        <a
                                            className="p-mr-3"
                                            onClick={() => {
                                                setOnSearchMessageChatMobile(true)
                                            }}
                                        >
                                            <i className="pi pi-search p-mr-4" />
                                        </a>

                                        <a
                                            className=""
                                            onClick={() => {
                                                setChatDialogMobile("disabled")
                                                setScreenMobile("screen")
                                            }}
                                        >
                                            <i className="pi pi-times" />
                                        </a>
                                    </div>
                                </div>
                            )}

                            {onSearchMessageChatMobile && (
                                <div style={{ width: "100%", height: "8vh" }} className="p-d-flex p-jc-center p-ai-center">
                                    {viewButtonsSearchMessageUpOrDown ? (
                                        <>
                                            {countIndexSearchMessageFilter >= 0 && !isEmptyObj(chatMessagesSearch) ? (
                                                <a
                                                    href={`#${chatMessagesSearch[countIndexSearchMessageFilter]}mobile`}
                                                    onClick={() => {
                                                        downScrollSearch()
                                                        handleIdSearchMessageDiv(chatMessagesSearch[countIndexSearchMessageFilter - 1])
                                                    }}
                                                >
                                                    <div
                                                        className="p-d-flex p-jc-center p-ai-center"
                                                        style={{ width: "2rem", height: "2rem", borderRadius: "50%" }}
                                                    >
                                                        <i className="pi pi-arrow-up" style={{ color: "#54656f" }}></i>
                                                    </div>
                                                </a>
                                            ) : (
                                                <div></div>
                                            )}
                                            &nbsp;
                                            {countIndexSearchMessageFilter < chatMessagesSearch.length ? (
                                                <a
                                                    href={`#${chatMessagesSearch[countIndexSearchMessageFilter]}mobile`}
                                                    onClick={() => {
                                                        upScrollSearch()
                                                        handleIdSearchMessageDiv(chatMessagesSearch[countIndexSearchMessageFilter + 1])
                                                    }}
                                                >
                                                    <div
                                                        className="p-d-flex p-jc-center p-ai-center"
                                                        style={{ width: "2rem", height: "2rem", borderRadius: "50%" }}
                                                    >
                                                        <i className="pi pi-arrow-down" style={{ color: "#54656f" }}></i>
                                                    </div>
                                                </a>
                                            ) : (
                                                <div></div>
                                            )}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    <InputText
                                        value={inputTextSearchMessagesChat}
                                        id="autofocs"
                                        className="inputTextInHeaderContact"
                                        onKeyDown={(e: any) => {
                                            handleKeyPressSearchMessage(e)
                                        }}
                                        onChange={(e) => {
                                            setInputTextSearchMessagesChat(e.target.value)
                                        }}
                                        autoFocus
                                        placeholder="Pesquisar ..."
                                    />
                                    &nbsp;
                                    <Button
                                        icon="pi pi-search"
                                        className="p-button-rounded p-button-text"
                                        style={{ color: "#54656f" }}
                                        onClick={() => chatMessageFilter(inputTextSearchMessagesChat, conversationSelected)}
                                    />
                                    <Button
                                        icon="pi pi-times"
                                        className="p-button-rounded p-button-text"
                                        style={{ color: "#54656f" }}
                                        onClick={() => {
                                            setOnSearchMessageChatMobile(false)
                                            setOnSearchMessageChatMobile(false)
                                            setInputTextSearchMessagesChat("")
                                            setViewButtonsSearchMessageUpOrDown(false)
                                            setColorDivSeachedMessage("")
                                            setChatMessagesSearch()
                                        }}
                                    />
                                </div>
                            )}
                        </div>

                        {/*BODY */}
                        <div className="messagesBodyContact">
                            {inputTextSendMessage === "" && (
                                <Button
                                    icon="pi pi-angle-double-down"
                                    className="p-button-rounded p-button-secondary"
                                    id="scroll"
                                    style={{
                                        position: "absolute",
                                        right: "2%",
                                        opacity: "0.4",
                                    }}
                                    onClick={() => {
                                        executeScrollMobile()
                                    }}
                                />
                            )}

                            <br />
                            {!isEmptyObj(conversationSelected) &&
                                conversationSelected.messages.map((contactMessageWhatsapp, index) => {
                                    const previousMessage = conversations[index - 1]
                                    let textForJSX =
                                        contactMessageWhatsapp.type === "text"
                                            ? contactMessageWhatsapp.text !== null
                                                ? contactMessageWhatsapp.text.replaceAll("\n", "<br />")
                                                : ""
                                            : ""
                                    let colorBackground = "white"
                                    let classNameForAnimation = "notAnimated"

                                    if (contactMessageWhatsapp.status === "received") {
                                        return (
                                            <div key={index}>
                                                <>
                                                    {dividerDataInChatMessages(contactMessageWhatsapp, previousMessage, indexSended)}
                                                    {typeOfMessageReceivedForMobile(
                                                        contactMessageWhatsapp,
                                                        index,
                                                        classNameForAnimation,
                                                        colorBackground,
                                                        textForJSX
                                                    )}
                                                    {valueIndexMessageDate(index)}
                                                </>
                                            </div>
                                        )
                                    }
                                    if (
                                        // contactMessageWhatsapp.phone_number === conversationSelected.phone_number &&
                                        contactMessageWhatsapp.status !== "received"
                                    ) {
                                        return (
                                            <div key={index}>
                                                <>
                                                    {dividerDataInChatMessages(contactMessageWhatsapp, previousMessage, indexSended)}
                                                    {typeOfMessageSentForMobile(
                                                        contactMessageWhatsapp,
                                                        index,
                                                        classNameForAnimation,
                                                        colorBackground,
                                                        textForJSX
                                                    )}
                                                    {valueIndexMessageDate(index)}
                                                </>
                                            </div>
                                        )
                                    }
                                })}
                        </div>

                        {/*FOOTER */}
                        <div className="sendMessage p-d-flex p-jc-center">
                            <div>
                                {!isEmptyObj(selectReplyMessageChat) ? (
                                    <div>
                                        <ReplyMessageChat
                                            selectReplyMessageChat={selectReplyMessageChat}
                                            profileUser={profileUser}
                                            setSelectReplyMessageChat={handleSetReplyMessage}
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                                <div className="p-d-flex p-jc-center p-ai-center" style={{ width: "100vw" }}>
                                    {/* Certo ^ */}
                                    <div className="p-d-flex p-jc-center p-ai-center">
                                        <>
                                            <div
                                                className="p-d-flex p-ai-center"
                                                style={{
                                                    padding: "0.3rem",
                                                    marginTop: `${marginTopWindowInput}vh`,
                                                }}
                                            >
                                                <span className="p-input-icon-right">
                                                    {isEmptyObj(inputTextSendMessage) && statusClose === false && !verification24 ? (
                                                        <i>
                                                            <div className="p-d-flex p-ai-center " style={{ marginTop: "-0.65rem" }}>
                                                                <Button
                                                                    icon="pi pi-globe"
                                                                    className="p-button-rounded p-button-text p-mr-1"
                                                                    onClick={() => {
                                                                        setImageUploadDialog(true)
                                                                    }}
                                                                    style={{
                                                                        zIndex: "99",
                                                                        color: "rgb(84, 101, 111)",
                                                                    }}
                                                                ></Button>
                                                                <Button
                                                                    icon="pi pi-paperclip"
                                                                    className=" p-button-sm p-button-rounded p-button-text p-mr-2"
                                                                    style={{
                                                                        marginLeft: "0.5rem",
                                                                        color: "rgb(84, 101, 111)",
                                                                    }}
                                                                    onClick={() => setUploadFromFilesDialog(true)}
                                                                />
                                                            </div>
                                                        </i>
                                                    ) : (
                                                        ""
                                                    )}

                                                    {!isActive && (
                                                        <div id="w-input-container">
                                                            <div className="w-input-text-group">
                                                                <div
                                                                    id="w-input-text"
                                                                    contentEditable="true"
                                                                    suppressContentEditableWarning={true}
                                                                    onInput={(e: any) => setInputTextSendMessage(e.currentTarget.textContent)}
                                                                    onKeyDown={(e: any) => {
                                                                        handleKeyPress(e)
                                                                    }}
                                                                />
                                                                <div className="w-placeholder">Digite uma mensagem...</div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </span>

                                                {inputTextSendMessage === "" && (
                                                    <div className=" p-button-sm p-button-rounded p-button-text p-d-flex p-ai-center"></div>
                                                )}

                                                {inputTextSendMessage === "" && !verification24 && (
                                                    <AudioRecorder
                                                        setMicRecprder={updatesetMicRecprder}
                                                        setRecording={updateSetRecording}
                                                        setIsActive={updateIsActive}
                                                        setStatusClose={updateSetStatusClose}
                                                        setSendFileAudio={updateSetSendFileAudio}
                                                        onSendAudio={onSendAudio}
                                                        onNotification={toastTrue}
                                                    />
                                                )}
                                                {statusClose === false && inputTextSendMessage !== "" && !verification24 && (
                                                    <Button
                                                        icon="pi pi-send"
                                                        className="p-button-rounded p-button-secondary p-mr-3 p-button-microphone"
                                                        onClick={(e) => {
                                                            onSendMessagetoContact(e)
                                                            executeScroll()
                                                        }}
                                                        style={{
                                                            width: "2.6rem",
                                                            height: "2.6rem",
                                                            boxShadow: "0.03rem 0.3Srem 0.3rem #b0b0b1",
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </>
                                    </div>
                                </div>
                                &nbsp; &nbsp;
                                {/* Certo ^ */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
