import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { InputText } from "primereact/inputtext"
import { MultiSelect } from "primereact/multiselect"
import { Rating } from "primereact/rating"
import { useEffect, useState } from "react"
import { OpportunityInterface } from "../../../interfaces/OpportunityInterface"
import { selectionPriorities } from "../../../listsArrays/ListsArrays"
import { UserInterface } from "../../../interfaces/UserInterface"
import { FunnelInterface } from "../../../interfaces/FunnelInterface"

interface StatusInterface {
    name: string
    code: string
}

interface FunnelAuxInterface {
    name: string
    val: string
}

interface PropsInterface {
    opportunities: OpportunityInterface[]
    auxOpportunities: OpportunityInterface[]
    setAuxOpportunities: React.Dispatch<React.SetStateAction<OpportunityInterface[]>>
    setLoadingUpdateAsOpportunities: React.Dispatch<React.SetStateAction<boolean>>
    allClearOpportunity: any
    listOfSellers: UserInterface[]
    loadingUpdateAsOpportunities: boolean
    toast: any
    funnel: FunnelInterface
}

const ClearOpportunitiesFilters = ({
    opportunities,
    auxOpportunities,
    setAuxOpportunities,
    setLoadingUpdateAsOpportunities,
    listOfSellers,
    allClearOpportunity,
    loadingUpdateAsOpportunities,
    toast,
    funnel,
}: PropsInterface) => {
    let [listOfStatus, setListOfStatus] = useState<StatusInterface[]>([])
    let [listOfState, setListOfState] = useState<StatusInterface[]>([])
    let [listOfFunnel, setListOfFunnel] = useState<FunnelAuxInterface[]>([])
    let [listOfStage, setListOfStage] = useState<StatusInterface[]>([])

    let [selectedStateFilter, setSelectedStateFilter] = useState<any[]>([])
    let [selectedFunnelStage, setSelectedFunnelStage] = useState<any[]>([])
    let [selectedStageFilter, setSelectedStageFilter] = useState<any[]>([])

    let [selectedSellerFilter, setSelectedSellerFilter] = useState<any[]>([])
    let [selectedPriorityFilter, setSelectedPriorityFilter] = useState<any[]>([])
    let [dateFinal, setDateFinal] = useState("")
    let [dateInit, setDateInit] = useState("")

    let [selectedStatusFilter, setSelectedStatusFilter] = useState<any[]>([])
    let [handleButtonNewOpportunities, setHandleButtonNewOpportunities] = useState(false)

    let [advancedSearchFilter, setAdvancedSearchFilter] = useState("")

    const colorNewCardOpportunity = () => (handleButtonNewOpportunities ? "#22C55E" : "")

    const groupedItemTemplate = (option: { code: number }): JSX.Element => {
        return (
            <div className="p-d-flex p-ai-center country-item">
                <Rating value={option.code} cancel={false} />
            </div>
        )
    }

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    const filter = () => {
        if (
            selectedSellerFilter.length > 0 ||
            dateInit !== "" ||
            selectedStatusFilter.length > 0 ||
            dateFinal !== "" ||
            !(advancedSearchFilter === "") ||
            selectedStageFilter.length > 0 ||
            selectedStateFilter.length > 0 ||
            selectedFunnelStage.length > 0 ||
            handleButtonNewOpportunities === true ||
            selectedPriorityFilter.length > 0 ||
            !isEmptyObj(funnel)
        ) {
            var listOpportunitiesFiltered: OpportunityInterface[] = []
            for (let atualLine of opportunities) {
                var addLine = true

                if (selectedSellerFilter.length > 0 && addLine) {
                    const validateSearch = selectedSellerFilter.findIndex((filter) => filter.id === atualLine.user.id)

                    if (validateSearch === -1) {
                        addLine = false
                    }
                }

                if (selectedStatusFilter.length > 0 && addLine) {
                    const validateSearch = selectedStatusFilter.findIndex((filter) => filter.code === atualLine.status)
                    if (validateSearch === -1) {
                        addLine = false
                    }
                }

                if (selectedPriorityFilter.length > 0 && addLine) {
                    const validateSearch = selectedPriorityFilter.findIndex((filter) => filter.code === atualLine.qualification)
                    if (validateSearch === -1) {
                        addLine = false
                    }
                }

                if (selectedStateFilter.length > 0 && addLine) {
                    const validateSearch = selectedStateFilter.findIndex((filter) => filter.code === atualLine.company.state)
                    if (validateSearch === -1) {
                        addLine = false
                    }
                }

                if (selectedStageFilter.length > 0 && addLine) {
                    const validateSearch = selectedStageFilter.findIndex((filter) => filter.code === atualLine.funnelStage.id)
                    if (validateSearch === -1) {
                        addLine = false
                    }
                }

                if (selectedFunnelStage.length > 0 && addLine) {
                    const validateSearch = selectedFunnelStage.findIndex((filter) => filter.val === atualLine.funnel_level)
                    if (validateSearch === -1) {
                        addLine = false
                    }
                }

                if (handleButtonNewOpportunities) {
                    if (atualLine.new_on_funnel !== true) {
                        addLine = false
                    }
                }

                let newAtualLineGetTime = new Date(
                    new Date(atualLine.created_at).getFullYear(),
                    new Date(atualLine.created_at).getMonth(),
                    new Date(atualLine.created_at).getDate()
                )

                if (dateInit !== "" && addLine) {
                    let newDateInitGetTime = new Date(new Date(dateInit).getFullYear(), new Date(dateInit).getMonth(), new Date(dateInit).getDate())
                    if (newAtualLineGetTime.getTime() < newDateInitGetTime.getTime()) {
                        addLine = false
                    } else {
                    }
                }

                if (dateFinal !== "" && addLine) {
                    let newDateFinalGetTime = new Date(new Date(dateFinal).getFullYear(), new Date(dateFinal).getMonth(), new Date(dateFinal).getDate())
                    if (newAtualLineGetTime.getTime() > newDateFinalGetTime.getTime()) {
                        addLine = false
                    } else {
                    }
                }

                if (!(advancedSearchFilter === "") && addLine) {
                    let numberString = "000000" + atualLine.number.toString().slice(-6)
                    addLine = false

                    var word = advancedSearchFilter.toLowerCase().split(" ") || ""
                    if (!atualLine.user.name) atualLine.user.name = ""
                    if (!atualLine.company.name) atualLine.company.name = ""
                    if (!atualLine.company.city) atualLine.company.city = ""
                    if (!numberString) numberString = ""
                    if (!atualLine.id) atualLine.id = ""
                    for (var oneWord in word) {
                        oneWord = oneWord.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

                        if (
                            !atualLine.user.name.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.name.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.company.name.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.company.city.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !numberString.includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.id.toLowerCase().includes(advancedSearchFilter.toLowerCase())
                        ) {
                            addLine = false
                            break
                        } else {
                            addLine = true
                        }
                    }
                }

                if (addLine) {
                    listOpportunitiesFiltered.push(atualLine)
                }
            }
            auxOpportunities = listOpportunitiesFiltered
            setAuxOpportunities(listOpportunitiesFiltered)
        } else {
            auxOpportunities = opportunities
            setAuxOpportunities(opportunities)
        }
    }

    const onLoadingCleanFilters = () => {
        dateInit = ""
        setDateInit("")
        dateFinal = ""
        setDateFinal("")
        setSelectedSellerFilter([])
        selectedSellerFilter = []
        setSelectedPriorityFilter([])
        selectedPriorityFilter = []
        setSelectedStatusFilter([])
        selectedStatusFilter = []
        setSelectedStageFilter([])
        selectedStageFilter = []
        setSelectedStateFilter([])
        setSelectedFunnelStage([])
        selectedFunnelStage = []
        selectedStateFilter = []
        handleButtonNewOpportunities = false
        setHandleButtonNewOpportunities(handleButtonNewOpportunities)
        setAdvancedSearchFilter("")
        advancedSearchFilter = ""
        filter()
    }

    useEffect(() => {
        const statusList: StatusInterface[] = []
        const stateList: StatusInterface[] = []
        const stageList: StatusInterface[] = []
        const funnelList: FunnelAuxInterface[] = []

        opportunities.forEach((item) => {
            const findStatus = statusList.find((user) => user.code === item.status)
            const findStage = stageList.find((user) => user.code === item.funnelStage.id)
            const findState = stateList.find((user) => user.code === item.company.state)

            if (!funnelList.some((i: any) => i.val === item.funnel_level)) {
                let name = ""
                switch (item.funnel_level) {
                    case "top":
                        name = "Topo"
                        break

                    case "middle":
                        name = "Meio"
                        break

                    case "bottom":
                        name = "Fundo"
                        break

                    default:
                        break
                }

                if (name !== "") {
                    funnelList.push({ name: name, val: item.funnel_level })
                }
            }

            if (!findStatus) {
                if (item.status === "open") statusList.push({ name: item.status.replace("open", "ABERTA"), code: item.status })
                if (item.status === "gain") statusList.push({ name: item.status.replace("gain", "GANHA"), code: item.status })
                if (item.status === "loss") statusList.push({ name: item.status.replace("loss", "PERDIDO"), code: item.status })
                if (item.status === "stopped") statusList.push({ name: item.status.replace("stopped", "SUSPENSO"), code: item.status })
                if (item.status === "deleted") statusList.push({ name: item.status.replace("deleted", "EXCLUÍDA"), code: item.status })
                if (item.status === "disqualified") statusList.push({ name: item.status.replace("disqualified", "DESQUALIFICADA"), code: item.status })
            }

            if (!findStage) {
                stageList.push({ name: item.funnelStage.name.toUpperCase(), code: item.funnelStage.id })
            }

            if (!findState) {
                if (item.company.state !== null) {
                    stateList.push({ name: item.company.state.toUpperCase(), code: item.company.state })
                }
            }
        })

        setListOfStatus(statusList)
        setListOfState(stateList)
        setListOfStage(stageList)
        setListOfFunnel(funnelList)
    }, [opportunities])

    return (
        <>
            <Calendar
                disabled={!isEmptyObj(opportunities) ? false : true}
                style={{ width: "10rem" }}
                className="filterPattern p-mr-2 p-mb-2"
                dateFormat="dd/mm/yy"
                id="icon"
                locale="pt"
                value={new Date(dateInit)}
                onMonthChange={() => ""}
                onChange={(e: any) => {
                    setDateInit(e.value)
                    filter()
                }}
                showIcon
                placeholder={"INÍCIO"}
            />
            <Calendar
                disabled={!isEmptyObj(opportunities) ? false : true}
                style={{ width: "10rem" }}
                className="filterPattern p-mr-2 p-mb-2"
                dateFormat="dd/mm/yy"
                id="icon"
                value={new Date(dateFinal)}
                onMonthChange={() => ""}
                onChange={(e: any) => {
                    setDateFinal(e.value)
                    filter()
                }}
                showIcon
                placeholder={"FIM"}
            />
            <MultiSelect
                disabled={!isEmptyObj(opportunities) ? false : true}
                style={{ marginTop: "1rem" }}
                className="p-mr-2"
                selectedItemsLabel="{0} itens selecionados"
                value={selectedSellerFilter}
                options={listOfSellers}
                onChange={(e: any) => {
                    selectedSellerFilter = e.value
                    setSelectedSellerFilter(e.value)
                    filter()
                }}
                emptyFilterMessage={"Não há dados"}
                filter={true}
                optionLabel="name"
                placeholder="VENDEDOR"
            />
            <MultiSelect
                disabled={!isEmptyObj(opportunities) ? false : true}
                className=""
                value={selectedPriorityFilter}
                options={selectionPriorities}
                onChange={(e: any) => {
                    selectedPriorityFilter = e.value
                    setSelectedPriorityFilter(selectedPriorityFilter)
                    filter()
                }}
                itemTemplate={groupedItemTemplate}
                emptyFilterMessage={"Não há dados"}
                optionLabel="name"
                selectedItemsLabel="{0} itens selecionados"
                placeholder="TEMPERATURA"
            />
            <MultiSelect
                disabled={!isEmptyObj(opportunities) ? false : true}
                className="filterPattern p-mr-2 p-mb-2"
                value={selectedStatusFilter}
                style={{ width: "10rem", marginTop: "1rem", marginLeft: "0.8rem" }}
                options={listOfStatus}
                onChange={(e: any) => {
                    selectedStatusFilter = e.value
                    setSelectedStatusFilter(selectedStatusFilter)
                    filter()
                }}
                emptyFilterMessage={"Não há dados"}
                filter={true}
                optionLabel="name"
                selectedItemsLabel="{0} itens selecionados"
                placeholder="STATUS"
            />
            <MultiSelect
                disabled={!isEmptyObj(opportunities) ? false : true}
                className="p-mr-2"
                style={{ width: "10rem", marginLeft: "0.8rem" }}
                value={selectedStageFilter}
                options={listOfStage}
                selectedItemsLabel="{0} itens selecionados"
                onChange={(e: any) => {
                    selectedStageFilter = e.value
                    setSelectedStageFilter(e.value)
                    filter()
                }}
                emptyFilterMessage={"Não há dados"}
                filter={true}
                optionLabel="name"
                placeholder="ETAPA"
            />
            <MultiSelect
                disabled={!isEmptyObj(opportunities) ? false : true}
                className="filterPattern p-mr-2 p-mb-2"
                value={selectedStateFilter}
                style={{ width: "10rem" }}
                options={listOfState}
                onChange={(e: any) => {
                    selectedStateFilter = e.value
                    setSelectedStateFilter(selectedStateFilter)
                    filter()
                }}
                emptyFilterMessage={"Não há dados"}
                filter={true}
                optionLabel="name"
                placeholder="ESTADO"
                selectedItemsLabel="{0} itens selecionados"
            />
            {/* <MultiSelect
                disabled={!isEmptyObj(opportunities) ? false : true}
                className="filterPattern p-mr-2 p-mb-2"
                value={selectedFunnelStage}
                style={{ width: "10rem" }}
                options={listOfFunnel}
                optionLabel="name"
                onChange={(e: any) => {
                    selectedFunnelStage = e.value
                    setSelectedFunnelStage(selectedFunnelStage)
                    filter()
                }}
                emptyFilterMessage={"Não há dados"}
                placeholder="FUNIL"
                selectedItemsLabel="{0} itens selecionados"
            /> */}
            <InputText
                disabled={!isEmptyObj(opportunities) ? false : true}
                style={{ width: "40rem" }}
                value={advancedSearchFilter}
                onChange={(e: any) => {
                    advancedSearchFilter = e.target.value
                    setAdvancedSearchFilter(advancedSearchFilter)
                    filter()
                }}
                placeholder="PESQUISA AVANÇADA..."
            />
            <br />
            <br />
            <Button
                className="p-button-rounded p-mr-2 p-mb-2"
                style={{ backgroundColor: `${colorNewCardOpportunity()}`, border: "none", marginBottom: "0.6rem", marginLeft: "1rem" }}
                loading={false}
                icon="pi pi-star"
                data-tip
                label="APENAS NOVAS OPORTUNIDADES"
                onClick={() => {
                    handleButtonNewOpportunities = !handleButtonNewOpportunities
                    setHandleButtonNewOpportunities(handleButtonNewOpportunities)
                    filter()
                }}
            />
            <Button
                icon="pi pi-refresh"
                label="ATUALIZAR"
                style={{ marginBottom: "0.5rem" }}
                className="p-button-rounded p-mr-2"
                loading={loadingUpdateAsOpportunities}
                onClick={() => {
                    setLoadingUpdateAsOpportunities(true)
                    allClearOpportunity()

                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Atualizado com sucesso",
                        life: 2000,
                    })
                }}
            />
            <Button
                style={{ marginBottom: "0.5rem" }}
                className="p-button-rounded p-mr-2"
                icon="pi pi-times"
                label="LIMPAR FILTROS"
                onClick={onLoadingCleanFilters}
            />
            <br /> <br />
        </>
    )
}

export default ClearOpportunitiesFilters
