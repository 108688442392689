import React, { createContext, useCallback, useState, useContext, useEffect, FC, useRef } from "react"

import api from "../services/api"
import { useLocation } from "react-router-dom"
import { addLocale, locale } from "primereact/api"
import { Toast } from "primereact/toast"

interface User {
    id: string
    name: string
    email: string
    phone: string
    status: boolean
    avatar: string
    sign: string
    created_at: string
    updated_at: string
    avatar_url: string
    sign_url: string
    whatsapp_phone_number: string
}

interface SignInCredentials {
    email: string
    password: string
}

interface AtuhContextUser {
    user: User

    signIn(credentials: SignInCredentials): Promise<any>
    signOut(): void
    validateSession(): Promise<boolean>
    captureUrl(): void
    editUser(user: User): void
}

interface DataUser {
    token: string
    user: User
}

export const AuthConxtet = createContext<AtuhContextUser>({} as AtuhContextUser)

export const AuthProvider: FC<any> = ({ children }) => {
    let toast = useRef<Toast>(null)

    let [permission, setPermission] = useState(false)

    let location = useLocation()

    const captureUrl = useCallback(() => {
        if (location.pathname !== "/login" && location.pathname !== "/") {
            localStorage.setItem("@esquadros:url", location.pathname + location.search)
        }
    }, [location])

    const [dataUser, setDataUser] = useState<DataUser>(() => {
        const token = localStorage.getItem("@esquadros:token")
        const user = localStorage.getItem("@esquadros:user")

        if (token && user) {
            return { token, user: JSON.parse(user) }
        }

        return {} as DataUser
    })

    let [notifications, setNotifications] = useState<any[]>([])

    // const allNotifications = async () => {
    //     try {
    //         const header = {
    //             headers: {
    //                 route: "list_profile_notifications",
    //             },
    //         }

    //         const response = await api.get("notifications/profile", header)
    //         const notifications = response.data

    //         setNotifications(notifications)
    //         localStorage.setItem("@esquadros:notifications", JSON.stringify(notifications))
    //     } catch (error) {
    //         console.error("Error fetching notifications:", error)
    //     }
    // }

    const signIn = useCallback(async (data: any) => {
        const responseIp = await fetch("https://ipapi.co/json/")
        const ipData = await responseIp.json()
        const ip = ipData.ip
        const isInternalIp = ip === "186.249.38.84"

        try {
            const { email, password } = data
            const response = await api.post("sessions", {
                email,
                password,
            })

            const { token, user } = response.data
            localStorage.setItem("@esquadros:token", token)
            localStorage.setItem("@esquadros:user", JSON.stringify(user))
            // allNotifications()
            setDataUser({ token, user })

            const header = {
                headers: {
                    route: "external_use",
                },
            }
            const res = await api.post("permission/check", {}, header)
            if (res.status === 200) {
                permission = true
                setPermission(permission)
            } else {
                permission = false
                setPermission(permission)
            }

            if (!permission) {
                signOut()
                return
            }

            if (!isInternalIp) {
                signOut()
                toast.current?.show({ severity: "error", summary: "Erro", detail: "Acesso não autorizado" })
                return
            }

            return response.data
        } catch (error: any) {
            console.log(error)
        }
    }, [])

    const signOut = useCallback(() => {
        localStorage.removeItem("@esquadros:token")
        localStorage.removeItem("@esquadros:user")
        localStorage.removeItem("@esquadros:url")
        localStorage.removeItem("@esquadros:passwordProposal")
        localStorage.removeItem("@esquadros:contactCompanySelected")
        setDataUser({} as DataUser)
    }, [])

    const editUser = useCallback(async (user: User) => {
        localStorage.removeItem("@esquadros:user")
        localStorage.setItem("@esquadros:user", JSON.stringify(user))

        setDataUser({ ...dataUser, [`user`]: user })
    }, [])

    addLocale("pt", {
        firstDayOfWeek: 1,
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        clear: "Limpar",
        today: "Hoje",
    })

    locale("pt")

    const validateSession = useCallback(async () => {
        const token = localStorage.getItem("@esquadros:token")
        api.defaults.headers.authorization = `Bearer ${token}`
        let header = {
            headers: {
                route: "validate_session",
            },
        }

        const response = await api.get("sessions", header)

        if (response.data.status) {
            return true
        } else {
            signOut()
            return false
        }
    }, [signOut])

    useEffect(() => {
        captureUrl()
    }, [captureUrl])

    return (
        <>
            <Toast ref={toast} />
            <AuthConxtet.Provider
                value={{
                    user: dataUser.user,
                    signIn,
                    signOut,
                    validateSession,
                    captureUrl,
                    editUser,
                }}
            >
                {children}
            </AuthConxtet.Provider>
        </>
    )
}

export function useAuth(): AtuhContextUser {
    const context = useContext(AuthConxtet)

    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider")
    }

    return context
}
