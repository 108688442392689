import React, { useState, useRef, useEffect } from "react"
import App from "../../layout/App"
import api from "../../services/api"
import Moment from "react-moment"

//PrimeReact
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Toast } from "primereact/toast"

//Interface
import { listProduct } from "../../interfaces/ListProductInterface"
import { PurchaseQuote } from "../../interfaces/PurchaseQuote"
import { Provider } from "../../interfaces/ProviderInterface"

export const OpenQuotes: React.FC = () => {
    const toast: any = useRef(null)
    const token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    const [loadingList, setLoadingList] = useState<boolean>(true)
    const [loadingPage, setLoadingPage] = useState<boolean>(true)
    const [loadingProduct, setLoadingProduct] = useState<boolean>(true)

    const [purchaseQuotes, setPurchaseQuotes] = useState<PurchaseQuote[]>([])
    const [purchaseQuote, setPurchaseQuote] = useState<PurchaseQuote>({} as PurchaseQuote)

    const [globalFilter, setGlobalFilter] = useState<string | null>(null)
    const [globalFilterPermissions] = useState<string | null>(null)
    const [globalFilterProducts] = useState<string | null>(null)
    const [providers, setProviders] = useState<Provider[]>([])

    const [products, setProducts] = useState<listProduct[]>([])
    const [productsDialog, setProductsDialog] = useState<boolean>(false)

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Listagem de Cotações em Aberto</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e: any) => setGlobalFilter(e.target.value)} placeholder="Pesquisar..." />
            </span>
        </div>
    )

    const buttonFromDataTable = (rowData: PurchaseQuote) => {
        return (
            <div className="actions">
                <Button className="p-button" label="Fornecedores" icon="pi pi-search" onClick={() => actionProvidersProducts(rowData)} />
            </div>
        )
    }

    const activeInactive = (rowData: Provider) => {
        let statusClass = ""
        let name = ""

        switch (rowData.shipping_status) {
            case "E":
                statusClass = "instock"
                name = "Enviado"
                break

            case "R":
                statusClass = "lowstock"
                name = "Reenviado"
                break

            case "P":
                statusClass = "custom1"
                name = "Preenchido"
                break

            case null:
                statusClass = "outofstock"
                name = "Não Enviado"
                break
        }

        return <span className={`product-badge status-${statusClass}`}>{name}</span>
    }

    const hideDialog = () => {
        setProductsDialog(false)
    }

    const dateFormatIssue = (rowData: PurchaseQuote) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.issue_date}</Moment>
            </>
        )
    }

    //Requisitions
    const actionSendQuote = (data: Provider) => {
        const { provider_id, provider_store } = data

        try {
            let header = {
                headers: {
                    route: "send_quote",
                },
            }

            api.post(
                "quotes/send",
                {
                    quote_id: purchaseQuote.id,
                    provider_id: provider_id,
                    provider_store: provider_store,
                },
                header
            )
                .then((response) => {
                    let _providers = [...providers]
                    let index = findIndexByIdProvider(response.data.provider_id)
                    _providers[index].shipping_status = response.data.status

                    setProviders(_providers)

                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Cotação enviada com sucesso!!",
                        life: 4000,
                    })
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao Enviar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) { }
    }

    const sendQuotes = (rowData: Provider) => {
        return (
            <>
                <Button
                    className="p-button"
                    icon="p-button-icon p-c pi pi-send"
                    label="Enviar"
                    iconPos="right"
                    onClick={() => {
                        actionSendQuote(rowData)
                    }}
                />
            </>
        )
    }

    const findIndexByIdProvider = (id: string) => {
        let index = -1
        for (let i = 0; i < providers.length; i++) {
            if (providers[i].provider_id === id) {
                index = i
                break
            }
        }

        return index
    }

    const allPurchaseQuotes = async () => {
        try {
            let header = {
                headers: {
                    route: "list_open_quotes",
                },
            }

            await api.get("quotes/open", header)
                .then((res) => {
                    setLoadingList(false)
                    setPurchaseQuotes(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const actionProvidersProducts = (data: PurchaseQuote) => {
        setPurchaseQuote(data)

        try {
            let header = {
                headers: {
                    route: "list_quote_providers",
                },
            }

            api.get(`quotes/providers/${data.id}`, header)
                .then((res) => {
                    setLoadingPage(false)
                    setProviders(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar Fornecedores!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }

        try {
            let header = {
                headers: {
                    route: "list_quote_products",
                },
            }

            api.get(`quotes/products/${data.id}`, header)
                .then((res) => {
                    setLoadingProduct(false)
                    setProducts(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar Produtos!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }

        setProductsDialog(true)
    }

    useEffect(() => {
        allPurchaseQuotes()
    }, [])

    return (
        <App>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />

                        <DataTable
                            value={purchaseQuotes}
                            dataKey="id"
                            paginator
                            loading={loadingList}
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} Cotações"
                            globalFilter={globalFilter}
                            emptyMessage="Não há produtos cadastrados"
                            header={header}
                            stripedRows
                        >
                            <Column align="center" alignHeader="center" header="Número" field="id" sortable></Column>

                            <Column
                                align="center"
                                alignHeader="center"
                                header="Data de Emissão"
                                body={dateFormatIssue}
                                sortField="issue_date"
                                sortable
                            ></Column>

                            <Column align="center" alignHeader="center" header="Solicitação de compra" field="purchase_request" sortable></Column>

                            <Column align="center" alignHeader="center" header="Solicitante" field="requester" sortable></Column>
                            <Column align="center" alignHeader="center" body={buttonFromDataTable}></Column>
                        </DataTable>

                        <Dialog
                            visible={productsDialog}
                            style={{ maxWidth: "1200px" }}
                            modal
                            className="p-fluid"
                            onHide={hideDialog}
                            header={`Detalhes da Cotação: ${purchaseQuote.id}`}
                        >
                            <div className="crud-demo">
                                <DataTable
                                    value={providers}
                                    loading={loadingPage}
                                    dataKey="provider_id: "
                                    paginator
                                    rows={4}
                                    globalFilter={globalFilterProducts}
                                    className="datatable-responsive"
                                    emptyMessage="Não há Fornecedores participantes cadastrados"
                                    header="Fornecedores Participantes"
                                    stripedRows
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} Fornecedores"
                                >
                                    <Column align="center" alignHeader="center" field="provider_email" header="E-mail" sortable></Column>

                                    <Column align="center" alignHeader="center" field="provider_id" header="Codigo Fornecedor" sortable></Column>

                                    <Column
                                        headerStyle={{
                                            textAlign: "center",
                                            width: "10rem",
                                        }}
                                        bodyStyle={{ textAlign: "center" }}
                                        field="provider_name"
                                        header="Razão Social"
                                        sortable
                                    ></Column>

                                    <Column align="center" alignHeader="center" field="provider_store" header="Loja" sortable></Column>

                                    <Column align="center" alignHeader="center" field="provider_contact" header="Contato" sortable></Column>

                                    <Column
                                        align="center"
                                        alignHeader="center"
                                        header="Data e Hora"
                                        body={dateFormatIssue}
                                        sortField="quote_send_date"
                                        sortable
                                    ></Column>

                                    <Column align="center" alignHeader="center" header="Status" body={activeInactive} sortField="status" sortable></Column>

                                    <Column align="center" alignHeader="center" body={sendQuotes}></Column>
                                </DataTable>
                                <br /> <br />
                                <DataTable
                                    value={products}
                                    paginator
                                    rows={4}
                                    stripedRows
                                    loading={loadingProduct}
                                    globalFilter={globalFilterPermissions}
                                    emptyMessage="Não há Produtos"
                                    header="Produtos da Cotação"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} Produtos"
                                >
                                    <Column bodyStyle={{ textAlign: "center" }} field="product_id" header="Cod. de Produto" sortable></Column>

                                    <Column
                                        headerStyle={{
                                            textAlign: "center",
                                            width: "22rem",
                                        }}
                                        bodyStyle={{ textAlign: "center" }}
                                        field="product_description"
                                        header="Descrição"
                                        sortable
                                    ></Column>

                                    <Column align="center" alignHeader="center" field="quantity" header="Quantidade" sortable></Column>
                                    <Column align="center" alignHeader="center" field="um" header="Un. de Medida" sortable></Column>
                                    <Column align="center" alignHeader="center" header="Qtd. 2° UM" field="quantity2" sortable></Column>
                                    <Column align="center" alignHeader="center" header="2° Un. Medida" field="quote_id" sortable></Column>
                                </DataTable>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </App>
    )
}
