import Dropzone, { IDropzoneProps } from "react-dropzone-uploader"

type Props = {
    types: string
    onEdit: (files: any) => void
}

let files: IDropzoneProps
export const EditUpload = (props: Props) => {
    return (
        <Dropzone
            styles={{
                dropzone: { maxWidth: "60rem", height: "23rem", overflow: "hidden" },
                dropzoneActive: { borderColor: "black", pointerEvents: "none" },
                submitButton: { backgroundColor: "#6da6fc", color: "white" },
            }}
            inputContent="📁 Arraste ou selecione um arquivo"
            submitButtonContent="Enviar"
            onSubmit={(files) => props.onEdit(files)}
            accept={props.types}
            maxSizeBytes={100000000}
            maxFiles={1}
        />
    )
}
