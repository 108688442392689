import { MachineApresentationInterface } from "../../../../../interfaces/MachineApresentationInterface"
import { OpportunityInterface } from "../../../../../interfaces/OpportunityInterface"
import api from "../../../../../services/api"
import { EditUpload } from "../../../../EditUpload"

interface PropsInterface {
    toast: any
    presentation: MachineApresentationInterface
    opportunity: OpportunityInterface
    setOpportunity: React.Dispatch<React.SetStateAction<OpportunityInterface>>
    setUpdateFileDialog: React.Dispatch<React.SetStateAction<boolean>>
}

export const UpdateAttachmentsDialog = ({ toast, opportunity, presentation, setOpportunity, setUpdateFileDialog }: PropsInterface) => {
    const onEditFile = (files: any) => {
        const data = new FormData()
        data.append("file", files[0].file)

        toast.current?.show({
            severity: "info",
            summary: "Enviando arquivo, aguarde...",
            life: 3000,
        })

        let header = {
            headers: {
                route: "update_file_machine_apresentation",
            },
        }

        api.patch(`attachments/file/${presentation.id}`, data, header)

            .then((res) => {
                if (res.status === 200) {
                    setOpportunity((prevState) => ({
                        ...prevState,
                        attachments: opportunity.attachments.map((attachment: any) => {
                            if (attachment.id === presentation.id) {
                                return {
                                    ...attachment,
                                    file: res.data.file,
                                    file_url: res.data.file_url,
                                }
                            } else {
                                return attachment
                            }
                        }),
                    }))
                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Arquivo enviado com sucesso",
                        life: 3000,
                    })
                    setUpdateFileDialog(false)
                } else {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao enviar arquivo!",
                        detail: res.data.message,
                        life: 3000,
                    })
                }
            })

            .catch((error) => {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao enviar arquivo!",
                    detail: error.response.data.message,
                    life: 3000,
                })
            })
    }

    return (
        <>
            <EditUpload
                onEdit={onEditFile}
                types={
                    ".mp4, .mkv, .avi,  .png, .jpg, .jpeg, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .odt, .ods, .odp, .odg, .odc, .odf, .odb, .odi, .odm, .webm, .mp3, .wav, .mp4, .m4a, .m4v, .ogg, .ogv, .ogm, .ogx, .wmv, .wma, .wax, .wvx, .mov, .qt, .3gp, .3g2, .flv, .swf, .svg, .svgz, .ogg, .aac, .flac, .wav, .mid, .midi, .woff, .woff2, .eot, .ttf, .otf, .ico, .icns, .png, .jpg, .jpeg, .gif, .bmp, .tiff, .tif, .svg, .svgz, , .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .odt, .ods, .odp, .odg, .odc, .odf, .odb, .odi, .odm, .webm, .mp3, .wav, .mp4, .m4a, .m4v, .ogg, .ogv, .ogm, .ogx, .wmv, .wma, .wax, .wvx, .mov, .qt, .3gp, .3g2, .flv, .swf, .svg, .svgz, .ogg, .aac, .flac, .wav, .mid, .midi, .woff, .woff2, .eot, .ttf, .otf, .ico, .icns, .png, .jpg, .jpeg, .gif, .bmp, .tiff, .tif, .svg, .svgz, .zip"
                }
            />
        </>
    )
}
