import React, { useRef, useState, useEffect } from "react"
import App from "../../layout/App"
import api from "../../services/api"
import Moment from "react-moment"

//PrimeReact
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { InputText } from "primereact/inputtext"
import { Toast } from "primereact/toast"

//Interface
import { Quote } from "../../interfaces/QuoteInterface"

export const Quotes: React.FC = () => {
    const toast: any = useRef(null)
    const token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    const [globalFilter, setGlobalFilter] = useState<null>(null)
    const [quotes, setQuotes] = useState<Quote[]>([])

    //Loading
    const [loadingList, setLoadingList] = useState<boolean>(true)

    const allQuotes = async () => {
        try {
            let header = {
                headers: {
                    route: "list_open_quotes",
                },
            }

            await api.get("quotes/open", header)
                .then((res) => {
                    setLoadingList(false)
                    setQuotes(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const generateReport = (rowData: Quote) => {
        return (
            <>
                <Button className="p-button" icon="p-button-icon p-c pi pi-search" label={rowData.id} iconPos="left" />
            </>
        )
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Cotações</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e: any) => setGlobalFilter(e.target.value)} placeholder="Pesquisar..." />
            </span>
        </div>
    )

    const dateFormat = (rowData: Quote) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.created_at}</Moment>
            </>
        )
    }

    useEffect(() => {
        allQuotes()
    }, [])

    return (
        <App>
            <div className="p-grid crud-demo">
                <Toast ref={toast} />

                <div className="p-col-12">
                    <div className="card">
                        <DataTable
                            value={quotes}
                            dataKey="id"
                            paginator
                            loading={loadingList}
                            rows={10}
                            stripedRows
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} cotações"
                            emptyMessage="Não há Cotações"
                            header={header}
                            globalFilter={globalFilter}
                        >
                            <Column sortable align="center" alignHeader="center" header="Solicitante" field="requester"></Column>

                            <Column align="center" alignHeader="center" sortable header="Cód.Solicitação" field="purchase_request"></Column>

                            <Column align="center" alignHeader="center" header="Cód.Cotação" sortable field="id"></Column>

                            <Column align="center" alignHeader="center" header="Cód.Pedido" sortable field="purchase_request"></Column>

                            <Column align="center" alignHeader="center" header="Data Emissão" body={dateFormat}></Column>

                            <Column align="center" alignHeader="center" body={generateReport}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </App>
    )
}
