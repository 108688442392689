import { InputText } from "primereact/inputtext"
import { useState } from "react"
import { MachineApresentationInterface } from "../../../../../interfaces/MachineApresentationInterface"
import { OpportunityInterface } from "../../../../../interfaces/OpportunityInterface"
import api from "../../../../../services/api"
import { LocalUpload } from "../../../../crm/whatsapp/LocalUpload"

interface PropsInterface {
    toast: any
    opportunity: OpportunityInterface
    setPresentationDialog: React.Dispatch<React.SetStateAction<boolean>>
    setLoadingOpen: React.Dispatch<React.SetStateAction<boolean>>
    setOpportunity: React.Dispatch<React.SetStateAction<OpportunityInterface>>
}

export const CreateAttachmentsDialog = ({ toast, opportunity, setPresentationDialog, setOpportunity, setLoadingOpen }: PropsInterface) => {
    let [createValuesPresentation, setCreateValuesPresentation] = useState<MachineApresentationInterface>({} as MachineApresentationInterface)

    const onChangeCreatePresentation = (event: any) => {
        const { value, name } = event.target

        setCreateValuesPresentation({
            ...createValuesPresentation,
            [name]: value,
        })
    }

    const onUploadFile = async (files: any) => {
        setLoadingOpen(true)
        setPresentationDialog(false)

        if (!createValuesPresentation.name) {
            setLoadingOpen(false)
            toast.current?.show({
                severity: "warn",
                summary: "Erro ao cadastrar !",
                detail: "O nome do Arquivo é um campo obrigatório",
                life: 3000,
            })
            return
        }

        const formData = new FormData()
        formData.append("file", files[0].file)

        try {
            const response = await api.post(
                "attachments",
                {
                    name: createValuesPresentation.name,
                    opportunity_id: opportunity.id,
                },
                {
                    headers: {
                        route: "create_attachment",
                    },
                }
            )

            if (response.status === 200) {
                const attachmentId = response.data.id
                const patchResponse = await api.patch(`attachments/file/${attachmentId}`, formData, {
                    headers: {
                        route: "create_attachment",
                    },
                })

                if (patchResponse.status === 200) {
                    setOpportunity((prevState) => ({
                        ...prevState,
                        attachments: [...prevState.attachments, patchResponse.data],
                    }))
                }
                setLoadingOpen(false)
                toast.current?.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Arquivo enviado com sucesso",
                    life: 3000,
                })
            }
        } catch (error: any) {
            setLoadingOpen(false)
            const errorMessage = error.response?.data?.message || "Erro ao enviar arquivo!"
            toast.current?.show({
                severity: "error",
                summary: "Erro",
                detail: errorMessage,
                life: 3000,
            })
        }
    }

    return (
        <>
            <div className="p-field">
                <label htmlFor="name">Nome</label>
                <InputText
                    id="name"
                    name="name"
                    required
                    autoFocus
                    type="String"
                    maxLength={45}
                    style={{ minWidth: "20rem", textTransform: "uppercase" }}
                    value={createValuesPresentation.name || ""}
                    onChange={onChangeCreatePresentation}
                />
            </div>
            <label htmlFor="name">Arquivo:</label>
            <br />
            <div className="p-field">
                <LocalUpload
                    onSubmit={onUploadFile}
                    types={
                        ".mp4, .mkv, .avi,  .png, .jpg, .jpeg, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .odt, .ods, .odp, .odg, .odc, .odf, .odb, .odi, .odm, .webm, .mp3, .wav, .mp4, .m4a, .m4v, .ogg, .ogv, .ogm, .ogx, .wmv, .wma, .wax, .wvx, .mov, .qt, .3gp, .3g2, .flv, .swf, .svg, .svgz, .ogg, .aac, .flac, .wav, .mid, .midi, .woff, .woff2, .eot, .ttf, .otf, .ico, .icns, .png, .jpg, .jpeg, .gif, .bmp, .tiff, .tif, .svg, .svgz, , .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .odt, .ods, .odp, .odg, .odc, .odf, .odb, .odi, .odm, .webm, .mp3, .wav, .mp4, .m4a, .m4v, .ogg, .ogv, .ogm, .ogx, .wmv, .wma, .wax, .wvx, .mov, .qt, .3gp, .3g2, .flv, .swf, .svg, .svgz, .ogg, .aac, .flac, .wav, .mid, .midi, .woff, .woff2, .eot, .ttf, .otf, .ico, .icns, .png, .jpg, .jpeg, .gif, .bmp, .tiff, .tif, .svg, .svgz, .zip"
                    }
                    size={100000000}
                />
            </div>
        </>
    )
}
