import { Button } from "primereact/button"
import { Accordion, AccordionTab } from "primereact/accordion"
import { v4 as uuidv4 } from "uuid"
import React from "react"
import { OpportunityInterface } from "../../../../interfaces/OpportunityInterface"
import { ContactInterface } from "../../../../interfaces/ContactInterface"

interface I_TabPanelContact {
    setDialogCreateContact: (e: any) => void
    setNewPhoneField: (e: any) => void
    onClickEditContact: (contact: ContactInterface) => void
    setFormContact: (contact: ContactInterface) => void

    loadingCreateTask: boolean
    opportunity: OpportunityInterface
}

export const TabPanelContact = ({
    loadingCreateTask,
    opportunity,
    setDialogCreateContact,
    setNewPhoneField,
    onClickEditContact,
    setFormContact,
}: I_TabPanelContact) => {
    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    return (
        <div className="p-grid">
            <div className="p-col-12 p-md-12 p-lg-12">
                <div className="p-grid">
                    <div className="p-col-12">
                        <Button
                            label="Adicionar Contato"
                            loading={loadingCreateTask}
                            onClick={() => {
                                setDialogCreateContact(true)
                                setNewPhoneField([{ id: uuidv4(), telephone: "", name: "phone", ddi: { ddi: "+055", country: "Brasil" } }])
                            }}
                        />
                    </div>

                    {!isEmptyObj(opportunity.company.contacts)
                        ? opportunity.company.contacts.map((contact, indexContact) => (
                              <div className="p-col-12" key={indexContact}>
                                  <Accordion>
                                      <AccordionTab
                                          header={
                                              <React.Fragment>
                                                  <i className="pi pi-user"></i>
                                                  <span>
                                                      &nbsp;&nbsp; {contact.name}
                                                      &nbsp;&nbsp;-&nbsp;&nbsp; {contact.job}
                                                  </span>
                                              </React.Fragment>
                                          }
                                      >
                                          <div className="p-grid">
                                              <div className="p-col-12 p-md-6 p-lg-6">
                                                  <h4>Emails:</h4>
                                                  {contact.emails !== null
                                                      ? contact.emails.map((email, indexEmail) => (
                                                            <div className="p-d-flex" key={indexEmail}>
                                                                <div className="p-mr-2 p-mb-2">
                                                                    <span>{email.email}</span>
                                                                </div>
                                                            </div>
                                                        ))
                                                      : ""}
                                              </div>

                                              <div className="p-col-12 p-md-6 p-lg-6">
                                                  <h4>Telefones:</h4>
                                                  {contact.phones !== null
                                                      ? contact.phones.map((phone, indexPhone) => (
                                                            <div className="p-d-flex" key={indexPhone}>
                                                                <div className="p-mr-2 p-mb-2">
                                                                    <span>{phone.number}</span>
                                                                    <a
                                                                        href={`/crm/atendimento/&/${phone.number.replace(/[^0-9]+/g, "")}`}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        <span style={{ marginLeft: "1rem" }}>
                                                                            <i className="pi pi-whatsapp" style={{ color: "#02d14b" }}></i>
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        ))
                                                      : null}
                                              </div>
                                          </div>

                                          <Button
                                              icon="pi pi-pencil"
                                              type="button"
                                              label={contact.name}
                                              id={contact.id}
                                              onClick={() => {
                                                  onClickEditContact(contact)
                                                  setFormContact(contact)
                                              }}
                                          />
                                      </AccordionTab>
                                  </Accordion>
                              </div>
                          ))
                        : ""}
                </div>
            </div>
        </div>
    )
}
