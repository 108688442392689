
export const DDICountries = [
    { ddi: "+055", country: "Brasil" },
    { ddi: "+093", country: "Afeganistão" },
    { ddi: "+027", country: "America do Sul" },
    { ddi: "+355", country: "Albânia" },
    { ddi: "+049", country: "Alemanha" },
    { ddi: "+376", country: "Andorra" },
    { ddi: "+244", country: "Angola" },
    { ddi: "+001", country: "Anguilla" },
    { ddi: "+001", country: "Antígua e Barbuda" },
    { ddi: "+599", country: "Antilhas Holandesas" },
    { ddi: "+966", country: "Arábia Saudita" },
    { ddi: "+213", country: "Argélia" },
    { ddi: "+054", country: "Argentina" },
    { ddi: "+374", country: "Armênia" },
    { ddi: "+297", country: "Aruba" },
    { ddi: "+247", country: "Ascensão" },
    { ddi: "+061", country: "Austrália" },
    { ddi: "+043", country: "Áustria" },
    { ddi: "+994", country: "Azerbaijão" },
    { ddi: "+001", country: "Bahamas" },
    { ddi: "+880", country: "Bangladesh" },
    { ddi: "+001", country: "Barbados" },
    { ddi: "+973", country: "Bahrein" },
    { ddi: "+032", country: "Bélgica" },
    { ddi: "+501", country: "Belize" },
    { ddi: "+229", country: "Benim" },
    { ddi: "+001", country: "Bermudas" },
    { ddi: "+375", country: "Bielorrússia" },
    { ddi: "+591", country: "Bolívia" },
    { ddi: "+387", country: "Bósnia e Herzegovina" },
    { ddi: "+267", country: "Botswana" },
    { ddi: "+673", country: "Brunei" },
    { ddi: "+359", country: "Bulgária" },
    { ddi: "+226", country: "Burkina Faso" },
    { ddi: "+257", country: "Burundi" },
    { ddi: "+975", country: "Butão" },
    { ddi: "+238", country: "Cabo Verde" },
    { ddi: "+237", country: "Camarões" },
    { ddi: "+855", country: "Camboja" },
    { ddi: "+001", country: "Canadá" },
    { ddi: "+007", country: "Cazaquistão" },
    { ddi: "+237", country: "Chade" },
    { ddi: "+056", country: "Chile" },
    { ddi: "+086", country: "República Popular da China" },
    { ddi: "+357", country: "Chipre" },
    { ddi: "+057", country: "Colômbia" },
    { ddi: "+269", country: "Comores" },
    { ddi: "+242", country: "Congo-Brazzaville" },
    { ddi: "+243", country: "Congo-Kinshasa" },
    { ddi: "+850", country: "Coreia do Norte" },
    { ddi: "+082", country: "Coreia do Sul" },
    { ddi: "+225", country: "Costa do Marfim" },
    { ddi: "+506", country: "Costa Rica" },
    { ddi: "+385", country: "Croácia" },
    { ddi: "+053", country: "Cuba" },
    { ddi: "+045", country: "Dinamarca" },
    { ddi: "+253", country: "Djibuti" },
    { ddi: "+001", country: "Dominica" },
    { ddi: "+020", country: "Egipto/Ásia" },
    { ddi: "+503", country: "El Salvador" },
    { ddi: "+971", country: "Emirados Árabes Unidos" },
    { ddi: "+593", country: "Equador" },
    { ddi: "+291", country: "Eritreia" },
    { ddi: "+421", country: "Eslováquia" },
    { ddi: "+386", country: "Eslovénia" },
    { ddi: "+034", country: "Espanha" },
    { ddi: "+001", country: "Estados Unidos" },
    { ddi: "+372", country: "Estónia" },
    { ddi: "+251", country: "Etiópia" },
    { ddi: "+679", country: "Fiji" },
    { ddi: "+063", country: "Filipinas" },
    { ddi: "+358", country: "Finlândia" },
    { ddi: "+033", country: "França" },
    { ddi: "+241", country: "Gabão" },
    { ddi: "+220", country: "Gâmbia" },
    { ddi: "+233", country: "Gana" },
    { ddi: "+995", country: "Geórgia" },
    { ddi: "+350", country: "Gibraltar" },
    { ddi: "+001", country: "Granada" },
    { ddi: "+030", country: "Grécia" },
    { ddi: "+299", country: "Groenlândia" },
    { ddi: "+590", country: "Guadalupe" },
    { ddi: "+671", country: "Guam" },
    { ddi: "+502", country: "Guatemala" },
    { ddi: "+592", country: "Guiana" },
    { ddi: "+594", country: "Guiana Francesa" },
    { ddi: "+224", country: "Guiné" },
    { ddi: "+245", country: "Guiné-Bissau" },
    { ddi: "+240", country: "Guiné Equatorial" },
    { ddi: "+509", country: "Haiti" },
    { ddi: "+504", country: "Honduras" },
    { ddi: "+852", country: "Hong Kong" },
    { ddi: "+036", country: "Hungria" },
    { ddi: "+967", country: "Iêmen" },
    { ddi: "+001", country: "Ilhas Cayman" },
    { ddi: "+672", country: "Ilha Christmas" },
    { ddi: "+672", country: "Ilhas Cocos" },
    { ddi: "+682", country: "Ilhas Cook" },
    { ddi: "+298", country: "Ilhas Féroe" },
    { ddi: "+672", country: "Ilha Heard e Ilhas McDonald" },
    { ddi: "+960", country: "Maldivas" },
    { ddi: "+500", country: "Ilhas Malvinas" },
    { ddi: "+001", country: "Ilhas Marianas do Norte" },
    { ddi: "+692", country: "Ilhas Marshall" },
    { ddi: "+672", country: "Ilha Norfolk" },
    { ddi: "+677", country: "Ilhas Salomão" },
    { ddi: "+001", country: "Ilhas Virgens Americanas" },
    { ddi: "+001", country: "Ilhas Virgens Britânicas" },
    { ddi: "+091", country: "Índia" },
    { ddi: "+062", country: "Indonésia/Oceania" },
    { ddi: "+098", country: "Irã" },
    { ddi: "+964", country: "Iraque" },
    { ddi: "+353", country: "Irlanda" },
    { ddi: "+354", country: "Islândia" },
    { ddi: "+972", country: "Israel" },
    { ddi: "+039", country: "Itália" },
    { ddi: "+001", country: "Jamaica" },
    { ddi: "+081", country: "Japão" },
    { ddi: "+962", country: "Jordânia" },
    { ddi: "+686", country: "Kiribati" },
    { ddi: "+383", country: "Kosovo" },
    { ddi: "+965", country: "Kuwait" },
    { ddi: "+856", country: "Laos" },
    { ddi: "+266", country: "Lesoto" },
    { ddi: "+371", country: "Letônia" },
    { ddi: "+961", country: "Líbano" },
    { ddi: "+231", country: "Libéria" },
    { ddi: "+218", country: "Líbia" },
    { ddi: "+423", country: "Liechtenstein" },
    { ddi: "+370", country: "Lituânia" },
    { ddi: "+352", country: "Luxemburgo" },
    { ddi: "+853", country: "Macau" },
    { ddi: "+389", country: "República da Macedônia" },
    { ddi: "+261", country: "Madagascar" },
    { ddi: "+060", country: "Malásia" },
    { ddi: "+265", country: "Malawi" },
    { ddi: "+223", country: "Mali" },
    { ddi: "+356", country: "Malta" },
    { ddi: "+212", country: "Marrocos" },
    { ddi: "+596", country: "Martinica" },
    { ddi: "+230", country: "Maurícia" },
    { ddi: "+222", country: "Mauritânia" },
    { ddi: "+269", country: "Mayotte" },
    { ddi: "+052", country: "México" },
    { ddi: "+691", country: "Estados Federados da Micronésia" },
    { ddi: "+258", country: "Moçambique" },
    { ddi: "+373", country: "Moldávia" },
    { ddi: "+377", country: "Mônaco" },
    { ddi: "+976", country: "Mongólia" },
    { ddi: "+382", country: "Montenegro" },
    { ddi: "+001", country: "Montserrat" },
    { ddi: "+095", country: "Myanmar" },
    { ddi: "+264", country: "Namíbia" },
    { ddi: "+674", country: "Nauru" },
    { ddi: "+977", country: "Nepal" },
    { ddi: "+505", country: "Nicarágua" },
    { ddi: "+227", country: "Níger" },
    { ddi: "+234", country: "Nigéria" },
    { ddi: "+683", country: "Niue" },
    { ddi: "+047", country: "Noruega" },
    { ddi: "+687", country: "Nova Caledônia" },
    { ddi: "+064", country: "Nova Zelândia" },
    { ddi: "+968", country: "Omã" },
    { ddi: "+031", country: "Países Baixos" },
    { ddi: "+680", country: "Palau" },
    { ddi: "+970", country: "Palestina" },
    { ddi: "+507", country: "Panamá" },
    { ddi: "+675", country: "Papua-Nova Guiné" },
    { ddi: "+092", country: "Paquistão" },
    { ddi: "+595", country: "Paraguai" },
    { ddi: "+051", country: "Peru" },
    { ddi: "+689", country: "Polinésia Francesa" },
    { ddi: "+048", country: "Polônia" },
    { ddi: "+001", country: "Porto Rico" },
    { ddi: "+351", country: "Portugal" },
    { ddi: "+974", country: "Qatar" },
    { ddi: "+254", country: "Quênia" },
    { ddi: "+996", country: "Quirguistão" },
    { ddi: "+044", country: "Reino Unido" },
    { ddi: "+236", country: "República Centro-Africana" },
    { ddi: "+001", country: "República Dominicana" },
    { ddi: "+420", country: "República Tcheca" },
    { ddi: "+262", country: "Reunião" },
    { ddi: "+040", country: "Romênia" },
    { ddi: "+250", country: "Ruanda" },
    { ddi: "+007", country: "Rússia/Ásia" },
    { ddi: "+212", country: "Saara Ocidental" },
    { ddi: "+685", country: "Samoa" },
    { ddi: "+001", country: "Samoa Americana" },
    { ddi: "+290", country: "Santa Helena (território)" },
    { ddi: "+001", country: "Santa Lúcia" },
    { ddi: "+001", country: "São Cristóvão e Nevis" },
    { ddi: "+378", country: "São Marinho" },
    { ddi: "+508", country: "Saint-Pierre e Miquelon" },
    { ddi: "+239", country: "São Tomé e Príncipe" },
    { ddi: "+001", country: "São Vicente e Granadinas" },
    { ddi: "+248", country: "Seicheles" },
    { ddi: "+221", country: "Senegal" },
    { ddi: "+232", country: "Serra Leoa" },
    { ddi: "+381", country: "Sérvia" },
    { ddi: "+065", country: "Singapura" },
    { ddi: "+963", country: "Síria" },
    { ddi: "+252", country: "Somália" },
    { ddi: "+094", country: "Sri Lanka" },
    { ddi: "+268", country: "Suazilândia" },
    { ddi: "+249", country: "Sudão" },
    { ddi: "+211", country: "Sudão do Sul" },
    { ddi: "+046", country: "Suécia" },
    { ddi: "+041", country: "Suíça" },
    { ddi: "+597", country: "Suriname" },
    { ddi: "+992", country: "Tadjiquistão" },
    { ddi: "+066", country: "Tailândia" },
    { ddi: "+886", country: "República da China" },
    { ddi: "+255", country: "Tanzânia" },
    { ddi: "+246", country: "Território Britânico do Oceano Índico" },
    { ddi: "+670", country: "Timor-Leste" },
    { ddi: "+228", country: "Togo" },
    { ddi: "+690", country: "Tokelau" },
    { ddi: "+676", country: "Tonga" },
    { ddi: "+001", country: "Trinidad e Tobago" },
    { ddi: "+216", country: "Tunísia" },
    { ddi: "+001", country: "Turcas e Caicos" },
    { ddi: "+993", country: "Turquemenistão" },
    { ddi: "+090", country: "Turquia//Europa" },
    { ddi: "+688", country: "Tuvalu" },
    { ddi: "+380", country: "Ucrânia" },
    { ddi: "+256", country: "Uganda" },
    { ddi: "+598", country: "Uruguai" },
    { ddi: "+998", country: "Uzbequistão" },
    { ddi: "+678", country: "Vanuatu" },
    { ddi: "+379", country: "Vaticano" },
    { ddi: "+058", country: "Venezuela" },
    { ddi: "+084", country: "Vietnã" },
    { ddi: "+681", country: "Wallis e Futuna" },
    { ddi: "+260", country: "Zâmbia" },
    { ddi: "+263", country: "Zimbábue" },
]

export const messagesContains = [
    "espero",
    "aguard",
    "esper",
    "retorno",
    "resposta",
    "responda",
    "respo",
    "mande",
    "envie",
    "dia",
    "noite",
    "favor",
    "pf",
    "pfv",
]

export const final_words = [
    "obrigado",
    "podemos ajudar",
    "vou pensar",
    "analis",
    "🤝",
    "👊",
    "👊🏻",
    "👊🏼",
    "👊🏽",
    "👊🏾",
    "👊🏿",
    "engan",
    "nada",
    "disponha",
    "até",
    "tchau",
    "adeus",
    "curr",
    "falou",
    "xau",
    "ok",
    "flw",
    "até mais",
    "okk",
    "até breve",
    "thank",
    "agrad",
    "obri",
    "tranquilo",
    "blz",
    "👍",
    "👍🏻",
    "👍🏼",
    "👍🏽",
    "👍🏾",
    "👍🏿",
    "👌",
    "👌🏻",
    "👌🏼",
    "👌🏽",
    "👌🏾",
    "👌🏿",
    "valeu",
    "vlw",
    "👏🏻",
    "👏",
    "👏🏼",
    "👏🏽",
    "👏🏾",
    "👏🏿",
    "👏🏽",
    "👏🏾",
    "certeza",
    "obg",
]

export const selectionPeriod = [
    {
        name: "DIA",
        daysNumber: 1,
    },
    {
        name: "SEMANA",
        daysNumber: 7,
    },
    {
        name: "MÊS",
        daysNumber: 30,
    },
    {
        name: "BIMESTRE",
        daysNumber: 60,
    },
    {
        name: "TRIMESTRE",
        daysNumber: 90,
    },
    {
        name: "SEMESTRE",
        daysNumber: 180,
    },
    {
        name: "ANO",
        daysNumber: 365,
    },
    {
        name: "DOIS ANOS",
        daysNumber: 365 * 2,
    },
]

export const selectionPriorities = [
    {
        name: "ALTA",
        code: 5,
    },
    {
        name: "MÉDIA ALTA",
        code: 4,
    },
    {
        name: "MÉDIA",
        code: 3,
    },
    {
        name: "MÉDIA BAIXA",
        code: 2,
    },
    {
        name: "BAIXA",
        code: 1,
    },
    ,
]

export const tooltipArrayRating = ["Baixa", "Média baixa", "Média", "Média alta", "Alta"]

export const taskType = [
    { name: "Ligação", code: "LG" },
    { name: "E-mail", code: "EM" },
    { name: "Reunião", code: "RE" },
    { name: "Visita", code: "VI" },
    { name: "Tarefa", code: "TA" },
    { name: "Almoço", code: "AL" },
    { name: "Whatsapp", code: "WP" },
]

export const fileType = [
    { name: "Vídeo", type: "video" },
    { name: "Imagem", type: "image" },
    { name: "Documento", type: "document" },
    { name: "Áudio", type: "audio" },
    { name: "Sticker", type: "sticker" },
]

export const typesMoney = [
    {
        currency: "BRL",
        locale: "pt-br",
        currency_symbol: "R$",
    },

    {
        currency: "USD",
        locale: "en-US",
        currency_symbol: "$",
    },

    {
        currency: "EUR",
        locale: "de-DE",
        currency_symbol: "€",
    },
]

export const typeProducts = [
    { id: "558877663322558", name: "Equipamentos", code: "machine" },
    { id: "565651658435743", name: "Opcionais", code: "optional" },
]
