
import React, { useRef, useState, useEffect } from "react"
import App from "../../layout/App"
import api from "../../services/api"
import classNames from "classnames"
import Moment from "react-moment"
import { useHotkeys } from "react-hotkeys-hook"

//PrimeReact
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { ConfirmDialog } from "primereact/confirmdialog"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { FilterMatchMode, FilterOperator } from "primereact/api"
import { Toast } from "primereact/toast"
import { Toolbar } from "primereact/toolbar"

//Componets
import { CrmRecords } from "../../components/crm/CrmRecords"
import { LocalUpload } from "../../components/crm/whatsapp/LocalUpload"
import AllPagesPDFViewer from "../../components/PDF/all-pages"
import "../../components/PDF/index.scss"
import "../../components/PDF/all-pages"

//Interface
import { MachineApresentationInterface } from "../../interfaces/MachineApresentationInterface"

//Css
import "react-dropzone-uploader/dist/styles.css"
import { HotKeys } from "../../components/crm/HotKeys"

export const Layouts = () => {
    const toast: any = useRef(null)
    const token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    let [createValues, setCreateValues] = useState<MachineApresentationInterface>({} as MachineApresentationInterface)
    let [layout, setLayout] = useState<MachineApresentationInterface>({} as MachineApresentationInterface)
    let [layouts, setLayouts] = useState<MachineApresentationInterface[]>([])
    let [submitted, setSubmitted] = useState<boolean>(false)

    //Loading
    let [loadingDelete, setLoadingDelete] = useState<boolean>(false)
    let [loadingEdit, setLoadingEdit] = useState<boolean>(false)
    let [loadingList, setLoadingList] = useState<boolean>(true)
    let [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    //Dialog
    let [deleteDialog, setDeleteDialog] = useState(false)
    let [hotKeysDialog, setHotKeysDialog] = useState(false)
    let [layoutDialog, setLayoutDialog] = useState<boolean | undefined>(false)
    let [layoutEditDialog, setLayoutEditDialog] = useState(false)
    let [uploadFileDialog, setUploadFileDialog] = useState(false)
    let [viewFileDialog, setViewFileDialog] = useState(false)

    //Filters
    let [auxLayoutsFiltered, setAuxLayoutsFiltered] = useState<MachineApresentationInterface[]>([])

    //HotKeys
    useHotkeys("shift+n", () => setLayoutDialog(true))
    useHotkeys("shift+i", () => setHotKeysDialog(true))

    //Filters
    let [globalFilterValue, setGlobalFilterValue] = useState("")
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        description: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    })

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value
        let _filters = { ...filters }
        _filters["global"].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    const findIndexById = (id: string) => {
        let index = -1
        for (let i = 0; i < auxLayoutsFiltered.length; i++) {
            if (auxLayoutsFiltered[i].id === id) {
                index = i
                break
            }
        }

        return index
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Layouts </h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Pesquisar..." />
            </span>
        </div>
    )

    //Dialog
    const hideDialog = () => {
        setCreateValues({} as any)
        setSubmitted(false)
        setLayoutDialog(false)
        setLayoutEditDialog(false)
        setDeleteDialog(false)
        setUploadFileDialog(false)
        setHotKeysDialog(false)
        setViewFileDialog(false)
    }

    const dialogEditLayout = (presentated: MachineApresentationInterface) => {
        setLayout(presentated)
        setLayoutEditDialog(true)
    }

    //Datable Formater
    const dateFormatCreate = (rowData: MachineApresentationInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.created_at}</Moment>
            </>
        )
    }

    const dateFormatUpdate = (rowData: MachineApresentationInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.updated_at}</Moment>
            </>
        )
    }

    //Buttons
    const dataTableButtons = (rowData: MachineApresentationInterface) => {
        return (
            <div className="actions">
                <Button icon="pi pi-user-edit" className="p-button-rounded p-button" onClick={() => dialogEditLayout(rowData)} />
                &nbsp;&nbsp;
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => deleteLayouts(rowData)} />
                &nbsp;&nbsp;
                {rowData.file === null && <Button icon="pi pi-upload" className="p-button-rounded p-button-success" onClick={() => uploadLayout(rowData)} />}
                {rowData.file != null && <Button icon="pi pi-upload" className="p-button-rounded p-button-warning" onClick={() => uploadLayout(rowData)} />}
            </div>
        )
    }

    const createLayoutsButton = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-user-plus" className="p-button" onClick={openNew} />
            </React.Fragment>
        )
    }

    const linkPDF = (rowData: MachineApresentationInterface) => {
        return (
            <>
                <div>
                    <a
                        onClick={() => {
                            setViewFileDialog(true)
                            setLayout(rowData)
                        }}
                        style={{ fontWeight: "600", fontSize: "1.1rem", color: "rgba(1, 1, 122, 1)" }}
                    >
                        {rowData.file}
                    </a>
                </div>
            </>
        )
    }

    //Requisitions
    const allLayouts = async () => {
        try {
            let header = {
                headers: {
                    route: "list_machines_layouts",
                },
            }

            await api
                .get("machines_layouts", header)
                .then((res) => {
                    setLoadingList(false)
                    setLayouts(res.data)
                    setAuxLayoutsFiltered(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const uploadLayout = (presentated: MachineApresentationInterface) => {
        setLayout(presentated)
        setUploadFileDialog(true)
    }

    const onSubmitEditLayout = () => {
        if (layout.description === null || layout.description === "" || layout.name === null || layout.name === "") {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao Editar",
                detail: "Revise os dados inseridos",
                life: 3000,
            })
            setLayoutEditDialog(true)
        } else {
            setLoadingSubmit(true)
            let auxConfirmLayout = {
                id: layout.id,
                name: layout.name,
                description: layout.description,
            }
            try {
                let header = {
                    headers: {
                        route: "update_machine_layout",
                    },
                }

                api.put("machines_layouts", auxConfirmLayout, header)

                    .then((res) => {
                        if (res.status === 200) {
                            setLoadingSubmit(false)
                            let _layouts = [...auxLayoutsFiltered]
                            let _layout = res.data
                            let index = findIndexById(res.data.id)
                            _layouts[index] = _layout
                            layouts = _layouts
                            auxLayoutsFiltered = _layouts

                            setLayouts(_layouts)
                            setAuxLayoutsFiltered(_layouts)
                            hideDialog()

                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso na alteração",
                                detail: "Layout alterado com sucesso!",
                                life: 3000,
                            })
                        } else {
                            setUploadFileDialog(true)
                            setLoadingSubmit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao Editar",
                                detail: "Por favor verifique os dados inseridos",
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setUploadFileDialog(true)
                        setLoadingSubmit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Editar",
                            detail: "Por favor verifique os dados inseridos",
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        }
    }

    const deleteLayouts = (item: MachineApresentationInterface) => {
        setLayout(item)
        setDeleteDialog(true)
    }

    const onDeleteLayout = () => {
        setLoadingDelete(true)
        try {
            api.delete("machines_layouts", {
                headers: {
                    route: "delete_machine_layout",
                },
                data: {
                    id: layout.id,
                },
            })

                .then((res) => {
                    setLoadingDelete(false)
                    if (res.status === 200) {
                        let auxDeleteLayout = auxLayoutsFiltered.filter((item) => layout.id !== item.id)
                        layouts = auxDeleteLayout
                        auxLayoutsFiltered = auxDeleteLayout
                        hideDialog()
                        setLayouts(auxDeleteLayout)
                        setAuxLayoutsFiltered(auxDeleteLayout)
                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na exclusão",
                            detail: "Layout excluído com sucesso!",
                            life: 3000,
                        })
                    } else {
                        setLoadingDelete(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao excluir!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingDelete(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao excluir!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmitLayout = (event: any) => {
        setLoadingEdit(true)
        event.preventDefault()
        setSubmitted(false)

        try {
            const { name, description } = createValues
            if (name === "" || description === "" || name === null || description === null || name === undefined || description === undefined) {
                setSubmitted(true)
                setLoadingEdit(false)
                toast.current?.show({
                    severity: "warn",
                    summary: "Erro ao cadastrar",
                    detail: "Por favor, verificar os dados preenchidos!",
                    life: 2000,
                })
            } else {
                let header = {
                    headers: {
                        route: "create_machine_layout",
                    },
                }

                api.post(
                    "machines_layouts",
                    {
                        name,
                        description,
                    },
                    header
                )
                    .then((res) => {
                        if (res.status === 200) {
                            hideDialog()
                            setLoadingEdit(false)
                            setLayouts([...layouts, res.data])
                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso",
                                detail: "Layout criado com sucesso",
                                life: 3000,
                            })
                        } else {
                            setLoadingEdit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao cadastar!",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingEdit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao cadastar!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const openNew = () => {
        setLayoutDialog(true)
    }

    const onUploadFile = (files: any) => {
        const data = new FormData()
        data.append("file", files[0].file)
        hideDialog()
        setLoadingList(true)

        toast.current?.show({
            severity: "info",
            summary: "Enviando arquivo, aguarde...",
            life: 3000,
        })

        let header = {
            headers: {
                route: "update_file_machine_layout",
            },
        }

        api.patch(`machines_layouts/file/${layout.id}`, data, header)

            .then((res) => {
                if (res.status === 200) {
                    setLoadingList(false)
                    setLayouts((prevState) => {
                        const index = prevState.findIndex((item) => item.id === layout.id)
                        prevState[index] = res.data
                        return [...prevState]
                    })

                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Layouts enviado com sucesso",
                        life: 3000,
                    })
                } else {
                    setLoadingList(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao enviar arquivo!",
                        detail: res.data.message,
                        life: 3000,
                    })
                }
            })

            .catch((error) => {
                setLoadingList(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao enviar arquivo!",
                    detail: error.response.data.message,
                    life: 3000,
                })
            })
    }

    //OnChange
    const onChangeEdit = (event: any) => {
        const { value, name } = event.target
        setLayout({
            ...layout,
            [name]: value,
        })
    }

    const onChangeCreateLayout = (event: any) => {
        const { value, name } = event.target

        setCreateValues({
            ...createValues,
            [name]: value,
        })
    }

    //Footers
    const layoutDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" autoFocus icon="pi pi-check" className="p-button-success p-button-text" loading={loadingEdit} onClick={onSubmitLayout} />
        </>
    )

    const layoutEditDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button
                label="Salvar"
                autoFocus
                icon="pi pi-check"
                className="p-button-success p-button-text"
                loading={loadingSubmit}
                onClick={onSubmitEditLayout}
            />
        </>
    )

    const deleteDialogFooter = () => {
        return (
            <>
                <Button label="Não" icon="pi pi-times" className="p-button-success p-button-text" onClick={hideDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-danger p-button-text" loading={loadingDelete} onClick={onDeleteLayout} />
            </>
        )
    }

    const deleteMessage = () => {
        return (
            <>
                {" "}
                Deseja realmente excluir o layout <strong>{layout.name}</strong>
            </>
        )
    }

    useEffect(() => {
        allLayouts()
    }, [])

    return (
        <App>
            <CrmRecords />
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="p-mb-4 p-toolbar" left={createLayoutsButton}></Toolbar>

                        <DataTable
                            value={layouts}
                            loading={loadingList}
                            stripedRows
                            selectionMode="checkbox"
                            dataKey="id"
                            rows={10}
                            rowsPerPageOptions={[10, 15, 20]}
                            // className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} layouts"
                            paginator
                            header={header}
                            filters={filters}
                            globalFilterFields={["name", "description"]}
                            emptyMessage="Não há Layouts cadastrados"
                            responsiveLayout="scroll"
                        >
                            <Column field="name" header="Nome" sortable align="center" alignHeader="center" />
                            <Column field="description" header="Descrição" sortable align="center" alignHeader="center" />
                            <Column body={dateFormatCreate} header="Data de Criação" sortable sortField="created_at" align="center" alignHeader="center" />
                            <Column body={dateFormatUpdate} sortField="update_at" header="Data de Atualização" sortable align="center" alignHeader="center" />
                            <Column body={linkPDF} header="Arquivo" sortable sortField="file" align="center" alignHeader="center" />
                            <Column align="center" alignHeader="center" body={dataTableButtons}></Column>
                        </DataTable>

                        <Dialog
                            visible={layoutDialog}
                            style={{ width: "450px" }}
                            header="Adicionar Layout de Equipamento"
                            modal
                            draggable={false}
                            className="p-fluid"
                            footer={layoutDialogFooter}
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <label htmlFor="name">Nome</label>
                                <InputText
                                    id="name"
                                    name="name"
                                    required
                                    type="String"
                                    maxLength={45}
                                    style={{ minWidth: "20rem" }}
                                    className={
                                        "p-field p-text-capitalize " +
                                        classNames({
                                            "p-invalid": submitted && createValues.name === "",
                                        })
                                    }
                                    value={createValues.name || ""}
                                    onChange={onChangeCreateLayout}
                                />
                            </div>
                            <div className="p-field">
                                <label htmlFor="description">Descrição</label>
                                <InputText
                                    id="description"
                                    name="description"
                                    required
                                    type="String"
                                    style={{ minWidth: "20rem" }}
                                    maxLength={45}
                                    className={
                                        "p-field p-text-capitalize " +
                                        classNames({
                                            "p-invalid": submitted && createValues.description === "",
                                        })
                                    }
                                    value={createValues.description || ""}
                                    onChange={onChangeCreateLayout}
                                />
                            </div>
                        </Dialog>

                        <Dialog
                            visible={layoutEditDialog}
                            style={{ width: "450px" }}
                            header={`Editar Layout:`}
                            modal
                            draggable={false}
                            className="p-fluid"
                            footer={layoutEditDialogFooter}
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <label htmlFor="name">Nome</label>
                                <InputText
                                    style={{ minWidth: "20rem" }}
                                    id="name"
                                    name="name"
                                    maxLength={120}
                                    onChange={(e: any) => onChangeEdit(e)}
                                    value={layout.name || ""}
                                />
                            </div>
                            <div className="p-field">
                                <label htmlFor="description">Descrição</label>
                                <InputText
                                    id="description"
                                    name="description"
                                    required
                                    type="String"
                                    style={{ minWidth: "20rem" }}
                                    maxLength={45}
                                    className={
                                        "p-field p-text-capitalize " +
                                        classNames({
                                            "p-invalid": submitted && createValues.description === "",
                                        })
                                    }
                                    value={layout.description || ""}
                                    onChange={(e: any) => onChangeEdit(e)}
                                />
                            </div>
                        </Dialog>

                        <Dialog
                            visible={uploadFileDialog}
                            style={{ width: "750px" }}
                            header={"Enviar arquivos de: " + layout.name}
                            modal
                            className="p-fluid"
                            draggable={false}
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <LocalUpload onSubmit={onUploadFile} types={".pdf"} size={100000000} />
                            </div>
                        </Dialog>

                        <Dialog
                            visible={viewFileDialog}
                            style={{ height: "80%" }}
                            header={"Arquivo: " + layout.name}
                            modal
                            className="p-fluid"
                            draggable={false}
                            onHide={hideDialog}
                        >
                            <Button
                                label="Baixar"
                                icon="pi pi-link"
                                className="p-button"
                                onClick={() => window.open(layout.file_url)}
                                style={{ width: "10rem", marginBottom: "1rem" }}
                            />
                            <div
                                className="p-field"
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                {" "}
                                <AllPagesPDFViewer pdf={layout.file_url} />
                            </div>
                        </Dialog>

                        <Dialog
                            visible={hotKeysDialog}
                            style={{ width: "700px" }}
                            modal
                            className="p-fluid"
                            onHide={hideDialog}
                            header={`Atalhos - Tela de Layouts`}
                            draggable={false}
                        >
                            <HotKeys create={"Criar um novo Layout"} />
                        </Dialog>

                        <ConfirmDialog
                            style={{ width: "400px", borderRadius: "16px" }}
                            draggable={false}
                            visible={deleteDialog}
                            onHide={hideDialog}
                            message={deleteMessage}
                            acceptLabel={"Sim"}
                            rejectLabel={"Não"}
                            header="Confirmação de exclusão"
                            icon="pi pi-exclamation-triangle"
                            accept={onDeleteLayout}
                            acceptIcon="pi pi-check"
                            rejectIcon="pi pi-times"
                            reject={hideDialog}
                            dismissableMask={true}
                            footer={deleteDialogFooter}
                        />
                    </div>
                </div>
            </div>
        </App>
    )
}
