import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { ScrollPanel } from "primereact/scrollpanel"
import { TabPanel, TabView } from "primereact/tabview"
import { AnnotaionOpportunity } from "../../../crm/opportunity/annotation"
import { OpportunityInterface } from "../../../../interfaces/OpportunityInterface"
import { OpportunityTimeLineInterface } from "../../../../interfaces/OpportunityTimeLineInterface"
import { Checkbox } from "primereact/checkbox"
import { Timeline as TimelineP } from "primereact/timeline"
import Moment from "react-moment"
import { useEffect, useState } from "react"
import api from "../../../../services/api"
import ReactPlayer from "react-player"

interface I_TabPanelHistorical {
    handleCreateAnnotation: () => void
    setAnnotation: (e: any) => void
    handleUpdateTimeLine: (e: any) => void
    opportunity: OpportunityInterface
    loadingCreateAnnotations: boolean
    annotation: string
}

export const TabPanelHistorical = ({
    handleCreateAnnotation,
    setAnnotation,
    handleUpdateTimeLine,
    opportunity,
    loadingCreateAnnotations,
    annotation,
}: I_TabPanelHistorical) => {
    let [auxTimeline, setAuxTimeline] = useState<OpportunityTimeLineInterface[] | null>(null)
    let [timeline, setTimeline] = useState([] as OpportunityTimeLineInterface[])
    let [loadingWhatsapp, setLoadingWhatsapp] = useState(false)
    let [statusFilters, setStatusFilters] = useState<any>([
        { name: "Anotação ", type: "annotation", checked: false },
        { name: "Tarefa  ", type: "task", checked: false },
        { name: "Alteração na Oportunidade ", type: "update", checked: false },
        { name: "Proposta  ", type: "proposal", checked: false },
        { name: "Anexo   ", type: "attachment", checked: false },
        { name: "WhatsApp   ", type: "whatsapp", checked: false },
    ])

    const icons = (timelineItem: OpportunityTimeLineInterface) => {
        let icon
        const ICONS: { [key: string]: JSX.Element } = {
            update: <Button icon="pi pi-refresh" disabled className="p-button-rounded p-button-info" />,
            annotation: <Button icon="pi pi-book" disabled className="p-button-rounded p-button-help" />,
            task: <Button icon="pi pi-bookmark" disabled className="p-button-rounded p-button-warning" />,
            proposal: <Button icon="pi pi-briefcase" disabled className="p-button-rounded p-button-danger" />,
            email: <Button icon="pi pi-envelope" disabled className="p-button-rounded p-button-secondary" />,
            emailreply: <Button icon="pi pi-envelope" disabled className="p-button-rounded p-button-help" />,
            whatsapp: <Button icon="pi pi-whatsapp" disabled className="p-button-rounded p-button-success" />,
        }
        icon = ICONS[timelineItem.type]
        return <span>{icon}</span>
    }

    const typesOfMessages = (timeline: OpportunityTimeLineInterface) => {
        switch (timeline.type) {
            case "whatsapp":
                switch (timeline.whatsapp_message_type) {
                    case "text":
                        return (
                            <>
                                {timeline.secondary_text !== "" && (
                                    <p style={{ fontSize: "1.2rem", marginBottom: "0.1rem", fontStyle: "italic", marginTop: "-1rem" }}>
                                        &nbsp;{timeline.secondary_text}
                                    </p>
                                )}
                            </>
                        )

                    case "image":
                        return <>{timeline.path_whatsapp && <img height={"300px"} src={timeline.path_whatsapp} />}</>

                    case "sticker":
                        return <>{timeline.path_whatsapp && <img height={"300px"} src={timeline.path_whatsapp} />}</>

                    case "document":
                        let splitArchive = timeline.path_whatsapp!.split(".")
                        let lengthSplit = splitArchive.length

                        if (splitArchive[lengthSplit - 1] === "pdf") {
                            return (
                                <iframe
                                    src={timeline.path_whatsapp}
                                    frameBorder="0"
                                    seamless={false}
                                    title="document"
                                    scrolling="no"
                                    width="100%"
                                    style={{ border: "none", overflow: "hidden" }}
                                    height="300px"
                                />
                            )
                        } else {
                            return <div></div>
                        }

                    case "video":
                        return (
                            <>
                                <ReactPlayer
                                    styles={{ backgroundColor: "red" }}
                                    autoPlay
                                    url={timeline.path_whatsapp}
                                    width="100%"
                                    height="300px"
                                    controls={true}
                                />
                            </>
                        )

                    case "audio":
                        return (
                            <>
                                {timeline.path_whatsapp && (
                                    <audio
                                        src={timeline.path_whatsapp}
                                        className="audio-1"
                                        controls
                                        playsInline={true}
                                        style={{
                                            width: "100%",
                                            resize: "none",
                                            outline: "none",
                                            borderRadius: "5px",
                                            backgroundColor: "white",
                                            textDecoration: "bold",
                                            fontSize: "1rem",
                                        }}
                                    />
                                )}
                            </>
                        )
                }

                break

            default:
                return (
                    <>
                        {timeline.secondary_text !== "" && (
                            <p style={{ fontSize: "1.2rem", marginBottom: "0.1rem", fontStyle: "italic", marginTop: "-1rem" }}>
                                &nbsp;{timeline.secondary_text}
                            </p>
                        )}
                    </>
                )
        }
    }

    const customizedContent = (timeline: OpportunityTimeLineInterface) => {
        return (
            <>
                {timeline.url ? (
                    <a href={timeline.url}>
                        <Card style={{ backgroundColor: "#f4f4f5", padding: "0rem 1rem 0rem 1rem", borderRadius: "1rem" }} className="">
                            <div className="" style={{ fontSize: "1.2rem" }}>
                                <strong> {timeline.user?.name} </strong> &nbsp;
                            </div>
                            <p style={{ fontSize: "1.2rem" }}> {timeline.main_text} </p>

                            {timeline.secondary_text !== "" && (
                                <div>
                                    {timeline.secondary_text?.split("\n").map((text_secondary) => (
                                        <p style={{ fontSize: "1.2rem", marginBottom: "0.2rem", fontStyle: "italic" }}>{text_secondary}</p>
                                    ))}
                                </div>
                            )}

                            <div className="">
                                <p style={{ fontSize: "0.9rem", marginBottom: "-1rem", marginTop: "-0.25rem" }}>
                                    <Moment format="DD/MM/YYYY HH:mm">{timeline.created_at}</Moment>
                                </p>
                            </div>
                        </Card>
                    </a>
                ) : (
                    <div>
                        <Card style={{ backgroundColor: "#f4f4f5", padding: "0rem 1rem 0rem 1rem", borderRadius: "1rem" }} className="">
                            <div className="" style={{ fontSize: "1.2rem" }}>
                                <strong> {timeline.user?.name} </strong> &nbsp;
                            </div>

                            <p style={{ fontSize: "1.2rem" }} dangerouslySetInnerHTML={{ __html: timeline.main_text }} />

                            <div className="">{typesOfMessages(timeline)}</div>

                            <div className="">
                                <p style={{ fontSize: "0.9rem", marginBottom: "-1rem", marginTop: "-0.25rem" }}>
                                    <Moment format="DD/MM/YYYY HH:mm">{timeline.created_at}</Moment>
                                </p>
                            </div>
                        </Card>
                    </div>
                )}
            </>
        )
    }

    const checkSearchFilter = (item: any) => {
        let auxTimeline_ = auxTimeline ? auxTimeline : timeline
        let auxTimelineData: OpportunityTimeLineInterface[] = []
        let auxStatusFilters = [...statusFilters]
        let checked = false

        statusFilters.forEach((statusFilter: any, indexStatusFilter: number) => {
            if (statusFilter.type === item) {
                statusFilter.checked = !statusFilter.checked
                auxStatusFilters[indexStatusFilter] = statusFilter
                setStatusFilters(auxStatusFilters)
            }

            if (statusFilter.checked) {
                checked = true
                auxTimeline_.forEach((timeline) => {
                    if (timeline.type === statusFilter.type) {
                        auxTimelineData.push(timeline)
                    } else {
                        auxTimelineData.filter((timelineData) => timelineData.type === statusFilter.type)
                    }
                })
            }
        })

        if (checked) {
            setAuxTimeline(auxTimelineData)
        } else {
            setAuxTimeline(null)
        }
    }

    const handleWhatsappConversation = async () => {
        setAuxTimeline(null)

        setLoadingWhatsapp(true)

        let whatsappMessageList: any[] = []
        let auxTimeline_: OpportunityTimeLineInterface[] = []

        try {
            for (const contact of opportunity.company.contacts) {
                if (contact.phones) {
                    for (const phone of contact.phones) {
                        try {
                            const response = await api.get(`whatsapp_conversation/conversation?number=${phone.number}&whatsapp_phone_number=551637124102`)

                            if (response.status === 200) {
                                whatsappMessageList.push(response.data)
                            }
                        } catch (error) {
                            console.error(`Failed to fetch conversation for number ${phone.number}`, error)
                        }
                    }
                }
            }

            whatsappMessageList.forEach((whats: any) => {
                whats.messages.forEach((message: any) => {
                    let auxTimeline__: OpportunityTimeLineInterface = {} as OpportunityTimeLineInterface

                    auxTimeline__.type = "whatsapp"
                    auxTimeline__.secondary_text = message.text
                    auxTimeline__.created_at = message.created_at
                    auxTimeline__.main_text = message.user
                        ? `<strong> Vendedor -- </strong> ${message.user?.name}`
                        : `<strong> Cliente -- </strong> ${whats.contact?.name}`
                    auxTimeline__.whatsapp_message_type = message.type

                    if (message.attachment) {
                        auxTimeline__.path_whatsapp = message.attachment.file_url
                    }

                    auxTimeline_.push(auxTimeline__)
                })
            })

            if (auxTimeline) {
                auxTimeline.forEach((auxTime) => {
                    auxTimeline_.push(auxTime)
                })
            } else {
                opportunity.timeline.forEach((auxTime) => {
                    auxTimeline_.push(auxTime)
                })
            }

            setTimeline(
                auxTimeline_.sort((a, b) => {
                    const dateA = new Date(a.created_at)
                    const dateB = new Date(b.created_at)
                    return dateB.getTime() - dateA.getTime()
                })
            )

            setLoadingWhatsapp(false)
        } catch (error) {
            console.log(error)
            setLoadingWhatsapp(false)
        }
    }

    useEffect(() => {
        setTimeline(opportunity.timeline)
        handleWhatsappConversation()
    }, [])

    return (
        <div className="p-grid">
            <div className="p-col-12 p-md-12 p-lg-12">
                <Card
                    style={{
                        width: "100%",
                        height: "auto",
                        minHeight: "auto",
                        borderRadius: "0px",
                    }}
                >
                    <TabView
                        className="p-mr-2 p-as-start"
                        style={{
                            marginTop: "-1.5rem",
                        }}
                    >
                        <TabPanel header="CRIAR ANOTAÇÃO">
                            <AnnotaionOpportunity
                                createAnotation={handleCreateAnnotation}
                                setAnotation={setAnnotation}
                                opportunityStatus={opportunity.status}
                                loadingCreateAnnotations={loadingCreateAnnotations}
                                anotation={annotation}
                            />
                        </TabPanel>
                    </TabView>
                </Card>
            </div>

            <div className="p-col-12 p-md-12 p-lg-12">
                <Card
                    style={{
                        width: "100%",
                        borderRadius: "0px",
                    }}
                >
                    <TabView
                        className="p-mr-2 p-as-start"
                        style={{
                            marginTop: "-1.5rem",
                        }}
                    >
                        <TabPanel header="HISTÓRICO DE OPORTUNIDADE">
                            {/* Alinhando no final da tela */}
                            <div className="p-d-flex p-jc-end">
                                <Button
                                    onClick={handleWhatsappConversation}
                                    type="button"
                                    icon="pi pi-whatsapp"
                                    className="p-button-rounded p-button-info p-button-outlined"
                                    tooltip="Sincronizar Mensagens"
                                    loading={loadingWhatsapp}
                                    style={{
                                        marginTop: "-3rem",
                                    }}
                                />
                                &nbsp;&nbsp;
                                <Button
                                    onClick={(e: any) => {
                                        handleUpdateTimeLine(e)
                                    }}
                                    icon="pi pi-refresh"
                                    className="p-button-rounded p-button-info p-button-outlined"
                                    tooltip="Atualizar Historico"
                                    style={{
                                        marginTop: "-3rem",
                                    }}
                                />
                            </div>
                            <br /> <br />
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-d-flex p-flex-column p-flex-md-row p-ai-center">
                                        {statusFilters.map((statusFilter: any, indexStatusFilter: number) => (
                                            <div className="p-mb-6 p-mr-6" key={indexStatusFilter}>
                                                <Checkbox
                                                    inputId={statusFilter.type}
                                                    value={statusFilter.type}
                                                    onChange={() => checkSearchFilter(statusFilter.type)}
                                                    checked={statusFilter.checked}
                                                />
                                                <span style={{ marginLeft: "0.5rem" }}>{statusFilter.name}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <ScrollPanel
                                        style={{
                                            height: "40rem",
                                        }}
                                        className="scrollTimeLine"
                                    >
                                        <TimelineP
                                            value={auxTimeline ? auxTimeline : timeline}
                                            marker={icons}
                                            align="alternate"
                                            content={(timelineItem) => customizedContent(timelineItem)}
                                        />
                                    </ScrollPanel>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </Card>
            </div>
        </div>
    )
}
