import React, { useRef, useState, useEffect } from "react"
import App from "../../layout/App"
import api from "../../services/api"
import { useHotkeys } from "react-hotkeys-hook"

//PrimeReact
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { Dropdown } from "primereact/dropdown"
import { Rating } from "primereact/rating"
import { Skeleton } from "primereact/skeleton"
import { Slider } from "primereact/slider"
import { TabView, TabPanel } from "primereact/tabview"
import { Toast } from "primereact/toast"

//Components
import { CrmRecords } from "../../components/crm/CrmRecords"
import { LocalUpload } from "../../components/crm/whatsapp/LocalUpload"

//CSS
import "../../components/PDF/index.scss"

//Interface
import { GropuInterface } from "../../interfaces/GroupInterface"
import { PreferencesInterface } from "../../interfaces/PreferencesInterface"
import { HotKeys } from "../../components/crm/HotKeys"
import { UserInterface } from "../../interfaces/UserInterface"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import Moment from "react-moment"
import { ConfirmDialog } from "primereact/confirmdialog"

export const Preferences = () => {
    let toast = useRef<Toast>(null)
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    let [groups, setGroups] = useState<GropuInterface[]>([])
    let [delayTime, setDelayTime] = useState({} as PreferencesInterface)
    let [group, setGroup] = useState<GropuInterface>({} as GropuInterface)
    let [sellerSelected, setSellerSelected] = useState<UserInterface>({} as UserInterface)
    let [users, setUsers] = useState<UserInterface[]>([])
    let [sellers, setSellers] = useState<UserInterface[]>([])

    //Loading
    let [loadingList, setLoadingList] = useState<boolean>(true)
    let [loadingEdit, setLoadingEdit] = useState<boolean>(false)

    //Dialog
    let [uploadFileDialog, setUploadFileDialog] = useState(false)
    let [hotKeysDialog, setHotKeysDialog] = useState(false)
    let [deleteSellerDialog, setDeleteSellerDialog] = useState(false)
    let [activeSellerDialog, setActiveSellerDialog] = useState(false)
    let [inativeSellerDialog, setInativeSellerDialog] = useState(false)
    let [createSellerDialog, setCreateSellerDialog] = useState(false)

    let [seller, setSeller] = useState({} as UserInterface)

    //HotKeys
    useHotkeys("shift+n", () => uploadPresentaiton())
    useHotkeys("shift+i", () => setHotKeysDialog(true))

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    //Dialog
    const hideDialog = () => {
        setUploadFileDialog(false)
        setHotKeysDialog(false)
        setDeleteSellerDialog(false)
        setSeller({} as UserInterface)
        setInativeSellerDialog(false)
        setActiveSellerDialog(false)
        setCreateSellerDialog(false)
        setSellerSelected({} as UserInterface)
    }

    const deleteDialogFooter = () => {
        return (
            <>
                <Button label="Não" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-success p-button-text" onClick={deleteSellerFunction} loading={loadingEdit} />
            </>
        )
    }

    const createSellerFooter = () => {
        return (
            <>
                <Button label="Não" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-success p-button-text" onClick={createSellerFunction} loading={loadingEdit} />
            </>
        )
    }
    const inativeDialogFooter = () => {
        return (
            <>
                <Button label="Não" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                <Button
                    label="Sim"
                    icon="pi pi-check"
                    loading={loadingEdit}
                    className="p-button-success p-button-text"
                    onClick={() => inactiveActiveFunction(false)}
                />
            </>
        )
    }

    const activeDialogFooter = () => {
        return (
            <>
                <Button label="Não" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                <Button
                    label="Sim"
                    icon="pi pi-check"
                    loading={loadingEdit}
                    className="p-button-success p-button-text"
                    onClick={() => inactiveActiveFunction(true)}
                />
            </>
        )
    }

    //Requisitions
    const allGroups = async () => {
        try {
            let header = {
                headers: {
                    route: "list_groups",
                },
            }

            await api
                .get("groups", header)
                .then((res) => {
                    setLoadingList(false)
                    setGroups(res.data)
                })
                .catch((error) => {
                    setLoadingList(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const allUsers = async () => {
        try {
            const header = {
                headers: {
                    route: "list_users",
                },
            }

            const response = await api.get("users", header)
            const userData = response.data

            setLoadingList(false)
            setUsers(userData)
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao listar!",
                    detail: error.response.data.message,
                    life: 3000,
                })
            } else {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao listar!",
                    detail: "Erro desconhecido ao obter a lista de usuários.",
                    life: 3000,
                })
            }
        }
    }

    const allSellers = async () => {
        try {
            const header = {
                headers: {
                    route: "list_users",
                },
            }

            const response = await api.get("rotation_sellers", header)
            const userData = response.data

            setLoadingList(false)
            setSellers(userData)
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao listar!",
                    detail: error.response.data.message,
                    life: 3000,
                })
            } else {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao listar!",
                    detail: "Erro desconhecido ao obter a lista de vendedores.",
                    life: 3000,
                })
            }
        }
    }

    const inactiveActiveFunction = async (active: boolean) => {
        setLoadingEdit(true)

        try {
            let header = {
                headers: {
                    route: "inative_seller",
                },
            }

            let auxSeller = {
                id: seller.id,
                active: active,
            }

            await api
                .patch(`rotation_sellers/status`, auxSeller)
                .then((res) => {
                    if (res.status === 200) {
                        hideDialog()
                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "Vendedor(a) inativado com sucesso!",
                            life: 3000,
                        })
                        allSellers()
                        setLoadingEdit(false)
                    } else {
                        setLoadingEdit(false)

                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao inativar!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingEdit(false)

                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao inativar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            setLoadingEdit(false)

            console.log(error)
        }
    }

    const deleteSellerFunction = async () => {
        loadingEdit = true
        setLoadingEdit(loadingEdit)
        try {
            let auxSeller = {
                id: seller.id,
            }

            await api
                .delete(`rotation_sellers`, { data: auxSeller })
                .then((res) => {
                    if (res.status === 200) {
                        hideDialog()
                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "Vendedor(a) inativado com sucesso!",
                            life: 3000,
                        })
                        allSellers()
                        setLoadingEdit(false)
                    } else {
                        setLoadingEdit(false)

                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao inativar!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingEdit(false)

                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao inativar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
            setLoadingEdit(false)
        }
    }

    const createSellerFunction = async () => {
        setLoadingEdit(true)

        try {
            let auxSeller = {
                user_id: sellerSelected.id,
            }

            await api
                .post(`rotation_sellers`, auxSeller)
                .then((res) => {
                    if (res.status === 200) {
                        hideDialog()
                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "Vendedor(a) adicionado com sucesso!",
                            life: 3000,
                        })
                        setSellers([...sellers, res.data])
                        setLoadingEdit(false)
                    } else {
                        setLoadingEdit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao adicionar!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingEdit(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao adicionar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const allPreferences = async () => {
        try {
            let header = {
                headers: {
                    route: "show_crm_configuration",
                },
            }
            await api
                .get("crm_configurations", header)
                .then((res) => {
                    setDelayTime(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const deleteMessage = () => {
        return (
            <>
                {" "}
                Deseja realmente remover o vendedor(a) <strong>{seller.name} do rodízio</strong>
            </>
        )
    }

    const inativeMessage = () => {
        return (
            <>
                {" "}
                Deseja realmente inativar o vendedor(a) <strong>{seller.name} do rodízio</strong>
            </>
        )
    }

    const activeMessage = () => {
        return (
            <>
                {" "}
                Deseja realmente reativar o vendedor(a) <strong>{seller.name} do rodízio</strong>
            </>
        )
    }

    const onUploadFile = (files: any) => {
        const data = new FormData()
        data.append("general_conditions", files[0].file)
        hideDialog()
        setLoadingList(true)

        toast.current?.show({
            severity: "info",
            summary: "Enviando arquivo, aguarde...",
            life: 3000,
        })

        let header = {
            headers: {
                route: "update_general_conditions",
            },
        }

        api.patch(`crm_configurations/general_conditions`, data, header)

            .then((res) => {
                if (res.status === 200) {
                    setDelayTime(res.data)
                    setLoadingList(false)
                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Arquivo enviado com sucesso",
                        life: 3000,
                    })
                } else {
                    setLoadingList(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao enviar arquivo!",
                        detail: res.data.message,
                        life: 3000,
                    })
                }
            })

            .catch((error) => {
                setLoadingList(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao enviar arquivo!",
                    detail: error.response.data.message,
                    life: 3000,
                })
            })
    }

    const dateFormatUpdate = (rowData: any) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.last_allocation_date}</Moment>
            </>
        )
    }

    const createFormatDate = (rowData: UserInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.created_at}</Moment>
            </>
        )
    }

    const uploadPresentaiton = () => {
        setUploadFileDialog(true)
    }

    const onEditPreferences = () => {
        setLoadingEdit(true)
        if (!isEmptyObj(group)) {
            try {
                let header = {
                    headers: {
                        route: "update_crm_configuration",
                    },
                }

                let auxConfirmPresentation = {
                    delaytime_one_star: delayTime.delaytime_one_star,
                    delaytime_two_star: delayTime.delaytime_two_star,
                    delaytime_three_star: delayTime.delaytime_three_star,
                    delaytime_four_star: delayTime.delaytime_four_star,
                    delaytime_five_star: delayTime.delaytime_five_star,
                    default_forecast_closing: delayTime.default_forecast_closing,
                    seller_group_id: group.id,
                }

                api.put("crm_configurations", auxConfirmPresentation, header)
                    .then((res) => {
                        if (res.status === 200) {
                            setLoadingEdit(false)
                            hideDialog()
                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso na alteração",
                                detail: "Preferências alteradas com sucesso!",
                                life: 3000,
                            })
                        } else {
                            setLoadingEdit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao Editar",
                                detail: "Por favor, verificar os dados preenchidos!",
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingEdit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Editar !",
                            detail: "Por favor, verificar os dados preenchidos!",
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        } else {
            try {
                let header = {
                    headers: {
                        route: "update_crm_configuration",
                    },
                }

                let auxConfirmPresentation = {
                    delaytime_one_star: delayTime.delaytime_one_star,
                    delaytime_two_star: delayTime.delaytime_two_star,
                    delaytime_three_star: delayTime.delaytime_three_star,
                    delaytime_four_star: delayTime.delaytime_four_star,
                    delaytime_five_star: delayTime.delaytime_five_star,
                    default_forecast_closing: delayTime.default_forecast_closing,
                    seller_group_id: delayTime.group.id,
                }

                api.put("crm_configurations", auxConfirmPresentation, header)
                    .then((res) => {
                        if (res.status === 200) {
                            setLoadingEdit(false)
                            hideDialog()
                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso na alteração",
                                detail: "Preferências alteradas com sucesso!",
                                life: 3000,
                            })
                        } else {
                            setLoadingEdit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao Editar",
                                detail: "Por favor, verificar os dados preenchidos!",
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingEdit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Editar !",
                            detail: "Por favor, verificar os dados preenchidos!",
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        }
    }

    const buttonsDataTable = (rowData: any) => {
        return (
            <div className="actions">
                &nbsp;&nbsp;
                {rowData.active && (
                    <Button
                        icon="pi pi-times"
                        className="p-button-rounded p-button p-button-text p-button-danger"
                        onClick={() => {
                            setSeller(rowData)
                            setInativeSellerDialog(true)
                        }}
                    />
                )}
                {!rowData.active && (
                    <Button
                        icon="pi pi-check"
                        className="p-button-rounded p-button-success p-button-text"
                        onClick={() => {
                            setSeller(rowData)
                            setActiveSellerDialog(true)
                        }}
                    />
                )}
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button p-button-text"
                    onClick={() => {
                        setSeller(rowData)
                        setDeleteSellerDialog(true)
                    }}
                />
            </div>
        )
    }

    const ativoDesativo = (rowData: any) => {
        const statusClass = rowData.active ? "instock" : "lowstock"
        return (
            <>
                <span className={`product-badge status-${statusClass}`}>{rowData.active ? "Ativo" : "Inativo"}</span>
            </>
        )
    }

    useEffect(() => {
        allPreferences()
        allGroups()
        allUsers()
        allSellers()
    }, [])

    return (
        <App>
            <CrmRecords />
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <Toast ref={toast} />

                    {/* Skelleton Dialog */}
                    {delayTime.default_forecast_closing === undefined && (
                        <div className="p-grid p-formgrid">
                            <div className="p-field p-col-12 p-md-12">
                                <Skeleton width="100%" borderRadius="16px" height="10rem"></Skeleton>
                                <Skeleton width="100%" borderRadius="16px" height="10rem"></Skeleton>
                                <Skeleton width="100%" borderRadius="16px" height="10rem"></Skeleton>
                                <Skeleton width="100%" borderRadius="16px" height="10rem"></Skeleton>
                                <Skeleton width="100%" borderRadius="16px" height="10rem"></Skeleton>
                                <Skeleton width="100%" borderRadius="16px" height="10rem"></Skeleton>
                                <Skeleton width="100%" borderRadius="16px" height="10rem"></Skeleton>
                                <br />
                            </div>
                        </div>
                    )}

                    {delayTime.default_forecast_closing !== undefined && (
                        <div className="p-grid crud-demo">
                            <div className="p-col-12">
                                <div className="card">
                                    <TabView>
                                        <TabPanel header="TEMPO DE ATRASO">
                                            <br />
                                            <h4 className="p-d-flex">
                                                TEMPO DE ATRASO PARA UMA ESTRELA &nbsp; &nbsp; <Rating value={1} cancel={false} disabled />
                                            </h4>
                                            <h5>{delayTime.delaytime_one_star !== 0 ? <h5>{delayTime.delaytime_one_star} DIAS</h5> : ""} </h5>
                                            <Slider
                                                value={delayTime.delaytime_one_star}
                                                min={1}
                                                onChange={(e: any) => setDelayTime({ ...delayTime, delaytime_one_star: e.value })}
                                            />
                                            <br /> <br />
                                            <h4 className="p-d-flex">
                                                TEMPO DE ATRASO PARA DUAS ESTRELA &nbsp; &nbsp; <Rating value={2} cancel={false} disabled />
                                            </h4>
                                            <h5>{delayTime.delaytime_two_star !== 0 ? <h5>{delayTime.delaytime_two_star} DIAS</h5> : ""} </h5>
                                            <Slider
                                                value={delayTime.delaytime_two_star}
                                                min={1}
                                                onChange={(e: any) => setDelayTime({ ...delayTime, delaytime_two_star: e.value })}
                                            />
                                            <br /> <br />
                                            <h4 className="p-d-flex">
                                                TEMPO DE ATRASO PARA TRÊS ESTRELA &nbsp; &nbsp; <Rating value={3} cancel={false} disabled />
                                            </h4>
                                            <h5>{delayTime.delaytime_three_star !== 0 ? <h5>{delayTime.delaytime_three_star} DIAS</h5> : ""} </h5>
                                            <Slider
                                                min={1}
                                                value={delayTime.delaytime_three_star}
                                                onChange={(e: any) => setDelayTime({ ...delayTime, delaytime_three_star: e.value })}
                                            />
                                            <br /> <br />
                                            <h4 className="p-d-flex">
                                                TEMPO DE ATRASO PARA QUATRO ESTRELA &nbsp; &nbsp; <Rating value={4} cancel={false} disabled />
                                            </h4>
                                            <h5>{delayTime.delaytime_four_star !== 0 ? <h5>{delayTime.delaytime_four_star} DIAS</h5> : ""} </h5>
                                            <Slider
                                                min={1}
                                                value={delayTime.delaytime_four_star}
                                                onChange={(e: any) => setDelayTime({ ...delayTime, delaytime_four_star: e.value })}
                                            />
                                            <br /> <br />
                                            <h4 className="p-d-flex">
                                                TEMPO DE ATRASO PARA CINCO ESTRELA &nbsp; &nbsp; <Rating value={5} cancel={false} disabled />
                                            </h4>
                                            <h5>{delayTime.delaytime_five_star !== 0 ? <h5>{delayTime.delaytime_five_star} DIAS</h5> : ""} </h5>
                                            <Slider
                                                min={1}
                                                value={delayTime.delaytime_five_star}
                                                onChange={(e: any) => setDelayTime({ ...delayTime, delaytime_five_star: e.value })}
                                            />
                                            <br /> <br />
                                            <h4>TEMPO DE PREVISÃO PARA A FINALIZAÇÃO DA OPORTUNIDADE (MESES) </h4>
                                            <h5>{delayTime.default_forecast_closing !== 0 ? <h5>{delayTime.default_forecast_closing} MESES</h5> : ""} </h5>
                                            <Slider
                                                min={1}
                                                max={24}
                                                value={delayTime.default_forecast_closing}
                                                onChange={(e: any) => setDelayTime({ ...delayTime, default_forecast_closing: e.value })}
                                            />
                                            <br /> <br />
                                            <h5>
                                                SELECIONAR GRUPO DE VENDEDORES &nbsp; &nbsp;&nbsp; <br /> <br />
                                                <Dropdown
                                                    style={{ minWidth: "30rem", fontSize: "4.5rem", fontWeight: "320" }}
                                                    value={group}
                                                    options={groups}
                                                    filter={true}
                                                    emptyMessage="Não há dados"
                                                    emptyFilterMessage="Não há dados"
                                                    optionLabel="name"
                                                    onChange={(e: any) => setGroup(e.value)}
                                                    placeholder={delayTime.group?.name}
                                                />
                                            </h5>
                                            <Button
                                                label="Salvar"
                                                className="p-button-raised"
                                                style={{ width: "130px", fontSize: "1.2rem" }}
                                                loading={loadingEdit}
                                                onClick={onEditPreferences}
                                            />
                                        </TabPanel>

                                        <TabPanel header="CONDIÇÃO GERAL">
                                            <br />
                                            <br />
                                            <div className="p-field">
                                                {isEmptyObj(delayTime.general_conditions_url) && (
                                                    <LocalUpload onSubmit={onUploadFile} types={".pdf"} size={100000000} />
                                                )}
                                                <Button
                                                    label="ENVIAR CONDIÇÃO"
                                                    loading={loadingList}
                                                    className="p-button-raised p-button"
                                                    onClick={uploadPresentaiton}
                                                    style={{
                                                        marginLeft: "1rem",
                                                        marginTop: "1rem",
                                                    }}
                                                />
                                                <br /> <br />
                                                {!isEmptyObj(delayTime.general_conditions_url) && (
                                                    <div className="preferences-esqaudros">
                                                        <iframe src={delayTime.general_conditions_url + `#toolbar=0`} title="Apresentação" />
                                                    </div>
                                                )}
                                            </div>
                                        </TabPanel>

                                        <TabPanel header="RODÍZIO DE VENDEDORES">
                                            <br /> <br />
                                            <div className="p-field">
                                                <Button
                                                    label="EDITAR VENDEDORES"
                                                    loading={loadingList}
                                                    icon="pi pi-users"
                                                    className="p-button-raised p-button"
                                                    style={{
                                                        marginLeft: "1rem",
                                                        marginTop: "1rem",
                                                    }}
                                                    onClick={() => setCreateSellerDialog(true)}
                                                />
                                            </div>
                                            <br />
                                            <DataTable
                                                value={sellers}
                                                stripedRows
                                                className="datatable-responsive"
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} vendedores"
                                                header={"Vendedores Selecionados"}
                                                emptyMessage="Não há dados"
                                                paginatorLeft={""}
                                                paginatorRight={""}
                                                loading={loadingList}
                                            >
                                                <Column field="position" header="Sequência" sortable align="center" alignHeader="center" />
                                                <Column field="user.name" header="Nome" sortable align="center" alignHeader="center" />
                                                <Column
                                                    body={createFormatDate}
                                                    sortField="update_at"
                                                    header="Data de Alocação"
                                                    sortable
                                                    align="center"
                                                    alignHeader="center"
                                                />
                                                <Column
                                                    body={dateFormatUpdate}
                                                    sortField="update_at"
                                                    header="Última Alocação"
                                                    sortable
                                                    align="center"
                                                    alignHeader="center"
                                                />
                                                <Column
                                                    align="center"
                                                    alignHeader="center"
                                                    body={ativoDesativo}
                                                    header="Status"
                                                    sortField="status"
                                                    sortable
                                                ></Column>

                                                <Column align="center" alignHeader="center" body={buttonsDataTable}></Column>
                                            </DataTable>
                                        </TabPanel>
                                    </TabView>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* Upload Dialog */}
                    <Dialog
                        visible={uploadFileDialog}
                        draggable={false}
                        style={{ width: "750px" }}
                        header={"Enviar nova condição"}
                        modal
                        className="p-fluid"
                        onHide={hideDialog}
                    >
                        <div className="p-field">
                            <LocalUpload onSubmit={onUploadFile} types={".pdf"} size={100000000} />
                        </div>
                    </Dialog>

                    {/* HotKeys Dialog */}
                    <Dialog
                        visible={hotKeysDialog}
                        style={{ width: "700px" }}
                        modal
                        className="p-fluid"
                        onHide={hideDialog}
                        header={`Atalhos - Tela de Configurações`}
                        draggable={false}
                    >
                        <HotKeys create={"Enviar condição geral"} />
                    </Dialog>

                    <Dialog
                        visible={createSellerDialog}
                        style={{ width: "700px" }}
                        modal
                        className="p-fluid"
                        onHide={hideDialog}
                        header={`Adicionar vendedor`}
                        draggable={false}
                        footer={createSellerFooter}
                        resizable={false}
                    >
                        <Dropdown
                            style={{ minWidth: "30rem", fontSize: "4.5rem", fontWeight: "320" }}
                            value={sellerSelected}
                            options={users}
                            filter={true}
                            emptyMessage="Não há dados"
                            emptyFilterMessage="Não há dados"
                            optionLabel="name"
                            onChange={(e: any) => setSellerSelected(e.value)}
                            placeholder="Selecione um vendedor"
                        />
                    </Dialog>

                    <ConfirmDialog
                        style={{ width: "400px", borderRadius: "16px" }}
                        draggable={false}
                        visible={deleteSellerDialog}
                        onHide={hideDialog}
                        message={deleteMessage}
                        acceptLabel={"Sim"}
                        rejectLabel={"Não"}
                        header="Confirmação de exclusão"
                        icon="pi pi-exclamation-triangle"
                        acceptIcon="pi pi-check"
                        rejectIcon="pi pi-times"
                        reject={hideDialog}
                        dismissableMask={true}
                        footer={deleteDialogFooter}
                    />

                    <ConfirmDialog
                        style={{ width: "400px", borderRadius: "16px" }}
                        draggable={false}
                        visible={inativeSellerDialog}
                        onHide={hideDialog}
                        message={inativeMessage}
                        acceptLabel={"Sim"}
                        rejectLabel={"Não"}
                        header="Confirmação de Inativação"
                        icon="pi pi-exclamation-triangle"
                        accept={() => inactiveActiveFunction(false)}
                        acceptIcon="pi pi-check"
                        rejectIcon="pi pi-times"
                        reject={hideDialog}
                        dismissableMask={true}
                        footer={inativeDialogFooter}
                    />
                    <ConfirmDialog
                        style={{ width: "400px", borderRadius: "16px" }}
                        draggable={false}
                        visible={activeSellerDialog}
                        onHide={hideDialog}
                        message={activeMessage}
                        acceptLabel={"Sim"}
                        rejectLabel={"Não"}
                        header="Confirmação de Reativaç]ap"
                        icon="pi pi-exclamation-triangle"
                        accept={() => inactiveActiveFunction(true)}
                        acceptIcon="pi pi-check"
                        rejectIcon="pi pi-times"
                        reject={hideDialog}
                        dismissableMask={true}
                        footer={activeDialogFooter}
                    />
                </div>
            </div>
        </App>
    )
}
