import React, { useRef, useEffect, useState } from "react"
import App from "../../layout/App"
import api from "../../services/api"
import Moment from "react-moment"

//PrimeReact
import { Button } from "primereact/button"
import { Checkbox } from "primereact/checkbox"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { InputText } from "primereact/inputtext"
import { Toast } from "primereact/toast"
import { Toolbar } from "primereact/toolbar"

//Interface
import { PurchaseOrder } from "../../interfaces/PurchaseOrder"

export const PurchaseOrders: React.FC = () => {
    const toast: any = useRef(null)
    const token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`
    const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([])
    const [auxPurchaseOrders, setAuxPurchaseOrders] = useState<PurchaseOrder[]>([])
    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState<PurchaseOrder[]>([])
    const [pending, setPending] = useState<boolean>(false)

    const [acronymFilter] = useState<any>([])

    const [loadingList, setLoadingList] = useState<boolean>(true)

    let [globalFilter, setGlobalFilter] = useState<string>("")

    const allPurchaseOrder = async () => {
        try {
            let header = {
                headers: {
                    route: "list_purchase_orders",
                },
            }

            await api.get("purchase_orders", header)
                .then((res) => {
                    setLoadingList(false)
                    setPurchaseOrders(res.data)
                    setAuxPurchaseOrders(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    // Filtro dos checkbox
    const searchFilter = (data: string, filter: string) => {
        let type = data
        let status = true
        let _purchaseOrder: PurchaseOrder[] = []

        if (data !== "") {
            let exists = acronymFilter.find((p: string) => {
                return p === type
            })

            !exists ? acronymFilter.push(type) : acronymFilter.splice(acronymFilter.indexOf(type), 1)
        }

        globalFilter = filter.toUpperCase()
        setGlobalFilter(globalFilter)

        if (acronymFilter.length > 0 || globalFilter.length > 0) {
            auxPurchaseOrders.forEach((p: PurchaseOrder) => {
                if (acronymFilter.length > 0) {
                    status = false
                    if (
                        p.approval_control === "L" &&
                        p.quantity_delivered !== p.quantity &&
                        p.order_closed !== "E" &&
                        p.order_closed_by_residue !== "S" &&
                        p.quantity_sort === "0"
                    ) {
                        status = true
                    }
                }

                if (status) {
                    if (globalFilter.length > 0 && globalFilter !== "") {
                        let words = globalFilter.split(" ")

                        words.forEach((word) => {
                            if (
                                !p.id.includes(word) &&
                                !p.product_id.includes(word) &&
                                !p.provider_name.includes(word) &&
                                !p.product_description.includes(word)
                            ) {
                                status = false
                            }
                        })
                    }
                }

                if (status) {
                    _purchaseOrder.push(p)
                }

                status = true
            })

            setPurchaseOrders(_purchaseOrder)
        } else {
            setPurchaseOrders(auxPurchaseOrders)
        }
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Pedidos de Compras</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onChange={(e: any) => searchFilter("", e.target.value)} placeholder="Pesquisar..." />
            </span>
        </div>
    )

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="p-d-flex p-flex-column p-flex-md-row">
                    <div className="p-mb-2 p-mr-6">
                        <Checkbox
                            inputId="c1"
                            value="PA"
                            checked={pending}
                            onChange={(e: any) => {
                                searchFilter(e.target.value, globalFilter)
                                setPending(!pending)
                            }}
                        ></Checkbox>

                        <label htmlFor="c1" className="p-checkbox-label">
                            &nbsp;&nbsp;Pedidos Abertos
                        </label>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const generateReportsToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="p-d-flex p-flex-column p-flex-md-row">
                    <div className="p-mb-2 p-mr-6">
                        <Button label="Pedido Comum" className="p-button-success" onClick={actionGenerateReports} />
                    </div>

                    <div className="p-mb-2 p-mr-6">
                        <Button label="Pedido 2°U.M" className="p-button-warning" />
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const dateFormat = (rowData: PurchaseOrder) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.issue_date}</Moment>
            </>
        )
    }

    const actionGenerateReports = () => { }
    const SUnity = (rowData: PurchaseOrder) => {
        return rowData.um2 === "" ? <>-</> : rowData.um2
    }

    useEffect(() => {
        allPurchaseOrder()
    }, [])

    return (
        <App>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />

                        <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}></Toolbar>

                        <Toolbar className="p-mb-4 p-toolbar" left={generateReportsToolbarTemplate}></Toolbar>
                    </div>
                </div>

                <div className="p-col-12">
                    <div className="card">
                        <DataTable
                            value={purchaseOrders}
                            selectionMode="radiobutton"
                            selection={selectedPurchaseOrder}
                            onSelectionChange={(e) => setSelectedPurchaseOrder(e.value)}
                            dataKey="id"
                            paginator
                            loading={loadingList}
                            rows={25}
                            stripedRows
                            rowsPerPageOptions={[25, 35, 45]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} Pedidos de compra"
                            emptyMessage="Não há Pedidos"
                            header={header}
                            rowGroupMode="rowspan"
                            sortField="id"
                        >
                            <Column selectionMode="single" headerStyle={{ width: "3em" }} field="id"></Column>

                            <Column align="center" alignHeader="center" header="C.pedido" field="id"></Column>

                            <Column align="center" alignHeader="center" header="Fornecedor" field="provider_name"></Column>

                            <Column align="center" alignHeader="center" header="Cód.produto" field="product_id"></Column>

                            <Column align="center" alignHeader="center" header="Desc.Produto" field="product_description"></Column>

                            <Column align="center" alignHeader="center" header="Qtd" field="quantity"></Column>

                            <Column align="center" alignHeader="center" header="P.unidade" field="um"></Column>

                            <Column align="center" alignHeader="center" header="S.unidade" body={SUnity}></Column>

                            <Column align="center" alignHeader="center" header="D.emissão" body={dateFormat}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </App>
    )
}
