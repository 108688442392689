import React, { useRef, useEffect, useState, TextareaHTMLAttributes, useCallback } from "react"
import * as yup from "yup"
import api from "../../services/api"
import App from "../../layout/App"
import moment from "moment"
import Moment from "react-moment"
import ReactPlayer from "react-player"
import { useHotkeys } from "react-hotkeys-hook"
import { useParams } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"

//PrimeFaces Components
import { Avatar } from "primereact/avatar"
import { Badge } from "primereact/badge"
import { Button } from "primereact/button"
import { ConfirmDialog } from "primereact/confirmdialog"
import { Dialog } from "primereact/dialog"
import { Dropdown } from "primereact/dropdown"
import { Image } from "primereact/image"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { Menu } from "primereact/menu"
import ReactTooltip from "react-tooltip"
import { Skeleton } from "primereact/skeleton"
import { TabPanel, TabView } from "primereact/tabview"
import { Toast } from "primereact/toast"
import { Tooltip } from "primereact/tooltip"
import { VirtualScroller } from "primereact/virtualscroller"

//Interface
import { AttachmentInterface } from "../../interfaces/AttachmentInterface"
import { ContactInterface } from "../../interfaces/ContactInterface"
import { ContactWhatsappConversation } from "../../interfaces/ContactWhatsappConversation"
import { DefaultMessageInterface } from "../../interfaces/DefaultMessageInterface"
import { MessagesRoadMapInterface } from "../../interfaces/MessagesRoadMapInterface"
import { MessagesWhatsappConversation } from "../../interfaces/MessagesWhatsappConversation"
import { OpportunityInterface } from "../../interfaces/OpportunityInterface"
import { RoadMapInterface } from "../../interfaces/RoadMapInterface"
import { SaveCompanyData } from "../../interfaces/SaveCompanyInterface"
import { TabsConversationInterfaces } from "../../interfaces/TabsConversationInterface"
import { UserInterface } from "../../interfaces/UserInterface"
import { WhatsappConversationInterface } from "../../interfaces/WhatsappConversationInterface"

//COMPONENTS
import { AudioRecorder } from "../../components/crm/whatsapp/AudioRecorder"
import { ChatConversationMobile } from "../../components/crm/whatsapp/ChatConversationMobile"
import { ChatsTabViewBody } from "../../components/crm/whatsapp/ChatsTabViewBody"
import { ClientInformation } from "../../components/crm/whatsapp/ClientInformation/Index"
import { Company } from "../../components/crm/forms/Company"
import { CompanyInterface } from "../../interfaces/CompanyInterface"
import { Contact } from "../../components/crm/forms/Contact"
import { CrmRecords } from "../../components/crm/CrmRecords"
import DialogToDataAllGroups from "../../components/crm/whatsapp/DialogToDataAllGroups"
import { HeaderConversationAttendance } from "../../components/crm/whatsapp/HeaderConversationAttendance"
import { HotKeys } from "../../components/crm/whatsapp/HotKeys"
import { IEmpresaInterface } from "../../interfaces/IEmpresaInterface"
import { InformationSellerDialog } from "../../components/crm/whatsapp/InformationSellerDialog"
import { LocalUpload } from "../../components/crm/whatsapp/LocalUpload"
import { ReplyMessageChat } from "../../components/crm/whatsapp/ReplyMessageChat"
import { socket } from "../../services/socket"
import { useAuth } from "../../hooks/AuthContext"
import { final_words, messagesContains } from "../../listsArrays/ListsArrays"

//CSS and SCSS imports
import "../../components/PDF/index.scss"
import "../../styles/Attendance.scss"
import { DataTableContacts } from "../../components/crm/whatsapp/DataTableContacts/Index"
import { ChangeContactDataTable } from "../../components/crm/whatsapp/ChangeContactDataTable/Index"
import { Calendar } from "primereact/calendar"

export const Attendance = (props: TextareaHTMLAttributes<HTMLTextAreaElement>) => {
    let dataLocalStorage: any
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`
    let dataTable: any = useRef(null)
    let indexSended = 0
    let menu = useRef<Menu>(null)
    let myRefCardMessage = useRef<null | HTMLDivElement>(null)
    let myRefCardMessageMobile = useRef<null | HTMLDivElement>(null)
    let params: any = useParams()
    let toast = useRef<Toast>(null)
    let widthWindow = window.innerWidth
    let zoom = 15
    let { paramId, phone } = params
    let { user } = useAuth()

    let noPictureURL = "/assets/pages/icons/no-avatar.png"

    //Conversations
    let [conversations, setConversations] = useState<WhatsappConversationInterface[]>([])
    let [auxConversations, setAuxConversations] = useState<WhatsappConversationInterface[]>([])
    let [conversationSelected, setConversationSelected] = useState<WhatsappConversationInterface>({} as WhatsappConversationInterface)
    let [contextMenuConversationSelected, setContextMenuConversationSelected] = useState<WhatsappConversationInterface>({} as WhatsappConversationInterface)

    let [conversationsFilter, setConversationsFilter] = useState<WhatsappConversationInterface[]>([])

    //Default Messages
    let [defaultMessages, setDefaultMessages] = useState<DefaultMessageInterface[]>([])
    let [auxDefaultMessages, setAuxDefaultMessages] = useState<DefaultMessageInterface[]>([])

    let [selectReplyMessageChat, setSelectReplyMessageChat] = useState<MessagesWhatsappConversation>({} as MessagesWhatsappConversation)
    let [companiesOpportunity, setCompaniesOpportunity] = useState<OpportunityInterface[]>([])
    let [activeIndex1, setActiveIndex1] = useState(0)
    let [requestApiCompany, setRequestApiCompany] = useState<IEmpresaInterface>({} as IEmpresaInterface)

    //Filter
    let [typeFilterSend, setTypeFilterSend] = useState("")
    let [advanceSearchFilterConversations, setAdvanceSearchFilterConversations] = useState("")
    let [viewSearchMessagesButton, setViewSearchMessagesButton] = useState(false)
    let [unreadFilter, setUnreadFilter] = useState(false)
    let [firstMessage, setFirstMessage] = useState(false)

    let [informationDetailMessageChat, setInformationDetailMessageChat] = useState<MessagesWhatsappConversation>({} as MessagesWhatsappConversation)
    let [profileUser, setProfileUser] = useState<UserInterface>({} as UserInterface)
    let [selectedViewImageMessage, setSelectedViewImageMessage] = useState<MessagesWhatsappConversation>({} as MessagesWhatsappConversation)
    let [sendFileImageAndVideo, setSendFileImageAndVideo] = useState<AttachmentInterface[]>([])
    let [sendFileDocument, setFileSendDocument] = useState<AttachmentInterface[]>([])
    let [responsibleContact, setResponsibleContact] = useState<WhatsappConversationInterface>({} as WhatsappConversationInterface)
    let [editResponsibleContact, setEditResponsibleContact] = useState<UserInterface>({} as UserInterface)

    let [roadMapSelectVisualization, setRoadMapSelectVisualization] = useState<RoadMapInterface>({} as RoadMapInterface)
    let [chatDialogMobile, setChatDialogMobile] = useState("disabled")
    let [screenMobile, setScreenMobile] = useState("screen")
    let [buttonScrollBottom, setButtonScrollBottom] = useState(false)

    //Array for TabView
    let [myConversationsTabView, setMyConversationsTabView] = useState<TabsConversationInterfaces>({} as TabsConversationInterfaces)
    let [noResponsibleTabView, setNoResponsibleTabView] = useState<TabsConversationInterfaces>({} as TabsConversationInterfaces)
    let [allConversationsTabView, setAllConversationsTabView] = useState<TabsConversationInterfaces>({} as TabsConversationInterfaces)

    //Data socket
    let [dataCreatedMessageSocket, setDataCreatedMessageSocket] = useState<MessagesWhatsappConversation>({} as MessagesWhatsappConversation)
    let [dataUpdateResponsible, setDataUpdateResponsible] = useState<WhatsappConversationInterface>({} as WhatsappConversationInterface)

    /* Attachments */
    let [defaultMessageItem, setDefaultMessageItem] = useState<DefaultMessageInterface>({} as DefaultMessageInterface)
    let [users, setUsers] = useState<UserInterface[]>([])

    /* File Path */
    let [generalFilePath, setGeneralFilePath] = useState<any>([])
    let [generalFilePathName, setGeneralFilePathName] = useState<any>([])
    let [imagesFilePath, setImagesFilePath] = useState<any>([])
    let [videoFilePath, setVideoPath] = useState<any>([])

    /* Audio Recorder */
    let [micRecorder, setMicRecprder] = useState(false)
    let [recording, setRecording] = useState(false)
    let [archiveConversation, setArchiveConversation] = useState(false)
    let [firstDefaultMessage, setFirstDefaultMessage] = useState(false)

    /* Input Texts */
    let [inputTextSearchMessagesChat, setInputTextSearchMessagesChat] = useState("")
    let [inputSendImage, setInputSendImage] = useState("")
    let [inputSendFirtsMessage, setInputSendFirtsMessage] = useState("")
    let [inputTextSendMessage, setInputTextSendMessage] = useState("")
    let [classNameinputTextSendMessageOnDesktop, setClassNameinputTextSendMessageOnDesktop] = useState("noSendTextInputMessage")
    let [classNameinputTextSendMessageOnMobile, setClassNameinputTextSendMessageOnMobile] = useState("noSendTextInputMessage")

    //Contacts
    let [auxContactsFiltered, setAuxContactsFiltered] = useState<ContactInterface[]>([])
    let [contacts, setContacts] = useState<ContactInterface[]>([])
    let [auxContacts, setAuxContacts] = useState<any>([])
    let [nameContact, setNameContact] = useState<string>("")
    let [phoneContact, setPhoneContact] = useState<string>("")
    let [widthBoxDateTimeInChat, setWidthBoxDateTimeInChat] = useState<string>("")
    let [widthLastMessageText] = useState<string>("")
    let [idMessageForOnMouseOver, setIdMessageForOnMouseOver] = useState<string>("")
    let [idReplyMessage, setIdReplyMessage] = useState<string>("")

    // Filter dates conversation
    let [valueFilterDate1, setValueFilterDate1] = useState<any>(null)
    let [valueFilterDate2, setValueFilterDate2] = useState<any>(null)

    //Dialogs
    let [archiveConversationDialog, setArchiveConversationDialog] = useState(false)
    let [changeResponsibleDialog, setChangeResponsibleDialog] = useState(false)
    let [confirmUploadFileDialog, setConfirmUploadFileDialog] = useState(false)
    let [contactsDialog, setContactsDialog] = useState(false)
    let [editCompanyDialog, setEditCompanyDialog] = useState(false)
    let [imageNewPreviewDialog, setImageNewPreviewDialog] = useState(false)
    let [imagePreviewDialog, setImagePreviewDialog] = useState(false)
    let [imageUploadDialog, setImageUploadDialog] = useState(false)
    let [informationDetailMessageVisualition, setInformationDetailMessageVisualition] = useState("noViewInformationMessage")
    let [nameClassAvatarDialog, setNameClassAvatarDialog] = useState("noDialogShowClientInformation")
    let [nameClassAvatarDialogSeller, setNameClassAvatarDialogSeller] = useState("noShowInformationAboutSellerWhoResponded")
    let [newMessageDialog, setNewMessageDialog] = useState(false)
    let [openModalVisualizationRoudMap, setOpenModalVisualizationRoudMap] = useState(false)
    let [roadMapDialog, setRoadMapDialog] = useState(false)
    let [sendDefaulMessageDialog, setSendDefaultMessageDialog] = useState(false)
    let [unarchiveConversationDialog, setUnArchiveConversationDialog] = useState(false)
    let [uploadFromFilesDialog, setUploadFromFilesDialog] = useState(false)
    let [uploadLocalDocument, setUploadLocalDocument] = useState(false)
    let [videoDialog, setVideoDialog] = useState(false)
    let [videoNewPreviewDialog, setVideoNewPreviewDialog] = useState(false)
    let [videoPreviewDialog, setVideoPreviewDialog] = useState(false)
    let [viewImageMessage, setViewImageMessage] = useState(false)
    let [executeScrolluseEffect, setExecuteScrollUseEffect] = useState(false)
    let [dialogFilterDatesConversation, setDialogFilterDatesConversation] = useState(false)

    //Fetch more messages
    let [lengthOfMessagesConversationSelected, setLengthOfMessagesConversationSelected] = useState(0)
    let [hasMoreMessagesConversationSelected, setHasMoreMessagesConversationSelected] = useState(true)
    let [positionOfLastMessageOpen, setpositionOfLastMessageOpen] = useState<MessagesWhatsappConversation>({} as MessagesWhatsappConversation)

    //Resposible
    let [changeResponsible, setChangeResponsible] = useState(false)
    let [removeResponsible, setRemoveResponsible] = useState(false)

    let [sendMessageAgain, setSendMessageAgain] = useState(false)
    let [messageFailedSendAgain, setMessageFailedSendAgain] = useState<MessagesWhatsappConversation>({} as MessagesWhatsappConversation)
    let [replyMessageUse, setReplyMessageUse] = useState<MessagesWhatsappConversation>({} as MessagesWhatsappConversation)
    let [informationAboutSellerWhoResponded, setInformationAboutSellerWhoResponded] = useState<UserInterface>({} as UserInterface)
    let [viewEditInformationAboutSeller, setViewEditInformationAboutSeller] = useState(false)

    //Count Date last message
    let [verification24, setVerification24] = useState(true)
    let [companyCnpj, setCompanyCnpj] = useState("")

    //Filter Search message
    let [chatMessagesSearch, setChatMessagesSearch] = useState<string[]>([])
    let [countIndexSearchMessageFilter, setCountIndexSearchMessageFilter] = useState(0)
    let [idSearchMessageDiv, setIdSearchMessageDiv] = useState("")
    let [viewButtonsSearchMessageUpOrDown, setViewButtonsSearchMessageUpOrDown] = useState(false)

    //Status (Controller from input and audio)
    let [isActive, setIsActive] = useState(false)
    let [statusClose, setStatusClose] = useState(false)

    //Audio timer counter
    let [sendFileAudio, setSendFileAudio] = useState(false)
    let [permission, setPermission] = useState(true)

    /* Loadings */
    let [loadingArchiveConversation, setLoadingArchiveConversation] = useState(false)
    let [loadingButtonUpdateOpportunity, setLoadingButtonUpdateOpportunity] = useState(false)
    let [loadingFetchMoreMessages, setLoadingFetchMoreMessages] = useState(false)
    let [loadingChangeResponsible, setLoadingChangeResponsible] = useState(false)
    let [loadingListContacts, setLoadingListContacts] = useState<boolean>(false)
    let [loadingSendFile, setLoadingSendFile] = useState<boolean>(false)
    let [loadingSendRoadMap, setLoadingSendRoadMap] = useState(false)
    let [loadingRemoveResponsible, setLoadingRemoveResponsible] = useState(false)
    let [skeletonLoading, setSkeletonLoading] = useState(false)
    let [skeletonLoadingMessages, setSkeletonLoadingMessages] = useState(false)
    let [skeletonLoadingDefaultMessage, setSkeletonLoadingDefaultMessage] = useState(false)
    let [loadingButtonFilterDate, setLoadingButtonFilterDate] = useState(false)
    let [loadingButtonClearFilterDate, setLoadingButtonClearFilterDate] = useState(false)

    //Chat contacts
    let [contactsChat, setContactsChat] = useState<WhatsappConversationInterface[]>([])
    let [saveCompanyData, setSaveCompanyData] = useState<SaveCompanyData>({} as SaveCompanyData)

    //Input text height setting
    let atualHeightInputMessage = document.getElementById("sendTextInputMessage")?.clientHeight
    let heightWindow = window.screen.height
    let marginTopWindowInput = 0

    //Mobile State
    let [onSearchMessageChatMobile, setOnSearchMessageChatMobile] = useState(false)
    let [existingCompany, setExistingCompany] = useState<CompanyInterface>({} as CompanyInterface)
    let [arrayConversationID] = useState<string[]>([])

    //Right click
    let [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 })
    let [show, setShow] = useState(false)

    //Atachemnts Selected
    let [imageSelected, setImageSelected] = useState<AttachmentInterface>({} as AttachmentInterface)
    let [videoSelected, setVideoSelected] = useState<AttachmentInterface>({} as AttachmentInterface)
    let [indexImage, setIndexImage] = useState(0)
    let [indexVideo, setIndexVideo] = useState(0)

    //Opportunity
    let [formContact, setFormContact] = useState<ContactInterface>({} as ContactInterface)
    let [opportunities, setOpportunities] = useState<OpportunityInterface[]>([])
    let [updateCompanyOpportunity] = useState<CompanyInterface>({} as CompanyInterface)
    let [newEmailField, setNewEmailField] = useState([{ id: uuidv4(), emails: "", name: "email" }])
    let [newPhoneField, setNewPhoneField] = useState([{ id: uuidv4(), telephone: "", name: "phone", ddi: { ddi: "+055", country: "Brasil" } }])

    /* HotKeys Dialog */
    let [rendererDefaultMessageShortcut, setRendererDefaultMessageShortcut] = useState(false)
    let [hotKeysDialog, setHotKeysDialog] = useState(false)

    useHotkeys("ctrl+alt+a", () => setHotKeysDialog(true))
    useHotkeys("ctrl+alt+p", () => setNameClassAvatarDialog("dialogShowClientInformation"))
    useHotkeys("ctrl+shift+alt+f", () => autoFocusAndCleanInputSearchBar())
    useHotkeys("ctrl+shift+alt+i", () => setImageUploadDialog(true))
    useHotkeys("ctrl+shift+alt+n", () => setNewMessageDialog(true))

    const menuItens = [
        {
            label: "Nova Conversa",
            icon: "pi pi-user-plus",
            command: () => {
                if (contacts.length === 0) {
                    allContacts()
                }

                setNewMessageDialog(true)
            },
        },
        {
            label: "Atualizar Contatos",
            icon: "pi pi-refresh",
            command: () => {
                refreshContacts()
            },
        },
        {
            label: archiveConversation ? "Fechar Conversas arquivadas" : "Ver Conversas arquivadas",
            icon: archiveConversation ? "pi pi-times " : "pi pi-folder-open",
            command: () => {
                if (archiveConversation) {
                    archiveConversation = false
                    setArchiveConversation(archiveConversation)
                } else {
                    archiveConversation = true
                    setArchiveConversation(archiveConversation)
                    allArchivedConversation()
                }
            },
        },
    ]

    async function validateCreateContact() {
        let schema = yup.object().shape({
            name: yup.string().required(),
            job: yup.string(),
            company: yup.object().required(),
        })

        try {
            await schema.validate(formContact)
            return true
        } catch (error) {
            return false
        }
    }

    const allArchivedConversation = async () => {
        try {
            const header = {
                headers: {
                    route: "list_whatsapp_conversations",
                },
            }

            const res = await api.get("whatsapp_conversation/archived", header)

            if (res.status === 200) {
                let auxConversation = [...conversations, ...res.data]
                const sortedArray = auxConversation.sort(
                    (a: WhatsappConversationInterface, b: WhatsappConversationInterface) =>
                        new Date(b.messages[b.messages.length - 1]?.created_at).getTime() - new Date(a.messages[a.messages.length - 1]?.created_at).getTime()
                )

                conversations = sortedArray

                setAuxConversations(conversations)

                setTimeout(() => {
                    setMyConversationsTabView((myConversationsTabView) => ({
                        ...myConversationsTabView,
                        conversations: conversations.filter((conversation) => conversation.user !== null && conversation.user.id === profileUser.id),
                    }))
                }, 100)

                setTimeout(() => {
                    setSkeletonLoading(false)
                }, 20)

                tabFilterSetFunction()
                allUseEffect()
                useParamEffect()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const allContacts = async () => {
        setLoadingListContacts(true)

        try {
            const response = await api.get("contacts")
            const data = response.data
            setLoadingListContacts(false)
            setContacts(data)
            setAuxContactsFiltered(data)
            contacts = data
            auxContactsFiltered = data
        } catch (error: any) {
            toast.current?.show({
                severity: "error",
                summary: `Erro ao listar contatos!`,
                detail: error.response?.data?.message || "Erro desconhecido",
                life: 3000,
            })
        }
    }

    const allConversations = async () => {
        try {
            const header = {
                headers: {
                    route: "list_whatsapp_conversations",
                },
            }

            const res = await api.get("whatsapp_conversation", header)

            if (res.status === 200) {
                conversations = res.data

                setAuxConversations(conversations)
                setConversations(conversations)
                setConversationsFilter(conversations)

                setTimeout(() => {
                    setMyConversationsTabView((myConversationsTabView) => ({
                        ...myConversationsTabView,
                        conversations: conversations.filter((conversation) => conversation.user !== null && conversation.user.id === profileUser.id),
                    }))
                }, 100)

                setTimeout(() => {
                    setSkeletonLoading(false)
                }, 20)

                tabFilterSetFunction()
                allUseEffect()
                useParamEffect()
                setLoadingButtonClearFilterDate(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const refreshContacts = async () => {
        try {
            const header = {
                headers: {
                    route: "list_whatsapp_conversations",
                },
            }

            const res = await api.post("whatsapp_conversation/contacts", header)

            if (res.status === 200) {
                toast.current?.show({
                    severity: "success",
                    summary: "Contatos atualizados com sucesso!",
                    life: 3000,
                })
            } else {
                throw new Error("Erro desconhecido")
            }
        } catch (error: any) {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar contatos!",
                detail: error.response?.data?.message || error.message,
                life: 3000,
            })
        }
    }

    const allSellers = async () => {
        try {
            const header = {
                headers: {
                    route: "show_crm_configuration_sellers",
                },
            }

            const res = await api.get("users", header)

            setUsers(res.data)
        } catch (error: any) {
            const errorMessage = error.response ? error.response.data.message : "Erro ao listar sellers!"
            toast.current?.show({
                severity: "error",
                summary: "Erro",
                detail: errorMessage,
                life: 3000,
            })
        }
    }

    const allDefaultMessages = async () => {
        try {
            setSkeletonLoadingDefaultMessage(true)
            const header = {
                headers: {
                    route: "list_chat_groups",
                },
            }
            const response = await api.get("default_message", header)
            const defaultMessages = response.data.map((defaultMessage: DefaultMessageInterface) => {
                if (defaultMessage.chat_group_id === null) {
                    return { ...defaultMessage, chat_group_id: "000001" }
                }
                return defaultMessage
            })
            setDefaultMessages(defaultMessages)
            setSkeletonLoadingDefaultMessage(false)
        } catch (error) {
            console.log(error)
        }
    }

    const showProfileClient = async (clientInformation: any) => {
        setCompaniesOpportunity([])
        const header = {
            headers: {
                route: "list_company_opportunity",
            },
        }

        const response = await api.get(`companies/opportunities/${clientInformation.contact.company_id}`, header)
        const data = response.data
        companiesOpportunity = data
        setCompaniesOpportunity(companiesOpportunity)
    }

    const allUseEffect = () => {
        const item = paramId ? conversations.find((item) => item.id === paramId) : undefined
        item !== undefined && setConversationSelected(item)
    }

    const allOpportunities = async (companyId: any) => {
        try {
            let header = {
                headers: {
                    route: "list_opportunities_by_company",
                },
            }

            const res = await api.get(`opportunities/list/company/${companyId}`, header)
            opportunities = res.data
            setOpportunities(opportunities)
            showProfileClient(conversationSelected)
            setNameClassAvatarDialog("dialogShowClientInformation")
            setNameClassAvatarDialogSeller("noShowInformationAboutSellerWhoResponded")
        } catch (error: any) {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar!",
                detail: error.response.data.message,
                life: 3000,
            })
        }
    }

    const allMessages = async (conversationId: any) => {
        if (!arrayConversationID.includes(conversationId)) {
            setSkeletonLoadingMessages(true)
            arrayConversationID.push(conversationId)

            try {
                const header = {
                    headers: {
                        route: "list_messages_by_conversation",
                    },
                }

                const res = await api.get(`/whatsapp/conversation/${conversationId}`, header)
                const auxConversations = res.data
                const updatedConversations = [...conversations]
                const index = updatedConversations.findIndex((item) => item.id === conversationId)

                if (index !== -1) {
                    updatedConversations[index].messages = auxConversations
                    setConversations(updatedConversations)
                    setExecuteScrollUseEffect(true)
                }
            } catch (error: any) {
                console.log(error)
            }
        }
    }

    const useParamEffect = async () => {
        if (!phone) return

        const cleanedPhone = phone.replace(/[^0-9]/g, "").replace(/^0/, "")
        const phone8Numbers = cleanedPhone.slice(-8)

        const conversation = conversations.find((item) => item.phone_number.endsWith(phone8Numbers))

        if (conversation) {
            if (conversation.user?.id !== profileUser.id) {
                permission
                    ? (executeScroll(), executeScrollMobile(), setConversationSelected(conversation), allMessages(conversation.id))
                    : toast.current?.show({
                          severity: "warn",
                          summary: "Você não tem permissão para visualizar essa conversa!",
                          detail: "Essa conversa pertence a outro usuário",
                          life: 3000,
                      })
            } else {
                executeScroll()
                executeScrollMobile()
                setConversationSelected(conversation)
                allMessages(conversation.id)

                if (conversation.archived) {
                    executeScroll()
                    executeScrollMobile()
                    setConversationSelected(conversation)
                    allMessages(conversation.id)
                }
            }
        } else {
            toast.current?.show({
                severity: "warn",
                summary: "Conversa não encontrada!",
                detail: "Essa conversa não existe, comece uma nova conversa!",
                life: 2000,
            })
            const newConversation = {
                id: "",
                messages: [],
                phone_number: cleanedPhone,
                whatsapp_phone_number: cleanedPhone,
                contact_id: "",
                contact: {} as ContactWhatsappConversation,
                user_id: "",
                user: {} as UserInterface,
                profile_picture_url: "",
                created_at: "",
                archived: false,
                updated_at: "",
                new: false,
            }
            setConversationSelected(newConversation)
        }
    }

    const OpportunityCreation = () => {
        let auxPhone

        if (conversationSelected.phone_number !== null) {
            if (conversationSelected.phone_number.substring(0, 2) === "55") {
                let newNumber = conversationSelected.phone_number.substring(2)
                auxPhone = {
                    id: uuidv4(),
                    telephone: `${newNumber}`,
                    name: "phone",
                    ddi: {
                        ddi: "+055",
                        country: "Brasil",
                    },
                }
            }
        }

        if (auxPhone !== undefined) {
            newPhoneField[0] = auxPhone
            setNewPhoneField(newPhoneField)
        }
        setFormContact(formContact)
    }

    const tabMyConversations = (options: any) => {
        if (!isEmptyObj(myConversationsTabView)) {
            return (
                <Button type="button" onClick={options.onClick} className={options.className}>
                    {myConversationsTabView.quantity > 0 && !archiveConversation && (
                        <Badge value={myConversationsTabView.quantity} severity="danger" style={{ marginRight: "6px" }}></Badge>
                    )}
                    {myConversationsTabView.archiveQuantity > 0 && archiveConversation && (
                        <Badge value={myConversationsTabView.archiveQuantity} severity="danger" style={{ marginRight: "6px" }}></Badge>
                    )}
                    {options.titleElement}
                </Button>
            )
        }
    }

    const tabWithoutResponsible = (options: any) => {
        if (!isEmptyObj(noResponsibleTabView)) {
            return (
                <Button type="button" onClick={options.onClick} className={options.className}>
                    {noResponsibleTabView.quantity > 0 && !archiveConversation && (
                        <Badge value={noResponsibleTabView.quantity} severity="danger" style={{ marginRight: "6px" }}></Badge>
                    )}
                    {noResponsibleTabView.archiveQuantity > 0 && archiveConversation && (
                        <Badge value={noResponsibleTabView.archiveQuantity} severity="danger" style={{ marginRight: "6px" }}></Badge>
                    )}

                    {options.titleElement}
                </Button>
            )
        }
    }

    const tabAllMyMessages = (options: any) => {
        if (!isEmptyObj(allConversationsTabView)) {
            return (
                <Button type="button" onClick={options.onClick} className={options.className}>
                    {allConversationsTabView.quantity > 0 && !archiveConversation && (
                        <Badge value={allConversationsTabView.quantity} severity="danger" style={{ marginRight: "6px" }}></Badge>
                    )}

                    {allConversationsTabView.archiveQuantity > 0 && archiveConversation && (
                        <Badge value={allConversationsTabView.archiveQuantity} severity="danger" style={{ marginRight: "6px" }}></Badge>
                    )}

                    {options.titleElement}
                </Button>
            )
        }
    }

    //Upload Methods
    const onUploadFile = (files: any) => {
        const data = new FormData()
        data.append("file", files[0].file)
        if (
            (files[0].file.type === "image/jpeg" || files[0].file.type === "image/png" || files[0].file.type === "image/jpg") &&
            files[0].file.size <= 5000000
        ) {
            setUploadFromFilesDialog(false)
            setImageNewPreviewDialog(true)
            imagesFilePath = URL.createObjectURL(files[0].file)
            setImagesFilePath(imagesFilePath)
            generalFilePath = data
            setGeneralFilePath(generalFilePath)
            generalFilePathName = files[0].file.name
            setGeneralFilePathName(generalFilePathName)
        } else if (
            (files[0].file.type === "image/jpeg" || files[0].file.type === "image/png" || files[0].file.type === "image/jpg") &&
            files[0].file.size > 5000000
        ) {
            toast.current?.show({
                severity: "warn",
                summary: "Arquivo de vídeo muito grande",
                detail: "Enviando como documento",
                life: 3000,
            })
            setUploadFromFilesDialog(false)
            setUploadLocalDocument(true)
            setVideoPath(URL.createObjectURL(files[0].file))
            generalFilePath = data
            setGeneralFilePath(generalFilePath)
            generalFilePathName = files[0].file.name
            setGeneralFilePathName(generalFilePathName)
            typeFilterSend = "image"
            setTypeFilterSend(typeFilterSend)
        } else if (
            (files[0].file.type === "audio/mp3" || files[0].file.type === "audio/ogg" || files[0].file.type === "audio/wav") &&
            files[0].file.size <= 16000000
        ) {
            generalFilePath = data
            setGeneralFilePath(generalFilePath)
            onSendLocalAudio()
            setUploadFromFilesDialog(false)
            toast.current?.show({
                severity: "info",
                summary: "Enviando áudio...",
                life: 500,
            })
        } else if (
            (files[0].file.type === "audio/mp3" || files[0].file.type === "audio/ogg" || files[0].file.type === "audio/wav") &&
            files[0].file.size > 16000000
        ) {
            toast.current?.show({
                severity: "warn",
                summary: "Arquivo de áudio muito grande",
                detail: "Enviando como documento",
                life: 3000,
            })
            setUploadFromFilesDialog(false)
            generalFilePath = data
            setGeneralFilePath(generalFilePath)
            generalFilePathName = files[0].file.name
            setGeneralFilePathName(generalFilePathName)
            onSendLocalLargeAudio()
            typeFilterSend = "audio"
            setTypeFilterSend(typeFilterSend)
        } else if (
            (files[0].file.type === "video/mp4" || files[0].file.type === "video/avi" || files[0].file.type === "video/mkv") &&
            files[0].file.size <= 16000000
        ) {
            setUploadFromFilesDialog(false)
            setVideoNewPreviewDialog(true)
            setVideoPath(URL.createObjectURL(files[0].file))
            generalFilePath = data
            setGeneralFilePath(generalFilePath)
            generalFilePathName = files[0].file.name
            setGeneralFilePathName(generalFilePathName)
        } else if (
            (files[0].file.type === "video/mp4" || files[0].file.type === "video/avi" || files[0].file.type === "video/mkv") &&
            files[0].file.size > 16000000
        ) {
            toast.current?.show({
                severity: "warn",
                summary: "Arquivo de vídeo muito grande",
                detail: "Enviando como documento",
                life: 3000,
            })
            setUploadFromFilesDialog(false)
            setUploadLocalDocument(true)
            setVideoPath(URL.createObjectURL(files[0].file))
            generalFilePath = data
            setGeneralFilePath(generalFilePath)
            generalFilePathName = files[0].file.name
            setGeneralFilePathName(generalFilePathName)
            typeFilterSend = "document"
            setTypeFilterSend(typeFilterSend)
        } else {
            setUploadFromFilesDialog(false)
            setUploadLocalDocument(true)
            setVideoPath(URL.createObjectURL(files[0].file))
            generalFilePath = data
            setGeneralFilePath(generalFilePath)

            generalFilePathName = files[0].file.name
            setGeneralFilePathName(generalFilePathName)
        }
    }

    const toastTrue = () => {
        toast.current?.show({
            severity: "warn",
            summary: "Erro ao gravar áudio!",
            detail: "Ative seu Microfone ou selecione outro dispositivo para gravar o áudio",
            life: 3000,
        })
    }

    const handleSaveCompanyData = (item: any) => {
        const { value, name } = item.target
        setSaveCompanyData({ ...saveCompanyData, [name]: value })
    }

    const sendFirtsMesage = async (event: any, textSend: any) => {
        try {
            event.preventDefault()

            setInputTextSendMessage("")
            const text = inputSendFirtsMessage.replace("<", "&lt;").replace(">", "&gt;")
            setInputSendFirtsMessage("")

            if (conversations.some((conversation) => conversation.phone_number === phoneContact)) {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao Enviar primeira mensagem!",
                    detail: ERROR_MESSAGES.contactExists,
                    life: 3000,
                })
            } else {
                setSelectReplyMessageChat({} as MessagesWhatsappConversation)

                const header = {
                    headers: {
                        route: "send_whatsapp_text_message",
                    },
                }

                const response = await api.post(
                    "whatsapp/send/text",
                    {
                        text: textSend,
                        to: conversationSelected.phone_number,
                        context_message_id: null,
                        over24: true,
                        whatsapp_conversation_id: null,
                        whatsapp_phone_number: user.whatsapp_phone_number,
                    },
                    header
                )

                if (response.status === 200) {
                    setFirstMessage(false)
                    const conversationExistInList = conversations.findIndex((conversation) => conversation.id === response.data)
                    if (conversationExistInList !== -1) {
                        conversationSelected = conversations[conversationExistInList]
                    } else {
                        conversationSelected.id = response.data
                    }
                    setConversationSelected(conversationSelected)
                    setInputSendFirtsMessage("")
                    setInputSendImage("")
                    allConversations()
                    allMessages(conversationSelected.id)
                } else {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao Enviar primeira mensagem!",
                        detail: ERROR_MESSAGES.response.replace("{message}", response.data.message),
                        life: 3000,
                    })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const updateWhatsappMessageToRead = () => {
        if (activeIndex1 !== 2) {
            try {
                let header = {
                    headers: {
                        route: "update_whatsapp_messages_to_read",
                    },
                }
                api.post(
                    "whatsapp/read",
                    {
                        whatsapp_conversation_id: conversationSelected.id,
                    },
                    header
                )
                    .then((res) => {
                        if (res.status === 200) {
                            let auxConversation = [...conversations]
                            let indexConversation = auxConversation.findIndex((conversation) => conversation.id === conversationSelected.id)

                            if (indexConversation >= 0) {
                                auxConversation[indexConversation].messages.map((message, index) => {
                                    if (message.new === true) {
                                        auxConversation[indexConversation].messages[index].new = false
                                    }
                                    return ""
                                })
                            }
                            setConversations(auxConversation)
                            countOfUnviewedMessages(conversations[indexConversation])
                            tabFilterSetFunction()
                        }
                    })
                    .catch((error) => {
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao visualizar mensagem!",
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        }
    }

    const onSendMessagetoContact = (textSend: string, event?: any) => {
        if (firstMessage) {
            sendFirtsMesage(event, textSend)
            return
        }

        let link
        if (textSend === "") {
        } else {
            let over24 = dateLastMessage(conversationSelected)
            let valueForMobile = document.getElementById("w-input-text")
            let valueForDesktop = document.getElementById("w-textAreaDesktop")
            let textDesktop = valueForDesktop !== null ? valueForDesktop.innerHTML : ""
            let textMobile = valueForMobile !== null ? valueForMobile.innerHTML : ""

            let number = user.whatsapp_phone_number

            var editElement = document.getElementById("w-input-text")
            if (editElement !== null) {
                editElement.innerHTML = ""
            }
            var editElementDesktop = document.getElementById("w-textAreaDesktop")
            if (editElementDesktop !== null) {
                editElementDesktop.innerHTML = ""
            }

            if (textSend.includes("\n")) {
                let text = textSend.replace(/\n/g, "")
                if (text === "") {
                    return
                }
            }

            let text = textSend.replace("<", "&lt;")
            let finalText = text.replace(">", "&gt;")

            if (textSend === "sendMessageAgain") {
                finalText = messageFailedSendAgain.text
            } else {
                event.preventDefault()
            }
            let contextMessage
            let activeWidonws: number

            if (conversationSelected.user_id === null) {
                activeWidonws = 1
            }

            if (!isEmptyObj(selectReplyMessageChat)) {
                contextMessage = selectReplyMessageChat.whatsapp_id
            } else {
                contextMessage = null
            }

            if (finalText.includes("https://") || finalText.includes("http://")) {
                link = true
            } else {
                link = false
            }

            try {
                let header = {
                    headers: {
                        route: "send_whatsapp_text_message",
                    },
                }
                api.post(
                    "whatsapp/send/text",
                    {
                        text: finalText,
                        to: conversationSelected.phone_number,
                        context_message_id: contextMessage,
                        over24: textSend === "sendMessageAgain" ? true : over24,
                        whatsapp_conversation_id: conversationSelected.id,
                        link,
                        whatsapp_phone_number: number,
                    },
                    header
                )
                    .then((res) => {
                        if (res.status === 200) {
                            setInputTextSendMessage("")
                            hideDialog()
                            setSendMessageAgain(false)
                            loadingSendFile = false
                            setLoadingSendFile(loadingSendFile)
                            executeScroll()
                            setSelectReplyMessageChat({} as MessagesWhatsappConversation)

                            if (activeWidonws === 1) {
                                setActiveIndex1(0)
                            }
                            if (conversationSelected.id === "") {
                                const conversationExistInList = conversations.findIndex((conversation) => conversation.id === res.data)
                                if (conversationExistInList === -1) {
                                    conversationSelected.id = res.data
                                    setConversationSelected(conversationSelected)
                                } else {
                                    conversationSelected = conversations[conversationExistInList]
                                    setConversationSelected(conversationSelected)
                                }
                            }
                        } else {
                            toast.current?.show({
                                severity: "warn",
                                summary: "Erro ao enviar mensagem!",
                                life: 3000,
                                detail: res.data.message,
                            })
                        }
                    })
                    .catch((error) => {
                        if (editElement !== null && editElementDesktop !== null) {
                            editElement.innerHTML = textMobile
                            editElementDesktop.innerHTML = textDesktop
                        }
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao enviar mensagem!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        }
    }

    const onUploadMediaFile = (textInformation?: string) => {
        let over24 = dateLastMessage(conversationSelected)

        let header = {
            headers: {
                route: "send_whatsapp_media_message",
            },
        }

        let activeWidonws: number

        if (conversationSelected.user_id === null) {
            activeWidonws = 1
        }

        if (textInformation === "sendMessageAgain") {
            let auxConfirPresentation

            let textImage = null
            if (messageFailedSendAgain.text !== null) {
                textImage = messageFailedSendAgain.text
            }

            auxConfirPresentation = {
                to: conversationSelected.phone_number,
                attachment_id: messageFailedSendAgain.attachment_id,
                text: textImage,
                over24: textInformation === "sendMessageAgain" ? true : over24,
                whatsapp_conversation_id: conversationSelected.id,
                whatsapp_phone_number: user.whatsapp_phone_number,
            }

            try {
                api.post("whatsapp/send/media", auxConfirPresentation, header)
                    .then((res) => {
                        if (res.status === 200) {
                            setSelectReplyMessageChat({} as MessagesWhatsappConversation)
                            setImagePreviewDialog(false)
                            setVideoDialog(false)
                            setSendMessageAgain(false)
                            hideDialog()
                            setLoadingSendFile(false)
                            setInputSendImage("")

                            if (conversationSelected.id === "") {
                                const conversationExistInList = conversations.findIndex((conversation) => conversation.id === res.data)
                                if (conversationExistInList === -1) {
                                    conversationSelected.id = res.data
                                    setConversationSelected(conversationSelected)
                                } else {
                                    conversationSelected = conversations[conversationExistInList]
                                    setConversationSelected(conversationSelected)
                                }
                            }

                            if (activeWidonws === 1) {
                                setActiveIndex1(0)
                            }
                        } else {
                            setLoadingSendFile(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao enviar arquivo!",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingSendFile(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao enviar arquivo!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        }

        sendFileImageAndVideo.forEach((thisFile) => {
            setLoadingSendFile(true)
            let auxConfirPresentation
            let contextMessage

            let text = inputSendImage.replace("<", "&lt;")
            let finalText = text.replace(">", "&gt;")

            if (!isEmptyObj(selectReplyMessageChat)) {
                contextMessage = selectReplyMessageChat.whatsapp_id
            } else {
                contextMessage = null
            }

            if (inputSendImage === "" && textInformation === undefined && thisFile.text === undefined) {
                auxConfirPresentation = {
                    to: conversationSelected.phone_number,
                    attachment_id: thisFile.id,
                    text: null,
                    over24: verification24,
                    context_message_id: contextMessage,
                    whatsapp_conversation_id: conversationSelected.id,
                    whatsapp_phone_number: user.whatsapp_phone_number,
                }
            } else if (inputSendImage !== "" && textInformation === undefined && thisFile.text === undefined) {
                auxConfirPresentation = {
                    to: conversationSelected.phone_number,
                    attachment_id: thisFile.id,
                    text: finalText,
                    over24: verification24,
                    context_message_id: contextMessage,
                    whatsapp_conversation_id: conversationSelected.id,
                    whatsapp_phone_number: user.whatsapp_phone_number,
                }
            } else if (thisFile.text !== undefined && textInformation === undefined) {
                auxConfirPresentation = {
                    to: conversationSelected.phone_number,
                    attachment_id: thisFile.id,
                    text: thisFile.text,
                    over24: verification24,
                    context_message_id: contextMessage,
                    whatsapp_conversation_id: conversationSelected.id,
                    whatsapp_phone_number: user.whatsapp_phone_number,
                }
            }

            try {
                api.post(
                    "whatsapp/send/media",

                    auxConfirPresentation,
                    header
                )
                    .then((res) => {
                        if (res.status === 200) {
                            setSelectReplyMessageChat({} as MessagesWhatsappConversation)
                            setImagePreviewDialog(false)
                            setVideoDialog(false)
                            setSendMessageAgain(false)
                            hideDialog()
                            setLoadingSendFile(false)
                            setInputSendImage("")

                            if (activeWidonws === 1) {
                                setActiveIndex1(0)
                            }
                        } else {
                            setLoadingSendFile(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao enviar arquivo!",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingSendFile(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao enviar arquivo!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        })
    }

    const onUploadMedianoTextFile = () => {
        let over24 = dateLastMessage(conversationSelected)

        sendFileDocument.forEach((file) => {
            let contextMessage
            let activeWidonws: number

            if (conversationSelected.user_id === null) {
                activeWidonws = 1
            }
            if (!isEmptyObj(selectReplyMessageChat)) {
                contextMessage = selectReplyMessageChat.whatsapp_id
            } else {
                contextMessage = null
            }

            setLoadingSendFile(true)
            let auxConfirPresentation = {
                to: conversationSelected.phone_number,
                attachment_id: file.id,
                context_message_id: contextMessage,
                whatsapp_conversation_id: conversationSelected.id,
                over24: over24,
                whatsapp_phone_number: user.whatsapp_phone_number,
            }

            let header = {
                headers: {
                    route: "send_whatsapp_media_message",
                },
            }

            try {
                api.post(
                    "whatsapp/send/media",

                    auxConfirPresentation,
                    header
                )
                    .then((res) => {
                        if (res.status === 200) {
                            setSelectReplyMessageChat({} as MessagesWhatsappConversation)
                            setImagePreviewDialog(false)
                            setVideoDialog(false)
                            hideDialog()
                            setLoadingSendFile(false)
                            setConfirmUploadFileDialog(false)
                            setVideoDialog(false)
                            if (activeWidonws === 1) {
                                setActiveIndex1(0)
                            }
                            if (conversationSelected.id === "") {
                                const conversationExistInList = conversations.findIndex((conversation) => conversation.id === res.data)
                                if (conversationExistInList === -1) {
                                    conversationSelected.id = res.data
                                    setConversationSelected(conversationSelected)
                                } else {
                                    conversationSelected = conversations[conversationExistInList]
                                    setConversationSelected(conversationSelected)
                                }
                            }
                            setInputSendImage("")
                        } else {
                            setLoadingSendFile(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao enviar arquivo!",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingSendFile(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao enviar arquivo!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        })
    }

    const onPermissionRoute = async () => {
        try {
            const header = {
                headers: {
                    route: "list_all_whatsapp_messages",
                },
            }

            const response = await api.post("permission/check", {}, header)
            const { status } = response

            setPermission(status === 200 || status === 201)
        } catch (error) {
            setPermission(false)
        }
    }

    const ERROR_MESSAGES = {
        generic: "Erro ao enviar roteiro de mensagem!",
        response: "Erro ao enviar roteiro de mensagem: {message}",
        responseDetail: "Erro ao enviar roteiro de mensagem: {detail}",
        roadmap: "Você não pode responder uma conversa com um roteiro de mensagem",
        contactExists: "Contato já cadastrado!",
    }

    const onSendRoadMap = async (rowData: any) => {
        try {
            const over24 = dateLastMessage(conversationSelected)
            const contextMessage = !isEmptyObj(selectReplyMessageChat) && verification24 !== false ? selectReplyMessageChat.whatsapp_id : null

            if (contextMessage) {
                toast.current?.show({
                    severity: "warn",
                    summary: "Erro ao enviar roteiro!",
                    detail: ERROR_MESSAGES.roadmap,
                    life: 3000,
                })
                return
            }

            setLoadingSendFile(true)

            const auxConfirPresentation = {
                to: conversationSelected.phone_number,
                roadmap_id: rowData.id,
                whatsapp_conversation_id: conversationSelected.id,
                over24: over24,
                whatsapp_phone_number: user.whatsapp_phone_number,
            }

            const header = {
                headers: {
                    route: "send_whatsapp_roadmap",
                },
            }

            const response = await api.post("whatsapp/send/roadmap", auxConfirPresentation, header)

            setLoadingSendFile(false)

            if (response.status === 200) {
                setSelectReplyMessageChat({} as MessagesWhatsappConversation)
                setLoadingSendFile(false)
                setRoadMapDialog(false)
                setSendDefaultMessageDialog(false)
                setLoadingSendRoadMap(false)
                setOpenModalVisualizationRoudMap(false)
                setImageUploadDialog(false)

                if (!conversationSelected.id) {
                    const conversationExistInList = conversations.findIndex((conversation) => conversation.id === response.data)
                    if (conversationExistInList !== -1) {
                        conversationSelected = conversations[conversationExistInList]
                    } else {
                        conversationSelected.id = response.data
                    }
                    setConversationSelected(conversationSelected)
                }
            } else {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao enviar roteiro de mensagem!",
                    detail: ERROR_MESSAGES.response.replace("{message}", response.data.message),
                    life: 3000,
                })
            }
        } catch (error: any) {
            setLoadingSendFile(false)
            const errorMessage = error.response ? ERROR_MESSAGES.responseDetail.replace("{detail}", error.response.data.message) : ERROR_MESSAGES.generic
            toast.current?.show({
                severity: "error",
                summary: "Erro ao enviar roteiro de mensagem!",
                detail: errorMessage,
                life: 3000,
            })
        }
    }

    const archiveDesarchiveConversation = async () => {
        setLoadingArchiveConversation(true)

        const auxConfirPresentation = {
            whatsapp_conversation_id: responsibleContact.id,
            archived: !responsibleContact.archived,
        }

        const header = {
            headers: {
                route: "update_whatsapp_conversation_archived",
            },
        }

        try {
            const response = await api.put("whatsapp_conversation/archived", auxConfirPresentation, header)

            setLoadingArchiveConversation(false)
            setUnArchiveConversationDialog(false)
            setArchiveConversationDialog(false)

            if (response.status === 200) {
                const toastMessage = responsibleContact.archived ? "Conversa desarquivada com sucesso" : "Mensagem arquivada com sucesso"

                toast.current?.show({
                    severity: "success",
                    summary: "Sucesso!",
                    detail: toastMessage,
                    life: 3000,
                })
            } else {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao arquivar conversa!",
                    detail: "Por favor verifique a conversa selecionada",
                    life: 3000,
                })
            }
        } catch (error: any) {
            setLoadingArchiveConversation(false)

            const errorMessage = error.response?.data?.message || "Erro ao arquivar conversa!"
            toast.current?.show({
                severity: "error",
                summary: "Erro ao arquivar conversa!",
                detail: errorMessage,
                life: 3000,
            })

            console.error(error)
        }
    }

    const onSendAudio = (blobURL: any) => {
        let activeWidonws: number

        if (conversationSelected.user_id === null) {
            activeWidonws = 1
        }

        try {
            let auxAttachmentsRequisition = {
                type: "audio",
                name: "mediaBlobUrl",
            }
            let header = {
                headers: {
                    route: "create_whatsapp_attachment",
                },
            }

            api.post("attachments/whatsapp", auxAttachmentsRequisition, header)
                .then(async (res) => {
                    if (res.status === 200) {
                        let id2 = res.data.id
                        let header = {
                            headers: {
                                route: "update_file_whatsapp_attachment",
                            },
                        }

                        const audioBlob = await fetch(blobURL !== undefined ? blobURL : "").then((r) => r.blob())
                        const audioFile = new File([audioBlob], "voice.mpeg", { type: "audio/mpeg" })
                        const formData = new FormData()
                        formData.append("file", audioFile)

                        api.patch(`attachments/file/whatsapp/${id2}`, formData, header)

                            .then((res) => {
                                if (res.status === 200) {
                                    let contextMessage

                                    if (!isEmptyObj(selectReplyMessageChat) && verification24 !== false) {
                                        contextMessage = selectReplyMessageChat.whatsapp_id
                                    } else {
                                        contextMessage = null
                                    }

                                    let auxConfirPresentation = {
                                        to: conversationSelected.phone_number,
                                        attachment_id: id2,
                                        context_message_id: contextMessage,
                                        whatsapp_conversation_id: conversationSelected.id,
                                        whatsapp_phone_number: user.whatsapp_phone_number,
                                    }

                                    let header = {
                                        headers: {
                                            route: "send_whatsapp_media_message",
                                        },
                                    }

                                    try {
                                        api.post(
                                            "whatsapp/send/media",

                                            auxConfirPresentation,
                                            header
                                        )
                                            .then((res) => {
                                                if (res.status === 200) {
                                                    setSelectReplyMessageChat({} as MessagesWhatsappConversation)

                                                    if (conversationSelected.id === "") {
                                                        const conversationExistInList = conversations.findIndex((conversation) => conversation.id === res.data)
                                                        if (conversationExistInList === -1) {
                                                            conversationSelected.id = res.data
                                                            setConversationSelected(conversationSelected)
                                                        } else {
                                                            conversationSelected = conversations[conversationExistInList]
                                                            setConversationSelected(conversationSelected)
                                                        }
                                                    }

                                                    if (activeWidonws === 1) {
                                                        setActiveIndex1(0)
                                                    }
                                                } else {
                                                    toast.current?.show({
                                                        severity: "error",
                                                        summary: "Erro ao enviar áudio!",
                                                        detail: res.data.message,
                                                        life: 3000,
                                                    })
                                                }
                                            })
                                            .catch((error) => {
                                                toast.current?.show({
                                                    severity: "error",
                                                    summary: "Erro ao enviar áudio!",
                                                    detail: error.response.data.message,
                                                    life: 3000,
                                                })
                                            })
                                    } catch (error) {
                                        console.log(error)
                                    }
                                } else {
                                    toast.current?.show({
                                        severity: "error",
                                        summary: "Erro ao enviar áudio!",
                                        detail: res.data.message,
                                        life: 3000,
                                    })
                                }
                            })

                            .catch((error) => {
                                toast.current?.show({
                                    severity: "error",
                                    summary: "Erro ao enviar áudio!",
                                    detail: error.response.data.message,
                                    life: 3000,
                                })
                            })
                    } else {
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao cadastar!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao Criar áudio!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const onSendLocalDocument = async () => {
        setLoadingSendFile(true)

        const over24 = dateLastMessage(conversationSelected)
        const activeWindows = conversationSelected.user_id === null ? 1 : undefined

        const typeFilterSendFinal = typeFilterSend || "document"

        try {
            const createAttachmentsReq = {
                type_filter: typeFilterSendFinal,
                type: "document",
                name: generalFilePathName,
            }

            const createAttachmentsHeader = {
                headers: {
                    route: "create_whatsapp_attachment",
                },
            }

            const createAttachmentsResponse = await api.post("attachments/whatsapp", createAttachmentsReq, createAttachmentsHeader)

            if (createAttachmentsResponse.status === 200) {
                const attachmentId = createAttachmentsResponse.data.id
                const updateAttachmentHeader = {
                    headers: {
                        route: "update_file_whatsapp_attachment",
                    },
                }

                await api.patch(`attachments/file/whatsapp/${attachmentId}`, generalFilePath, updateAttachmentHeader)

                const contextMessage = !isEmptyObj(selectReplyMessageChat) && verification24 !== false ? selectReplyMessageChat.whatsapp_id : null

                const auxConfirPresentation = {
                    to: conversationSelected.phone_number,
                    attachment_id: attachmentId,
                    text: inputSendImage || null,
                    over24: over24,
                    context_message_id: contextMessage,
                    whatsapp_conversation_id: conversationSelected.id,
                    whatsapp_phone_number: user.whatsapp_phone_number,
                }

                const sendMediaHeader = {
                    headers: {
                        route: "send_whatsapp_media_message",
                    },
                }

                try {
                    const sendMediaResponse = await api.post("whatsapp/send/media", auxConfirPresentation, sendMediaHeader)

                    if (sendMediaResponse.status === 200) {
                        setSelectReplyMessageChat({} as MessagesWhatsappConversation)
                        setImagePreviewDialog(false)
                        setVideoDialog(false)
                        setImageNewPreviewDialog(false)
                        setVideoNewPreviewDialog(false)
                        hideDialog()
                        setUploadLocalDocument(false)
                        setLoadingSendFile(false)
                        if (activeWindows === 1) {
                            setActiveIndex1(0)
                        }
                        setInputSendImage("")
                    } else {
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao enviar documento!",
                            detail: sendMediaResponse.data.message,
                            life: 3000,
                        })
                    }
                } catch (error: any) {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao enviar documento!",
                        detail: error.response?.data?.message || "Erro desconhecido",
                        life: 3000,
                    })
                }
            } else {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao enviar documento!",
                    detail: "Por favor, verificar os dados preenchidos!",
                    life: 3000,
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoadingSendFile(false)
        }
    }

    const onSendLocalAudio = () => {
        setLoadingSendFile(true)
        let activeWidonws: number

        if (conversationSelected.user_id === null) {
            activeWidonws = 1
        }

        try {
            let auxAttachmentsRequisition = {
                type: "audio",
                name: "audio",
            }
            let header = {
                headers: {
                    route: "create_whatsapp_attachment",
                },
            }

            api.post("attachments/whatsapp", auxAttachmentsRequisition, header)
                .then(async (res) => {
                    if (res.status === 200) {
                        let id2 = res.data.id
                        let header = {
                            headers: {
                                route: "update_file_whatsapp_attachment",
                            },
                        }

                        api.patch(`attachments/file/whatsapp/${id2}`, generalFilePath, header)

                            .then((res) => {
                                if (res.status === 200) {
                                    let auxConfirPresentation
                                    let contextMessage

                                    if (!isEmptyObj(selectReplyMessageChat) && verification24 !== false) {
                                        contextMessage = selectReplyMessageChat.whatsapp_id
                                    } else {
                                        contextMessage = null
                                    }

                                    if (inputSendImage === "") {
                                        auxConfirPresentation = {
                                            to: conversationSelected.phone_number,
                                            attachment_id: res.data.id,
                                            text: null,
                                            context_message_id: contextMessage,
                                            whatsapp_conversation_id: conversationSelected.id,
                                            whatsapp_phone_number: user.whatsapp_phone_number,
                                        }
                                    } else if (inputSendImage !== "") {
                                        auxConfirPresentation = {
                                            to: conversationSelected.phone_number,
                                            attachment_id: res.data.id,
                                            text: inputSendImage,
                                            context_message_id: contextMessage,
                                            whatsapp_conversation_id: conversationSelected.id,
                                            whatsapp_phone_number: user.whatsapp_phone_number,
                                        }
                                    }

                                    let header = {
                                        headers: {
                                            route: "send_whatsapp_media_message",
                                        },
                                    }

                                    try {
                                        api.post(
                                            "whatsapp/send/media",

                                            auxConfirPresentation,
                                            header
                                        )
                                            .then((res) => {
                                                if (res.status === 200) {
                                                    setSelectReplyMessageChat({} as MessagesWhatsappConversation)
                                                    setImagePreviewDialog(false)

                                                    setVideoDialog(false)
                                                    setImageNewPreviewDialog(false)
                                                    setVideoNewPreviewDialog(false)
                                                    hideDialog()
                                                    setUploadLocalDocument(false)
                                                    setLoadingSendFile(false)
                                                    if (activeWidonws === 1) {
                                                        setActiveIndex1(0)
                                                    }
                                                    setInputSendImage("")
                                                } else {
                                                    setLoadingSendFile(false)
                                                    toast.current?.show({
                                                        severity: "error",
                                                        summary: "Erro ao enviar documento!",
                                                        detail: res.data.message,
                                                        life: 3000,
                                                    })
                                                }
                                            })
                                            .catch((error) => {
                                                setLoadingSendFile(false)
                                                setLoadingSendFile(false)
                                                toast.current?.show({
                                                    severity: "error",
                                                    summary: "Erro ao enviar documento!",
                                                    detail: error.response.data.message,
                                                    life: 3000,
                                                })
                                            })
                                    } catch (error) {
                                        console.log(error)
                                    }
                                } else {
                                    toast.current?.show({
                                        severity: "error",
                                        summary: "Erro ao enviar documento!",
                                        detail: res.data.message,
                                        life: 3000,
                                    })
                                }
                            })

                            .catch((error) => {
                                setLoadingSendFile(false)
                                toast.current?.show({
                                    severity: "error",
                                    summary: "Erro ao enviar documento!",
                                    detail: error.response.data.message,
                                    life: 3000,
                                })
                            })

                        hideDialog()
                    } else {
                        setLoadingSendFile(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao enviar documento!",
                            detail: "Por favor, verificar os dados preenchidos!",
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao enviar documento!",
                        detail: "Por favor, verificar os dados preenchidos!",
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const onSendLocalLargeAudio = () => {
        setLoadingSendFile(true)
        let activeWidonws: number

        if (conversationSelected.user_id === null) {
            activeWidonws = 1
        }

        try {
            let auxAttachmentsRequisition = {
                type: "document",
                name: generalFilePathName,
                type_filter: "audio",
            }
            let header = {
                headers: {
                    route: "create_whatsapp_attachment",
                },
            }

            api.post("attachments/whatsapp", auxAttachmentsRequisition, header)
                .then(async (res) => {
                    if (res.status === 200) {
                        let id2 = res.data.id
                        let header = {
                            headers: {
                                route: "update_file_whatsapp_attachment",
                            },
                        }

                        api.patch(`attachments/file/whatsapp/${id2}`, generalFilePath, header)

                            .then((res) => {
                                if (res.status === 200) {
                                    let auxConfirPresentation
                                    let contextMessage

                                    if (!isEmptyObj(selectReplyMessageChat) && verification24 !== false) {
                                        contextMessage = selectReplyMessageChat.whatsapp_id
                                    } else {
                                        contextMessage = null
                                    }

                                    if (inputSendImage === "") {
                                        auxConfirPresentation = {
                                            to: conversationSelected.phone_number,
                                            attachment_id: res.data.id,
                                            text: null,
                                            context_message_id: contextMessage,
                                            whatsapp_conversation_id: conversationSelected.id,
                                            whatsapp_phone_number: user.whatsapp_phone_number,
                                        }
                                    } else if (inputSendImage !== "") {
                                        auxConfirPresentation = {
                                            to: conversationSelected.phone_number,
                                            attachment_id: res.data.id,
                                            text: inputSendImage,
                                            context_message_id: contextMessage,
                                            whatsapp_conversation_id: conversationSelected.id,
                                            whatsapp_phone_number: user.whatsapp_phone_number,
                                        }
                                    }

                                    let header = {
                                        headers: {
                                            route: "send_whatsapp_media_message",
                                        },
                                    }

                                    try {
                                        api.post(
                                            "whatsapp/send/media",

                                            auxConfirPresentation,
                                            header
                                        )
                                            .then((res) => {
                                                if (res.status === 200) {
                                                    setSelectReplyMessageChat({} as MessagesWhatsappConversation)
                                                    setImagePreviewDialog(false)

                                                    setVideoDialog(false)
                                                    setImageNewPreviewDialog(false)
                                                    setVideoNewPreviewDialog(false)
                                                    hideDialog()
                                                    setUploadLocalDocument(false)
                                                    setLoadingSendFile(false)
                                                    if (activeWidonws === 1) {
                                                        setActiveIndex1(0)
                                                    }
                                                    setInputSendImage("")
                                                } else {
                                                    setLoadingSendFile(false)
                                                    toast.current?.show({
                                                        severity: "error",
                                                        summary: "Erro ao enviar documento!",
                                                        detail: res.data.message,
                                                        life: 3000,
                                                    })
                                                }
                                            })
                                            .catch((error) => {
                                                setLoadingSendFile(false)
                                                setLoadingSendFile(false)
                                                toast.current?.show({
                                                    severity: "error",
                                                    summary: "Erro ao enviar documento!",
                                                    detail: error.response.data.message,
                                                    life: 3000,
                                                })
                                            })
                                    } catch (error) {
                                        console.log(error)
                                    }
                                } else {
                                    toast.current?.show({
                                        severity: "error",
                                        summary: "Erro ao enviar documento!",
                                        detail: res.data.message,
                                        life: 3000,
                                    })
                                }
                            })

                            .catch((error) => {
                                setLoadingSendFile(false)

                                toast.current?.show({
                                    severity: "error",
                                    summary: "Erro ao enviar documento!",
                                    detail: error.response.data.message,
                                    life: 3000,
                                })
                            })

                        hideDialog()
                    } else {
                        setLoadingSendFile(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao enviar documento!",
                            detail: "Por favor, verificar os dados preenchidos!",
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao enviar documento!",
                        detail: "Por favor, verificar os dados preenchidos!",
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const onSendLocalImage = () => {
        let over24 = dateLastMessage(conversationSelected)
        setLoadingSendFile(true)
        let activeWidonws: number

        if (conversationSelected.user_id === null) {
            activeWidonws = 1
        }
        try {
            let auxAttachmentsRequisition = {
                type: "image",
                name: generalFilePathName,
            }
            let header = {
                headers: {
                    route: "create_whatsapp_attachment",
                },
            }

            api.post("attachments/whatsapp", auxAttachmentsRequisition, header)
                .then(async (res) => {
                    if (res.status === 200) {
                        let id2 = res.data.id
                        let header = {
                            headers: {
                                route: "update_file_whatsapp_attachment",
                            },
                        }

                        api.patch(`attachments/file/whatsapp/${id2}`, generalFilePath, header)

                            .then((res) => {
                                if (res.status === 200) {
                                    setSelectReplyMessageChat({} as MessagesWhatsappConversation)
                                    let auxConfirPresentation

                                    let contextMessage

                                    if (!isEmptyObj(selectReplyMessageChat) && verification24 !== false) {
                                        contextMessage = selectReplyMessageChat.whatsapp_id
                                    } else {
                                        contextMessage = null
                                    }

                                    if (inputSendImage === "") {
                                        auxConfirPresentation = {
                                            to: conversationSelected.phone_number,
                                            attachment_id: res.data.id,
                                            text: null,
                                            context_message_id: contextMessage,
                                            over24: over24,
                                            whatsapp_conversation_id: conversationSelected.id,
                                            whatsapp_phone_number: user.whatsapp_phone_number,
                                        }
                                    } else if (inputSendImage !== "") {
                                        auxConfirPresentation = {
                                            to: conversationSelected.phone_number,
                                            attachment_id: res.data.id,
                                            text: inputSendImage,
                                            over24: over24,
                                            context_message_id: contextMessage,
                                            whatsapp_conversation_id: conversationSelected.id,
                                            whatsapp_phone_number: user.whatsapp_phone_number,
                                        }
                                    }

                                    let header = {
                                        headers: {
                                            route: "send_whatsapp_media_message",
                                        },
                                    }

                                    try {
                                        api.post(
                                            "whatsapp/send/media",

                                            auxConfirPresentation,
                                            header
                                        )
                                            .then((res) => {
                                                if (res.status === 200) {
                                                    setImagePreviewDialog(false)
                                                    setImageNewPreviewDialog(false)
                                                    setImagePreviewDialog(false)
                                                    hideDialog()
                                                    setLoadingSendFile(false)
                                                    setInputSendImage("")
                                                    const conversationExistInList = conversations.findIndex((conversation) => conversation.id === res.data)
                                                    if (conversationExistInList === -1) {
                                                        conversationSelected.id = res.data
                                                        setConversationSelected(conversationSelected)
                                                    } else {
                                                        conversationSelected = conversations[conversationExistInList]
                                                        setConversationSelected(conversationSelected)
                                                    }
                                                    if (activeWidonws === 1) {
                                                        setActiveIndex1(0)
                                                    }
                                                } else {
                                                    setLoadingSendFile(false)
                                                    toast.current?.show({
                                                        severity: "error",
                                                        summary: "Erro ao enviar imagem!",
                                                        detail: res.data.message,
                                                        life: 3000,
                                                    })
                                                }
                                            })
                                            .catch((error) => {
                                                setLoadingSendFile(false)
                                                setLoadingSendFile(false)
                                                toast.current?.show({
                                                    severity: "error",
                                                    summary: "Erro ao enviar imagem!",
                                                    detail: error.response.data.message,
                                                    life: 3000,
                                                })
                                            })
                                    } catch (error) {
                                        console.log(error)
                                    }
                                } else {
                                    toast.current?.show({
                                        severity: "error",
                                        summary: "Erro ao enviar imagem!",
                                        detail: res.data.message,
                                        life: 3000,
                                    })
                                }
                            })

                            .catch((error) => {
                                setLoadingSendFile(false)

                                toast.current?.show({
                                    severity: "error",
                                    summary: "Erro ao enviar imagem!",
                                    detail: error.response.data.message,
                                    life: 3000,
                                })
                            })

                        hideDialog()
                    } else {
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao enviar imagem!",
                            detail: "Por favor, verificar os dados preenchidos!",
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao enviar imagem!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const onSendLocalVideo = async () => {
        const finalText = inputSendImage.replace("<", "&lt;").replace(">", "&gt;")

        setLoadingSendFile(true)

        const activeWidonws = conversationSelected.user_id === null ? 1 : undefined

        try {
            const auxAttachmentsRequisition = {
                type: "video",
                name: generalFilePathName,
            }

            const createAttachmentsHeader = {
                headers: {
                    route: "create_whatsapp_attachment",
                },
            }

            const createAttachmentsResponse = await api.post("attachments/whatsapp", auxAttachmentsRequisition, createAttachmentsHeader)

            if (createAttachmentsResponse.status === 200) {
                const attachmentId = createAttachmentsResponse.data.id

                const updateAttachmentHeader = {
                    headers: {
                        route: "update_file_whatsapp_attachment",
                    },
                }

                await api.patch(`attachments/file/whatsapp/${attachmentId}`, generalFilePath, updateAttachmentHeader)

                const contextMessage = !isEmptyObj(selectReplyMessageChat) && verification24 !== false ? selectReplyMessageChat.whatsapp_id : null

                const auxConfirPresentation = {
                    to: conversationSelected.phone_number,
                    attachment_id: attachmentId,
                    text: inputSendImage ? finalText : null,
                    over24: verification24,
                    context_message_id: contextMessage,
                    whatsapp_conversation_id: conversationSelected.id,
                    whatsapp_phone_number: user.whatsapp_phone_number,
                }

                const sendMediaHeader = {
                    headers: {
                        route: "send_whatsapp_media_message",
                    },
                }

                try {
                    const sendMediaResponse = await api.post("whatsapp/send/media", auxConfirPresentation, sendMediaHeader)

                    if (sendMediaResponse.status === 200) {
                        setSelectReplyMessageChat({} as MessagesWhatsappConversation)
                        setImagePreviewDialog(false)
                        setVideoDialog(false)
                        setImageNewPreviewDialog(false)
                        setVideoNewPreviewDialog(false)
                        hideDialog()
                        setLoadingSendFile(false)
                        setInputSendImage("")
                        if (activeWidonws === 1) {
                            setActiveIndex1(0)
                        }
                    } else {
                        setLoadingSendFile(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao enviar vídeo!",
                            detail: sendMediaResponse.data.message,
                            life: 3000,
                        })
                    }
                } catch (error: any) {
                    setLoadingSendFile(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao enviar vídeo!",
                        detail: error.response?.data?.message || "Erro desconhecido",
                        life: 3000,
                    })
                }
            } else {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao enviar vídeo!",
                    detail: "Por favor, verificar os dados preenchidos!",
                    life: 3000,
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoadingSendFile(false)
        }
    }

    const handleCreateContactOpportunity = () => {
        let auxFieldEmail: any = []
        let auxFieldPhones: any = []

        newEmailField.forEach((auxNewEmailField) => {
            if (auxNewEmailField.emails !== "") {
                auxFieldEmail.push({
                    email: auxNewEmailField.emails,
                })
            }
        })

        newPhoneField.forEach((auxNewContactField) => {
            if (auxNewContactField.telephone !== "") {
                auxFieldPhones.push({
                    number:
                        auxNewContactField.ddi.ddi.replaceAll("+", "") +
                        "-" +
                        auxNewContactField.telephone.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replaceAll("+", ""),
                })
            }
        })

        let auxCreateContact: ContactInterface = {
            name: formContact.name,
            job: formContact.job,
            company_id: formContact.company !== undefined ? formContact.company.id : "",
            authorization: "LI",
            authorization_status: true,
            phones: auxFieldPhones,
            emails: auxFieldEmail,
            company: formContact.company,
        }

        return auxCreateContact
    }

    const onUpdateContactPerson = async (rowData: any) => {
        try {
            const header = {
                headers: {
                    route: "update_whatsapp_conversation_contact",
                },
            }

            const auxSendContact = {
                contact_id: rowData[0],
                whatsapp_conversation_id: rowData[1],
            }

            const response = await api.put("whatsapp_conversation/contact", auxSendContact, header)

            if (response.status === 200) {
                toast.current?.show({
                    severity: "success",
                    summary: "Sucesso!",
                    detail: "O contato foi atualizado com sucesso",
                    life: 3000,
                })
            } else {
                throw new Error("Erro ao atualizar Contato")
            }
        } catch (error) {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao alterar Contato!",
                life: 3000,
            })
        }
    }

    const handleEditCompany = (event: any) => {
        event.preventDefault()
        setLoadingButtonUpdateOpportunity(true)

        if (!isEmptyObj(requestApiCompany)) {
            let auxSaveCompanyData = {
                interests: saveCompanyData.interests,
                id: conversationSelected?.contact.company.id,
                company: requestApiCompany,
            }
            setLoadingButtonUpdateOpportunity(false)
            handleProcessUpdateCompany(auxSaveCompanyData)
        } else if (!isEmptyObj(existingCompany)) {
            updateCompanyOpportunity.id = existingCompany.id

            let auxSaveCompanyData = {
                interests: existingCompany.interests,
                id: existingCompany.id,
                company: {
                    razao_social: existingCompany.name,
                    estabelecimento: {
                        cnpj: existingCompany.cnpj !== null ? existingCompany.cnpj.replaceAll("-", "").replaceAll("/", "").replaceAll(".", "") : null,
                    },
                },
            }
            setLoadingButtonUpdateOpportunity(false)
            handleProcessUpdateCompany(auxSaveCompanyData)

            if (existingCompany.cnpj !== conversationSelected?.contact.company.cnpj) {
                handleUpdateCompanyOpportunity(event)
            }
        } else if (!isEmptyObj(saveCompanyData)) {
            if (conversationSelected?.contact.company.cnpj === null) {
                if (saveCompanyData.name !== conversationSelected?.contact.company.name) {
                    let auxSaveCompanyData = {
                        interests: saveCompanyData.interests,
                        id: conversationSelected?.contact.company.id,
                        company: {
                            razao_social: saveCompanyData.name,
                            estabelecimento: {
                                cnpj: null,
                            },
                        },
                    }
                    setLoadingButtonUpdateOpportunity(false)
                    handleProcessUpdateCompany(auxSaveCompanyData)
                } else if (
                    saveCompanyData.name === conversationSelected?.contact.company.name &&
                    saveCompanyData.interests.length !== conversationSelected?.contact.company.interests.length
                ) {
                    let auxSaveCompanyData = {
                        interests: saveCompanyData.interests,
                        id: conversationSelected?.contact.company.id,
                        company: {
                            razao_social: saveCompanyData.name,
                            estabelecimento: {
                                cnpj: null,
                            },
                        },
                    }
                    setLoadingButtonUpdateOpportunity(false)
                    handleProcessUpdateCompany(auxSaveCompanyData)
                    allConversations()
                } else {
                    setLoadingButtonUpdateOpportunity(false)
                    toast.current?.show({
                        severity: "warn",
                        summary: "OPS!",
                        detail: "Nenhuma alteração foi realizada.",
                        life: 3000,
                    })
                }
            } else {
                let auxSaveCompanyData = {
                    interests: saveCompanyData.interests,
                    id: conversationSelected?.contact.company.id,
                    company: {
                        estabelecimento: {
                            cnpj: null,
                        },
                    },
                }
                handleProcessUpdateCompany(auxSaveCompanyData)
            }
        }
    }

    const handleUpdateCompanyOpportunity = (event: any) => {
        event.preventDefault()
        setLoadingButtonUpdateOpportunity(true)

        if (!isEmptyObj(updateCompanyOpportunity)) {
            try {
                let header = {
                    headers: {
                        route: "update_opportunity_company",
                    },
                }

                let auxUpdateCompanyOpportunity = {
                    id: conversationSelected?.contact.company.id,
                    company_id: updateCompanyOpportunity.id,
                }

                api.put("opportunities/company", auxUpdateCompanyOpportunity, header)
                    .then((res) => {
                        setLoadingButtonUpdateOpportunity(false)
                        if (res.status === 200) {
                            allConversations()
                            setLoadingButtonUpdateOpportunity(false)
                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso!",
                                detail: "A empresa foi atualizada!",
                                life: 3000,
                            })
                        } else {
                            setLoadingButtonUpdateOpportunity(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao Fzaer o Update",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingButtonUpdateOpportunity(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Fazer o Update !",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            } catch (error) {
                setLoadingButtonUpdateOpportunity(false)
                console.log(error)
            }
            hideDialog()
        } else {
            setLoadingButtonUpdateOpportunity(false)
            toast.current?.show({
                severity: "error",
                summary: "Erro na atualização",
                detail: "Selecione uma empresa para atualizar!!",
                life: 3000,
            })
            hideDialog()
        }
        hideDialog()
    }

    const handleProcessUpdateCompany = (auxSaveCompanyData: any) => {
        let header = {
            headers: {
                route: "update_company",
            },
        }
        setLoadingButtonUpdateOpportunity(true)
        api.put("companies", auxSaveCompanyData, header)
            .then((res) => {
                if (res.status === 200) {
                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso!",
                        detail: "Sucesso na alteração da empresa",
                        life: 3000,
                    })
                    hideDialog()
                    setLoadingButtonUpdateOpportunity(false)
                } else {
                    hideDialog()
                    setLoadingButtonUpdateOpportunity(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao editar empresa!",
                        detail: res.data.message,
                        life: 3000,
                    })
                }
            })
            .catch((error) => {
                hideDialog()
                setLoadingButtonUpdateOpportunity(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao editar empresa!",
                    detail: error.response.data.message,
                    life: 3000,
                })
            })
    }

    const handleCreateContact = async (event: any) => {
        event.preventDefault()

        let auxCreateContact: ContactInterface = handleCreateContactOpportunity()
        formContact = auxCreateContact

        if (!(await validateCreateContact())) {
            toast.current?.show({
                severity: "error",
                summary: "Dados Inválidos!",
                detail: "Preencha todos os dados obrigatórios!",
                life: 6000,
            })
        } else {
            if (auxCreateContact.job === undefined) {
                delete auxCreateContact.job
            }

            auxCreateContact.phones = isEmptyObj(auxCreateContact.phones) ? [] : auxCreateContact.phones
            auxCreateContact.emails = isEmptyObj(auxCreateContact.emails) ? [] : auxCreateContact.emails
            delete auxCreateContact.company

            let header = {
                headers: {
                    route: "create_contact",
                },
            }

            try {
                const res = await api.post("contacts", auxCreateContact, header)
                if (res.status === 200) {
                    hideDialog()
                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Contato cadastrado com sucesso!",
                        life: 3000,
                    })
                } else {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao cadastrar o contato!",
                        detail: res.data.message,
                        life: 3000,
                    })
                }
            } catch (error: any) {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao cadastrar o contato!",
                    detail: error.response?.data?.message || "Erro desconhecido",
                    life: 3000,
                })
            }
        }
    }

    const handleRequestApiCompany = (item: IEmpresaInterface) => {
        if (!isEmptyObj(item) && item.simples !== null) {
            setRequestApiCompany({ ...requestApiCompany, [`razao_social`]: item.estabelecimento.nome_fantasia })
        }

        setRequestApiCompany(item)
    }

    const handleFilterDateConversation = async (event: any) => {
        event.preventDefault()
        setLoadingButtonFilterDate(true)

        if (valueFilterDate1 !== null && valueFilterDate2 !== null) {
            const auxValueFilterDate1 = new Date(valueFilterDate1)
            const auxValueFilterDate2 = new Date(valueFilterDate2)

            try {
                const header = {
                    headers: {
                        route: "list_whatsapp_conversations_all",
                    },
                }

                const res = await api.get("whatsapp_conversation/all", header)

                if (res.status === 200) {
                    let result: any = []

                    for (let i = 0; i < res.data.length; i++) {
                        const item = res.data[i]
                        for (let j = 0; j < item.messages.length; j++) {
                            const message = item.messages[j]
                            const messageCreatedAt = new Date(message.created_at)
                            if (messageCreatedAt >= auxValueFilterDate1 && messageCreatedAt <= auxValueFilterDate2) {
                                let auxItem = item.messages[0]
                                item.messages = []
                                item.messages.push(auxItem)
                                result.push(item)
                                break // Breaks out of the inner loop
                            }
                        }
                    }

                    conversations = []
                    conversations = result

                    setAuxConversations(conversations)
                    setConversations(conversations)
                    setConversationsFilter(conversations)

                    setTimeout(() => {
                        setMyConversationsTabView((myConversationsTabView) => ({
                            ...myConversationsTabView,
                            conversations: conversations.filter((conversation) => conversation.user !== null && conversation.user.id === profileUser.id),
                        }))
                    }, 100)

                    setTimeout(() => {
                        setSkeletonLoading(false)
                    }, 20)

                    tabFilterSetFunction()
                    allUseEffect()
                    useParamEffect()

                    console.log(result)

                    setValueFilterDate1(null)
                    setValueFilterDate2(null)
                    setLoadingButtonFilterDate(false)
                    hideDialog()
                }
            } catch (error) {
                console.log(error)
            }
        } else {
            toast.current?.show({
                severity: "warn",
                summary: "Datas não foram selecionadas",
                detail: "Selecione as datas para continuar.",
                life: 3000,
            })
        }
    }

    ///OnChange Functions
    const onChangeResponsible = (user: UserInterface | null, message: WhatsappConversationInterface) => {
        let auxConfirPresentation
        setLoadingChangeResponsible(true)
        setLoadingRemoveResponsible(true)
        try {
            let header = {
                headers: {
                    route: "update_whatsapp_conversation",
                },
            }
            if (user === null) {
                auxConfirPresentation = {
                    id: message.id,
                    user_id: null,
                }
            } else {
                auxConfirPresentation = {
                    id: message.id,
                    user_id: user.id,
                }
            }

            api.put("whatsapp_conversation", auxConfirPresentation, header)

                .then((res) => {
                    if (res.status === 200) {
                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na alteração",
                            detail: "Responsável definido com sucesso",
                            life: 3000,
                        })
                        setChangeResponsible(false)
                        setRemoveResponsible(false)
                        setLoadingChangeResponsible(false)
                        setLoadingRemoveResponsible(false)
                    } else {
                        setLoadingChangeResponsible(false)
                        setChangeResponsible(false)
                        setLoadingRemoveResponsible(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Editar Responsável",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setChangeResponsible(false)
                    setLoadingRemoveResponsible(false)
                    setLoadingChangeResponsible(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao Editar Responsável",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            setChangeResponsible(false)
            setLoadingRemoveResponsible(false)
            setLoadingChangeResponsible(false)
            console.log(error)
        }
    }

    const onChangeContact = () => {
        auxContacts = []
        setAuxContacts(auxContacts)

        if (auxContacts[1]) {
            setChangeResponsibleDialog(true)
        } else {
            toast.current?.show({
                severity: "info",
                summary: "Este número tem apenas um contato vinculado!",
                life: 3000,
            })
        }
        setAuxContacts(auxContacts)
    }

    //Messages functions
    const countOfUnviewedMessages = (contactInformations: WhatsappConversationInterface) => {
        const lastMessage = contactInformations.messages[contactInformations.messages?.length - 1]

        if (contactInformations.id === conversationSelected.id && activeIndex1 !== 2) {
            return null
        }

        const newMessages = contactInformations.messages.reduce((count, message) => {
            return count + (message.new ? 1 : 0)
        }, 0)

        if (newMessages !== 0) {
            return (
                <div
                    className="numberMessagesCircle p-d-flex p-ai-center p-jc-center"
                    style={{ width: "1.5rem", height: "1.5rem", backgroundColor: "green", borderRadius: "50%" }}
                >
                    <div className="p-d-flex p-jc-end" style={{ color: "white", fontSize: "0.85rem", userSelect: "none" }}>
                        {newMessages}
                    </div>
                </div>
            )
        } else if (
            lastMessage?.status === "received" &&
            ["document", "audio", "text"].includes(lastMessage.type) &&
            (!final_words.some((word) => lastMessage?.text?.toLowerCase().includes(word)) ||
                messagesContains.some((word) => lastMessage?.text?.toLowerCase().includes(word)))
        ) {
            return (
                <div
                    className="numberMessagesCircle p-d-flex p-ai-center p-jc-center"
                    style={{ width: "1.5rem", height: "1.5rem", backgroundColor: "orange", borderRadius: "50%" }}
                />
            )
        }

        return null
    }

    const dateLastMessage = (contactInformations: WhatsappConversationInterface) => {
        let messages: MessagesWhatsappConversation[] = []
        let haveMessageCostumer = false
        let auxFilter

        if (contactInformations !== undefined) {
            contactInformations.messages.forEach((message: MessagesWhatsappConversation) => {
                if (message.status === "received") {
                    messages.push(message)
                }
            })
        }

        let length = messages.length
        let lastMessage = length > 0 ? messages[length - 1] : messages[length]

        if (messages) {
            messages.map((message) => {
                if (message.status === "received") {
                    haveMessageCostumer = true
                }
            })

            if (haveMessageCostumer === false) {
                verification24 = true
                auxFilter = verification24
            }
        }

        if (lastMessage !== undefined && haveMessageCostumer) {
            let toTimeZone = new Date(lastMessage.created_at)
            let hours = moment().diff(moment(toTimeZone), "hours", true)
            if (hours >= 24) {
                verification24 = true
                auxFilter = verification24
                setVerification24(verification24)
            } else {
                verification24 = false
                auxFilter = verification24
                setVerification24(verification24)
            }
        }
        return auxFilter
    }

    //DETAIL MESSAGE CHAT
    const viewDetailMessageSelected = (contactInformations: MessagesWhatsappConversation) => {
        switch (contactInformations.type) {
            case "document":
                return "Documento"

            case "image":
                return (
                    <div>
                        <Image src={!isEmptyObj(contactInformations.attachment) ? contactInformations.attachment.file_url : ""} width="200" alt="Imagem" />
                    </div>
                )

            case "sticker":
                return <Image src={!isEmptyObj(contactInformations.attachment) ? contactInformations.attachment.file_url : ""} width="200" alt="Imagem" />

            case "video":
                return (
                    <div>
                        <ReactPlayer
                            autoPlay
                            url={contactInformations.attachment !== null ? contactInformations.attachment.file_url : ""}
                            width="90%"
                            height="90%"
                            controls={true}
                        />
                    </div>
                )

            case "audio":
                return (
                    <div>
                        <audio
                            src={contactInformations.attachment !== null ? contactInformations.attachment.file_url : ""}
                            className="audio-1"
                            controls
                            playsInline={true}
                            style={{
                                width: "15rem",
                                height: "2.3rem",
                                resize: "none",
                                outline: "none",
                                borderRadius: "5px",
                                textDecoration: "bold",
                                fontSize: "1rem",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                            }}
                        />
                    </div>
                )

            case "text":
                return contactInformations.text

            case "locate":
                return (
                    <div className="dowloadFileDocument">
                        <iframe
                            title="map"
                            src={`https://maps.google.com/maps?q=${contactInformations.locate_lat},${contactInformations.locate_long}&t=&z=${zoom}&ie=UTF8&iwloc=&output=embed`}
                            frameBorder="0"
                            style={{ border: "0", width: "100%", height: "100%" }}
                        />
                    </div>
                )
        }
    }

    // contactsChat
    const chatsInProgress = () => {
        const contactList: WhatsappConversationInterface[] = []

        let arrayListMessages = conversations

        arrayListMessages
            .slice()
            .reverse()
            .map((listContacts, index) => {
                const findIdContactMessage = contactList.find((contact) => contact.phone_number === listContacts.phone_number)
                if (findIdContactMessage === undefined) {
                    contactList.push(listContacts)
                }
            })

        contactsChat = contactList
        setContactsChat(contactsChat)
    }

    //Scroll of the messages filter
    const upScrollSearch = () => setCountIndexSearchMessageFilter(countIndexSearchMessageFilter + 1)
    const downScrollSearch = () => setCountIndexSearchMessageFilter(countIndexSearchMessageFilter - 1)

    const chatMessageFilter = (textSearch: string, selectedCompany: any) => {
        let idList: string[] = []
        let indexValue: number[] = []

        conversationSelected.messages.forEach((whatsappMessage, index) => {
            var word = textSearch.toLowerCase().split(" ")
            for (var oneWord in word) {
                oneWord = oneWord.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                if (whatsappMessage.text !== null && whatsappMessage.text.toLowerCase().includes(textSearch.toLowerCase())) {
                    indexValue.push(index)
                    idList.push(whatsappMessage.id)
                }
            }
        })
        let auxLength = conversationSelected.messages.length
        let subValue = auxLength - lengthOfMessagesConversationSelected
        let lengthUpdate = auxLength - indexValue[0]

        // Load messages to the last message found
        if (lengthUpdate > subValue) {
            lengthOfMessagesConversationSelected = indexValue[0]
            setLengthOfMessagesConversationSelected(lengthOfMessagesConversationSelected)
            setHasMoreMessagesConversationSelected(true)

            if (lengthOfMessagesConversationSelected === 0) {
                setHasMoreMessagesConversationSelected(false)
            }
        }

        if (idList.length <= 0) {
            toast.current?.show({
                severity: "error",
                summary: "Nenhuma mensagem encontrada!",
                detail: "",
                life: 1000,
            })
        } else if (idList.length >= 0) {
            setChatMessagesSearch(idList)
            setViewButtonsSearchMessageUpOrDown(true)
            setCountIndexSearchMessageFilter(idList.length - 1)
        }
    }

    const dividerDataInChatMessages = (contactMessageWhatsapp: MessagesWhatsappConversation, previousMessage: MessagesWhatsappConversation, index: number) => {
        const element = document.getElementById(`${contactMessageWhatsapp.id}`)

        if (!isEmptyObj(previousMessage)) {
            if (
                moment(contactMessageWhatsapp.created_at).format("DD/MM/YYYY") !==
                    moment(conversationSelected.messages[index].created_at).format("DD/MM/YYYY") &&
                element?.style.display !== "none"
            ) {
                return (
                    <>
                        <div style={{ marginBottom: "-1rem", marginTop: "-0.5rem" }}>
                            <br />
                            <div
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    backgroundColor: "rgba(228, 229, 231, 0.3)",
                                    borderRadius: "100rem",
                                    fontWeight: "500",
                                }}
                                className="p-d-flex p-jc-center"
                            >
                                <Moment
                                    style={{
                                        userSelect: "none",
                                        color: "rgba(141, 142, 143, 0.7)",
                                    }}
                                    format="DD/MM/YYYY"
                                >
                                    {contactMessageWhatsapp.created_at}
                                </Moment>
                            </div>
                            <br />
                        </div>
                    </>
                )
            }
        }
    }

    const valueIndexMessageDate = (index: number) => {
        indexSended = index
    }

    const archiveTypeForDocuments = (contactMessageWhatsapp: MessagesWhatsappConversation) => {
        let splitArchive = contactMessageWhatsapp.attachment.file.split(".")
        let lengthSplit = splitArchive.length
        if (splitArchive[lengthSplit - 1] === "pdf") {
            return (
                <iframe
                    src={contactMessageWhatsapp.attachment !== null ? contactMessageWhatsapp.attachment.file_url : ""}
                    frameBorder="0"
                    seamless={false}
                    title="document"
                    scrolling="no"
                    width="100%"
                    style={{ border: "none", overflow: "hidden" }}
                    height="98%"
                />
            )
        } else {
            return (
                <div>
                    <br />
                    <div className="p-d-flex p-jc-center">
                        <i className="pi pi-file" style={{ fontSize: "3em", color: "#54656f" }}></i>
                    </div>
                    <br />
                    <div className="p-d-flex p-jc-center" style={{ color: "#54656f" }}>
                        {contactMessageWhatsapp.attachment.name}
                    </div>
                </div>
            )
        }
    }

    const repliedMessageMarker = (contactMessageWhatsapp: MessagesWhatsappConversation) => {
        if (!isEmptyObj(selectReplyMessageChat)) {
            if (contactMessageWhatsapp.whatsapp_id === selectReplyMessageChat.whatsapp_id) {
                return "rgba(141, 142, 143, 0.1)"
            }
        }

        if (!isEmptyObj(idReplyMessage)) {
            if (contactMessageWhatsapp.id === idReplyMessage) {
                return "rgba(141, 142, 143, 0.1)"
            }
        }
        return ""
    }

    const sendMessageAgainFunction = (messageFailed: MessagesWhatsappConversation) => {
        const messageType = messageFailed.type

        messageType === "text" ? onSendMessagetoContact("sendMessageAgain") : onUploadMediaFile("sendMessageAgain")
    }

    const replyMessage = (replyMessage: MessagesWhatsappConversation) => {
        conversationSelected.messages.forEach((message) => {
            if (replyMessage.context_message_id === message.whatsapp_id) {
                replyMessageUse = message
            }
        })
        if (!isEmptyObj(replyMessageUse)) {
            return (
                <div>
                    <a
                        href={widthWindow > 420 ? `#${replyMessageUse.id}` : `#${replyMessageUse.id}mobile`}
                        onClick={() => {
                            setIdReplyMessage(replyMessageUse.id)
                            setTimeout(() => {
                                let auxReply = {} as MessagesWhatsappConversation
                                replyMessageUse = auxReply
                                setReplyMessageUse(auxReply)
                                setIdReplyMessage(replyMessageUse.id)
                            }, 2500)
                        }}
                    >
                        <div>
                            {replyMessageUse.type === "text" ? (
                                <div
                                    className="p-d-flex p-jc-center"
                                    style={{
                                        width: `${widthWindow <= 414 ? "20rem" : "auto"}`,
                                        maxWidth: `${widthWindow <= 414 ? "18rem" : "50rem"}`,
                                    }}
                                >
                                    {replyMessageUse.text}
                                </div>
                            ) : (
                                <></>
                            )}

                            {replyMessageUse.type === "locate" ? (
                                <div className="dowloadFileDocument">
                                    <iframe
                                        title="map"
                                        src={`https://maps.google.com/maps?q=${replyMessageUse.locate_lat},${replyMessageUse.locate_long}&t=&z=${zoom}&ie=UTF8&iwloc=&output=embed`}
                                        frameBorder="0"
                                        style={{ border: "0", width: "100%", height: "100%" }}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}

                            {replyMessageUse.type === "image" ? (
                                <div
                                    className="p-d-flex p-jc-center"
                                    style={{
                                        width: `${widthWindow <= 414 ? "20rem" : "auto"}`,
                                        maxWidth: `${widthWindow <= 414 ? "20rem" : "20rem"}`,
                                    }}
                                >
                                    <Image
                                        src={!isEmptyObj(replyMessageUse.attachment) ? replyMessageUse.attachment.file_url : ""}
                                        width="100"
                                        height="auto"
                                        alt="Imagem"
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                            {replyMessageUse.type === "document" ? (
                                <div
                                    className="p-d-flex p-jc-center"
                                    style={{
                                        width: `${widthWindow <= 414 ? "20rem" : "auto"}`,
                                        maxWidth: `${widthWindow <= 414 ? "20rem" : "20rem"}`,
                                    }}
                                >
                                    <div style={{ width: "7rem" }}>{archiveTypeForDocuments(replyMessageUse)}</div>
                                </div>
                            ) : (
                                <></>
                            )}
                            {replyMessageUse.type === "audio" ? (
                                <div style={{ width: `${widthWindow <= 414 ? "15.5rem" : "20rem"}` }}>
                                    <audio
                                        src={replyMessageUse.attachment !== null ? replyMessageUse.attachment.file_url : ""}
                                        className="audio-1"
                                        controls
                                        playsInline={true}
                                        style={{
                                            width: "100%",
                                            height: "2.3rem",
                                            resize: "none",
                                            outline: "none",
                                            borderRadius: "5px",
                                            textDecoration: "bold",
                                            fontSize: "1rem",
                                        }}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                            {replyMessageUse.type === "video" ? (
                                <div
                                    className="p-d-flex p-jc-center"
                                    style={{
                                        width: `${widthWindow <= 414 ? "20rem" : "auto"}`,
                                        maxWidth: `${widthWindow <= 414 ? "20rem" : "20rem"}`,
                                    }}
                                >
                                    <ReactPlayer
                                        autoPlay
                                        url={replyMessageUse.attachment !== null ? replyMessageUse.attachment.file_url : ""}
                                        width="90%"
                                        height="90%"
                                        controls={true}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                            {replyMessageUse.type === "sticker" ? (
                                <div
                                    className="p-d-flex p-jc-center"
                                    style={{
                                        width: `${widthWindow <= 414 ? "20rem" : "auto"}`,
                                        maxWidth: `${widthWindow <= 414 ? "20rem" : "20rem"}`,
                                    }}
                                >
                                    <Image
                                        src={!isEmptyObj(replyMessageUse.attachment) ? replyMessageUse.attachment.file_url : ""}
                                        width="100"
                                        height="auto"
                                        alt="Imagem"
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>

                        <div className="p-d-flex p-jc-center p-as-end" style={{ marginTop: "0.2rem" }}>
                            <a
                                onClick={() => {
                                    if (replyMessageUse.status !== "failed") {
                                        setInformationDetailMessageChat(replyMessageUse)
                                    }
                                }}
                                className="p-d-flex p-as-end"
                                style={{ cursor: "pointer" }}
                            ></a>
                        </div>
                    </a>
                </div>
            )
        }
    }

    //TYPES OF MESSAGE FOR DESKTOP
    const typeOfMessageSent = (
        contactMessageWhatsapp: MessagesWhatsappConversation,
        index: number,
        classNameForAnimation: string,
        colorBackground: string,
        textForJSX: string
    ) => {
        const formatText = (text: string) => {
            // Format *Bold*
            text = text.replace(/\*([^*]+)\*/g, "<strong>$1</strong>")

            // Format _Italic_
            text = text.replace(/_([^_]+)_/g, "<em>$1</em>")

            // Format ~Strikethrough~
            text = text.replace(/~([^~]+)~/g, "<del>$1</del>")

            // Format ```Monospace```
            text = text.replace(/```([^`]+)```/g, "<code>$1</code>")

            // Format _*Bold Italic*_
            text = text.replace(/_\*\s*([^*_]+)\s*\*_/g, "<em><strong>$1</strong></em>")

            // Format *_~Strikethrough Bold~_*
            text = text.replace(/\*\*_~([^~]+)~_\*\*/g, "<strong><em><del>$1</del></em></strong>")

            return text
        }

        const formattedText = formatText(textForJSX)

        return (
            <div
                ref={myRefCardMessage}
                id={contactMessageWhatsapp.id}
                key={contactMessageWhatsapp.id}
                style={{
                    marginRight: "1.5rem",
                    padding: "0.8rem 0rem 0.8rem 0rem",
                    backgroundColor: `${repliedMessageMarker(contactMessageWhatsapp)}`,
                    // display: `${index < lengthOfMessagesConversationSelected ? "none" : "block"}`,
                }}
                onMouseOver={() => setIdMessageForOnMouseOver(contactMessageWhatsapp.whatsapp_id)}
            >
                <div className={`${classNameForAnimation}`} style={{ marginTop: "1rem" }}>
                    <div className="p-grid p-d-flex p-jc-end">
                        <div className="" key="1">
                            <div className="p-d-flex">
                                <div className="p-d-flex p-jc-end">
                                    {widthWindow <= 454 ? (
                                        <>
                                            {/* <a
                                                onClick={() => {
                                                    setSelectReplyMessageChat(contactMessageWhatsapp)
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <i className="pi pi-arrow-circle-down" style={{ opacity: "0.4", margin: "0.3rem" }} />
                                            </a> */}
                                        </>
                                    ) : (
                                        <div>
                                            {idMessageForOnMouseOver === contactMessageWhatsapp.whatsapp_id ? (
                                                <div style={{ margin: "0.3rem" }}>
                                                    {/* <a
                                                        onClick={() => {
                                                            setSelectReplyMessageChat(contactMessageWhatsapp)
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <i className="iconReplyMessage pi pi-arrow-circle-down" />
                                                    </a> */}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    )}

                                    <div
                                        className={`${contactMessageWhatsapp.type !== "sticker" ? "bubbleMessageCardSent" : ""}`}
                                        style={{
                                            backgroundColor: `${contactMessageWhatsapp.type === "sticker" ? "" : "#D9FDD3"}`,
                                        }}
                                    >
                                        {!isEmptyObj(contactMessageWhatsapp.context_message_id) ? (
                                            <div
                                                className="p-d-flex p-jc-center"
                                                style={{
                                                    padding: "0.5rem",
                                                    margin: "0.5rem",
                                                    backgroundColor: "rgba(100, 105, 98, 0.2)",
                                                    borderRadius: "0.5rem",
                                                    maxWidth: `${widthWindow <= 414 ? "15rem" : "45rem"}`,
                                                }}
                                            >
                                                {replyMessage(contactMessageWhatsapp)}
                                            </div>
                                        ) : (
                                            <></>
                                        )}

                                        <div className="p-d-flex p-jc-end p-ai-center">
                                            <div style={{ padding: "0.2rem", maxWidth: `${widthWindow <= 414 ? "15rem" : "45rem"}` }}>
                                                {textForJSX.includes("https://") || textForJSX.includes("http://") ? (
                                                    <div style={{ maxWidth: `${widthWindow <= 414 ? "15rem" : "44rem"}` }}>
                                                        <iframe
                                                            src={textForJSX}
                                                            frameBorder="0"
                                                            seamless={false}
                                                            title="document"
                                                            width="100%"
                                                            style={{ border: "none", borderRadius: "0.8rem" }}
                                                        />
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {contactMessageWhatsapp.attachment && (
                                                    <div>
                                                        {contactMessageWhatsapp.attachment.type_filter === "image" && (
                                                            <div>
                                                                <a
                                                                    onClick={() => {
                                                                        setViewImageMessage(true)
                                                                        setSelectedViewImageMessage(contactMessageWhatsapp)
                                                                    }}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <Image
                                                                        className="imageSend"
                                                                        src={
                                                                            !isEmptyObj(contactMessageWhatsapp.attachment)
                                                                                ? contactMessageWhatsapp.attachment.file_url
                                                                                : ""
                                                                        }
                                                                        width={`${widthWindow <= 414 ? "200" : "300"}`}
                                                                        height="auto"
                                                                        alt="Imagem"
                                                                    />
                                                                    <div
                                                                        style={{
                                                                            color: "#545B67",
                                                                        }}
                                                                    >
                                                                        {contactMessageWhatsapp.text !== null ? contactMessageWhatsapp.text : ""}
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        )}

                                                        {contactMessageWhatsapp.attachment.type_filter === "document" && (
                                                            <div style={{ padding: "0.2rem" }}>
                                                                <div style={{ textAlign: "center" }}>
                                                                    <div>{archiveTypeForDocuments(contactMessageWhatsapp)}</div>
                                                                </div>
                                                                <br />
                                                                <div className="p-d-flex p-jc-center">
                                                                    <a
                                                                        href={
                                                                            contactMessageWhatsapp.attachment !== null
                                                                                ? contactMessageWhatsapp.attachment.file_url
                                                                                : ""
                                                                        }
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                    >
                                                                        <div className="dowloadFileDocument">
                                                                            <strong>DOWNLOAD</strong>
                                                                            &nbsp;&nbsp;
                                                                            <i className="pi pi-external-link"></i>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {contactMessageWhatsapp.attachment.type_filter === "audio" && (
                                                            <div
                                                                className="Container"
                                                                style={{
                                                                    maxWidth: `${widthWindow <= 414 ? "20rem" : "35rem"}`,
                                                                    width: "25rem",
                                                                    marginBottom: "0.3rem",
                                                                    marginTop: "0.3rem",
                                                                    wordBreak: "normal",
                                                                }}
                                                            >
                                                                <audio
                                                                    src={
                                                                        contactMessageWhatsapp.attachment !== null
                                                                            ? contactMessageWhatsapp.attachment.file_url
                                                                            : ""
                                                                    }
                                                                    className="audio-1"
                                                                    controls
                                                                    playsInline={true}
                                                                    style={{
                                                                        width: "90%",
                                                                        height: "2.3rem",
                                                                        resize: "none",
                                                                        outline: "none",
                                                                        borderRadius: "5px",
                                                                        textDecoration: "bold",
                                                                        fontSize: "1rem",
                                                                        marginLeft: "1rem",
                                                                        marginRight: "1rem",
                                                                    }}
                                                                />
                                                            </div>
                                                        )}

                                                        {contactMessageWhatsapp.attachment.type_filter === "video" && (
                                                            <div className="p-d-flex p-jc-end p-ai-center">
                                                                <div
                                                                    style={{
                                                                        maxWidth: `${widthWindow <= 414 ? "20rem" : "21.4rem"}`,
                                                                        marginBottom: "0.5rem",
                                                                        marginTop: "0.6rem",
                                                                        marginLeft: "0.3rem",
                                                                        marginRight: "0.3rem",
                                                                        wordBreak: "normal",
                                                                    }}
                                                                >
                                                                    <ReactPlayer
                                                                        styles={{ backgroundColor: "red" }}
                                                                        autoPlay
                                                                        url={
                                                                            contactMessageWhatsapp.attachment !== null
                                                                                ? contactMessageWhatsapp.attachment.file_url
                                                                                : ""
                                                                        }
                                                                        width="100%"
                                                                        height="90%"
                                                                        controls={true}
                                                                    />
                                                                    <div
                                                                        style={{
                                                                            color: "#545B67",
                                                                        }}
                                                                    >
                                                                        {contactMessageWhatsapp.text !== null ? contactMessageWhatsapp.text : ""}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {contactMessageWhatsapp.attachment.type_filter === "sticker" && (
                                                            <a
                                                                onClick={() => {
                                                                    setViewImageMessage(true)
                                                                    setSelectedViewImageMessage(contactMessageWhatsapp)
                                                                }}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <Image
                                                                    src={
                                                                        !isEmptyObj(contactMessageWhatsapp.attachment)
                                                                            ? contactMessageWhatsapp.attachment.file_url
                                                                            : ""
                                                                    }
                                                                    width="170"
                                                                    alt="Imagem"
                                                                />
                                                            </a>
                                                        )}
                                                    </div>
                                                )}
                                                {contactMessageWhatsapp.over24 && (
                                                    <div className="p-d-flex p-jc-end">
                                                        {/* //Tooltip */}
                                                        <i id="money" title="Mensagem monetizada" className="pi pi-money-bill" style={{ color: "red" }}></i>
                                                    </div>
                                                )}
                                                {contactMessageWhatsapp.type === "text" && (
                                                    // Aqui
                                                    <div
                                                        className="Container"
                                                        style={{
                                                            fontSize: "1.1rem",
                                                            textRendering: "optimizeLegibility",
                                                            maxWidth: `${widthWindow <= 414 ? "15rem" : "45rem"}`,
                                                            wordBreak: "normal",
                                                        }}
                                                        dangerouslySetInnerHTML={{ __html: formattedText }}
                                                    ></div>
                                                )}

                                                <div className="p-d-flex p-jc-end">
                                                    <br />
                                                    <div
                                                        onClick={() => {
                                                            if (contactMessageWhatsapp.status !== "failed") {
                                                                setInformationDetailMessageVisualition("viewInformationMessage")
                                                                setInformationDetailMessageChat(contactMessageWhatsapp)
                                                            }
                                                        }}
                                                        className="p-d-flex p-as-end"
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <div className="p-d-flex" style={{ fontSize: "0.65rem" }}>
                                                            <div style={{ color: "#797E88" }} className="p-mr-1">
                                                                <Moment format="HH:mm">{contactMessageWhatsapp.created_at}</Moment>
                                                            </div>

                                                            <a
                                                                onClick={() => {
                                                                    if (contactMessageWhatsapp.status === "failed") {
                                                                        setSendMessageAgain(true)
                                                                        messageFailedSendAgain = contactMessageWhatsapp
                                                                        setMessageFailedSendAgain(messageFailedSendAgain)
                                                                    }
                                                                }}
                                                            >
                                                                {messageReadConfirmation(contactMessageWhatsapp)}
                                                            </a>
                                                        </div>

                                                        <p style={{ display: "none" }}>{contactMessageWhatsapp.status} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                &nbsp;
                                <Avatar
                                    image={contactMessageWhatsapp.user.avatar_url !== null ? contactMessageWhatsapp.user.avatar_url : noPictureURL}
                                    className="p-mr-2"
                                    shape="circle"
                                    style={{
                                        width: "2rem",
                                        height: "2rem",
                                    }}
                                    onClick={() => {
                                        setNameClassAvatarDialogSeller("showInformationAboutSellerWhoResponded")
                                        informationAboutSellerWhoResponded = contactMessageWhatsapp.user
                                        setInformationAboutSellerWhoResponded(informationAboutSellerWhoResponded)
                                        setViewEditInformationAboutSeller(false)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const typeOfMessageReceived = (
        contactMessageWhatsapp: MessagesWhatsappConversation,
        index: number,
        classNameForAnimation: string,
        colorBackground: string,
        textForJSX: any
    ) => {
        let splits = textForJSX.split(" ")
        splits = splits.toString()
        let str = splits.replace(/[^0-9]/g, "")

        const formatText = (text: string) => {
            // Format *Bold*
            text = text.replace(/\*([^*]+)\*/g, "<strong>$1</strong>")

            // Format _Italic_
            text = text.replace(/_([^_]+)_/g, "<em>$1</em>")

            // Format ~Strikethrough~
            text = text.replace(/~([^~]+)~/g, "<del>$1</del>")

            // Format ```Monospace```
            text = text.replace(/```([^`]+)```/g, "<code>$1</code>")

            // Format _*Bold Italic*_
            text = text.replace(/_\*\s*([^*_]+)\s*\*_/g, "<em><strong>$1</strong></em>")

            // Format *_~Strikethrough Bold~_*
            text = text.replace(/\*\*_~([^~]+)~_\*\*/g, "<strong><em><del>$1</del></em></strong>")

            return text
        }

        const formattedText = formatText(textForJSX)
        return (
            <>
                <div
                    id={contactMessageWhatsapp.id}
                    className={`${classNameForAnimation}`}
                    onMouseOver={() => setIdMessageForOnMouseOver(contactMessageWhatsapp.whatsapp_id)}
                    style={{
                        marginLeft: "1.9rem",
                        marginTop: "1rem",
                        // display: `${index < lengthOfMessagesConversationSelected ? "none" : "block"}`
                    }}
                >
                    <div
                        ref={myRefCardMessage}
                        id={contactMessageWhatsapp.id}
                        className={`${classNameForAnimation}`}
                        style={{ padding: "0.8rem 0.8rem 0.8rem 0.8rem", backgroundColor: `${repliedMessageMarker(contactMessageWhatsapp)}` }}
                    >
                        <div key={index} className="p-grid">
                            <div className="" key="1">
                                <div className="p-d-flex">
                                    <div>
                                        <div
                                            className={`${contactMessageWhatsapp.type !== "sticker" ? "bubbleMessageCardReceived p-d-flex p-ai-center" : ""}`}
                                            style={{
                                                backgroundColor: `${contactMessageWhatsapp.type === "sticker" ? "" : "white"}`,
                                                maxWidth: `${widthWindow <= 414 ? "15rem" : "45rem"}`,
                                            }}
                                        >
                                            <div>
                                                {!isEmptyObj(contactMessageWhatsapp.context_message_id) ? (
                                                    <div
                                                        className="p-d-flex p-jc-center"
                                                        style={{
                                                            width: "auto",
                                                            padding: "0.5rem",
                                                            margin: "0.5rem",
                                                            backgroundColor: "rgba(100, 105, 98, 0.2)",
                                                            borderRadius: "0.5rem",
                                                        }}
                                                    >
                                                        {replyMessage(contactMessageWhatsapp)}
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}

                                                <div style={{ padding: "0.2rem" }}>
                                                    {textForJSX.includes("https://") || textForJSX.includes("http://") ? (
                                                        <div style={{ maxWidth: `${widthWindow <= 414 ? "15rem" : "44rem"}` }}>
                                                            <iframe
                                                                src={textHttpsToIframe(textForJSX)}
                                                                frameBorder="0"
                                                                seamless={false}
                                                                title="document"
                                                                width="100%"
                                                                style={{ border: "none", borderRadius: "0.8rem" }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}

                                                    {str.length === 14 ||
                                                        (textForJSX.includes("CNPJ" && str.length === 14) &&
                                                            conversationSelected.contact?.company.cnpj === null && (
                                                                <div className="p-d-flex p-jc-end">
                                                                    <div className="p-mr-2">
                                                                        <i
                                                                            className="pi pi-question-circle"
                                                                            onClick={() => {
                                                                                companyCnpj = str
                                                                                setCompanyCnpj(companyCnpj)
                                                                                setEditCompanyDialog(true)
                                                                            }}
                                                                            style={{ color: "#91bbff", cursor: "pointer" }}
                                                                        ></i>{" "}
                                                                    </div>
                                                                </div>
                                                            ))}

                                                    {contactMessageWhatsapp.attachment && (
                                                        <div>
                                                            {contactMessageWhatsapp.attachment.type_filter === "image" && (
                                                                <div>
                                                                    <a
                                                                        onClick={() => {
                                                                            setViewImageMessage(true)
                                                                            setSelectedViewImageMessage(contactMessageWhatsapp)
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                        <div>
                                                                            <Image
                                                                                src={
                                                                                    !isEmptyObj(contactMessageWhatsapp.attachment)
                                                                                        ? contactMessageWhatsapp.attachment.file_url
                                                                                        : ""
                                                                                }
                                                                                width={`${widthWindow <= 414 ? "200" : "300"}`}
                                                                                height="auto"
                                                                                alt="Imagem"
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                color: "#545B67",
                                                                            }}
                                                                        >
                                                                            {contactMessageWhatsapp.text !== null ? contactMessageWhatsapp.text : ""}
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            )}

                                                            {contactMessageWhatsapp.attachment.type_filter === "document" && (
                                                                <div style={{ padding: "0.2rem" }}>
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <div>{archiveTypeForDocuments(contactMessageWhatsapp)}</div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="p-d-flex p-jc-center">
                                                                        <a
                                                                            href={
                                                                                contactMessageWhatsapp.attachment !== null
                                                                                    ? contactMessageWhatsapp.attachment.file_url
                                                                                    : ""
                                                                            }
                                                                            rel="noopener noreferrer"
                                                                            target="_blank"
                                                                        >
                                                                            <div className="dowloadFileDocument">
                                                                                <strong>DOWNLOAD</strong>
                                                                                &nbsp;&nbsp;
                                                                                <i className="pi pi-external-link"></i>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {contactMessageWhatsapp.attachment.type_filter === "audio" && (
                                                                <div
                                                                    className="Container"
                                                                    style={{
                                                                        maxWidth: `${widthWindow <= 414 ? "20rem" : "35rem"}`,
                                                                        width: "25rem",
                                                                        marginBottom: "0.3rem",
                                                                        marginTop: "0.3rem",
                                                                        wordBreak: "normal",
                                                                    }}
                                                                >
                                                                    <audio
                                                                        src={
                                                                            contactMessageWhatsapp.attachment !== null
                                                                                ? contactMessageWhatsapp.attachment.file_url
                                                                                : ""
                                                                        }
                                                                        className="audio-1"
                                                                        controls
                                                                        playsInline={true}
                                                                        style={{
                                                                            width: "90%",
                                                                            height: "2.3rem",
                                                                            resize: "none",
                                                                            outline: "none",
                                                                            borderRadius: "5px",
                                                                            textDecoration: "bold",
                                                                            fontSize: "1rem",
                                                                            marginLeft: "1rem",
                                                                            marginRight: "1rem",
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}

                                                            {contactMessageWhatsapp.attachment.type_filter === "video" && (
                                                                <div className="p-d-flex p-jc-end p-ai-center">
                                                                    <div
                                                                        style={{
                                                                            maxWidth: `${widthWindow <= 414 ? "20rem" : "21.4rem"}`,
                                                                            marginBottom: "0.5rem",
                                                                            marginTop: "0.6rem",
                                                                            marginLeft: "0.3rem",
                                                                            marginRight: "0.3rem",
                                                                            wordBreak: "normal",
                                                                        }}
                                                                    >
                                                                        <ReactPlayer
                                                                            styles={{ backgroundColor: "red" }}
                                                                            autoPlay
                                                                            url={
                                                                                contactMessageWhatsapp.attachment !== null
                                                                                    ? contactMessageWhatsapp.attachment.file_url
                                                                                    : ""
                                                                            }
                                                                            width="100%"
                                                                            height="90%"
                                                                            controls={true}
                                                                        />
                                                                        <div
                                                                            style={{
                                                                                color: "#545B67",
                                                                            }}
                                                                        >
                                                                            {contactMessageWhatsapp.text !== null ? contactMessageWhatsapp.text : ""}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {contactMessageWhatsapp.attachment.type_filter === "sticker" && (
                                                                <a
                                                                    onClick={() => {
                                                                        setViewImageMessage(true)
                                                                        setSelectedViewImageMessage(contactMessageWhatsapp)
                                                                    }}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <Image
                                                                        src={
                                                                            !isEmptyObj(contactMessageWhatsapp.attachment)
                                                                                ? contactMessageWhatsapp.attachment.file_url
                                                                                : ""
                                                                        }
                                                                        width="170"
                                                                        alt="Imagem"
                                                                    />
                                                                </a>
                                                            )}
                                                        </div>
                                                    )}

                                                    {contactMessageWhatsapp.type === "text" && (
                                                        <div>
                                                            {textForJSX.includes("https://") || textForJSX.includes("http://") ? (
                                                                <div
                                                                    dangerouslySetInnerHTML={{ __html: transformTextLink(textForJSX) }}
                                                                    style={{
                                                                        fontSize: "1.1rem",
                                                                        textRendering: "optimizeLegibility",
                                                                        maxWidth: "45vw",
                                                                        marginRight: "0.2rem",
                                                                        wordBreak: "normal",
                                                                    }}
                                                                ></div>
                                                            ) : (
                                                                <>
                                                                    <div
                                                                        className="Container"
                                                                        style={{
                                                                            fontSize: "1.1rem",
                                                                            textRendering: "optimizeLegibility",
                                                                            maxWidth: "45vw",
                                                                            marginRight: "0.2rem",
                                                                            wordBreak: "normal",
                                                                        }}
                                                                        dangerouslySetInnerHTML={{ __html: formattedText }}
                                                                    ></div>
                                                                </>
                                                            )}
                                                        </div>
                                                    )}

                                                    {contactMessageWhatsapp.type === "locate" && (
                                                        <div style={{ padding: "0.2rem" }}>
                                                            <div className="p-d-flex p-jc-center">
                                                                <>
                                                                    <div className="dowloadFileDocument">
                                                                        <iframe
                                                                            title="map"
                                                                            src={`https://maps.google.com/maps?q=${contactMessageWhatsapp.locate_lat},${contactMessageWhatsapp.locate_long}&t=&z=${zoom}&ie=UTF8&iwloc=&output=embed`}
                                                                            frameBorder="0"
                                                                            style={{ border: "0", width: "100%", height: "100%" }}
                                                                        />
                                                                    </div>
                                                                </>
                                                            </div>
                                                            <div style={{ textAlign: "center" }}>
                                                                <div style={{ color: "#2196F3" }}>{contactMessageWhatsapp.locate_name}</div>
                                                                <div>{contactMessageWhatsapp.locate_address}</div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {contactMessageWhatsapp.type === "contact" && (
                                                        <div>
                                                            <>
                                                                <div style={{ width: "22rem", maxWidth: "23rem", height: "7rem" }}>
                                                                    <div
                                                                        className="p-d-flex"
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "75%",
                                                                            borderBottom: "0.5px solid rgba(91, 95, 103, 0.05)",
                                                                        }}
                                                                    >
                                                                        <div className="p-d-flex p-jc-center p-ai-center" style={{ width: "25%" }}>
                                                                            <Avatar icon="pi pi-user" className="p-mr-2" size="xlarge" shape="circle" />
                                                                        </div>
                                                                        <div
                                                                            className="p-d-flex p-ai-center p-text-nowrap p-text-truncate"
                                                                            style={{ width: "75%", maxWidth: "75%" }}
                                                                        >
                                                                            <p style={{ fontWeight: "600" }}>{contactMessageWhatsapp.contact_name}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="p-d-flex"
                                                                        style={{ width: "100%", height: "25%", color: "#027eb5", marginTop: "0.2rem" }}
                                                                    >
                                                                        <div
                                                                            className="p-d-flex p-jc-center p-ai-center"
                                                                            style={{ width: "50%" }}
                                                                            onClick={() => {
                                                                                let findContact = conversations.findIndex(
                                                                                    (conversation) =>
                                                                                        contactMessageWhatsapp.contact_whatsapp_number !== null &&
                                                                                        conversation.phone_number ===
                                                                                            contactMessageWhatsapp.contact_whatsapp_number
                                                                                )
                                                                                if (findContact >= 0) {
                                                                                    let aux = conversations[findContact]
                                                                                    if (permission || aux.user_id === user.id) {
                                                                                        conversationSelected = aux
                                                                                        setConversationSelected(conversationSelected)
                                                                                    } else {
                                                                                        toast.current?.show({
                                                                                            severity: "warn",
                                                                                            summary: "Erro",
                                                                                            detail: "Você não tem permissão para acessar essa conversa",
                                                                                            life: 3000,
                                                                                        })
                                                                                    }
                                                                                } else if (findContact === -1) {
                                                                                    if (contactMessageWhatsapp.contact_whatsapp_number === null) {
                                                                                        toast.current?.show({
                                                                                            severity: "error",
                                                                                            summary: "Este número não possui whatsapp",
                                                                                            detail: "",
                                                                                            life: 3000,
                                                                                        })
                                                                                    } else {
                                                                                        let auxNumber = contactMessageWhatsapp.contact_number.replace(
                                                                                            /[^0-9]/g,
                                                                                            ""
                                                                                        )
                                                                                        phoneContact = auxNumber
                                                                                        setPhoneContact(phoneContact)
                                                                                        nameContact = contactMessageWhatsapp.contact_name
                                                                                        setNameContact(nameContact)
                                                                                        openANonExistingConversation(auxNumber)
                                                                                    }
                                                                                } else {
                                                                                    toast.current?.show({
                                                                                        severity: "error",
                                                                                        summary: "Aconteceu um erro ao abrir a conversa",
                                                                                        detail: "Fale com o suporte",
                                                                                        life: 3000,
                                                                                    })
                                                                                }
                                                                            }}
                                                                        >
                                                                            <p style={{ fontWeight: "400", cursor: "pointer" }}>Conversar</p>
                                                                        </div>

                                                                        <div
                                                                            className="p-d-flex p-jc-center p-ai-center"
                                                                            style={{ width: "50%", borderLeft: "0.5px solid rgba(91, 95, 103, 0.05)" }}
                                                                            onClick={() => {
                                                                                setContactsDialog(true)
                                                                                let auxPhone

                                                                                if (contactMessageWhatsapp.contact_whatsapp_number !== null) {
                                                                                    if (
                                                                                        contactMessageWhatsapp.contact_whatsapp_number.substring(0, 2) === "55"
                                                                                    ) {
                                                                                        let newNumber =
                                                                                            contactMessageWhatsapp.contact_whatsapp_number.substring(2)
                                                                                        auxPhone = {
                                                                                            id: uuidv4(),
                                                                                            telephone: `${newNumber}`,
                                                                                            name: "phone",
                                                                                            ddi: {
                                                                                                ddi: "+055",
                                                                                                country: "Brasil",
                                                                                            },
                                                                                        }
                                                                                    }
                                                                                } else if (contactMessageWhatsapp.contact_number !== null) {
                                                                                    if (contactMessageWhatsapp.contact_number.substring(0, 3) === "+55") {
                                                                                        let newNumber = contactMessageWhatsapp.contact_number
                                                                                            .substring(3)
                                                                                            .replaceAll(" ", "")
                                                                                        auxPhone = {
                                                                                            id: uuidv4(),
                                                                                            telephone: `${newNumber}`,
                                                                                            name: "phone",
                                                                                            ddi: {
                                                                                                ddi: "+055",
                                                                                                country: "Brasil",
                                                                                            },
                                                                                        }
                                                                                    }
                                                                                }

                                                                                if (auxPhone !== undefined) {
                                                                                    newPhoneField[0] = auxPhone
                                                                                    setNewPhoneField(newPhoneField)
                                                                                }
                                                                                formContact.name = contactMessageWhatsapp.contact_name
                                                                                setFormContact(formContact)
                                                                            }}
                                                                        >
                                                                            <p style={{ fontWeight: "400", cursor: "pointer" }}>Adicionar contato</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="p-d-flex p-jc-end" style={{ maxWidth: `${widthWindow <= 414 ? "15rem" : "44.5rem"}` }}>
                                                    <a
                                                        onClick={() => {
                                                            if (contactMessageWhatsapp.status !== "failed") {
                                                                setInformationDetailMessageVisualition("viewInformationMessage")
                                                                setInformationDetailMessageChat(contactMessageWhatsapp)
                                                            }
                                                        }}
                                                        className="p-d-flex p-as-end"
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <div
                                                            className="p-d-flex p-as-end"
                                                            style={{ fontSize: "0.65rem", color: "#797E88", marginLeft: "0.2rem" }}
                                                        >
                                                            <Moment format="HH:mm" style={{}}>
                                                                {contactMessageWhatsapp.created_at}
                                                            </Moment>{" "}
                                                            &nbsp;&nbsp;
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {widthWindow <= 454 ? (
                                        <>
                                            <a
                                                onClick={() => {
                                                    setSelectReplyMessageChat(contactMessageWhatsapp)
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <i className="pi pi-arrow-circle-down" style={{ opacity: "0.4", margin: "0.3rem" }} />
                                            </a>
                                        </>
                                    ) : (
                                        <div>
                                            {idMessageForOnMouseOver === contactMessageWhatsapp.whatsapp_id ? (
                                                <div style={{ margin: "0.3rem" }}>
                                                    {/* <a
                                                        onClick={() => {
                                                            setSelectReplyMessageChat(contactMessageWhatsapp)
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <i className="iconReplyMessage pi pi-arrow-circle-down" />
                                                    </a> */}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const filterSearchConversations = () => {
        if (advanceSearchFilterConversations !== "" || unreadFilter) {
            const listMediasFiltered = conversationsFilter.filter((atualLine) => {
                if (unreadFilter) {
                    const auxAdd = atualLine.messages.some((message) => {
                        return (
                            message.status === "received" &&
                            (message.type === "document" || message.type === "audio" || message.type === "text") &&
                            (!final_words.some((word) => message?.text?.toLowerCase().includes(word)) ||
                                messagesContains.some((word) => message?.text?.toLowerCase().includes(word)))
                        )
                    })

                    if (!auxAdd) {
                        return false
                    }
                }

                if (advanceSearchFilterConversations !== "" && advanceSearchFilterConversations !== null) {
                    const word = advanceSearchFilterConversations.toLowerCase().split(" ")

                    if (atualLine.contact) {
                        if (!atualLine.contact?.name) atualLine.contact.name = ""
                        if (atualLine.contact.company) {
                            if (!atualLine.contact?.company?.name) atualLine.contact.company.name = ""
                            if (!atualLine.phone_number) atualLine.phone_number = ""
                            if (!atualLine.contact?.company?.cnpj) atualLine.contact.company.cnpj = ""
                        }
                    }

                    if (!atualLine.phone_number) atualLine.phone_number = ""
                    if (!atualLine.id) atualLine.id = ""

                    const addLine = word.some((oneWord) => {
                        oneWord = oneWord.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                        return (
                            atualLine.id.includes(oneWord) ||
                            atualLine.phone_number.includes(oneWord) ||
                            atualLine.contact?.company?.name.toLowerCase().includes(oneWord) ||
                            atualLine.contact?.name.toLowerCase().includes(oneWord) ||
                            atualLine.contact?.company?.cnpj.toLowerCase().includes(oneWord)
                        )
                    })

                    if (!addLine) {
                        return false
                    }
                }

                return true
            })

            conversations = listMediasFiltered
            setConversations(conversations)
            tabFilterSetFunction()
        } else {
            conversations = conversationsFilter
            setConversations(conversations)
            tabFilterSetFunction()
        }
    }

    const autoFocusAndCleanInputSearchBar = () => {
        var hh = document.getElementById("autofocs")
        setTimeout(() => {
            hh?.focus()
        }, 500)
    }

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    /* Handle Key Press */
    const handleKeyPress = (event: any) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault()
            if (conversationSelected.id !== "") {
                onSendMessagetoContact(inputTextSendMessage, event)
                setInputTextSendMessage("")
            } else if (conversationSelected.id === "") {
                inputSendFirtsMessage = inputTextSendMessage
                setInputSendFirtsMessage(inputSendFirtsMessage)
                sendFirtsMesage(event, inputSendFirtsMessage)
                setInputTextSendMessage("")
            }
        }
    }

    const handleKeyPressDefaultMessage = (event: any) => {
        var editElementDesktop = document.getElementById("w-textAreaDesktop")

        if (event.key === "Enter") {
            event.preventDefault()
            if (editElementDesktop !== null) {
                editElementDesktop.innerHTML = auxDefaultMessages[0].text
                inputTextSendMessage = auxDefaultMessages[0].text
                setInputTextSendMessage(inputTextSendMessage)
                setRendererDefaultMessageShortcut(false)
            }
        }
    }

    const handleKeyPressSearchMessage = (event: any) => {
        if (event.key === "Enter") {
            event.preventDefault()
            chatMessageFilter(inputTextSearchMessagesChat, conversationSelected)
        }
    }

    const handleContextMenu = useCallback(
        (event: any) => {
            event.preventDefault()
            setAnchorPoint({ x: event.pageX, y: event.pageY })
            setShow(true)
            return ""
        },
        [setAnchorPoint, setShow]
    )

    /* Fields Formater */
    const replaceStatusMessageWriting = (messageInformation: MessagesWhatsappConversation) => {
        switch (messageInformation.status) {
            case "delivered":
                return "Entregue"
            case "read":
                return "Visualizada"
            case "sent":
                return "Enviado"
            case "failed":
                return "Não enviada"
            default:
                return ""
        }
    }

    const colorSelectedCard = (companyName: WhatsappConversationInterface) => {
        return !isEmptyObj(conversationSelected) && conversationSelected.id === companyName.id ? "#f0f2f5" : ""
    }

    const messageReadConfirmation = (contactMessageWhatsapp: MessagesWhatsappConversation) => {
        const { status } = contactMessageWhatsapp

        switch (status) {
            case "unsent":
                return (
                    <div>
                        <i className="pi pi-spin pi-spinner" style={{ fontSize: "1em" }}></i>
                    </div>
                )
            case "delivered":
                return (
                    <div>
                        <i className="pi pi-check" style={{ fontSize: "0.6rem", color: "" }}></i>
                        <i className="pi pi-check" style={{ fontSize: "0.6rem", color: "", marginLeft: "-0.3rem", marginRight: "0.1rem" }}></i>
                    </div>
                )
            case "read":
                return (
                    <div>
                        <i className="pi pi-check" style={{ fontSize: "0.6rem", color: "#19e5e6" }}></i>
                        <i className="pi pi-check" style={{ fontSize: "0.6rem", color: "#19e5e6", marginLeft: "-0.3rem", marginRight: "0.1rem" }}></i>
                    </div>
                )
            case "sent":
                return (
                    <i
                        className="pi pi-check p-d-flex p-as-center"
                        style={{ fontSize: "0.6rem", color: "", marginBottom: "-0.2rem", marginRight: "0.1rem" }}
                    ></i>
                )
            case "failed":
                return (
                    <div>
                        <i className="pi pi-times" style={{ fontSize: "0.8rem", color: "red" }}></i>
                    </div>
                )
            default:
                return null
        }
    }

    const companyImageDataTable = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Imagem</span>
                <Avatar
                    image={rowData.company.picture_url === null ? noPictureURL : rowData.company.picture_url}
                    className="p-mr-2"
                    size="xlarge"
                    shape="circle"
                />
            </>
        )
    }

    const companyFormaterDataTable = (rowData: any) => {
        return rowData.company.name === null ? <>-</> : rowData.company.name
    }

    const executeScroll = () => {
        myRefCardMessage.current && myRefCardMessage.current.scrollIntoView(false)
    }

    const hideDialog = () => {
        setCompaniesOpportunity([])
        setSelectedViewImageMessage({} as MessagesWhatsappConversation)
        setSaveCompanyData({} as SaveCompanyData)
        setNameContact("")
        setPhoneContact("")
        setImagePreviewDialog(false)
        setFormContact({} as ContactInterface)
        setHotKeysDialog(false)
        setEditCompanyDialog(false)
        setNewMessageDialog(false)
        setViewImageMessage(false)
        setVideoDialog(false)
        setVideoPreviewDialog(false)
        setContactsDialog(false)
        setNameClassAvatarDialog("noDialogShowClientInformation")
        setNameClassAvatarDialogSeller("noShowInformationAboutSellerWhoResponded")
        setInformationDetailMessageVisualition("noViewInformationMessage")
        setSelectReplyMessageChat({} as MessagesWhatsappConversation)
        setImageNewPreviewDialog(false)
        setImageUploadDialog(false)
        setUploadFromFilesDialog(false)
        setSendDefaultMessageDialog(false)
        setVideoNewPreviewDialog(false)
        setChangeResponsible(false)
        setRemoveResponsible(false)
        setSendMessageAgain(false)
        setViewEditInformationAboutSeller(false)
        setConfirmUploadFileDialog(false)
        setFirstDefaultMessage(false)
        setImagesFilePath([])
        setRoadMapDialog(false)
        setRequestApiCompany({} as IEmpresaInterface)
        setExistingCompany({} as CompanyInterface)
        setDialogFilterDatesConversation(false)
    }

    const textMenssageResponsible = () => {
        return (
            <>
                <Dropdown
                    style={{
                        width: "350px",
                    }}
                    filter={true}
                    options={users}
                    emptyMessage="Não há dados"
                    emptyFilterMessage="Não há dados"
                    optionLabel="name"
                    value={!isEmptyObj(editResponsibleContact) ? editResponsibleContact : ""}
                    placeholder={
                        !isEmptyObj(contextMenuConversationSelected)
                            ? contextMenuConversationSelected.user !== null
                                ? contextMenuConversationSelected.user.name
                                : "Sem responsável"
                            : "Sem responsável"
                    }
                    onChange={(e) => {
                        editResponsibleContact = e.value
                        setEditResponsibleContact(e.value)
                    }}
                />
            </>
        )
    }

    const openContactFunction = (advancedSearchFilter: string) => {
        setPhoneContact(advancedSearchFilter)
        setNameContact(advancedSearchFilter)
        openANonExistingConversation(advancedSearchFilter)
    }

    const openANonExistingConversation = (number: string) => {
        let exist = false
        conversations.map((conversation) => {
            if (number.length > 8) {
                const numberEnds = number.substring(number.length - 8, number.length)

                if (conversation.phone_number.endsWith(numberEnds)) {
                    if (conversation?.user_id === null || conversation?.user_id === profileUser.id || permission) {
                        conversationSelected = conversation
                        setConversationSelected(conversationSelected)
                        allMessages(conversationSelected.id)
                        exist = true
                        return
                    } else {
                        toast.current?.show({
                            severity: "error",
                            summary: `A conversa já possui um responsável`,
                            detail: "",
                            life: 4000,
                        })
                        exist = true
                        return
                    }
                }
            } else if (number.length <= 8) {
                if (conversation.phone_number.endsWith(number)) {
                    if (conversation?.user_id === null || conversation?.user_id === profileUser.id || permission) {
                        conversationSelected = conversation
                        setConversationSelected(conversationSelected)
                        allMessages(conversationSelected.id)
                        exist = true
                        return
                    } else {
                        toast.current?.show({
                            severity: "error",
                            summary: `A conversa já possui um responsável`,
                            detail: "",
                            life: 4000,
                        })
                        exist = true
                        return
                    }
                }
            }
        })
        if (!exist) {
            let formatedNumber = number.split(" ")

            let auxContact: WhatsappConversationInterface = {
                id: "",
                messages: [],
                phone_number: `${formatedNumber}`,
                whatsapp_phone_number: `${formatedNumber}`,
                contact_id: "",
                contact: {} as ContactWhatsappConversation,
                user_id: "",
                user: {} as UserInterface,
                profile_picture_url: "",
                created_at: "",
                archived: false,
                updated_at: "",
                new: false,
            }

            conversationSelected = auxContact
            setConversationSelected(conversationSelected)
            firstMessage = true
            setFirstMessage(firstMessage)
        }

        setNewMessageDialog(false)
    }

    const windowWidth = (widthWindow: number) => {
        const widthBoxDateTimeInChat = widthWindow > 1281 ? "30%" : "50%"
        widthLastMessageText = widthWindow > 1281 ? "22rem" : "12rem"
        setWidthBoxDateTimeInChat(widthBoxDateTimeInChat)
    }

    //Road Map Visualization
    const mapOfMessagesRoadMap = () => {
        let dataRoadMap = roadMapSelectVisualization.messages.sort((a: MessagesRoadMapInterface, b: MessagesRoadMapInterface) =>
            a.sequence > b.sequence ? 1 : -1
        )
        let classNameForAnimation = "notAnimated"
        let colorBackground = "white"

        return dataRoadMap.map((roadMap, index) => {
            return <div>{typeOfMessageSentVisualization(roadMap, index, classNameForAnimation, colorBackground)}</div>
        })
    }

    const typeOfMessageSentVisualization = (
        contactMessageWhatsapp: MessagesRoadMapInterface,
        index: number,
        classNameForAnimation: string,
        colorBackground: string
    ) => {
        if (contactMessageWhatsapp.type === "text") {
            return messageTextVisualization(contactMessageWhatsapp, classNameForAnimation, colorBackground)
        }

        if (contactMessageWhatsapp.type === "attachment") {
            return messageImageVisualization(contactMessageWhatsapp, classNameForAnimation)
        }
    }

    const messageTextVisualization = (contactMessageWhatsapp: MessagesRoadMapInterface, classNameForAnimation: string, colorBackground: string) => {
        return (
            <>
                <div
                    id={contactMessageWhatsapp.id}
                    key={contactMessageWhatsapp.id}
                    style={{
                        padding: "0.8rem 0rem 0.8rem 0rem",
                    }}
                >
                    <div className={`${classNameForAnimation}`} style={{ marginLeft: "0rem", marginTop: "1rem" }}>
                        <div className="p-grid p-d-flex p-jc-end" style={{ marginRight: "1rem" }}>
                            <div className="" key="1">
                                <p className="p-d-flex p-jc-end" style={{ marginBottom: "-0.1rem", fontSize: "0.8rem" }}>
                                    {profileUser.name} &nbsp;&nbsp;&nbsp;
                                </p>
                                <div className="p-d-flex">
                                    <div className="p-d-flex p-jc-end">
                                        <div
                                            style={{
                                                backgroundColor: `${colorBackground}`,
                                                border: "0.01rem solid #e4e5e7",
                                                borderRadius: "0.8rem 0.8rem 0rem 0.8rem",
                                                boxShadow: "0.04rem 0.04rem 0.06rem #d7d8db",
                                            }}
                                        >
                                            <div className="p-d-flex p-jc-end p-ai-center">
                                                <div
                                                    className="Container"
                                                    style={{
                                                        fontSize: "1.1rem",
                                                        textRendering: "optimizeLegibility",
                                                        maxWidth: `${widthWindow <= 414 ? "15rem" : "45rem"}`,
                                                        marginBottom: "0.5rem",
                                                        marginTop: "0.3rem",
                                                        marginLeft: "0.5rem",
                                                        marginRight: "0.5rem",
                                                        wordBreak: "normal",
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            contactMessageWhatsapp.defaultMessage !== undefined
                                                                ? contactMessageWhatsapp.defaultMessage.text !== undefined
                                                                    ? contactMessageWhatsapp.defaultMessage.text
                                                                    : ""
                                                                : "",
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                    &nbsp;
                                    <Avatar
                                        image={profileUser.avatar_url !== null ? profileUser.avatar_url : noPictureURL}
                                        className="p-mr-2"
                                        shape="circle"
                                        style={{ position: "unset" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const messageImageVisualization = (contactMessageWhatsapp: MessagesRoadMapInterface, classNameForAnimation: string) => {
        return (
            <>
                <div ref={myRefCardMessage} id={contactMessageWhatsapp.id} key={contactMessageWhatsapp.id} style={{ padding: "0.8rem 0rem 0.8rem 0rem" }}>
                    <div className={`${classNameForAnimation}`} style={{ marginLeft: "0rem", marginTop: "1rem" }}>
                        <div className="p-grid p-d-flex p-jc-end" style={{ marginBottom: "1.5rem", marginRight: "1rem" }}>
                            <div className="" key="1">
                                <p className="p-d-flex p-jc-end" style={{ marginBottom: "-0.1rem", fontSize: "0.8rem" }}>
                                    {profileUser.name} &nbsp;&nbsp;&nbsp;
                                </p>
                                <div className="p-d-flex">
                                    <div className="p-d-flex p-jc-end">
                                        {widthWindow <= 454 ? (
                                            <>
                                                <a onClick={() => {}} style={{ cursor: "pointer" }}>
                                                    <i className="pi pi-arrow-circle-down" style={{ opacity: "0.4", margin: "0.3rem" }} />
                                                </a>
                                            </>
                                        ) : (
                                            <div></div>
                                        )}
                                        <div
                                            style={{
                                                backgroundColor: "white",
                                                border: "0.01rem solid #e4e5e7",
                                                borderRadius: "0.5rem 0.5rem 0rem 0.5rem",
                                                boxShadow: "0.04rem 0.04rem 0.06rem #d7d8db",
                                            }}
                                        >
                                            <div className="p-d-flex p-jc-end p-ai-center">
                                                <div className="p-d-flex p-jc-end p-ai-center">
                                                    <div
                                                        className="Container"
                                                        style={{
                                                            maxWidth: `${widthWindow <= 414 ? "20rem" : "35rem"}`,
                                                            marginBottom: "0.3rem",
                                                            marginTop: "0.3rem",
                                                            marginLeft: "0.3rem",
                                                            marginRight: "0.3rem",
                                                            wordBreak: "normal",
                                                        }}
                                                    >
                                                        <a
                                                            onClick={() => {
                                                                setViewImageMessage(true)
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <>
                                                                {contactMessageWhatsapp.attachment !== undefined
                                                                    ? typeOfRoadMap(contactMessageWhatsapp.attachment)
                                                                    : ""}
                                                            </>
                                                        </a>

                                                        <div className="p-d-flex p-jc-end p-as-end" style={{ fontSize: "0.65rem", userSelect: "none" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    &nbsp;
                                    <Avatar
                                        icon={profileUser.avatar_url === null ? "pi pi-user" : ""}
                                        image={profileUser.avatar_url}
                                        className="p-mr-2"
                                        shape="circle"
                                        style={{ position: "unset" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const typeOfRoadMap = (attachment: AttachmentInterface) => {
        if (attachment.type === "image") {
            return (
                <>
                    <Image
                        src={attachment !== undefined ? (attachment.file_url !== undefined ? attachment.file_url : "") : ""}
                        width={`${widthWindow <= 414 ? "200" : "300"}`}
                        height="auto"
                        alt="Imagem"
                    />
                </>
            )
        }
        if (attachment.type === "sticker") {
            return <Image src={attachment !== undefined ? (attachment.file_url !== undefined ? attachment.file_url : "") : ""} width="170" alt="Imagem" />
        }

        if (attachment.type === "document") {
            let splitArchive = attachment.file.split(".")
            let lengthSplit = splitArchive.length
            if (splitArchive[lengthSplit - 1] === "pdf") {
                return (
                    <iframe
                        src={attachment !== undefined ? (attachment.file_url !== undefined ? attachment.file_url : "") : ""}
                        frameBorder="0"
                        seamless={false}
                        title="document"
                        scrolling="no"
                        width="100%"
                        style={{ border: "none", overflow: "hidden" }}
                        height="98%"
                    />
                )
            } else {
                return (
                    <div>
                        <br />
                        <div className="p-d-flex p-jc-center">
                            <i className="pi pi-file" style={{ fontSize: "3em", color: "#54656f" }}></i>
                        </div>
                        <br />
                        <div className="p-d-flex p-jc-center" style={{ color: "#54656f" }}>
                            {attachment.name}
                        </div>
                    </div>
                )
            }
        }

        if (attachment.type === "video") {
            return (
                <ReactPlayer
                    styles={{ backgroundColor: "red" }}
                    autoPlay
                    url={attachment !== undefined ? (attachment.file_url !== undefined ? attachment.file_url : "") : ""}
                    width="100%"
                    height="90%"
                    controls={true}
                />
            )
        }

        if (attachment.type === "audio") {
            return (
                <div style={{ width: "25rem" }}>
                    <audio
                        src={attachment !== undefined ? (attachment.file_url !== undefined ? attachment.file_url : "") : ""}
                        className="audio-1"
                        controls
                        playsInline={true}
                        style={{
                            width: "100%",
                            resize: "none",
                            outline: "none",
                            borderRadius: "5px",
                            textDecoration: "bold",
                            fontSize: "1rem",
                        }}
                    />
                </div>
            )
        }
    }

    const widthScreen = () => {
        window.addEventListener("resize", function () {
            widthWindow = window.innerWidth
            windowWidth(widthWindow)
        })
    }

    const updateTheNewMessageForRead = () => {
        let auxConversationSelected = { ...conversationSelected }
        let existNewMessage = false

        if (!isEmptyObj(auxConversationSelected)) {
            auxConversationSelected.messages.map((message) => {
                if (message.new === true && activeIndex1 !== 2) {
                    existNewMessage = true
                }
            })
        }

        if (existNewMessage) {
            updateWhatsappMessageToRead()
        }
    }

    const tabFilterSetFunction = () => {
        let myConversationsTabViewAux = { ...myConversationsTabView }
        let noResponsibleTabViewAux = { ...noResponsibleTabView }
        let allConversationsTabViewAux = { ...allConversationsTabView }

        let count1 = 0
        let count1Archive = 0

        let count2 = 0
        let count2Archive = 0

        let count3 = 0
        let count3Archive = 0

        myConversationsTabViewAux.conversations = conversations.filter((conversation) => conversation.user !== null && conversation.user.id === profileUser.id)
        myConversationsTabViewAux.conversations.map((conversation) => {
            if (conversation.archived === false) {
                for (let message of conversation.messages) {
                    if (message.new === true) {
                        count1++
                        break
                    }
                }
            } else {
                for (let message of conversation.messages) {
                    if (message.new === true) {
                        count1Archive++
                        break
                    }
                }
            }
        })

        myConversationsTabViewAux.quantity = count1
        myConversationsTabViewAux.archiveQuantity = count1Archive
        myConversationsTabView = myConversationsTabViewAux
        setMyConversationsTabView(myConversationsTabView)

        noResponsibleTabViewAux.conversations = conversations.filter((conversation) => conversation.user === null)
        noResponsibleTabViewAux.conversations.forEach((conversation) => {
            if (conversation.archived === false) {
                count2++
            }
        })

        let ArciveConversation = conversations.filter((conversationArchive) => conversationArchive.user === null && conversationArchive.archived === true)
        ArciveConversation.forEach((conversation) => {
            count2Archive++
        })

        noResponsibleTabViewAux.archiveQuantity = count2Archive
        noResponsibleTabViewAux.quantity = count2

        noResponsibleTabView = noResponsibleTabViewAux
        setNoResponsibleTabView(noResponsibleTabView)

        allConversationsTabViewAux.conversations = conversations
        allConversationsTabViewAux.conversations.map((conversation) => {
            if (conversation.archived === false) {
                for (let message of conversation.messages) {
                    if (message.new === true) {
                        count3++
                        break
                    }
                }
            } else {
                for (let message of conversation.messages) {
                    if (message.new === true) {
                        count3Archive++
                        break
                    }
                }
            }
        })
        allConversationsTabViewAux.archiveQuantity = count3Archive
        allConversationsTabViewAux.quantity = count3

        allConversationsTabView = allConversationsTabViewAux
        setAllConversationsTabView(allConversationsTabView)

        tabViewBody(activeIndex1)
    }

    const tabViewBody = (tabSelected: number) => {
        let arrayTab: WhatsappConversationInterface[] = []

        switch (tabSelected) {
            case 0:
                arrayTab = myConversationsTabView.conversations
                break
            case 1:
                arrayTab = noResponsibleTabView.conversations
                break
            case 2:
                arrayTab = allConversationsTabView.conversations
                break
            default:
                break
        }

        const basicItemTemplate = (item: WhatsappConversationInterface) => {
            return (
                <div style={{ cursor: "pointer" }}>
                    <ChatsTabViewBody
                        tabSelected={tabSelected}
                        contactChat={item}
                        anchorPoint={anchorPoint}
                        widthBoxDateTimeInChat={widthBoxDateTimeInChat}
                        archiveConversation={archiveConversation}
                        widthLastMessageText={widthLastMessageText}
                        show={show}
                        setOpportunityCreationDialog={OpportunityCreation}
                        conversations={conversations}
                        conversationSelected={conversationSelected}
                        handleUpdateConversationSelected={handleUpdateConversationSelected}
                        handleColorSelectedCard={handleColorSelectedCard}
                        callWidthScreen={widthScreen}
                        executeScroll={executeScroll}
                        onChangeContact={onChangeContact}
                        updateWhatsappMessageToRead={updateWhatsappMessageToRead}
                        fetchMoreMessagesInitialPattern={fetchMoreMessagesInitialPattern}
                        autoFocusForInputSendMessage={autoFocusForInputSendMessage}
                        informationAboutSellerWhoResponded={handleInformationAboutSellerWhoResponded}
                        responsibleContact={responsibleContact}
                        handleContextMenuConversationSelected={handleContextMenuConversationSelected}
                        updateTheNewMessageForRead={updateTheNewMessageForRead}
                        handleResponsibleContact={handleResponsibleContact}
                        setCompaniesOpportunity={() => setCompaniesOpportunity([])}
                        setAuxContacts={() => setAuxContacts([])}
                        setRemoveResponsible={(e: boolean) => setRemoveResponsible(e)}
                        setUnArchiveConversationDialog={(e: boolean) => setUnArchiveConversationDialog(e)}
                        setArchiveConversationDialog={(e: boolean) => setArchiveConversationDialog(e)}
                        handleContextMenu={(e: any) => handleContextMenu(e)}
                        setChangeResponsible={(e: true) => setChangeResponsible(e)}
                        setChatDialogMobile={(e: string) => setChatDialogMobile(e)}
                        colorSelectedCard={(e: WhatsappConversationInterface) => colorSelectedCard(e)}
                        setConversationSelected={(e: any) => {
                            setConversationSelected(e)
                            allMessages(conversationSelected.id)
                            executeScroll()
                            executeScrollMobile()
                        }}
                        setNameClassAvatarDialogSeller={(e: string) => setNameClassAvatarDialogSeller(e)}
                        setClassNameinputTextSendMessageOnDesktop={(e: string) => setClassNameinputTextSendMessageOnDesktop(e)}
                        setClassNameinputTextSendMessageOnMobile={(e: string) => setClassNameinputTextSendMessageOnMobile(e)}
                        setInputTextSendMessage={(e: string) => setInputTextSendMessage(e)}
                        dateLastMessage={(e: WhatsappConversationInterface) => dateLastMessage(e)}
                        setScreenMobile={(e: string) => setScreenMobile(e)}
                        setNameClassAvatarDialog={(e: string) => setNameClassAvatarDialog(e)}
                    />
                </div>
            )
        }

        return (
            <div className="tabViewScrollabe">
                {!isEmptyObj(arrayTab) && (
                    <VirtualScroller items={arrayTab} numToleratedItems={50} showLoader={true} itemSize={50} itemTemplate={basicItemTemplate} />
                )}
            </div>
        )
    }

    const autoFocusForInputSendMessage = () => {
        setTimeout(() => {
            document.getElementById("sendTextInputMessage")?.focus()
        }, 100)
    }

    const itemTemplateDataScrollerChat = (message: MessagesWhatsappConversation) => {
        const index = conversationSelected.messages.findIndex((messageSelected: MessagesWhatsappConversation) => messageSelected.id === message.id)
        const previousMessage = conversationSelected.messages[index - 1]

        let classNameForAnimation = "notAnimated"
        let colorBackground = "white"

        let textForJSX = message.type === "text" ? (message.text !== null ? message.text.replaceAll("\n", "<br />") : "") : ""

        if (message.id === idSearchMessageDiv) {
            colorBackground = "#D7D8DB"
            classNameForAnimation = "animationSearchMessage"
        } else {
            colorBackground = "white"
        }

        if (message.status === "received") {
            return (
                <>
                    {dividerDataInChatMessages(message, previousMessage, indexSended)}
                    {typeOfMessageReceived(message, index, classNameForAnimation, colorBackground, textForJSX)}
                    {valueIndexMessageDate(index)}
                </>
            )
        }

        if (message.status !== "received") {
            return (
                <>
                    {dividerDataInChatMessages(message, previousMessage, indexSended)}
                    {typeOfMessageSent(message, index, classNameForAnimation, colorBackground, textForJSX)}
                    {valueIndexMessageDate(index)}
                </>
            )
        }
    }

    const fetchMoreMessages = () => {
        let positionOfMessage = conversationSelected.messages[lengthOfMessagesConversationSelected]
        let aux = lengthOfMessagesConversationSelected - 25
        if (lengthOfMessagesConversationSelected >= 25) {
            lengthOfMessagesConversationSelected = aux
            setHasMoreMessagesConversationSelected(true)
        } else {
            lengthOfMessagesConversationSelected = 0
            setHasMoreMessagesConversationSelected(false)
        }

        setLengthOfMessagesConversationSelected(lengthOfMessagesConversationSelected)
        positionOfLastMessageOpen = positionOfMessage
        setpositionOfLastMessageOpen(positionOfLastMessageOpen)
    }

    const fetchMoreMessagesInitialPattern = () => {
        if (conversationSelected.messages !== undefined) {
            let lengthMessages = conversationSelected.messages.length

            if (lengthMessages > 25) {
                let aux = lengthMessages - 25

                lengthOfMessagesConversationSelected = aux
                setLengthOfMessagesConversationSelected(lengthOfMessagesConversationSelected)
                setHasMoreMessagesConversationSelected(true)
            } else {
                let aux = lengthMessages - lengthMessages

                lengthOfMessagesConversationSelected = aux
                setLengthOfMessagesConversationSelected(lengthOfMessagesConversationSelected)
                setHasMoreMessagesConversationSelected(false)
            }
        }
    }

    document.getElementById("messagesBodyContact")?.addEventListener("scroll", function () {
        var myDiv = document.getElementById("messagesBodyContact")?.scrollHeight

        if (widthWindow < 1930) {
            if (this.scrollTop < (myDiv !== undefined ? myDiv - 800 : 0)) {
                setButtonScrollBottom(true)
            } else {
                setButtonScrollBottom(false)
            }
        } else if (widthWindow > 1930) {
            if (this.scrollTop < (myDiv !== undefined ? myDiv - 1300 : 0)) {
                setButtonScrollBottom(true)
            } else {
                setButtonScrollBottom(false)
            }
        }
    })

    const executeScrollMobile = () => {
        myRefCardMessageMobile.current && myRefCardMessageMobile.current.scrollIntoView(false)
    }

    //Props
    const updateSetRecording = (prop: boolean) => {
        setRecording(prop)
    }

    const updatesetMicRecprder = (prop: boolean) => {
        setMicRecprder(prop)
    }

    const updateIsActive = (prop: boolean) => {
        setIsActive(prop)
    }

    const updateSetStatusClose = (prop: boolean) => {
        setStatusClose(prop)
    }

    const updateSetSendFileAudio = (prop: boolean) => {
        setSendFileAudio(prop)
    }

    const updateVideoPreviewDialog = (prop: boolean) => {
        videoPreviewDialog = prop
        setVideoPreviewDialog(videoPreviewDialog)
    }

    const upateVideoDialog = (prop: boolean) => {
        videoDialog = prop
        setVideoDialog(videoDialog)
    }

    const updateSendFile = (prop: React.SetStateAction<AttachmentInterface[]>) => {
        setSendFileImageAndVideo(prop)
    }

    const updateSendFileDocument = (prop: React.SetStateAction<AttachmentInterface[]>) => {
        setFileSendDocument(prop)
    }

    const setNameClassAvatarDialogProp = (prop: React.SetStateAction<string>) => {
        setNameClassAvatarDialog(prop)
    }

    const updateVideoFilePath = (prop: any) => {
        setVideoPath(prop)
    }

    const setDefaultMessageItemProp = (prop: React.SetStateAction<any>) => {
        defaultMessageItem = prop
        setDefaultMessageItem(defaultMessageItem)
        updateInput()
    }

    const updateInput = () => {
        const inputTextSendMessage = defaultMessageItem.text
        setInputTextSendMessage(inputTextSendMessage)

        const editElementDesktop = document.getElementById("w-textAreaDesktop")
        if (editElementDesktop) {
            editElementDesktop.innerHTML = inputTextSendMessage
        }
    }

    const updateConfirmUploadFileDialog = (prop: boolean) => {
        confirmUploadFileDialog = prop
        setConfirmUploadFileDialog(confirmUploadFileDialog)
    }

    const roadMapSelected = (prop: RoadMapInterface) => {
        roadMapSelectVisualization = prop
        setRoadMapSelectVisualization(roadMapSelectVisualization)
    }

    const setOpenModalVisualizationRoudMapProp = (prop: boolean) => {
        openModalVisualizationRoudMap = prop
        setOpenModalVisualizationRoudMap(openModalVisualizationRoudMap)
    }

    const setSendDefaultMessageDialogProp = (prop: boolean) => {
        sendDefaulMessageDialog = prop
        setSendDefaultMessageDialog(sendDefaulMessageDialog)
    }

    const setCompaniesOpportunityProp = (prop: React.SetStateAction<OpportunityInterface[]>) => {
        setCompaniesOpportunity(prop)
    }

    const setCompanyDialog = (prop: React.SetStateAction<boolean>) => {
        setEditCompanyDialog(prop)
    }

    //FUNCTION PROPS REPLY MESSAGE
    const handleSetReplyMessage = () => {
        setSelectReplyMessageChat({} as MessagesWhatsappConversation)
    }

    //FUNCTION PROPS INFORMATION SELLER DIALOG
    const handleSetNameClassAvatarDialogSeller = (e: string) => {
        setNameClassAvatarDialogSeller(e)
    }

    //FUNCTION PROPS CHAT TAB VIEW
    const handleColorSelectedCard = (e: WhatsappConversationInterface) => {
        colorSelectedCard(e)
    }

    //FUNCTIONS PROPS CHAT TAB VIEW
    const handleUpdateConversationSelected = (contactChat: WhatsappConversationInterface) => {
        allMessages(contactChat.id)
        conversationSelected = contactChat
        setConversationSelected(conversationSelected)
        executeScroll()
        executeScrollMobile()
    }

    const handleResponsibleContact = (contactChat: WhatsappConversationInterface) => {
        responsibleContact = contactChat
        setResponsibleContact(responsibleContact)
    }

    const handleInformationAboutSellerWhoResponded = (contactChat: UserInterface) => {
        informationAboutSellerWhoResponded = contactChat
        setInformationAboutSellerWhoResponded(informationAboutSellerWhoResponded)
    }

    const handleContextMenuConversationSelected = (contactChat: WhatsappConversationInterface) => {
        contextMenuConversationSelected = contactChat
        setContextMenuConversationSelected(contextMenuConversationSelected)
    }

    const handleExistingCompany = (item: CompanyInterface) => {
        setExistingCompany(item)
    }

    //FUNCTION PROPS CHAT MOBILE
    const handleIdSearchMessageDiv = (chatMessagesSearch: string) => {
        idSearchMessageDiv = chatMessagesSearch
        setIdSearchMessageDiv(idSearchMessageDiv)
    }

    async function openClientDialog() {
        opportunities = []
        setOpportunities(opportunities)
        if (conversationSelected?.contact?.company_id !== undefined && conversationSelected?.contact?.company_id !== null) {
            if (opportunities.length === 0) {
                allOpportunities(conversationSelected?.contact?.company_id)
            }
        } else {
            showProfileClient(conversationSelected)
            setNameClassAvatarDialog("dialogShowClientInformation")
            setNameClassAvatarDialogSeller("noShowInformationAboutSellerWhoResponded")
        }
    }

    async function closeClientDialog() {
        setNameClassAvatarDialog("noDialogShowClientInformation")
        setCompaniesOpportunity([])
    }

    const handleUpdateContact = (item: any) => {
        setFormContact(item)
    }

    const handleUpdateFieldEmail = (item: any) => {
        setNewEmailField(item)
    }

    const handleUpdateFieldPhone = (item: any) => {
        setNewPhoneField(item)
    }

    /* Footers */
    const confirmLocalUploadFileFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={() => setUploadLocalDocument(false)} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingSendFile} onClick={onSendLocalDocument} />
        </>
    )

    const confirmRemoveUser = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={() => setSendDefaultMessageDialog(false)} />
            <Button
                label="Confirmar"
                icon="pi pi-check"
                className="p-button-success p-button-text"
                loading={loadingRemoveResponsible}
                onClick={() => onChangeResponsible(null, responsibleContact)}
            />
        </>
    )

    const confirmArchiveMessage = (
        <>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-danger p-button-text"
                onClick={() => {
                    setArchiveConversationDialog(false)
                    setUnArchiveConversationDialog(false)
                }}
            />
            <Button
                label="Confirmar"
                icon="pi pi-check"
                className="p-button-success p-button-text"
                loading={loadingArchiveConversation}
                onClick={archiveDesarchiveConversation}
            />
        </>
    )

    const uploadFileFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={() => setVideoPreviewDialog(false)} />
            <Button
                label="Enviar"
                icon="pi pi-check"
                className="p-button-success p-button-text"
                loading={loadingSendFile}
                onClick={() => {
                    onUploadMediaFile()
                }}
            />
        </>
    )

    const uploadFileDocumentsFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={() => setConfirmUploadFileDialog(false)} />
            <Button
                label="Enviar"
                icon="pi pi-check"
                className="p-button-success p-button-text"
                loading={loadingSendFile}
                onClick={() => {
                    onUploadMedianoTextFile()
                }}
            />
        </>
    )

    const imagePreviewFooter = (
        <>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-danger p-button-text"
                onClick={() => {
                    setImagePreviewDialog(false)
                }}
            />
            <Button
                label="Enviar"
                icon="pi pi-check"
                className="p-button-success p-button-text"
                loading={loadingSendFile}
                onClick={() => onUploadMediaFile()}
            />
        </>
    )

    const imageLocalPreviewFooter = (
        <>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-danger p-button-text"
                onClick={() => {
                    setImageNewPreviewDialog(false)
                    setImagesFilePath([])
                }}
            />
            <Button label="Enviar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingSendFile} onClick={onSendLocalImage} />
        </>
    )

    const changeResponsibleFooter = () => {
        return (
            <>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={() => setChangeResponsible(false)} />
                <Button
                    label="Confirmar"
                    loading={loadingChangeResponsible}
                    icon="pi pi-check"
                    className="p-button-success p-button-text"
                    onClick={() => {
                        onChangeResponsible(editResponsibleContact, responsibleContact)
                    }}
                />
            </>
        )
    }

    const videoLocalPreviewFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text " onClick={() => setVideoNewPreviewDialog(false)} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingSendFile} onClick={onSendLocalVideo} />
        </>
    )

    const ordenationChat = (conversationsAux: WhatsappConversationInterface[]) => {
        return conversationsAux.sort((a, b) => {
            const lastMessageA = a.messages?.[a.messages.length - 1]
            const lastMessageB = b.messages?.[b.messages.length - 1]

            if (lastMessageA && lastMessageB) {
                const dateA = new Date(lastMessageA.created_at).getTime()
                const dateB = new Date(lastMessageB.created_at).getTime()

                return dateB - dateA
            } else if (lastMessageA) {
                return -1
            } else if (lastMessageB) {
                return 1
            }

            return 0
        })
    }

    const defaultMessageShortcut = (text: string) => {
        auxDefaultMessages = defaultMessages
        setAuxDefaultMessages(auxDefaultMessages)

        if (text.length === 1 && text === "/") {
            setRendererDefaultMessageShortcut(true)
        } else {
            const nameSearch = text.substring(1).toLowerCase()
            const auxFilterDefault = auxDefaultMessages.filter((aux) => aux.name.toLowerCase().includes(nameSearch))
            auxDefaultMessages = auxFilterDefault
            setAuxDefaultMessages(auxDefaultMessages)
            setRendererDefaultMessageShortcut(true)
        }
    }

    const textHttpsToIframe = (text: string) => {
        const stringHttps = text.replaceAll("<br />", " ")
        const myArray = stringHttps.split(" ")
        const indexLink = myArray.findIndex((string) => string.includes("https"))
        return myArray[indexLink]
    }

    const transformTextLink = (text: string) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g

        return text.replace(
            urlRegex,
            (url) => `
            <a href="${url}" target="_blank" style="font-size: 1.1rem; text-rendering: optimizeLegibility; max-width: 45vw; margin-right: 0.2rem; word-break: normal; color: #5DA6F8; cursor: pointer">
                ${url}
            </a>`
        )
    }

    const createdMessage = (data: any) => {
        setDataCreatedMessageSocket(data)
    }

    const whatsappConversationResponsible = (data: any) => {
        dataUpdateResponsible = data
        setDataUpdateResponsible(data)
    }

    const footerSendRoadMap = () => (
        <div>
            <Button
                label="Enviar"
                onClick={() => {
                    setLoadingSendRoadMap(true)
                    onSendRoadMap(roadMapSelectVisualization)
                }}
                loading={loadingSendRoadMap}
            />
        </div>
    )

    const sendMessageAgainFooter = () => {
        return (
            <>
                <Button label="Não" icon="pi pi-times" className="p-button-text  " onClick={() => setSendMessageAgain(false)} style={{ color: "red" }} />
                <Button
                    loading={loadingSendFile}
                    label="Sim"
                    icon="pi pi-check"
                    className="p-button-text"
                    style={{ color: "green" }}
                    onClick={() => {
                        setLoadingSendFile(true)
                        sendMessageAgainFunction(messageFailedSendAgain)
                    }}
                />
            </>
        )
    }

    //UseEffect
    useEffect(() => {
        if (!isEmptyObj(positionOfLastMessageOpen)) {
            window.location.href = `#${positionOfLastMessageOpen.id}`
        }
    }, [positionOfLastMessageOpen])

    useEffect(() => {
        if (widthWindow > 430) {
            setClassNameinputTextSendMessageOnDesktop("sendTextInputMessage")
        }
        setSkeletonLoading(true)
        chatsInProgress()
        allDefaultMessages()
        onPermissionRoute()
        allSellers()
        allConversations()

        if (localStorage.getItem("@esquadros:user") !== null) {
            dataLocalStorage = JSON.parse(localStorage.getItem("@esquadros:user") || "") || [] || {}

            if (dataLocalStorage) {
                profileUser = dataLocalStorage
                setProfileUser(profileUser)
            }
        }

        document.addEventListener("click", function () {
            setShow(false)
        })
    }, [])

    useEffect(() => {
        atualHeightInputMessage = document.getElementById("sendTextInputMessage")?.clientHeight
    }, [atualHeightInputMessage])

    useEffect(() => {
        if (!isEmptyObj(selectReplyMessageChat)) {
            setSelectReplyMessageChat({} as MessagesWhatsappConversation)
        }

        setInformationDetailMessageChat({} as MessagesWhatsappConversation)
        setInformationDetailMessageVisualition("noViewInformationMessage")
        // setTimeout(() => {
        //     executeScroll()
        // }, 100)
    }, [conversationSelected, openModalVisualizationRoudMap, chatDialogMobile])

    useEffect(() => {
        atualHeightInputMessage = document.getElementById("sendTextInputMessage")?.clientHeight

        window.addEventListener("resize", function () {
            widthWindow = window.innerWidth
            windowWidth(widthWindow)
        })

        windowWidth(widthWindow)
    }, [widthWindow, heightWindow])

    useEffect(() => {
        executeScroll()
        executeScrollMobile()
        setExecuteScrollUseEffect(false)
        setSkeletonLoadingMessages(false)
    }, [executeScrolluseEffect])

    //This effect updates or adds the socket return message
    useEffect(() => {
        let conversationsAux = [...conversations]
        let conversationSelectedAux = { ...conversationSelected }

        let indexConversations = conversations.findIndex(
            (conversation: WhatsappConversationInterface) => conversation.id === dataCreatedMessageSocket.whatsapp_conversation_id
        )

        if (indexConversations >= 0) {
            var aux = true

            conversationsAux[indexConversations].messages.map((message, index) => {
                if (message.id === dataCreatedMessageSocket.id) {
                    conversationsAux[indexConversations].messages[index] = dataCreatedMessageSocket
                    aux = false
                    return
                }
            })

            if (aux) {
                conversationsAux[indexConversations].messages.push(dataCreatedMessageSocket)
                if (!isEmptyObj(conversationSelectedAux) && conversationsAux[indexConversations].id === conversationSelectedAux.id) {
                    conversationSelectedAux = conversationsAux[indexConversations]
                }

                setConversationSelected(conversationSelectedAux)
                if (dataCreatedMessageSocket.whatsapp_conversation_id === conversationSelected.id) {
                    setTimeout(() => {
                        executeScroll()
                        executeScrollMobile()
                    }, 300)
                }
            }
        }

        if (dataCreatedMessageSocket.whatsapp_conversation_id === conversationSelected.id) {
            updateTheNewMessageForRead()
        }

        conversations = ordenationChat(conversationsAux)
        setConversations(conversations)

        auxConversations = conversations
        setAuxConversations(auxConversations)

        tabFilterSetFunction()
        // dateLastMessage(conversations[indexConversations])
    }, [dataCreatedMessageSocket])

    //This effect updates responsible of the conversation
    useEffect(() => {
        if (!isEmptyObj(dataUpdateResponsible)) {
            let indexConversations = conversations.findIndex((conversation: WhatsappConversationInterface) => conversation.id === dataUpdateResponsible.id)
            let conversationsAux = [...conversations]

            if (indexConversations >= 0) {
                conversations.forEach((contact, index) => {
                    if (contact.id === dataUpdateResponsible.id) {
                        conversationsAux[index] = dataUpdateResponsible
                    }
                })
            } else {
                conversationsAux.push(dataUpdateResponsible)
            }

            if (dataCreatedMessageSocket.whatsapp_conversation_id === conversationSelected.id) {
                setTimeout(() => {
                    executeScroll()
                    executeScrollMobile()
                }, 300)
            }

            conversations = ordenationChat(conversationsAux)
            setConversations(conversations)

            for (const conversation of conversations) {
                if (conversation.id === conversationSelected.id) {
                    setConversationSelected(conversation)
                    break
                }
            }

            auxConversations = ordenationChat(conversationsAux)
            setAuxConversations(auxConversations)
            tabFilterSetFunction()
        }
    }, [dataUpdateResponsible])

    useEffect(() => {
        socket.on("whatsapp_conversation", whatsappConversationResponsible)
        socket.on("message_update", createdMessage)

        return () => {
            socket.off("whatsapp_conversation")
            socket.off("message_update")
        }
    }, [])

    return (
        <App>
            <div className="attendanceAllPage">
                <Toast ref={toast} />

                <div style={{ display: "none" }}>
                    <CrmRecords />
                </div>

                <div className="desktop-view">
                    <div className="desktop p-grid crud-demo">
                        <div className="p-col-12 p-md-12 p-lg-12">
                            {/* CHAT MOBILE */}
                            <div className={`${chatDialogMobile}`}>
                                <ChatConversationMobile
                                    conversationSelected={conversationSelected}
                                    selectReplyMessageChat={selectReplyMessageChat}
                                    conversations={conversations}
                                    chatDialogMobile={chatDialogMobile}
                                    onSearchMessageChatMobile={onSearchMessageChatMobile}
                                    viewButtonsSearchMessageUpOrDown={viewButtonsSearchMessageUpOrDown}
                                    countIndexSearchMessageFilter={countIndexSearchMessageFilter}
                                    chatMessagesSearch={chatMessagesSearch}
                                    inputTextSearchMessagesChat={inputTextSearchMessagesChat}
                                    inputTextSendMessage={inputTextSendMessage}
                                    verification24={verification24}
                                    marginTopWindowInput={marginTopWindowInput}
                                    statusClose={statusClose}
                                    isActive={isActive}
                                    indexSended={indexSended}
                                    idReplyMessage={idReplyMessage}
                                    profileUser={profileUser}
                                    widthWindow={widthWindow}
                                    idMessageForOnMouseOver={idMessageForOnMouseOver}
                                    updatesetMicRecprder={updatesetMicRecprder}
                                    updateSetRecording={updateSetRecording}
                                    updateIsActive={updateIsActive}
                                    updateSetStatusClose={updateSetStatusClose}
                                    updateSetSendFileAudio={updateSetSendFileAudio}
                                    onSendAudio={onSendAudio}
                                    toastTrue={toastTrue}
                                    replyMessageUse={replyMessageUse}
                                    myRefCardMessageMobile={myRefCardMessageMobile}
                                    downScrollSearch={downScrollSearch}
                                    handleIdSearchMessageDiv={handleIdSearchMessageDiv}
                                    upScrollSearch={upScrollSearch}
                                    handleKeyPressSearchMessage={handleKeyPressSearchMessage}
                                    executeScroll={executeScroll}
                                    setChatMessagesSearch={() => setChatMessagesSearch([])}
                                    executeScrollMobile={() => executeScrollMobile()}
                                    setOnSearchMessageChatMobile={(e: boolean) => setOnSearchMessageChatMobile(e)}
                                    setChatDialogMobile={(e: string) => setChatDialogMobile(e)}
                                    setScreenMobile={(e: string) => setScreenMobile(e)}
                                    setInputTextSearchMessagesChat={(e: string) => setInputTextSearchMessagesChat(e)}
                                    chatMessageFilter={(x: any, y: any) => chatMessageFilter(x, y)}
                                    setViewButtonsSearchMessageUpOrDown={(e: boolean) => setViewButtonsSearchMessageUpOrDown(e)}
                                    valueIndexMessageDate={(e: number) => valueIndexMessageDate(e)}
                                    setSelectReplyMessageChat={(e: MessagesWhatsappConversation) => setSelectReplyMessageChat(e)}
                                    dividerDataInChatMessages={(x: MessagesWhatsappConversation, y: any, z: number) => dividerDataInChatMessages(x, y, z)}
                                    setUploadFromFilesDialog={(e: boolean) => setUploadFromFilesDialog(e)}
                                    setInputTextSendMessage={(e: any) => setInputTextSendMessage(e)}
                                    handleKeyPress={(e: any) => handleKeyPress(e)}
                                    onSendMessagetoContact={(e: any) => onSendMessagetoContact(e)}
                                    setIdMessageForOnMouseOver={(e: string) => setIdMessageForOnMouseOver(e)}
                                    setViewImageMessage={(e: boolean) => setViewImageMessage(e)}
                                    setSelectedViewImageMessage={(e: MessagesWhatsappConversation) => setSelectedViewImageMessage(e)}
                                    setInformationDetailMessageVisualition={(e: any) => setInformationDetailMessageVisualition(e)}
                                    setInformationDetailMessageChat={(e: any) => setInformationDetailMessageChat(e)}
                                    messageReadConfirmation={(e: MessagesWhatsappConversation) => messageReadConfirmation(e)}
                                    setIdReplyMessage={(e: string) => setIdReplyMessage(e)}
                                    setReplyMessageUse={(e: MessagesWhatsappConversation) => setReplyMessageUse(e)}
                                    setImageUploadDialog={(e: boolean) => setImageUploadDialog(e)}
                                />
                            </div>

                            {/* CHAT DESKTOP  ^ */}
                            <div className="desktopPageAttendance">
                                <div className="contentDesktopPage p-d-flex p-jc-center p-ai-center">
                                    <div
                                        style={{
                                            backgroundColor: "#ffff",
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "0.5rem",
                                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                        }}
                                        className="p-d-flex"
                                    >
                                        <div className="leftSideOfPage">
                                            {skeletonLoading ? (
                                                <div className="leftSkeleton">
                                                    <Skeleton width="100%" height="50px" />
                                                    <br />
                                                    <Skeleton width="100%" height="55px" />
                                                    <br />
                                                    <Skeleton width="100%" height="calc(100vh - 298px)" />
                                                </div>
                                            ) : (
                                                <div className="" style={{ border: "none", display: "flex", flexDirection: "column", height: "100%" }}>
                                                    <div
                                                        className="p-d-flex p-jc-center p-ai-center"
                                                        style={{ height: "10%", backgroundColor: "#ffffff", padding: "1rem", borderRadius: "0.5rem" }}
                                                    >
                                                        <div
                                                            className="p-d-flex p-jc-between p-ai-center"
                                                            style={{
                                                                marginLeft: `${widthWindow <= 414 ? "1rem" : ""}`,
                                                                marginTop: "0rem",
                                                                width: `${widthWindow <= 414 ? "100%" : "100%"}`,
                                                            }}
                                                        >
                                                            <div className="p-d-flex p-ai-center">
                                                                <Avatar
                                                                    icon={profileUser.avatar_url === null ? "pi pi-user" : ""}
                                                                    image={profileUser.avatar_url}
                                                                    className="p-mr-2"
                                                                    shape="circle"
                                                                    style={{
                                                                        boxShadow:
                                                                            "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",

                                                                        width: `${widthWindow <= 414 ? "3rem" : "5vh"}`,
                                                                        height: `${widthWindow <= 414 ? "3rem" : "5vh"}`,
                                                                    }}
                                                                    onClick={() => {
                                                                        setNameClassAvatarDialogSeller("showInformationAboutSellerWhoResponded")
                                                                        informationAboutSellerWhoResponded = profileUser
                                                                        setInformationAboutSellerWhoResponded(informationAboutSellerWhoResponded)
                                                                        setViewEditInformationAboutSeller(true)
                                                                    }}
                                                                />
                                                                <p
                                                                    style={{
                                                                        marginLeft: "0.6vw",
                                                                        fontSize: "1.4rem",
                                                                        marginTop: "0.5rem",
                                                                        fontFamily:
                                                                            "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol",
                                                                    }}
                                                                >
                                                                    {profileUser.name}
                                                                </p>
                                                            </div>

                                                            <div className="p-d-flex">
                                                                <Menu model={menuItens} popup ref={menu} id="popup_menu" style={{ width: "18rem" }} />
                                                                <Button
                                                                    icon="pi pi-ellipsis-v"
                                                                    className="p-button-rounded p-button-text"
                                                                    aria-label="Submit"
                                                                    onClick={(event) => menu.current?.toggle(event)}
                                                                    aria-controls="popup_menu"
                                                                    aria-haspopup
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="" style={{ height: "auto" }}>
                                                        <div className="search">
                                                            <span
                                                                className="p-input-icon-left"
                                                                style={{ width: "100%", display: "flex", alignItems: "center" }}
                                                            >
                                                                <i className="pi pi-search" />
                                                                <InputText
                                                                    className="searchInput"
                                                                    id="autofocs"
                                                                    placeholder="Pesquisar Conversa..."
                                                                    value={advanceSearchFilterConversations}
                                                                    onKeyDown={() => {
                                                                        let auxText = advanceSearchFilterConversations
                                                                        setAdvanceSearchFilterConversations(auxText)
                                                                    }}
                                                                    onChange={(e) => {
                                                                        advanceSearchFilterConversations = e.target.value
                                                                        setAdvanceSearchFilterConversations(advanceSearchFilterConversations)
                                                                        filterSearchConversations()
                                                                    }}
                                                                    style={{
                                                                        borderRadius: "0.4rem",
                                                                        border: "none",
                                                                        backgroundColor: "#f0f2f5",
                                                                        position: "relative",
                                                                    }}
                                                                />

                                                                <i
                                                                    data-tip
                                                                    data-for="unreadMessages"
                                                                    className={`pi ${unreadFilter ? "pi-filter-fill" : "pi-filter"}`}
                                                                    style={{ position: "absolute", right: "5rem", cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        unreadFilter = !unreadFilter
                                                                        setUnreadFilter((state) => unreadFilter)
                                                                        filterSearchConversations()
                                                                    }}
                                                                />
                                                                <ReactTooltip className="toolTip" id="unreadMessages" place="right" effect="solid">
                                                                    <p style={{ fontSize: "0.8rem", fontWeight: "400" }}>CONVERSAS PENDENTES</p>
                                                                </ReactTooltip>

                                                                <i
                                                                    data-tip
                                                                    data-for="filterDate"
                                                                    className="pi pi-calendar"
                                                                    style={{ position: "absolute", right: "3rem", cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setDialogFilterDatesConversation(true)
                                                                    }}
                                                                />

                                                                <ReactTooltip className="toolTip" id="filterDate" place="right" effect="solid">
                                                                    <p style={{ fontSize: "0.8rem", fontWeight: "400" }}>ESCOLHA AS DATAS</p>
                                                                </ReactTooltip>

                                                                {!loadingButtonClearFilterDate ? (
                                                                    <i
                                                                        data-tip
                                                                        data-for="cleanFilterDate"
                                                                        className="pi pi-stop"
                                                                        style={{ position: "absolute", right: "1rem", cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setLoadingButtonClearFilterDate(true)
                                                                            conversations = []
                                                                            setAuxConversations(conversations)
                                                                            setConversations(conversations)
                                                                            setConversationsFilter(conversations)
                                                                            allConversations()
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <Button
                                                                        type="button"
                                                                        disabled={true}
                                                                        loading={loadingButtonClearFilterDate}
                                                                        style={{ position: "absolute", right: "0.5rem" }}
                                                                        className="p-button-success p-button-text"
                                                                    />
                                                                )}

                                                                <ReactTooltip className="toolTip" id="cleanFilterDate" place="right" effect="solid">
                                                                    <p style={{ fontSize: "0.8rem", fontWeight: "400" }}>Limpar Filto</p>
                                                                </ReactTooltip>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div style={{ height: "100%" }}>
                                                        {!archiveConversation && (
                                                            <TabView
                                                                scrollable
                                                                activeIndex={activeIndex1}
                                                                onTabChange={(e) => {
                                                                    setActiveIndex1(e.index)
                                                                }}
                                                            >
                                                                <TabPanel header="Minhas Conversas" headerTemplate={tabMyConversations}>
                                                                    {tabViewBody(activeIndex1)}
                                                                </TabPanel>

                                                                <TabPanel header="Sem Atendimento" headerTemplate={tabWithoutResponsible}>
                                                                    {tabViewBody(activeIndex1)}
                                                                </TabPanel>

                                                                {permission && (
                                                                    <TabPanel header="Todas as Conversas" headerTemplate={tabAllMyMessages}>
                                                                        {tabViewBody(activeIndex1)}
                                                                    </TabPanel>
                                                                )}
                                                            </TabView>
                                                        )}

                                                        {archiveConversation && (
                                                            <TabView
                                                                scrollable
                                                                activeIndex={activeIndex1}
                                                                onTabChange={(e) => {
                                                                    setActiveIndex1(e.index)
                                                                }}
                                                            >
                                                                <TabPanel header="Minhas Conversas (Arquivadas)" headerTemplate={tabMyConversations}>
                                                                    {tabViewBody(activeIndex1)}
                                                                </TabPanel>

                                                                <TabPanel header="Sem Atendimento (Arquivadas)" headerTemplate={tabWithoutResponsible}>
                                                                    {tabViewBody(activeIndex1)}
                                                                </TabPanel>

                                                                {permission && (
                                                                    <TabPanel header="Todas as Conversas (Arquivadas)" headerTemplate={tabAllMyMessages}>
                                                                        {tabViewBody(activeIndex1)}
                                                                    </TabPanel>
                                                                )}
                                                            </TabView>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div style={{ width: "75%", height: "100%" }}>
                                            {skeletonLoading || skeletonLoadingMessages ? (
                                                <div style={{ marginLeft: "1rem", marginTop: "1rem" }} className="rightSkeletonPage">
                                                    {skeletonLoading && (
                                                        <>
                                                            <Skeleton width="99%" height="50px" />
                                                            <br />
                                                            <Skeleton width="99%" height="calc(100vh - 225px)" />
                                                        </>
                                                    )}

                                                    {skeletonLoadingMessages && (
                                                        <>
                                                            <Skeleton width="99%" height="calc(100vh - 150px)" />
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <>
                                                    {!isEmptyObj(conversationSelected) && (
                                                        <div className="rightMessageScreen" style={{ right: "0", width: "100%" }}>
                                                            {/* =========== DIALOG DIV COMPANY */}
                                                            <div className={`${nameClassAvatarDialog}`}>
                                                                <ClientInformation
                                                                    conversationSelected={conversationSelected}
                                                                    companiesOpportunity={companiesOpportunity}
                                                                    setNameClassAvatarDialog={setNameClassAvatarDialogProp}
                                                                    setCompaniesOpportunity={setCompaniesOpportunityProp}
                                                                    UpdateCompany={setCompanyDialog}
                                                                    setOpportunityCreationDialog={OpportunityCreation}
                                                                />
                                                            </div>
                                                            {/* =========== DIALOG DIV COMPANY */}

                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    height: "calc(100vh - 126px)",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                }}
                                                            >
                                                                {/* HEADER */}
                                                                <HeaderConversationAttendance
                                                                    heightWindow={heightWindow}
                                                                    conversationSelected={conversationSelected}
                                                                    widthWindow={widthWindow}
                                                                    nameClassAvatarDialog={nameClassAvatarDialog}
                                                                    viewSearchMessagesButton={viewSearchMessagesButton}
                                                                    viewButtonsSearchMessageUpOrDown={viewButtonsSearchMessageUpOrDown}
                                                                    chatMessagesSearch={chatMessagesSearch}
                                                                    countIndexSearchMessageFilter={countIndexSearchMessageFilter}
                                                                    idSearchMessageDiv={idSearchMessageDiv}
                                                                    inputTextSearchMessagesChat={inputTextSearchMessagesChat}
                                                                    downScrollSearch={() => downScrollSearch()}
                                                                    openClientDialog={() => openClientDialog()}
                                                                    closeClientDialog={() => closeClientDialog()}
                                                                    upScrollSearch={() => upScrollSearch()}
                                                                    handleKeyPressSearchMessage={(e: any) => handleKeyPressSearchMessage(e)}
                                                                    setOpportunityCreationDialog={OpportunityCreation}
                                                                    setIdSearchMessageDiv={(e: any) => {
                                                                        idSearchMessageDiv = e
                                                                        setIdSearchMessageDiv(idSearchMessageDiv)
                                                                    }}
                                                                    setInputTextSearchMessagesChat={(e: any) => {
                                                                        inputTextSearchMessagesChat = e
                                                                        setInputTextSearchMessagesChat(inputTextSearchMessagesChat)
                                                                    }}
                                                                    chatMessageFilter={(e: any, y: any) => chatMessageFilter(e, y)}
                                                                    setViewSearchMessagesButton={(e: any) => {
                                                                        setViewSearchMessagesButton(e)
                                                                    }}
                                                                    setCountIndexSearchMessageFilter={(e: any) => {
                                                                        countIndexSearchMessageFilter = e
                                                                        setCountIndexSearchMessageFilter(countIndexSearchMessageFilter)
                                                                    }}
                                                                    setChatMessagesSearch={(e: any) => {
                                                                        chatMessagesSearch = e
                                                                        setChatMessagesSearch(chatMessagesSearch)
                                                                    }}
                                                                />

                                                                {/* BODY */}
                                                                <div className="messagesBodyContact" id="messagesBodyContact">
                                                                    {buttonScrollBottom && (
                                                                        <Button
                                                                            icon="pi pi-angle-double-down"
                                                                            className="p-button-rounded p-button-secondary"
                                                                            id="scroll"
                                                                            style={{
                                                                                position: "absolute",
                                                                                bottom: `${widthWindow > 1281 ? "calc(100vh - 91vh)" : "13vh"}`,
                                                                                right: "calc(100vw - 97.5vw)",
                                                                                opacity: "0.3",
                                                                            }}
                                                                            onClick={() => {
                                                                                executeScroll()
                                                                            }}
                                                                        />
                                                                    )}

                                                                    {hasMoreMessagesConversationSelected && conversationSelected.messages.length > 25 ? (
                                                                        <div className="buttonFetchMoreMessages">
                                                                            <Button
                                                                                style={{ backgroundColor: "rgba(41, 50, 65, 0.4)", border: "none" }}
                                                                                label="Visualizar mais..."
                                                                                className=" p-button-rounded p-button-secondary"
                                                                                loading={loadingFetchMoreMessages}
                                                                                onClick={() => {
                                                                                    fetchMoreMessages()
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <div></div>
                                                                    )}

                                                                    <div>
                                                                        <div
                                                                            style={{
                                                                                marginBottom: "-1rem",
                                                                                marginTop: "-0.5rem",
                                                                            }}
                                                                        >
                                                                            <br />
                                                                            <div
                                                                                style={{
                                                                                    width: "100%",
                                                                                    height: "auto",
                                                                                    backgroundColor: "rgba(228, 229, 231, 0.2)",
                                                                                    borderRadius: "100rem",
                                                                                    fontWeight: "500",
                                                                                }}
                                                                                className="p-d-flex p-jc-center"
                                                                            >
                                                                                {conversationSelected.messages.length > 0 && (
                                                                                    <Moment
                                                                                        style={{
                                                                                            userSelect: "none",
                                                                                            color: "rgba(141, 142, 143, 0.7)",
                                                                                        }}
                                                                                        format="DD/MM/YYYY"
                                                                                    >
                                                                                        {conversationSelected !== null
                                                                                            ? conversationSelected.messages.length > 0
                                                                                                ? conversationSelected.messages[0] !== null
                                                                                                    ? conversationSelected.messages[0].created_at
                                                                                                    : ""
                                                                                                : ""
                                                                                            : ""}
                                                                                    </Moment>
                                                                                )}
                                                                            </div>
                                                                            <br />
                                                                        </div>

                                                                        {conversationSelected.messages.map((message, index) => {
                                                                            setTimeout(() => {
                                                                                setLoadingFetchMoreMessages(false)
                                                                            }, 500)
                                                                            return <div key={index}>{itemTemplateDataScrollerChat(message)}</div>
                                                                        })}
                                                                    </div>
                                                                </div>

                                                                {/* FOOTER */}
                                                                {rendererDefaultMessageShortcut ? (
                                                                    <div className="defaultMessageShortcutAttendance">
                                                                        {auxDefaultMessages.length > 0 ? (
                                                                            <div>
                                                                                {auxDefaultMessages.map((defaultMessage, index) => (
                                                                                    <a
                                                                                        onClick={() => {
                                                                                            inputTextSendMessage = defaultMessage.text
                                                                                            setInputTextSendMessage(inputTextSendMessage)

                                                                                            var editElementDesktop =
                                                                                                document.getElementById("w-textAreaDesktop")
                                                                                            if (editElementDesktop !== null) {
                                                                                                editElementDesktop.innerHTML = defaultMessage.text
                                                                                            }

                                                                                            setRendererDefaultMessageShortcut(false)
                                                                                        }}
                                                                                    >
                                                                                        <div key={index} className="messageShortcutAttendance">
                                                                                            <p>
                                                                                                <span style={{ fontWeight: "600" }}>
                                                                                                    {defaultMessage.name} -{" "}
                                                                                                </span>
                                                                                                {defaultMessage.text}
                                                                                            </p>
                                                                                        </div>
                                                                                    </a>
                                                                                ))}
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                {skeletonLoadingDefaultMessage ? (
                                                                                    <div style={{ padding: "1rem", zIndex: "-1" }}>
                                                                                        <Skeleton
                                                                                            className="mb-2"
                                                                                            borderRadius="16px"
                                                                                            width="100%"
                                                                                            height="10rem"
                                                                                        ></Skeleton>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div> Mensagem padrão não encontrada</div>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <div className="sendMessage p-d-flex p-ai-center">
                                                                    <div style={{ width: "100%" }}>
                                                                        {!isEmptyObj(selectReplyMessageChat) ? (
                                                                            <ReplyMessageChat
                                                                                selectReplyMessageChat={selectReplyMessageChat}
                                                                                profileUser={profileUser}
                                                                                setSelectReplyMessageChat={handleSetReplyMessage}
                                                                            />
                                                                        ) : (
                                                                            ""
                                                                        )}

                                                                        <div className="p-d-flex p-jc-center p-ai-center" style={{ width: "100%" }}>
                                                                            <div className="p-d-flex p-ai-center" style={{ width: "100%", height: "auto" }}>
                                                                                {statusClose === false && !verification24 && (
                                                                                    <>
                                                                                        {conversationSelected.id !== "" &&
                                                                                            conversationSelected.messages.length !== 0 && (
                                                                                                <Button
                                                                                                    icon="pi pi-paperclip"
                                                                                                    className=" p-button-sm p-button-rounded p-button-text p-d-flex"
                                                                                                    style={{
                                                                                                        position: "relative",
                                                                                                        height: "2rem",
                                                                                                        width: "2rem",
                                                                                                        marginLeft: "1rem",
                                                                                                        color: "rgb(84, 101, 111)",
                                                                                                    }}
                                                                                                    onClick={() => setUploadFromFilesDialog(true)}
                                                                                                />
                                                                                            )}

                                                                                        <div className="speeddial-tooltip-demo">
                                                                                            <Tooltip target=".speeddial-tooltip-demo .speeddial-right .p-speeddial-action" />

                                                                                            <Button
                                                                                                icon="pi pi-globe"
                                                                                                className="p-button-rounded p-button-text p-mr-2"
                                                                                                onClick={() => setImageUploadDialog(true)}
                                                                                                style={{
                                                                                                    color: "rgb(84, 101, 111)",
                                                                                                }}
                                                                                            ></Button>
                                                                                        </div>

                                                                                        <div style={{ width: "100%" }}>
                                                                                            <div id="w-input-container" style={{ position: "relative" }}>
                                                                                                <div className="w-input-text-group">
                                                                                                    <div
                                                                                                        id="w-textAreaDesktop"
                                                                                                        contentEditable="true"
                                                                                                        suppressContentEditableWarning={true}
                                                                                                        spellCheck={true}
                                                                                                        onInput={(e: any) => {
                                                                                                            setInputTextSendMessage(e.currentTarget.textContent)
                                                                                                            inputSendFirtsMessage = e.currentTarget.textContent
                                                                                                            setInputSendFirtsMessage(inputSendFirtsMessage)

                                                                                                            if (
                                                                                                                e.currentTarget.textContent.substring(0, 1) ===
                                                                                                                "/"
                                                                                                            ) {
                                                                                                                defaultMessageShortcut(
                                                                                                                    e.currentTarget.textContent
                                                                                                                )
                                                                                                            } else {
                                                                                                                setRendererDefaultMessageShortcut(false)
                                                                                                            }
                                                                                                        }}
                                                                                                        onKeyDown={(e: any) => {
                                                                                                            if (
                                                                                                                e.currentTarget.textContent.substring(0, 1) ===
                                                                                                                "/"
                                                                                                            ) {
                                                                                                                handleKeyPressDefaultMessage(e)
                                                                                                            } else {
                                                                                                                handleKeyPress(e)
                                                                                                            }
                                                                                                        }}
                                                                                                    />

                                                                                                    <div className="w-placeholder">Digite uma mensagem...</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {statusClose === false && inputTextSendMessage === "" && (
                                                                                                <div className=" p-button-sm p-button-rounded p-button-text p-d-flex p-ai-center"></div>
                                                                                            )}
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                                {verification24 && (
                                                                                    <>
                                                                                        {conversationSelected.id !== "" &&
                                                                                            conversationSelected.messages.length !== 0 && (
                                                                                                <Button
                                                                                                    icon="pi pi-paperclip"
                                                                                                    className=" p-button-sm p-button-rounded p-button-text p-d-flex"
                                                                                                    style={{
                                                                                                        position: "relative",
                                                                                                        height: "2rem",
                                                                                                        width: "2rem",
                                                                                                        marginLeft: "1rem",
                                                                                                        color: "rgb(84, 101, 111)",
                                                                                                    }}
                                                                                                    onClick={() => setUploadFromFilesDialog(true)}
                                                                                                />
                                                                                            )}

                                                                                        <Button
                                                                                            icon="pi pi-globe"
                                                                                            className="p-button-rounded p-button-text p-mr-2"
                                                                                            onClick={() => {
                                                                                                setImageUploadDialog(true)
                                                                                            }}
                                                                                            style={{
                                                                                                color: "rgb(84, 101, 111)",
                                                                                            }}
                                                                                        ></Button>

                                                                                        {/* ===================================================================== */}
                                                                                        <div style={{ width: "100%" }}>
                                                                                            <div id="w-input-container">
                                                                                                <div className="w-input-text-group">
                                                                                                    <div
                                                                                                        id="w-textAreaDesktop"
                                                                                                        contentEditable="true"
                                                                                                        suppressContentEditableWarning={true}
                                                                                                        spellCheck={true}
                                                                                                        onInput={(e: any) => {
                                                                                                            setInputTextSendMessage(e.currentTarget.textContent)

                                                                                                            if (
                                                                                                                e.currentTarget.textContent.substring(0, 1) ===
                                                                                                                "/"
                                                                                                            ) {
                                                                                                                defaultMessageShortcut(
                                                                                                                    e.currentTarget.textContent
                                                                                                                )
                                                                                                            } else {
                                                                                                                setRendererDefaultMessageShortcut(false)
                                                                                                            }
                                                                                                        }}
                                                                                                        onKeyDown={(e: any) => {
                                                                                                            if (
                                                                                                                e.currentTarget.textContent.substring(0, 1) ===
                                                                                                                "/"
                                                                                                            ) {
                                                                                                                handleKeyPressDefaultMessage(e)
                                                                                                            } else {
                                                                                                                handleKeyPress(e)
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                    <div className="w-placeholder">Digite uma mensagem...</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <Button
                                                                                            icon="pi pi-send"
                                                                                            className="p-button-rounded p-button-secondary p-mr-3 p-button-microphone"
                                                                                            onClick={(e) => {
                                                                                                onSendMessagetoContact(inputTextSendMessage, e)
                                                                                                setInputTextSendMessage("")
                                                                                                executeScroll()
                                                                                            }}
                                                                                            style={{
                                                                                                width: "2.2rem",
                                                                                                height: "2.2rem",
                                                                                                boxShadow: "0.03rem 0.3Srem 0.3rem #b0b0b1",
                                                                                                marginLeft: "1rem",
                                                                                            }}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                                &nbsp; &nbsp;
                                                                                {inputTextSendMessage === "" && !verification24 && (
                                                                                    <AudioRecorder
                                                                                        setMicRecprder={updatesetMicRecprder}
                                                                                        setRecording={updateSetRecording}
                                                                                        setIsActive={updateIsActive}
                                                                                        setStatusClose={updateSetStatusClose}
                                                                                        setSendFileAudio={updateSetSendFileAudio}
                                                                                        onSendAudio={onSendAudio}
                                                                                        onNotification={toastTrue}
                                                                                    />
                                                                                )}
                                                                                {inputTextSendMessage !== "" && !verification24 && (
                                                                                    <Button
                                                                                        icon="pi pi-send"
                                                                                        className="p-button-rounded p-button-secondary p-mr-3 p-button-microphone"
                                                                                        onClick={(e) => {
                                                                                            dateLastMessage(conversationSelected)
                                                                                            onSendMessagetoContact(inputTextSendMessage, e)
                                                                                            setInputTextSendMessage("")
                                                                                            executeScroll()
                                                                                        }}
                                                                                        style={{
                                                                                            width: "2.6rem",
                                                                                            height: "2.6rem",
                                                                                            boxShadow: "0.03rem 0.3Srem 0.3rem #b0b0b1",
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {isEmptyObj(conversationSelected) && (
                                                        <div
                                                            className="rightSideEmpty"
                                                            style={{ backgroundColor: "rgb(175, 176, 178)", height: "calc(100vh - 125px)" }}
                                                        >
                                                            <div
                                                                className="p-d-flex p-ai-center p-jc-center"
                                                                style={{
                                                                    height: "calc(100vh - 125px)",
                                                                    backgroundColor: " #f7f7f8",
                                                                    boxShadow: "inset 0 0 0.2em #d7d8db, 0 0 0",
                                                                }}
                                                            >
                                                                <div className="p-col-12 p-md-12 p-lg-12" style={{ margin: "0.8rem" }}>
                                                                    <div className="p-d-flex p-ai-center p-jc-center">
                                                                        <i className="pi pi-desktop" style={{ fontSize: "2em", opacity: "60%" }}></i>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                        <i className="pi pi-globe" style={{ fontSize: "2em", opacity: "60%" }}></i>
                                                                    </div>

                                                                    <br />

                                                                    <div
                                                                        className="p-d-flex p-ai-center p-jc-center"
                                                                        style={{ fontWeight: "600", opacity: "60%", userSelect: "none" }}
                                                                    >
                                                                        CLIQUE EM UM CONTATO PARA ABRIR UMA CONVERSA
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>

                                        {/* =========== DIALOG DIV INFORMATION SELLER*/}
                                        {!isEmptyObj(informationAboutSellerWhoResponded) && (
                                            <div className={`${nameClassAvatarDialogSeller}`}>
                                                <InformationSellerDialog
                                                    informationAboutSellerWhoResponded={informationAboutSellerWhoResponded}
                                                    viewEditInformationAboutSeller={viewEditInformationAboutSeller}
                                                    handleSetNameClassAvatarDialogSeller={handleSetNameClassAvatarDialogSeller}
                                                    handleSetCompaniesOpportunity={() => setCompaniesOpportunity([])}
                                                />
                                            </div>
                                        )}

                                        {/* =========== DIALOG DIV INFORMATION MESSAGE*/}
                                        <div className={`${informationDetailMessageVisualition}`}>
                                            <div className="dialogShowDetailMessageInformation" style={{ padding: "2rem 2rem 2rem 2rem" }}>
                                                <div className="dialogShowDetailMessageInformationScroll">
                                                    <div className="p-d-flex p-jc-end">
                                                        <Button
                                                            icon="pi pi-times"
                                                            className="p-button-rounded p-button-danger p-button-text p-d-flex p-jc-end"
                                                            onClick={() => setInformationDetailMessageVisualition("noViewInformationMessage")}
                                                        />
                                                    </div>

                                                    <br />

                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            height: "auto",
                                                            backgroundColor: "#f7f7f8",
                                                            boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                                                            borderRadius: "0.8rem 0.8rem 0.8rem 0.8rem",
                                                        }}
                                                    >
                                                        <div>
                                                            <div className="p-d-flex p-jc-end" style={{ marginBottom: "0.5rem" }}>
                                                                <>
                                                                    {!isEmptyObj(informationDetailMessageChat) ? (
                                                                        informationDetailMessageChat.status === "received" ? (
                                                                            !isEmptyObj(informationDetailMessageChat) ? (
                                                                                <>
                                                                                    <p
                                                                                        className="p-d-flex p-jc-end"
                                                                                        style={{
                                                                                            marginBottom: "-0.1rem",
                                                                                            fontSize: "0.8rem",
                                                                                            padding: "0.3rem",
                                                                                            userSelect: "none",
                                                                                        }}
                                                                                    >
                                                                                        {conversationSelected.contact !== null
                                                                                            ? conversationSelected.contact.company.name
                                                                                            : conversationSelected.phone_number}
                                                                                    </p>
                                                                                </>
                                                                            ) : (
                                                                                conversationSelected.phone_number
                                                                            )
                                                                        ) : (
                                                                            ""
                                                                        )
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </>
                                                                &nbsp;&nbsp;
                                                                <>
                                                                    {!isEmptyObj(informationDetailMessageChat) ? (
                                                                        informationDetailMessageChat.status !== "received" ? (
                                                                            <>
                                                                                <p
                                                                                    className="p-d-flex p-jc-end"
                                                                                    style={{
                                                                                        marginBottom: "-0.1rem",
                                                                                        fontSize: "0.8rem",
                                                                                        padding: "0.3rem",
                                                                                        userSelect: "none",
                                                                                    }}
                                                                                >
                                                                                    {profileUser.name}
                                                                                </p>
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </>
                                                                &nbsp;&nbsp;
                                                            </div>
                                                            <div className="p-grid p-d-flex p-jc-end" style={{ marginBottom: "1.5rem", marginRight: "1rem" }}>
                                                                <div
                                                                    className="p-d-flex p-flex-column"
                                                                    key="1"
                                                                    style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
                                                                >
                                                                    <div className="p-d-flex">
                                                                        <div className="p-d-flex p-jc-end">
                                                                            <div
                                                                                className="p-d-flex p-jc-end p-ai-center"
                                                                                style={{
                                                                                    backgroundColor: "white",

                                                                                    border: "0.01rem solid #e4e5e7",
                                                                                    borderRadius: "0.5rem 0.5rem 0rem 0.5rem",
                                                                                    boxShadow: "0.04rem 0.04rem 0.06rem #d7d8db",
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className="Container"
                                                                                    style={{
                                                                                        maxWidth: "15rem",
                                                                                        marginBottom: "0.3rem",
                                                                                        marginTop: "0.3rem",
                                                                                        marginLeft: "0.3rem",
                                                                                        marginRight: "0.3rem",
                                                                                        wordBreak: "normal",
                                                                                    }}
                                                                                >
                                                                                    {viewDetailMessageSelected(informationDetailMessageChat)}
                                                                                </div>

                                                                                <div
                                                                                    className="p-d-flex p-as-end"
                                                                                    style={{ fontSize: "0.65rem", userSelect: "none" }}
                                                                                >
                                                                                    <>
                                                                                        <Moment format="HH:mm">
                                                                                            {informationDetailMessageChat.created_at}
                                                                                        </Moment>{" "}
                                                                                        &nbsp;
                                                                                        {messageReadConfirmation(informationDetailMessageChat)} &nbsp;
                                                                                    </>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        &nbsp;
                                                                        {!isEmptyObj(informationDetailMessageChat) ? (
                                                                            informationDetailMessageChat.status !== "received" ? (
                                                                                <Avatar
                                                                                    icon={profileUser.avatar_url === null ? "pi pi-user" : ""}
                                                                                    image={profileUser.avatar_url}
                                                                                    className="p-mr-2"
                                                                                    shape="circle"
                                                                                    style={{ position: "unset" }}
                                                                                />
                                                                            ) : (
                                                                                ""
                                                                            )
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {!isEmptyObj(informationDetailMessageChat) ? (
                                                                            informationDetailMessageChat.status === "received" ? (
                                                                                !isEmptyObj(informationDetailMessageChat) ? (
                                                                                    <Avatar
                                                                                        image={
                                                                                            conversationSelected.contact !== null
                                                                                                ? conversationSelected.contact.company.picture_url || undefined
                                                                                                : noPictureURL
                                                                                        }
                                                                                        className="p-mr-2"
                                                                                        shape="circle"
                                                                                        style={{ position: "unset" }}
                                                                                    />
                                                                                ) : (
                                                                                    ""
                                                                                )
                                                                            ) : (
                                                                                ""
                                                                            )
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ marginBottom: "1rem" }}>
                                                        <div className="p-d-flex p-jc-center p-ai-center" style={{ marginBottom: "0rem" }}>
                                                            <strong>Mensagem:</strong>
                                                        </div>
                                                        <div className="p-d-flex p-jc-center p-ai-center">
                                                            <p>{informationDetailMessageChat.text}</p>
                                                        </div>
                                                    </div>

                                                    {informationDetailMessageChat.created_at && (
                                                        <>
                                                            <div className="p-d-flex p-jc-center">
                                                                <strong>Criação:</strong>
                                                            </div>
                                                            <div className="p-d-flex p-flex-column p-ai-center" style={{ marginBottom: "1rem" }}>
                                                                <Moment format="HH:mm">{informationDetailMessageChat.created_at}</Moment>
                                                                <Moment format="DD/MM/YYYY">{informationDetailMessageChat.created_at}</Moment>
                                                            </div>
                                                        </>
                                                    )}

                                                    {informationDetailMessageChat.sent_at && (
                                                        <>
                                                            <div className="p-d-flex p-jc-center">
                                                                <strong>Envio:</strong>
                                                            </div>
                                                            <div className="p-d-flex p-flex-column p-ai-center" style={{ marginBottom: "1rem" }}>
                                                                <Moment format="HH:mm">{informationDetailMessageChat.sent_at}</Moment>
                                                                <Moment format="DD/MM/YYYY">{informationDetailMessageChat.sent_at}</Moment>
                                                            </div>
                                                        </>
                                                    )}

                                                    {informationDetailMessageChat.delivered_at && (
                                                        <>
                                                            <div className="p-d-flex p-jc-center">
                                                                <strong>Recebimento:</strong>
                                                            </div>
                                                            <div className="p-d-flex p-flex-column p-ai-center" style={{ marginBottom: "1rem" }}>
                                                                <Moment format="HH:mm">{informationDetailMessageChat.delivered_at}</Moment>
                                                                <Moment format="DD/MM/YYYY">{informationDetailMessageChat.delivered_at}</Moment>
                                                            </div>
                                                        </>
                                                    )}

                                                    {informationDetailMessageChat.read_at && (
                                                        <>
                                                            <div className="p-d-flex p-jc-center">
                                                                <strong>Leitura:</strong>
                                                            </div>
                                                            <div className="p-d-flex p-flex-column p-ai-center" style={{ marginBottom: "1rem" }}>
                                                                <Moment format="HH:mm">{informationDetailMessageChat.read_at}</Moment>
                                                                <Moment format="DD/MM/YYYY">{informationDetailMessageChat.read_at}</Moment>
                                                            </div>
                                                        </>
                                                    )}

                                                    {informationDetailMessageChat.status !== "received" ? (
                                                        <>
                                                            <div className="p-d-flex p-jc-center">
                                                                <strong>Status da mensagem:</strong>
                                                            </div>
                                                            <div className="p-d-flex p-flex-column p-ai-center">
                                                                {replaceStatusMessageWriting(informationDetailMessageChat)}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <br />
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <>
                    {/* New message client DataTable */}
                    <Dialog
                        visible={newMessageDialog}
                        dismissableMask={true}
                        modal
                        header={"INICIAR NOVA CONVERSA"}
                        style={{ width: "1220PX" }}
                        className="p-fluid"
                        onHide={hideDialog}
                        draggable={false}
                    >
                        <DataTableContacts
                            auxContactsFiltered={auxContactsFiltered}
                            contacts={contacts}
                            conversations={conversations}
                            loadingListContacts={loadingListContacts}
                            setNameContact={setNameContact}
                            setPhoneContact={setPhoneContact}
                            openANonExistingConversation={openANonExistingConversation}
                            setAuxContactsFiltered={setAuxContactsFiltered}
                            nameContact={nameContact}
                            phoneContact={phoneContact}
                            setContacts={setContacts}
                            setConversationSelected={setConversationSelected}
                            setNewMessageDialog={setNewMessageDialog}
                            openContactFunction={openContactFunction}
                        />
                    </Dialog>

                    {/* Change Contact of a Conversation */}
                    <Dialog
                        visible={changeResponsibleDialog}
                        dismissableMask={true}
                        modal
                        header={`Alterar contato de: ${
                            responsibleContact.contact !== undefined && responsibleContact.contact !== null
                                ? responsibleContact.contact.name
                                : responsibleContact.phone_number
                        } ; ${responsibleContact.contact !== undefined && responsibleContact.contact !== null ? responsibleContact.phone_number : ""}`}
                        style={{ width: "1220PX" }}
                        onHide={() => setChangeResponsibleDialog(false)}
                        draggable={false}
                    >
                        <ChangeContactDataTable
                            auxContacts={auxContacts}
                            loadingListContacts={loadingListContacts}
                            onUpdateContactPerson={onUpdateContactPerson}
                            setChangeResponsibleDialog={setChangeResponsibleDialog}
                            responsibleContact={responsibleContact}
                        />
                    </Dialog>

                    {/* View image Chat Dialog */}
                    <Dialog
                        visible={viewImageMessage}
                        dismissableMask={true}
                        style={{ maxWidth: "80vw", maxHeight: "80vh" }}
                        onHide={hideDialog}
                        draggable={false}
                    >
                        <div style={{ maxHeight: "30%" }}>
                            <img
                                src={!isEmptyObj(selectedViewImageMessage.attachment) ? selectedViewImageMessage.attachment.file_url : ""}
                                style={{ width: "100%", maxHeight: "65vh" }}
                            ></img>
                        </div>
                    </Dialog>

                    <Dialog
                        visible={uploadFromFilesDialog}
                        dismissableMask={true}
                        style={{ width: "65rem", height: "30rem" }}
                        modal
                        className="p-fluid"
                        onHide={() => {
                            setUploadFromFilesDialog(false)
                        }}
                        header={`Enviar uma mídia para: ${
                            conversationSelected.contact !== undefined && conversationSelected.contact !== null
                                ? conversationSelected.contact.name
                                : conversationSelected.phone_number
                        }`}
                        draggable={false}
                    >
                        <LocalUpload
                            onSubmit={onUploadFile}
                            types={
                                verification24 === true
                                    ? ".mp4, .mkv, .avi,  .png, .jpg, .jpeg, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .png, .jpg, .jpeg, .gif, .bmp, .tiff, .tif, .svg, .svgz, .zip, .rar, .7z, .tar, .gz, .bz2, .xz, .z, .7zip, .tar, .gz, .bz2, .xz, "
                                    : ".mp4, .mkv, .avi,  .png, .jpg, .jpeg, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .odt, .ods, .odp, .odg, .odc, .odf, .odb, .odi, .odm, .webm, .mp3, .wav, .mp4, .m4a, .m4v, .ogg, .ogv, .ogm, .ogx, .wmv, .wma, .wax, .wvx, .mov, .qt, .3gp, .3g2, .flv, .swf, .svg, .svgz, .ogg, .aac, .flac, .wav, .mid, .midi, .woff, .woff2, .eot, .ttf, .otf, .ico, .icns, .png, .jpg, .jpeg, .gif, .bmp, .tiff, .tif, .svg, .svgz, , .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .odt, .ods, .odp, .odg, .odc, .odf, .odb, .odi, .odm, .webm, .mp3, .wav, .mp4, .m4a, .m4v, .ogg, .ogv, .ogm, .ogx, .wmv, .wma, .wax, .wvx, .mov, .qt, .3gp, .3g2, .flv, .swf, .svg, .svgz, .ogg, .aac, .flac, .wav, .mid, .midi, .woff, .woff2, .eot, .ttf, .otf, .ico, .icns, .png, .jpg, .jpeg, .gif, .bmp, .tiff, .tif, .svg, .svgz, .zip, .webp, .rar, .7z, .tar, .gz, .bz2, .xz, .z, .7zip, .tar, .gz, .bz2, .xz, .z"
                            }
                            size={100000000}
                        />
                    </Dialog>

                    {/* Image preview dialog*/}
                    <Dialog
                        visible={imagePreviewDialog}
                        modal
                        header={`Enviar imagem para: ${
                            conversationSelected.contact !== undefined && conversationSelected.contact !== null
                                ? conversationSelected.contact.name
                                : conversationSelected.phone_number
                        }`}
                        draggable={false}
                        dismissableMask
                        style={{ maxWidth: "120vw" }}
                        footer={imagePreviewFooter}
                        className="p-fluid"
                        onHide={() => {
                            {
                                setImagesFilePath([])
                                setImagePreviewDialog(false)
                            }
                        }}
                    >
                        <div className="p-d-flex p-jc-center p-ai-center p-mb-3">
                            {sendFileImageAndVideo.length > 1 ? (
                                <>
                                    <div className="">
                                        <Image src={imageSelected.file_url} width="500rem" height="auto" alt="Image Text" />
                                        <div>
                                            <div className="p-d-flex p-jc-center">
                                                <div
                                                    className="imagePreviewDialogHorizontalScroll p-d-flex p-jc-center"
                                                    style={{ maxWidth: "25rem", marginBottom: "1rem", padding: "0.5rem 0.5rem 0.1rem 0.5rem" }}
                                                >
                                                    {sendFileImageAndVideo.map((image, index) => {
                                                        return (
                                                            <div>
                                                                <a
                                                                    onClick={() => {
                                                                        setImageSelected((state) => image)
                                                                        indexImage = index
                                                                        setIndexImage(indexImage)
                                                                    }}
                                                                >
                                                                    <Image
                                                                        className="p-mr-2"
                                                                        src={image.file_url}
                                                                        width="65rem"
                                                                        height="65rem"
                                                                        alt="Image Text"
                                                                    />
                                                                </a>
                                                                <br />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            {sendFileImageAndVideo.map((image) => {
                                                let element = document.getElementById("textForDialogImageOrVideo1")

                                                if (element && image.id === sendFileImageAndVideo[indexImage].id && image.text !== undefined) {
                                                    element.textContent = `${image.text}`
                                                }
                                                if (element && image.id === sendFileImageAndVideo[indexImage].id && image.text === undefined) {
                                                    element.textContent = ""
                                                }

                                                return (
                                                    <div>
                                                        {image.id === imageSelected.id && (
                                                            <div>
                                                                <div id="w-input-container" style={{ position: "relative" }}>
                                                                    <div className="w-input-text-group">
                                                                        <div
                                                                            id="textForDialogImageOrVideo1"
                                                                            contentEditable="true"
                                                                            suppressContentEditableWarning={true}
                                                                            spellCheck={true}
                                                                            onInput={(e: any) => {
                                                                                if (image.text === undefined) {
                                                                                    image["text"] = ""
                                                                                }
                                                                                image.text = e.currentTarget.textContent
                                                                            }}
                                                                        />

                                                                        <div className="w-placeholder">Digite uma mensagem...</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="p-d-flex p-jc-center p-ai-center p-mb-3">
                                        <div>
                                            <Image
                                                src={sendFileImageAndVideo.length > 0 ? sendFileImageAndVideo[0].file_url : ""}
                                                width="100%"
                                                height="400rem"
                                                alt="Image Text"
                                            />
                                            <div className="p-d-flex p-jc-center p-ai-center p-mb-3">
                                                <InputTextarea
                                                    onKeyDown={(e: any) => {
                                                        handleKeyPress(e)
                                                    }}
                                                    maxLength={4096}
                                                    placeholder="Digite uma mensagem..."
                                                    onChange={(e) => {
                                                        setInputSendImage(e.target.value)
                                                    }}
                                                    value={inputSendImage}
                                                    rows={5}
                                                    cols={10}
                                                    autoResize
                                                    style={{ height: "3rem", marginTop: "1rem" }}
                                                />
                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </Dialog>

                    {/* Image New preview dialog*/}
                    <Dialog
                        visible={imageNewPreviewDialog}
                        modal
                        header={`Enviar imagem para: ${
                            conversationSelected.contact !== undefined && conversationSelected.contact !== null
                                ? conversationSelected.contact.name
                                : conversationSelected.phone_number
                        }`}
                        style={{ backgroundColor: "black", maxWidth: "120vw" }}
                        footer={imageLocalPreviewFooter}
                        className="p-fluid"
                        draggable={false}
                        onHide={() => {
                            setImageNewPreviewDialog(false)
                            setImagesFilePath([])
                        }}
                        dismissableMask={true}
                    >
                        <div className="p-d-flex p-jc-center p-ai-center p-mb-3">
                            <Image src={imagesFilePath} width="100%" height="400rem" alt="Image Text" />
                        </div>

                        <div className="p-d-flex p-jc-center p-ai-center p-mb-3">
                            <InputTextarea
                                maxLength={4096}
                                placeholder="Digite uma mensagem..."
                                onChange={(e) => {
                                    setInputSendImage(e.target.value)
                                }}
                                onKeyDown={(e: any) => {
                                    handleKeyPress(e)
                                }}
                                value={inputSendImage}
                                rows={5}
                                cols={10}
                                autoResize
                                style={{ height: "3rem", marginTop: "1rem" }}
                            />
                            <br />
                            <br />
                        </div>
                    </Dialog>

                    {/* View files dialog*/}
                    <Dialog
                        visible={imageUploadDialog}
                        dismissableMask={true}
                        style={{ width: "100%", height: "100vh", margin: "1rem" }}
                        modal
                        maximizable
                        draggable={false}
                        className="dialogShowAttachments p-fluid"
                        onHide={() => {
                            imageUploadDialog = false
                            setImageUploadDialog(imageUploadDialog)
                        }}
                        header={`Enviar para: ${
                            conversationSelected.contact !== undefined && conversationSelected.contact !== null
                                ? conversationSelected.contact.name
                                : conversationSelected.phone_number
                        }`}
                    >
                        <DialogToDataAllGroups
                            type={"attachments"}
                            setImagePreviewDialog={(e: boolean) => {
                                imagePreviewDialog = e
                                setImagePreviewDialog(imagePreviewDialog)
                            }}
                            setImageSelected={(e: AttachmentInterface) => setImageSelected(e)}
                            setImageUploadDialog={(e: boolean) => setImageUploadDialog(e)}
                            setVideoPreviewDialog={updateVideoPreviewDialog}
                            setVideoPath={updateVideoFilePath}
                            setVideoDialog={upateVideoDialog}
                            setVideoSelected={(e: AttachmentInterface) => setVideoSelected(e)}
                            setConfirmUploadFileDialog={updateConfirmUploadFileDialog}
                            sendFile={updateSendFile}
                            sendFileDocument={(e: any) => updateSendFileDocument(e)}
                            setSendDefaultMessageDialog={setSendDefaultMessageDialogProp}
                            setDefaultMessageItem={setDefaultMessageItemProp}
                            firstDefaultMessage={firstDefaultMessage}
                            setOpenModalVisualizationRoudMap={setOpenModalVisualizationRoudMapProp}
                            setRoadMapSelectVisualization={roadMapSelected}
                            verification24={verification24}
                            setRoadMapDialog={(e: any) => setRoadMapDialog(e)}
                        />
                    </Dialog>

                    {/* Video preview dialog*/}
                    <Dialog
                        visible={videoPreviewDialog}
                        modal
                        header={`Enviar vídeo para: ${
                            conversationSelected.contact !== undefined && conversationSelected.contact !== null
                                ? conversationSelected.contact.name
                                : conversationSelected.phone_number
                        }`}
                        style={{ minWidth: "33rem", minHeight: "40%", backgroundColor: "black" }}
                        footer={uploadFileFooter}
                        className="p-fluid"
                        draggable={false}
                        onHide={() => setVideoPreviewDialog(false)}
                        dismissableMask={true}
                    >
                        <div className="p-d-flex p-jc-center p-ai-center p-mb-3">
                            {sendFileImageAndVideo.length > 1 ? (
                                <>
                                    <div className="">
                                        <ReactPlayer url={videoSelected.file_url} controls={true} width="40em" height="25rem" />
                                        <div>
                                            <div className="p-d-flex p-jc-center">
                                                <div
                                                    className="imagePreviewDialogHorizontalScroll p-d-flex p-jc-center"
                                                    style={{ maxWidth: "25rem", marginBottom: "1rem", padding: "0.5rem 0.5rem 0.1rem 0.5rem" }}
                                                >
                                                    {sendFileImageAndVideo.map((video, index) => {
                                                        return (
                                                            <div>
                                                                <a
                                                                    onClick={() => {
                                                                        setVideoSelected((state) => video)
                                                                        setIndexVideo((state) => index)
                                                                    }}
                                                                >
                                                                    <div>
                                                                        <ReactPlayer
                                                                            className="p-mr-2"
                                                                            playing={false}
                                                                            stopOnUnmount={false}
                                                                            url={video.file_url}
                                                                            controls={false}
                                                                            width="8rem"
                                                                            height="auto"
                                                                            style={{ marginRight: "0.5rem" }}
                                                                        />
                                                                    </div>
                                                                </a>
                                                                <br />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            {sendFileImageAndVideo.map((video) => {
                                                let element = document.getElementById("textForDialogImageOrVideo")

                                                if (element && video.id === sendFileImageAndVideo[indexVideo].id && video.text !== undefined) {
                                                    element.textContent = `${video.text}`
                                                }

                                                if (element && video.id === sendFileImageAndVideo[indexVideo].id && video.text === undefined) {
                                                    element.textContent = ""
                                                }

                                                return (
                                                    <div>
                                                        {video.id === sendFileImageAndVideo[indexVideo].id && (
                                                            <div>
                                                                <div id="w-input-container" style={{ position: "relative" }}>
                                                                    <div className="w-input-text-group">
                                                                        <div
                                                                            id="textForDialogImageOrVideo"
                                                                            contentEditable="true"
                                                                            suppressContentEditableWarning={true}
                                                                            spellCheck={true}
                                                                            onInput={(e: any) => {
                                                                                if (video.text === undefined) {
                                                                                    video["text"] = ""
                                                                                }

                                                                                video.text = e.currentTarget.textContent
                                                                            }}
                                                                        />

                                                                        <div className="w-placeholder">Digite uma mensagem...</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="p-d-flex p-jc-center p-ai-center p-mb-3">
                                        <div>
                                            <ReactPlayer
                                                url={sendFileImageAndVideo.length > 0 ? sendFileImageAndVideo[0].file_url : ""}
                                                controls={true}
                                                width="30em"
                                                height="25rem"
                                            />
                                            <div className="p-d-flex p-jc-center p-ai-center p-mb-3">
                                                <InputTextarea
                                                    onKeyDown={(e: any) => {
                                                        handleKeyPress(e)
                                                    }}
                                                    maxLength={4096}
                                                    placeholder="Digite uma mensagem..."
                                                    onChange={(e) => {
                                                        setInputSendImage(e.target.value)
                                                    }}
                                                    value={inputSendImage}
                                                    rows={5}
                                                    cols={10}
                                                    autoResize
                                                    style={{ height: "3rem", marginTop: "1rem" }}
                                                />
                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </Dialog>

                    {/* Video preview new dialog*/}
                    <Dialog
                        visible={videoNewPreviewDialog}
                        dismissableMask={true}
                        modal
                        header={`Enviar vídeo para: ${
                            conversationSelected.contact !== undefined && conversationSelected.contact !== null
                                ? conversationSelected.contact.name
                                : conversationSelected.phone_number
                        }`}
                        style={{ width: "1000px", backgroundColor: "black" }}
                        footer={videoLocalPreviewFooter}
                        className="p-fluid"
                        onHide={() => setVideoNewPreviewDialog(false)}
                    >
                        <div className="p-d-flex p-jc-center p-ai-center p-mb-3">
                            <ReactPlayer url={videoFilePath} controls={true} width="30em" height="20rem" />
                        </div>
                        <div className="p-d-flex p-jc-center p-ai-center p-mb-3">
                            <InputTextarea
                                maxLength={4096}
                                placeholder="Digite uma mensagem..."
                                onChange={(e) => {
                                    setInputSendImage(e.target.value)
                                }}
                                onKeyDown={(e: any) => {
                                    handleKeyPress(e)
                                }}
                                value={inputSendImage}
                                rows={5}
                                cols={10}
                                autoResize
                                style={{ height: "3rem", marginTop: "1rem" }}
                            />

                            <br />
                            <br />
                        </div>
                    </Dialog>

                    {/* RoadMap view dialog*/}
                    <Dialog
                        visible={roadMapDialog}
                        dismissableMask={true}
                        style={{ minWidth: "60%", height: "auto", minHeight: "80vh", margin: "1rem" }}
                        modal
                        maximizable
                        className="p-fluid"
                        onHide={() => {
                            roadMapDialog = false
                            setRoadMapDialog(roadMapDialog)
                            roadMapSelectVisualization = {} as RoadMapInterface

                            if (openModalVisualizationRoudMap) {
                                setOpenModalVisualizationRoudMap(false)
                            } else {
                                setRoadMapDialog(false)
                                setOpenModalVisualizationRoudMap(false)
                            }
                        }}
                        header={`Enviar um roteiro de mensagens para: ${
                            conversationSelected.contact !== undefined && conversationSelected.contact !== null
                                ? conversationSelected.contact.name
                                : conversationSelected.phone_number
                        }`}
                        footer={openModalVisualizationRoudMap ? footerSendRoadMap : ""}
                        draggable={false}
                    >
                        <div>
                            {openModalVisualizationRoudMap && (
                                <div>
                                    {!isEmptyObj(roadMapSelectVisualization) && (
                                        <div>
                                            <div>{mapOfMessagesRoadMap()}</div>
                                        </div>
                                    )}
                                    {isEmptyObj(roadMapSelectVisualization.messages) && (
                                        <div style={{ userSelect: "none" }}>*Este roteiro ainda não possui mensagens</div>
                                    )}
                                </div>
                            )}
                        </div>
                    </Dialog>

                    {/* Edit company Dialog */}
                    <Dialog
                        visible={editCompanyDialog}
                        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
                        modal
                        className="p-fluid"
                        onHide={hideDialog}
                        header="Editar Empresa"
                        dismissableMask={true}
                        draggable={false}
                    >
                        <form onSubmit={handleEditCompany}>
                            <Company
                                existingCompany={existingCompany}
                                saveCompanyData={saveCompanyData}
                                requestApiCompany={requestApiCompany}
                                setSaveCompanyData={handleSaveCompanyData}
                                setRequestApiCompany={handleRequestApiCompany}
                                setExistingCompany={handleExistingCompany}
                                setWhatsappCNPJ={companyCnpj}
                                type="update"
                                from="zap"
                                company={conversationSelected?.contact?.company}
                            />
                            <div className="p-dialog-footer">
                                <Button type="button" label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                                <Button
                                    type="submit"
                                    loading={loadingButtonUpdateOpportunity}
                                    label="Salvar"
                                    icon="pi pi-check"
                                    className="p-button-success p-button-text"
                                />
                            </div>
                        </form>
                    </Dialog>

                    {/* HotKeys Dialog */}
                    <Dialog
                        className="p-fluid"
                        dismissableMask={true}
                        visible={hotKeysDialog}
                        blockScroll={true}
                        style={{ width: "1000px" }}
                        modal
                        onHide={hideDialog}
                        header={`Atalhos do Teclado - Tela de Atendimentos`}
                        draggable={false}
                    >
                        <HotKeys />
                    </Dialog>

                    {/* Contact Dialog */}
                    <Dialog
                        style={{ minWidth: "40vw" }}
                        visible={contactsDialog}
                        modal
                        className="p-fluid"
                        onHide={hideDialog}
                        header="Novo Contato"
                        draggable={false}
                    >
                        <form onSubmit={handleCreateContact}>
                            <Contact
                                formContact={formContact}
                                setFormContact={handleUpdateContact}
                                setNewPhoneField={handleUpdateFieldPhone}
                                setNewEmailField={handleUpdateFieldEmail}
                                newEmailField={newEmailField}
                                newPhoneField={newPhoneField}
                            />

                            <div className="p-dialog-footer">
                                <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                                <Button type="submit" label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" />
                            </div>
                        </form>
                    </Dialog>

                    {/* Dialog filter dates conversation */}
                    <Dialog
                        style={{ minWidth: "40vw" }}
                        visible={dialogFilterDatesConversation}
                        modal
                        className="p-fluid"
                        onHide={hideDialog}
                        header="Selecione as datas"
                        draggable={false}
                    >
                        <form onSubmit={handleFilterDateConversation}>
                            <div>
                                <h4>Data Inicio:</h4>
                                <Calendar value={valueFilterDate1} onChange={(e) => setValueFilterDate1(e.value)} inline showWeek showTime hourFormat="24" />
                            </div>

                            <br />

                            <div>
                                <h4>Data Fim:</h4>
                                <Calendar value={valueFilterDate2} onChange={(e) => setValueFilterDate2(e.value)} inline showWeek showTime hourFormat="24" />
                            </div>

                            <div className="p-dialog-footer">
                                <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                                <Button
                                    type="submit"
                                    label="Filtrar"
                                    icon="pi pi-check"
                                    className="p-button-success p-button-text"
                                    loading={loadingButtonFilterDate}
                                />
                            </div>
                        </form>
                    </Dialog>

                    {/*  Send message again */}
                    <ConfirmDialog
                        dismissableMask={true}
                        message={`A mensagem tentará ser enviada ao cliente ${
                            conversationSelected.contact !== undefined && conversationSelected.contact !== null
                                ? conversationSelected.contact.name
                                : conversationSelected.phone_number
                        } novamente`}
                        header={"Deseja reenviar esta mensagem?"}
                        visible={sendMessageAgain}
                        draggable={false}
                        style={{ width: "450px" }}
                        footer={sendMessageAgainFooter}
                        onHide={() => setSendMessageAgain(false)}
                    />

                    {/* Upload file confirmation */}
                    <ConfirmDialog
                        dismissableMask={true}
                        message="Deseja realmente enviar os arquivos para o cliente ?"
                        header={`Enviar arquivos para: ${
                            conversationSelected.contact !== undefined && conversationSelected.contact !== null
                                ? conversationSelected.contact.name
                                : conversationSelected.phone_number
                        }`}
                        visible={confirmUploadFileDialog}
                        draggable={false}
                        style={{ width: "450px" }}
                        footer={uploadFileDocumentsFooter}
                        onHide={() => setConfirmUploadFileDialog(false)}
                    />

                    <ConfirmDialog
                        dismissableMask={true}
                        message="Deseja realmente enviar o arquivo para o cliente ?"
                        header={`Enviar arquivo para: ${
                            conversationSelected.contact !== undefined && conversationSelected.contact !== null
                                ? conversationSelected.contact.name
                                : conversationSelected.phone_number
                        }`}
                        visible={uploadLocalDocument}
                        draggable={false}
                        style={{ width: "450px" }}
                        footer={confirmLocalUploadFileFooter}
                        onHide={() => setUploadLocalDocument(false)}
                    />

                    <ConfirmDialog
                        dismissableMask={true}
                        visible={changeResponsible}
                        style={{ width: "30rem" }}
                        draggable={false}
                        header={`Alterar responsável da conversa: ${
                            responsibleContact.contact !== undefined && responsibleContact.contact !== null
                                ? responsibleContact.contact.name
                                : responsibleContact.phone_number
                        }`}
                        message={textMenssageResponsible}
                        footer={changeResponsibleFooter}
                        onHide={() => {
                            setChangeResponsible(false)
                        }}
                    ></ConfirmDialog>

                    <ConfirmDialog
                        dismissableMask={true}
                        draggable={false}
                        message={`A  conversa com ${
                            responsibleContact.contact !== undefined && responsibleContact.contact !== null
                                ? responsibleContact.contact.name
                                : responsibleContact.phone_number
                        }  ficará sem responsável`}
                        header={`Deseja remover ${
                            changeResponsibleDialog !== undefined
                                ? !isEmptyObj(responsibleContact.user)
                                    ? responsibleContact.user.name !== null
                                        ? responsibleContact.user.name
                                        : ""
                                    : ""
                                : ""
                        }  como responsável da conversa ?`}
                        visible={removeResponsible}
                        style={{ width: "450px" }}
                        footer={confirmRemoveUser}
                        onHide={() => setRemoveResponsible(false)}
                    />

                    <ConfirmDialog
                        dismissableMask={true}
                        draggable={false}
                        message={`A  conversa com ${
                            responsibleContact.contact !== undefined && responsibleContact.contact !== null
                                ? responsibleContact.contact.name
                                : responsibleContact.phone_number
                        }  ficará arquivada`}
                        header={`Deseja arquivar a conversa ?`}
                        visible={archiveConversationDialog}
                        style={{ width: "450px" }}
                        footer={confirmArchiveMessage}
                        onHide={() => {
                            setArchiveConversationDialog(false)
                        }}
                    />

                    <ConfirmDialog
                        dismissableMask={true}
                        draggable={false}
                        message={`A  conversa com ${
                            responsibleContact.contact !== undefined && responsibleContact.contact !== null
                                ? responsibleContact.contact.name
                                : responsibleContact.phone_number
                        }  será desarquivada`}
                        header={`Deseja desarquivar a conversa ?`}
                        visible={unarchiveConversationDialog}
                        style={{ width: "450px" }}
                        footer={confirmArchiveMessage}
                        onHide={() => setUnArchiveConversationDialog(false)}
                    />
                </>
            </div>
        </App>
    )
}
