import "../../../styles/Attendance.scss"

export const HotKeys = (props) => {
    return (
        <>
            <br />
            <div className="p-d-flex p-jc-between">
                <div style={{ borderRight: "solid 0.6px", paddingRight: "8rem" }}>
                    <p>
                        <strong className="keyboard-key">SHIFT</strong> <strong className="keyboard-key">I</strong> - Informaçãoes de Atalho
                    </p>
                    <br />
                    <p>
                        <strong className="keyboard-key">SHIFT</strong> <strong className="keyboard-key">O</strong> - Criar uma nova oportunidade
                    </p>
                    <br />
                    <p>
                        <strong className="keyboard-key">SHIFT</strong> <strong className="keyboard-key">N</strong> - {props.create}
                    </p>
                </div>

                <div>
                    <p>
                        <strong className="keyboard-key">SHIFT</strong> <strong className="keyboard-key">C</strong> - Criar um novo contato
                    </p>
                    <br />
                    <p>
                        <strong className="keyboard-key">SHIFT</strong> <strong className="keyboard-key">E</strong> - Criar uma nova empresa
                    </p>
                    <br />
                    <p>
                        <strong className="keyboard-key">SHIFT</strong> <strong className="keyboard-key">T</strong> - Criar uma nova tarefa
                    </p>
                </div>
            </div>
        </>
    )
}
