
import React, { useRef, useEffect, useState, TextareaHTMLAttributes } from "react"
import App from "../../../layout/App"
import api from "../../../services/api"
import Moment from "react-moment"
import ReactPlayer from "react-player"

//Components
import { Avatar } from "primereact/avatar"
import { Button } from "primereact/button"
import { ConfirmDialog } from "primereact/confirmdialog"
import { Dialog } from "primereact/dialog"
import { Dropdown } from "primereact/dropdown"
import { Image } from "primereact/image"
import { InputText } from "primereact/inputtext"
import { Toast } from "primereact/toast"
import { TabPanel, TabView } from "primereact/tabview"

//Interface
import { AttachmentInterface } from "../../../interfaces/AttachmentInterface"
import { ChatSubgroupInterface } from "../../../interfaces/ChatSubgroupInterface"
import { CrmRecords } from "../../../components/crm/CrmRecords"
import { DefaultMessageInterface } from "../../../interfaces/DefaultMessageInterface"
import { GropuInterface } from "../../../interfaces/GroupInterface"
import { ListWhatsappMessagesInterface } from "../../../interfaces/ListWhatsappMessagesInterface"
import { MessagesRoadMapInterface } from "../../../interfaces/MessagesRoadMapInterface"
import { OpportunityInterface } from "../../../interfaces/OpportunityInterface"
import { RoadMapInterface } from "../../../interfaces/RoadMapInterface"
import { RoadMapAttachmentInterface } from "../../../interfaces/RoadMapAttachmentInterface"
import { ShowProfileInterface } from "../../../interfaces/ShowProfileInterface"

//CSS
import "../../../styles/RoadMap.scss"

export const RoadMap = (props: TextareaHTMLAttributes<HTMLTextAreaElement>) => {
    let myRefCardMessage = useRef<null | HTMLDivElement>(null)
    let toast = useRef<Toast>(null)
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    let noPictureURL = "https://relvado.rs.gov.br/site/custom/default/img/no-avatar.png"
    let [heightChat, setHeightChat] = useState("")
    let [informationDetailMessageChat, setInformationDetailMessageChat] = useState<ListWhatsappMessagesInterface>({} as ListWhatsappMessagesInterface)
    let [profileUser, setProfileUser] = useState<ShowProfileInterface>({} as ShowProfileInterface)
    let [createValues, setCreateValues] = useState<any>({} as any)

    let [chatDialogMobile, setChatDialogMobile] = useState("disabled")
    let [widthBoxDateTimeInChat, setWidthBoxDateTimeInChat] = useState<string>("")
    let [widthLastMessageText] = useState<string>("")

    let [subGroups, setSubGroups] = useState<AttachmentInterface[]>([])
    const [selectedGroup, setSelectedGroup] = useState<GropuInterface | null>(null)

    //Attachments
    let [allRoadMap, setAllRoadMap] = useState<RoadMapInterface[]>([])
    let [auxMediaFiltered, setAuxMediaFiltered] = useState<RoadMapInterface[]>([])
    let [attachments, setAttachments] = useState<RoadMapAttachmentInterface[]>([])
    let [defaltMenssages, setDefaultMenssages] = useState<DefaultMessageInterface[]>([])
    let [handleDeleteMessageRoadMap, setHandleDeleteMessageRoadMap] = useState<MessagesRoadMapInterface>({} as MessagesRoadMapInterface)
    let [groupsAttachments, setGroupsAttachments] = useState<RoadMapAttachmentInterface[]>([])
    let [auxRoadMapSelected, setAuxRoadMapSelected] = useState<RoadMapInterface>({} as RoadMapInterface)
    let [roadMap, setRoadMap] = useState<RoadMapInterface>({} as RoadMapInterface)
    let [roadMapSelected, setRoadMapSelected] = useState<RoadMapInterface>({} as RoadMapInterface)
    let [tabPannel, setTabPannel] = useState<RoadMapAttachmentInterface[]>([])

    //InputsText
    let [inputTextSearchMessagesChat, setInputTextSearchMessagesChat] = useState("")

    //Dialogs
    let [deleteAttachmentDialog, setDeleteAttachmentDialog] = useState(false)
    let [informationDetailMessageVisualition, setInformationDetailMessageVisualition] = useState("noViewInformationMessage")
    let [newMessageDialog, setNewMessageDialog] = useState(false)
    let [nameClassAvatarDialog, setNameClassAvatarDialog] = useState("noDialogShowClientInformation")
    let [nameClassAvatarDialogSeller, setNameClassAvatarDialogSeller] = useState("noDialogShowSellerInformation")
    let [deleteMessageOnRoadMap, setDeleteMessageOnRoadMap] = useState(false)

    //Uploads Dialog
    let [audioUploadDialog, setAudioUploadDialog] = useState(false)
    let [documentDialog, setDocumentDialog] = useState(false)
    let [imageUploadDialog, setImageUploadDialog] = useState(false)
    let [messagesDefault, setMessagesDefault] = useState(false)
    let [roadMapEditDialog, setRoadMapEditDialog] = useState(false)
    let [stickerUploadDialog, setStickerUploadDialog] = useState(false)
    let [videoDialog, setVideoDialog] = useState(false)

    //Filter Search message
    let [chatMessagesSearch, setChatMessagesSearch] = useState<string[]>([])
    let [countIndexSearchMessageFilter, setCountIndexSearchMessageFilter] = useState(0)
    let [idSearchMessageDiv, setIdSearchMessageDiv] = useState("")
    let [viewButtonsSearchMessageUpOrDown, setViewButtonsSearchMessageUpOrDown] = useState(false)

    //Loadings
    let [loadingDelete, setLoadingDelete] = useState<boolean>(false)
    let [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    let [loadingSendMessageOnRoadMap, setLoadingSendMessageOnRoadMap] = useState<boolean>(false)

    //Filters
    let [advancedSearchFilter, setAdvancedSearchFilter] = useState("")
    let [viewSearchMessagesButton, setViewSearchMessagesButton] = useState(false)

    //Chat contacts
    let [contactCompanySelected] = useState<ListWhatsappMessagesInterface>({} as ListWhatsappMessagesInterface)

    //Input text height setting
    let atualHeightInputMessage = document.getElementById("sendTextInputMessage")?.clientHeight
    const upScrollSearch = () => setCountIndexSearchMessageFilter(countIndexSearchMessageFilter + 1)
    const downScrollSearch = () => setCountIndexSearchMessageFilter(countIndexSearchMessageFilter - 1)

    //Scroll of the messages filter
    const chatMessageFilter = (textSearch: string, selectedCompany: any) => {
        let idList: string[] = []

        if (idList.length <= 0) {
            toast.current?.show({
                severity: "error",
                summary: "Nenhuma mensagem encontrada!",
                detail: "",
                life: 1000,
            })
        } else if (idList.length >= 0) {
            setChatMessagesSearch(idList)
            setViewButtonsSearchMessageUpOrDown(true)
            setCountIndexSearchMessageFilter(idList.length - 1)
        }
    }

    const sendNewMessageDialog = () => {
        setNewMessageDialog(true)
    }

    //TYPES OF MESSAGE
    const mapOfMessagesRoudMap = () => {
        let dataRoadMap =
            !isEmptyObj(roadMapSelected) && roadMapSelected.messages !== undefined
                ? roadMapSelected.messages.sort((a: any, b: any) => (a.sequence > b.sequence ? 1 : -1))
                : []
        let classNameForAnimation = "notAnimated"
        let colorBackground = "white"

        return dataRoadMap.map((roadMap, index) => {
            return <div className="">{typeOfMessageSent(roadMap, index, classNameForAnimation, colorBackground)}</div>
        })
    }

    const typeOfMessageSent = (contactMessageWhatsapp: MessagesRoadMapInterface, index: number, classNameForAnimation: any, colorBackground: any) => {
        if (contactMessageWhatsapp.type === "text") {
            return messageText(contactMessageWhatsapp, classNameForAnimation, colorBackground)
        }

        if (contactMessageWhatsapp.type === "attachment") {
            return messageImage(contactMessageWhatsapp, classNameForAnimation)
        }
    }

    const messageText = (contactMessageWhatsapp: MessagesRoadMapInterface, classNameForAnimation: any, colorBackground: any) => {
        return (
            <>
                <div
                    ref={myRefCardMessage}
                    id={contactMessageWhatsapp.id}
                    key={contactMessageWhatsapp.id}
                    style={{
                        padding: "0.8rem 0rem 0.8rem 0rem",
                    }}
                >
                    <div className={`${classNameForAnimation}`} style={{ marginLeft: "0rem", marginTop: "1rem" }}>
                        <div className="p-grid p-d-flex p-jc-end" style={{ marginRight: "1rem" }}>
                            <div className="" key="1">
                                <p className="p-d-flex p-jc-end" style={{ marginBottom: "-0.1rem", fontSize: "0.8rem" }}>
                                    {profileUser.name} &nbsp;&nbsp;&nbsp;
                                </p>
                                <div className="p-d-flex">
                                    <div className="p-d-flex p-jc-end">
                                        <div
                                            style={{
                                                backgroundColor: `${colorBackground}`,
                                                border: "0.01rem solid #e4e5e7",
                                                borderRadius: "0.8rem 0.8rem 0rem 0.8rem",
                                                boxShadow: "0.04rem 0.04rem 0.06rem #d7d8db",
                                            }}
                                        >
                                            {" "}
                                            <div className="p-d-flex p-jc-end">
                                                {contactMessageWhatsapp.defaultMessage &&
                                                    contactMessageWhatsapp.defaultMessage?.text.startsWith("https://") && (
                                                        <iframe
                                                            src={contactMessageWhatsapp.defaultMessage?.text}
                                                            style={{ width: "100%", height: "40%" }}
                                                        ></iframe>
                                                    )}
                                                <a
                                                    onClick={() => {
                                                        setDeleteMessageOnRoadMap(true)
                                                        setHandleDeleteMessageRoadMap(contactMessageWhatsapp)
                                                    }}
                                                >
                                                    <i
                                                        className="pi pi-trash"
                                                        style={{ margin: "0.5rem", color: "red", opacity: "0.6", cursor: "pointer" }}
                                                    ></i>
                                                </a>
                                            </div>
                                            <div className="p-d-flex p-jc-end p-ai-center">
                                                <div
                                                    className="Container"
                                                    style={{
                                                        fontSize: "1.1rem",
                                                        textRendering: "optimizeLegibility",
                                                        maxWidth: `${widthWindow <= 414 ? "15rem" : "45rem"}`,
                                                        marginBottom: "0.5rem",
                                                        marginTop: "0.3rem",
                                                        marginLeft: "0.5rem",
                                                        marginRight: "0.5rem",
                                                        wordBreak: "normal",
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            contactMessageWhatsapp.defaultMessage !== undefined
                                                                ? contactMessageWhatsapp.defaultMessage.text !== undefined
                                                                    ? contactMessageWhatsapp.defaultMessage.text
                                                                    : ""
                                                                : "",
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                    &nbsp;
                                    <Avatar
                                        image={profileUser.avatar_url !== null ? profileUser.avatar_url : noPictureURL}
                                        className="p-mr-2"
                                        shape="circle"
                                        style={{ position: "unset" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const messageImage = (contactMessageWhatsapp: MessagesRoadMapInterface, classNameForAnimation: any) => {
        return (
            <>
                <div ref={myRefCardMessage} id={contactMessageWhatsapp.id} key={contactMessageWhatsapp.id} style={{ padding: "0.8rem 0rem 0.8rem 0rem" }}>
                    <div style={{ marginLeft: "0rem", marginTop: "1rem" }}>
                        <div className="p-grid p-d-flex p-jc-end" style={{ marginBottom: "1.5rem", marginRight: "1rem" }}>
                            <div className="" key="1">
                                <p className="p-d-flex p-jc-end" style={{ marginBottom: "-0.1rem", fontSize: "0.8rem" }}>
                                    {profileUser.name} &nbsp;&nbsp;&nbsp;
                                </p>
                                <div className="p-d-flex">
                                    <div className="p-d-flex p-jc-end">
                                        {widthWindow <= 454 ? (
                                            <>
                                                <a onClick={() => {}} style={{ cursor: "pointer" }}>
                                                    <i className="pi pi-arrow-circle-down" style={{ opacity: "0.4", margin: "0.3rem" }} />
                                                </a>
                                            </>
                                        ) : (
                                            <div></div>
                                        )}
                                        <div
                                            style={{
                                                backgroundColor: "white",
                                                border: "0.01rem solid #e4e5e7",
                                                borderRadius: "0.5rem 0.5rem 0rem 0.5rem",
                                                boxShadow: "0.04rem 0.04rem 0.06rem #d7d8db",
                                            }}
                                        >
                                            <div className="p-d-flex p-jc-end">
                                                <a
                                                    onClick={() => {
                                                        setDeleteMessageOnRoadMap(true)
                                                        setHandleDeleteMessageRoadMap(contactMessageWhatsapp)
                                                    }}
                                                >
                                                    <i
                                                        className="pi pi-trash"
                                                        style={{ margin: "0.5rem", color: "red", opacity: "0.6", cursor: "pointer" }}
                                                    ></i>
                                                </a>
                                            </div>
                                            <div className="p-d-flex p-jc-end p-ai-center">
                                                <div className="p-d-flex p-jc-end p-ai-center">
                                                    <div
                                                        className="Container"
                                                        style={{
                                                            maxWidth: `${widthWindow <= 414 ? "20rem" : "35rem"}`,
                                                            marginBottom: "0.3rem",
                                                            marginTop: "0.3rem",
                                                            marginLeft: "0.3rem",
                                                            marginRight: "0.3rem",
                                                            wordBreak: "normal",
                                                        }}
                                                    >
                                                        <a onClick={() => {}}>
                                                            <>
                                                                {contactMessageWhatsapp.attachment !== undefined
                                                                    ? typeOfRoadMap(contactMessageWhatsapp.attachment)
                                                                    : ""}
                                                            </>
                                                        </a>

                                                        <div className="p-d-flex p-jc-end p-as-end" style={{ fontSize: "0.65rem", userSelect: "none" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    &nbsp;
                                    <Avatar
                                        icon={profileUser.avatar_url === null ? "pi pi-user" : ""}
                                        image={profileUser.avatar_url}
                                        className="p-mr-2"
                                        shape="circle"
                                        style={{ position: "unset" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const onChangeCreateAttachmentsName = (event: any) => {
        const { value, name } = event.target

        setCreateValues({
            ...createValues,
            [name]: value,
        })
    }

    const onChangeCreateAttachments = (event: any) => {
        const { value, name } = event.target

        if (createValues.chat_subgroup_id) {
            let auxCreateValues = { ...createValues }
            auxCreateValues.chat_subgroup_id = null

            createValues = auxCreateValues
            setCreateValues(createValues)
        }

        setCreateValues({
            ...createValues,
            [name]: value,
        })
    }

    const onChangeEdit = (event: any) => {
        const { value, name } = event.target

        setRoadMap({
            ...roadMap,
            [name]: value,
        })
    }

    const typeOfRoadMap = (attachment: AttachmentInterface) => {
        if (attachment.type === "image") {
            return (
                <>
                    <Image
                        src={attachment !== undefined ? (attachment.file_url !== undefined ? attachment.file_url : "") : ""}
                        width={`${widthWindow <= 414 ? "200" : "300"}`}
                        height="auto"
                        alt="Imagem"
                    />
                </>
            )
        }
        if (attachment.type === "sticker") {
            return <Image src={attachment !== undefined ? (attachment.file_url !== undefined ? attachment.file_url : "") : ""} width="170" alt="Imagem" />
        }

        if (attachment.type === "document") {
            let splitArchive = attachment.file.split(".")
            let lengthSplit = splitArchive.length
            if (splitArchive[lengthSplit - 1] === "pdf") {
                return (
                    <iframe
                        src={attachment !== undefined ? (attachment.file_url !== undefined ? attachment.file_url : "") : ""}
                        frameBorder="0"
                        seamless={false}
                        title="document"
                        scrolling="no"
                        width="100%"
                        style={{ border: "none", overflow: "hidden" }}
                        height="98%"
                    />
                )
            } else {
                return (
                    <div>
                        <br />
                        <a href={attachment.file_url}>
                            <div className="p-d-flex p-jc-center">
                                <i className="pi pi-file" style={{ fontSize: "3em", marginTop: "-1rem" }}></i>
                            </div>
                            <br />
                            <div className="p-d-flex p-jc-center">{attachment.name}</div>
                        </a>
                    </div>
                )
            }
        }

        if (attachment.type === "video") {
            return (
                <ReactPlayer
                    styles={{ backgroundColor: "red" }}
                    autoPlay
                    url={attachment !== undefined ? (attachment.file_url !== undefined ? attachment.file_url : "") : ""}
                    width="100%"
                    height="90%"
                    controls={true}
                />
            )
        }

        if (attachment.type === "audio") {
            return (
                <div style={{ width: "25rem" }}>
                    <audio
                        src={attachment !== undefined ? (attachment.file_url !== undefined ? attachment.file_url : "") : ""}
                        className="audio-1"
                        controls
                        playsInline={true}
                        style={{
                            width: "100%",
                            resize: "none",
                            outline: "none",
                            borderRadius: "5px",
                            backgroundColor: "white",
                            textDecoration: "bold",
                            fontSize: "1rem",
                        }}
                    />
                </div>
            )
        }
    }

    const roadMapDocumentDialog = (attachment: AttachmentInterface) => {
        if (attachment.type === "document") {
            let splitArchive = attachment.file.split(".")
            let lengthSplit = splitArchive.length
            if (splitArchive[lengthSplit - 1] === "pdf") {
                return (
                    <div style={{ width: "20vw", height: "20vh" }}>
                        <iframe
                            src={attachment !== undefined ? (attachment.file_url !== undefined ? attachment.file_url : "") : ""}
                            frameBorder="0"
                            seamless={false}
                            title="document"
                            scrolling="no"
                            width="100%"
                            style={{ border: "none", overflow: "hidden" }}
                            height="98%"
                        />
                    </div>
                )
            } else {
                return (
                    <div style={{ width: "20vw", height: "20vh" }}>
                        <br />
                        <div className="p-d-flex p-jc-center">
                            <i className="pi pi-file" style={{ fontSize: "15vh" }}></i>
                        </div>
                        <br />
                        <div className="p-d-flex p-jc-center">{attachment.name}</div>
                    </div>
                )
            }
        }
    }

    //Filters
    const filterSearchContacts = () => {
        if (advancedSearchFilter !== "") {
            var listMediasFiltered: any[] = []

            for (let atualLine of auxMediaFiltered) {
                var addLine = true

                if (advancedSearchFilter !== "" && addLine) {
                    addLine = false
                    var word = advancedSearchFilter.toLowerCase().split(" ")
                    if (advancedSearchFilter === "") {
                        setAuxMediaFiltered(allRoadMap)
                    }
                    if (!atualLine.id) atualLine.id = ""
                    if (!atualLine.name) atualLine.name = ""

                    for (var oneWord in word) {
                        oneWord = oneWord.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                        if (
                            !atualLine.id.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.name.toLowerCase().includes(advancedSearchFilter.toLowerCase())
                        ) {
                            addLine = false
                            break
                        } else {
                            addLine = true
                        }
                    }
                }

                if (addLine) {
                    listMediasFiltered.push(atualLine)
                }
            }

            setAllRoadMap(listMediasFiltered)
        } else {
            setAllRoadMap(auxMediaFiltered)
        }
    }

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    const findIndexByIdFilter = (id: string) => {
        let index = -1
        for (let i = 0; i < allRoadMap.length; i++) {
            if (allRoadMap[i].id === id) {
                index = i
                break
            }
        }

        return index
    }

    //Requisitions
    const allRoadmap = async () => {
        try {
            let header = {
                headers: {
                    route: "list_roadmaps",
                },
            }
            await api
                .get("/roadmap", header)
                .then((res) => {
                    setAllRoadMap(res.data)
                    setAuxMediaFiltered(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro!",
                        detail: error.response.data.message,
                        life: 4000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const allGroupsAttachments = async () => {
        try {
            let header = {
                headers: {
                    route: "list_chat_groups",
                },
            }
            await api
                .get("chat_groups", header)
                .then((res) => {
                    groupsAttachments = res.data
                    let auxGroup: RoadMapAttachmentInterface = {} as RoadMapAttachmentInterface
                    auxGroup.name = "SEM CATEGORIA"
                    auxGroup.id = "000001"
                    setGroupsAttachments([...groupsAttachments, auxGroup])
                    setTabPannel([...groupsAttachments, auxGroup])
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 4000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const allDefaultMessages = async () => {
        try {
            let header = {
                headers: {
                    route: "list_chat_groups",
                },
            }

            await api.get("default_message", header).then((res) => {
                let auxtest: DefaultMessageInterface[] = []
                res.data.forEach((defaultMessage: DefaultMessageInterface) => {
                    if (defaultMessage.chat_group_id === null) {
                        defaultMessage.chat_group_id = "000001"
                        auxtest.push(defaultMessage)
                    } else {
                        auxtest.push(defaultMessage)
                    }
                })

                defaltMenssages = auxtest
                setDefaultMenssages(defaltMenssages)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const allAttachments = async () => {
        try {
            let header = {
                headers: {
                    route: "list_global_attachments",
                },
            }

            await api.get("attachments/global", header).then((res) => {
                let auxtest: RoadMapAttachmentInterface[] = []
                res.data.forEach((attachment: RoadMapAttachmentInterface) => {
                    if (attachment.chat_group_id === null) {
                        attachment.chat_group_id = "000001"
                        auxtest.push(attachment)
                    } else {
                        auxtest.push(attachment)
                    }
                })

                setAttachments(auxtest)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const allSubGroups = async () => {
        try {
            let header = {
                headers: {
                    route: "list_chat_subgroups",
                },
            }

            await api
                .get("chat_subgroups", header)
                .then((res) => {
                    subGroups = res.data
                    setSubGroups(subGroups)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const updateRoadMap = (data: any, roadMap?: any) => {
        let updateData = {
            roadmap_id: roadMapSelected.id,
            roadmapMessages: data,
        }

        try {
            let header = {
                headers: {
                    route: "update_roadmap_message",
                },
            }

            api.put("roadmap/messages", updateData, header)
                .then((res) => {
                    if (res.status === 200) {
                        if (roadMap !== undefined) {
                            setAuxRoadMapSelected(roadMap)
                            setRoadMapSelected(roadMap)
                        }

                        setLoadingSendMessageOnRoadMap(false)
                        hideDialog()
                        executeScroll()
                    } else {
                    }
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao atualizar roteiro!",
                        life: 4000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const onCreateRoadMap = () => {
        let id = createValues.chat_subgroup_id?.id
        if (id === undefined) {
            id = "000001"
        }
        let auxAttachmentsRequisition
        try {
            if (createValues.group.id === "000001") {
                auxAttachmentsRequisition = {
                    name: createValues.name,
                    chat_subgroup_id: null,
                }
            } else {
                auxAttachmentsRequisition = {
                    name: createValues.name,
                    chat_subgroup_id: id,
                }
            }

            if (createValues.name === "") {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao cadastrar",
                    detail: "Por favor, verificar os dados preenchidos!",
                    life: 4000,
                })
            } else {
                let header = {
                    headers: {
                        route: "create_roadmap",
                    },
                }

                api.post("roadmap", auxAttachmentsRequisition, header)
                    .then((res) => {
                        if (res.status === 200) {
                            setCreateValues({} as RoadMapInterface)
                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso na criação",
                                detail: "Roteito de mensagens criado com sucesso!",
                            })
                            setRoadMapSelected(res.data)
                            setAuxRoadMapSelected(res.data)
                            setLoadingSubmit(false)
                            setNewMessageDialog(false)

                            setAllRoadMap([...allRoadMap, res.data])
                            hideDialog()
                        }
                    })
                    .catch((error) => {
                        setLoadingSubmit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao cadastar!",
                            detail: "Por favor, verificar os dados preenchidos!",
                            life: 4000,
                        })
                    })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onDeleteRoadMap = () => {
        setLoadingDelete(true)
        try {
            api.delete("roadmap", {
                headers: {
                    route: "delete_roadmap",
                },
                data: {
                    id: roadMapSelected.id,
                },
            })

                .then((res) => {
                    setLoadingDelete(false)
                    if (res.status === 200) {
                        let auxDeleteAttachament = allRoadMap.filter((item) => roadMapSelected.id !== item.id)

                        allRoadMap = auxDeleteAttachament
                        setDeleteAttachmentDialog(false)
                        setAuxRoadMapSelected({} as RoadMapInterface)
                        setRoadMapSelected({} as RoadMapInterface)
                        hideDialog()
                        setAllRoadMap(auxDeleteAttachament)
                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na exclusão",
                            detail: "Roteiro de Mensagens excluído com sucesso!",
                            life: 4000,
                        })
                    } else {
                        setLoadingDelete(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao excluir!",
                            detail: res.data.message,
                            life: 4000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingDelete(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao excluir!",
                        detail: error.response.data.message,
                        life: 4000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const onEditRoadMap = () => {
        let finalArray
        let auxConfirPresentation

        if (
            (roadMap.chatSubgroup?.id === null || roadMap.chatSubgroup?.id === undefined) &&
            !isEmptyObj(selectedGroup) &&
            selectedGroup?.name !== "SEM CATEGORIA"
        ) {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao Editar",
                detail: "Revise os dados inseridos",
                life: 3000,
            })
        } else {
            setLoadingSubmit(true)

            if (roadMap.name === null || roadMap.name === "") {
                setLoadingSubmit(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao Editar",
                    detail: "Revise os dados inseridos",
                    life: 3000,
                })
            } else {
                setLoadingSubmit(true)
                if (isEmptyObj(selectedGroup) || selectedGroup?.name === "SEM CATEGORIA") {
                    finalArray = {
                        id: roadMap.id,
                        name: roadMap.name,
                        chat_subgroup_id: null,
                    }
                } else {
                    finalArray = {
                        id: roadMap.id,
                        name: roadMap.name,
                        chat_subgroup_id: roadMap.chatSubgroup.id,
                    }
                }
                try {
                    let header = {
                        headers: {
                            route: "update_roadmap",
                        },
                    }

                    api.put("roadmap", finalArray, header)

                        .then((res) => {
                            if (res.status === 200) {
                                toast.current?.show({
                                    severity: "success",
                                    summary: "Sucesso na alteração",
                                    detail: "Apresetação alterada com sucesso!",
                                    life: 3000,
                                })

                                setLoadingSubmit(false)
                                setRoadMapEditDialog(false)
                                setLoadingSubmit(false)
                                let _presentations = [...allRoadMap]
                                let _presentation = res.data
                                let index = findIndexByIdFilter(res.data.id)
                                _presentations[index] = _presentation
                                allRoadMap = _presentations
                                setAllRoadMap(_presentations)
                                hideDialog()

                                setRoadMapSelected(_presentation)
                                setAuxRoadMapSelected(_presentation)
                            } else {
                                setLoadingSubmit(false)
                                toast.current?.show({
                                    severity: "error",
                                    summary: "Erro ao Editar",
                                    detail: res.data.message,
                                    life: 3000,
                                })
                            }
                        })
                        .catch((error) => {
                            setLoadingSubmit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao Editar",
                                detail: error.response.data.message,

                                life: 3000,
                            })
                        })
                } catch (error) {
                    console.log(error)
                }
            }
        }
    }

    //Handle Key Press
    const handleKeyPressSearchMessage = (event: any) => {
        if (event.key === "Enter") {
            event.preventDefault()
            chatMessageFilter(inputTextSearchMessagesChat, contactCompanySelected)
        }
    }

    const messageReadConfirmation = (contactMessageWhatsapp: any) => {
        if (contactMessageWhatsapp.status === "unsent") {
            return (
                <div>
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: "1em" }}></i>
                </div>
            )
        } else if (contactMessageWhatsapp.status === "delivered") {
            return (
                <div>
                    <i className="pi pi-check " style={{ fontSize: "0.6rem", color: "" }}></i>
                    <i className="pi pi-check" style={{ fontSize: "0.6rem", color: "", marginLeft: "-0.3rem", marginRight: "0.1rem" }}></i>
                </div>
            )
        } else if (contactMessageWhatsapp.status === "read") {
            return (
                <div>
                    <i className="pi pi-check " style={{ fontSize: "0.6rem", color: "#19e5e6" }}></i>
                    <i className="pi pi-check" style={{ fontSize: "0.6rem", color: "#19e5e6", marginLeft: "-0.3rem", marginRight: "0.1rem" }}></i>
                </div>
            )
        } else if (contactMessageWhatsapp.status === "sent") {
            return (
                <i className="pi pi-check p-d-flex p-as-center" style={{ fontSize: "0.6rem", color: "", marginBottom: "-0.2rem", marginRight: "0.1rem" }}></i>
            )
        } else if (contactMessageWhatsapp.status === "failed") {
            return (
                <div>
                    <i className="pi pi-times" style={{ fontSize: "0.8rem", color: "red" }}></i>
                </div>
            )
        }
    }

    const executeScroll = () => {
        myRefCardMessage.current && myRefCardMessage.current.scrollIntoView(false)
    }

    const filterSubGroups = (rowData: any) => {
        let auxSub: any = []
        subGroups.forEach((sub) => {
            if (sub.chat_group_id === rowData?.id) {
                auxSub.push(sub)
            }
        })
        return auxSub
    }

    const deleteMessage = () => {
        return (
            <>
                {" "}
                Deseja realmente excluir o Roteiro de Mensagens <strong>{roadMapSelected.name}</strong>
            </>
        )
    }

    //Dialogs
    const hideDialog = () => {
        setNewMessageDialog(false)
        setVideoDialog(false)
        setDeleteAttachmentDialog(false)
        setMessagesDefault(false)
        setDocumentDialog(false)
        setImageUploadDialog(false)
        setVideoDialog(false)
        setAudioUploadDialog(false)
        setStickerUploadDialog(false)
        setRoadMapEditDialog(false)
        setDeleteMessageOnRoadMap(false)
        setSelectedGroup({} as GropuInterface)
    }

    const showOpportunityDetail = async () => {
        try {
            let header = {
                headers: {
                    route: "show_profile",
                },
            }

            await api
                .get(`profile`, header)
                .then((res: any) => {
                    profileUser = res.data
                    setProfileUser(res.data)
                })
                .catch((error: any) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 4000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const deleteMessageRoadMap = (widthWindow: MessagesRoadMapInterface) => {
        let filterArray = auxRoadMapSelected.messages.filter((item) => item.sequence !== widthWindow.sequence)
        let newArray = (auxRoadMapSelected.messages = filterArray)
        updateRoadMap(newArray)
    }

    //Footers
    const deleteDialogFooter = () => {
        return (
            <>
                <Button label="Não" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingDelete} onClick={onDeleteRoadMap} />
            </>
        )
    }

    const createDefaultMessageFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingSubmit} onClick={onCreateRoadMap} />
        </>
    )

    const editDefaultMessageFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingSubmit} onClick={onEditRoadMap} />
        </>
    )

    const colorCardSelected = (rowData: any) => {
        if (!isEmptyObj(roadMapSelected) && roadMapSelected.id === rowData.id) {
            return "rgb(240, 242, 245)"
        } else {
            return ""
        }
    }

    let widthWindow = window.innerWidth

    const windowWidth = (widthWindow: number) => {
        if (widthWindow > 1281) {
            widthLastMessageText = "22rem"
            widthBoxDateTimeInChat = "30%"
            setWidthBoxDateTimeInChat(widthBoxDateTimeInChat)
        } else if (widthWindow <= 1280) {
            widthLastMessageText = "12rem"
            widthBoxDateTimeInChat = "50%"
            setWidthBoxDateTimeInChat(widthBoxDateTimeInChat)
        }
    }

    //UseEffect
    useEffect(() => {
        showOpportunityDetail()
        allGroupsAttachments()
        allDefaultMessages()
        allRoadmap()
        allAttachments()
        allSubGroups()
    }, [])

    useEffect(() => {
        atualHeightInputMessage = document.getElementById("sendTextInputMessage")?.clientHeight
    }, [atualHeightInputMessage])

    useEffect(() => {
        atualHeightInputMessage = document.getElementById("sendTextInputMessage")?.clientHeight

        window.addEventListener("resize", function () {
            //var altura = window.innerHeight;
            widthWindow = window.innerWidth
            windowWidth(widthWindow)
        })
        windowWidth(widthWindow)

        if (widthWindow > 1281) {
            heightChat = "85%"
        } else if (widthWindow <= 1280) {
            heightChat = "83%"
        }
    }, [widthWindow])

    return (
        <App>
            <div className="attendanceAllPage">
                <Toast ref={toast} />
                <div style={{ display: "none" }}>
                    <CrmRecords />
                </div>
                <div className="desktop-view">
                    <div className="desktop p-grid crud-demo">
                        <div className="p-col-12 p-md-12 p-lg-12">
                            {/* CHAT MOBILE */}
                            <div className={`${chatDialogMobile}`}>
                                <div style={{ width: "100%", height: "100%", backgroundColor: "#e4e5e7" }}>
                                    <div style={{ width: "100%", height: "7%", backgroundColor: "#e4e5e7" }} className="p-d-flex p-jc-between p-ai-center">
                                        <p style={{ marginLeft: "1rem", fontSize: "1.3rem", marginTop: "0.5rem" }} className="p-d-flex p-jc-center">
                                            {roadMapSelected.name}
                                        </p>

                                        <a onClick={() => setChatDialogMobile("disabled")} className="p-d-flex p-jc-end">
                                            <i className="pi pi-times" style={{ marginRight: "1.5rem" }}></i>
                                        </a>
                                    </div>
                                    <div style={{ width: "100%", height: "75vh", backgroundColor: "#f7f7f8" }} className="messagesBodyContact">
                                        <br />
                                        {mapOfMessagesRoudMap()}
                                    </div>

                                    <div
                                        style={{
                                            width: "100%",
                                            height: "auto",
                                            backgroundColor: "rgb(215, 216, 219)",
                                            borderRadius: "0rem 0rem 0.8rem 0.8rem",
                                            padding: "0.5rem",
                                        }}
                                        className="sendMessage"
                                    >
                                        <div className="sendMessage">
                                            <div className="p-d-flex p-jc-center">
                                                <Button
                                                    icon="pi pi-comment"
                                                    label="Mensagens"
                                                    onClick={() => setMessagesDefault(true)}
                                                    className="p-button-raised p-button-rounded"
                                                />
                                                &nbsp;&nbsp;
                                                <Button
                                                    icon="pi pi-paperclip"
                                                    label="Documentos"
                                                    onClick={() => setDocumentDialog(true)}
                                                    className="p-button-raised p-button-rounded"
                                                />
                                                &nbsp;&nbsp;
                                                <Button
                                                    icon="pi pi-image"
                                                    label="Imagens"
                                                    onClick={() => setImageUploadDialog(true)}
                                                    className="p-button-raised p-button-rounded"
                                                />
                                            </div>
                                            &nbsp;&nbsp;
                                            <div className="p-d-flex p-jc-center">
                                                <Button
                                                    icon="pi pi-paperclip"
                                                    label="Vídeos"
                                                    onClick={() => setVideoDialog(true)}
                                                    className="p-button-raised p-button-rounded"
                                                />
                                                &nbsp;&nbsp;
                                                <Button
                                                    icon="pi pi-volume-up"
                                                    label="Áudios"
                                                    onClick={() => setAudioUploadDialog(true)}
                                                    className="p-button-raised p-button-rounded"
                                                />
                                                &nbsp;&nbsp;
                                                <Button
                                                    icon="pi pi-stop"
                                                    onClick={() => setStickerUploadDialog(true)}
                                                    label="Stickers"
                                                    className="p-button-raised p-button-rounded"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* CHAT MOBILE  ^ */}

                            <div className="desktopPage">
                                <div className="contentDesktopPage p-d-flex p-jc-center p-ai-center">
                                    <div
                                        style={{
                                            backgroundColor: "#ffff",
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "0.5rem",
                                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                        }}
                                        className="p-d-flex"
                                    >
                                        <div className="leftSideOfPage">
                                            <div className="" style={{ border: "none", display: "flex", flexDirection: "column", height: "100%" }}>
                                                <div
                                                    className="p-d-flex p-jc-center p-ai-center"
                                                    style={{ height: "10%", backgroundColor: "#ffffff", padding: "1rem", borderRadius: "0.5rem" }}
                                                >
                                                    <div
                                                        className="p-d-flex p-jc-between p-ai-center"
                                                        style={{
                                                            marginLeft: `${widthWindow <= 414 ? "1rem" : ""}`,
                                                            marginTop: "0rem",
                                                            width: `${widthWindow <= 414 ? "100%" : "100%"}`,
                                                        }}
                                                    >
                                                        <div className="p-d-flex p-ai-center">
                                                            <Avatar
                                                                icon={profileUser.avatar_url === null ? "pi pi-user" : ""}
                                                                image={profileUser.avatar_url}
                                                                className="p-mr-2"
                                                                shape="circle"
                                                                style={{
                                                                    boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",

                                                                    width: `${widthWindow <= 414 ? "3rem" : "2.5vw"}`,
                                                                    height: `${widthWindow <= 414 ? "3rem" : "5vh"}`,
                                                                }}
                                                                onClick={() => {
                                                                    setNameClassAvatarDialogSeller("dialogShowSellerInformation")
                                                                    setNameClassAvatarDialog("noDialogShowClientInformation")
                                                                }}
                                                            />
                                                            <p style={{ marginLeft: "1rem", fontSize: "1.3rem" }}>{profileUser.name}</p>
                                                        </div>
                                                    </div>
                                                    <div className="p-col-2 p-md-2 p-lg-2">
                                                        <div className="p-d-flex">
                                                            <Button
                                                                icon="pi pi-plus"
                                                                className="p-button-rounded p-button-text"
                                                                aria-label="Submit"
                                                                onClick={() => {
                                                                    sendNewMessageDialog()
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="" style={{ height: "auto" }}>
                                                    <div className="search">
                                                        <span className="p-input-icon-left" style={{ width: "100%" }}>
                                                            <i className="pi pi-search" />
                                                            <InputText
                                                                className="searchInput"
                                                                id="autofocs"
                                                                placeholder="Pesquisar Roteiro..."
                                                                value={advancedSearchFilter}
                                                                onKeyUp={() =>
                                                                    advancedSearchFilter === ""
                                                                        ? filterSearchContacts()
                                                                        : "" || advancedSearchFilter !== ""
                                                                        ? filterSearchContacts()
                                                                        : ""
                                                                }
                                                                onChange={(e) => {
                                                                    setAdvancedSearchFilter(e.target.value)
                                                                    filterSearchContacts()
                                                                }}
                                                                style={{ borderRadius: "0.4rem", border: "none", backgroundColor: "#f0f2f5" }}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>

                                                <div style={{ padding: "0.5rem", color: "green", fontWeight: "500", userSelect: "none" }}>
                                                    <p className="chatString">ROTEIROS</p>
                                                </div>

                                                <div className="desktop-attendance-screen" style={{ cursor: "pointer" }}>
                                                    {allRoadMap.map((roadMap, indexRoadMap) => {
                                                        return (
                                                            <a
                                                                key={indexRoadMap}
                                                                onClick={() => {
                                                                    setRoadMapSelected(roadMap)
                                                                    setAuxRoadMapSelected(roadMap)
                                                                    setChatDialogMobile("active")
                                                                    setNameClassAvatarDialog("noDialogShowClientInformation")

                                                                    setTimeout(() => {
                                                                        executeScroll()
                                                                    }, 50)
                                                                }}
                                                            >
                                                                <div
                                                                    className="contactcontact p-d-flex"
                                                                    style={{
                                                                        marginLeft: "0rem",
                                                                        backgroundColor: `${colorCardSelected(roadMap)}`,
                                                                    }}
                                                                >
                                                                    <div className="p-col-12 p-md-12 p-lg-12" style={{ width: "100%" }}>
                                                                        <div style={{ width: "100%", height: "100%" }} className="p-d-flex">
                                                                            <div style={{ width: "100%", height: "100%" }}>
                                                                                <div
                                                                                    style={{ fontSize: "1.1rem", fontWeight: "400", width: "100%" }}
                                                                                    className="p-text-nowrap p-text-truncate"
                                                                                >
                                                                                    {roadMap.name}
                                                                                </div>

                                                                                <div
                                                                                    style={{ fontSize: "0.7rem", width: "100%" }}
                                                                                    className="p-text-nowrap p-text-truncate"
                                                                                >
                                                                                    {roadMap.chatSubgroup !== undefined && roadMap.chatSubgroup !== null
                                                                                        ? roadMap.chatSubgroup.name
                                                                                        : "SEM CATEGORIA"}
                                                                                </div>
                                                                                <Moment
                                                                                    className="momentLastMessageChat p-d-flex p-jc-end p-as-end  p-ai-end"
                                                                                    format="DD/MM/YYYY HH:mm"
                                                                                    style={{
                                                                                        fontSize: "0.7rem",
                                                                                        color: "#444445",
                                                                                        textAlign: "right",
                                                                                        userSelect: "none",
                                                                                    }}
                                                                                >
                                                                                    {roadMap.updated_at}
                                                                                </Moment>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ width: "75%", height: "100%" }}>
                                            {!isEmptyObj(roadMapSelected) && (
                                                <>
                                                    <div className="rightMessageScreen" style={{ right: "0", width: "100%" }}>
                                                        {/* =========== DIALOG COMPANY */}
                                                        <div className={`${nameClassAvatarDialog}`}>
                                                            <div className="dialogShowClientInformation" style={{ padding: "2rem 2rem 2rem 2rem" }}>
                                                                <div className="p-d-flex p-jc-end">
                                                                    <Button
                                                                        icon="pi pi-times"
                                                                        className="p-button-rounded p-button-danger p-button-text p-d-flex p-jc-end"
                                                                        onClick={() => {
                                                                            setNameClassAvatarDialog("noDialogShowClientInformation")
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="avatarDialogInformation p-d-flex p-jc-center  p-mb-4">
                                                                    <Avatar
                                                                        image={
                                                                            !isEmptyObj(contactCompanySelected.contact)
                                                                                ? contactCompanySelected.contact.company
                                                                                    ? contactCompanySelected.contact.company.picture_url !== null
                                                                                        ? contactCompanySelected.contact.company.picture_url
                                                                                        : noPictureURL
                                                                                    : noPictureURL
                                                                                : noPictureURL
                                                                        }
                                                                        className="p-mr-2 "
                                                                        shape="circle"
                                                                        size="large"
                                                                        style={{ width: "100px", height: "100px" }}
                                                                    />
                                                                </div>

                                                                {!isEmptyObj(contactCompanySelected.contact) &&
                                                                    contactCompanySelected.contact.company.address !== null && (
                                                                        <div
                                                                            className="p-d-flex p-jc-center p-mb-3"
                                                                            style={{ fontSize: "1rem", fontWeight: "400", textAlign: "center" }}
                                                                        >
                                                                            {!isEmptyObj(contactCompanySelected.contact) ? (
                                                                                <>
                                                                                    <strong> EMPRESA: </strong>
                                                                                    {contactCompanySelected.contact.company.cnpj}
                                                                                </>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    )}

                                                                {!isEmptyObj(contactCompanySelected.contact) && contactCompanySelected.contact.job !== null && (
                                                                    <div
                                                                        className="p-d-flex p-jc-center p-mb-3"
                                                                        style={{ fontSize: "1rem", fontWeight: "400", textAlign: "center" }}
                                                                    >
                                                                        {!isEmptyObj(contactCompanySelected.contact) ? (
                                                                            <>
                                                                                <strong> CARGO: </strong>
                                                                                {contactCompanySelected.contact.job}
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                )}

                                                                {!isEmptyObj(contactCompanySelected.contact) &&
                                                                    contactCompanySelected.contact.company.address !== null &&
                                                                    contactCompanySelected.contact.company.city !== null &&
                                                                    contactCompanySelected.contact.company.state !== null && (
                                                                        <div
                                                                            className="p-d-flex p-jc-center p-mb-3"
                                                                            style={{
                                                                                fontSize: "1rem",
                                                                                fontWeight: "00",
                                                                                textAlign: "center",
                                                                            }}
                                                                        >
                                                                            Endereço: <br /> {contactCompanySelected.contact.company.address} -{" "}
                                                                            {contactCompanySelected.contact.company.city},{" "}
                                                                            {contactCompanySelected.contact.company.state}
                                                                        </div>
                                                                    )}
                                                                {!isEmptyObj(contactCompanySelected.contact) &&
                                                                    contactCompanySelected.contact.company.main_activity_description !== null && (
                                                                        <div
                                                                            className="p-d-flex p-jc-center p-mb-3"
                                                                            style={{
                                                                                fontSize: "1rem",
                                                                                fontWeight: "500",
                                                                                textAlign: "center",
                                                                            }}
                                                                        >
                                                                            Atividade Principal: <br />
                                                                            {contactCompanySelected.contact.company.main_activity_description}
                                                                        </div>
                                                                    )}

                                                                <br />
                                                                <div className="scrollShowClientInformation"></div>
                                                            </div>
                                                        </div>
                                                        {/* =========== DIALOG COMPANY */}

                                                        {/* =========== DIALOG DIV INFORMATION MESSAGE*/}
                                                        <div className={`${informationDetailMessageVisualition}`}>
                                                            <div className="dialogShowDetailMessageInformation" style={{ padding: "2rem 2rem 2rem 2rem" }}>
                                                                <div className="dialogShowDetailMessageInformationScroll">
                                                                    <div className="p-d-flex p-jc-end">
                                                                        <Button
                                                                            icon="pi pi-times"
                                                                            className="p-button-rounded p-button-danger p-button-text p-d-flex p-jc-end"
                                                                            onClick={() => setInformationDetailMessageVisualition("noViewInformationMessage")}
                                                                        />
                                                                    </div>
                                                                    <br />
                                                                    <div
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "auto",
                                                                            backgroundColor: "#f7f7f8",
                                                                            boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                                                                            borderRadius: "0.8rem 0.8rem 0.8rem 0.8rem",
                                                                        }}
                                                                    >
                                                                        <div ref={myRefCardMessage}>
                                                                            <div className="p-d-flex p-jc-end" style={{ marginBottom: "0.5rem" }}>
                                                                                <>
                                                                                    {!isEmptyObj(informationDetailMessageChat) ? (
                                                                                        informationDetailMessageChat.status === "received" ? (
                                                                                            !isEmptyObj(informationDetailMessageChat.contact) ? (
                                                                                                <>
                                                                                                    <p
                                                                                                        className="p-d-flex p-jc-end"
                                                                                                        style={{
                                                                                                            marginBottom: "-0.1rem",
                                                                                                            fontSize: "0.8rem",
                                                                                                            padding: "0.3rem",
                                                                                                            userSelect: "none",
                                                                                                        }}
                                                                                                    >
                                                                                                        {informationDetailMessageChat.contact.company.name}
                                                                                                    </p>
                                                                                                </>
                                                                                            ) : (
                                                                                                informationDetailMessageChat.phone_number
                                                                                            )
                                                                                        ) : (
                                                                                            ""
                                                                                        )
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </>
                                                                                &nbsp;&nbsp;
                                                                                <>
                                                                                    {!isEmptyObj(informationDetailMessageChat) ? (
                                                                                        informationDetailMessageChat.status !== "received" ? (
                                                                                            <>
                                                                                                <p
                                                                                                    className="p-d-flex p-jc-end"
                                                                                                    style={{
                                                                                                        marginBottom: "-0.1rem",
                                                                                                        fontSize: "0.8rem",
                                                                                                        padding: "0.3rem",
                                                                                                        userSelect: "none",
                                                                                                    }}
                                                                                                >
                                                                                                    {profileUser.name}
                                                                                                </p>
                                                                                            </>
                                                                                        ) : (
                                                                                            ""
                                                                                        )
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </>
                                                                                &nbsp;&nbsp;
                                                                            </div>
                                                                            <div
                                                                                className="p-grid p-d-flex p-jc-end"
                                                                                style={{ marginBottom: "1.5rem", marginRight: "1rem" }}
                                                                            >
                                                                                <div
                                                                                    className="p-d-flex p-flex-column"
                                                                                    key="1"
                                                                                    style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
                                                                                >
                                                                                    <div className="p-d-flex">
                                                                                        <div className="p-d-flex p-jc-end">
                                                                                            <div
                                                                                                className="p-d-flex p-jc-end p-ai-center"
                                                                                                style={{
                                                                                                    backgroundColor: "white",

                                                                                                    border: "0.01rem solid #e4e5e7",
                                                                                                    borderRadius: "0.5rem 0.5rem 0rem 0.5rem",
                                                                                                    boxShadow: "0.04rem 0.04rem 0.06rem #d7d8db",
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="Container"
                                                                                                    style={{
                                                                                                        maxWidth: "15rem",
                                                                                                        marginBottom: "0.3rem",
                                                                                                        marginTop: "0.3rem",
                                                                                                        marginLeft: "0.3rem",
                                                                                                        marginRight: "0.3rem",
                                                                                                        wordBreak: "normal",
                                                                                                    }}
                                                                                                ></div>

                                                                                                <div
                                                                                                    className="p-d-flex p-as-end"
                                                                                                    style={{ fontSize: "0.65rem", userSelect: "none" }}
                                                                                                >
                                                                                                    <>
                                                                                                        <Moment format="HH:mm">
                                                                                                            {informationDetailMessageChat.created_at}
                                                                                                        </Moment>{" "}
                                                                                                        &nbsp;
                                                                                                        {messageReadConfirmation(
                                                                                                            informationDetailMessageChat
                                                                                                        )}{" "}
                                                                                                        &nbsp;
                                                                                                    </>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        &nbsp;
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* =========== DIALOG DIV INFORMATION MESSAGE*/}
                                                        <div
                                                            style={{
                                                                width: "auto",
                                                                height: "calc(100vh - 126px)",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                            }}
                                                        >
                                                            {/* HEADER CONTACT RIGHT */}
                                                            <div
                                                                style={{ height: "8%", backgroundColor: "", marginBottom: "0.5rem" }}
                                                                className="p-d-flex p-ai-center"
                                                            >
                                                                <div className="p-col-5 p-md-5 p-lg-5">
                                                                    <div className="p-d-flex">
                                                                        <p style={{ marginLeft: "1rem", fontSize: "1.3rem" }}>{roadMapSelected.name}</p>
                                                                    </div>
                                                                </div>

                                                                <div className="p-col-7 p-md-7 p-lg-7">
                                                                    <div className="p-d-flex  p-jc-end">
                                                                        {viewSearchMessagesButton === true ? (
                                                                            <>
                                                                                {viewButtonsSearchMessageUpOrDown ? (
                                                                                    <>
                                                                                        {countIndexSearchMessageFilter >= 0 &&
                                                                                        !isEmptyObj(chatMessagesSearch) ? (
                                                                                            <a
                                                                                                href={`#${chatMessagesSearch[countIndexSearchMessageFilter]}`}
                                                                                                onClick={() => {
                                                                                                    downScrollSearch()
                                                                                                    idSearchMessageDiv =
                                                                                                        chatMessagesSearch[countIndexSearchMessageFilter - 1]
                                                                                                    setIdSearchMessageDiv(idSearchMessageDiv)
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="p-d-flex p-jc-center p-ai-center"
                                                                                                    style={{
                                                                                                        width: "2rem",
                                                                                                        height: "2rem",
                                                                                                        borderRadius: "50%",
                                                                                                    }}
                                                                                                >
                                                                                                    <i
                                                                                                        className="pi pi-arrow-up"
                                                                                                        style={{ color: "#2196F3" }}
                                                                                                    ></i>
                                                                                                </div>
                                                                                            </a>
                                                                                        ) : (
                                                                                            <div></div>
                                                                                        )}
                                                                                        &nbsp;
                                                                                        {countIndexSearchMessageFilter < chatMessagesSearch.length ? (
                                                                                            <a
                                                                                                href={`#${chatMessagesSearch[countIndexSearchMessageFilter]}`}
                                                                                                onClick={() => {
                                                                                                    upScrollSearch()
                                                                                                    idSearchMessageDiv =
                                                                                                        chatMessagesSearch[countIndexSearchMessageFilter + 1]
                                                                                                    setIdSearchMessageDiv(idSearchMessageDiv)
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="p-d-flex p-jc-center p-ai-center"
                                                                                                    style={{
                                                                                                        width: "2rem",
                                                                                                        height: "2rem",
                                                                                                        borderRadius: "50%",
                                                                                                    }}
                                                                                                >
                                                                                                    <i
                                                                                                        className="pi pi-arrow-down"
                                                                                                        style={{ color: "#2196F3" }}
                                                                                                    ></i>
                                                                                                </div>
                                                                                            </a>
                                                                                        ) : (
                                                                                            <div></div>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                                &nbsp;
                                                                                <InputText
                                                                                    value={inputTextSearchMessagesChat}
                                                                                    id="autofocs"
                                                                                    className="inputTextInHeaderContact"
                                                                                    onKeyDown={(e: any) => {
                                                                                        handleKeyPressSearchMessage(e)
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        setInputTextSearchMessagesChat(e.target.value)
                                                                                    }}
                                                                                    autoFocus
                                                                                    placeholder="Pesquisar ...."
                                                                                />
                                                                                &nbsp;
                                                                                <Button
                                                                                    icon="pi pi-search"
                                                                                    className="p-button-rounded p-button-text"
                                                                                    onClick={() =>
                                                                                        chatMessageFilter(inputTextSearchMessagesChat, contactCompanySelected)
                                                                                    }
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Button
                                                                                    icon={"pi pi-pencil"}
                                                                                    className="p-button-rounded p-button-text"
                                                                                    aria-label="Submit"
                                                                                    onClick={() => {
                                                                                        setRoadMap(roadMapSelected)
                                                                                        if (roadMap.chatSubgroup) {
                                                                                            const group = groupsAttachments.find(
                                                                                                (group) => group.id === roadMap.chatSubgroup.chat_group_id
                                                                                            )
                                                                                            setSelectedGroup(group ? group : null)
                                                                                            console.log(selectedGroup)
                                                                                        }
                                                                                        setRoadMapEditDialog(true)
                                                                                    }}
                                                                                />
                                                                                <Button
                                                                                    icon={"pi pi-trash"}
                                                                                    className="p-button-rounded p-button-text"
                                                                                    loading={loadingDelete}
                                                                                    aria-label="Submit"
                                                                                    onClick={() => {
                                                                                        setDeleteAttachmentDialog(true)
                                                                                    }}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* MESSAGES BODY CONTACT RIGHT */}
                                                            <div className="messagesBodyContact" id="messagesBodyContact">
                                                                {mapOfMessagesRoudMap()}
                                                            </div>

                                                            {/* MESSAGE SEND CONTACT RIGHT */}
                                                            <div style={{ width: "100%" }} className="sendMessage p-d-flex p-ai-center">
                                                                <div className="sendMessage p-d-flex p-jc-center">
                                                                    <div>
                                                                        <Button
                                                                            icon="pi pi-comment"
                                                                            label="Mensagens"
                                                                            onClick={() => setMessagesDefault(true)}
                                                                            className="p-button-raised p-button-rounded"
                                                                        />
                                                                        &nbsp;&nbsp;
                                                                        <Button
                                                                            icon="pi pi-paperclip"
                                                                            label="Documentos"
                                                                            onClick={() => setDocumentDialog(true)}
                                                                            className="p-button-raised p-button-rounded"
                                                                        />
                                                                        &nbsp;&nbsp;
                                                                        <Button
                                                                            icon="pi pi-image"
                                                                            label="Imagens"
                                                                            onClick={() => setImageUploadDialog(true)}
                                                                            className="p-button-raised p-button-rounded"
                                                                        />
                                                                        &nbsp;&nbsp;
                                                                        <Button
                                                                            icon="pi pi-paperclip"
                                                                            label="Vídeos"
                                                                            onClick={() => setVideoDialog(true)}
                                                                            className="p-button-raised p-button-rounded"
                                                                        />
                                                                        &nbsp;&nbsp;
                                                                        {roadMapSelected.chatSubgroup?.name !== "TRANSMISSÃO" && (
                                                                            <Button
                                                                                icon="pi pi-volume-up"
                                                                                label="Áudios"
                                                                                onClick={() => setAudioUploadDialog(true)}
                                                                                className="p-button-raised p-button-rounded"
                                                                            />
                                                                        )}
                                                                        &nbsp;&nbsp;
                                                                        {roadMapSelected.chatSubgroup?.name !== "TRANSMISSÃO" && (
                                                                            <Button
                                                                                icon="pi pi-stop"
                                                                                onClick={() => setStickerUploadDialog(true)}
                                                                                label="Stickers"
                                                                                className="p-button-raised p-button-rounded"
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {isEmptyObj(roadMapSelected) && (
                                                <div
                                                    className="rightSideEmpty"
                                                    style={{ backgroundColor: "rgb(175, 176, 178)", height: "calc(100vh - 125px)" }}
                                                >
                                                    <div
                                                        className="p-d-flex p-ai-center p-jc-center"
                                                        style={{
                                                            height: "calc(100vh - 125px)",
                                                            backgroundColor: " #f7f7f8",
                                                            boxShadow: "inset 0 0 0.2em #d7d8db, 0 0 0",
                                                        }}
                                                    >
                                                        <div className="p-col-12 p-md-12 p-lg-12" style={{ margin: "0.8rem" }}>
                                                            <div className="p-d-flex p-ai-center p-jc-center">
                                                                <i className="pi pi-desktop" style={{ fontSize: "2em", opacity: "60%" }}></i>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <i className="pi pi-globe" style={{ fontSize: "2em", opacity: "60%" }}></i>
                                                            </div>

                                                            <br />

                                                            <div
                                                                className="p-d-flex p-ai-center p-jc-center"
                                                                style={{ fontWeight: "600", opacity: "60%", userSelect: "none" }}
                                                            >
                                                                CLIQUE PARA ABRIR UM ROTEIRO
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* View image Chat Dialog */}
            <Dialog visible={deleteMessageOnRoadMap} dismissableMask={true} style={{ maxWidth: "55vw" }} onHide={hideDialog} draggable={false}>
                <div>
                    <strong className="p-d-flex p-mb-4"> EXCLUIR MENSAGEM DO ROTEIRO </strong>
                    <div className="p-d-flex p-jc-center">
                        <Button label="CANCELAR" icon="pi pi-check" className="p-mr-2" onClick={hideDialog} />
                        <Button
                            label="EXCLUIR"
                            icon="pi pi-trash"
                            style={{ background: "red", border: "none" }}
                            onClick={() => deleteMessageRoadMap(handleDeleteMessageRoadMap)}
                        />
                    </div>
                </div>
            </Dialog>

            {/* New message client DataTable */}
            <Dialog
                visible={newMessageDialog}
                dismissableMask={true}
                modal
                style={{ width: "450px" }}
                header={"Criar Novo Roteiro de Mensagens"}
                className="p-fluid"
                onHide={hideDialog}
                footer={createDefaultMessageFooter}
                draggable={false}
            >
                <div className="p-field">
                    <label htmlFor="name">Nome da Rotina:</label>
                    <InputText
                        required
                        id="name"
                        name="name"
                        autoFocus
                        type="String"
                        maxLength={35}
                        onChange={onChangeCreateAttachmentsName}
                        value={createValues.name || ""}
                    />
                </div>

                <br />
                <div className="p-field">
                    <label htmlFor="description">Categoria:</label>
                    <Dropdown
                        id="group"
                        name="group"
                        required
                        options={groupsAttachments}
                        optionLabel="name"
                        style={{ minWidth: "20rem" }}
                        onChange={onChangeCreateAttachments}
                        value={createValues.group || ""}
                        placeholder="Sem Categoria"
                    />
                </div>
                {createValues.group?.id !== undefined && createValues.group?.id !== "000001" && (
                    <>
                        <br />
                        <div className="p-field">
                            <label htmlFor="description">Subcategoria:</label>
                            <Dropdown
                                id="group"
                                name="chat_subgroup_id"
                                required
                                options={filterSubGroups(createValues.group)}
                                optionLabel="name"
                                style={{ minWidth: "20rem" }}
                                onChange={onChangeCreateAttachments}
                                value={createValues.chat_subgroup_id || ""}
                                placeholder="Selecionar"
                            />
                        </div>
                    </>
                )}
            </Dialog>

            {/* Image view files dialog*/}
            <Dialog
                visible={imageUploadDialog}
                dismissableMask={true}
                style={{ width: "1200px" }}
                modal
                className="p-fluid"
                onHide={() => {
                    setImageUploadDialog(false)
                }}
                header={`Selecionar uma imagem: ${roadMapSelected !== undefined && roadMapSelected !== null ? roadMapSelected.name : ""}`}
                draggable={false}
            >
                <TabView scrollable>
                    {tabPannel.map((priceTable: RoadMapAttachmentInterface, indexPriceTable: number) => (
                        <TabPanel key={indexPriceTable} header={priceTable.name}>
                            {priceTable.id !== "000001" ? (
                                <TabView scrollable>
                                    {subGroups
                                        .filter((a: ChatSubgroupInterface) => a.chat_group_id === priceTable.id)
                                        .map((subGroup) => (
                                            <TabPanel key={subGroup.id} header={subGroup.name}>
                                                <div className="p-grid" style={{ paddingBottom: "30px" }}>
                                                    {attachments
                                                        .filter((a: AttachmentInterface) => a.chat_subgroup_id === subGroup.id && a.type_filter === "image")
                                                        .map((image) => (
                                                            <>
                                                                <br /> <br />
                                                                <div className="p-mb-3">
                                                                    <div style={{ margin: "1rem" }}>
                                                                        <br /> <br />
                                                                        <Avatar
                                                                            style={{ width: "20rem", height: "20rem", margin: "8px" }}
                                                                            className="p-overlay-badge"
                                                                            image={image.file_url}
                                                                            size="xlarge"
                                                                            shape="square"
                                                                        ></Avatar>
                                                                        <h6 style={{ margin: "0.4rem", textAlign: "center" }}>{image.name}</h6>
                                                                    </div>
                                                                    <br />
                                                                    <Button
                                                                        label="Selecionar"
                                                                        className="p-button-rounded"
                                                                        style={{
                                                                            width: "90px",
                                                                            textAlign: "center",
                                                                            display: "block",
                                                                            marginLeft: "auto",
                                                                            marginRight: "auto",
                                                                        }}
                                                                        onClick={() => {
                                                                            setLoadingSendMessageOnRoadMap(true)
                                                                            let index =
                                                                                auxRoadMapSelected.messages !== undefined
                                                                                    ? auxRoadMapSelected.messages.length
                                                                                    : 0
                                                                            let sequenceNumber
                                                                            if (index === 0) {
                                                                                sequenceNumber = 0
                                                                            } else {
                                                                                sequenceNumber = auxRoadMapSelected.messages[index - 1].sequence
                                                                            }
                                                                            let dataMessage: MessagesRoadMapInterface = {
                                                                                type: "attachment",
                                                                                attachment_id: image.id,
                                                                                default_message_id: undefined,
                                                                                sequence: sequenceNumber + 1,
                                                                                file_url: image.file_url,
                                                                                attachment: {
                                                                                    id: image.id,
                                                                                    name: image.name,
                                                                                    description: "",
                                                                                    opportunity_id: "",
                                                                                    opportunity: {} as OpportunityInterface,
                                                                                    file: image.file,
                                                                                    created_at: new Date(),
                                                                                    updated_at: new Date(),
                                                                                    file_url: image.file_url,
                                                                                    type: image.type,
                                                                                    group: {} as GropuInterface,
                                                                                    chat_group_id: image.chat_group_id,
                                                                                    text: "",
                                                                                    chat_subgroup_id: "",
                                                                                    chatGroup: {} as GropuInterface,
                                                                                    type_filter: image.type,
                                                                                    chatSubgroup: {} as GropuInterface,
                                                                                },
                                                                            }
                                                                            if (!auxRoadMapSelected.messages) {
                                                                                auxRoadMapSelected["messages"] = []
                                                                            }

                                                                            auxRoadMapSelected.messages.push(dataMessage)

                                                                            updateRoadMap(auxRoadMapSelected.messages, auxRoadMapSelected)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <br /> <br />
                                                            </>
                                                        ))}
                                                </div>
                                            </TabPanel>
                                        ))}
                                </TabView>
                            ) : (
                                <div>
                                    <div className="p-grid" style={{ paddingBottom: "30px" }}>
                                        {attachments
                                            .filter((a: AttachmentInterface) => a.chat_subgroup_id === null && a.type_filter === "image")
                                            .map((image) => (
                                                <>
                                                    <br /> <br />
                                                    <div className="p-mb-3">
                                                        <div style={{ margin: "1rem" }}>
                                                            <br /> <br />
                                                            <Avatar
                                                                style={{ width: "20rem", height: "20rem", margin: "8px" }}
                                                                className="p-overlay-badge"
                                                                image={image.file_url}
                                                                size="xlarge"
                                                                shape="square"
                                                            ></Avatar>
                                                            <h6 style={{ margin: "0.4rem", textAlign: "center" }}>{image.name}</h6>
                                                        </div>
                                                        <br />
                                                        <Button
                                                            label="Selecionar"
                                                            className="p-button-rounded"
                                                            style={{
                                                                width: "90px",
                                                                textAlign: "center",
                                                                display: "block",
                                                                marginLeft: "auto",
                                                                marginRight: "auto",
                                                            }}
                                                            onClick={() => {
                                                                setLoadingSendMessageOnRoadMap(true)
                                                                let index = auxRoadMapSelected.messages !== undefined ? auxRoadMapSelected.messages.length : 0
                                                                let sequenceNumber
                                                                if (index === 0) {
                                                                    sequenceNumber = 0
                                                                } else {
                                                                    sequenceNumber = auxRoadMapSelected.messages[index - 1].sequence
                                                                }
                                                                let dataMessage: MessagesRoadMapInterface = {
                                                                    type: "attachment",
                                                                    attachment_id: image.id,
                                                                    default_message_id: undefined,
                                                                    sequence: sequenceNumber + 1,
                                                                    file_url: image.file_url,
                                                                    attachment: {
                                                                        id: image.id,
                                                                        name: image.name,
                                                                        description: "",
                                                                        opportunity_id: "",
                                                                        opportunity: {} as OpportunityInterface,
                                                                        file: image.file,
                                                                        created_at: new Date(),
                                                                        updated_at: new Date(),
                                                                        file_url: image.file_url,
                                                                        type: image.type,
                                                                        group: {} as GropuInterface,
                                                                        chat_group_id: image.chat_group_id,
                                                                        text: "",
                                                                        chat_subgroup_id: "",
                                                                        chatGroup: {} as GropuInterface,
                                                                        type_filter: image.type,
                                                                        chatSubgroup: {} as GropuInterface,
                                                                    },
                                                                }
                                                                if (!auxRoadMapSelected.messages) {
                                                                    auxRoadMapSelected["messages"] = []
                                                                }

                                                                auxRoadMapSelected.messages.push(dataMessage)

                                                                updateRoadMap(auxRoadMapSelected.messages, auxRoadMapSelected)
                                                            }}
                                                        />
                                                    </div>
                                                    <br /> <br />
                                                </>
                                            ))}
                                    </div>
                                </div>
                            )}
                        </TabPanel>
                    ))}
                </TabView>
            </Dialog>

            {/* Video view files dialog*/}
            <Dialog
                visible={videoDialog}
                dismissableMask={true}
                style={{ width: "1200px" }}
                modal
                className="p-fluid"
                onHide={() => {
                    setVideoDialog(false)
                }}
                header={`Selecionar um vídeo : ${roadMapSelected !== undefined && roadMapSelected !== null ? roadMapSelected.name : ""}`}
                draggable={false}
            >
                <TabView scrollable>
                    {tabPannel.map((priceTable: RoadMapAttachmentInterface, indexPriceTable: number) => (
                        <TabPanel key={indexPriceTable} header={priceTable.name}>
                            {priceTable.id !== "000001" ? (
                                <TabView scrollable>
                                    {subGroups
                                        .filter((a: ChatSubgroupInterface) => a.chat_group_id === priceTable.id)
                                        .map((subGroup) => (
                                            <TabPanel key={subGroup.id} header={subGroup.name}>
                                                <div className="p-grid" style={{ paddingBottom: "30px" }}>
                                                    {attachments
                                                        .filter((a: AttachmentInterface) => a.chat_subgroup_id === subGroup.id && a.type_filter === "video")
                                                        .map((video) => (
                                                            <>
                                                                <br /> <br />
                                                                <div className="p-mb-3">
                                                                    <div style={{ margin: "1rem" }}>
                                                                        <br /> <br />
                                                                        <ReactPlayer
                                                                            autoplay
                                                                            url={video.file_url}
                                                                            width="20rem"
                                                                            height="10rem"
                                                                            controls={true}
                                                                        />
                                                                        <h6 style={{ margin: "0.4rem", textAlign: "center" }}>{video.name}</h6>
                                                                    </div>
                                                                    <br />
                                                                    <Button
                                                                        label="Selecionar"
                                                                        className="p-button-rounded"
                                                                        style={{
                                                                            width: "90px",
                                                                            textAlign: "center",
                                                                            display: "block",
                                                                            marginLeft: "auto",
                                                                            marginRight: "auto",
                                                                        }}
                                                                        onClick={() => {
                                                                            setLoadingSendMessageOnRoadMap(true)
                                                                            let index =
                                                                                auxRoadMapSelected.messages !== undefined
                                                                                    ? auxRoadMapSelected.messages.length
                                                                                    : 0
                                                                            let sequenceNumber
                                                                            if (index === 0) {
                                                                                sequenceNumber = 0
                                                                            } else {
                                                                                sequenceNumber = auxRoadMapSelected.messages[index - 1].sequence
                                                                            }
                                                                            let dataMessage: MessagesRoadMapInterface = {
                                                                                type: "attachment",
                                                                                attachment_id: video.id,
                                                                                default_message_id: undefined,
                                                                                sequence: sequenceNumber + 1,
                                                                                file_url: video.file_url,
                                                                                attachment: {
                                                                                    id: video.id,
                                                                                    name: video.name,
                                                                                    description: "",
                                                                                    opportunity_id: "",
                                                                                    opportunity: {} as OpportunityInterface,
                                                                                    file: video.file,
                                                                                    created_at: new Date(),
                                                                                    updated_at: new Date(),
                                                                                    file_url: video.file_url,
                                                                                    type: video.type,
                                                                                    group: {} as GropuInterface,
                                                                                    chat_group_id: video.chat_group_id,
                                                                                    text: "",
                                                                                    chat_subgroup_id: "",
                                                                                    chatGroup: {} as GropuInterface,
                                                                                    type_filter: video.type,
                                                                                    chatSubgroup: {} as GropuInterface,
                                                                                },
                                                                            }
                                                                            if (!auxRoadMapSelected.messages) {
                                                                                auxRoadMapSelected["messages"] = []
                                                                            }

                                                                            auxRoadMapSelected.messages.push(dataMessage)

                                                                            updateRoadMap(auxRoadMapSelected.messages, auxRoadMapSelected)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <br /> <br />
                                                            </>
                                                        ))}
                                                </div>
                                            </TabPanel>
                                        ))}
                                </TabView>
                            ) : (
                                <div>
                                    <div className="p-grid" style={{ paddingBottom: "30px" }}>
                                        {attachments
                                            .filter((a: AttachmentInterface) => a.chat_subgroup_id === null && a.type_filter === "video")
                                            .map((video) => (
                                                <>
                                                    <br /> <br />
                                                    <div className="p-mb-3">
                                                        <div style={{ margin: "1rem" }}>
                                                            <br /> <br />
                                                            <ReactPlayer autoplay url={video.file_url} width="20rem" height="10rem" controls={true} />
                                                            <h6 style={{ margin: "0.4rem", textAlign: "center" }}>{video.name}</h6>
                                                        </div>
                                                        <br />
                                                        <Button
                                                            label="Selecionar"
                                                            className="p-button-rounded"
                                                            style={{
                                                                width: "90px",
                                                                textAlign: "center",
                                                                display: "block",
                                                                marginLeft: "auto",
                                                                marginRight: "auto",
                                                            }}
                                                            onClick={() => {
                                                                setLoadingSendMessageOnRoadMap(true)
                                                                let index = auxRoadMapSelected.messages !== undefined ? auxRoadMapSelected.messages.length : 0
                                                                let sequenceNumber
                                                                if (index === 0) {
                                                                    sequenceNumber = 0
                                                                } else {
                                                                    sequenceNumber = auxRoadMapSelected.messages[index - 1].sequence
                                                                }
                                                                let dataMessage: MessagesRoadMapInterface = {
                                                                    type: "attachment",
                                                                    attachment_id: video.id,
                                                                    default_message_id: undefined,
                                                                    sequence: sequenceNumber + 1,
                                                                    file_url: video.file_url,
                                                                    attachment: {
                                                                        id: video.id,
                                                                        name: video.name,
                                                                        description: "",
                                                                        opportunity_id: "",
                                                                        opportunity: {} as OpportunityInterface,
                                                                        file: video.file,
                                                                        created_at: new Date(),
                                                                        updated_at: new Date(),
                                                                        file_url: video.file_url,
                                                                        type: video.type,
                                                                        group: {} as GropuInterface,
                                                                        chat_group_id: video.chat_group_id,
                                                                        text: "",
                                                                        chat_subgroup_id: "",
                                                                        chatGroup: {} as GropuInterface,
                                                                        type_filter: video.type,
                                                                        chatSubgroup: {} as GropuInterface,
                                                                    },
                                                                }
                                                                if (!auxRoadMapSelected.messages) {
                                                                    auxRoadMapSelected["messages"] = []
                                                                }

                                                                auxRoadMapSelected.messages.push(dataMessage)

                                                                updateRoadMap(auxRoadMapSelected.messages, auxRoadMapSelected)
                                                            }}
                                                        />
                                                    </div>
                                                    <br /> <br />
                                                </>
                                            ))}
                                    </div>
                                </div>
                            )}
                        </TabPanel>
                    ))}
                </TabView>
            </Dialog>

            {/* Sticker view files dialog*/}
            <Dialog
                visible={stickerUploadDialog}
                dismissableMask={true}
                style={{ width: "1200px" }}
                modal
                className="p-fluid"
                onHide={() => {
                    setStickerUploadDialog(false)
                }}
                header={`Selecionar um sticker: ${roadMapSelected !== undefined && roadMapSelected !== null ? roadMapSelected.name : ""}`}
                draggable={false}
            >
                <TabView scrollable>
                    {tabPannel.map((priceTable: RoadMapAttachmentInterface, indexPriceTable: number) => (
                        <TabPanel key={indexPriceTable} header={priceTable.name}>
                            {priceTable.id !== "000001" ? (
                                <TabView scrollable>
                                    {subGroups
                                        .filter((a: ChatSubgroupInterface) => a.chat_group_id === priceTable.id)
                                        .map((subGroup) => (
                                            <TabPanel key={subGroup.id} header={subGroup.name}>
                                                <div className="p-grid" style={{ paddingBottom: "30px" }}>
                                                    {attachments
                                                        .filter((a: AttachmentInterface) => a.chat_subgroup_id === subGroup.id && a.type_filter === "sticker")
                                                        .map((image) => (
                                                            <>
                                                                <br /> <br />
                                                                <div className="p-mb-3">
                                                                    <div style={{ margin: "1rem" }}>
                                                                        <br /> <br />
                                                                        <Avatar
                                                                            style={{ width: "20rem", height: "20rem", margin: "8px" }}
                                                                            className="p-overlay-badge"
                                                                            image={image.file_url}
                                                                            size="xlarge"
                                                                            shape="square"
                                                                        ></Avatar>
                                                                        <h6 style={{ margin: "0.4rem", textAlign: "center" }}>{image.name}</h6>
                                                                    </div>
                                                                    <br />
                                                                    <Button
                                                                        label="Selecionar"
                                                                        className="p-button-rounded"
                                                                        style={{
                                                                            width: "90px",
                                                                            textAlign: "center",
                                                                            display: "block",
                                                                            marginLeft: "auto",
                                                                            marginRight: "auto",
                                                                        }}
                                                                        onClick={() => {
                                                                            setLoadingSendMessageOnRoadMap(true)
                                                                            let index =
                                                                                auxRoadMapSelected.messages !== undefined
                                                                                    ? auxRoadMapSelected.messages.length
                                                                                    : 0
                                                                            let sequenceNumber
                                                                            if (index === 0) {
                                                                                sequenceNumber = 0
                                                                            } else {
                                                                                sequenceNumber = auxRoadMapSelected.messages[index - 1].sequence
                                                                            }
                                                                            let dataMessage: MessagesRoadMapInterface = {
                                                                                type: "attachment",
                                                                                attachment_id: image.id,
                                                                                default_message_id: undefined,
                                                                                sequence: sequenceNumber + 1,
                                                                                file_url: image.file_url,
                                                                                attachment: {
                                                                                    id: image.id,
                                                                                    name: image.name,
                                                                                    description: "",
                                                                                    opportunity_id: "",
                                                                                    opportunity: {} as OpportunityInterface,
                                                                                    file: image.file,
                                                                                    created_at: new Date(),
                                                                                    updated_at: new Date(),
                                                                                    file_url: image.file_url,
                                                                                    type: image.type,
                                                                                    group: {} as GropuInterface,
                                                                                    chat_group_id: image.chat_group_id,
                                                                                    text: "",
                                                                                    chat_subgroup_id: "",
                                                                                    chatGroup: {} as GropuInterface,
                                                                                    type_filter: image.type,
                                                                                    chatSubgroup: {} as GropuInterface,
                                                                                },
                                                                            }
                                                                            if (!auxRoadMapSelected.messages) {
                                                                                auxRoadMapSelected["messages"] = []
                                                                            }

                                                                            auxRoadMapSelected.messages.push(dataMessage)

                                                                            updateRoadMap(auxRoadMapSelected.messages, auxRoadMapSelected)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <br /> <br />
                                                            </>
                                                        ))}
                                                </div>
                                            </TabPanel>
                                        ))}
                                </TabView>
                            ) : (
                                <div>
                                    <div className="p-grid" style={{ paddingBottom: "30px" }}>
                                        {attachments
                                            .filter((a: AttachmentInterface) => a.chat_subgroup_id === null && a.type_filter === "sticker")
                                            .map((image) => (
                                                <>
                                                    <br /> <br />
                                                    <div className="p-mb-3">
                                                        <div style={{ margin: "1rem" }}>
                                                            <br /> <br />
                                                            <Avatar
                                                                style={{ width: "20rem", height: "20rem", margin: "8px" }}
                                                                className="p-overlay-badge"
                                                                image={image.file_url}
                                                                size="xlarge"
                                                                shape="square"
                                                            ></Avatar>
                                                            <h6 style={{ margin: "0.4rem", textAlign: "center" }}>{image.name}</h6>
                                                        </div>
                                                        <br />
                                                        <Button
                                                            label="Selecionar"
                                                            className="p-button-rounded"
                                                            style={{
                                                                width: "90px",
                                                                textAlign: "center",
                                                                display: "block",
                                                                marginLeft: "auto",
                                                                marginRight: "auto",
                                                            }}
                                                            onClick={() => {
                                                                setLoadingSendMessageOnRoadMap(true)
                                                                let index = auxRoadMapSelected.messages !== undefined ? auxRoadMapSelected.messages.length : 0
                                                                let sequenceNumber
                                                                if (index === 0) {
                                                                    sequenceNumber = 0
                                                                } else {
                                                                    sequenceNumber = auxRoadMapSelected.messages[index - 1].sequence
                                                                }
                                                                let dataMessage: MessagesRoadMapInterface = {
                                                                    type: "attachment",
                                                                    attachment_id: image.id,
                                                                    default_message_id: undefined,
                                                                    sequence: sequenceNumber + 1,
                                                                    file_url: image.file_url,
                                                                    attachment: {
                                                                        id: image.id,
                                                                        name: image.name,
                                                                        description: "",
                                                                        opportunity_id: "",
                                                                        opportunity: {} as OpportunityInterface,
                                                                        file: image.file,
                                                                        created_at: new Date(),
                                                                        updated_at: new Date(),
                                                                        file_url: image.file_url,
                                                                        type: image.type,
                                                                        group: {} as GropuInterface,
                                                                        chat_group_id: image.chat_group_id,
                                                                        text: "",
                                                                        chat_subgroup_id: "",
                                                                        chatGroup: {} as GropuInterface,
                                                                        type_filter: image.type,
                                                                        chatSubgroup: {} as GropuInterface,
                                                                    },
                                                                }
                                                                if (!auxRoadMapSelected.messages) {
                                                                    auxRoadMapSelected["messages"] = []
                                                                }

                                                                auxRoadMapSelected.messages.push(dataMessage)

                                                                updateRoadMap(auxRoadMapSelected.messages, auxRoadMapSelected)
                                                            }}
                                                        />
                                                    </div>
                                                    <br /> <br />
                                                </>
                                            ))}
                                    </div>
                                </div>
                            )}
                        </TabPanel>
                    ))}
                </TabView>
            </Dialog>

            {/* Audio view files dialog*/}
            <Dialog
                visible={audioUploadDialog}
                dismissableMask={true}
                style={{ width: "1200px" }}
                modal
                className="p-fluid"
                onHide={() => {
                    setAudioUploadDialog(false)
                }}
                header={`Selecionar um áudio: ${roadMapSelected !== undefined && roadMapSelected !== null ? roadMapSelected.name : ""}`}
                draggable={false}
            >
                <TabView scrollable>
                    {tabPannel.map((priceTable: RoadMapAttachmentInterface, indexPriceTable: number) => (
                        <TabPanel key={indexPriceTable} header={priceTable.name}>
                            {priceTable.id !== "000001" ? (
                                <TabView scrollable>
                                    {subGroups
                                        .filter((a: ChatSubgroupInterface) => a.chat_group_id === priceTable.id)
                                        .map((subGroup) => (
                                            <TabPanel key={subGroup.id} header={subGroup.name}>
                                                <div className="p-grid" style={{ paddingBottom: "30px" }}>
                                                    {attachments
                                                        .filter((a: AttachmentInterface) => a.chat_subgroup_id === subGroup.id && a.type_filter === "audio")
                                                        .map((image) => (
                                                            <>
                                                                <br /> <br />
                                                                <div className="p-mb-3">
                                                                    <div style={{ margin: "1rem" }}>
                                                                        <br /> <br />
                                                                        <Avatar
                                                                            style={{
                                                                                width: "20rem",
                                                                                marginTop: "20px",
                                                                                height: "20rem",
                                                                                margin: "8px",
                                                                                backgroundColor: "#c2d6f0",
                                                                                textAlign: "center",
                                                                                fontSize: "20px",
                                                                            }}
                                                                            className="p-overlay-badge"
                                                                            label={image.name}
                                                                            size="xlarge"
                                                                            shape="square"
                                                                        ></Avatar>
                                                                    </div>
                                                                    <audio controls style={{ marginLeft: "12px" }}>
                                                                        <source src={image.file_url} />
                                                                    </audio>
                                                                    <br /> <br />
                                                                    <Button
                                                                        label="Selecionar"
                                                                        className="p-button-rounded"
                                                                        style={{
                                                                            width: "90px",
                                                                            textAlign: "center",
                                                                            display: "block",
                                                                            marginLeft: "auto",
                                                                            marginRight: "auto",
                                                                        }}
                                                                        onClick={() => {
                                                                            setLoadingSendMessageOnRoadMap(true)
                                                                            {
                                                                                let index =
                                                                                    auxRoadMapSelected.messages !== undefined
                                                                                        ? auxRoadMapSelected.messages.length
                                                                                        : 0
                                                                                let sequenceNumber
                                                                                if (index === 0) {
                                                                                    sequenceNumber = 0
                                                                                } else {
                                                                                    sequenceNumber = auxRoadMapSelected.messages[index - 1].sequence
                                                                                }
                                                                                let dataMessage: MessagesRoadMapInterface = {
                                                                                    type: "attachment",
                                                                                    attachment_id: image.id,
                                                                                    default_message_id: undefined,
                                                                                    sequence: sequenceNumber + 1,
                                                                                    file_url: image.file_url,
                                                                                    attachment: {
                                                                                        id: image.id,
                                                                                        name: image.name,
                                                                                        description: "",
                                                                                        opportunity_id: "",
                                                                                        opportunity: {} as OpportunityInterface,
                                                                                        file: image.file,
                                                                                        created_at: new Date(),
                                                                                        updated_at: new Date(),
                                                                                        file_url: image.file_url,
                                                                                        type: image.type,
                                                                                        group: {} as GropuInterface,
                                                                                        chat_group_id: image.chat_group_id,
                                                                                        text: "",
                                                                                        chat_subgroup_id: "",
                                                                                        chatGroup: {} as GropuInterface,
                                                                                        type_filter: image.type,
                                                                                        chatSubgroup: {} as GropuInterface,
                                                                                    },
                                                                                }
                                                                                if (!auxRoadMapSelected.messages) {
                                                                                    auxRoadMapSelected["messages"] = []
                                                                                }

                                                                                auxRoadMapSelected.messages.push(dataMessage)

                                                                                updateRoadMap(auxRoadMapSelected.messages, auxRoadMapSelected)
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                                <br /> <br />
                                                            </>
                                                        ))}
                                                </div>
                                            </TabPanel>
                                        ))}
                                </TabView>
                            ) : (
                                <div>
                                    <div className="p-grid" style={{ paddingBottom: "30px" }}>
                                        {attachments
                                            .filter((a: AttachmentInterface) => a.chat_subgroup_id === null && a.type_filter === "audio")
                                            .map((image) => (
                                                <>
                                                    <br /> <br />
                                                    <div className="p-mb-3">
                                                        <div style={{ margin: "1rem" }}>
                                                            <br /> <br />
                                                            <Avatar
                                                                style={{
                                                                    width: "20rem",
                                                                    marginTop: "20px",
                                                                    height: "20rem",
                                                                    margin: "8px",
                                                                    backgroundColor: "#c2d6f0",
                                                                    textAlign: "center",
                                                                    fontSize: "20px",
                                                                }}
                                                                className="p-overlay-badge"
                                                                label={image.name}
                                                                size="xlarge"
                                                                shape="square"
                                                            ></Avatar>
                                                            <audio controls>
                                                                <source src={image.file_url} />
                                                            </audio>
                                                        </div>
                                                        <br /> <br />
                                                        <Button
                                                            label="Selecionar"
                                                            className="p-button-rounded"
                                                            style={{
                                                                width: "90px",
                                                                textAlign: "center",
                                                                display: "block",
                                                                marginLeft: "auto",
                                                                marginRight: "auto",
                                                            }}
                                                            onClick={() => {
                                                                setLoadingSendMessageOnRoadMap(true)
                                                                {
                                                                    let index =
                                                                        auxRoadMapSelected.messages !== undefined ? auxRoadMapSelected.messages.length : 0
                                                                    let sequenceNumber
                                                                    if (index === 0) {
                                                                        sequenceNumber = 0
                                                                    } else {
                                                                        sequenceNumber = auxRoadMapSelected.messages[index - 1].sequence
                                                                    }
                                                                    let dataMessage: MessagesRoadMapInterface = {
                                                                        type: "attachment",
                                                                        attachment_id: image.id,
                                                                        default_message_id: undefined,
                                                                        sequence: sequenceNumber + 1,
                                                                        file_url: image.file_url,
                                                                        attachment: {
                                                                            id: image.id,
                                                                            name: image.name,
                                                                            description: "",
                                                                            opportunity_id: "",
                                                                            opportunity: {} as OpportunityInterface,
                                                                            file: image.file,
                                                                            created_at: new Date(),
                                                                            updated_at: new Date(),
                                                                            file_url: image.file_url,
                                                                            type: image.type,
                                                                            group: {} as GropuInterface,
                                                                            chat_group_id: image.chat_group_id,
                                                                            text: "",
                                                                            chat_subgroup_id: "",
                                                                            chatGroup: {} as GropuInterface,
                                                                            type_filter: image.type,
                                                                            chatSubgroup: {} as GropuInterface,
                                                                        },
                                                                    }
                                                                    if (!auxRoadMapSelected.messages) {
                                                                        auxRoadMapSelected["messages"] = []
                                                                    }

                                                                    auxRoadMapSelected.messages.push(dataMessage)

                                                                    updateRoadMap(auxRoadMapSelected.messages, auxRoadMapSelected)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <br /> <br />
                                                </>
                                            ))}
                                    </div>
                                </div>
                            )}
                        </TabPanel>
                    ))}
                </TabView>
            </Dialog>

            {/* Document view files dialog*/}
            <Dialog
                visible={documentDialog}
                dismissableMask={true}
                style={{ width: "1200px" }}
                modal
                className="p-fluid"
                onHide={() => {
                    setDocumentDialog(false)
                }}
                header={`Selecionar um documento: ${roadMapSelected !== undefined && roadMapSelected !== null ? roadMapSelected.name : ""}`}
                draggable={false}
            >
                <TabView scrollable>
                    {tabPannel.map((priceTable: RoadMapAttachmentInterface, indexPriceTable: number) => (
                        <TabPanel key={indexPriceTable} header={priceTable.name}>
                            {priceTable.id !== "000001" ? (
                                <TabView scrollable>
                                    {subGroups
                                        .filter((a: ChatSubgroupInterface) => a.chat_group_id === priceTable.id)
                                        .map((subGroup) => (
                                            <TabPanel key={subGroup.id} header={subGroup.name}>
                                                <div className="p-grid" style={{ paddingBottom: "30px" }}>
                                                    {attachments
                                                        .filter((a: AttachmentInterface) => a.chat_subgroup_id === subGroup.id && a.type_filter === "document")
                                                        .map((image) => (
                                                            <>
                                                                <br /> <br />
                                                                <div className="p-mb-3">
                                                                    <div style={{ margin: "1rem" }}>
                                                                        <br /> <br />
                                                                        {roadMapDocumentDialog(image)}
                                                                        <h6 style={{ margin: "0.4rem", textAlign: "center" }}>{image.name}</h6>
                                                                    </div>
                                                                    <br />
                                                                    <Button
                                                                        label="Selecionar"
                                                                        className="p-button-rounded"
                                                                        style={{
                                                                            width: "90px",
                                                                            textAlign: "center",
                                                                            display: "block",
                                                                            marginLeft: "auto",
                                                                            marginRight: "auto",
                                                                        }}
                                                                        onClick={() => {
                                                                            setLoadingSendMessageOnRoadMap(true)
                                                                            {
                                                                                let index =
                                                                                    auxRoadMapSelected.messages !== undefined
                                                                                        ? auxRoadMapSelected.messages.length
                                                                                        : 0
                                                                                let sequenceNumber
                                                                                if (index === 0) {
                                                                                    sequenceNumber = 0
                                                                                } else {
                                                                                    sequenceNumber = auxRoadMapSelected.messages[index - 1].sequence
                                                                                }
                                                                                let dataMessage: MessagesRoadMapInterface = {
                                                                                    type: "attachment",
                                                                                    attachment_id: image.id,
                                                                                    default_message_id: undefined,
                                                                                    sequence: sequenceNumber + 1,
                                                                                    attachment: {
                                                                                        id: image.id,
                                                                                        name: image.name,
                                                                                        description: "",
                                                                                        opportunity_id: "",
                                                                                        opportunity: {} as OpportunityInterface,
                                                                                        file: image.file,
                                                                                        created_at: new Date(),
                                                                                        updated_at: new Date(),
                                                                                        file_url: image.file_url,
                                                                                        type: image.type,
                                                                                        group: {} as GropuInterface,
                                                                                        chat_group_id: image.chat_group_id,
                                                                                        text: "",
                                                                                        chat_subgroup_id: "",
                                                                                        chatGroup: {} as GropuInterface,
                                                                                        type_filter: image.type,
                                                                                        chatSubgroup: {} as GropuInterface,
                                                                                    },
                                                                                }

                                                                                if (!auxRoadMapSelected.messages) {
                                                                                    auxRoadMapSelected["messages"] = []
                                                                                }

                                                                                auxRoadMapSelected.messages.push(dataMessage)

                                                                                updateRoadMap(auxRoadMapSelected.messages, auxRoadMapSelected)
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                                <br /> <br />
                                                            </>
                                                        ))}
                                                </div>
                                            </TabPanel>
                                        ))}
                                </TabView>
                            ) : (
                                <div>
                                    <div className="p-grid" style={{ paddingBottom: "30px" }}>
                                        {attachments
                                            .filter((a: AttachmentInterface) => a.chat_subgroup_id === null && a.type_filter === "document")
                                            .map((image) => (
                                                <>
                                                    <br /> <br />
                                                    <div className="p-mb-3">
                                                        <div style={{ margin: "1rem" }}>
                                                            <br /> <br />
                                                            {roadMapDocumentDialog(image)}
                                                            <h6 style={{ margin: "0.4rem", textAlign: "center" }}>{image.name}</h6>
                                                        </div>
                                                        <br />
                                                        <Button
                                                            label="Selecionar"
                                                            className="p-button-rounded"
                                                            style={{
                                                                width: "90px",
                                                                textAlign: "center",
                                                                display: "block",
                                                                marginLeft: "auto",
                                                                marginRight: "auto",
                                                            }}
                                                            onClick={() => {
                                                                setLoadingSendMessageOnRoadMap(true)
                                                                {
                                                                    let index =
                                                                        auxRoadMapSelected.messages !== undefined ? auxRoadMapSelected.messages.length : 0
                                                                    let sequenceNumber
                                                                    if (index === 0) {
                                                                        sequenceNumber = 0
                                                                    } else {
                                                                        sequenceNumber = auxRoadMapSelected.messages[index - 1].sequence
                                                                    }
                                                                    let dataMessage: MessagesRoadMapInterface = {
                                                                        type: "attachment",
                                                                        attachment_id: image.id,
                                                                        default_message_id: undefined,
                                                                        sequence: sequenceNumber + 1,
                                                                        attachment: {
                                                                            id: image.id,
                                                                            name: image.name,
                                                                            description: "",
                                                                            opportunity_id: "",
                                                                            opportunity: {} as OpportunityInterface,
                                                                            file: image.file,
                                                                            created_at: new Date(),
                                                                            updated_at: new Date(),
                                                                            file_url: image.file_url,
                                                                            type: image.type,
                                                                            group: {} as GropuInterface,
                                                                            chat_group_id: image.chat_group_id,
                                                                            text: "",
                                                                            chat_subgroup_id: "",
                                                                            chatGroup: {} as GropuInterface,
                                                                            type_filter: image.type,
                                                                            chatSubgroup: {} as GropuInterface,
                                                                        },
                                                                    }

                                                                    if (!auxRoadMapSelected.messages) {
                                                                        auxRoadMapSelected["messages"] = []
                                                                    }

                                                                    auxRoadMapSelected.messages.push(dataMessage)

                                                                    updateRoadMap(auxRoadMapSelected.messages, auxRoadMapSelected)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <br /> <br />
                                                </>
                                            ))}
                                    </div>
                                </div>
                            )}
                        </TabPanel>
                    ))}
                </TabView>
            </Dialog>

            {/* Menssages Default view files dialog*/}
            <Dialog
                visible={messagesDefault}
                dismissableMask={messagesDefault}
                style={{ width: "80%", height: "70%" }}
                modal
                className="p-fluid"
                onHide={() => {
                    setMessagesDefault(false)
                }}
                header={`Escolher uma mensagem padrão para: ${roadMapSelected !== undefined && roadMapSelected !== null ? roadMapSelected.name : ""}`}
                draggable={false}
            >
                <TabView scrollable>
                    {tabPannel.map((priceTable: RoadMapAttachmentInterface, indexPriceTable: number) => (
                        <TabPanel key={indexPriceTable} header={priceTable.name}>
                            {priceTable.id !== "000001" ? (
                                <TabView scrollable>
                                    {subGroups
                                        .filter((a: ChatSubgroupInterface) => a.chat_group_id === priceTable.id)
                                        .map((subGroup) => (
                                            <TabPanel key={subGroup.id} header={subGroup.name}>
                                                <div>
                                                    {defaltMenssages
                                                        .filter((a: DefaultMessageInterface) => a.chat_subgroup_id === subGroup.id)
                                                        .map((defaultMessage) => (
                                                            <>
                                                                <br /> <br />
                                                                <div className="" style={{ paddingTop: "1rem", marginLeft: "auto", marginRight: "auto" }}>
                                                                    <div className="p-d-flex" style={{ display: "flex", justifyContent: "center" }}>
                                                                        <div className="">
                                                                            <div
                                                                                style={{
                                                                                    border: "0.01rem solid #e4e5e7",
                                                                                    borderRadius: "0.8rem 0.8rem 0rem 0.8rem",
                                                                                    boxShadow: "0.04rem 0.04rem 0.06rem #d7d8db",
                                                                                }}
                                                                            >
                                                                                {defaultMessage.text.includes("https://") ||
                                                                                    (defaultMessage.text.includes("http://") && (
                                                                                        <iframe src={defaultMessage.text} width="100%" height="100%" />
                                                                                    ))}

                                                                                <div className="p-d-flex p-jc-end p-ai-center">
                                                                                    <div
                                                                                        className="Container"
                                                                                        style={{
                                                                                            fontSize: "1.1rem",
                                                                                            textRendering: "optimizeLegibility",
                                                                                            maxWidth: `${widthWindow <= 414 ? "15rem" : "45rem"}`,
                                                                                            marginBottom: "0.5rem",
                                                                                            marginLeft: "0.5rem",
                                                                                            marginRight: "0.5rem",
                                                                                            wordBreak: "normal",
                                                                                        }}
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html:
                                                                                                defaultMessage !== undefined
                                                                                                    ? defaultMessage.text !== undefined
                                                                                                        ? defaultMessage.text
                                                                                                        : ""
                                                                                                    : "",
                                                                                        }}
                                                                                    ></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        &nbsp;
                                                                        <Avatar
                                                                            image={profileUser.avatar_url !== null ? profileUser.avatar_url : noPictureURL}
                                                                            className="p-mr-2"
                                                                            shape="circle"
                                                                            style={{ position: "unset" }}
                                                                        />
                                                                    </div>
                                                                    <br />
                                                                    <Button
                                                                        label="Selecionar"
                                                                        className="p-button-rounded"
                                                                        style={{ width: "90px", display: "block", marginLeft: "auto", marginRight: "auto" }}
                                                                        onClick={() => {
                                                                            let sequenceNumber
                                                                            setLoadingSendMessageOnRoadMap(true)
                                                                            let index =
                                                                                auxRoadMapSelected.messages !== undefined
                                                                                    ? auxRoadMapSelected.messages.length
                                                                                    : 0
                                                                            if (index === 0) {
                                                                                sequenceNumber = 0
                                                                            } else {
                                                                                sequenceNumber = auxRoadMapSelected.messages[index - 1].sequence
                                                                            }
                                                                            let dataMessage: MessagesRoadMapInterface = {
                                                                                type: "text",
                                                                                attachment_id: undefined,
                                                                                default_message_id: defaultMessage.id,
                                                                                sequence: sequenceNumber + 1,
                                                                                defaultMessage: defaultMessage,
                                                                            }
                                                                            if (!auxRoadMapSelected.messages) {
                                                                                auxRoadMapSelected["messages"] = []
                                                                            }

                                                                            auxRoadMapSelected.messages.push(dataMessage)

                                                                            updateRoadMap(auxRoadMapSelected.messages, auxRoadMapSelected)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <br /> <br />
                                                            </>
                                                        ))}
                                                </div>
                                            </TabPanel>
                                        ))}
                                </TabView>
                            ) : (
                                <div>
                                    <div>
                                        {defaltMenssages
                                            .filter((a: DefaultMessageInterface) => a.chat_subgroup_id === null)
                                            .map((defaultMessage) => (
                                                <>
                                                    <br /> <br />
                                                    <div className="" style={{ paddingTop: "1rem", marginLeft: "auto", marginRight: "auto" }}>
                                                        <div className="p-d-flex" style={{ display: "flex", justifyContent: "center" }}>
                                                            <div className="">
                                                                <div
                                                                    style={{
                                                                        border: "0.01rem solid #e4e5e7",
                                                                        borderRadius: "0.8rem 0.8rem 0rem 0.8rem",
                                                                        boxShadow: "0.04rem 0.04rem 0.06rem #d7d8db",
                                                                    }}
                                                                >
                                                                    {defaultMessage.text.includes("https://") && (
                                                                        <iframe src={defaultMessage.text} width="100%" height="100%" />
                                                                    )}

                                                                    <div className="p-d-flex p-jc-end p-ai-center">
                                                                        <div
                                                                            className="Container"
                                                                            style={{
                                                                                fontSize: "1.1rem",
                                                                                textRendering: "optimizeLegibility",
                                                                                maxWidth: `${widthWindow <= 414 ? "15rem" : "45rem"}`,
                                                                                marginBottom: "0.5rem",
                                                                                marginLeft: "0.5rem",
                                                                                marginRight: "0.5rem",
                                                                                wordBreak: "normal",
                                                                            }}
                                                                            dangerouslySetInnerHTML={{
                                                                                __html:
                                                                                    defaultMessage !== undefined
                                                                                        ? defaultMessage.text !== undefined
                                                                                            ? defaultMessage.text
                                                                                            : ""
                                                                                        : "",
                                                                            }}
                                                                        ></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            &nbsp;
                                                            <Avatar
                                                                image={profileUser.avatar_url !== null ? profileUser.avatar_url : noPictureURL}
                                                                className="p-mr-2"
                                                                shape="circle"
                                                                style={{ position: "unset" }}
                                                            />
                                                        </div>
                                                        <br />
                                                        <Button
                                                            label="Selecionar"
                                                            className="p-button-rounded"
                                                            style={{ width: "90px", display: "block", marginLeft: "auto", marginRight: "auto" }}
                                                            onClick={() => {
                                                                let sequenceNumber
                                                                setLoadingSendMessageOnRoadMap(true)
                                                                let index = auxRoadMapSelected.messages !== undefined ? auxRoadMapSelected.messages.length : 0
                                                                if (index === 0) {
                                                                    sequenceNumber = 0
                                                                } else {
                                                                    sequenceNumber = auxRoadMapSelected.messages[index - 1].sequence
                                                                }
                                                                let dataMessage: MessagesRoadMapInterface = {
                                                                    type: "text",
                                                                    attachment_id: undefined,
                                                                    default_message_id: defaultMessage.id,
                                                                    sequence: sequenceNumber + 1,
                                                                    defaultMessage: defaultMessage,
                                                                }
                                                                if (!auxRoadMapSelected.messages) {
                                                                    auxRoadMapSelected["messages"] = []
                                                                }

                                                                auxRoadMapSelected.messages.push(dataMessage)

                                                                updateRoadMap(auxRoadMapSelected.messages, auxRoadMapSelected)
                                                            }}
                                                        />
                                                    </div>
                                                    <br /> <br />
                                                </>
                                            ))}
                                    </div>
                                </div>
                            )}
                        </TabPanel>
                    ))}
                </TabView>
            </Dialog>

            {/* Edit  RoadMap   Dialog */}
            <Dialog
                visible={roadMapEditDialog}
                style={{ width: "450px" }}
                header={"Editar Roteiro"}
                dismissableMask={true}
                draggable={false}
                modal
                className="p-fluid"
                footer={editDefaultMessageFooter}
                onHide={() => setRoadMapEditDialog(false)}
            >
                <div className="p-field">
                    <label htmlFor="text">Nome do Roteiro:</label>
                    <InputText required id="name" name="name" autoFocus type="String" maxLength={35} onChange={onChangeEdit} value={roadMap.name}></InputText>
                </div>
                <br />
                <div className="p-field">
                    <label htmlFor="attachment_group_id">Categoria:</label>
                    <Dropdown
                        id="chatGroup"
                        name="chatGroup"
                        required
                        type="String"
                        emptyMessage="Não há dados"
                        emptyFilterMessage="Não há dados"
                        style={{ minWidth: "20rem" }}
                        maxLength={45}
                        options={groupsAttachments}
                        dataKey="id"
                        optionLabel="name"
                        onChange={(e: any) => {
                            setSelectedGroup(e.value)
                        }}
                        value={selectedGroup}
                        placeholder="SEM CATEGORIA"
                    />
                </div>
                {!isEmptyObj(selectedGroup) && selectedGroup?.id !== "000001" && (
                    <div className="p-field">
                        <label htmlFor="description">Subcategoria:</label>
                        <Dropdown
                            id="chatSubgroup"
                            name="chatSubgroup"
                            required
                            options={subGroups.filter((a) => {
                                return a.chat_group_id === selectedGroup?.id
                            })}
                            dataKey="id"
                            optionLabel="name"
                            style={{ minWidth: "20rem" }}
                            onChange={(e: any) => setRoadMap({ ...roadMap, [`chatSubgroup`]: e.target.value })}
                            value={roadMap.chatSubgroup}
                            placeholder="SEM CATEGORIA"
                        />
                    </div>
                )}
            </Dialog>

            {/* Delete Attachments Dialog */}
            <ConfirmDialog
                style={{ width: "400px", borderRadius: "16px" }}
                draggable={false}
                visible={deleteAttachmentDialog}
                onHide={hideDialog}
                message={deleteMessage}
                acceptLabel={"Sim"}
                rejectLabel={"Não"}
                header="Confirmação de exclusão"
                icon="pi pi-exclamation-triangle"
                accept={onDeleteRoadMap}
                acceptIcon="pi pi-check"
                rejectIcon="pi pi-times"
                reject={hideDialog}
                dismissableMask={true}
                footer={deleteDialogFooter}
            />
        </App>
    )
}
