import { Button } from "primereact/button"
import { useRef } from "react"
import { OpportunityInterface } from "../../../interfaces/OpportunityInterface"
import { ValueChangeRating } from "../../../interfaces/ValueChangeRatingInterface"
import api from "../../../services/api"
import { Toast } from "primereact/toast"

interface PropsInterface {
    setDisplayDialogConfirmChangeRating: React.Dispatch<React.SetStateAction<boolean>>
    setOpportunities: React.Dispatch<React.SetStateAction<OpportunityInterface[]>>
    newValueRating: any
    auxOpportunities: OpportunityInterface[]
    opportunity: OpportunityInterface
}

const DialogRatingClearOpportunities = ({
    opportunity,
    setDisplayDialogConfirmChangeRating,
    newValueRating,
    setOpportunities,
    auxOpportunities,
}: PropsInterface) => {
    const toast = useRef<Toast>(null)

    const handleRating = async (event: any, id: string) => {
        const qualification = event
        const header = {
            headers: {
                route: "update_opportunity_qualification",
            },
        }

        try {
            const res = await api.put("opportunities/qualification", { id, qualification }, header)
            if (res.status === 200) {
                auxOpportunities.forEach((opportunity: any) => {
                    if (opportunity.id === id) {
                        opportunity.qualification = qualification
                    }
                })

                setOpportunities(auxOpportunities)
                toast.current?.show({
                    severity: "success",
                    summary: "Qualificação alterada com sucesso!",
                    detail: res.data.message,
                    life: 3000,
                })
            } else {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro na alteração da qualificação!",
                    detail: res.data.message,
                    life: 3000,
                })
            }
        } catch (error: any) {
            toast.current?.show({
                severity: "error",
                summary: "Erro na alteração da qualificação!",
                detail: error.response?.data?.message || "Erro desconhecido",
                life: 3000,
            })
        } finally {
            setDisplayDialogConfirmChangeRating(false)
        }
    }

    const replaceQualification = (qualification: number | string) => {
        switch (qualification) {
            case 0:
                return "nenhuma"
            case 1:
                return "baixa"
            case 2:
                return "média baixa"
            case 3:
                return "média"
            case 4:
                return "média alta"
            case 5:
                return "alta"
            default:
                return ""
        }
    }

    return (
        <>
            <div>
                <Toast ref={toast} />
                <p>
                    Deseja alterar a qualificação da oportunidade de {replaceQualification(opportunity.qualification)} para{" "}
                    {replaceQualification((newValueRating as ValueChangeRating).value)}?
                </p>
                <div></div>
                <div className="p-d-flex p-jc-center">
                    <Button
                        label="Cancelar"
                        className="p-button-danger p-mr-2"
                        style={{ width: "10rem" }}
                        onClick={() => setDisplayDialogConfirmChangeRating(false)}
                    />
                    <Button
                        label="Continuar"
                        className="p-button-success"
                        style={{ width: "10rem" }}
                        onClick={() => {
                            handleRating((newValueRating as ValueChangeRating).value, (newValueRating as ValueChangeRating).id)
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default DialogRatingClearOpportunities
