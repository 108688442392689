import { formatDistanceToNow } from "date-fns"
import ptBR from "date-fns/locale/pt-BR"
import moment from "moment"
import { Avatar } from "primereact/avatar"
import { useState } from "react"
import Moment from "react-moment"
import { WhatsappConversationInterface } from "../../../../interfaces/WhatsappConversationInterface"
import { final_words } from "../../../../listsArrays/ListsArrays"
import { messagesContains } from "../../../../listsArrays/ListsArrays"

type PropsInterface = {
    archiveConversation: boolean
    show: boolean
    tabSelected: number
    widthBoxDateTimeInChat: string
    widthLastMessageText: string
    anchorPoint: { x: number; y: number }
    contactChat: WhatsappConversationInterface
    conversations: WhatsappConversationInterface[]
    conversationSelected: WhatsappConversationInterface
    responsibleContact: WhatsappConversationInterface
    handleColorSelectedCard: (e: any) => void

    setChangeResponsible: (e: true) => void

    updateWhatsappMessageToRead: any

    setAuxContacts: () => void
    onChangeContact: () => void
    setRemoveResponsible: (e: boolean) => void
    setUnArchiveConversationDialog: (e: boolean) => void
    setArchiveConversationDialog: (e: boolean) => void
    handleContextMenu: (e: any) => void
    callWidthScreen: () => void
    setChatDialogMobile: (e: string) => void
    colorSelectedCard: (e: WhatsappConversationInterface) => void
    executeScroll: () => void
    handleUpdateConversationSelected: (e: any) => void
    setOpportunityCreationDialog: (e: any) => void
    setScreenMobile: (e: string) => void
    setNameClassAvatarDialog: (e: string) => void

    fetchMoreMessagesInitialPattern: () => void
    autoFocusForInputSendMessage: () => void

    setConversationSelected: (e: any) => void
    setNameClassAvatarDialogSeller: (e: string) => void
    setClassNameinputTextSendMessageOnDesktop: (e: string) => void
    setClassNameinputTextSendMessageOnMobile: (e: string) => void
    setInputTextSendMessage: (e: string) => void
    updateTheNewMessageForRead: () => void
    handleResponsibleContact: (e: any) => void
    setCompaniesOpportunity: () => void
    informationAboutSellerWhoResponded: (e: any) => void
    handleContextMenuConversationSelected: (e: any) => void
    dateLastMessage: (e: WhatsappConversationInterface) => void
}

export const ChatsTabViewBody = ({
    tabSelected,
    anchorPoint,
    show,
    contactChat,
    widthBoxDateTimeInChat,
    archiveConversation,
    widthLastMessageText,
    handleColorSelectedCard,
    setAuxContacts,
    onChangeContact,
    updateWhatsappMessageToRead,
    setRemoveResponsible,
    setUnArchiveConversationDialog,
    setArchiveConversationDialog,
    handleContextMenu,
    callWidthScreen,
    setChatDialogMobile,
    setChangeResponsible,
    colorSelectedCard,
    executeScroll,
    responsibleContact,
    conversations,
    conversationSelected,
    setScreenMobile,
    setNameClassAvatarDialog,
    fetchMoreMessagesInitialPattern,
    autoFocusForInputSendMessage,
    setConversationSelected,
    setNameClassAvatarDialogSeller,
    setClassNameinputTextSendMessageOnDesktop,
    setClassNameinputTextSendMessageOnMobile,
    setInputTextSendMessage,
    updateTheNewMessageForRead,
    handleResponsibleContact,
    setCompaniesOpportunity,
    informationAboutSellerWhoResponded,
    handleContextMenuConversationSelected,
    setOpportunityCreationDialog,
    handleUpdateConversationSelected,
    dateLastMessage,
}: PropsInterface) => {
    let noPictureURL = "/assets/pages/icons/no-avatar.png"
    let widthWindow = window.innerWidth

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    const findDateLastMessageChatCompany = (contactInformations: WhatsappConversationInterface) => {
        if (contactInformations.messages !== undefined && contactInformations.messages.length > 0) {
            let lastMessage = contactInformations.messages[contactInformations.messages?.length - 1]

            var dataInicio = new Date(contactInformations.messages[contactInformations.messages?.length - 1].created_at)
            var dataFim = new Date()
            var diffMilissegundos = dataFim.getTime() - dataInicio.getTime()
            var diffSegundos = diffMilissegundos / 1000
            var diffMinutos = diffSegundos / 60
            var diffHoras = diffMinutos / 60
            var diffDias = diffHoras / 24
            var diffMeses = diffDias / 30

            if (diffMinutos < 60) {
                return <div>{formatDistanceToNow(dataInicio, { addSuffix: true, locale: ptBR })}</div>
            } else if (diffHoras < 24) {
                let hours = Math.floor(diffHoras).toFixed(0)
                return <div>{formatDistanceToNow(dataInicio, { addSuffix: true, locale: ptBR })}</div>
            } else if (diffDias < 7) {
                let daysToWeek = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"]
                let day = daysToWeek[new Date(lastMessage.created_at).getDay()]

                let thisDay = moment(lastMessage.created_at).format("DD/MM")

                return (
                    <div>
                        {" "}
                        {day} {thisDay}
                    </div>
                )
            } else {
                return (
                    <div>
                        <Moment format="DD/MM/YYYY">{lastMessage.created_at}</Moment>
                    </div>
                )
            }
        }
    }

    const getAvatar = (): string => {
        if (contactChat?.profile_picture_url) {
            return contactChat.profile_picture_url
        }

        if (contactChat?.contact?.company?.picture_url) {
            return contactChat.contact.company.picture_url
        }

        return noPictureURL
    }

    const messageTypes: { [key: string]: string } = {
        document: "Documento",
        image: "Imagem",
        video: "Video",
        audio: "Áudio",
        sticker: "Figurinha",
        locate: "Localização",
        contact: "Contato",
    }

    const findLastMessageChatCompany = (contactInformations: WhatsappConversationInterface): string => {
        const conversation = conversations.find((conversation) => conversation.id === contactInformations.id)

        if (!conversation || !contactInformations.messages || contactInformations.messages.length === 0) {
            return "Mensagem..."
        }

        const lastMessage = contactInformations.messages[contactInformations.messages.length - 1]
        const messageType = lastMessage.type

        return messageTypes[messageType] || lastMessage.text || "Mensagem..."
    }

    const countOfUnviewedMessages = (contactInformations: WhatsappConversationInterface) => {
        let newMessages = 0
        let lastMessage = contactInformations.messages[contactInformations.messages?.length - 1]

        if (contactInformations.id === conversationSelected.id && tabSelected !== 2) {
            return null
        }

        newMessages = contactInformations.messages.reduce((count, message) => {
            if (message.new === true) {
                return count + 1
            }
            return count
        }, 0)

        if (newMessages !== 0) {
            return (
                <div
                    className="numberMessagesCircle p-d-flex p-ai-center p-jc-center"
                    style={{ width: "1.5rem", height: "1.5rem", backgroundColor: "green", borderRadius: "50%" }}
                >
                    <div className="p-d-flex p-jc-end" style={{ color: "white", fontSize: "0.85rem", userSelect: "none" }}>
                        {newMessages.toString()}
                    </div>
                </div>
            )
        } else if (
            lastMessage?.status === "received" &&
            (lastMessage.type === "document" || lastMessage.type === "audio" || lastMessage.type === "text") &&
            (!final_words.some((word) => lastMessage?.text?.toLowerCase().includes(word)) ||
                messagesContains.some((word) => lastMessage?.text?.toLowerCase().includes(word)))
        ) {
            return (
                <div
                    className="numberMessagesCircle p-d-flex p-ai-center p-jc-center"
                    style={{ width: "1.5rem", height: "1.5rem", backgroundColor: "orange", borderRadius: "50%" }}
                />
            )
        }

        return null
    }

    return (
        <div>
            <div>
                {show ? (
                    <ul
                        className="menu"
                        style={{
                            top: anchorPoint.y,
                            left: anchorPoint.x,
                        }}
                    >
                        {tabSelected === 0 && responsibleContact.contact_id === null && (
                            <>
                                <li
                                    onClick={() => {
                                        setOpportunityCreationDialog(true)
                                    }}
                                >
                                    <i className="pi pi-phone mr-2">&nbsp;</i>
                                    Criar Oportunidade da Conversa
                                </li>
                                <hr className="divider" />
                            </>
                        )}

                        <li
                            onClick={() => {
                                setChangeResponsible(true)
                            }}
                        >
                            <i className="pi pi-user-edit mr-2">&nbsp;</i>
                            Selecionar Responsável
                        </li>

                        <hr className="divider" />

                        <li
                            onClick={() => {
                                setAuxContacts()
                                onChangeContact()
                            }}
                        >
                            <i className="pi pi-users mr-2">&nbsp;</i>
                            Alterar contato vinculado
                        </li>

                        {tabSelected === 0 && (
                            <>
                                <hr className="divider" />
                                <li>
                                    <a href={`/crm/contatos/${responsibleContact?.contact?.id}`} target="_blank" rel="noreferrer" style={{ color: "#495057" }}>
                                        <i className="pi pi-pencil mr-2">&nbsp;</i>
                                        Editar contato
                                    </a>
                                </li>
                            </>
                        )}

                        {tabSelected === 1 ? (
                            ""
                        ) : (
                            <>
                                <hr className="divider" />
                                <li
                                    onClick={() => {
                                        setRemoveResponsible(true)
                                    }}
                                >
                                    <i className="pi pi-user-minus mr-2">&nbsp;</i>
                                    Remover Responsável
                                </li>
                                <hr className="divider" />

                                {archiveConversation && (
                                    <li
                                        onClick={() => {
                                            setUnArchiveConversationDialog(true)
                                        }}
                                    >
                                        <i className="pi pi-folder-open mr-2">&nbsp;</i>
                                        Desarquivar Conversa
                                    </li>
                                )}

                                {!archiveConversation && (
                                    <li
                                        onClick={() => {
                                            setArchiveConversationDialog(true)
                                        }}
                                    >
                                        <i className="pi pi-folder mr-2">&nbsp;</i>
                                        Arquivar Conversa
                                    </li>
                                )}
                            </>
                        )}
                    </ul>
                ) : (
                    <> </>
                )}
            </div>

            {contactChat.archived === false && !archiveConversation && (
                <div
                    onContextMenu={(e) => {
                        handleContextMenu(e)
                        handleResponsibleContact(contactChat)
                    }}
                    onClick={() => {
                        if (tabSelected === 0) {
                            let lastMessage = contactChat.messages[contactChat.messages.length - 1]
                            if (
                                ((lastMessage.type === "document" || lastMessage.type === "audio") && lastMessage.status === "received") ||
                                (lastMessage.type === "text" && lastMessage.status === "received" && !final_words.includes(lastMessage.text.toLowerCase()))
                            ) {
                            } else {
                                updateWhatsappMessageToRead()
                            }
                        }
                    }}
                    className="contactcontact p-d-flex"
                    style={{
                        backgroundColor: `${colorSelectedCard(contactChat)}`,
                    }}
                >
                    <div
                        onClick={() => {
                            handleUpdateConversationSelected(contactChat)
                        }}
                        className="p-col-12 p-md-12 p-lg-12"
                        style={{ padding: "", width: "100%", position: "relative" }}
                    >
                        <a
                            onContextMenu={() => {
                                handleContextMenuConversationSelected(contactChat)
                            }}
                            onClick={() => {
                                callWidthScreen()
                                setInputTextSendMessage("")
                                setChatDialogMobile("active")
                                setScreenMobile("disabledForMobile")
                                setCompaniesOpportunity()
                                setNameClassAvatarDialog("noDialogShowClientInformation")
                                dateLastMessage(contactChat)
                                fetchMoreMessagesInitialPattern()
                                autoFocusForInputSendMessage()

                                setTimeout(() => {
                                    executeScroll()
                                }, 20)

                                if (tabSelected !== 2) {
                                    updateTheNewMessageForRead()
                                }

                                if (widthWindow > 430) {
                                    setClassNameinputTextSendMessageOnDesktop("sendTextInputMessage")
                                } else {
                                    setClassNameinputTextSendMessageOnMobile("sendTextInputMessage")
                                }
                            }}
                        >
                            <div style={{ width: "100%", height: "100%" }} className="p-d-flex p-ai-center">
                                <div style={{ width: "15%", marginRight: "1rem", position: `${tabSelected === 2 ? "relative" : "static"}` }}>
                                    {tabSelected === 2 ? (
                                        <div style={{ width: "100%", height: "100%", display: "inline-flex" }}>
                                            <Avatar
                                                image={getAvatar()}
                                                className=""
                                                shape="circle"
                                                style={{
                                                    width: `${widthWindow <= 414 ? "3rem" : "3.5rem"}`,
                                                    height: `${widthWindow <= 414 ? "3rem" : "auto"}`,
                                                }}
                                            />
                                            {contactChat.user !== null && (
                                                <div
                                                    style={{
                                                        width: "52%",
                                                        height: "52%",
                                                        position: "absolute",
                                                        top: "-0.1rem",
                                                        right: "-0.4rem",
                                                        display: "inline-block",
                                                    }}
                                                >
                                                    <Avatar
                                                        image={contactChat.user !== null ? contactChat.user.avatar_url : noPictureURL}
                                                        className="avatarResponsibleChatOnTabAllConversations"
                                                        shape="circle"
                                                        onClick={() => {
                                                            informationAboutSellerWhoResponded(contactChat.user)
                                                            setNameClassAvatarDialogSeller("showInformationAboutSellerWhoResponded")
                                                        }}
                                                        style={{
                                                            width: "auto",
                                                            height: "auto",
                                                            border: "0.15rem solid #F0F2F5",
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div style={{ width: "100%", height: "100%", display: "inline-flex" }}>
                                            <Avatar
                                                image={getAvatar()}
                                                className=""
                                                shape="circle"
                                                style={{
                                                    width: `${widthWindow <= 414 ? "3rem" : "3.5rem"}`,
                                                    height: `${widthWindow <= 414 ? "3rem" : "auto"}`,
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div style={{ width: "100%" }}>
                                    <div>
                                        {!isEmptyObj(contactChat.contact) ? (
                                            <div className="textMessageClient p-text-nowrap p-text-truncate" style={{ textTransform: "uppercase" }}>
                                                {contactChat.contact.name}
                                            </div>
                                        ) : (
                                            <div>{contactChat.phone_number}</div>
                                        )}
                                        <div className="textMessageClient p-text-nowrap p-text-truncate" style={{ fontSize: "0.7rem", fontWeight: "400" }}>
                                            {!isEmptyObj(contactChat.contact) ? (
                                                contactChat.contact.company.name
                                            ) : (
                                                <div style={{ fontSize: "0.6rem", color: "" }}>Contato não cadastrado</div>
                                            )}
                                        </div>
                                        <div
                                            className=""
                                            style={{
                                                fontSize: "0.8rem",
                                                color: "#444445",
                                                width: "70%",
                                                paddingTop: "0.3rem",
                                            }}
                                        >
                                            <div
                                                className=" textMessageClient p-text-nowrap p-text-truncate"
                                                style={{
                                                    width: `${widthLastMessageText}`,
                                                    userSelect: "none",
                                                }}
                                            >
                                                {findLastMessageChatCompany(contactChat)}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ width: `${widthBoxDateTimeInChat}`, height: "100%" }} className="">
                                    {widthWindow > 430 ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    justifyContent: "center",
                                                    position: "absolute",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    right: "1rem",
                                                }}
                                            >
                                                {countOfUnviewedMessages(contactChat)}
                                            </div>
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    bottom: `${widthWindow > 430 ? "0" : "-1rem"}`,
                                                    right: "1rem",
                                                    fontSize: "0.7rem",
                                                    color: "#444445",
                                                    userSelect: "none",
                                                }}
                                            >
                                                {findDateLastMessageChatCompany(contactChat)}
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{ width: `${widthBoxDateTimeInChat}`, height: "auto" }} className="p-d-flex p-jc-end p-as-end  p-ai-end">
                                            <div
                                                style={{
                                                    justifyContent: "center",
                                                    position: "absolute",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    right: "1rem",
                                                }}
                                            >
                                                {countOfUnviewedMessages(contactChat)}
                                            </div>
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    bottom: `${widthWindow > 430 ? "0" : "-1rem"}`,
                                                    right: "1rem",
                                                    fontSize: "0.7rem",
                                                    color: "#444445",
                                                    userSelect: "none",
                                                }}
                                            >
                                                {findDateLastMessageChatCompany(contactChat)}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            )}

            {contactChat.archived === true && archiveConversation && (
                <div
                    onContextMenu={(e) => {
                        handleContextMenu(e)
                        handleResponsibleContact(contactChat)
                    }}
                    className="contactcontact p-d-flex"
                    style={{
                        backgroundColor: `${colorSelectedCard(contactChat)}`,
                    }}
                >
                    <div className="p-col-12 p-md-12 p-lg-12" style={{ padding: "0.2rem", width: "100%", position: "relative" }}>
                        <a
                            onClick={() => {
                                callWidthScreen()
                                handleUpdateConversationSelected(contactChat)
                                setChatDialogMobile("active")
                                setScreenMobile("disabledForMobile")
                                setNameClassAvatarDialog("noDialogShowClientInformation")

                                setCompaniesOpportunity()

                                dateLastMessage(contactChat)

                                setTimeout(() => {
                                    executeScroll()
                                }, 20)

                                if (tabSelected !== 2) {
                                    updateTheNewMessageForRead()
                                }

                                if (widthWindow > 430) {
                                    setClassNameinputTextSendMessageOnDesktop("sendTextInputMessage")
                                } else {
                                    setClassNameinputTextSendMessageOnMobile("sendTextInputMessage")
                                }
                            }}
                        >
                            <div style={{ width: "100%", height: "100%" }} className="p-d-flex p-ai-center">
                                <div style={{ width: "10%", marginRight: "1rem" }}>
                                    <Avatar
                                        image={getAvatar()}
                                        className="p-mr-2 p-d-flex p-ai-center"
                                        shape="circle"
                                        style={{ width: "3rem", height: "auto" }}
                                    />
                                </div>

                                <div style={{ width: "100%" }}>
                                    <div>
                                        {!isEmptyObj(contactChat.contact) ? (
                                            <div className="textMessageClient p-text-nowrap p-text-truncate">
                                                {contactChat.contact.name}
                                            </div>
                                        ) : (
                                            <div>{contactChat.phone_number}</div>
                                        )}
                                        <div style={{ fontSize: "0.7rem", fontWeight: "400" }}>
                                            {!isEmptyObj(contactChat.contact) ? (
                                                contactChat.contact.company.name
                                            ) : (
                                                <div style={{ fontSize: "0.6rem", color: "" }}>Contato não cadastrado</div>
                                            )}
                                        </div>
                                        <div
                                            className=""
                                            style={{
                                                fontSize: "0.8rem",
                                                color: "#444445",
                                                width: "70%",
                                                paddingTop: "0.3rem",
                                            }}
                                        >
                                            <div
                                                className=" textMessageClient p-text-nowrap p-text-truncate"
                                                style={{
                                                    width: `${widthLastMessageText}`,
                                                    userSelect: "none",
                                                }}
                                            >
                                                {findLastMessageChatCompany(contactChat)}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ width: `${widthBoxDateTimeInChat}`, height: "auto" }} className="p-d-flex p-jc-end p-as-end  p-ai-end">
                                    {widthWindow > 430 ? (
                                        <div
                                            className="p-d-flex p-jc-center"
                                            style={{
                                                marginRight: "1.5rem",
                                                padding: "0rem",
                                                position: "absolute",
                                                marginBottom: "1.3rem",
                                            }}
                                        >
                                            {countOfUnviewedMessages(contactChat)}
                                        </div>
                                    ) : (
                                        <div
                                            className=""
                                            style={{
                                                right: "22vw",
                                                position: "absolute",
                                            }}
                                        >
                                            {countOfUnviewedMessages(contactChat)}
                                        </div>
                                    )}

                                    <div
                                        style={{
                                            position: `${widthWindow > 430 ? "static" : "absolute"}`,
                                            bottom: `${widthWindow > 430 ? "" : "-1rem"}`,
                                            fontSize: "0.7rem",
                                            color: "#444445",
                                            userSelect: "none",
                                        }}
                                    >
                                        {findDateLastMessageChatCompany(contactChat)}
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            )}
        </div>
    )
}
