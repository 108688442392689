//PrimeReact
import { Avatar } from "primereact/avatar"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"

//Interfaces
import { WhatsappConversationInterface } from "../../../../interfaces/WhatsappConversationInterface"
interface HeaderConversationProps {
    heightWindow: number
    conversationSelected: WhatsappConversationInterface
    widthWindow: number
    viewSearchMessagesButton: boolean
    nameClassAvatarDialog: string
    viewButtonsSearchMessageUpOrDown: boolean
    countIndexSearchMessageFilter: number
    idSearchMessageDiv: string
    inputTextSearchMessagesChat: string
    chatMessagesSearch: string[]
    openClientDialog: () => void
    closeClientDialog: () => void
    downScrollSearch: () => void
    upScrollSearch: () => void
    setOpportunityCreationDialog: (e: any) => void
    setIdSearchMessageDiv: (e: any) => void
    handleKeyPressSearchMessage: (e: any) => void
    setInputTextSearchMessagesChat: (e: any) => void
    chatMessageFilter: (e: any, t: any) => void
    setViewSearchMessagesButton: (e: any) => void
    setCountIndexSearchMessageFilter: (e: any) => void
    setChatMessagesSearch: (e: any) => void
}

export const HeaderConversationAttendance = (props: HeaderConversationProps) => {
    let noPictureURL = "/assets/pages/icons/no-avatar.png"

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    const getAvatar = () => {
        if (props.conversationSelected?.profile_picture_url) {
            return props.conversationSelected.profile_picture_url
        } else if (!isEmptyObj(props.conversationSelected?.contact) && props.conversationSelected.contact.company.picture_url) {
            return props.conversationSelected.contact.company.picture_url
        } else {
            return noPictureURL
        }
    }

    return (
        <div
            style={{
                height: `${props.heightWindow >= 1080 ? "7vh" : "11.2vh"}`,
                paddingLeft: "1rem",
                paddingRight: "2rem",
                borderRadius: "0rem 0.5rem 0rem 0rem",
            }}
            className="p-d-flex p-ai-center p-jc-between"
        >
            <div className="p-d-flex p-ai-center">
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            minWidth: "30px",
                            minHeight: "30px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Avatar
                            image={getAvatar()}
                            shape="circle"
                            style={{
                                boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",

                                width: `${props.widthWindow <= 414 ? "3rem" : "3rem"}`,
                                height: `${props.widthWindow <= 414 ? "3rem" : "5vh"}`,
                            }}
                            onClick={() => {
                                if (props.conversationSelected.contact !== null && props.nameClassAvatarDialog !== "dialogShowClientInformation") {
                                    props.openClientDialog()
                                } else if (props.conversationSelected.contact !== null) {
                                    props.closeClientDialog()
                                } else {
                                    props.openClientDialog()
                                }
                            }}
                        />
                    </div>

                    <div
                        style={{
                            maxWidth: "calc(100vw - 650px)",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",

                            marginLeft: "0.6vw",
                            fontSize: "1.4rem",
                            fontWeight: "500",
                        }}
                    >
                        {!isEmptyObj(props.conversationSelected.contact) ? props.conversationSelected.contact.name : props.conversationSelected.phone_number}
                    </div>
                </div>
            </div>

            <div className="p-d-flex  p-jc-end">
                {props.viewSearchMessagesButton === true ? (
                    <>
                        {props.viewButtonsSearchMessageUpOrDown ? (
                            <>
                                {props.countIndexSearchMessageFilter >= 0 && !isEmptyObj(props.chatMessagesSearch) ? (
                                    <a
                                        href={`#${props.chatMessagesSearch[props.countIndexSearchMessageFilter]}`}
                                        onClick={() => {
                                            let aux = props.chatMessagesSearch[props.countIndexSearchMessageFilter - 1]
                                            props.downScrollSearch()

                                            props.setIdSearchMessageDiv(aux)
                                        }}
                                    >
                                        <div
                                            className="p-d-flex p-jc-center p-ai-center"
                                            style={{
                                                width: "2rem",
                                                height: "2rem",
                                                borderRadius: "50%",
                                            }}
                                        >
                                            <i className="pi pi-arrow-up" style={{ color: "#54656f" }}></i>
                                        </div>
                                    </a>
                                ) : (
                                    <div></div>
                                )}
                                &nbsp;
                                {props.countIndexSearchMessageFilter < props.chatMessagesSearch.length ? (
                                    <a
                                        href={`#${props.chatMessagesSearch[props.countIndexSearchMessageFilter]}`}
                                        onClick={() => {
                                            let aux = props.chatMessagesSearch[props.countIndexSearchMessageFilter + 1]
                                            props.upScrollSearch()

                                            props.setIdSearchMessageDiv(aux)
                                        }}
                                    >
                                        <div
                                            className="p-d-flex p-jc-center p-ai-center"
                                            style={{
                                                width: "2rem",
                                                height: "2rem",
                                                borderRadius: "50%",
                                            }}
                                        >
                                            <i className="pi pi-arrow-down" style={{ color: "#54656f" }}></i>
                                        </div>
                                    </a>
                                ) : (
                                    <div></div>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                        &nbsp;
                        <InputText
                            value={props.inputTextSearchMessagesChat}
                            id="autofocs"
                            className="inputTextInHeaderContact"
                            onKeyDown={(e: any) => {
                                props.handleKeyPressSearchMessage(e)
                            }}
                            onChange={(e) => {
                                props.setInputTextSearchMessagesChat(e.target.value)
                            }}
                            autoFocus
                            placeholder="Pesquisar..."
                        />
                        &nbsp;
                        <Button
                            icon="pi pi-search"
                            className="p-button-rounded p-button-text"
                            style={{ color: "#54656f" }}
                            onClick={() => {
                                props.chatMessageFilter(props.inputTextSearchMessagesChat, props.conversationSelected)
                            }}
                        />
                    </>
                ) : (
                    ""
                )}
                &nbsp;&nbsp;
                <Button
                    icon={props.viewSearchMessagesButton ? "pi pi-times" : "pi pi-search"}
                    className="p-button-rounded p-button-text"
                    aria-label="Submit"
                    style={{ color: "#54656f" }}
                    onClick={() => {
                        let value = !props.viewSearchMessagesButton
                        props.setViewSearchMessagesButton(value)
                        if (!value) {
                            props.setInputTextSearchMessagesChat("")
                            props.setCountIndexSearchMessageFilter(0)
                            props.setChatMessagesSearch([])
                            props.setIdSearchMessageDiv("")
                        }
                    }}
                />
            </div>
        </div>
    )
}
