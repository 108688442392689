import React, { useRef } from "react"
import { Toast } from "primereact/toast"
import { CrmRecords } from "../components/crm/CrmRecords"
import App from "../layout/App"
import api from "../services/api"
import "../styles/Creation.scss"

export const Criacao: React.FC = () => {
    const toastRef = useRef<any>(null)
    const token = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    return (
        <App>
            <CrmRecords />
            <Toast ref={toastRef} />

            <div
                style={{
                    backgroundImage: "url(https://esquadros-bi.s3.amazonaws.com/front-end-crm/logo-fundo-azul-1920x870.png)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundColor: "white",
                    backgroundRepeat: "no-repeat",
                    margin: "-30px -36px",
                    transition: "opacity 1s ease-in-out",
                    opacity: 1,
                    width: "100vw",
                    height: "calc(100vh - 62px)",
                }}
                className="creation"
            ></div>
        </App>
    )
}
