import React from "react"

import { Button } from "primereact/button"
import { InputTextarea } from "primereact/inputtextarea"

interface Props {
    setAnotation: (e: string) => void
    anotation: string
    loadingCreateAnnotations: boolean
    opportunityStatus?: string
    createAnotation: () => void
}

export const AnnotaionOpportunity = (props: Props) => {
    return (
        <div>
            <>
                <InputTextarea
                    value={props.anotation}
                    onChange={(e) => props.setAnotation(e.target.value)}
                    rows={3}
                    autoResize
                    style={{
                        width: "100%",
                        marginTop: "1vh",
                    }}
                    placeholder="Digite sua anotação"
                />

                <br />
                <br />
                <Button onClick={props.createAnotation} disabled={props.opportunityStatus === "gain" ? true : false} label="Salvar" className="p-button-raised" loading={props.loadingCreateAnnotations} />
            </>
        </div>
    )
}
