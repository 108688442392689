import React, { useState, useEffect, useRef } from "react"
import api from "../../../services/api"
import { cnpj } from "cpf-cnpj-validator"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { InputMask } from "primereact/inputmask"
import { InputText } from "primereact/inputtext"
import { MultiSelect } from "primereact/multiselect"
import { AutoComplete } from "primereact/autocomplete"
import { InterestInterface } from "../../../interfaces/InterestInterface"
import { CompanyInterface } from "../../../interfaces/CompanyInterface"
import { IEmpresaInterface } from "../../../interfaces/IEmpresaInterface"
import { Toast } from "primereact/toast"
import { countryList } from "../../../util/countriesList"

interface PropsInterface {
    company?: CompanyInterface | any
    allCompanies?: CompanyInterface[]
    type: string
    existingCompany: CompanyInterface
    requestApiCompany: IEmpresaInterface
    saveCompanyData: SaveCompanyData
    setSaveCompanyData: (item: any) => void
    setRequestApiCompany: (item: IEmpresaInterface) => void
    setExistingCompany: (item: CompanyInterface) => void
    setWhatsappCNPJ?: string
    from?: string
}

interface SaveCompanyData {
    name: string
    cnpj: string
    address?: string
    city?: string
    state?: string
    neighborhood?: string
    number?: string
    interests: InterestInterface[]
    country?: { name: string; code: string }
    internationalCompany?: { name: string }
}

interface Interests {
    id: string
    name: string
    status: number
    created_at: string
    updated_at: string
}

export const Company = (props: PropsInterface) => {
    let toast = useRef<Toast>(null)
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    let [newExistingCompany, setewExistingCompany] = useState<CompanyInterface>({} as CompanyInterface)
    let [interests, setInterests] = useState<InterestInterface[]>([])
    let [loadingSearchCnpj, setLoadingSearchCnpj] = useState(false)
    let [filteredCountries, setFilteredCountries] = useState<any>(null)
    let [companiesSelect, setCompaniesSelect] = useState<any>([])
    let [cnpjConsulted, setCnpjConsulted] = useState<any>()

    const allCompanies = async () => {
        try {
            let header = {
                headers: {
                    route: "list_companies",
                },
            }

            await api.get("companies", header).then((res) => {
                setCompaniesSelect(res.data)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const allInterests = async () => {
        if (interests.length > 0) {
            return
        }
        try {
            let header = {
                headers: {
                    route: "list_interests",
                },
            }

            await api
                .get("interests", header)
                .then((res) => {
                    const activeInterests = res.data.filter((item: Interests) => item.status !== 0)
                    setInterests(activeInterests)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar os interesses!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const searchCompany = (event: { query: string }) => {
        setTimeout(() => {
            let _searchCompany
            if (!event.query) {
                _searchCompany = [...companiesSelect]
            } else {
                _searchCompany = companiesSelect.filter((company: any) => {
                    return company.name.toLowerCase().startsWith(event.query.toLowerCase())
                })
            }

            setFilteredCountries(_searchCompany)
        }, 250)
    }

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }
        return true
    }

    const consultCnpj = async () => {
        setLoadingSearchCnpj(true)
        try {
            let requestCnpj = require("consultar-cnpj")

            let cnpjClean = props.saveCompanyData.cnpj.replaceAll("_", "").replaceAll("/", "").replaceAll(".", "").replaceAll("-", "")
            try {
                toast.current?.show({
                    severity: "info",
                    summary: "Consultando CNPJ",
                    life: 1000,
                })

                if (!cnpj.isValid(cnpjClean)) {
                    setLoadingSearchCnpj(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "CNPJ Inválido",
                        detail: "Por Favor, Revise o CNPJ digitado",
                        life: 1000,
                    })
                } else {
                    let header = {
                        headers: {
                            route: "show_company_cnpj",
                        },
                    }

                    let response: any = await api
                        .get(`companies/cnpj/${cnpjClean}`, header)
                        .then((response) => {
                            return response.data
                        })
                        .catch((error) =>
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro na Requisição",
                                detail: "Limite de pesquisas por minuto excedida",
                                life: 3000,
                            })
                        )

                    if (response.code) {
                        setLoadingSearchCnpj(false)
                        props.setExistingCompany(response.company)
                        setewExistingCompany(response.company)
                        if (response.company.opportunities.length > 0) {
                            response.company.opportunities.forEach((item: any) => {
                                window.open(`https://crm.esquadros.com.br/crm/leads/${item.id}`, "_blank")
                            })
                        }
                    } else {
                        setLoadingSearchCnpj(false)
                        let response = await requestCnpj(cnpjClean)

                        if (!isEmptyObj(response)) {
                            props.setRequestApiCompany(response)
                        }
                    }
                }
            } catch (error: any) {
                if (error.status === 429) {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro na Requisição",
                        detail: "Limite de pesquisas por minuto excedida",
                        life: 3000,
                    })
                } else if (error.status === 404) {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro na Requisição",
                        detail: "Empresa não encontrada ou recém-criada",
                        life: 3000,
                    })
                }
            }
        } catch (error) {
            setLoadingSearchCnpj(false)
        }
    }

    const onSelectedCompany = async (event: any) => {
        try {
            let header = {
                headers: {
                    route: "show_company",
                },
            }

            await api.get(`companies/${event.id}`, header).then((res) => {
                props.setExistingCompany(res.data)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const valueCNPJ = () => {
        if (props.company?.cnpj === null && props.setWhatsappCNPJ) {
            return props.setWhatsappCNPJ
        } else if (props.company?.cnpj === null && !props.setWhatsappCNPJ) {
            return props.saveCompanyData.cnpj
        } else if (props.company?.cnpj !== null) {
            return props.company?.cnpj
        }
    }

    useEffect(() => {
        allCompanies()
        allInterests()

        if (!isEmptyObj(props.existingCompany)) {
            props.allCompanies?.forEach((company) => {
                if (company.cnpj === props.existingCompany.cnpj) {
                    props.setExistingCompany(company)
                }
            })
        }

        if (!isEmptyObj(props.company)) {
            if (props.company?.cnpj !== null && props.company?.cnpj !== undefined) {
                props.setExistingCompany(props.company)
            }
        }

        if (!isEmptyObj(props.company) && props.company !== undefined) {
            props.saveCompanyData.name = props.company?.name
            props.saveCompanyData.interests = props.company.interests
        }
        
    }, [newExistingCompany, props.requestApiCompany])

    useEffect(() => {
        if (props.from === "zap" && props.saveCompanyData.cnpj !== undefined && props.saveCompanyData.cnpj !== "" && props.saveCompanyData.cnpj !== null) {
            consultCnpj()
        }

        if (props.company?.country) {
            countryList.forEach((item) => {
                if (item.name === props.company.country) {
                    props.saveCompanyData.country = item
                }
            })
        }
    })
    
    
    useEffect(() => {
        function handleKeyDown(event: any) {
            //Visualizando se o campo ativo é do cnpj
            if (event.code === "Tab" && event.target.id === "cnpj") {
                if (cnpjConsulted !== undefined && cnpjConsulted !== "" && cnpjConsulted !== null) {
                    if (cnpjConsulted.length === 18) {
                        consultCnpj()
                    }
                }
            }
        }

        window.addEventListener("keydown", handleKeyDown)

        return () => {
            window.removeEventListener("keydown", handleKeyDown)
        }
    }, [cnpjConsulted])

    return (
        <>
            <Toast ref={toast} />
            <div className="p-mb-2 p-mr-4">
                {(props.company?.cnpj === null || props.type === "created" || props.type === "created_opportunity") && (
                    <>
                        {props.type === "update" || props.type === "created" || props.type === "created_opportunity" ? (
                            <div className="p-field">
                                <div className="p-field">
                                    <label htmlFor="internationalCompany">Empresa Internacional?</label>
                                    <Dropdown
                                        style={{ minWidth: "20rem" }}
                                        id="internationalCompany"
                                        name="internationalCompany"
                                        options={[{ name: "Sim" }, { name: "Não" }]}
                                        optionLabel="name"
                                        placeholder="Selecionar"
                                        type="button"
                                        value={props.saveCompanyData.internationalCompany}
                                        onChange={(e: any) => {
                                            props.setSaveCompanyData(e)
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            ""
                        )}

                        {props.saveCompanyData.internationalCompany?.name === "Não" && (
                            <div className="p-field">
                                {!isEmptyObj(props.existingCompany) && <p style={{ color: "red" }}>Empresa já cadastrada na base de dados.</p>}
                                <label htmlFor="nameClientCompany">CNPJ</label>

                                <div className="p-d-flex p-jc-between">
                                    {isEmptyObj(props.requestApiCompany) && isEmptyObj(props.existingCompany) && (
                                        <InputMask
                                            mask="99.999.999/9999-99"
                                            style={{
                                                minWidth: "20rem",
                                                marginRight: "0.5rem",
                                            }}
                                            id="cnpj"
                                            //Quando clicar no enter, ele vai chamar a função de consultar o CNPJ

                                            name="cnpj"
                                            value={valueCNPJ()}
                                            onChange={(e: any) => {
                                                props.setSaveCompanyData(e)
                                                let cnpj = e.target.value
                                                console.log(cnpj)
                                                cnpjConsulted = cnpj
                                                setCnpjConsulted(cnpjConsulted)
                                            }}
                                        />
                                    )}

                                    {(!isEmptyObj(props.requestApiCompany) || !isEmptyObj(props.existingCompany)) && (
                                        <>
                                            <InputMask
                                                mask="99.999.999/9999-99"
                                                disabled={true}
                                                style={{
                                                    minWidth: "20rem",
                                                    marginRight: "0.5rem",
                                                }}
                                                value={!isEmptyObj(props.requestApiCompany) ? props.saveCompanyData.cnpj : props.existingCompany.cnpj}
                                                onChange={(e: any) => {
                                                    props.setSaveCompanyData(e)
                                                }}
                                            />
                                        </>
                                    )}

                                    <>
                                        <Button
                                            icon="pi pi-times"
                                            type="button"
                                            onClick={() => {
                                                props.setRequestApiCompany({} as IEmpresaInterface)
                                                props.setExistingCompany({} as CompanyInterface)
                                            }}
                                            className="p-button-rounded p-button-text p-button-danger"
                                        />

                                        <Button
                                            icon="pi pi-search"
                                            type="button"
                                            loading={loadingSearchCnpj}
                                            onClick={consultCnpj}
                                            className="p-button-rounded p-button-text"
                                        />
                                    </>
                                </div>
                            </div>
                        )}

                        <div className="p-field">
                            <label htmlFor="name">Nome Empresa</label>

                            {isEmptyObj(props.requestApiCompany) &&
                                isEmptyObj(props.existingCompany) &&
                                (props.type === "update" || props.type === "created_opportunity" || props.type === "created_opportunity" ? (
                                    <AutoComplete
                                        id="name"
                                        name="name"
                                        required
                                        style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                        value={props.saveCompanyData.name || ""}
                                        suggestions={filteredCountries}
                                        completeMethod={searchCompany}
                                        field="name"
                                        onChange={(e: any) => {
                                            props.setSaveCompanyData(e)
                                        }}
                                        onSelect={(e: any) => {
                                            onSelectedCompany(e.value)
                                        }}
                                    />
                                ) : (
                                    <InputText
                                        id="name"
                                        name="name"
                                        style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                        placeholder={props.saveCompanyData.name}
                                        onChange={(e: any) => {
                                            props.setSaveCompanyData(e)
                                        }}
                                    />
                                ))}

                            {!isEmptyObj(props.requestApiCompany) && (
                                <InputText
                                    disabled={true}
                                    style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                    value={props.requestApiCompany.razao_social}
                                />
                            )}

                            {!isEmptyObj(props.existingCompany) && (
                                <InputText disabled={true} style={{ minWidth: "20rem", textTransform: "uppercase" }} value={props.existingCompany.name} />
                            )}
                        </div>

                        <div className="p-field">
                            <div className="p-grid">
                                <div className="p-col-5 p-md-3 p-lg-4">
                                    <label htmlFor="country">Pais</label>
                                    <Dropdown
                                        id="country"
                                        name="country"
                                        filter={true}
                                        options={countryList}
                                        style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                        optionLabel="name"
                                        placeholder="Selecionar"
                                        value={props.saveCompanyData.country || ""}
                                        onChange={(e: any) => {
                                            props.setSaveCompanyData(e)
                                        }}
                                    />
                                </div>

                                <div className="p-col-5 p-md-6 p-lg-4">
                                    <label htmlFor="name">Cidade</label>
                                    {isEmptyObj(props.requestApiCompany) &&
                                        isEmptyObj(props.existingCompany) &&
                                        (props.type === "update" || props.type === "created_opportunity" || props.type === "created_opportunity" ? (
                                            <InputText
                                                id="city"
                                                name="city"
                                                style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                                value={props.saveCompanyData.city || ""}
                                                onChange={(e: any) => {
                                                    props.setSaveCompanyData(e)
                                                }}
                                                onSelect={(e: any) => {
                                                    onSelectedCompany(e.value)
                                                }}
                                            />
                                        ) : (
                                            <InputText
                                                id="city"
                                                name="city"
                                                style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                                placeholder={props.saveCompanyData.city}
                                                onChange={(e: any) => {
                                                    props.setSaveCompanyData(e)
                                                }}
                                            />
                                        ))}

                                    {!isEmptyObj(props.requestApiCompany) && (
                                        <InputText
                                            disabled={true}
                                            style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                            value={props.requestApiCompany.estabelecimento.cidade.nome}
                                        />
                                    )}

                                    {!isEmptyObj(props.existingCompany) && (
                                        <InputText
                                            disabled={true}
                                            style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                            value={props.existingCompany.city}
                                        />
                                    )}
                                </div>

                                <div className="p-col-5 p-md-3 p-lg-4">
                                    <label htmlFor="name">Estado</label>
                                    {isEmptyObj(props.requestApiCompany) &&
                                        isEmptyObj(props.existingCompany) &&
                                        (props.type === "update" || props.type === "created_opportunity" || props.type === "created_opportunity" ? (
                                            <InputText
                                                id="state"
                                                name="state"
                                                style={{ minWidth: "10rem", textTransform: "uppercase" }}
                                                value={props.saveCompanyData.state || ""}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    props.setSaveCompanyData(e)
                                                }}
                                                onSelect={(e: any) => {
                                                    onSelectedCompany(e.value)
                                                }}
                                            />
                                        ) : (
                                            <InputText
                                                id="neighborhood"
                                                name="neighborhood"
                                                style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                                placeholder={props.saveCompanyData.state}
                                                onChange={(e: any) => {
                                                    props.setSaveCompanyData(e)
                                                }}
                                            />
                                        ))}

                                    {!isEmptyObj(props.requestApiCompany) && (
                                        <InputText
                                            disabled={true}
                                            style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                            value={props.requestApiCompany.estabelecimento.estado.nome}
                                        />
                                    )}

                                    {!isEmptyObj(props.existingCompany) && (
                                        <InputText
                                            disabled={true}
                                            style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                            value={props.existingCompany.state}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="p-field">
                            <div className="p-grid">
                                <div className="p-col-5 p-md-3 p-lg-4">
                                    <label htmlFor="name">Endereço</label>
                                    {isEmptyObj(props.requestApiCompany) &&
                                        isEmptyObj(props.existingCompany) &&
                                        (props.type === "update" || props.type === "created_opportunity" || props.type === "created_opportunity" ? (
                                            <InputText
                                                id="address"
                                                name="address"
                                                style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                                value={props.saveCompanyData.address || ""}
                                                onChange={(e: any) => {
                                                    props.setSaveCompanyData(e)
                                                }}
                                                onSelect={(e: any) => {
                                                    onSelectedCompany(e.value)
                                                }}
                                            />
                                        ) : (
                                            <InputText
                                                id="address"
                                                name="address"
                                                style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                                placeholder={props.saveCompanyData.address}
                                                onChange={(e: any) => {
                                                    props.setSaveCompanyData(e)
                                                }}
                                            />
                                        ))}

                                    {!isEmptyObj(props.requestApiCompany) && (
                                        <InputText
                                            disabled={true}
                                            style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                            value={props.requestApiCompany.estabelecimento.logradouro}
                                        />
                                    )}

                                    {!isEmptyObj(props.existingCompany) && (
                                        <InputText
                                            disabled={true}
                                            style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                            value={props.existingCompany.name}
                                        />
                                    )}
                                </div>

                                <div className="p-col-5 p-md-3 p-lg-4">
                                    <label htmlFor="name">Bairro</label>
                                    {isEmptyObj(props.requestApiCompany) &&
                                        isEmptyObj(props.existingCompany) &&
                                        (props.type === "update" || props.type === "created_opportunity" || props.type === "created_opportunity" ? (
                                            <InputText
                                                id="neighborhood"
                                                name="neighborhood"
                                                style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                                value={props.saveCompanyData.neighborhood || ""}
                                                onChange={(e: any) => {
                                                    props.setSaveCompanyData(e)
                                                }}
                                                onSelect={(e: any) => {
                                                    onSelectedCompany(e.value)
                                                }}
                                            />
                                        ) : (
                                            <InputText
                                                id="neighborhood"
                                                name="neighborhood"
                                                style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                                placeholder={props.saveCompanyData.neighborhood}
                                                onChange={(e: any) => {
                                                    props.setSaveCompanyData(e)
                                                }}
                                            />
                                        ))}

                                    {!isEmptyObj(props.requestApiCompany) && (
                                        <InputText
                                            disabled={true}
                                            style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                            value={props.requestApiCompany.estabelecimento.bairro}
                                        />
                                    )}

                                    {!isEmptyObj(props.existingCompany) && (
                                        <InputText
                                            disabled={true}
                                            style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                            value={props.existingCompany.neighborhood}
                                        />
                                    )}
                                </div>

                                <div className="p-col-5 p-md-3 p-lg-4">
                                    <label htmlFor="name">Número</label>
                                    {isEmptyObj(props.requestApiCompany) &&
                                        isEmptyObj(props.existingCompany) &&
                                        (props.type === "update" || props.type === "created_opportunity" || props.type === "created_opportunity" ? (
                                            <InputText
                                                id="number"
                                                name="number"
                                                value={props.saveCompanyData.number || ""}
                                                onChange={(e: any) => {
                                                    props.setSaveCompanyData(e)
                                                }}
                                            ></InputText>
                                        ) : (
                                            <InputText
                                                id="number"
                                                name="number"
                                                value={props.saveCompanyData.number || ""}
                                                onChange={(e: any) => {
                                                    props.setSaveCompanyData(e)
                                                }}
                                            ></InputText>
                                        ))}

                                    {!isEmptyObj(props.requestApiCompany) && (
                                        <InputText
                                            disabled={true}
                                            style={{ minWidth: "20rem", textTransform: "uppercase" }}
                                            value={props.requestApiCompany.estabelecimento.numero}
                                        />
                                    )}

                                    {!isEmptyObj(props.existingCompany) && (
                                        <InputText
                                            id="number"
                                            name="number"
                                            value={props.existingCompany.number || ""}
                                            onChange={(e: any) => {
                                                props.setSaveCompanyData(e)
                                            }}
                                        ></InputText>
                                    )}
                                </div>
                            </div>
                        </div>

                        {props.type === "update" || props.type === "created" || props.type === "created_opportunity" ? (
                            <div className="p-field">
                                <label htmlFor="interests">Interesse:</label>
                                <MultiSelect
                                    style={{ minWidth: "20rem" }}
                                    id="interests"
                                    name="interests"
                                    required
                                    filter={true}
                                    options={interests}
                                    optionLabel="name"
                                    placeholder="Selecionar"
                                    value={props.saveCompanyData.interests || props.existingCompany.interests || ""}
                                    onChange={(e: any) => {
                                        if (isEmptyObj(props.existingCompany)) {
                                            props.setSaveCompanyData(e)
                                        } else if (!isEmptyObj(props.existingCompany)) {
                                            if (!isEmptyObj(props.existingCompany)) {
                                                props.setExistingCompany({ ...props.existingCompany, [`interests`]: e.value })
                                            } else if (isEmptyObj(props.existingCompany)) {
                                                props.setSaveCompanyData({ ...props.saveCompanyData, [`interests`]: e.value })
                                            }
                                        }
                                    }}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </>
                )}

                {props.company?.cnpj !== null && props.company !== undefined && (
                    <>
                        <div className="p-field">
                            <Dropdown
                                style={{
                                    width: "100%",
                                }}
                                id="name"
                                name="name"
                                placeholder={props.existingCompany.name}
                                filter={true}
                                options={companiesSelect}
                                emptyMessage="Não há dados"
                                disabled={props.type === "updateInterests" ? true : false}
                                emptyFilterMessage="Não há dados"
                                optionLabel="name"
                                onChange={(e: any) => {
                                    props.setExistingCompany(e.value)
                                }}
                            />
                        </div>

                        <div className="p-field">
                            <label htmlFor="interests">Interesse:</label>
                            <MultiSelect
                                style={{ minWidth: "20rem" }}
                                id="interests"
                                name="interests"
                                filter={true}
                                options={interests}
                                optionLabel="name"
                                placeholder="Selecionar"
                                value={props.existingCompany.interests || ""}
                                onChange={(e: any) => {
                                    //onChangeCreateCompany(e)
                                    props.setExistingCompany({ ...props.existingCompany, [`interests`]: e.value })
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    )
}
