import React from "react"
import api from "../../../../services/api"
import { InputText } from "primereact/inputtext"

interface PropsInterface {
    type?: string
    annotation: string
    setSaveAnnotationData: (prop: string) => void
}

export const CompleteTask = (props: PropsInterface) => {
    const token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    return (
        <>
            <div className="p-d-flex p-flex-column p-flex-md-row">
                <div className="p-mb-2 p-mr-6">
                    <div className="p-field">
                        <label htmlFor="postPone">Anotação de Conclusão:</label>
                        <InputText
                            style={{ minWidth: "26rem" }}
                            id="annotation"
                            name="annotation"
                            value={props.annotation || ""}
                            onChange={(e: any) => {
                                props.setSaveAnnotationData(e.target.value)
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
