import React, { useRef, useEffect, useState } from "react"
import App from "../../layout/App"
import api from "../../services/api"
import Moment from "react-moment"
import ReactTooltip from "react-tooltip"

//PrimeReact component
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { Column } from "primereact/column"
import { CrmRecords } from "../../components/crm/CrmRecords"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { MultiSelect } from "primereact/multiselect"
import { Toast } from "primereact/toast"

//Components

//Interface
import { OpportunityProposalInterface } from "../../interfaces/OpportunityProposalInterface"

export const AllProposals = () => {
    let toast = useRef<Toast>(null)
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    let [accountable, setAccountable] = useState<any[]>([])
    let [dateFinal, setDateFinal] = useState<any>(new Date())
    let [dateInit, setDateInit] = useState<any>("")
    let [displayBasicMobile, setDisplayBasicMobile] = useState(false)
    let [interest, setInterest] = useState<any[]>([])
    let [modalOptionalsMachine, setModalOptionalsMachine] = useState(false)
    let [proposal, setProposal] = useState<OpportunityProposalInterface[]>([])
    let [proposalOptionals, setProposalOptional] = useState<OpportunityProposalInterface[]>([])
    let [selectedAccountable, setSelectedAccountable] = useState<any[]>([])
    let [selectedInterest, setSelectedInterest] = useState<any[]>([])

    //Filter
    let [advancedSearchFilter, setAdvancedSearchFilter] = useState("")
    let [globalFilter] = useState<string | null>(null)
    let [globalFilterOptionals, setGlobalFilterOptionals] = useState<string | null>(null)
    let [auxProposalFilter, setAuxProposalFilter] = useState<OpportunityProposalInterface[]>([])

    //Loading
    let [loadingList, setLoadingList] = useState<boolean>(true)
    let [loadingCleanFilters, setLoadingCleanFilters] = useState(false)

    //Dialog

    //Headers
    const header = (
        <div>
            {/* Desktop */}
            <div className="desktop-opportunity-filter-menu">
                <div className="p-d-flex p-jc-between">
                    <h5 className="">Propostas </h5>
                    <div className="p-d-flex p-jc-end">
                        <span className="p-input-icon-left p-mr-2">
                            <i className="pi pi-search" />
                            <InputText
                                className="searchFilter"
                                placeholder="PESQUISAR..."
                                value={advancedSearchFilter}
                                onKeyUp={() => (advancedSearchFilter === "" ? filter() : "" || advancedSearchFilter !== "" ? filter() : "")}
                                onChange={(e) => {
                                    setAdvancedSearchFilter(e.target.value)
                                    filter()
                                }}
                            />
                        </span>
                    </div>
                </div>
            </div>
            {/* Mobile */}
            <div className="mobile-opportunity-filter-menu">
                <div className="p-d-flex">
                    <div className="table-header">
                        <h5 className="p-mb-2">Propostas</h5>
                        <span className="p-input-icon-left p-mr-2">
                            <i className="pi pi-search" />
                            <InputText
                                className="searchFilter"
                                placeholder="PESQUISAR..."
                                value={advancedSearchFilter}
                                onKeyUp={() => (advancedSearchFilter === "" ? filter() : "" || advancedSearchFilter !== "" ? filter() : "")}
                                onChange={(e) => {
                                    setAdvancedSearchFilter(e.target.value)
                                    filter()
                                }}
                            />
                        </span>
                        <i
                            className="iconForMobileFilter pi pi-filter"
                            style={{ fontSize: "1.5em" }}
                            onClick={() => onClickMobileDialog("displayBasic", "center")}
                        ></i>
                    </div>
                </div>
            </div>
        </div>
    )

    const headerOptionals = (
        <div className="table-header">
            <div className="p-d-flex p-jc-between">
                <h5 className="p-m-0"> </h5>
                <div>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />

                        <InputText
                            style={{ width: "100%" }}
                            type="search"
                            onInput={(e: any) => setGlobalFilterOptionals(e.target.value)}
                            placeholder="Pesquisar..."
                        />
                    </span>
                </div>
            </div>
        </div>
    )

    //Requisitions
    const allProposal = async () => {
        try {
            const header = {
                headers: {
                    route: "list_opportunity_proposals",
                },
            }

            const res = await api.get("opportunity_proposals", header)

            if (res.status === 200) {
                setLoadingList(false)
                setProposal(res.data)
                setAuxProposalFilter(res.data)
            } else {
                throw new Error(res.data.message)
            }
        } catch (error: any) {
            console.log(error)
            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar propostas!",
                detail: error.message || "Ocorreu um erro ao listar propostas.",
                life: 3000,
            })
        }
    }

    const allOptionalMachine = (rowData: any) => {
        setModalOptionalsMachine(true)

        for (let optional of rowData.optionals) {
            proposalOptionals.push(optional)
        }
    }

    //DataTable Formatters
    const columnNumberOpportunityProposal = (rowData: OpportunityProposalInterface) => {
        return (
            <span>
                {("000000" + rowData.opportunity.number).slice(-6)}_{("0" + rowData.number).slice(-2)}
            </span>
        )
    }

    const columnNameProposal = (rowData: OpportunityProposalInterface) => {
        return (
            <span>
                {rowData.machine_description}
            </span>
        )
    }

    const dateFormatCreate = (rowData: OpportunityProposalInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.created_at}</Moment>
                <br />
                <Moment format="HH:mm">{rowData.created_at}</Moment>
            </>
        )
    }

    const orderTable = (rowData: OpportunityProposalInterface) => {
        return rowData.order === null ? <>NÃO</> : "SIM"
    }

    const CompanyConcatenate = (rowData: OpportunityProposalInterface) => {
        return (
            <span>
                {rowData.opportunity.company.name} <br /> <br /> <strong>{rowData.opportunity.company.cnpj}</strong>
            </span>
        )
    }

    const statusProposal = (rowData: OpportunityProposalInterface) => {
        return (
            <span style={{ fontWeight: "bold" }}>
                {rowData.status_proposal === "not-sended"
                    ? "Proposta Não Enviada"
                    : rowData.status_proposal === "sended"
                    ? "Proposta Enviada"
                    : rowData.status_proposal === "resended"
                    ? "Proposta Reenviada"
                    : rowData.status_proposal === "viewed"
                    ? "Proposta Visualizada"
                    : ""}
                <br />
                {!rowData.signed_client && (
                    <span style={{ fontWeight: "bold" }}>
                        <br />
                        {rowData.status === "not-sended"
                            ? "Assinatura Não Enviada"
                            : rowData.status === "sended"
                            ? "Assinatura Enviada"
                            : rowData.status === "resended"
                            ? "Assinatura Reenviada"
                            : ""}
                    </span>
                )}
                <br />
                {rowData.status === "sended" ||
                    (rowData.status === "resended" && (
                        <>
                            <span>{rowData.signed_client ? "Cliente Assinou" : "Cliente não Assinou"}</span>
                            &nbsp; <br />
                            <br />
                            <span>{rowData.signed_owner ? "Gestor Assinou" : "Gestor não Assinou"}</span>
                        </>
                    ))}
            </span>
        )
    }

    const dataTableButtons = (rowData: any) => {
        return (
            <div className="actions">
                <Button
                    data-tip
                    data-for="optionals"
                    icon="pi pi-shopping-bag"
                    className="p-button-rounded p-button"
                    onClick={() => allOptionalMachine(rowData)}
                />
                <ReactTooltip className="toolTip" id="optionals" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Opcionais</p>
                </ReactTooltip>
                &nbsp;&nbsp;
                <Button
                    data-tip
                    data-for="menuGroups"
                    icon="pi pi-external-link"
                    className="p-button-rounded p-button-danger"
                    onClick={() => {
                        window.open(rowData.file_url)
                    }}
                />
                <ReactTooltip className="toolTip" id="menuGroups" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Visualizar Proposta</p>
                </ReactTooltip>
            </div>
        )
    }

    const onLoadingCleanFilters = () => {
        setDateInit("")
        setDateFinal(new Date())

        setSelectedAccountable([])
        setAccountable([])
        setSelectedInterest([])
        setAdvancedSearchFilter("")
        setLoadingCleanFilters(true)
        allProposal()

        setTimeout(() => {
            setLoadingCleanFilters(false)
        }, 1000)
    }

    //Filters
    const filter = () => {
        if (
            dateInit !== null ||
            dateFinal !== null ||
            selectedAccountable.length > 0 ||
            selectedInterest.length > 0 ||
            globalFilter ||
            !(advancedSearchFilter === "")
        ) {
            var listProposalFilter: any[] = []
            for (let atualLine of auxProposalFilter) {
                var addLine = true

                if (dateFinal !== "" && dateInit !== "" && addLine) {
                    let newDateInitGetTime = new Date(dateInit).getTime()
                    let newDateFinalGetTime = new Date(dateFinal).getTime()
                    let newAtualLineGetTime = new Date(atualLine.created_at).getTime()
                    if ((newAtualLineGetTime >= newDateInitGetTime && newAtualLineGetTime <= newDateFinalGetTime) || dateInit === undefined) {
                    } else {
                        addLine = false
                    }
                }

                if (selectedAccountable.length > 0 && addLine) {
                    const validateSearch = selectedAccountable.findIndex((filter) => filter.code === atualLine.opportunity.user_id)
                    if (validateSearch === -1) {
                        addLine = false
                    }
                }

                if (selectedInterest.length > 0 && addLine) {
                    const validateMedia = selectedInterest.findIndex((filter) => filter.code === atualLine.opportunity.media.id)
                    if (validateMedia === -1) {
                        addLine = false
                    }
                }

                if (!(advancedSearchFilter === "") && addLine) {
                    addLine = false
                    var word = advancedSearchFilter.toLowerCase().split(" ")
                    if (advancedSearchFilter === "") {
                        setAuxProposalFilter(proposal)
                    }
                    if (!atualLine.id) atualLine.id = ""
                    if (!atualLine.opportunity_id) atualLine.opportunity_id = ""
                    if (!atualLine.opportunity.name) atualLine.opportunity.name = ""
                    if (!atualLine.contact.name) atualLine.contact.name = ""
                    if (!atualLine.opportunity.user.email) atualLine.opportunity.user.email = ""
                    if (!atualLine.opportunity.user.name) atualLine.opportunity.user.name = ""
                    if (!atualLine.id) atualLine.id = ""
                    if (!atualLine.opportunity.id) atualLine.opportunity.id = ""
                    if (!atualLine.machine_description) atualLine.machine_description = ""
                    if (!atualLine.opportunity.company.cnpj) atualLine.opportunity.company.cnpj = ""
                    if (!atualLine.opportunity.company.name) atualLine.opportunity.company.name = ""
                    if (!atualLine.opportunity.media.name) atualLine.opportunity.media.name = ""
                    if (!atualLine.machine_code) atualLine.machine_code = ""
                    

                    for (var oneWord in word) {
                        oneWord = oneWord.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                        if (
                            !atualLine.id.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.opportunity_id.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.opportunity.name.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.contact.name.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.opportunity.user.email.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.opportunity.user.name.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.id.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.opportunity.id.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.machine_description.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.opportunity.company.name.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.opportunity.company.cnpj.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.opportunity.media.name.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.machine_code.toLowerCase().includes(advancedSearchFilter.toLowerCase())
                        ) {
                            addLine = false
                            break
                        } else {
                            addLine = true
                        }
                    }
                }
                if (advancedSearchFilter === null) {
                    setProposal(proposal)
                }

                if (addLine) {
                    listProposalFilter.push(atualLine)
                }
            }

            setProposal(listProposalFilter)
        } else {
            setProposal(auxProposalFilter)
        }
    }

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    //Dialogs
    const hideDialog = () => {
        setModalOptionalsMachine(false)
        setProposalOptional([])
    }

    const dialogFuncMapMobile = {
        displayBasic: setDisplayBasicMobile,
    }

    const onClickMobileDialog = (name: any, position: any) => {
        dialogFuncMapMobile[`${"displayBasic"}`](true)
    }

    const onHideDialogMobile = (name: any) => {
        dialogFuncMapMobile[`${"displayBasic"}`](false)
    }

    //Footers
    const renderFooter = (name: any) => {
        return (
            <div>
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    onClick={() => {
                        onHideDialogMobile(name)
                        onLoadingCleanFilters()
                    }}
                    className="p-button-danger p-button-text"
                />
                <Button label="Aplicar" icon="pi pi-check" onClick={() => onHideDialogMobile(name)} autoFocus className="p-button-success p-button-text" />
            </div>
        )
    }

    useEffect(() => {
        const accountableList: any[] = []
        const interestFullList: any[] = []
        const interestList: any[] = []

        auxProposalFilter.forEach((item, index) => {
            const findAccountable = accountableList.find((user) => user.name === item.opportunity.user.name)

            if (!findAccountable) {
                accountableList.push({ name: item.opportunity.user.name, code: item.opportunity.user.id })
            }

            if (item.opportunity.media !== null) {
                var findInterest
                interestFullList.push({ name: item.opportunity.media.name, code: item.opportunity.media.id })
                findInterest = interestList.find((user) => user.name === item.opportunity.media.name)

                if (!findInterest) {
                    interestList.push({ name: item.opportunity.media.name, code: item.opportunity.media.id })
                }
            }
        })
        setInterest(interestList)
        setAccountable(accountableList)
    }, [auxProposalFilter])

    useEffect(() => {
        allProposal()
    }, [])

    return (
        <App>
            <div className="p-grid crud-demo">
                <Toast ref={toast} />
                <CrmRecords />
                <div className="p-col-12 p-md-12 p-lg-12" style={{ marginBottom: "-1rem" }}>
                    <div className="desktop-opportunity-filter-menu">
                        <div className="card">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="p-d-flex p-jc-between">
                                    <div>
                                        <Calendar
                                            className="calendarx p-mr-2 p-mb-1"
                                            dateFormat="dd/mm/yy"
                                            id="icon"
                                            value={dateInit}
                                            onMonthChange={() => ""}
                                            onChange={(e) => {
                                                dateInit = e.value
                                                setDateInit(e.value)
                                                filter()
                                            }}
                                            showIcon
                                            placeholder={"INÍCIO"}
                                            locale="pt"
                                        />
                                        <Calendar
                                            className="calendarx p-mr-2 p-mb-1"
                                            dateFormat="dd/mm/yy"
                                            id="icon"
                                            value={dateFinal}
                                            onMonthChange={() => ""}
                                            onChange={(e) => {
                                                dateFinal = e.value
                                                setDateFinal(e.value)
                                                filter()
                                            }}
                                            showIcon
                                            locale="pt"
                                            placeholder={"FIM"}
                                        />
                                        <MultiSelect
                                            className="multiselectx p-mr-2 p-mb-1"
                                            value={selectedAccountable}
                                            options={accountable}
                                            emptyFilterMessage={"Não há dados"}
                                            disabled={!isEmptyObj(accountable) ? false : true}
                                            onChange={(e) => {
                                                selectedAccountable = e.value
                                                setSelectedAccountable(e.value)
                                                filter()
                                            }}
                                            filter={true}
                                            optionLabel="name"
                                            placeholder={!isEmptyObj(accountable) ? "RESPONSÁVEL" : "NÃO HÁ RESPONSÁVEL"}
                                        />

                                        <MultiSelect
                                            className="multiselectx p-mr-2 p-mb-1"
                                            value={selectedInterest}
                                            options={interest}
                                            disabled={!isEmptyObj(interest) ? false : true}
                                            onChange={(e) => {
                                                selectedInterest = e.value
                                                setSelectedInterest(e.value)
                                                filter()
                                            }}
                                            emptyFilterMessage={"Não há dados"}
                                            filter={true}
                                            optionLabel="name"
                                            placeholder={!isEmptyObj(accountable) ? "FONTE" : "NÃO HÁ FONTES"}
                                        />
                                    </div>

                                    <div className="">
                                        <Button
                                            className="p-button-rounded p-jc-end"
                                            label="LIMPAR FILTROS"
                                            icon="pi pi-times"
                                            style={{ width: "13rem" }}
                                            loading={loadingCleanFilters}
                                            onClick={onLoadingCleanFilters}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-12 p-lg-12">
                    <div className="card">
                        <DataTable
                            style={{ paddingBottom: "70px" }}
                            value={proposal}
                            header={header}
                            selectionMode="checkbox"
                            loading={loadingList}
                            stripedRows
                            dataKey="id"
                            rows={10}
                            rowsPerPageOptions={[10, 15, 20]}
                            globalFilter={globalFilter}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} Proposta"
                            paginator
                            emptyMessage="Não há Propostas"
                            responsiveLayout="scroll"
                        >
                            <Column
                                sortable
                                sortField="opportunity.number"
                                align="center"
                                alignHeader="center"
                                body={columnNumberOpportunityProposal}
                                header="Número da Oportunidade"
                            />
                            <Column sortable sortField="number" align="center" alignHeader="center" body={columnNameProposal} header="Equipamento" />
                            <Column
                                sortable
                                sortField="opportunity.machine_code"
                                align="center"
                                alignHeader="center"
                                field="machine_code"
                                header="Cod Produto"
                            />
                            <Column sortable sortField="created_at" align="center" alignHeader="center" body={dateFormatCreate} header="Criação da Proposta" />

                            <Column
                                sortable
                                sortField="opportunity.user.name"
                                align="center"
                                alignHeader="center"
                                field="opportunity.user.name"
                                header="Responsável da Proposta"
                            />
                            <Column
                                sortable
                                sortField="opportunity.media.name"
                                align="center"
                                alignHeader="center"
                                field="opportunity.media.name"
                                header="Fonte de Chegada"
                            />
                            <Column
                                sortable
                                sortField="opportunity.company.name"
                                align="center"
                                alignHeader="center"
                                body={CompanyConcatenate}
                                header="Empresa"
                            />
                            <Column sortable sortField="contact.name" align="center" alignHeader="center" field="contact.name" header="Contato" />
                            <Column sortable align="center" sortField="order" alignHeader="center" body={orderTable} header="Vendido" />
                            <Column
                                sortable
                                align="center"
                                sortField="status_proposal"
                                alignHeader="center"
                                style={{ width: "14rem", fontSize: "0.8rem", fontWeight: "700", textAlign: "center" }}
                                body={statusProposal}
                                header="Status"
                            />
                            <Column body={dataTableButtons} align="center" alignHeader="center" style={{ width: "10rem" }}></Column>
                        </DataTable>
                    </div>
                </div>

                <Dialog
                    visible={modalOptionalsMachine}
                    style={{ maxWidth: "1200px" }}
                    header={`Opcionais`}
                    modal
                    className="p-fluid"
                    onHide={hideDialog}
                    draggable={false}
                >
                    <DataTable
                        value={proposalOptionals}
                        stripedRows
                        selectionMode="checkbox"
                        dataKey="id"
                        rows={10}
                        globalFilter={globalFilterOptionals}
                        rowsPerPageOptions={[10, 15, 20]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} opcionais"
                        paginator
                        header={headerOptionals}
                        emptyMessage="Não há Opcionais"
                    >
                        <Column field="code" header="Código" sortable align="center" alignHeader="center" />
                        <Column field="description" header="Nome" sortable align="center" alignHeader="center" />
                    </DataTable>
                </Dialog>
            </div>

            <Dialog
                header="Filtros"
                footer={renderFooter("displayBasic")}
                visible={displayBasicMobile}
                style={{ width: "90%", height: "90%" }}
                onHide={() => onHideDialogMobile("displayBasic")}
            >
                <div className="p-col-12 p-md-12 p-lg-12">
                    <div className="p-d-flex p-flex-column p-jc-center">
                        <InputText
                            className="p-mb-2"
                            placeholder="PESQUISAR..."
                            value={advancedSearchFilter}
                            onChange={(e) => {
                                setAdvancedSearchFilter(e.target.value)
                                filter()
                            }}
                        />

                        <Calendar
                            className="p-mb-2"
                            dateFormat="dd/mm/yy"
                            id="icon"
                            locale="pt"
                            value={dateInit}
                            onMonthChange={() => ""}
                            onChange={(e) => {
                                dateInit = e.value
                                setDateInit(e.value)
                                filter()
                            }}
                            showIcon
                            placeholder={"INÍCIO"}
                        />
                        <Calendar
                            className="p-mb-2"
                            dateFormat="dd/mm/yy"
                            id="icon"
                            locale="pt"
                            value={dateFinal}
                            onMonthChange={() => ""}
                            onChange={(e) => {
                                dateFinal = e.value
                                setDateFinal(e.value)
                                filter()
                            }}
                            showIcon
                            placeholder={"FIM"}
                        />
                        <MultiSelect
                            className="p-mb-2"
                            value={selectedAccountable}
                            options={accountable}
                            disabled={!isEmptyObj(accountable) ? false : true}
                            onChange={(e) => {
                                selectedAccountable = e.value
                                setSelectedAccountable(e.value)
                                filter()
                            }}
                            emptyFilterMessage={"Não há dados"}
                            filter={true}
                            optionLabel="name"
                            placeholder={!isEmptyObj(accountable) ? "RESPONSÁVEL" : "NÃO HÁ RESPONSÁVEL"}
                        />

                        <MultiSelect
                            className="p-mb-2"
                            value={selectedInterest}
                            options={interest}
                            disabled={!isEmptyObj(interest) ? false : true}
                            onChange={(e) => {
                                selectedInterest = e.value
                                setSelectedInterest(e.value)
                                filter()
                            }}
                            emptyFilterMessage={"Não há dados"}
                            filter={true}
                            optionLabel="name"
                            placeholder={!isEmptyObj(accountable) ? "FONTES" : "NÃO HÁ FONTES"}
                        />

                        <Button
                            className="p-button-rounded p-jc-end"
                            label="LIMPAR FILTROS"
                            icon="pi pi-times"
                            loading={loadingCleanFilters}
                            onClick={onLoadingCleanFilters}
                        />
                    </div>
                </div>
            </Dialog>
        </App>
    )
}
