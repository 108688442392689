import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { useState } from "react"
import { MachineApresentationInterface } from "../../../../../interfaces/MachineApresentationInterface"
import { OpportunityInterface } from "../../../../../interfaces/OpportunityInterface"
import api from "../../../../../services/api"

interface PropsInterface {
    toast: any
    presentation: MachineApresentationInterface
    setPresentation: React.Dispatch<React.SetStateAction<MachineApresentationInterface>>
    opportunity: OpportunityInterface
    setOpportunity: React.Dispatch<React.SetStateAction<OpportunityInterface>>
    setPresentationEditDialog: React.Dispatch<React.SetStateAction<boolean>>
}

export const UpdateAttachmentName = ({ toast, opportunity, presentation, setPresentation, setOpportunity, setPresentationEditDialog }: PropsInterface) => {
    let [loadingSubmitPresentation, setLoadingSubmitPresentation] = useState<boolean>(false)

    const onSubmitEditPresentation = () => {
        setLoadingSubmitPresentation(true)
        let auxConfirmPresentation = {
            id: presentation.id,
            name: presentation.name,
        }

        try {
            let header = {
                headers: {
                    route: "update_attachment",
                },
            }
            api.put("attachments", auxConfirmPresentation, header)

                .then((res) => {
                    if (res.status === 200) {
                        setPresentationEditDialog(false)
                        setLoadingSubmitPresentation(false)
                        setOpportunity((prevState) => ({
                            ...prevState,
                            attachments: opportunity.attachments.map((attachment: any) => {
                                if (attachment.id === presentation.id) {
                                    return {
                                        ...attachment,
                                        name: presentation.name,
                                    }
                                } else {
                                    return attachment
                                }
                            }),
                        }))

                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na alteração",
                            detail: "Anexo editado com sucesso!",
                            life: 3000,
                        })
                    } else {
                        setLoadingSubmitPresentation(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Editar",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingSubmitPresentation(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao Editar",
                        detail: "Por favor verifique os dados inseridos",
                        life: 3000,
                    })
                })
        } catch (error) {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao Editar",
                detail: "Verifique os dados inseridos",
                life: 3000,
            })
        }
    }

    const onChangeEditPresentation = (event: any) => {
        const { value, name } = event.target
        setPresentation({
            ...presentation,
            [name]: value,
        })
    }

    return (
        <>
            <div className="p-field">
                <label htmlFor="name">Nome</label>
                <InputText
                    style={{ minWidth: "20rem", textTransform: "uppercase" }}
                    id="name"
                    name="name"
                    maxLength={120}
                    onChange={(e: any) => onChangeEditPresentation(e)}
                    value={presentation.name || ""}
                />
            </div>

            <div
                style={{
                    display: "flex",
                    marginTop: "3rem",
                    width: "fit-content",
                    marginLeft: "auto",
                }}
            >
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    className="p-button p-button-text p-button-danger"
                    onClick={() => setPresentationEditDialog(false)}
                />
                <Button
                    style={{
                        marginLeft: "1rem",
                    }}
                    label="Salvar"
                    icon="pi pi-check"
                    className="p-button p-button-text p-button-success"
                    loading={loadingSubmitPresentation}
                    onClick={onSubmitEditPresentation}
                />
            </div>
        </>
    )
}
