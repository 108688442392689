import { Avatar } from "primereact/avatar"
import { Chip } from "primereact/chip"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { useRef } from "react"
import { ContactInterface } from "../../../../interfaces/ContactInterface"

interface Props {
    auxContacts: ContactInterface[]
    loadingListContacts: boolean
    onUpdateContactPerson: (auxRequisition: any) => void
    setChangeResponsibleDialog: (changeResponsibleDialog: boolean) => void
    responsibleContact: any
}


export const ChangeContactDataTable = (props: Props) => {
    let dataTable: any = useRef(null)

    let noPictureURL = "/assets/pages/icons/no-avatar.png"

    const ConcatenateTelephoneChangeContact = (rowData: any) => {
        return (
            <div
                onClick={() => {
                    let auxRequisition = []
                    auxRequisition.push(rowData.id, props.responsibleContact.id)
                    props.onUpdateContactPerson(auxRequisition)
                    props.setChangeResponsibleDialog(false)
                }}
            >
                <Chip style={{ margin: "5px", color: "white", backgroundColor: "#3574db" }} label={"Selecionar Contato"} />{" "}
            </div>
        )
    }

    const companyFormaterDataTable = (rowData: any) => {
        return rowData.company.name === null ? <>-</> : rowData.company.name
    }

    const companyImageDataTable = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Imagem</span>
                <Avatar
                    image={rowData.company.picture_url === null ? noPictureURL : rowData.company.picture_url}
                    className="p-mr-2"
                    size="xlarge"
                    shape="circle"
                />
            </>
        )
    }


    return (
        <DataTable
            ref={dataTable}
            value={props.auxContacts}
            loading={props.loadingListContacts}
            stripedRows
            dataKey="id"
            rows={25}
            rowsPerPageOptions={[25, 50, 100]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} contatos"
            paginator
            emptyMessage="Não há Contatos"
        >
            <Column body={companyImageDataTable} style={{ textTransform: "uppercase" }} header="" align="center" alignHeader="center" />
            <Column sortable field="name" header="Nome" align="center" alignHeader="center" style={{ textTransform: "uppercase" }} />
            <Column
                sortable
                align="center"
                alignHeader="center"
                body={companyFormaterDataTable}
                header="Empresa/Cliente"
                sortField="company.name"
            />
            <Column body={ConcatenateTelephoneChangeContact} style={{ cursor: "pointer" }} align="center" alignHeader="center" />
        </DataTable>
    )
}