import React from "react"
import { Card } from "primereact/card"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"
import { Chip } from "primereact/chip"
import { Button } from "primereact/button"
import { InputMask } from "primereact/inputmask"
import { Editor } from "primereact/editor"

export const MetaForms = (props: any) => {
    const cities = [{ name: "Nenhum" }, { name: "Texto" }, { name: "Midia" }]
    const action = [{ name: "Ligar" }, { name: "Acessar o Site" }]

    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
        )
    }

    const header = renderHeader()

    return (
        <div className="p-mr-2" style={{ width: "80%" }}>
            <div className="p-d-flex">
                <div className="p-mr-2">
                    <h4 style={{ fontWeight: "bold" }}>
                        Criar Modelo:
                        <InputText
                            placeholder="Nome do modelo"
                            value={props.nameModel}
                            onChange={(e) => props.setNameModel(e.target.value)}
                            style={{ marginLeft: "1rem", width: "50rem" }}
                            maxLength={512}
                        />
                    </h4>
                </div>
            </div>
            <hr />
            <br />
            <div className="p-d-flex">
                <div className="p-mr-2">
                    <h4 style={{ fontWeight: "bold" }}>Cabeçalho</h4>
                </div>
                <div className="p-mr-2">
                    <Chip label="Opcional" className="mr-2 mb-2" style={{ height: "18px", fontSize: "12px" }} />
                </div>
            </div>
            <p>Adicione um título ou escolha qual tipo de mídia você usará para esse cabeçalho</p>
            <Dropdown value={props.selectedHeader} options={cities} onChange={props.onCityChange} optionLabel="name" placeholder="Nenhum" />
            {props.selectedHeader?.name === "Texto" && (
                <>
                    <InputText
                        maxLength={50}
                        value={props.headerText}
                        style={{ width: "90%", marginLeft: "1rem" }}
                        onChange={(e) => props.setHeadertext(e.target.value)}
                        placeholder="Digite o texto do cabeçalho"
                    />
                    <br /> <br />
                    <p>Caracteres: {props.headerText ? props.headerText.length : "0"}/50</p>
                </>
            )}
            {props.selectedHeader?.name === "Midia" && (
                <>
                    <br /> <br />
                    <div className="p-d-flex">
                        <div className="p-mr-2">
                            <Card style={{ width: "20rem", marginBottom: "2em" }}>
                                <div className="p-d-flex p-jc-center">
                                    <i className="pi pi-image" style={{ fontSize: "9em", color: "gray" }}></i>
                                </div>
                                <h4 style={{ color: "gray", textAlign: "center" }}>IMAGEM</h4>
                            </Card>
                        </div>

                        <div className="p-mr-2">
                            <Card style={{ width: "20rem", marginBottom: "2em" }}>
                                <div className="p-d-flex p-jc-center">
                                    <i className="pi pi-play" style={{ fontSize: "9em", color: "gray" }}></i>
                                </div>
                                <h4 style={{ color: "gray", textAlign: "center" }}>VIDEO</h4>
                            </Card>
                        </div>

                        <div className="p-mr-2">
                            <Card style={{ width: "20rem", marginBottom: "2em" }}>
                                <div className="p-d-flex p-jc-center">
                                    <i className="pi pi-book" style={{ fontSize: "9em", color: "gray" }}></i>
                                </div>
                                <h4 style={{ color: "gray", textAlign: "center" }}>DOCUMENTO</h4>
                            </Card>
                        </div>
                    </div>
                </>
            )}
            <h4 style={{ fontWeight: "bold" }}>Corpo</h4>
            <p>Insira o texto da sua mensagem no idioma selecionado</p>
            <Editor
                headerTemplate={header}
                style={{ height: "16rem", fontSize: "13px", width: "90%" }}
                value={props.bodyText}
                onTextChange={(e: any) => props.setBodyText(e.htmlValue)}
            />
            <p>Caracteres: {props.bodyText ? props.bodyText.length : "0"}/ 1024</p>
            <br />
            <br />
            <div className="p-d-flex">
                <div className="p-mr-2">
                    <h4 style={{ fontWeight: "bold" }}>Rodapé</h4>
                </div>
                <div className="p-mr-2">
                    <Chip label="Opcional" className="mr-2 mb-2" style={{ height: "18px", fontSize: "12px" }} />
                </div>
            </div>
            <p>Adicione uma pequena linha de texti na parte inferior do seu modelo de mensagem.</p>
            <InputText
                maxLength={50}
                value={props.footerText}
                style={{ width: "100%" }}
                onChange={(e) => props.setFooterText(e.target.value)}
                placeholder="Digite o texto do rodapé"
            />
            <br /> <br />
            <p>Caracteres: {props.footerText ? props.footerText.length : "0"}/50</p>
            <br />
            <div className="p-d-flex">
                <div className="p-mr-2">
                    <h4 style={{ fontWeight: "bold" }}>Botões</h4>
                </div>
                <div className="p-mr-2">
                    <Chip label="Opcional" className="mr-2 mb-2" style={{ height: "18px", fontSize: "12px" }} />
                </div>
            </div>
            <p>Crie até 2 botoões para permitir que os clientes respondam à a sua mensagem ou executam alguma ação.</p>
            {!props.firstButton && (
                <Button
                    label="Adicionar Botão"
                    onClick={() => {
                        props.setFirstButton(true)
                    }}
                    icon="pi pi-plus"
                    className="p-button-secondary p-button-outlined"
                />
            )}
            {props.firstButton && (
                <Button
                    label="Remover Botão"
                    onClick={() => {
                        props.setFirstButton(false)
                        props.setSecondButton(false)
                    }}
                    icon="pi pi-minus"
                    className="p-button-secondary p-button-outlined"
                />
            )}
            <br /> <br />
            {props.firstButton && (
                <>
                    <Card style={{ width: "100%", marginBottom: "2em", backgroundColor: "#f2f4f7" }}>
                        <div className="p-d-flex">
                            <div className="p-mr-2">
                                <h5 style={{ fontWeight: "bold" }}>Tipo de Ação</h5>
                                <Dropdown
                                    style={{ width: "20rem" }}
                                    value={props.selectedAction}
                                    options={action}
                                    onChange={props.onFirstButtonChange}
                                    optionLabel="name"
                                    placeholder="Nenhum"
                                />
                            </div>

                            {props.selectedAction && (
                                <div className="p-mr-4" style={{ paddingLeft: "2rem" }}>
                                    <h5 style={{ fontWeight: "bold", paddingLeft: "10px" }}>Texto do Botão</h5>
                                    <InputText
                                        maxLength={50}
                                        value={props.firstButtonText}
                                        style={{ width: "20rem", marginLeft: "1rem" }}
                                        onChange={(e) => props.setFirstButtonText(e.target.value)}
                                        placeholder="Digite o texto do botão"
                                    />
                                </div>
                            )}

                            {props.selectedAction?.name === "Acessar o Site" && (
                                <>
                                    <div className="p-mr-2" style={{ paddingLeft: "2rem" }}>
                                        <h5 style={{ fontWeight: "bold", paddingLeft: "10px" }}>URL</h5>
                                        <InputText
                                            maxLength={2000}
                                            value={props.firstButtonActionText}
                                            style={{ width: "30rem", marginLeft: "1rem" }}
                                            onChange={(e) => props.setFirstButtonAtcion(e.target.value)}
                                            placeholder="https://esquadros.com.br/"
                                        />
                                    </div>
                                </>
                            )}
                            {props.selectedAction?.name === "Ligar" && (
                                <>
                                    <div className="p-mr-2" style={{ paddingLeft: "2rem" }}>
                                        <h5 style={{ fontWeight: "bold", paddingLeft: "10px" }}>Telefone</h5>
                                        <InputMask
                                            id="phone"
                                            mask="+99 (999) 99999-9999"
                                            value={props.firstButtonActionText}
                                            placeholder="+55 (999) 99999-9999"
                                            onChange={(e) => props.setFirstButtonAtcion(e.value)}
                                        ></InputMask>
                                    </div>
                                </>
                            )}
                        </div>
                        <br />
                        <br />
                    </Card>
                </>
            )}
            <br />
            {props.firstButton && !props.secondButton && (
                <Button
                    label="Adicionar Botão"
                    onClick={() => {
                        props.setSecondButton(true)
                    }}
                    icon="pi pi-plus"
                    className="p-button-secondary p-button-outlined"
                />
            )}
            {props.secondButton && (
                <Button
                    label="Remover Botão"
                    onClick={() => {
                        props.setSecondButton(false)
                    }}
                    icon="pi pi-minus"
                    className="p-button-secondary p-button-outlined"
                />
            )}
            <br /> <br />
            {props.secondButton && (
                <>
                    <Card style={{ width: "100%", marginBottom: "2em", backgroundColor: "#f2f4f7" }}>
                        <div className="p-d-flex">
                            <div className="p-mr-2">
                                <h5 style={{ fontWeight: "bold" }}>Tipo de Ação</h5>
                                <Dropdown
                                    style={{ width: "20rem" }}
                                    value={props.selectedAction2}
                                    options={action}
                                    onChange={props.onSecondButtonChange}
                                    optionLabel="name"
                                    placeholder="Nenhum"
                                />
                            </div>

                            {props.selectedAction2 && (
                                <div className="p-mr-4" style={{ paddingLeft: "2rem" }}>
                                    <h5 style={{ fontWeight: "bold", paddingLeft: "10px" }}>Texto do Botão</h5>
                                    <InputText
                                        maxLength={50}
                                        value={props.secondButtonText}
                                        style={{ width: "20rem", marginLeft: "1rem" }}
                                        onChange={(e) => props.setSecondButtonText(e.target.value)}
                                        placeholder="Digite o texto do botão"
                                    />
                                </div>
                            )}

                            {props.selectedAction2?.name === "Acessar o Site" && (
                                <>
                                    <div className="p-mr-2" style={{ paddingLeft: "2rem" }}>
                                        <h5 style={{ fontWeight: "bold", paddingLeft: "10px" }}>URL</h5>
                                        <InputText
                                            maxLength={2000}
                                            value={props.secondButtonActionText}
                                            style={{ width: "30rem", marginLeft: "1rem" }}
                                            onChange={(e) => props.setSecondButtonAtcion(e.target.value)}
                                            placeholder="https://esquadros.com.br/"
                                        />
                                    </div>
                                </>
                            )}
                            {props.selectedAction2?.name === "Ligar" && (
                                <>
                                    <div className="p-mr-2" style={{ paddingLeft: "2rem" }}>
                                        <h5 style={{ fontWeight: "bold", paddingLeft: "10px" }}>Telefone</h5>
                                        <InputMask
                                            id="phone"
                                            mask="+99 (999) 99999-9999"
                                            value={props.secondButtonActionText}
                                            placeholder="+55 (999) 99999-9999"
                                            onChange={(e) => props.setSecondButtonAtcion(e.value)}
                                        ></InputMask>
                                    </div>
                                </>
                            )}
                        </div>
                        <br />
                        <br />
                    </Card>
                </>
            )}
        </div>
    )
}
