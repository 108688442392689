
import { useEffect, useState, useRef } from "react"

import MicRecorder from "mic-recorder-to-mp3"
import { Button } from "primereact/button"
import { Toast } from "primereact/toast"

//Styles
import * as S from "./index.styles"

export const AudioRecorder = (props) => {
    let toast = useRef(null)

    let micIcon = "/assets/pages/icons/mic.png"
    let soundGif = "/assets/pages/icons/sound.gif"

    let [currentRecording, setCurrentRecording] = useState(false)
    let [currentRecordingButtons, setCurrentRecordingButtons] = useState(false)
    let [counter, setCounter] = useState(0)
    let [isActiveRecording, setIsActiveRecording] = useState(false)

    let [blobURL, setBlobUrl] = useState(null)

    let [minuteCounter, setMinuteCounter] = useState("00")
    let [secondCounter, setSecondCounter] = useState("00")

    const [recorder, setRecorder] = useState(new MicRecorder({ bitRate: 128 }))

    const updatePropsActive = () => {
        props.setRecording(true)
        props.setMicRecprder(true)
        props.setIsActive(true)
        props.setStatusClose(true)
        props.setSendFileAudio(false)
    }

    const updatePropsInactive = () => {
        props.setRecording(false)
        props.setMicRecprder(false)
        props.setIsActive(false)
        props.setStatusClose(false)
        props.setSendFileAudio(true)
    }

    const startRecording = () => {
        let recorderQuery = { name: "microphone" }

        navigator.permissions
            .query(recorderQuery)
            .then((permissionObj) => {
                if (permissionObj.state !== "denied") {
                    recorder.start()
                    updatePropsActive()
                    setCurrentRecording(true)
                    setIsActiveRecording(true)
                } else {
                    updatePropsInactive()
                    props.onNotification()
                }
            })

            .catch((error) => {
                console.log(error)
            })
    }

    const stopRecording = () => {
        recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                const file = new File(buffer, "music.mp3", {
                    type: blob.type,
                    lastModified: Date.now(),
                })

                const blobURL = URL.createObjectURL(file)

                setIsActiveRecording(false)
                setBlobUrl(blobURL)
            })
    }

    useEffect(() => {
        let intervalId

        if (isActiveRecording) {
            intervalId = setInterval(() => {
                const secondCounter = counter % 60
                const minuteCounter = Math.floor(counter / 60)

                let computedSecond = String(secondCounter).length === 1 ? `0${secondCounter}` : secondCounter
                let computedMinute = String(minuteCounter).length === 1 ? `0${minuteCounter}` : minuteCounter

                setSecondCounter(computedSecond)
                setMinuteCounter(computedMinute)

                setCounter((counter) => counter + 1)
            }, 1000)
        } else {
            setCounter(0)
        }

        return () => clearInterval(intervalId)
    }, [counter, isActiveRecording])

    return (
        <>
            <Toast ref={toast} style={{ marginBottom: "100%" }} />
            <S.container>
                {!currentRecording && (
                    <S.buttonStartAudio>
                        <S.micIcon
                            src={micIcon}
                            alt="Microphone para gravação de audio"
                            onClick={() => {
                                startRecording()
                            }}
                        />
                    </S.buttonStartAudio>
                )}

                {currentRecording && (
                    <S.containerCurrentRecording>
                        {!currentRecordingButtons && (
                            <>
                                <S.titleCurrentRecording>
                                    Gravando - {minuteCounter} : {secondCounter}
                                </S.titleCurrentRecording>

                                <S.soundGif src={soundGif} alt="Gif audio" />
                            </>
                        )}

                        <S.containerButtonsCurrentRecording>
                            {!currentRecordingButtons && (
                                <>
                                    <Button
                                        icon="pi pi-trash"
                                        className="p-button-rounded p-button-danger p-mr-4 p-button-microphone"
                                        onClick={() => {
                                            setCurrentRecording(false)
                                            stopRecording()
                                            updatePropsInactive()
                                        }}
                                    />

                                    <Button
                                        icon="pi pi-stop-circle"
                                        className="p-button-rounded p-button-secondary p-mr-4 p-button-microphone"
                                        onClick={() => {
                                            stopRecording()
                                            setCurrentRecordingButtons(true)
                                        }}
                                    />
                                </>
                            )}

                            {currentRecordingButtons && (
                                <>
                                    <S.audioView src={blobURL} playsInline={true} controls />

                                    <Button
                                        icon="pi pi-times"
                                        className="p-button-rounded p-button-danger p-mr-4 p-button-microphone"
                                        onClick={() => {
                                            stopRecording()
                                            setCurrentRecordingButtons(false)
                                            setCurrentRecording(false)
                                            updatePropsInactive()
                                        }}
                                    />

                                    <Button
                                        icon="pi pi-send"
                                        className="p-button-rounded p-button-secondary p-mr-4 p-button-microphone"
                                        onClick={() => {
                                            props.onSendAudio(blobURL)
                                            stopRecording()
                                            updatePropsInactive()
                                            setCurrentRecordingButtons(false)
                                            setCurrentRecording(false)
                                        }}
                                    />
                                </>
                            )}
                        </S.containerButtonsCurrentRecording>
                    </S.containerCurrentRecording>
                )}
            </S.container>
        </>
    )
}
