import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import Moment from "react-moment"
import { ConnectionsInterface } from "../../../../interfaces/ConnectionsInterface"

interface I_TabPanelConnections {
    connections: ConnectionsInterface[]
}

export const TabPanelConnections = ({ connections }: I_TabPanelConnections) => {
    const formatDuration = (seconds: number) => {
        const hours = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds % 3600) / 60)
        const remainingSeconds = seconds % 60

        const formattedHours = String(hours).padStart(2, "0")
        const formattedMinutes = String(minutes).padStart(2, "0")
        const formattedSeconds = String(remainingSeconds).padStart(2, "0")

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
    }

    return (
        <>
            <br />
            <DataTable value={connections} rows={10} emptyMessage="Nenhuma ligação encontrada" paginator>
                <Column body={(item) => <Moment format="DD/MM/YYYY HH:mm">{item.calldate}</Moment>} header="Data | Hora"></Column>
                <Column field="src" header="Número Raiz"></Column>
                <Column field="dst" header="Número Destino"></Column>
                <Column body={(item) => <>{formatDuration(item.duration)}</>} header="Duração"></Column>
                <Column
                    body={(item) => (
                        <a
                            href={`http://192.168.0.8/index.php?menu=monitoring&action=display_record&id=${item.uniqueid}&namefile=${item.recordingfile}&rawmode=yes`}
                            target="_blank"
                        >
                            {item.recordingfile}
                        </a>
                    )}
                    header="Arquivo"
                ></Column>
            </DataTable>
        </>
    )
}
