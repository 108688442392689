import Dropzone, { IDropzoneProps } from "react-dropzone-uploader"

type Props = {
    size: number
    types: string
    onSubmit: (files: any) => void
}
export const LocalUpload = (props: Props) => {
    return (
        <Dropzone
            styles={{
                dropzone: { minWidth: "50rem", height: "20rem", overflow: "hidden" },
            }}
            inputContent="📁 Arraste ou selecione um arquivo"
            submitButtonContent="Enviar"
            accept={props.types}
            onSubmit={(files) => props.onSubmit(files)}
            maxSizeBytes={props.size}
            maxFiles={1}
        />
    )
}
