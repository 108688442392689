import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { InputText } from "primereact/inputtext"
import { ScrollPanel } from "primereact/scrollpanel"
import Moment from "react-moment"
import { OpportunityInterface } from "../../../../interfaces/OpportunityInterface"
import { TaskInterface } from "../../../../interfaces/TaskInterface"
import moment from "moment"

interface I_TabPanelTasks {
    setTasksDialog: (e: any) => void
    setOpportunity: (e: any) => void
    setTask: (e: any) => void
    setConfirmConclude: (e: any) => void
    setTasksEditDialog: (e: any) => void
    setChangeDate: (e: any) => void
    setOpenTask: (e: any) => void

    opportunity: OpportunityInterface
    auxTask: TaskInterface[]
    loadingUpdateOpportunityReturnOpen: boolean
}

export const TabPanelTasks = ({
    setTasksDialog,
    setOpportunity,
    setTask,
    setConfirmConclude,
    setTasksEditDialog,
    setChangeDate,
    setOpenTask,
    opportunity,
    auxTask,
    loadingUpdateOpportunityReturnOpen,
}: I_TabPanelTasks) => {
    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    const confirmTask = (item: TaskInterface) => {
        setTask(item)
        setConfirmConclude(true)
    }

    const filterTasks = (task: TaskInterface, filter: string) => {
        let words = filter.split(" ")
        return words.every((word) => task.subject.toLowerCase().includes(word.toLowerCase()))
    }

    const SearchFilter = (filter: string) => {
        if (filter.length > 0) {
            let filteredTasks = opportunity.tasks.filter((task) => filterTasks(task, filter))
            setOpportunity({ ...opportunity, tasks: filteredTasks })
        } else {
            setOpportunity({ ...opportunity, tasks: auxTask })
        }
    }

    const checkTaskStatus = (task: TaskInterface) => {
        let response = { status: "", days: 0, color: "", massage: "" }
        let remainingDate = 0
        let lateDate = 0

        if (task.status === "open") {
            var p = moment(new Date()).format()
            var s = moment(task.date).format()

            remainingDate = moment(s).diff(p, "days")
            lateDate = moment(p).diff(s, "days")

            if (p > s) {
                response = {
                    status: "late_open",
                    days: lateDate,
                    color: "orange",
                    massage: "Dias de atraso: ",
                }
            } else {
                response = {
                    status: "open_on_day",
                    days: remainingDate,
                    color: "green",
                    massage: "Dias para vencer: ",
                }
            }
        } else {
            response = {
                status: "closed",
                days: remainingDate,
                color: "red",
                massage: "Tarefa Fechada",
            }
        }

        return response
    }

    const postPoneTask = (item: TaskInterface) => {
        setTask(item)
        setChangeDate(true)
    }

    const openTask = (task: TaskInterface) => {
        setTask(task)
        setOpenTask(true)
    }

    return (
        <>
            <div className="p-d-flex p-jc-between">
                <Button
                    label="Adicionar Tarefa"
                    disabled={opportunity.status === "gain" ? true : false}
                    onClick={() => {
                        setTasksDialog(true)
                    }}
                />

                <InputText
                    type="search"
                    onChange={(e: any) => {
                        SearchFilter(e.target.value)
                    }}
                    placeholder="Pesquisar..."
                />
            </div>

            <br />

            <div className="p-grid">
                {!isEmptyObj(opportunity.tasks)
                    ? opportunity.tasks.map((task, indexTask) => (
                          <div className="p-col-12 p-md-6 p-lg-6" key={indexTask}>
                              <Card style={{ boxShadow: `inset 0 0 0.4em ${checkTaskStatus(task).color}` }}>
                                  <div className="p-grid p-ai-center">
                                      <div className="p-col-12 p-md-9 p-lg-9">
                                          <ScrollPanel style={{ width: "100%", height: "5rem" }}>
                                              <h5>{task.subject}</h5>
                                          </ScrollPanel>

                                          <div className="p-grid">
                                              <div className="p-col-12 p-md-6 p-lg-6 p-d-flex p-ai-center">
                                                  <div className="p-mr-2 p-mb-2">
                                                      <i style={{ fontSize: "1.5em" }} className="pi pi-calendar"></i>
                                                  </div>

                                                  <div className="p-mr-3 p-mb-3">
                                                      <span>
                                                          <strong>Inicio: </strong>
                                                      </span>
                                                  </div>

                                                  <div className="p-mr-3 p-mb-3">
                                                      <Moment format="DD-MM-YYYY HH:mm">{task.created_at}</Moment>
                                                  </div>
                                              </div>

                                              <div className="p-col-12 p-md-6 p-lg-6 p-d-flex p-ai-center">
                                                  <div className="p-mr-2 p-mb-2">
                                                      <i style={{ fontSize: "1.5em" }} className="pi pi-calendar-times"></i>
                                                  </div>

                                                  <div className="p-mr-3 p-mb-3">
                                                      <span>
                                                          <strong>Fim: </strong>
                                                      </span>
                                                  </div>

                                                  <div className="p-mr-3 p-mb-3">
                                                      <Moment format="DD-MM-YYYY HH:mm">{task.date}</Moment>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="p-grid">
                                              <div className="p-col-12 p-md-6 p-lg-6 p-d-flex p-ai-center">
                                                  <div className="p-mr-2 p-mb-2">
                                                      <i style={{ fontSize: "1.5em" }} className="pi pi-clock"></i>
                                                  </div>

                                                  <div className="p-mr-3 p-mb-3">
                                                      <span>
                                                          <strong>{checkTaskStatus(task).massage} </strong>
                                                      </span>
                                                  </div>

                                                  <div className="p-mr-3 p-mb-3">
                                                      {checkTaskStatus(task).status !== "closed" ? checkTaskStatus(task).days + " Dias" : ""}
                                                  </div>
                                              </div>

                                              <div className="p-col-12 p-md-6 p-lg-6 p-d-flex p-ai-center">
                                                  <div className="p-mr-2 p-mb-2">
                                                      <i style={{ fontSize: "1.5em" }} className="pi pi-user"></i>
                                                  </div>

                                                  <div className="p-mr-3 p-mb-3">
                                                      <span>
                                                          {task.users &&
                                                              task.users.map((user, indexUser) => (
                                                                  <span key={indexUser}>
                                                                      <span>{user.name}</span>
                                                                  </span>
                                                              ))}
                                                          {task.users && task.users.length === 0 && (
                                                              <span>
                                                                  <span>Nenhum Responsável</span>
                                                              </span>
                                                          )}
                                                      </span>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="p-grid">
                                              <div className="p-col-12 p-d-flex p-ai-center"></div>
                                          </div>
                                      </div>

                                      <div className="p-col-12 p-md-3 p-lg-3">
                                          <div className="p-d-flex p-jc-end p-flex-column">
                                              {task.status === "open" && (
                                                  <>
                                                      <div className="p-mr-2 p-mb-2">
                                                          <Button
                                                              style={{ minWidth: "100%" }}
                                                              label="Editar"
                                                              className="p-button-outlined"
                                                              iconPos="right"
                                                              icon="pi pi-pencil"
                                                              onClick={() => {
                                                                  setTasksEditDialog(true)
                                                                  setTask(task)
                                                              }}
                                                          />
                                                      </div>

                                                      <div className="p-mr-2 p-mb-2">
                                                          <Button
                                                              style={{ minWidth: "100%" }}
                                                              label="Adiar"
                                                              className="p-button-outlined p-button-secondary"
                                                              iconPos="right"
                                                              icon="pi pi-clock"
                                                              onClick={() => postPoneTask(task)}
                                                          />
                                                      </div>
                                                  </>
                                              )}
                                              {task.status === "open" && (
                                                  <div className="p-mr-2 p-mb-2">
                                                      <Button
                                                          style={{ minWidth: "100%" }}
                                                          className="p-button-outlined p-button-success"
                                                          label="Concluir"
                                                          icon="pi pi-check"
                                                          iconPos="right"
                                                          onClick={() => confirmTask(task)}
                                                      />
                                                  </div>
                                              )}
                                              {task.status === "finished" && (
                                                  <div className="p-mr-2 p-mb-2">
                                                      <Button
                                                          className="p-button-outlined p-button-help"
                                                          style={{ minWidth: "100%" }}
                                                          label="Retomar Tarefa"
                                                          loading={loadingUpdateOpportunityReturnOpen}
                                                          value="open"
                                                          iconPos="right"
                                                          icon="pi pi-arrow-down-right"
                                                          onClick={(e: any) => openTask(task)}
                                                      />
                                                  </div>
                                              )}
                                          </div>
                                      </div>
                                  </div>
                              </Card>
                          </div>
                      ))
                    : ""}
            </div>
        </>
    )
}
