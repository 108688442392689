import React, { useRef, useState, useEffect } from "react"
import api from "../../services/api"

//PrimeReact
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Skeleton } from "primereact/skeleton"
import { Toast } from "primereact/toast"
import { useParams } from "react-router-dom"

//Interface
import { OpportunityProposalInterface } from "../../interfaces/OpportunityProposalInterface"

//Components
import AllPagesPDFViewer from "../../components/PDF/all-pages"
import "../../components/PDF/index.scss"
import "../../components/PDF/all-pages"

export const Proposal = () => {
    let toast = useRef<Toast>(null)
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    let params: any = useParams()
    let { proposal, password } = params

    let [passwordDialog, setPasswordDialog] = useState(localStorage.getItem("@esquadros:passwordProposal") !== null ? false : true)
    let [opportunityProposals, setOpportunityProposals] = useState<OpportunityProposalInterface>({} as OpportunityProposalInterface)
    let [passwordValue, setPasswordValue] = useState<string>("")
    let [loadingPassword, setLoadingPassword] = useState<boolean>(false)
    let widthPage: number = window.screen.width

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    const allOpportunityProposal = async () => {
        let header = {
            headers: {
                route: "show_public_opportunity_proposal",
            },
        }

        let auxProposal = {
            id: proposal,
            password: localStorage.getItem("@esquadros:passwordProposal") !== null ? localStorage.getItem("@esquadros:passwordProposal") : password,
        }

        await api
            .post("opportunity_proposals/public", auxProposal, header)
            .then((res) => {
                if (res.status === 200) {
                    setOpportunityProposals(res.data)
                } else {
                    setPasswordDialog(true)
                    localStorage.removeItem("@esquadros:passwordProposal")
                }
            })

            .catch((error) => {
                setPasswordDialog(true)
                localStorage.removeItem("@esquadros:passwordProposal")
            })
    }

    const onSubmitPassword = (event: any) => {
        event.preventDefault()
        setLoadingPassword(true)

        if (passwordValue === "") {
            setLoadingPassword(false)

            toast.current?.show({
                severity: "warn",
                summary: "Ops!",
                detail: "Por favor, digite uma senha para continuar!",
                life: 3000,
            })
        } else {
            let auxProposal = {
                id: proposal,
                password: passwordValue,
            }

            let header = {
                headers: {
                    route: "show_opportunity_proposal",
                },
            }

            api.post("opportunity_proposals/public", auxProposal, header)
                .then((res) => {
                    if (res.status === 200) {
                        setOpportunityProposals(res.data)
                        hideDialog()
                        setLoadingPassword(false)
                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na autenticação",
                            detail: "Senha inserida com sucesso!",
                            life: 3000,
                        })

                        localStorage.setItem("@esquadros:passwordProposal", passwordValue)
                    } else {
                        setLoadingPassword(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao autenticar",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingPassword(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao autenticar",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        }
    }

    const stylePageProposal = () => {
        if (widthPage > 800) {
            window.document.body.classList.add("apresentation-body-desktop")
        } else {
            window.document.body.classList.add("apresentation-body-mobile")
        }
    }

    const hideDialog = () => {
        setPasswordDialog(false)
    }

    useEffect(() => {
        stylePageProposal()
        let passwordProposal = localStorage.getItem("@esquadros:passwordProposal")
        if (passwordProposal !== null || password !== undefined) {
            setPasswordDialog(false)
            allOpportunityProposal()
        }
    }, [])

    return (
        <>
            <Toast ref={toast} />
            {isEmptyObj(opportunityProposals) && (
                <div className="p-grid">
                    <div className="p-col-12 p-md-12 p-lg-12">
                        <div className="card">
                            <div className="grid formgrid">
                                <Skeleton width="90%" height="5rem" />
                                <br />
                                <Skeleton width="90%" height="3rem" />
                                <br />
                                <Skeleton width="50%" height="5rem" />
                                <br />
                                <Skeleton width="80%" height="3rem" />
                                <br />
                                <Skeleton width="60%" height="5rem" />
                                <br />
                                <Skeleton width="30%" height="3rem" />
                                <br />
                                <Skeleton width="50%" height="5rem" />
                                <br />
                                <Skeleton width="80%" height="2rem" />
                                <br />
                                <Skeleton width="60%" height="5rem" />
                                <br />
                                <Skeleton width="30%" height="2rem" />
                                <br />
                                <Skeleton width="90%" height="3rem" />
                                <br />
                                <Skeleton width="50%" height="5rem" />
                                <br />
                                <Skeleton width="80%" height="3rem" />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <Dialog
                visible={passwordDialog}
                breakpoints={{ "960px": "75vw", "640px": "100vw" }}
                modal
                className="p-fluid"
                onHide={hideDialog}
                draggable={false}
                closable={false}
            >
                <form onSubmit={onSubmitPassword}>
                    <div className="p-d-flex p-flex-column p-flex-md-row">
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <div className="p-field">
                                <div>
                                    <h5>Insira a senha para continuar: </h5>
                                </div>
                                <div className="p-d-flex">
                                    <i className="pi pi-lock p-mr-2 p-as-center"></i>
                                    <InputText
                                        style={{ minWidth: "15rem" }}
                                        id="password"
                                        type="number"
                                        name="password"
                                        placeholder="Senha"
                                        value={passwordValue}
                                        onChange={(e: any) => {
                                            setPasswordValue(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-dialog-footer">
                        <Button type="submit" label="Entrar" icon="pi pi-check" className="p-button p-button-text" loading={loadingPassword} />
                    </div>
                </form>
            </Dialog>

            {!isEmptyObj(opportunityProposals) && (
                <>
                    <div className="p-col-12">
                        <div className="Proposal">
                            <div className="all-page-container" style={{ position: "relative" }}>
                                {!isEmptyObj(opportunityProposals.file_url) && (
                                    <>
                                        {widthPage > 1001 && (
                                            <>
                                                <div className="apresentation-proposal-desktop">
                                                    <iframe src={opportunityProposals.file_url + `#toolbar=0`} title="Proposta Esquadros" />
                                                </div>
                                            </>
                                        )}

                                        {widthPage < 1000 && (
                                            <>
                                                <div className="apresentation-proposal-moblie">
                                                    <AllPagesPDFViewer pdf={opportunityProposals.file_url} />
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}

            {isEmptyObj(opportunityProposals.file_url) &&
                toast.current?.show({
                    severity: "warn",
                    summary: "Não tem uma apresentação disponível para essa proposta.",
                    detail: "Tente novamente mais tarde ou entre em contato com um vendedor",
                    life: 3000,
                })}
        </>
    )
}
