import React, { useRef, useState, useEffect } from "react"
import App from "../../layout/App"
import api from "../../services/api"
import { Toast } from "primereact/toast"
import { InputText } from "primereact/inputtext"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Checkbox } from "primereact/checkbox"
import { Toolbar } from "primereact/toolbar"
import { Button } from "primereact/button"

interface SolicitationData {
    id: string
    item: string
    product_id: string
    product_description: string
    quantity: string
    price: string
    quantity_items: string
    quote_id: string
    approval_control: string
    order_closed_by_residue: string
    issue_date: string
}

export const Solicitation: React.FC = () => {
    const [solicitations, setSolicitations] = useState<SolicitationData[]>([])
    const [auxSolicitations, setaAuxSolicitations] = useState<SolicitationData[]>([])
    const [selectedSolicitation, setSelectedSolicitation] = useState<SolicitationData[]>([])

    const [acronymFilter] = useState<any>([])
    const [openSolicitation, setOpenSolicitation] = useState<boolean>(false)

    const [loadingList, setLoadingList] = useState<boolean>(true)

    let [globalFilter, setGlobalFilter] = useState<string>("")
    const toast: any = useRef(null)
    const token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    const allSolicitation = async() => {
        try {
            let header = {
                headers: {
                    route: "list_purchase_requests",
                },
            }

            await api.get("purchase_requests", header)
                .then((res) => {
                    setLoadingList(false)
                    setSolicitations(res.data)
                    setaAuxSolicitations(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const checkSearchFilter = (data: string, filter: string) => {
        let type = data
        let status = true
        let _solicitation: SolicitationData[] = []

        globalFilter = filter.toUpperCase()
        setGlobalFilter(globalFilter)

        if (type !== "") {
            let exists = acronymFilter.find((p: string) => {
                return p === type
            })

            !exists ? acronymFilter.push(type) : acronymFilter.splice(acronymFilter.indexOf(type), 1)
        }

        if (acronymFilter.length > 0 || globalFilter.length > 0) {
            auxSolicitations.forEach((p: SolicitationData) => {
                if (acronymFilter.length > 0) {
                    status = false
                    if (p.order_closed_by_residue === "" && p.approval_control === "L") {
                        status = true
                    }
                }

                if (status) {
                    if (globalFilter.length > 0 && globalFilter !== "") {
                        let words = globalFilter.split(" ")

                        words.forEach((word) => {
                            if (
                                !p.id.includes(word) &&
                                !p.product_description.includes(word) &&
                                !p.product_id.includes(word) &&
                                !p.quantity_items.includes(word) &&
                                !p.issue_date.includes(word)
                            ) {
                                status = false
                            }
                        })
                    }
                }

                if (status) {
                    _solicitation.push(p)
                }

                status = true
            })

            setSolicitations(_solicitation)
        } else {
            setSolicitations(auxSolicitations)
        }
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="p-d-flex p-flex-column p-flex-md-row">
                    <div className="p-mb-2 p-mr-6">
                        <Checkbox
                            inputId="c1"
                            value="SA"
                            onChange={(e: any) => {
                                checkSearchFilter(e.target.value, globalFilter)
                                setOpenSolicitation(!openSolicitation)
                            }}
                            checked={openSolicitation}
                        ></Checkbox>

                        <label htmlFor="L" className="p-checkbox-label">
                            &nbsp;&nbsp;Solicitações Abertas
                        </label>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const generateReportsToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="p-d-flex p-flex-column p-flex-md-row">
                    <div className="p-mb-2 p-mr-6">
                        <Button label="Pedido Comum" className="p-button-success" />
                    </div>

                    <div className="p-mb-2 p-mr-6">
                        <Button label="Pedido 2°U.M" className="p-button-warning" />
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Solicitações de Compra</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onChange={(e: any) => {
                        checkSearchFilter("", e.target.value)
                    }}
                    placeholder="Pesquisar..."
                />
            </span>
        </div>
    )

    useEffect(() => {
        allSolicitation()
    }, [])

    return (
        <App>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />

                        <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}></Toolbar>

                        <Toolbar className="p-mb-4 p-toolbar" left={generateReportsToolbarTemplate}></Toolbar>
                    </div>
                </div>

                <div className="p-col-12">
                    <div className="card">
                        <DataTable
                            rowGroupMode="rowspan"
                            sortMode="single"
                            sortField="id"
                            sortOrder={1}
                            value={solicitations}
                            selectionMode="radiobutton"
                            selection={selectedSolicitation}
                            onSelectionChange={(e) => setSelectedSolicitation(e.value)}
                            dataKey="id"
                            paginator
                            stripedRows
                            loading={loadingList}
                            rows={25}
                            rowsPerPageOptions={[25, 35, 45]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} Cotação"
                            emptyMessage="Não há Cotações"
                            header={header}
                        >
                            <Column
                                selectionMode="single"
                                headerStyle={{
                                    width: "3em",
                                }}
                                field="id"
                            ></Column>

                            <Column align="center" alignHeader="center" header="ID" field="id"></Column>
                            <Column align="center" alignHeader="center" header="Produto" field="product_id"></Column>
                            <Column align="center" alignHeader="center" header="Descrição" field="product_description"></Column>
                            <Column align="center" alignHeader="center" header="Qtd. Itens" field="quantity_items"></Column>
                            <Column align="center" alignHeader="center" header="Data Emissão" field="issue_date"></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </App>
    )
}
