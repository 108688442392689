import React, { useRef } from "react"

//Components
import { Notification } from "../components/notification/Notification"

//PrimeReact
import { Button } from "primereact/button"
import { Toast } from "primereact/toast"

const AppRightPanel = (props) => {
    const toast = useRef(null)

    const notificationIcons = (item) => {
        var data = {}

        switch (item) {
            case "success":
                data = { icon: "pi-check-circle", color: "#d4ffe6", fontColor: "#004a15", borderColor: "#004a15" }
                break

            case "error":
                data = { icon: "pi-times-circle", color: "#ffbfc5", fontColor: "#a10303", borderColor: "#a10303" }
                break

            case "warn":
                data = { icon: "pi-exclamation-circle", color: "#f6f7a3", fontColor: "#838500", borderColor: "#838500" }
                break

            case "info":
                data = { icon: "pi-info-circle", color: "#bae3ff", fontColor: "#116bfa", borderColor: "#116bfa" }
                break

            default:
                break
        }

        return data
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="layout-rightpanel" onClick={props.onRightPanelClick}>
                <div className="rightpanel-wrapper">
                    <div className="rightpanel-section weather-section">
                        <div className="weather" style={{ paddingBottom: "1px" }}>
                            <div className="p-grid">
                                <>
                                    <Button
                                        id="button"
                                        onClick={() => props.getNotifications()}
                                        icon="pi pi-refresh"
                                        className="p-button-success"
                                        label="Atualizar notificações"
                                        style={{ marginBottom: "1rem", marginRight: "1rem" }}
                                    />

                                    {props.notifications.length > 0 && (
                                        <>
                                            {props.notifications.map((notification, index) => (
                                                <div key={index}>
                                                    <Notification
                                                        className="notification-active-esquadros"
                                                        idNotification={notification.id}
                                                        url={notification.url}
                                                        colorIcons={notificationIcons(notification.type)}
                                                        title={notification.title}
                                                        description={notification.description}
                                                        created_at={notification.created_at}
                                                        updateNotification={props.getNotifications}
                                                    />
                                                </div>
                                            ))}
                                        </>
                                    )}

                                    {props.notifications.length <= 0 && (
                                        <>
                                            <div>
                                                <div className="center">
                                                    <h5>Não há novas notificações</h5>
                                                </div>
                                                <div className="centerIcon">
                                                    <i className="pi pi-inbox" style={{ fontSize: "2em" }}></i>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AppRightPanel
