import React, { useRef, useState, useEffect } from "react"
import App from "../../layout/App"
import api from "../../services/api"
import { useHotkeys } from "react-hotkeys-hook"

//PrimeReact
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import { TabPanel, TabView } from "primereact/tabview"
import { Toast } from "primereact/toast"

//Components
import { CrmRecords } from "../../components/crm/CrmRecords"

//Interface
import { FilterMatchMode, FilterOperator } from "primereact/api"
import { MachineApresentationInterface } from "../../interfaces/MachineApresentationInterface"
import { MachineLayoutInterface } from "../../interfaces/MachineLayoutInterface"
import { PriceTableInterface } from "../../interfaces/PriceTableInterface"
import { TableProductInterfaces } from "../../interfaces/TableProductInterfaces"

export const ProductsCRM = () => {
    let token: string | null = localStorage.getItem("@esquadros:token")
    let toast = useRef<Toast>(null)
    api.defaults.headers.authorization = `Bearer ${token}`
    let dt: any = useRef(null)

    let [apresentations, setApresentations] = useState<MachineApresentationInterface[]>([])
    let [layouts, setLayouts] = useState<MachineLayoutInterface[]>([])
    let [machine, setMachine] = useState<TableProductInterfaces>({} as TableProductInterfaces)
    let [machines, setMachines] = useState<TableProductInterfaces[]>([])

    //Opcionals
    let [optionals, setOptionals] = useState<TableProductInterfaces[]>([])
    let [optionalsforMachine, setOptionalsforMachine] = useState<TableProductInterfaces[]>([])

    //Dialog
    let [dialogLinkLayoutOnEquipment, setDialogLinkLayoutOnEquipment] = useState(false)
    let [dialogLinkPresentationOnEquipment, setPresentationLinkLayoutOnEquipment] = useState(false)
    let [hotKeysDialog, setHotKeysDialog] = useState(false)
    let [modalOptionals, setModalOptionals] = useState(false)
    let [modalOptionalsMachine, setModalOptionalsMachine] = useState(false)

    //TabsPannel
    let [pricesTable, setPricesTable] = useState<PriceTableInterface[]>([])
    let [tableSelected, setTableSelected] = useState<PriceTableInterface>({} as PriceTableInterface)

    //Filter
    let [globalFilterValue, setGlobalFilterValue] = useState("")
    let [optionalFilter, setOptionalFilter] = useState("")

    let [loadingSyncTotvs, setLoadingSyncTotvs] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0)

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        description: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    })

    const [filtersOptionals, setFiltersOptionals] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        description: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    })

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value
        let _filters = { ...filters }
        _filters["global"].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    const onGlobalFilterOptionalsChange = (e: any) => {
        const value = e.target.value
        let _filters = { ...filtersOptionals }
        _filters["global"].value = value

        setFiltersOptionals(_filters)
        setOptionalFilter(value)
    }

    let [globalFilterTableApresentations, setGlobalFilterTableApresentations] = useState<string | null>(null)

    //Loading
    let [loadingTableApresentations, setLoadingTableApresentations] = useState<boolean>(true)

    //HotKeys
    useHotkeys("shift+i", () => setHotKeysDialog(true))

    let isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    //Get method
    let allApresentations = (rowData: TableProductInterfaces) => {
        try {
            let header = {
                headers: {
                    route: "list_machines_apresentations",
                },
            }

            api.get(`machines_apresentations/product/all?product_code=${rowData.id}&product_table=${tableSelected.id}`, header)
                .then((res) => {
                    setLoadingTableApresentations(false)
                    setApresentations(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    let allLayouts = async (rowData: any) => {
        try {
            let header = {
                headers: {
                    route: "show_all_machines_layouts_product",
                },
            }

            await api
                .get(`machines_layouts/product/all?product_code=${rowData.id}`, header)
                .then((res) => {
                    setLoadingTableApresentations(false)
                    setLayouts(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    let allMachines = async (item: any) => {
        let header = {
            headers: {
                route: "list_machines",
            },
        }

        await api.get(`machines/${item}`, header).then((res) => {
            setMachines(res.data)
        })
        let headerOptionals = {
            headers: {
                route: "list_optionals",
            },
        }

        await api.get(`optionals/${item}`, headerOptionals).then((res) => {
            setOptionals(res.data)
        })
    }

    let allOptionalMachine = (item: any) => {
        allMachinesOptionals(item)
        setModalOptionalsMachine(true)
    }

    let allMachinesOptionals = async (item: any) => {
        let headerOptionals = {
            headers: {
                route: "list_optionals",
            },
        }

        await api.get(`optionals/?machine_id=${item.id}&price_table=${item.price_table}`, headerOptionals).then((res) => {
            setOptionalsforMachine(res.data)
        })
    }

    const syncProtheus = () => {
        setLoadingSyncTotvs(true)
        toast.current?.show({
            severity: "info",
            summary: "Sincronizando...",
            detail: "Aguarde um momento!",
            life: 3000,
        })
        try {
            let header = {
                headers: {
                    route: "sync_proteus",
                },
            }

            api.post(`machines/synchronize`, header)
                .then((res) => {
                    if (res.status === 201) {
                        allMachines(pricesTable[0].id)
                        allLayouts(pricesTable[0].id)
                        setTableSelected(pricesTable[0])
                        
                        setActiveIndex(parseInt(pricesTable[0].id))
                        setLoadingSyncTotvs(false)
                        toast.current?.show({
                            severity: "success",
                            summary: "Sincronizado com sucesso!",
                            detail: "A tabela de produtos foi atualizada!",
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao sincronizar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    //Dialog
    let hideDialog = () => {
        setModalOptionals(false)
        setModalOptionalsMachine(false)
        setPresentationLinkLayoutOnEquipment(false)
        setDialogLinkLayoutOnEquipment(false)
        setHotKeysDialog(false)
    }

    //Requisitions
    let updateStatusApresentation = (machineApresentation: MachineApresentationInterface) => {
        apresentations.forEach((apresentation: MachineApresentationInterface, indexApresentation: number) => {
            if (apresentation.id === machineApresentation.id) {
                if (!apresentations[indexApresentation].status) {
                    let header = {
                        headers: {
                            route: "create_product_machine_apresentation",
                        },
                    }

                    let auxUpdateApresentetion = {
                        machine_apresentation_id: machineApresentation.id,
                        product_code: machine.id,
                        product_table: tableSelected.id,
                    }

                    api.post("machines_apresentations/product", auxUpdateApresentetion, header)
                        .then((res) => {
                            apresentations[indexApresentation].product_machine_apresentation_id = res.data.id
                        })
                        .catch((error) => {
                            toast.current?.show({
                                severity: "error",
                                summary: `Erro ao listar!`,
                                detail: error.response.data.message,
                                life: 3000,
                            })
                        })
                } else {
                    api.delete("machines_apresentations/product", {
                        headers: {
                            route: "delete_product_machine_apresentation",
                        },
                        data: {
                            id: machineApresentation.product_machine_apresentation_id,
                        },
                    }).catch((error) => {
                        toast.current?.show({
                            severity: "error",
                            summary: `Erro ao listar!`,
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
                }

                apresentations[indexApresentation].status = !apresentation.status
                setApresentations([...apresentations])
            }
        })
    }

    let updateStatusLayout = (machineLayout: MachineLayoutInterface) => {
        layouts.forEach((layout: MachineLayoutInterface, indexLayout: number) => {
            if (layout.id === machineLayout.id) {
                if (!layouts[indexLayout].status) {
                    let header = {
                        headers: {
                            route: "create_product_machine_layout",
                        },
                    }

                    let auxUpdateApresentetion = {
                        machine_layout_id: machineLayout.id,
                        product_code: machine.id,
                    }

                    api.post("machines_layouts/product", auxUpdateApresentetion, header).then((res) => {
                        layouts[indexLayout].product_machine_layout_id = res.data.id
                    })
                } else {
                    api.delete("machines_layouts/product", {
                        headers: {
                            route: "delete_product_machine_layout",
                        },
                        data: {
                            id: machineLayout.product_machine_layout_id,
                        },
                    })
                }

                layouts[indexLayout].status = !layout.status
                setLayouts([...layouts])
            }
        })
    }

    //DataTableFormater field
    let priceTableFuntion = () => {
        let header = {
            headers: {
                route: `list_price_tables`,
            },
        }

        api.get("price_tables", header).then((res) => {
            setPricesTable(res.data)
        })
    }

    let tabHeaderITemplate = (item: any) => {
        return (
            <>
                &nbsp; &nbsp;
                <Button
                    className={item.id == activeIndex ? '' : 'p-button-text'}
                    label={`Tabela ${item.id}`}
                    onClick={() => {
                        allMachines(item.id)
                        setTableSelected(item)
                        setActiveIndex(parseInt(item.id))
                    }}
                />
            </>
        )
    }

    let priceFunction = (rowData: any) => {
        let price = ""
        if (rowData.price !== null) {
            price = "R$ " + rowData.price.toLocaleString("pt-BR")
        } else {
            price = "-"
        }
        return price
    }

    let deadline = (rowData: any) => {
        let date = ""
        if (rowData.price !== null) {
            date = rowData.deadline + " DIAS"
        } else {
            date = "-"
        }
        return date
    }

    let priceInternet = (rowData: any) => {
        let price = ""
        if (rowData.net_price !== null) {
            price = "R$ " + rowData.net_price.toLocaleString("pt-BR") || ""
        } else {
            price = "-"
        }
        return price
    }

    let NCMConcatenate = (rowData: any) => {
        let ncm = ""
        if (rowData.ncm !== "") {
            ncm = rowData.ncm
        } else {
            ncm = "-"
        }
        return ncm
    }

    //Buttons
    let dataTableButtons = (rowData: any) => {
        return (
            <div className="actions">
                <div className="p-d-flex">
                    <div className="p-mb-2 p-mr-2">
                        <Button
                            icon="pi pi-shopping-bag"
                            tooltip="Opcionais"
                            tooltipOptions={{ position: "top" }}
                            className="p-button-rounded p-button"
                            onClick={() => allOptionalMachine(rowData)}
                        />
                    </div>

                    <div className="p-mb-2 p-mr-2">
                        <Button
                            icon="pi pi-image"
                            tooltip="Vincular Apresentação"
                            tooltipOptions={{ position: "top" }}
                            className="p-button-danger p-button-rounded p-button"
                            onClick={() => {
                                setPresentationLinkLayoutOnEquipment(true)
                                setMachine(rowData)
                                allApresentations(rowData)
                            }}
                        />
                    </div>
                    <div className="p-mb-2 p-mr-2">
                        <Button
                            icon="pi pi-list"
                            tooltip="Vincular Layouts"
                            tooltipOptions={{ position: "top" }}
                            className="p-button-warning p-button-rounded p-button"
                            onClick={() => {
                                setDialogLinkLayoutOnEquipment(true)
                                setMachine(rowData)
                                allLayouts(rowData)
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    let presentationsSendButton = (machineApresentation: MachineApresentationInterface) => {
        return (
            <>
                <InputSwitch checked={machineApresentation.status} onChange={() => updateStatusApresentation(machineApresentation)} />
            </>
        )
    }

    let layoutSendButton = (machineLayout: MachineLayoutInterface) => {
        return (
            <>
                <InputSwitch checked={machineLayout.status} onChange={() => updateStatusLayout(machineLayout)} />
            </>
        )
    }

    //Headers
    let header = (
        <div className="table-header">
            <h4>{`Produtos da tabela ${activeIndex}`}</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Pesquisar..." />
                <Button label="Opcionais" onClick={() => setModalOptionals(true)} className="p-button-text" />
            </span>
        </div>
    )

    let headerOptionals = (
        <div className="table-header">
            <div className="p-d-flex p-jc-between">
                <h5 className="p-m-0"> </h5>
                <div>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />

                        <InputText value={optionalFilter} onChange={onGlobalFilterOptionalsChange} placeholder="Pesquisar..." />
                    </span>
                </div>
            </div>
        </div>
    )

    let headerTableApresentations = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e: any) => setGlobalFilterTableApresentations(e.target.value)} placeholder="Pesquisar..." />
            </span>
        </div>
    )

    useEffect(() => {
        priceTableFuntion()
    }, [])

    useEffect(() => {
        if (!isEmptyObj(pricesTable)) {
            allMachines(pricesTable[0].id)
            allLayouts(pricesTable[0].id)
            setTableSelected(pricesTable[0])
            setActiveIndex(parseInt(pricesTable[0].id))
        }
    }, [pricesTable])

    return (
        <App>
            <CrmRecords />

            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />

                        {/* Tabs from Table */}
                        <TabView>
                            {pricesTable.map((priceTable: PriceTableInterface, indexPriceTable: number) => (
                                <TabPanel key={indexPriceTable} headerTemplate={() => tabHeaderITemplate(priceTable)} style={{ margin: "0.5rem 0" }}>
                                    {!isEmptyObj(machines) && (
                                        <>
                                            <br />
                                            <DataTable
                                                ref={dt}
                                                value={machines}
                                                stripedRows
                                                selectionMode="checkbox"
                                                dataKey="id"
                                                rows={10}
                                                globalFilterFields={["description", "id", "price", "net_price", "ncm", "deadline"]}
                                                rowsPerPageOptions={[10, 15, 20]}
                                                // className="datatable-responsive"
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} maquinas"
                                                paginator
                                                filters={filters}
                                                header={header}
                                                emptyMessage="Não há opcionais cadastrados"
                                                responsiveLayout="scroll"
                                            >
                                                <Column field="id" header="Código" sortable align="center" alignHeader="center" />
                                                <Column field="description" header="Descrição" sortable align="center" alignHeader="center" />

                                                <Column header="Preço" sortField="price" body={priceFunction} sortable align="center" alignHeader="center" />
                                                <Column
                                                    header="Preço líquido"
                                                    body={priceInternet}
                                                    sortField="net_price"
                                                    sortable
                                                    align="center"
                                                    alignHeader="center"
                                                />
                                                <Column header="NCM" body={NCMConcatenate} sortField="ncm" sortable align="center" alignHeader="center" />
                                                <Column
                                                    header="Prazo de Entrega"
                                                    body={deadline}
                                                    sortField="deadline"
                                                    sortable
                                                    align="center"
                                                    alignHeader="center"
                                                />
                                                <Column header="" align="center" alignHeader="center" body={dataTableButtons}></Column>
                                            </DataTable>
                                        </>
                                    )}
                                </TabPanel>
                            ))}
                        </TabView>

                        <Button
                            loading={loadingSyncTotvs}
                            label="Sincronizar com o TOTVS"
                            icon="pi pi-sync"
                            className="p-button-outlined p-button-info"
                            style={{
                                position: "absolute",
                                top: "110px",
                                right: "60px",
                            }}
                            onClick={() => {
                                syncProtheus()
                            }}
                        />

                        {/* Products DataTable */}
                        <Dialog
                            visible={modalOptionals}
                            style={{ maxWidth: "1200px" }}
                            header={"Opcionais"}
                            modal
                            className="p-fluid"
                            onHide={hideDialog}
                            draggable={false}
                        >
                            <DataTable
                                ref={dt}
                                value={optionals}
                                stripedRows
                                selectionMode="checkbox"
                                dataKey="id"
                                rows={10}
                                filters={filters}
                                globalFilterFields={["description", "id", "price", "net_price", "ncm", "deadline"]}
                                rowsPerPageOptions={[10, 15, 20]}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} opcionais"
                                paginator
                                header={headerOptionals}
                                emptyMessage="Não há opcionais cadastrados"
                            >
                                <Column
                                    field="id"
                                    header="Código"
                                    sortable
                                    bodyStyle={{
                                        textAlign: "center",
                                    }}
                                    headerStyle={{
                                        textAlign: "center",
                                        width: "em",
                                    }}
                                />
                                <Column
                                    field="description"
                                    header="Descrição"
                                    sortable
                                    bodyStyle={{
                                        textAlign: "center",
                                    }}
                                    headerStyle={{
                                        textAlign: "center",
                                        width: "em",
                                    }}
                                />

                                <Column
                                    header="Preço"
                                    sortField="price"
                                    body={priceFunction}
                                    sortable
                                    bodyStyle={{
                                        textAlign: "center",
                                        width: "em",
                                    }}
                                    headerStyle={{
                                        textAlign: "center",
                                        width: "em",
                                    }}
                                />
                                <Column
                                    header="Preço líquido"
                                    body={priceInternet}
                                    sortField="net_price"
                                    sortable
                                    bodyStyle={{
                                        textAlign: "center",
                                        width: "em",
                                    }}
                                    headerStyle={{
                                        textAlign: "center",
                                        width: "em",
                                    }}
                                />
                                <Column
                                    header="NCM"
                                    body={NCMConcatenate}
                                    sortField="ncm"
                                    sortable
                                    bodyStyle={{
                                        textAlign: "center",
                                        width: "em",
                                    }}
                                    headerStyle={{
                                        textAlign: "center",
                                        width: "em",
                                    }}
                                />
                                <Column
                                    header="Preço Tabela"
                                    field="price_table"
                                    sortable
                                    bodyStyle={{
                                        textAlign: "center",
                                        width: "em",
                                    }}
                                    headerStyle={{
                                        textAlign: "center",
                                        width: "em",
                                    }}
                                />
                                <Column
                                    header="Prazo de Entrega"
                                    body={deadline}
                                    sortField="deadline"
                                    sortable
                                    bodyStyle={{
                                        textAlign: "center",
                                    }}
                                    headerStyle={{
                                        textAlign: "center",
                                        width: "em",
                                    }}
                                />
                            </DataTable>
                        </Dialog>

                        {/* Optionals DataTable */}
                        <Dialog
                            visible={modalOptionalsMachine}
                            style={{ maxWidth: "1200px" }}
                            header={"Opcionais"}
                            modal
                            className="p-fluid"
                            draggable={false}
                            onHide={hideDialog}
                        >
                            <DataTable
                                ref={dt}
                                value={optionalsforMachine}
                                stripedRows
                                selectionMode="checkbox"
                                dataKey="id"
                                rows={10}
                                rowsPerPageOptions={[10, 15, 20]}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} opcionais"
                                paginator
                                filters={filtersOptionals}
                                globalFilterFields={["description", "id", "price", "net_price", "ncm", "deadline"]}
                                header={headerOptionals}
                                emptyMessage="Não há opcionais cadastrados"
                            >
                                <Column
                                    field="id"
                                    header="Código"
                                    sortable
                                    bodyStyle={{
                                        textAlign: "center",
                                    }}
                                    headerStyle={{
                                        textAlign: "center",
                                        width: "em",
                                    }}
                                />
                                <Column
                                    field="description"
                                    header="Descrição"
                                    sortable
                                    bodyStyle={{
                                        textAlign: "center",
                                    }}
                                    headerStyle={{
                                        textAlign: "center",
                                        width: "em",
                                    }}
                                />

                                <Column
                                    header="Preço"
                                    sortField="price"
                                    body={priceFunction}
                                    sortable
                                    bodyStyle={{
                                        textAlign: "center",
                                        width: "em",
                                    }}
                                    headerStyle={{
                                        textAlign: "center",
                                        width: "em",
                                    }}
                                />
                                <Column
                                    header="Preço líquido"
                                    body={priceInternet}
                                    sortField="net_price"
                                    sortable
                                    bodyStyle={{
                                        textAlign: "center",
                                        width: "em",
                                    }}
                                    headerStyle={{
                                        textAlign: "center",
                                        width: "em",
                                    }}
                                />
                                <Column
                                    header="NCM"
                                    body={NCMConcatenate}
                                    sortField="ncm"
                                    sortable
                                    bodyStyle={{
                                        textAlign: "center",
                                        width: "em",
                                    }}
                                    headerStyle={{
                                        textAlign: "center",
                                        width: "em",
                                    }}
                                />

                                <Column
                                    header="Prazo de Entrega"
                                    body={deadline}
                                    sortField="deadline"
                                    sortable
                                    bodyStyle={{
                                        textAlign: "center",
                                    }}
                                    headerStyle={{
                                        textAlign: "center",
                                        width: "em",
                                    }}
                                />
                            </DataTable>
                        </Dialog>

                        {/* Presentation Vinculation */}
                        <Dialog
                            visible={dialogLinkPresentationOnEquipment}
                            style={{ minWidth: "60vw" }}
                            modal
                            className="p-fluid"
                            draggable={false}
                            onHide={hideDialog}
                            header={`Vincular apresentação ao equipamento: ${machine.description}`}
                        >
                            <DataTable
                                ref={dt}
                                value={apresentations}
                                dataKey="id"
                                stripedRows
                                paginator
                                loading={loadingTableApresentations}
                                rows={4}
                                className="datatable-responsive"
                                header={headerTableApresentations}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} de {last} Total {totalRecords} apresentações"
                                globalFilter={globalFilterTableApresentations}
                                emptyMessage="Não há apresentação cadastrados"
                            >
                                <Column align="center" alignHeader="center" field="name" header="Nome" sortable></Column>

                                <Column align="center" alignHeader="center" field="description" header="Descrição" sortable></Column>

                                <Column
                                    align="center"
                                    alignHeader="center"
                                    header="Vincular / Não vincular"
                                    body={presentationsSendButton}
                                    sortable
                                    sortField="userFromGroup"
                                ></Column>
                            </DataTable>
                        </Dialog>

                        {/* Layout Vinculation */}
                        <Dialog
                            visible={dialogLinkLayoutOnEquipment}
                            style={{ minWidth: "60vw" }}
                            modal
                            className="p-fluid"
                            draggable={false}
                            onHide={hideDialog}
                            header={`Vincular layout ao equipamento: ${machine.description}`}
                        >
                            <DataTable
                                ref={dt}
                                value={layouts}
                                dataKey="id"
                                stripedRows
                                paginator
                                loading={loadingTableApresentations}
                                rows={4}
                                className="datatable-responsive"
                                header={headerTableApresentations}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} de {last} Total {totalRecords} layouts"
                                globalFilter={globalFilterTableApresentations}
                                emptyMessage="Não há apresentação cadastrados"
                            >
                                <Column align="center" alignHeader="center" field="name" header="Nome" sortable></Column>

                                <Column align="center" alignHeader="center" field="description" header="Descrição" sortable></Column>

                                <Column
                                    align="center"
                                    alignHeader="center"
                                    header="Vincular / Não vincular"
                                    body={layoutSendButton}
                                    sortable
                                    sortField="userFromGroup"
                                ></Column>
                            </DataTable>
                        </Dialog>

                        {/* HotKeys Dialog */}
                        <Dialog
                            visible={hotKeysDialog}
                            style={{ width: "450px" }}
                            modal
                            className="p-fluid"
                            onHide={hideDialog}
                            header={`Atalhos - Tela de Produtos`}
                            draggable={false}
                        >
                            <p>
                                <strong>SHIFT + O</strong> - Criar uma nova oportunidade
                            </p>
                            <p>
                                <strong>SHIFT + C</strong> - Criar um novo contato
                            </p>
                            <p>
                                <strong>SHIFT + E</strong> - Criar uma nova empresa
                            </p>
                            <p>
                                <strong>SHIFT + T</strong> - Criar uma nova tarefa
                            </p>
                        </Dialog>
                    </div>
                </div>
            </div>
        </App>
    )
}
