import { Avatar } from "primereact/avatar"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { Rating } from "primereact/rating"
import { Toast as PrimeToast, Toast } from "primereact/toast"
import { useRef, useState } from "react"
import Moment from "react-moment"
import { OpportunityInterface } from "../../../interfaces/OpportunityInterface"
import { ValueChangeRating } from "../../../interfaces/ValueChangeRatingInterface"
import DialogRatingClearOpportunities from "./DialogRating"

interface PropsInterface {
    auxOpportunities: OpportunityInterface[]
    opportunities: OpportunityInterface[]
    opportunity: OpportunityInterface
    setOpportunity: React.Dispatch<React.SetStateAction<OpportunityInterface>>
    setOpportunities: React.Dispatch<React.SetStateAction<OpportunityInterface[]>>
    loadingList: boolean
}

interface ToastRef {
    current: PrimeToast | null
}

interface FunnelColors {
    [key: string]: string
    default: string
}

const ClearOpportunitiesDataTable = ({ auxOpportunities, loadingList, opportunities, opportunity, setOpportunity, setOpportunities }: PropsInterface) => {
    const dt = useRef<any>(null)
    let [displayDialogConfirmChangeRating, setDisplayDialogConfirmChangeRating] = useState(false)
    let [newValueRating, setNewValueRating] = useState<{}>({} as ValueChangeRating)

    const toast = useRef<ToastRef>(null)

    const imageBodyTemplate = (rowData: OpportunityInterface) => (
        <div className="p-d-flex">
            <Avatar
                icon={rowData.user.avatar_url === null ? "pi pi-user" : ""}
                image={rowData.user.avatar_url}
                className="p-mr-2"
                size="normal"
                shape="circle"
            />
            <div className="p-text-nowrap p-text-truncate" style={{ width: "8rem" }}>
                {rowData.user.name}
            </div>
        </div>
    )

    const timelineFunnelListing = (rowData: OpportunityInterface) => {
        const funnelStage = rowData.funnelStage
        const funnelColors: FunnelColors = {
            "Primeiro Contato": "#E3455E",
            "Atendimento / Proposta": "#FDAB3F",
            "Engenharia de Aplicação": "#FFC900",
            "Follow-UP": "#569BFA",
            "Formalização de Pedido": "#01C877",
            default: "#03A9F4",
        }
        const chipColor = funnelStage.name && funnelColors[funnelStage.name] ? funnelColors[funnelStage.name] : funnelColors.default

        const chipStyle = {
            backgroundColor: chipColor,
            marginRight: "8px",
            color: "#fff",
            fontSize: "0.9rem",
            fontWeight: "600",
            padding: "0.5rem",
            borderRadius: "0.5rem",
        }

        return (
            <div className="funnelOnTable">
                <span style={chipStyle}>{funnelStage.name}</span>
            </div>
        )
    }

    const replaceStatus = (status: string) => {
        const statusMapping: { [key: string]: string } = {
            open: "ABERTA",
            gain: "GANHA",
            loss: "PERDIDA",
            stopped: "PAUSADA",
            deleted: "EXCLUÍDA",
            disqualified: "DESQUALIFICADA",
        }

        return statusMapping[status] || status
    }

    const colorStatusOpportunity = (status: string) => {
        const statusColors: { [key: string]: string } = {
            open: "#03A9F4",
            gain: "#689F38",
            loss: "#D32F2F",
            stopped: "#FBC02D",
            deleted: "#607D8B",
            disqualified: "#FF6400",
        }

        return statusColors[status] || ""
    }

    const nameOpportunity = (rowData: OpportunityInterface) => {
        const linkId = `/crm/leads/${rowData.id}`
        const isNew = rowData.new || rowData.new_on_funnel

        return (
            <div className="p-d-flex">
                <div className="p-d-flex p-as-center">
                    <Avatar
                        icon={rowData.company.picture_url === null ? "pi pi-user" : ""}
                        image={rowData.company.picture_url}
                        className="p-mr-2"
                        size="large"
                        shape="circle"
                    />
                </div>
                &nbsp;&nbsp;
                <div>
                    {isNew && (
                        <div className="p-d-flex p-jc-center" style={{ color: "#689F38" }}>
                            <div style={{ fontSize: "0.7rem", fontWeight: "600" }}>NOVA OPORTUNIDADE!&nbsp;</div>
                            <i className="pi pi-star-fill" style={{ fontSize: "0.7rem" }}></i>
                        </div>
                    )}
                    <div className="numberCompany p-d-flex p-text-nowrap p-text-truncate">
                        <a href={linkId} target="_blank" rel="noreferrer">
                            <p className="p-mb-0" style={{ color: colorStatusOpportunity(rowData.status) }}>
                                <strong>{("000000" + rowData.number).slice(-6)} - </strong>
                            </p>
                        </a>
                        &nbsp;
                        <a href={linkId} target="_blank" rel="noreferrer">
                            <p
                                className="p-text-nowrap p-text-truncate"
                                style={{ fontWeight: "600", fontSize: "1.1rem", color: "rgba(41, 50, 65, 0.8)", maxWidth: "18rem" }}
                            >
                                {rowData.name}
                            </p>
                        </a>
                    </div>
                    <a href={linkId}>
                        <div className="nameCompany p-text-nowrap p-text-truncate" style={{ maxWidth: "18rem" }}>
                            {rowData.company.name}
                        </div>
                    </a>
                </div>
            </div>
        )
    }

    const cnpjOpportunityColumns = (rowData: OpportunityInterface) => <div>{rowData.company.cnpj !== null ? rowData.company.cnpj : "-"}</div>

    const statusOpportunityColumns = (rowData: OpportunityInterface) => (
        <div style={{ color: colorStatusOpportunity(rowData.status) }}>{replaceStatus(rowData.status)}</div>
    )

    const createdAtFormatter = (rowData: OpportunityInterface) =>
        rowData.daysWithoutInteraction !== null && <Moment format="DD/MM/YYYY">{rowData.created_at}</Moment>

    const replaceFunnelLevel = (rowData: OpportunityInterface) => {
        const funnelLevels: { [key: string]: string } = {
            bottom: "Fundo",
            middle: "Meio",
            top: "Topo",
        }

        return funnelLevels[rowData.funnel_level] || "-"
    }

    const rating = (rowData: OpportunityInterface) => {
        const findOpportunityIndex = opportunities.findIndex((opportunity) => opportunity.id === rowData.id)
        const findOpportunity = findOpportunityIndex !== -1 ? opportunities[findOpportunityIndex] : null

        const handleRatingChange = (e: any) => {
            if (findOpportunity && findOpportunity.qualification !== e.value) {
                const newQualification = e.value === null ? 0 : e.value
                const newValueRating = { value: newQualification, id: rowData.id }
                setNewValueRating(newValueRating)
                setDisplayDialogConfirmChangeRating(true)
                setOpportunity(rowData)
            }
        }

        return (
            <div className="centralizeFromDesktopRating">
                <Rating
                    value={findOpportunity ? findOpportunity.qualification : 0}
                    tooltip={replaceQualification(rowData.qualification)?.toUpperCase()}
                    tooltipOptions={{ position: "bottom" }}
                    onChange={handleRatingChange}
                />
            </div>
        )
    }

    const replaceQualification = (qualification: number | string) => {
        switch (qualification) {
            case 0:
                return "nenhuma"
            case 1:
                return "baixa"
            case 2:
                return "média baixa"
            case 3:
                return "média"
            case 4:
                return "média alta"
            case 5:
                return "alta"
            default:
                return ""
        }
    }

    return (
        <>
            <Toast />
            <DataTable
                ref={dt}
                value={auxOpportunities}
                dataKey="id"
                paginator
                stripedRows
                rows={10}
                loading={loadingList}
                size="small"
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} oportunidades"
                emptyMessage="Não há Oportunidades"
                style={{ width: "100%" }}
                responsiveLayout="scroll"
            >
                <Column align="center" alignHeader="center" body={imageBodyTemplate} />
                <Column alignHeader="center" sortField="name" sortable header="OPORTUNIDADES" body={nameOpportunity} />

                <Column align="center" alignHeader="center" header="CNPJ" sortField="company.cnpj" sortable body={cnpjOpportunityColumns} />

                <Column
                    align="center"
                    alignHeader="center"
                    style={{
                        width: "300px",
                    }}
                    header="ENDEREÇO"
                    sortField="company.address"
                    field="company.address"
                    sortable
                />

                <Column align="center" alignHeader="center" header="STATUS" sortField="status" sortable body={statusOpportunityColumns} />

                <Column align="center" alignHeader="center" header="CRIAÇÃO" sortField="created_at" sortable body={createdAtFormatter} />

                <Column align="center" alignHeader="center" body={rating} header="TEMPERATURA" sortField="qualification" sortable />

                {/* <Column align="center" alignHeader="center" header="ETAPA DO FUNIL" sortField="dateLastInteraction" sortable body={replaceFunnelLevel} /> */}

                <Column
                    align="center"
                    alignHeader="center"
                    field=""
                    body={timelineFunnelListing}
                    header="ETAPA DE ATENDIMENTO"
                    sortable
                    sortField="funnelStage.sequence"
                />
            </DataTable>

            <Dialog
                visible={displayDialogConfirmChangeRating}
                style={{}}
                draggable={false}
                onHide={() => setDisplayDialogConfirmChangeRating(false)}
                modal
                className="p-fluid"
                header={opportunity.name}
            >
                <DialogRatingClearOpportunities
                    newValueRating={newValueRating}
                    auxOpportunities={auxOpportunities}
                    opportunity={opportunity}
                    setDisplayDialogConfirmChangeRating={setDisplayDialogConfirmChangeRating}
                    setOpportunities={setOpportunities}
                />
            </Dialog>
        </>
    )
}

export default ClearOpportunitiesDataTable
