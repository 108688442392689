import React, { useRef, useState, useEffect } from "react"
import App from "../../layout/App"
import api from "../../services/api"
import Moment from "react-moment"

//PrimeReact components
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Toast } from "primereact/toast"
import { Toolbar } from "primereact/toolbar"
import { FilterMatchMode, FilterOperator } from "primereact/api"

//Components
import { CrmRecords } from "../../components/crm/CrmRecords"

//Interface
import { InterestInterface } from "../../interfaces/InterestInterface"
import ReactTooltip from "react-tooltip"
import { CompetitionsInterface } from "../../interfaces/CompetitionsInterface"
import { InputMask } from "primereact/inputmask"

export const Concurrent = () => {
    let toast = useRef<Toast>(null)
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    let [allCompetitions, setAllCompetitions] = useState<CompetitionsInterface[]>([])
    let [competition, setCompetition] = useState<CompetitionsInterface>({} as CompetitionsInterface)
    let [competitionEditable, setCompetitionEditable] = useState<any>({ name: "", cnpj: "", site: "" })

    let [desabledCompetitorEditButton, setDesableCompetitorEditButton] = useState(true)
    let [globalFilterValue, setGlobalFilterValue] = useState("")

    //Loadings
    let [loading, setLoading] = useState<boolean>(false)

    let [interestEditDialog, setInterestEditDialog] = useState(false)
    let [interestsDialog, setInterestsDialog] = useState<boolean | undefined>(false)

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    })

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value
        let _filters = { ...filters }
        _filters["global"].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    //Requisitions
    const getAllCompetitions = async () => {
        try {
            let header = {
                headers: {
                    route: "list_competitor",
                },
            }
            await api
                .get("competitor", header)
                .then((res) => {
                    setAllCompetitions(() => res.data)
                    setLoading(false)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const updateCompetition = () => {
        setLoading(true)

        try {
            const newValue = {
                id: competition.id,
                name: competitionEditable.name,
                cnpj: competitionEditable.cnpj || "",
                site: competitionEditable.site || "",
            }

            let header = {
                headers: {
                    route: "update_competitor",
                },
            }

            api.put("competitor", newValue, header)

                .then((res) => {
                    if (res.status === 200) {
                        setLoading(false)
                        hideDialog()

                        const findIndexCompetitor = allCompetitions.findIndex((competition) => competition.id === res.data.id)
                        allCompetitions[findIndexCompetitor] = res.data
                        setAllCompetitions(allCompetitions)
                        setCompetitionEditable({ name: "", cnpj: "" })

                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na alteração",
                            detail: "Concorrente alterado com sucesso!",
                            life: 3000,
                        })
                    } else {
                        setLoading(false)
                        setCompetitionEditable({ name: "", cnpj: "" })

                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Editar",
                            detail: "Por favor, verificar os dados preenchidos!",
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoading(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao Editar",
                        detail: "Por favor, verificar os dados preenchidos!",
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const createCompetition = (event: any) => {
        setLoading(true)
        event.preventDefault()

        try {
            const { name, cnpj, site } = competitionEditable

            if (name === "") {
                setLoading(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao cadastrar",
                    detail: "Por favor, verificar os dados preenchidos!",
                    life: 3000,
                })
            } else {
                let header = {
                    headers: {
                        route: "create_competitor",
                    },
                }

                api.post(
                    "competitor",
                    {
                        name,
                        cnpj,
                        site
                    },
                    header
                )
                    .then((res) => {
                        if (res.status === 200) {
                            hideDialog()
                            setAllCompetitions([...allCompetitions, res.data])
                            setLoading(false)
                            setCompetitionEditable({ name: "", cnpj: "" })

                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso",
                                detail: "Cadastro de concorrente criado",
                                life: 3000,
                            })
                        } else {
                            setLoading(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao cadastar!",
                                detail: "Por favor, verificar os dados preenchidos! 1",
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoading(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao cadastar!",
                            detail: error.response.data.message || "Por favor, verificar os dados preenchidos! 2",
                            life: 3000,
                        })
                    })
            }
        } catch (error) {
            console.log(error)
        }
    }

    //Headers
    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Concorrentes </h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Pesquisar..." />
            </span>
        </div>
    )

    const dataTableButtons = (rowData: CompetitionsInterface) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-user-edit"
                    data-tip
                    data-for="edit-competition"
                    className="p-button-rounded p-button"
                    onClick={() => dialogEditInterest(rowData)}
                />
                <ReactTooltip className="toolTip" id="edit-competition" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Editar Interesse</p>
                </ReactTooltip>
            </div>
        )
    }

    //Formtater DataTable field
    const dateFormatCreate = (rowData: InterestInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.created_at}</Moment>
            </>
        )
    }

    const dateFormatUpdate = (rowData: InterestInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.updated_at}</Moment>
            </>
        )
    }

    //Dialogs
    const hideDialog = () => {
        setLoading(false)
        setInterestsDialog(false)
        setInterestEditDialog(false)
        setCompetitionEditable({ name: "", cnpj: "" })
    }

    const dialogEditInterest = (competition: CompetitionsInterface) => {
        setCompetition(competition)
        setCompetitionEditable({ name: competition.name, cnpj: competition.cnpj })
        setInterestEditDialog(true)
    }

    //Footers
    const createCompetitionDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" loading={loading} onClick={createCompetition} />
        </>
    )

    const editCompetitionFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-success p-button-text"
                loading={loading}
                onClick={updateCompetition}
                disabled={desabledCompetitorEditButton}
            />
        </>
    )

    useEffect(() => {
        getAllCompetitions()
    }, [])

    useEffect(() => {
        const nameComparison = competition.name === competitionEditable.name
        const cnpjComparison = competition.cnpj === competitionEditable.cnpj
        const siteComparison = competition.site === competitionEditable.site

        setDesableCompetitorEditButton(nameComparison && cnpjComparison && siteComparison)
    }, [competitionEditable])

    return (
        <App>
            <CrmRecords />

            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        
                        <Toolbar
                            className="p-mb-4 p-toolbar"
                            left={
                                <Button
                                    label="Novo"
                                    icon="pi pi-user-plus"
                                    className="p-button"
                                    onClick={() => {
                                        setInterestsDialog(true)
                                    }}
                                />
                            }
                        ></Toolbar>

                        {/* DataTable */}
                        <DataTable
                            value={allCompetitions}
                            loading={loading}
                            stripedRows
                            selectionMode="checkbox"
                            filters={filters}
                            globalFilterFields={["name"]}
                            dataKey="id"
                            rows={10}
                            rowsPerPageOptions={[10, 15, 20]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} interesses"
                            paginator
                            header={header}
                            emptyMessage="Não há Interesses cadastrados"
                            responsiveLayout="scroll"
                        >
                            <Column field="name" header="Nome" sortable align="center" alignHeader="center" />
                            <Column field="cnpj" header="CNPJ" sortable align="center" alignHeader="center" />
                            <Column field="site" header="Site" sortable align="center" alignHeader="center" />
                            <Column body={dateFormatCreate} header="Data de Criação" sortable sortField="created_at" align="center" alignHeader="center" />
                            <Column body={dateFormatUpdate} sortField="update_at" header="Data de Atualização" sortable align="center" alignHeader="center" />
                            <Column align="center" alignHeader="center" body={dataTableButtons}></Column>
                        </DataTable>

                        {/* Create Method */}
                        <Dialog
                            visible={interestsDialog}
                            style={{ width: "450px" }}
                            draggable={false}
                            header="Adicionar Empresa Concorrente"
                            modal
                            className="p-fluid"
                            footer={createCompetitionDialogFooter}
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <label htmlFor="name">Nome</label>
                                <InputText
                                    id="name"
                                    name="name"
                                    required
                                    style={{ minWidth: "20rem", marginBottom: "2rem" }}
                                    type="String"
                                    maxLength={45}
                                    className={"p-field p-text-capitalize "}
                                    value={competitionEditable.name || ""}
                                    onChange={(e: any) => {
                                        setCompetitionEditable((prevState: any) => ({
                                            ...prevState,
                                            name: e.target.value,
                                        }))
                                    }}
                                />

                                <label htmlFor="name">Site</label>
                                <InputText
                                    id="site"
                                    name="site"
                                    required
                                    style={{ minWidth: "20rem", marginBottom: "2rem" }}
                                    type="String"
                                    maxLength={45}
                                    className={"p-field"}
                                    value={competitionEditable.site || ""}
                                    onChange={(e: any) => {
                                        setCompetitionEditable((prevState: any) => ({
                                            ...prevState,
                                            site: e.target.value,
                                        }))
                                    }}
                                />

                                <label htmlFor="cnpj">CNPJ</label>
                                <InputMask
                                    mask="99.999.999/9999-99"
                                    style={{ minWidth: "20rem" }}
                                    id="cnpj"
                                    name="cnpj"
                                    maxLength={120}
                                    value={competitionEditable.cnpj || ""}
                                    onChange={(e: any) => {
                                        setCompetitionEditable((prevState: any) => ({
                                            ...prevState,
                                            cnpj: e.target.value,
                                        }))
                                    }}
                                />
                            </div>
                        </Dialog>

                        {/* Edit Method */}
                        <Dialog
                            visible={interestEditDialog}
                            style={{ width: "450px" }}
                            draggable={false}
                            header={`Editar Interesse:`}
                            modal
                            className="p-fluid"
                            footer={editCompetitionFooter}
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <label htmlFor="name">Nome</label>
                                <InputText
                                    style={{ minWidth: "20rem", marginBottom: "2rem" }}
                                    id="name"
                                    name="name"
                                    maxLength={120}
                                    onChange={(e: any) => {
                                        setCompetitionEditable((prevState: any) => ({
                                            ...prevState,
                                            name: e.target.value,
                                        }))
                                    }}
                                    value={competitionEditable.name || ""}
                                />

                                <label htmlFor="name">Site</label>
                                <InputText
                                    style={{ minWidth: "20rem", marginBottom: "2rem" }}
                                    id="site"
                                    name="site"
                                    maxLength={120}
                                    onChange={(e: any) => {
                                        setCompetitionEditable((prevState: any) => ({
                                            ...prevState,
                                            site: e.target.value,
                                        }))
                                    }}
                                    value={competitionEditable.site || ""}
                                />

                                <label htmlFor="name">CNPJ</label>
                                <InputMask
                                    mask="99.999.999/9999-99"
                                    style={{ minWidth: "20rem" }}
                                    id="name"
                                    name="name"
                                    maxLength={120}
                                    onChange={(e: any) => {
                                        setCompetitionEditable((prevState: any) => ({
                                            ...prevState,
                                            cnpj: e.target.value,
                                        }))
                                    }}
                                    value={competitionEditable.cnpj || ""}
                                />
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </App>
    )
}
