import React, { useRef, useState, useEffect } from "react"
import api from "../../services/api"
import App from "../../layout/App"
import Moment from "react-moment"
import { useHotkeys } from "react-hotkeys-hook"

//PrimeReact
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { ConfirmDialog } from "primereact/confirmdialog"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { FilterMatchMode, FilterOperator } from "primereact/api"
import { InputText } from "primereact/inputtext"
import { Toast } from "primereact/toast"
import { Toolbar } from "primereact/toolbar"

//Interfaces
import { FunnelInterface } from "../../interfaces/FunnelInterface"

//Components
import { CrmRecords } from "../../components/crm/CrmRecords"
import { HotKeys } from "../../components/crm/HotKeys"

export const Funnel = () => {
    const toast: any = useRef(null)
    const token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    let [funnels, setFunnels] = useState<FunnelInterface[]>([])
    let [funnel, setFunnel] = useState<FunnelInterface>({} as FunnelInterface)

    //Loadings
    let [loadingEdit, setLoadingEdit] = useState<boolean>(false)
    let [loadingList, setLoadingList] = useState<boolean>(true)
    let [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    //Dialog
    let [funilCreateDialog, setFunilDialog] = useState<boolean | undefined>(false)
    let [funilEditDialog, setFunilEditDialog] = useState(false)
    let [hotKeysDialog, setHotKeysDialog] = useState(false)
    let [modalOptionals, setModalOptionals] = useState(false)

    //Filters
    let [auxFunnelFiltered, setAuxFunnelFiltered] = useState<FunnelInterface[]>([])
    let [globalFilterValue, setGlobalFilterValue] = useState("")
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    })

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value
        let _filters = { ...filters }
        _filters["global"].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    //Hotkeys
    useHotkeys("shift+n", () => setFunilDialog(true))
    useHotkeys("shift+i", () => setHotKeysDialog(true))

    const allFunnles = async () => {
        try {
            let header = {
                headers: {
                    route: "list_funnels",
                },
            }

            await api
                .get("funnels", header)
                .then((res) => {
                    setLoadingList(false)
                    setFunnels(res.data)
                    setAuxFunnelFiltered(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    //Filters
    const findIndexById = (id: string) => {
        let index = -1
        for (let i = 0; i < auxFunnelFiltered.length; i++) {
            if (auxFunnelFiltered[i].id === id) {
                index = i
                break
            }
        }

        return index
    }

    //Buttons
    const buttonCreateFunnel = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-user-plus" className="p-button" onClick={openNew} />
            </React.Fragment>
        )
    }

    const ButtonsDataTable = (rowData: FunnelInterface) => {
        return (
            <div className="actions">
                <Button icon="pi pi-user-edit" className="p-button-rounded p-button" onClick={() => dialogEditFunnel(rowData)} />
                &nbsp;&nbsp;
                <Button icon="pi pi-sort" className="p-button-rounded p-button-warning" onClick={() => stagesFunnelSet(rowData)} />
            </div>
        )
    }

    const ButtonsStagesPositions = (rowData: FunnelInterface) => {
        return (
            <div className="actions">
                <Button
                    style={{ fontSize: "4rem" }}
                    icon="pi pi-sort-up"
                    className="p-button-lg p-button-rounded p-button-text"
                    onClick={() => onUpStage(rowData)}
                />
                &nbsp;&nbsp;
                <Button
                    style={{ fontSize: "4rem" }}
                    icon="pi pi-sort-down"
                    className="p-button-lg p-button-rounded p-button-text"
                    onClick={() => onDownStage(rowData)}
                />
                &nbsp;&nbsp;
            </div>
        )
    }

    //DataTable fields formater
    const dateFormatCreate = (rowData: FunnelInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.created_at}</Moment>
            </>
        )
    }

    const dateFormatUpdate = (rowData: FunnelInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.updated_at}</Moment>
            </>
        )
    }

    //Requisitions
    const openNew = () => {
        setFunilDialog(true)
    }

    const onSubitFunnel = async (event: any) => {
        setLoadingEdit(true)
        event.preventDefault()

        try {
            let header = {
                headers: {
                    route: "create_funnel",
                },
            }

            await api
                .post("funnels", "", header)
                .then((res) => {
                    if (res.status === 200) {
                        hideDialog()
                        setLoadingEdit(false)
                        setFunnels([...funnels, res.data])
                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "Funil Criado",
                            life: 3000,
                        })
                    } else {
                        setLoadingEdit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao cadastar!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingEdit(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao cadastar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const onChangeEditFunnel = (e: any, name: string) => {
        const val = (e.target && e.target.value) || ""
        let _funnel = { ...funnel }

        switch (name) {
            case "name":
                _funnel["name"] = val
                break

            default:
                break
        }

        setFunnel(_funnel)
    }

    const onSubmitEditFunnel = () => {
        setLoadingSubmit(true)
        try {
            const { name, id } = funnel

            if (name === null || name === "") {
                setLoadingSubmit(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao Editar",
                    detail: "Verifique os dados inseridos",
                    life: 3000,
                })
            } else {
                let header = {
                    headers: {
                        route: "update_funnel_name",
                    },
                }

                api.put("funnels/funnel_name", { id, name }, header)

                    .then((res) => {
                        if (res.status === 200) {
                            setLoadingSubmit(false)
                            let index = findIndexById(res.data.id)
                            let _funnels = [...auxFunnelFiltered]
                            _funnels[index] = res.data
                            funnels = _funnels
                            auxFunnelFiltered = _funnels
                            setFunnels(_funnels)
                            setAuxFunnelFiltered(_funnels)
                            hideDialog()

                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso na alteração",
                                detail: "Funil alterado com sucesso!",
                                life: 3000,
                            })
                        } else {
                            setLoadingSubmit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao Editar",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingSubmit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Editar",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const stagesFunnelSet = (item: FunnelInterface) => {
        setFunnel(item)
        setModalOptionals(true)
    }

    const onUpStage = (rowData: FunnelInterface) => {
        try {
            let auxUp = {
                id: rowData.id,
                type: "RIGHT",
            }

            let header = {
                headers: {
                    route: "update_stage_sequence",
                },
            }

            api.put("funnels/stage_sequence", auxUp, header)

                .then((res) => {
                    if (res.status === 200) {
                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na alteração",
                            detail: "Etapa ediatada com sucesso!",
                            life: 4000,
                        })
                        setFunnel({ ...funnel, [`stages`]: res.data })
                    } else {
                        setLoadingList(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Editar",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingSubmit(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao Editar",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const onDownStage = (rowData: FunnelInterface) => {
        try {
            let auxUp = {
                id: rowData.id,
                type: "LEFT",
            }

            let header = {
                headers: {
                    route: "update_stage_sequence",
                },
            }

            api.put("funnels/stage_sequence", auxUp, header)

                .then((res) => {
                    if (res.status === 200) {
                        setLoadingList(false)
                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na alteração",
                            detail: "Etapa ediatada com sucesso!",
                            life: 4000,
                        })
                        setFunnel({ ...funnel, [`stages`]: res.data })
                    } else {
                        setLoadingList(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Editar",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingSubmit(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao Editar",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    //Dialog
    const hideDialog = () => {
        setFunilDialog(false)
        setFunilEditDialog(false)
        setModalOptionals(false)
        setHotKeysDialog(false)
    }

    const dialogEditFunnel = (segmemt: FunnelInterface) => {
        setFunnel(segmemt)
        setFunilEditDialog(true)
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Funis </h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Pesquisar..." />
            </span>
        </div>
    )

    const headerStages = (
        <div className="table-header">
            <div className="p-d-flex p-jc-between">
                <h5 className="p-m-0"> </h5>
            </div>
        </div>
    )

    const textMenssageCreateFunnel = () => {
        return (
            <>
                <h5>
                    <strong>Deseja criar um novo Funil:</strong>
                </h5>
                <br />
            </>
        )
    }

    //Footer
    const funnelDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingEdit} onClick={onSubitFunnel} />
        </>
    )

    const funnelEditFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingSubmit} onClick={onSubmitEditFunnel} />
        </>
    )

    useEffect(() => {
        allFunnles()
    }, [])

    return (
        <App>
            <CrmRecords />

            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="p-mb-4 p-toolbar" left={buttonCreateFunnel}></Toolbar>

                        {/* DataTable */}
                        <DataTable
                            value={funnels}
                            loading={loadingList}
                            stripedRows
                            selectionMode="checkbox"
                            dataKey="id"
                            rows={10}
                            rowsPerPageOptions={[10, 15, 20]}
                            // className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} funis"
                            paginator
                            header={header}
                            filters={filters}
                            globalFilterFields={["name"]}
                            emptyMessage="Não há fúnils cadastrados"
                            responsiveLayout="scroll"
                        >
                            <Column field="name" header="Nome do Funil" sortable align="center" alignHeader="center" />
                            <Column body={dateFormatCreate} header="Data de Criação" sortable sortField="created_at" align="center" alignHeader="center" />
                            <Column body={dateFormatUpdate} sortField="update_at" header="Data de Atualização" sortable align="center" alignHeader="center" />
                            <Column align="center" alignHeader="center" body={ButtonsDataTable}></Column>
                        </DataTable>

                        {/* Create Method */}
                        <ConfirmDialog
                            visible={funilCreateDialog}
                            style={{ width: "23rem" }}
                            message={textMenssageCreateFunnel}
                            footer={funnelDialogFooter}
                            onHide={hideDialog}
                            draggable={false}
                        ></ConfirmDialog>

                        {/* Edit Method */}
                        <Dialog
                            visible={funilEditDialog}
                            style={{ width: "450px" }}
                            header={`Editar Funil:`}
                            modal
                            className="p-fluid"
                            footer={funnelEditFooter}
                            draggable={false}
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <label htmlFor="name">Nome</label>
                                <InputText
                                    style={{ minWidth: "20rem" }}
                                    id="name"
                                    required
                                    name="name"
                                    maxLength={120}
                                    onChange={(e: any) => onChangeEditFunnel(e, "name")}
                                    value={funnel.name || ""}
                                />
                            </div>
                        </Dialog>

                        {/* DataTable Stages */}
                        <Dialog
                            visible={modalOptionals}
                            style={{ maxWidth: "1200px" }}
                            header={`ETAPAS DO FUNIL - ${funnel.name}`}
                            modal
                            className="p-fluid"
                            draggable={false}
                            onHide={hideDialog}
                        >
                            <DataTable
                                value={funnel.stages}
                                loading={loadingList}
                                stripedRows
                                selectionMode="checkbox"
                                dataKey="id"
                                rows={10}
                                rowsPerPageOptions={[10, 15, 20]}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} etapas"
                                paginator
                                header={headerStages}
                                emptyMessage="Não há etapas cadastradas"
                            >
                                <Column field="sequence" header="Etapa" sortable align="center" alignHeader="center" />

                                <Column field="name" header="Nome da Etapa" sortable align="center" alignHeader="center" />

                                <Column body={dateFormatCreate} header="Data de Criação" sortable align="center" alignHeader="center" />
                                <Column body={dateFormatUpdate} header="Data de Atualização" sortable align="center" alignHeader="center" />
                                <Column
                                    headerStyle={{
                                        width: "12rem",
                                        textAlign: "center",
                                    }}
                                    bodyStyle={{ textAlign: "center" }}
                                    body={ButtonsStagesPositions}
                                ></Column>
                            </DataTable>
                        </Dialog>

                        {/* HotKeys Dialog */}
                        <Dialog
                            visible={hotKeysDialog}
                            style={{ width: "450px" }}
                            modal
                            className="p-fluid"
                            onHide={hideDialog}
                            header={`Atalhos - Tela de Funis`}
                            draggable={false}
                        >
                            <HotKeys create={"Criar um novo Funil"} />
                        </Dialog>
                    </div>
                </div>
            </div>
        </App>
    )
}
