import { Button } from "primereact/button"
import { ScrollPanel } from "primereact/scrollpanel"
import { TabPanel, TabView } from "primereact/tabview"
import { OpportunityInterface } from "../../../../interfaces/OpportunityInterface"

interface PropsInterface {
    opportunity: OpportunityInterface
    handleUpdateCompanyOpportunity: any
    setDisplayBasicForDialogUpdateCnpj: React.Dispatch<React.SetStateAction<boolean>>
}

export const CompanyLeads = ({ opportunity, setDisplayBasicForDialogUpdateCnpj, handleUpdateCompanyOpportunity }: PropsInterface) => {
    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    return (
        <>
            <div className="p-mb-2">
                <Button
                    label="ATUALIZAR EMPRESA"
                    className="p-d-flex p-as-end"
                    style={{
                        marginTop: "1rem",
                    }}
                    type="button"
                    onClick={() => {
                        setDisplayBasicForDialogUpdateCnpj(true)
                    }}
                />
            </div>
            
            <form onSubmit={handleUpdateCompanyOpportunity}>
                {!isEmptyObj(opportunity.company.cnpj) && (
                    <div className="p-col-12 p-md-12 p-lg-12">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6 p-lg-6">
                                <TabView>
                                    <TabPanel header="DADOS DA EMPRESA">
                                        <ScrollPanel style={{ width: "100%", height: "200px" }} className="custombar1">
                                            <div className="p-field">
                                                <h6
                                                    style={{
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    Razão Social: {opportunity.company.name}
                                                </h6>
                                                <h6>Nome Fantasia:{opportunity.company.fantasy_name}</h6>
                                                <h6>{opportunity.company.address}</h6>
                                                <h6>Cidade: {opportunity.company.city}</h6>
                                                <h6>
                                                    Capital Social:{" "}
                                                    {opportunity.company.share_capital
                                                        ? "R$ " + opportunity.company.share_capital.toLocaleString("pt-BR")
                                                        : "CAPITAL SOCIAL NÃO INFORMADO"}
                                                </h6>
                                                <h6>Cidade: {opportunity.company.city}</h6>
                                                <h6>Estado: {opportunity.company.state}</h6>
                                                <h6>País: {opportunity.company.country}</h6>
                                                <h6>CNPJ: {opportunity.company.cnpj}</h6>
                                                <h6>Situação Cadastral: {opportunity.company.cadastral_situation}</h6>
                                                <h6>Tipo: {opportunity.company.type}</h6>
                                            </div>
                                        </ScrollPanel>
                                    </TabPanel>
                                </TabView>
                            </div>
                            <div className="p-col-12 p-md-6 p-lg-6">
                                <TabView>
                                    <TabPanel header="INTERESSE">
                                        <ScrollPanel style={{ width: "100%", height: "200px" }} className="custombar1">
                                            <div className="p-field">
                                                {!isEmptyObj(opportunity.company.interests)
                                                    ? opportunity.company.interests.map((interest, indexInterest) => (
                                                          <h6 key={indexInterest}>{interest.name}</h6>
                                                      ))
                                                    : "NÃO HÁ DADOS"}
                                            </div>
                                        </ScrollPanel>
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                        
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6 p-lg-6">
                                <TabView>
                                    <TabPanel header="SÓCIOS">
                                        <ScrollPanel style={{ width: "100%", height: "200px" }} className="custombar1">
                                            <div className="p-field">
                                                {!isEmptyObj(opportunity.company.partners)
                                                    ? opportunity.company.partners.map((partners: any, partnersIndex: number) => (
                                                          <h6
                                                              key={partnersIndex}
                                                              style={{
                                                                  marginTop: "10px",
                                                              }}
                                                          >
                                                              {" "}
                                                              Sócio {partnersIndex + 1}: {partners.name}, {partners.type}
                                                          </h6>
                                                      ))
                                                    : "NÃO HÁ DADOS"}
                                            </div>
                                        </ScrollPanel>
                                    </TabPanel>
                                </TabView>
                            </div>
                            <div className="p-col-12 p-md-6 p-lg-6">
                                <TabView>
                                    <TabPanel header="ATIVIDADES">
                                        <ScrollPanel style={{ width: "100%", height: "200px" }} className="custombar1">
                                            <div className="p-field">
                                                <h6
                                                    style={{
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    Atividades: {opportunity.company.main_activity_description}
                                                </h6>
                                                {!isEmptyObj(opportunity.company.secondaryActivities)
                                                    ? opportunity.company.secondaryActivities.map(
                                                          (secondaryActivities: any, secondaryActivitiesIndex: number) => (
                                                              <h6 key={secondaryActivitiesIndex}> {secondaryActivities.description}</h6>
                                                          )
                                                      )
                                                    : ""}
                                                <br />
                                            </div>
                                        </ScrollPanel>
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                    </div>
                )}

                {isEmptyObj(opportunity.company.cnpj) && (
                    <div className="p-col-12 p-md-12 p-lg-12">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6 p-lg-6">
                                <TabView style={{ width: "100%" }}>
                                    <TabPanel header="DADOS DA EMPRESA">
                                        <br />
                                        <div className="p-field">
                                            <h6>Nome: {opportunity.company.name}</h6>
                                            <h6>Tipo: {opportunity.company.type}</h6>
                                            <h6>Pais: {opportunity.company.country}</h6>
                                        </div>
                                    </TabPanel>
                                </TabView>
                            </div>
                            
                            <div className="p-col-12 p-md-6 p-lg-6">
                                <TabView style={{ width: "100%" }}>
                                    <TabPanel header="INTERESSE">
                                        <div className="p-field">
                                            {!isEmptyObj(opportunity.company.interests)
                                                ? opportunity.company.interests.map((interest, indexInterest) => (
                                                      <h6
                                                          style={{
                                                              marginTop: "10px",
                                                          }}
                                                          key={indexInterest}
                                                      >
                                                          {interest.name?.toUpperCase()}
                                                      </h6>
                                                  ))
                                                : "NÃO HÁ DADOS"}
                                        </div>
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                    </div>
                )}
            </form>
        </>
    )
}
