import React, { useRef, useState, useEffect } from "react"
import api from "../../../../services/api"
import { Dropdown } from "primereact/dropdown"
import { Calendar } from "primereact/calendar"
import { InputText } from "primereact/inputtext"
import { MultiSelect } from "primereact/multiselect"
import { TaskInterface } from "../../../../interfaces/TaskInterface"
import { CompanyInterface } from "../../../../interfaces/CompanyInterface"
import moment from "moment"

interface PropsInterface {
    type?: string

    updateTaskData?: TaskInterface
    saveTaskData: TaskInterface
    createTaskIdInOpportunity?: string
    setSaveTaskData: (prop1: any, prop2: any) => void
    setUpdateTaskData?: (prop: TaskInterface) => void
}

export const Task = (props: PropsInterface) => {
    const toast: any = useRef(null)
    const token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    const [oportunities, setOportunities] = useState<TaskInterface[]>([])
    const [users, setUsers] = useState<CompanyInterface[]>([])

    const allUsers = async () => {
        try {
            let header = {
                headers: {
                    route: "list_users",
                },
            }

            await api
                .get("users", header)
                .then((res) => {
                    setUsers(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar usuários!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const allOportunities = async () => {
        try {
            let header = {
                headers: {
                    route: "list_opportunities",
                },
            }

            await api
                .get("opportunities", header)
                .then((res) => {
                    setOportunities(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar oportunidades!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const taskType = [
        { name: "Ligação", code: "LG" },
        { name: "E-mail", code: "EM" },
        { name: "Reunião", code: "RE" },
        { name: "Visita", code: "VI" },
        { name: "Tarefa", code: "TA" },
        { name: "Almoço", code: "AL" },
        { name: "Whatsapp", code: "WP" },
    ]

    useEffect(() => {
        allOportunities()
        allUsers()

        if (props.type === "update" && props.updateTaskData !== undefined && props.setUpdateTaskData !== undefined) {
            props.setUpdateTaskData(props.updateTaskData)
        }

        if (props.type === "createOpportunity" && props.createTaskIdInOpportunity !== undefined) {
            props.setSaveTaskData("opportunity_id", props.createTaskIdInOpportunity)
        }
    }, [])

    const filterNameDate = () => {
        var dateStringWithTime = moment(props.saveTaskData.date).format("DD-MM-YYYY HH:mm:SS")
        return dateStringWithTime
    }

    return (
        <>
            <div className="p-d-flex p-flex-column p-flex-md-row">
                <div className="p-mb-2 p-mr-6">
                    <div className="p-field">
                        <label htmlFor="type">Tipo de Tarefa:</label>
                        <Dropdown
                            style={{ minWidth: "20rem" }}
                            id="type"
                            name="type"
                            value={
                                taskType.filter((a: any) => {
                                    return a.code === props.saveTaskData.type
                                })[0]
                            }
                            options={taskType}
                            filter={true}
                            optionLabel="name"
                            placeholder="Selecionar"
                            onChange={(e: any) => {
                                props.setSaveTaskData(e.target.name, e.target.value.code)
                            }}
                        />
                    </div>

                    <div className="p-field">
                        <div className="p-field">
                            <label htmlFor="subject">Assunto:</label>
                            <InputText
                                style={{ minWidth: "20rem" }}
                                id="subject"
                                name="subject"
                                value={props.saveTaskData.subject}
                                onChange={(e: any) => {
                                    props.setSaveTaskData(e.target.name, e.target.value)
                                }}
                            />
                        </div>
                    </div>

                    <div className="p-field">
                        <label htmlFor="date">Data / Hora</label>
                        <Calendar
                            style={{ minWidth: "20rem" }}
                            id="date"
                            name="date"
                            value={props.saveTaskData.date}
                            onMonthChange={() => ""}
                            dateFormat="dd/mm/yy"
                            showTime
                            locale="pt"
                            showSeconds
                            showIcon
                            placeholder={props.type === "create" || props.type === "createOpportunity" ? "Selecionar" : filterNameDate()}
                            onChange={(e: any) => {
                                props.setSaveTaskData(e.target.name, e.target.value)
                            }}
                        />
                    </div>
                </div>

                <div className="p-mb-2 p-mr-6">
                    <div className="p-field">
                        <label htmlFor="users">Responsáveis:</label>
                        <MultiSelect
                            style={{ minWidth: "20rem" }}
                            id="users"
                            name="users"
                            filter={true}
                            value={props.saveTaskData.users}
                            options={users}
                            optionLabel="name"
                            placeholder="Selecionar"
                            onChange={(e: any) => {
                                props.setSaveTaskData(e.target.name, e.target.value)
                            }}
                        />
                    </div>

                    <div className="p-field">
                        <label htmlFor="opportunity_id">Oportunidade Vinculada:</label>
                        <Dropdown
                            style={{ minWidth: "20rem" }}
                            id="opportunity_id"
                            name="opportunity_id"
                            filter={true}
                            value={
                                oportunities.filter((a: any) => {
                                    return a.id === props.saveTaskData.opportunity_id
                                })[0]
                            }
                            options={oportunities}
                            optionLabel="name"
                            placeholder="Selecionar"
                            disabled={props.type === "createOpportunity" || props.type === "update" ? true : false}
                            onChange={(e: any) => {
                                props.setSaveTaskData(e.target.name, e.target.value.id)
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
