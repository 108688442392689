export const countryList = [
    { name: "África do Sul", code: "ZA" },
    { name: "Albânia", code: "AL" },
    { name: "Alemanha", code: "DE" },
    { name: "Andorra", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antártida", code: "AQ" },
    { name: "Antígua e Barbuda", code: "AG" },
    { name: "Arábia Saudita", code: "SA" },
    { name: "Argélia", code: "DZ" },
    { name: "Argentina", code: "AR" },
    { name: "Armênia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Austrália", code: "AU" },
    { name: "Áustria", code: "AT" },
    { name: "Azerbaijão", code: "AZ" },
    { name: "Bahamas", code: "BS" },
    { name: "Bahrein", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Bélgica", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermudas", code: "BM" },
    { name: "Bolívia", code: "BO" },
    { name: "Bonaire, Santo Eustáquio e Saba", code: "BQ" },
    { name: "Bósnia e Herzegovina", code: "BA" },
    { name: "Botsuana", code: "BW" },
    { name: "Brasil", code: "BR" },
    { name: "Brunei", code: "BN" },
    { name: "Bulgária", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Butão", code: "BT" },
    { name: "Cabo Verde", code: "CV" },
    { name: "Camarões", code: "CM" },
    { name: "Camboja", code: "KH" },
    { name: "Canadá", code: "CA" },
    { name: "Catar", code: "QA" },
    { name: "Cazaquistão", code: "KZ" },
    { name: "Chade", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Chipre", code: "CY" },
    { name: "Cingapura", code: "SG" },
    { name: "Colômbia", code: "CO" },
    { name: "Comores", code: "KM" },
    { name: "Congo", code: "CG" },
    { name: "Coreia do Norte", code: "KP" },
    { name: "Coreia do Sul", code: "KR" },
    { name: "Costa do Marfim", code: "CI" },
    { name: "Costa Rica", code: "CR" },
    { name: "Croácia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Curaçao", code: "CW" },
    { name: "Dinamarca", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Egito", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Emirados Árabes Unidos", code: "AE" },
    { name: "Equador", code: "EC" },
    { name: "Eritréia", code: "ER" },
    { name: "Eslováquia", code: "SK" },
    { name: "Eslovênia", code: "SI" },
    { name: "Espanha", code: "ES" },
    { name: "Estados Unidos da América", code: "US" },
    { name: "Estados Unidos Ilhas Menores Distantes", code: "UM" },
    { name: "Estônia", code: "EE" },
    { name: "Eswatini", code: "SZ" },
    { name: "Etiópia", code: "ET" },
    { name: "Fiji", code: "FJ" },
    { name: "Filipinas", code: "PH" },
    { name: "Finlândia", code: "FI" },
    { name: "França", code: "FR" },
    { name: "Gabão", code: "GA" },
    { name: "Gâmbia", code: "GM" },
    { name: "Gana", code: "GH" },
    { name: "Geórgia do Sul e Ilhas Sandwich do Sul", code: "GS" },
    { name: "Geórgia", code: "GE" },
    { name: "Gibraltar", code: "GI" },
    { name: "Granada", code: "GD" },
    { name: "Grécia", code: "GR" },
    { name: "Groenlândia", code: "GL" },
    { name: "Guadalupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guiana Francesa", code: "GF" },
    { name: "Guiana", code: "GY" },
    { name: "Guiné Equatorial", code: "GQ" },
    { name: "Guiné", code: "GN" },
    { name: "Guiné-Bissau", code: "GW" },
    { name: "Haiti", code: "HT" },
    { name: "Holanda", code: "NL" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungria", code: "HU" },
    { name: "Iêmen", code: "YE" },
    { name: "Ilha Bouvet", code: "BV" },
    { name: "Ilha Christmas", code: "CX" },
    { name: "Ilha de Man", code: "IM" },
    { name: "Ilha Heard e Ilhas McDonald", code: "HM" },
    { name: "Ilha Norfolk", code: "NF" },
    { name: "Ilhas Aland", code: "AX" },
    { name: "Ilhas Cayman", code: "KY" },
    { name: "Ilhas Cocos (Keeling)", code: "CC" },
    { name: "Ilhas Cook", code: "CK" },
    { name: "Ilhas Falkland (Malvinas)", code: "FK" },
    { name: "Ilhas Faroe", code: "FO" },
    { name: "Ilhas Marianas do Norte", code: "MP" },
    { name: "Ilhas Marshall", code: "MH" },
    { name: "Ilhas Salomão", code: "SB" },
    { name: "Ilhas Turks e Caicos", code: "TC" },
    { name: "Ilhas Virgens Americanas", code: "VI" },
    { name: "Ilhas Virgens Britânicas", code: "VG" },
    { name: "Índia", code: "IN" },
    { name: "Indonésia", code: "ID" },
    { name: "Irã", code: "IR" },
    { name: "Iraque", code: "IQ" },
    { name: "Irlanda", code: "IE" },
    { name: "Islândia", code: "IS" },
    { name: "Israel", code: "IL" },
    { name: "Itália", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japão", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordânia", code: "JO" },
    { name: "Kiribati", code: "KI" },
    { name: "Kuwait", code: "KW" },
    { name: "Laos", code: "LA" },
    { name: "Lesoto", code: "LS" },
    { name: "Letônia", code: "LV" },
    { name: "Líbano", code: "LB" },
    { name: "Libéria", code: "LR" },
    { name: "Líbia", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lituânia", code: "LT" },
    { name: "Luxemburgo", code: "LU" },
    { name: "Macau", code: "MO" },
    { name: "Madagáscar", code: "MG" },
    { name: "Malásia", code: "MY" },
    { name: "Malaui", code: "MW" },
    { name: "Maldivas", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marrocos", code: "MA" },
    { name: "Martinica", code: "MQ" },
    { name: "Maurícia", code: "MU" },
    { name: "Mauritânia", code: "MR" },
    { name: "Mayotte", code: "YT" },
    { name: "México", code: "MX" },
    { name: "Micronésia", code: "FM" },
    { name: "Moldávia", code: "MD" },
    { name: "Mônaco", code: "MC" },
    { name: "Mongólia", code: "MN" },
    { name: "Montenegro", code: "ME" },
    { name: "Montserrat", code: "MS" },
    { name: "Moçambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namíbia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Nicarágua", code: "NI" },
    { name: "Níger", code: "NE" },
    { name: "Nigéria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Noruega", code: "NO" },
    { name: "Nova Caledônia", code: "NC" },
    { name: "Nova Zelândia", code: "NZ" },
    { name: "Omã", code: "OM" },
    { name: "Palau", code: "PW" },
    { name: "Palestina", code: "PS" },
    { name: "Panamá", code: "PA" },
    { name: "Papua Nova Guiné", code: "PG" },
    { name: "Paquistão", code: "PK" },
    { name: "Paraguai", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Pitcairn", code: "PN" },
    { name: "Polinésia Francesa", code: "PF" },
    { name: "Polônia", code: "PL" },
    { name: "Porto Rico", code: "PR" },
    { name: "Portugal", code: "PT" },
    { name: "Quênia", code: "KE" },
    { name: "Quirguistão", code: "KG" },
    { name: "Reino Unido", code: "GB" },
    { name: "República Centro-Africana", code: "CF" },
    { name: "República da Macedônia do Norte", code: "MK" },
    { name: "República Democrática do Congo", code: "CD" },
    { name: "República Dominicana", code: "DO" },
    { name: "República Tcheca", code: "CZ" },
    { name: "Reunião", code: "RE" },
    { name: "Romênia", code: "RO" },
    { name: "Ruanda", code: "RW" },
    { name: "Rússia", code: "RU" },
    { name: "Saara Ocidental", code: "EH" },
    { name: "Samoa Americana", code: "AS" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Santa Helena", code: "SH" },
    { name: "Santa Lúcia", code: "LC" },
    { name: "Santa Sé", code: "VA" },
    { name: "São Bartolomeu", code: "BL" },
    { name: "São Cristóvão e Névis", code: "KN" },
    { name: "São Martinho", code: "MF" },
    { name: "São Pedro e Miquelão", code: "PM" },
    { name: "São Tomé e Príncipe", code: "ST" },
    { name: "São Vicente e Granadinas", code: "VC" },
    { name: "Seicheles", code: "SC" },
    { name: "Senegal", code: "SN" },
    { name: "Serra Leoa", code: "SL" },
    { name: "Sérvia", code: "RS" },
    { name: "Sint Maarten", code: "SX" },
    { name: "Síria", code: "SY" },
    { name: "Somália", code: "SO" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudão do Sul", code: "SS" },
    { name: "Sudão", code: "SD" },
    { name: "Suécia", code: "SE" },
    { name: "Suíça", code: "CH" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard e Jan Mayen", code: "SJ" },
    { name: "Tailândia", code: "TH" },
    { name: "Taiwan", code: "TW" },
    { name: "Tajiquistão", code: "TJ" },
    { name: "Tanzânia", code: "TZ" },
    { name: "Território Britânico do Oceano Índico", code: "IO" },
    { name: "Territórios Franceses do Sul", code: "TF" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad e Tobago", code: "TT" },
    { name: "Tunísia", code: "TN" },
    { name: "Turcomenistão", code: "TM" },
    { name: "Turquia", code: "TR" },
    { name: "Tuvalu", code: "TV" },
    { name: "Ucrânia", code: "UA" },
    { name: "Uganda", code: "UG" },
    { name: "Uruguai", code: "UY" },
    { name: "Uzbequistão", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela", code: "VE" },
    { name: "Vietnã", code: "VN" },
    { name: "Wallis e Futuna", code: "WF" },
    { name: "Zâmbia", code: "ZM" },
    { name: "Zimbábue", code: "ZW" },
]
