
import React, { useRef, useState, useEffect } from "react"
import * as yup from "yup"
import api from "../../services/api"
import App from "../../layout/App"
import Moment from "react-moment"

//PrimeReact
import { Avatar } from "primereact/avatar"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Password } from "primereact/password"
import { Toast } from "primereact/toast"
import { useAuth } from "../../hooks/AuthContext"

//Components
import { LocalUpload } from "../../components/crm/whatsapp/LocalUpload"

//Interface
import { UserInterface } from "../../interfaces/UserInterface"
import { PasswordInterface } from "../../interfaces/PasswordInterface"
import { AvatarDefaultInterface } from "../../interfaces/AvatarDefaultInterface"
import { PermissionGroup } from "../../interfaces/PermissionGroupInterface"

interface AvatarData {
    avatar: string
}

export const Profile: React.FC = () => {
    let toast = useRef<Toast>(null)
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`
    let { user, editUser } = useAuth()

    let [avatars] = useState<AvatarData[]>([])
    let [editUserData, setEditUserData] = useState<UserInterface>(user)
    let [passwordEdit, setEditPassword] = useState<PasswordInterface>({} as PasswordInterface)
    let [editPasswordDialog, setEditPasswordDialog] = useState(false)
    let [avatarsDefault, setAvatarsDefault] = useState<AvatarDefaultInterface[]>([])

    let [permissionsGroup, setPermissionsGroup] = useState<PermissionGroup[]>([])

    //Dialog
    let [editPhotoDialog, setEditPhotoDialog] = useState(false)
    let [editSign, setEditSign] = useState(false)
    let [chooseAvatarDefault, setChooseAvatarDefault] = useState(false)
    let [editUserDialog, setEditUserDialog] = useState(false)

    //Loadings
    let [loading, setLoading] = useState(false)
    let [loadingPassword, setLoadingPassword] = useState(false)
    let [loadingDefaultAvatar, setLoadingDefaultAvatar] = useState<boolean>(false)
    let [loadingSign, setLoadignSign] = useState<boolean>(false)

    async function validateUser() {
        let schema = yup.object().shape({
            name: yup.string().required(),
        })

        try {
            await schema.validate(editUserData)
            return true
        } catch (error) {
            return false
        }
    }

    const allAvatars = async () => {
        try {
            let header = {
                headers: {
                    route: "list_avatars",
                },
            }

            await api
                .get("avatars", header)
                .then((res) => {
                    setAvatarsDefault(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const allGroups = async () => {
        try {
            let header = {
                headers: {
                    route: "show_user_groups_with_groups",
                },
            }

            await api
                .get(`user_group/groups/${user.id}`, header)
                .then((res) => {
                    let permissionsGroupAll: PermissionGroup[] = []
                    res.data.forEach((p: any) => {
                        permissionsGroupAll.push({
                            id: p.group.id,
                            name: p.group.name,
                            userFromGroup: p.userFromGroup,
                            user_id: user.id,
                        })
                    })

                    permissionsGroup = permissionsGroupAll
                    setPermissionsGroup(permissionsGroup)
                    console.log(permissionsGroup)
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    //Requisitions
    const onUploadDefaut = (item: AvatarDefaultInterface) => {
        setLoadingDefaultAvatar(true)
        hideDialog()
        toast.current?.show({
            severity: "info",
            summary: "Atualizando avatar...",
            life: 1500,
        })
        try {
            let header = {
                headers: {
                    route: "update_default_avatar",
                },
            }

            let auxAvatarDefault = {
                avatar_id: item.id,
            }

            api.patch("users/default_avatar", auxAvatarDefault, header)
                .then((res) => {
                    hideDialog()
                    setLoadingDefaultAvatar(false)
                    editUser(res.data)
                    toast.current?.show({
                        severity: "success",
                        summary: "Avatar Atualizado!",
                        detail: "Seus avatar foi carregado com sucesso!",
                        life: 4000,
                    })
                })
                .catch((error) => {
                    setLoadingDefaultAvatar(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Ocorreu um Erro!",
                        detail: error.response.data.message,
                        life: 6000,
                    })
                })
        } catch (error) {}
    }

    const onRemoveProfilePicutre = () => {
        setLoadingDefaultAvatar(true)
        hideDialog()
        toast.current?.show({
            severity: "info",
            summary: "Removendo avatar...",
            life: 1500,
        })
        try {
            let header = {
                headers: {
                    route: "update_default_avatar",
                },
            }

            let auxAvatarDefault = {
                avatar_id: null,
            }

            api.patch("users/default_avatar", auxAvatarDefault, header)
                .then((res) => {
                    hideDialog()
                    setLoadingDefaultAvatar(false)
                    editUser(res.data)
                    toast.current?.show({
                        severity: "success",
                        summary: "Avatar Atualizado!",
                        detail: "Seus avatar foi removido com sucesso!",
                        life: 4000,
                    })
                })
                .catch((error) => {
                    setLoadingDefaultAvatar(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Ocorreu um Erro!",
                        detail: error.response.data.message,
                        life: 6000,
                    })
                })
        } catch (error) {}
    }

    const handleEditUser = async () => {
        setLoading(true)
        if (!(await validateUser())) {
            toast.current?.show({
                severity: "error",
                summary: "Dados Invalidos!",
                detail: "Preencha todos os dados obrigatorios!",
                life: 6000,
            })
        } else {
            if (editUserData.phone === null || editUserData.phone === "") {
                try {
                    let header = {
                        headers: {
                            route: "update_profile",
                        },
                    }

                    let auxEditUserData = {
                        name: editUserData.name,
                        email: editUserData.email,
                        phone: null,
                    }

                    api.put("profile", auxEditUserData, header)
                        .then((res) => {
                            hideDialog()
                            setLoading(false)
                            editUser(res.data)
                            toast.current?.show({
                                severity: "success",
                                summary: "Usuario Atualizado!",
                                detail: "Seus dados foram atualizados!",
                                life: 4000,
                            })
                        })
                        .catch((error) => {
                            setLoading(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Ocorreu um Erro!",
                                detail: error.response.data.message,
                                life: 6000,
                            })
                        })
                } catch (error) {}
            } else {
                try {
                    let header = {
                        headers: {
                            route: "update_profile",
                        },
                    }

                    let auxEditUserData = {
                        name: editUserData.name,
                        email: editUserData.email,
                        phone: editUserData.phone,
                    }

                    api.put("profile", auxEditUserData, header)
                        .then((res) => {
                            hideDialog()
                            setLoading(false)
                            editUser(res.data)
                            toast.current?.show({
                                severity: "success",
                                summary: "Usuario Atualizado!",
                                detail: "Seus dados foram atualizados!",
                                life: 4000,
                            })
                        })
                        .catch((error) => {
                            setLoading(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Ocorreu um Erro!",
                                detail: error.response.data.message,
                                life: 6000,
                            })
                        })
                } catch (error) {}
            }
        }
    }

    const handleEditPassword = async () => {
        setLoadingPassword(true)
        try {
            let header = {
                headers: {
                    route: "update_profile",
                },
            }

            let auxEditUserData = {
                name: user.name,
                email: user.email,
                old_password: passwordEdit.passwordOld,
                password: passwordEdit.passwordNew,
                password_confirmation: passwordEdit.passwordConfirm,
            }

            api.put("profile", auxEditUserData, header)
                .then((res) => {
                    hideDialog()
                    setLoadingPassword(true)
                    editUser(res.data)
                    toast.current?.show({
                        severity: "success",
                        summary: "Usuario Atualizado!",
                        detail: "Seus dados foram atualizados!",
                        life: 4000,
                    })
                })
                .catch((error) => {
                    setLoadingPassword(true)
                    toast.current?.show({
                        severity: "error",
                        summary: "Ocorreu um Erro!",
                        detail: error.response.data.message,
                        life: 6000,
                    })
                })
        } catch (error) {}
    }

    const onUploadSign = (files: any) => {
        const data = new FormData()
        data.append("sign", files[0].file)
        hideDialog()
        setLoadignSign(true)

        toast.current?.show({
            severity: "info",
            summary: "Enviando arquivo, aguarde...",
            life: 3000,
        })

        let header = {
            headers: {
                route: "update_profile_sign",
            },
        }

        api.patch(`profile/sign`, data, header)

            .then((res) => {
                if (res.status === 200) {
                    setLoadignSign(false)
                    editUser(res.data)
                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Arquivo enviado com sucesso",
                        life: 3000,
                    })
                } else {
                    setLoadignSign(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao enviar arquivo!",
                        detail: res.data.message,
                        life: 3000,
                    })
                }
            })

            .catch((error) => {
                setLoadignSign(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao enviar arquivo!",
                    detail: error.response.data.message,
                    life: 3000,
                })
            })
    }

    const onUploadAvatar = (files: any) => {
        const data = new FormData()
        data.append("avatar", files[0].file)
        hideDialog()
        toast.current?.show({
            severity: "info",
            summary: "Enviando arquivo, aguarde...",
            life: 3000,
        })

        let header = {
            headers: {
                route: "update_avatar",
            },
        }

        api.patch(`users/avatar`, data, header)

            .then((res) => {
                if (res.status === 200) {
                    editUser(res.data)
                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Avatar enviado com sucesso",
                        life: 3000,
                    })
                } else {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao enviar avatar !",
                        detail: res.data.message,
                        life: 3000,
                    })
                }
            })

            .catch((error) => {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao enviar avatar !",
                    detail: error.response.data.message,
                    life: 3000,
                })
            })
    }

    //OnChange
    const onChangeUser = (event: any) => {
        const { value, name } = event.target

        setEditUserData({
            ...editUserData,
            [name]: value,
        })
    }

    const onChangePassword = (event: any) => {
        const { value, name } = event.target

        setEditPassword({
            ...passwordEdit,
            [name]: value,
        })
    }

    //Dialog
    const hideDialog = () => {
        setEditUserDialog(false)
        setEditPhotoDialog(false)
        setEditSign(false)
        setChooseAvatarDefault(false)
        setEditPasswordDialog(false)
    }

    //Footers
    const editUserDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" loading={loading} className="p-button-success p-button-text" onClick={handleEditUser} />
        </>
    )

    const editPasswordFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" loading={loadingPassword} className="p-button-success p-button-text" onClick={handleEditPassword} />
        </>
    )

    useEffect(() => {
        allAvatars()
    }, [user, avatars])

    useEffect(() => {
        allGroups()
    }, [])

    return (
        <App>
            <div className="p-grid">
                <Toast ref={toast} />
                <div className="p-col-12 p-md-6 p-lg-6">
                    <div className="card" style={{ minHeight: "25rem" }}>
                        <h5>INFORMAÇÃO DO USUÁRIO</h5>
                        <div className="p-grid">
                            <div
                                className="p-col-12 p-md-5 p-lg-5"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {user.avatar_url != null && (
                                    <Avatar
                                        style={{ width: "10rem", height: "10rem" }}
                                        className="p-overlay-badge"
                                        image={user.avatar_url}
                                        onClick={() => setChooseAvatarDefault(true)}
                                        size="xlarge"
                                        shape="circle"
                                    ></Avatar>
                                )}

                                {user.avatar_url === null && (
                                    <Avatar
                                        style={{ width: "10rem", height: "10rem" }}
                                        icon="pi pi-user"
                                        onClick={() => setChooseAvatarDefault(true)}
                                        className="mr-2"
                                        size="xlarge"
                                        shape="circle"
                                    ></Avatar>
                                )}

                                <Button
                                    label="ALTERAR FOTO DE PERFIL"
                                    className="p-button-text"
                                    loading={loadingDefaultAvatar}
                                    onClick={() => setChooseAvatarDefault(true)}
                                />
                            </div>

                            <div
                                className="p-col-12 p-md-7 p-lg-7"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <div className="p-field">
                                    <label htmlFor="dateCreate">
                                        <strong>NOME: </strong>
                                    </label>
                                    <span id="dateCreate"> {user.name}</span>
                                </div>

                                <div className="p-field">
                                    <label htmlFor="dateCreate">
                                        <strong>EMAIL: </strong>
                                    </label>
                                    <span id="dateCreate"> {user.email}</span>
                                </div>
                                {!isEmptyObj(user.phone) && (
                                    <div className="p-field">
                                        <label htmlFor="dateCreate">
                                            <strong>TELEFONE: </strong>
                                        </label>
                                        <span id="dateCreate"> {user.phone}</span>
                                    </div>
                                )}

                                <div className="p-field">
                                    <label htmlFor="dateCreate">
                                        <strong>DATA DE CRIAÇÃO: &nbsp; </strong>
                                    </label>
                                    <span id="dateCreate">
                                        <Moment format="DD-MM-YYYY HH:MM:ss">{user.created_at}</Moment>
                                    </span>
                                </div>

                                <div className="p-field">
                                    <label htmlFor="dateCreate">
                                        <strong>DATA DE EDIÇÃO: &nbsp;</strong>
                                    </label>
                                    <span id="dateCreate">
                                        <Moment format="DD-MM-YYYY HH:MM:ss">{user.updated_at}</Moment>
                                    </span>
                                </div>

                                <div className="p-field">
                                    <Button label="EDITAR DADOS" loading={loading} className="p-button-text" onClick={() => setEditUserDialog(true)} />
                                    <Button label="ALTERAR SENHA" loading={loading} className="p-button-text" onClick={() => setEditPasswordDialog(true)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-6 p-lg-6">
                    {isEmptyObj(user.sign) && (
                        <div className="card" style={{ maxHeight: "25rem" }}>
                            <h5>ASSINATURA DE EMAIL</h5>
                            <div className="p-field">
                                <LocalUpload onSubmit={onUploadSign} types={".png, .jpg, .jpeg, .gif"} size={80000000} />
                            </div>
                        </div>
                    )}

                    {!isEmptyObj(user.sign) && (
                        <div className="card" style={{ minHeight: "25rem", maxHeight: "25rem" }}>
                            <h5>ASSINATURA DE EMAIL</h5>
                            <div className="p-field">
                                <img src={user.sign_url} style={{ maxHeight: "16.5rem", maxWidth: "100%" }} alt="Imagens Users" />
                            </div>
                            <Button label="MUDAR ASSINATURA" className="p-button-text" loading={loadingSign} onClick={() => setEditSign(true)} />
                        </div>
                    )}
                </div>
            </div>

            <Dialog
                visible={editUserDialog}
                breakpoints={{ "960px": "75vw", "640px": "100vw" }}
                modal
                className="p-fluid"
                onHide={hideDialog}
                header="Editar Usuario"
                footer={editUserDialogFooter}
                draggable={false}
            >
                <form>
                    <div className="p-d-flex p-flex-column p-flex-md-column">
                        <div className="p-field">
                            <label htmlFor="name">NOME:</label>
                            <InputText
                                style={{ minWidth: "20rem" }}
                                id="name"
                                name="name"
                                placeholder={user.name}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeUser(e)}
                                value={editUserData.name || ""}
                            />
                        </div>
                        <div className="p-field">
                            <label htmlFor="email">EMAIL:</label>
                            <InputText
                                style={{ minWidth: "20rem" }}
                                id="email"
                                name="email"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeUser(e)}
                                value={editUserData.email || ""}
                            />
                        </div>

                        <div className="p-field">
                            <label htmlFor="phone">TELEFONE:</label>
                            <InputText
                                style={{ minWidth: "20rem" }}
                                id="phone"
                                name="phone"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeUser(e)}
                                value={editUserData.phone || ""}
                            />
                        </div>
                    </div>
                </form>
            </Dialog>

            {/* Dialog Edit Imagem User */}
            <Dialog
                visible={editPhotoDialog}
                style={{ width: "750px" }}
                breakpoints={{ "960px": "75vw", "640px": "100vw" }}
                modal
                className="p-fluid"
                onHide={hideDialog}
                header="Editar Avatar"
                draggable={false}
            >
                <LocalUpload onSubmit={onUploadAvatar} types={".png, .jpg, .jpeg, .gif"} size={80000000} />
            </Dialog>

            <Dialog
                visible={editSign}
                style={{ width: "750px" }}
                header={"Enviar assinatura de: " + user.name}
                modal
                className="p-fluid"
                onHide={hideDialog}
                draggable={false}
            >
                <div className="p-field">
                    <LocalUpload onSubmit={onUploadSign} types={".png, .jpg, .jpeg, .gif"} size={80000000} />
                </div>
            </Dialog>

            <Dialog
                visible={chooseAvatarDefault}
                style={{ width: "800px" }}
                modal
                className="p-fluid"
                onHide={hideDialog}
                draggable={false}
                header="Envie uma foto de perfil ou escolha um avatar"
            >
                <div className="p-field">
                    <LocalUpload onSubmit={onUploadAvatar} types={".png, .jpg, .jpeg, .gif"} size={80000000} />
                    <br /> <br />
                    {avatarsDefault.map((avatarDefault) => (
                        <Avatar
                            style={{ width: "6rem", height: "6rem", margin: "0.7rem" }}
                            className="p-overlay-badge"
                            image={avatarDefault.file_url}
                            onClick={() => onUploadDefaut(avatarDefault)}
                            size="xlarge"
                            shape="circle"
                        ></Avatar>
                    ))}
                </div>
                <br />
                {!isEmptyObj(user.avatar_url) && (
                    <Button label="Remover foto de perfil" onClick={() => onRemoveProfilePicutre()} className="p-button-raised p-button-danger" />
                )}
            </Dialog>

            <Dialog
                visible={editPasswordDialog}
                breakpoints={{ "960px": "75vw", "640px": "100vw" }}
                modal
                className="p-fluid"
                onHide={hideDialog}
                header="ALTERAR SENHA"
                footer={editPasswordFooter}
                draggable={false}
            >
                <form>
                    <div className="p-d-flex p-flex-column p-flex-md-column">
                        <div className="p-field">
                            <label htmlFor="password">Senha Antiga:</label>
                            <Password
                                style={{ minWidth: "20rem" }}
                                id="passwordOld"
                                name="passwordOld"
                                type="password"
                                toggleMask
                                weakLabel="Senha Fraca"
                                mediumLabel="Senha média"
                                strongLabel="Senha forte"
                                promptLabel="Digite uma senha"
                                onChange={(e: any) => onChangePassword(e)}
                            />
                        </div>
                        <div className="p-field">
                            <label htmlFor="passwordNew">Nova Senha:</label>
                            <Password
                                style={{ minWidth: "30rem" }}
                                id="passwordNew"
                                name="passwordNew"
                                type="password"
                                weakLabel="Senha Fraca"
                                mediumLabel="Senha média"
                                strongLabel="Senha forte"
                                promptLabel="Digite uma senha"
                                onChange={(e: any) => onChangePassword(e)}
                            />
                        </div>
                        <div className="p-field">
                            <label htmlFor="confirmPassword">Confirmar Senha:</label>
                            <Password
                                style={{ minWidth: "20rem" }}
                                id="passwordConfirm"
                                name="passwordConfirm"
                                type="password"
                                weakLabel="Senha Fraca"
                                mediumLabel="Senha média"
                                strongLabel="Senha forte"
                                promptLabel="Digite uma senha"
                                onChange={(e: any) => onChangePassword(e)}
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
        </App>
    )
}
