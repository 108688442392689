
import React, { useRef, useState, useEffect } from "react"
import api from "../../../services/api"
import App from "../../../layout/App"
import Moment from "react-moment"
import { useHotkeys } from "react-hotkeys-hook"

//PrimeReact
import { Button } from "primereact/button"
import { Chip } from "primereact/chip"
import { Column } from "primereact/column"
import { ConfirmDialog } from "primereact/confirmdialog"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { TabPanel, TabView } from "primereact/tabview"
import { Toast } from "primereact/toast"

//Components
import { CrmRecords } from "../../../components/crm/CrmRecords"

//Interfaces
import { AttachmentInterface } from "../../../interfaces/AttachmentInterface"
import { ChatSubgroupInterface } from "../../../interfaces/ChatSubgroupInterface"
import { DefaultMessageInterface } from "../../../interfaces/DefaultMessageInterface"
import { FilterMatchMode, FilterOperator } from "primereact/api"
import { GropuInterface } from "../../../interfaces/GroupInterface"
import { InputTextarea } from "primereact/inputtextarea"
import { HotKeys } from "../../../components/crm/HotKeys"

export const DefaultMessages = () => {
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`
    let toast = useRef<Toast>(null)

    let [activeIndex1, setActiveIndex1] = useState(0)
    let [activeIndex2, setActiveIndex2] = useState(0)
    let [createValues, setCreateValues] = useState<any>({} as any)
    let [defaultMessage, setDefaultMessage] = useState<DefaultMessageInterface>({} as DefaultMessageInterface)
    let [defaultMessages, setDefaultMessages] = useState<DefaultMessageInterface[]>([])
    let [groupsAttachments, setGroupsAttachments] = useState<GropuInterface[]>([])
    let [subGroups, setSubGroups] = useState<ChatSubgroupInterface[]>([])

    //Loading
    let [loadingDelete, setLoadingDelete] = useState<boolean>(false)
    let [loadingList, setLoadingList] = useState<boolean>(true)
    let [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    //Dialog
    //Attachment
    let [defaultMessagesEditDialog, setDefaultMessagesEditDialog] = useState(false)
    let [deleteDefaultMessage, setDeleteDefaultMessageDialog] = useState(false)
    let [hotKeysDialog, setHotKeysDialog] = useState(false)

    //Groups
    let [attachmentsDialog, setAttachmentsDialog] = useState<boolean | undefined>(false)
    let [tablePannel, setTablePannel] = useState<GropuInterface[]>([])
    let [tableSubPannel, setSubTablePannel] = useState<AttachmentInterface[]>([])

    //Filters
    let [globalFilterValue, setGlobalFilterValue] = useState("")

    //Hotkeys
    useHotkeys("shift+n", () => openNewDialog())
    useHotkeys("shift+i", () => setHotKeysDialog(true))

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        text: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
    })

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value
        let _filters = { ...filters }
        _filters["global"].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    const header = (item: any) => {
        return (
            <div className="table-header">
                <h5 style={{ paddingLeft: "5px" }} className="p-m-0">
                    {item.name}
                </h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Pesquisar..." />
                </span>
            </div>
        )
    }

    //List Method
    const allGroupsAttachments = async () => {
        try {
            let header = {
                headers: {
                    route: "list_chat_groups",
                },
            }

            await api
                .get("chat_groups", header)
                .then((res) => {
                    setLoadingList(false)
                    groupsAttachments = res.data

                    let auxGroup: GropuInterface = {} as GropuInterface

                    auxGroup.name = "SEM CATEGORIA"
                    auxGroup.id = "000001"

                    setGroupsAttachments([...groupsAttachments, auxGroup])
                    setTablePannel([...groupsAttachments, auxGroup])
                    setActiveIndex1(groupsAttachments.length)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const allSubGroups = async () => {
        try {
            let header = {
                headers: {
                    route: "list_chat_subgroups",
                },
            }

            await api
                .get("chat_subgroups", header)
                .then((res) => {
                    setLoadingList(false)
                    subGroups = res.data
                    setSubGroups(subGroups)
                    setSubTablePannel(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const allDefaultMessages = async () => {
        try {
            let header = {
                headers: {
                    route: "list_chat_groups",
                },
            }

            await api.get("default_message", header).then((res) => {
                setLoadingList(false)
                let auxtest: DefaultMessageInterface[] = []
                res.data.forEach((defaultMessage: DefaultMessageInterface) => {
                    if (defaultMessage.chat_group_id === null) {
                        defaultMessage.chat_group_id = "000001"
                        defaultMessage.chat_subgroup_id = "000001"
                        auxtest.push(defaultMessage)
                    } else {
                        auxtest.push(defaultMessage)
                    }
                })

                setDefaultMessages(auxtest)
            })
        } catch (error) {
            console.log(error)
        }
    }

    //Dialogs
    const hideDialog = () => {
        setCreateValues({} as DefaultMessageInterface)
        setAttachmentsDialog(false)
        setDefaultMessagesEditDialog(false)
        setDeleteDefaultMessageDialog(false)
        setHotKeysDialog(false)
        setSelectedGroup({} as GropuInterface)
    }

    const editDefaultMessageDialog = (attachment: DefaultMessageInterface) => {
        setDefaultMessage(attachment)

        if (attachment.chatSubgroup) {
            const group = groupsAttachments.find((group) => group.id === attachment.chatSubgroup.chat_group_id)
            setSelectedGroup(group ? group : null)
        }

        setDefaultMessagesEditDialog(true)
    }

    const openNewDialog = () => {
        setAttachmentsDialog(true)
    }

    const deleteDefaultMessageDialog = (item: DefaultMessageInterface) => {
        setDefaultMessage(item)
        setDeleteDefaultMessageDialog(true)
    }

    //Button
    const dataTableButton = (rowData: DefaultMessageInterface) => {
        return (
            <div className="actions">
                <Button icon="pi pi-user-edit" className="p-button-rounded p-button" onClick={() => editDefaultMessageDialog(rowData)} />
                &nbsp;&nbsp;
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => deleteDefaultMessageDialog(rowData)} />
                &nbsp;&nbsp;
            </div>
        )
    }

    const concatenateTask = (rowData: DefaultMessageInterface) => {
        let cells
        cells = <Chip style={{ margin: "5px" }} label={rowData.text} />
        return cells
    }

    //Filters
    const findIndexByIdFilter = (id: string) => {
        let index = -1
        for (let i = 0; i < defaultMessages.length; i++) {
            if (defaultMessages[i].id === id) {
                index = i
                break
            }
        }

        return index
    }

    const filterSubGroups = (rowData: DefaultMessageInterface) => {
        let auxSub: any = []
        subGroups.forEach((sub) => {
            if (sub.chat_group_id === rowData?.id) {
                auxSub.push(sub)
            }
        })
        return auxSub
    }

    //OnChange
    const onChangeCreateAttachmentsMessage = (event: any) => {
        const { value, name } = event.target

        setCreateValues({
            ...createValues,
            [name]: value,
        })
    }

    const onChangeCreateAttachments = (event: any) => {
        if (createValues.chat_subgroup_id) {
            let auxCreateValues = { ...createValues }
            auxCreateValues.chat_subgroup_id = null

            createValues = auxCreateValues
            setCreateValues(createValues)
        }

        const { value, name } = event.target

        setCreateValues({
            ...createValues,
            [name]: value,
        })
    }

    const onChangeEdit = (event: any) => {
        const { value, name } = event.target
        setDefaultMessage({
            ...defaultMessage,
            [name]: value,
        })
    }

    let isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    //Requisitions
    const onDeleteDefaultMessages = () => {
        setLoadingDelete(true)
        try {
            api.delete("default_message", {
                headers: {
                    route: "delete_chat_group",
                },
                data: {
                    id: defaultMessage.id,
                },
            })

                .then((res) => {
                    setLoadingDelete(false)
                    if (res.status === 200) {
                        let auxDeleteAttachament = defaultMessages.filter((item) => defaultMessage.id !== item.id)
                        defaultMessages = auxDeleteAttachament

                        hideDialog()
                        setDefaultMessages(auxDeleteAttachament)
                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na exclusão",
                            detail: "Mensagem padrão excluída com sucesso!",
                            life: 3000,
                        })
                    } else {
                        setLoadingDelete(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao excluir!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingDelete(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao excluir!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmitDefaultMessage = () => {
        let auxLink
        let finalArray

        if (
            (defaultMessage.chatSubgroup?.id === null || defaultMessage.chatSubgroup?.id === undefined) &&
            !isEmptyObj(selectedGroup) &&
            selectedGroup?.name !== "SEM CATEGORIA"
        ) {
            setLoadingSubmit(false)
            toast.current?.show({
                severity: "error",
                summary: "Erro ao Editar",
                detail: "Revise os dados inseridos",
                life: 3000,
            })
        } else {
            setLoadingSubmit(true)
            if (defaultMessage.text === null || defaultMessage.text === "") {
                setLoadingSubmit(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao Editar",
                    detail: "Revise os dados inseridos",
                    life: 3000,
                })
            } else {
                if (defaultMessage.text.startsWith("https://") || defaultMessage.text.startsWith("http://")) {
                    auxLink = true
                } else {
                    auxLink = false
                }

                setLoadingSubmit(true)
                if (isEmptyObj(selectedGroup) || selectedGroup?.name === "SEM CATEGORIA") {
                    finalArray = {
                        id: defaultMessage.id,
                        text: defaultMessage.text,
                        chat_subgroup_id: null,
                        link: auxLink,
                        name: defaultMessage.name,
                    }
                } else {
                    finalArray = {
                        id: defaultMessage.id,
                        text: defaultMessage.text,
                        chat_subgroup_id: defaultMessage.chatSubgroup.id,
                        link: auxLink,
                        name: defaultMessage.name,
                    }
                }
                try {
                    let header = {
                        headers: {
                            route: "update_chat_group",
                        },
                    }

                    api.put("default_message", finalArray, header)

                        .then((res) => {
                            if (res.status === 200) {
                                setLoadingSubmit(false)
                                setDefaultMessages((prevState) => {
                                    const data = [...prevState]
                                    data[findIndexByIdFilter(defaultMessage.id)] = res.data
                                    return data
                                })
                                hideDialog()

                                toast.current?.show({
                                    severity: "success",
                                    summary: "Sucesso na criação",
                                    detail: "Mensagem padrão criada com sucesso!",
                                    life: 3000,
                                })
                            } else {
                                setLoadingSubmit(false)
                                toast.current?.show({
                                    severity: "error",
                                    summary: "Erro ao Editar",
                                    detail: "Por favor verifique os dados inseridos",
                                    life: 3000,
                                })
                            }
                        })
                        .catch((error) => {
                            setLoadingSubmit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao Editar",
                                detail: error.response.data.message,
                                life: 3000,
                            })
                        })
                } catch (error) {
                    console.log(error)
                }
            }
        }
    }

    const onSubmitAttachments = () => {
        if (
            createValues.name === null ||
            createValues.name === "" ||
            createValues.text === null ||
            createValues.text === "" ||
            createValues.name === undefined ||
            createValues.text === undefined
        ) {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao criar",
                detail: "Revise os dados inseridos",
                life: 3000,
            })
        } else {
            if (createValues.chat_subgroup_id !== null) {
                let group
                let auxLink
                let id = createValues.chat_subgroup_id?.id

                let auxAttachmentsRequisition

                if (defaultMessage.text) {
                    if (defaultMessage.text.startsWith("https://") || defaultMessage.text.startsWith("http://")) {
                        auxLink = true
                    } else {
                        auxLink = false
                    }
                }

                if (createValues.group === undefined) {
                    auxAttachmentsRequisition = {
                        text: createValues.text,
                        chat_subgroup_id: null,
                        link: auxLink,
                        name: createValues.name,
                    }
                    let header = {
                        headers: {
                            route: "create_default_message",
                        },
                    }
                    api.post("default_message", auxAttachmentsRequisition, header)
                        .then((res) => {
                            if (res.status === 200) {
                                toast.current?.show({
                                    severity: "success",
                                    summary: "Sucesso na alteração",
                                    detail: "Mensagem padrão alterada com sucesso!",
                                    life: 3000,
                                })
                                setLoadingSubmit(false)
                                setAttachmentsDialog(false)
                                setDefaultMessages([...defaultMessages, res.data])
                                hideDialog()
                                allDefaultMessages()
                            }
                        })
                        .catch((error) => {
                            setLoadingSubmit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao cadastar!",
                                detail: "O campo de mensagem não pode ser vazio",
                                life: 3000,
                            })
                        })
                } else {
                    if (defaultMessage.text) {
                        if (defaultMessage.text.includes("https://") || defaultMessage.text.includes("http://")) {
                            auxLink = true
                        } else {
                            auxLink = false
                        }
                    }
                    try {
                        if (createValues.group.id === "000001" || group === "000001") {
                            auxAttachmentsRequisition = {
                                text: createValues.text,
                                chat_subgroup_id: null,
                                link: auxLink,
                                name: createValues.name,
                            }
                        } else {
                            auxAttachmentsRequisition = {
                                text: createValues.text,
                                chat_subgroup_id: id,
                                link: auxLink,
                                name: createValues.name,
                            }
                        }

                        if (createValues.text === "") {
                            setLoadingSubmit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao cadastrar",
                                detail: "Por favor, verificar os dados preenchidos!",
                                life: 3000,
                            })
                        } else {
                            let header = {
                                headers: {
                                    route: "create_chat_group",
                                },
                            }

                            api.post("default_message", auxAttachmentsRequisition, header)
                                .then((res) => {
                                    if (res.status === 200) {
                                        toast.current?.show({
                                            severity: "success",
                                            summary: "Sucesso na alteração",
                                            detail: "Mensagem padrão alterada com sucesso!",
                                            life: 3000,
                                        })
                                        setLoadingSubmit(false)
                                        setAttachmentsDialog(false)
                                        setDefaultMessages([...defaultMessages, res.data])
                                        hideDialog()
                                        allDefaultMessages()
                                    }
                                })
                                .catch((error) => {
                                    setLoadingSubmit(false)
                                    toast.current?.show({
                                        severity: "error",
                                        summary: "Erro ao cadastar!",
                                        detail: error.response.data.message,
                                        life: 3000,
                                    })
                                })
                        }
                    } catch (error) {
                        console.log(error)
                    }
                }
            } else {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao cadastrar",
                    detail: "Por favor, verificar os dados preenchidos!",
                    life: 3000,
                })
            }
        }
    }

    //Datatble formater
    const dateFormatCreate = (rowData: DefaultMessageInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.created_at}</Moment>
            </>
        )
    }

    const dateFormatUpdate = (rowData: DefaultMessageInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.updated_at}</Moment>
            </>
        )
    }

    //Footers
    const deleteDialogFooter = () => {
        return (
            <>
                <Button label="Não" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingDelete} onClick={onDeleteDefaultMessages} />
            </>
        )
    }

    const deleteMessage = () => {
        return (
            <>
                {" "}
                Deseja realmente excluir a mensagem: <strong>{defaultMessage.text}</strong>
            </>
        )
    }

    const editDefaultMessageFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingSubmit} onClick={onSubmitDefaultMessage} />
        </>
    )

    const createDefaultMessage = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingSubmit} onClick={onSubmitAttachments} />
        </>
    )

    const setValueNewAttachment = () => {
        createValues.group = tablePannel[activeIndex1]
        createValues.chat_subgroup_id = subGroups[activeIndex2]
        setCreateValues(createValues)
    }

    //UseEffect
    useEffect(() => {
        allGroupsAttachments()
        allDefaultMessages()
        allSubGroups()
    }, [])

    const [selectedGroup, setSelectedGroup] = useState<GropuInterface | null>(null)

    return (
        <App>
            <CrmRecords />
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <div className="p-d-flex p-jc-between">
                            <Button
                                label="Novo"
                                icon="pi pi-plus"
                                className="p-button"
                                onClick={() => {
                                    openNewDialog()
                                    setValueNewAttachment()
                                }}
                            />
                        </div>
                        <br />

                        {/* DataTable Attachmets*/}
                        <TabView activeIndex={activeIndex1} onTabChange={(e) => setActiveIndex1(e.index)}>
                            {tablePannel.map((group: GropuInterface, indexgroup: number) => {
                                if (group.id !== "000001") {
                                    return (
                                        <TabPanel key={indexgroup} header={group.name}>
                                            <br />
                                            {!isEmptyObj(groupsAttachments) && (
                                                <TabView activeIndex={activeIndex2} onTabChange={(e) => setActiveIndex2(e.index)}>
                                                    {tableSubPannel.map((subgroup: AttachmentInterface, indexPriceTable: number) => {
                                                        if (group.id !== "000001") {
                                                            if (subgroup.chat_group_id === group.id) {
                                                                return (
                                                                    <TabPanel key={indexPriceTable} header={subgroup.name}>
                                                                        <DataTable
                                                                            value={defaultMessages.filter(
                                                                                (a: DefaultMessageInterface) => a.chat_subgroup_id === subgroup.id
                                                                            )}
                                                                            loading={loadingList}
                                                                            globalFilterFields={["text", "name"]}
                                                                            stripedRows
                                                                            selectionMode="checkbox"
                                                                            dataKey="id"
                                                                            filters={filters}
                                                                            rows={10}
                                                                            rowsPerPageOptions={[10, 15, 20]}
                                                                            className="datatable-responsive"
                                                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} mensagens padrão"
                                                                            paginator
                                                                            header={() => header(subgroup)}
                                                                            emptyMessage="Não há Mensagens Padrões"
                                                                        >
                                                                            <Column
                                                                                style={{ width: "18rem" }}
                                                                                field="name"
                                                                                header="Nome"
                                                                                sortField="name"
                                                                                sortable
                                                                                align="center"
                                                                                alignHeader="center"
                                                                            />

                                                                            <Column
                                                                                style={{ width: "29rem" }}
                                                                                body={concatenateTask}
                                                                                header="Mensagem"
                                                                                sortField="text"
                                                                                sortable
                                                                                align="center"
                                                                                alignHeader="center"
                                                                            />

                                                                            <Column
                                                                                body={dateFormatCreate}
                                                                                header="Data de Criação"
                                                                                sortable
                                                                                sortField="created_at"
                                                                                align="center"
                                                                                alignHeader="center"
                                                                            />
                                                                            <Column
                                                                                body={dateFormatUpdate}
                                                                                sortField="update_at"
                                                                                header="Data de Atualização"
                                                                                sortable
                                                                                align="center"
                                                                                alignHeader="center"
                                                                            />
                                                                            <Column align="center" alignHeader="center" body={dataTableButton}></Column>
                                                                        </DataTable>
                                                                    </TabPanel>
                                                                )
                                                            }
                                                        }
                                                    })}
                                                </TabView>
                                            )}
                                        </TabPanel>
                                    )
                                } else {
                                    return (
                                        <TabPanel header={group.name}>
                                            <DataTable
                                                value={defaultMessages.filter((a: DefaultMessageInterface) => a.chat_subgroup_id == null)}
                                                loading={loadingList}
                                                globalFilterFields={["text", "name"]}
                                                stripedRows
                                                selectionMode="checkbox"
                                                dataKey="id"
                                                filters={filters}
                                                rows={10}
                                                header={() => header("SEM CATEGORIA")}
                                                rowsPerPageOptions={[10, 15, 20]}
                                                className="datatable-responsive"
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} mensagens padrão"
                                                paginator
                                                emptyMessage="Não há Mensagens Padrões"
                                            >
                                                <Column
                                                    style={{ width: "18rem" }}
                                                    field="name"
                                                    header="Nome"
                                                    sortField="name"
                                                    sortable
                                                    align="center"
                                                    alignHeader="center"
                                                />

                                                <Column
                                                    style={{ width: "29rem" }}
                                                    body={concatenateTask}
                                                    header="Mensagem"
                                                    sortField="text"
                                                    sortable
                                                    align="center"
                                                    alignHeader="center"
                                                />

                                                <Column
                                                    body={dateFormatCreate}
                                                    header="Data de Criação"
                                                    sortable
                                                    sortField="created_at"
                                                    align="center"
                                                    alignHeader="center"
                                                />
                                                <Column
                                                    body={dateFormatUpdate}
                                                    sortField="update_at"
                                                    header="Data de Atualização"
                                                    sortable
                                                    align="center"
                                                    alignHeader="center"
                                                />
                                                <Column align="center" alignHeader="center" body={dataTableButton}></Column>
                                            </DataTable>
                                        </TabPanel>
                                    )
                                }
                            })}
                        </TabView>

                        {/* Edit  Default Message   Dialog */}
                        <Dialog
                            visible={defaultMessagesEditDialog}
                            style={{ width: "450px" }}
                            header={"Editar Mensagem Padrão "}
                            draggable={false}
                            modal
                            className="p-fluid"
                            footer={editDefaultMessageFooter}
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <InputText
                                    id="name"
                                    name="name"
                                    required
                                    type="String"
                                    maxLength={120}
                                    style={{ minWidth: "20rem" }}
                                    value={defaultMessage.name}
                                    onChange={(e: any) => onChangeEdit(e)}
                                />
                                <br /> <br />
                                <label htmlFor="text">Mensagem:</label>
                                <InputTextarea
                                    name="text"
                                    onChange={(e: any) => onChangeEdit(e)}
                                    value={defaultMessage.text}
                                    rows={5}
                                    cols={10}
                                    autoResize
                                    style={{ height: "3rem" }}
                                />
                            </div>

                            <br />

                            <div className="p-field">
                                <label htmlFor="attachment_group_id">Categoria:</label>
                                <Dropdown
                                    id="chatGroup"
                                    name="chatGroup"
                                    required
                                    type="String"
                                    emptyMessage="Não há dados"
                                    emptyFilterMessage="Não há dados"
                                    style={{ minWidth: "20rem" }}
                                    maxLength={45}
                                    options={groupsAttachments}
                                    dataKey="id"
                                    optionLabel="name"
                                    onChange={(e: any) => {
                                        setSelectedGroup(e.value)
                                    }}
                                    value={selectedGroup}
                                    placeholder="SEM CATEGORIA"
                                />
                            </div>

                            <br />
                            {!isEmptyObj(selectedGroup) && selectedGroup?.id !== "000001" && (
                                <div className="p-field">
                                    <label htmlFor="description">Subcategoria:</label>
                                    <Dropdown
                                        id="chatSubgroup"
                                        name="chatSubgroup"
                                        required
                                        options={subGroups.filter((a) => {
                                            return a.chat_group_id === selectedGroup?.id
                                        })}
                                        dataKey="id"
                                        optionLabel="name"
                                        style={{ minWidth: "20rem" }}
                                        onChange={(e: any) => setDefaultMessage({ ...defaultMessage, [`chatSubgroup`]: e.target.value })}
                                        value={defaultMessage.chatSubgroup}
                                        placeholder="Selecionar"
                                    />
                                </div>
                            )}
                        </Dialog>

                        {/* Create Default Message  Dialog */}
                        <Dialog
                            visible={attachmentsDialog}
                            style={{ width: "450px" }}
                            header={"Criar Mensagem padrão "}
                            modal
                            className="p-fluid"
                            draggable={false}
                            footer={createDefaultMessage}
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <label htmlFor="text">Nome:</label>
                                <InputText
                                    id="name"
                                    name="name"
                                    required
                                    type="String"
                                    maxLength={120}
                                    style={{ minWidth: "20rem" }}
                                    value={createValues.name || ""}
                                    onChange={onChangeCreateAttachmentsMessage}
                                />
                                <br /> <br />
                                <label htmlFor="text">Mensagem:</label>
                                <InputTextarea
                                    onChange={onChangeCreateAttachmentsMessage}
                                    name="text"
                                    rows={5}
                                    cols={10}
                                    autoResize
                                    style={{ height: "5rem" }}
                                />
                            </div>
                            <br />
                            <div className="p-field">
                                <label htmlFor="description">Categoria:</label>
                                <Dropdown
                                    id="group"
                                    name="group"
                                    required
                                    options={groupsAttachments}
                                    optionLabel="name"
                                    style={{ minWidth: "20rem" }}
                                    onChange={onChangeCreateAttachments}
                                    value={createValues.group || ""}
                                    placeholder="SEM CATEGORIA"
                                />
                                {createValues.group?.id !== undefined && createValues.group?.id !== "000001" && (
                                    <>
                                        <br />
                                        <div className="p-field">
                                            <label htmlFor="description">Subcategoria:</label>
                                            <Dropdown
                                                id="group"
                                                name="chat_subgroup_id"
                                                required
                                                options={filterSubGroups(createValues.group)}
                                                optionLabel="name"
                                                style={{ minWidth: "20rem" }}
                                                onChange={onChangeCreateAttachments}
                                                value={createValues.chat_subgroup_id || ""}
                                                placeholder="Selecionar"
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </Dialog>

                        {/* HotKeys Dialog */}
                        <Dialog
                            visible={hotKeysDialog}
                            style={{ width: "550px" }}
                            modal
                            className="p-fluid"
                            onHide={hideDialog}
                            header={`Atalhos - Tela de Mensagens Padrão`}
                            draggable={false}
                        >
                            <HotKeys create={"Criar uma nova Mensagem Padrão"} />
                        </Dialog>

                        {/* Delete Attachments Dialog */}
                        <ConfirmDialog
                            style={{ width: "400px", borderRadius: "16px" }}
                            draggable={false}
                            visible={deleteDefaultMessage}
                            onHide={hideDialog}
                            message={deleteMessage}
                            acceptLabel={"Sim"}
                            rejectLabel={"Não"}
                            header="Confirmação de exclusão"
                            icon="pi pi-exclamation-triangle"
                            accept={onDeleteDefaultMessages}
                            acceptIcon="pi pi-check"
                            rejectIcon="pi pi-times"
                            reject={hideDialog}
                            dismissableMask={true}
                            footer={deleteDialogFooter}
                        />
                    </div>
                </div>
            </div>
        </App>
    )
}
