import React, { useRef } from "react"
import "./index.scss"
import api from "../../services/api"
import Moment from "react-moment"
import { Toast } from "primereact/toast"
import { useHistory } from "react-router-dom"

interface DataNotification {
    idNotification?: string
    title?: string
    description?: string
    severity?: string
    exit?: string
    url?: string
    className?: string
    colorIcons?: Data
    created_at?: string
    updateNotification: () => void
}

interface Data {
    color: string
    icon: string
    fontColor: string
    borderColor: string
}

export const Notification = (props: DataNotification) => {
    let toast = useRef<Toast>(null)
    const history = useHistory()

    const onClickCard = (idNotification: string) => {
        try {
            let header = {
                headers: {
                    route: "update_notification",
                },
            }

            api.put("notifications", { id: idNotification }, header)

                .then((res) => {
                    if (res.status === 200) {
                        props.updateNotification()
                    }
                })
                .catch((error) => { })
        } catch (error) {
            console.log(error)
        }
    }

    const onDeleteNotification = async (idNotification: string) => {
        try {
            await api.delete("notifications", {
                headers: {
                    route: "delete_notification",
                },
                data: {
                    id: idNotification,
                },
            })

            props.updateNotification()
        } catch (error) { }

        window.open(`${props.url}`, '_blank');
    }

    return (
        <>
            <Toast ref={toast} />

            <div className="notification-container" onClick={() => onDeleteNotification(props.idNotification !== undefined ? props.idNotification : "")}>
                <div
                    className={`notification-esquadros-card ${props.className}`}
                    style={{ background: `${props.colorIcons?.color}`, borderLeftColor: `${props.colorIcons?.borderColor}` }}
                >
                    <div className="notification-image-esquadros">
                        <i
                            style={{ fontSize: "1.4em", color: `${props.colorIcons?.fontColor}`, paddingLeft: "-2rem" }}
                            className={`pi ${props.colorIcons?.icon}`}
                        ></i>
                    </div>

                    <div className="p-d-flex p-jc-center">
                        <h3 className="notification-title-esquadros" style={{ color: `${props.colorIcons?.fontColor}`, paddingLeft: "2.8rem" }}>
                            &nbsp; <strong>{props.title}</strong>
                        </h3>
                    </div>

                    <br />

                    <p className="notification-message-esquadros" style={{ color: `${props.colorIcons?.fontColor}`, whiteSpace: "pre-wrap" }}>
                        {props.description}
                    </p>

                    <br />

                    <Moment format="DD-MM-YYYY hh:mm">{props.created_at}</Moment>
                </div>
            </div>

            <br />
        </>
    )
}
