import { Avatar } from "primereact/avatar"
import { Button } from "primereact/button"
import { useEffect } from "react"
import { UserInterface } from "../../../../interfaces/UserInterface"

type PropsInterface = {
    informationAboutSellerWhoResponded: UserInterface
    viewEditInformationAboutSeller: boolean

    handleSetNameClassAvatarDialogSeller: (e: string) => void
    handleSetCompaniesOpportunity: () => void
}

export const InformationSellerDialog = ({
    informationAboutSellerWhoResponded,
    viewEditInformationAboutSeller,
    handleSetNameClassAvatarDialogSeller,
    handleSetCompaniesOpportunity,
}: PropsInterface) => {
    const React = require("react")

    const placeHolder = () => {
        const phoneNumber = informationAboutSellerWhoResponded?.whatsapp_phone_number;

        const phoneNumberInfo: Record<string, string> = {
            "551637124102": "Whatsapp: Vendas (16) 3712-4180",
            "551637124180": "Whatsapp: Suprimentos (16) 3712-4180"
        };

        return phoneNumber ? phoneNumberInfo[phoneNumber] || "Informações não disponíveis" : "Informações não disponíveis";
    };


    const handleKeyDown = (event: any) => {
        if (event.key === "Escape") {
            handleSetNameClassAvatarDialogSeller("noShowInformationAboutSellerWhoResponded")
            handleSetCompaniesOpportunity()
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown, true)
    }, [])

    return (
        <div>
            <div className="p-d-flex p-jc-end">
                <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-danger p-button-text p-d-flex p-jc-end"
                    onClick={() => {
                        handleSetNameClassAvatarDialogSeller("noShowInformationAboutSellerWhoResponded")
                        handleSetCompaniesOpportunity()
                    }}
                />
            </div>
            <div>
                <div className="p-d-flex p-jc-center  p-mb-4">
                    <Avatar
                        icon={informationAboutSellerWhoResponded.avatar_url === null ? "pi pi-user" : ""}
                        image={informationAboutSellerWhoResponded.avatar_url}
                        className="p-mr-2 "
                        shape="circle"
                        size="large"
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
                <div className="p-d-flex p-jc-center p-mb-3" style={{ fontSize: "1rem", fontWeight: "500" }}>
                    Nome: {informationAboutSellerWhoResponded.name}
                </div>
                {informationAboutSellerWhoResponded.email !== null && (
                    <div className="p-d-flex p-jc-center p-mb-3" style={{ fontSize: "1rem", fontWeight: "500" }}>
                        Email: {informationAboutSellerWhoResponded.email}
                    </div>
                )}
                {informationAboutSellerWhoResponded.phone !== null && (
                    <div className="p-d-flex p-jc-center p-mb-3" style={{ fontSize: "1rem", fontWeight: "500" }}>
                        Telefone: {informationAboutSellerWhoResponded.phone}
                    </div>
                )}
                {informationAboutSellerWhoResponded.whatsapp_phone_number !== null && (
                    <div className="p-d-flex p-jc-center p-mb-3" style={{ fontSize: "1rem", fontWeight: "500" }}>
                        {placeHolder()}
                    </div>
                )}
                {viewEditInformationAboutSeller && (
                    <div className="p-d-flex p-jc-center">
                        <a href={`/perfil/`} rel="noopener noreferrer" target="_blank">
                            <Button label="Editar informações" aria-label="Submit" />
                        </a>
                    </div>
                )}
            </div>
        </div>
    )
}
