import React, { useRef, useState, useEffect } from "react"
import App from "../../layout/App"
import api from "../../services/api"
import Moment from "react-moment"
import { useHotkeys } from "react-hotkeys-hook"

//PrimeReact
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { ConfirmDialog } from "primereact/confirmdialog"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { Toast } from "primereact/toast"
import { Toolbar } from "primereact/toolbar"

//Components
import { CrmRecords } from "../../components/crm/CrmRecords"

//Interface
import { FunnelInterface } from "../../interfaces/FunnelInterface"
import { FunnelAutomatizationInterface } from "../../interfaces/FunnelAutomatizationInterface"
import { FilterMatchMode, FilterOperator } from "primereact/api"
import { HotKeys } from "../../components/crm/HotKeys"

export const FunnelAutomatization = () => {
    const toast: any = useRef(null)
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    let [automatizations, setAutomatizations] = useState<FunnelAutomatizationInterface[]>([])
    let [funnels, setFunnels] = useState<FunnelInterface[]>([])
    let [automatization, setAutomatization] = useState<FunnelAutomatizationInterface>({} as FunnelAutomatizationInterface)
    let [createValues, setCreateValues] = useState<FunnelAutomatizationInterface>({} as FunnelAutomatizationInterface)

    //HotKeys
    useHotkeys("shift+n", () => setCreateAutomatizationDialog(true))
    useHotkeys("shift+i", () => setHotKeysDialog(true))

    //Loading
    let [loadingList, setLoadingList] = useState<boolean>(true)
    let [loadingEdit, setLoadingEdit] = useState<boolean>(false)
    let [loadingDelete, setLoadingDelete] = useState<boolean>(false)

    //Dialogs
    let [deleteDialog, setDeleteDialog] = useState(false)
    let [createAutomatizationDialog, setCreateAutomatizationDialog] = useState<boolean | undefined>(false)

    let [hotKeysDialog, setHotKeysDialog] = useState(false)

    //Filters
    let [auxFunnelAutomatizationFiltered, setAuxFunnelAutomatizationFiltered] = useState<FunnelAutomatizationInterface[]>([])
    let [globalFilterValue, setGlobalFilterValue] = useState("")
    let [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    })

    //Get Method
    const allFunnels = async () => {
        try {
            let header = {
                headers: {
                    route: "list_funnels",
                },
            }

            await api
                .get("funnels", header)
                .then((res) => {
                    setLoadingList(false)
                    setFunnels(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const allAutomatization = async () => {
        try {
            let header = {
                headers: {
                    route: "list_stages_automations",
                },
            }

            await api
                .get("stages_automations", header)
                .then((res) => {
                    setLoadingList(false)
                    setAutomatizations(res.data)
                    setAuxFunnelAutomatizationFiltered(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar automatizações!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    //OnChange
    const onChangeCreateAutomation = (event: any) => {
        const { value, name } = event.target

        setCreateValues({
            ...createValues,
            [name]: value,
        })
    }

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value
        let _filters = { ...filters }
        _filters["global"].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Automação de Etapas </h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Pesquisar..." />
            </span>
        </div>
    )

    //Button
    const createAutomatizationButton = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-user-plus" className="p-button" onClick={openNew} />
            </React.Fragment>
        )
    }

    const dataTableButtons = (rowData: FunnelAutomatizationInterface) => {
        return (
            <div className="actions">
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => deleteAutomatization(rowData)} />
            </div>
        )
    }

    //Dialog
    const hideDialog = () => {
        setCreateValues({} as FunnelAutomatizationInterface)
        setCreateAutomatizationDialog(false)
        setDeleteDialog(false)
        setHotKeysDialog(false)
    }

    //DataTable fields formater
    const dateFormatCreate = (rowData: FunnelAutomatizationInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.created_at}</Moment>
            </>
        )
    }

    const openNew = () => {
        setCreateAutomatizationDialog(true)
    }

    //Requisitions
    const handleCreateAutomation = (event: any) => {
        setLoadingEdit(true)

        try {
            const { stageOrigin, stageDestin, funnelDestin, funnelOrigin } = createValues
            if (
                stageOrigin === null ||
                stageDestin === null ||
                funnelDestin === null ||
                funnelOrigin === null ||
                stageOrigin === undefined ||
                stageDestin === undefined ||
                funnelDestin === undefined ||
                funnelOrigin === undefined
            ) {
                setLoadingEdit(false)
                toast.current?.show({
                    severity: "warn",
                    summary: "Erro ao cadastrar",
                    detail: "Por favor, verificar os dados preenchidos!",
                    life: 3000,
                })
            } else {
                let header = {
                    headers: {
                        route: "create_stage_automation",
                    },
                }

                let auxAutomationCreate = {
                    origin_stage_id: stageOrigin.id,
                    destination_stage_id: stageDestin.id,
                }

                api.post("stages_automations", auxAutomationCreate, header)
                    .then((res) => {
                        if (res.status === 200) {
                            hideDialog()
                            setCreateValues({} as FunnelAutomatizationInterface)
                            setLoadingEdit(false)
                            setAutomatizations([...automatizations, res.data])
                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso",
                                detail: "Automatização Criada",
                                life: 3000,
                            })
                        } else {
                            setLoadingEdit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao cadastar!",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingEdit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao cadastar!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            }
        } catch (error) {
            setLoadingEdit(false)
            console.log(error)
        }
    }

    const deleteAutomatization = (item: FunnelAutomatizationInterface) => {
        setAutomatization(item)
        setDeleteDialog(true)
    }

    const onDeleteAutomatization = () => {
        setLoadingDelete(true)
        try {
            api.delete("stages_automations", {
                headers: {
                    route: "delete_stage_automation",
                },
                data: {
                    origin_stage_id: automatization.id,
                },
            })

                .then((res) => {
                    setLoadingDelete(false)
                    if (res.status === 200) {
                        let auxDeleteLayout = auxFunnelAutomatizationFiltered.filter((item) => automatization.id !== item.id)
                        automatizations = auxDeleteLayout
                        auxFunnelAutomatizationFiltered = auxDeleteLayout
                        hideDialog()
                        setAutomatizations(auxDeleteLayout)
                        setAuxFunnelAutomatizationFiltered(auxDeleteLayout)
                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na exclusão",
                            detail: "Automação excluída com sucesso!",

                            life: 3000,
                        })
                    } else {
                        setLoadingDelete(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao excluir!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingDelete(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao excluir!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    //Footers
    const deleteDialogFooter = () => {
        return (
            <>
                <Button label="Não" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingDelete} onClick={onDeleteAutomatization} />
            </>
        )
    }

    const deleteMessage = () => {
        return (
            <>
                {" "}
                Deseja realmente excluir a automação: <strong>{automatization.name}</strong>
            </>
        )
    }

    const automatizationDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingEdit} onClick={handleCreateAutomation} />
        </>
    )

    useEffect(() => {
        allAutomatization()
        allFunnels()
    }, [createValues])

    return (
        <App>
            <CrmRecords />

            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="p-mb-4 p-toolbar" left={createAutomatizationButton}></Toolbar>

                        {/* DataTable */}
                        <DataTable
                            value={automatizations}
                            loading={loadingList}
                            stripedRows
                            selectionMode="checkbox"
                            filters={filters}
                            globalFilterFields={["funnel.name", "destination.funnel.name", "destination.name", "name"]}
                            dataKey="id"
                            rows={10}
                            rowsPerPageOptions={[10, 15, 20]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} automações"
                            paginator
                            header={header}
                            emptyMessage="Não há Automações"
                            responsiveLayout="scroll"
                        >
                            <Column field="funnel.name" header="Funil de Origem" sortable align="center" alignHeader="center" />
                            <Column field="name" header="Etapa de Origem" sortable align="center" alignHeader="center" />
                            <Column field="destination.funnel.name" header="Funil de Destino" sortable align="center" alignHeader="center" />
                            <Column field="destination.name" header="Etapa de Destino" sortable align="center" alignHeader="center" />
                            <Column body={dateFormatCreate} header="Data de Criação" sortable sortField="created_at" align="center" alignHeader="center" />
                            <Column align="center" alignHeader="center" body={dataTableButtons}></Column>
                        </DataTable>

                        {/* Create Method */}
                        <Dialog
                            visible={createAutomatizationDialog}
                            breakpoints={{ "960px": "75vw", "640px": "100vw" }}
                            header="Adicionar Automação de Etapa"
                            modal
                            className="p-fluid"
                            footer={automatizationDialogFooter}
                            onHide={hideDialog}
                        >
                            <div className="p-d-flex p-flex-column p-flex-md-row">
                                <div className="p-mb-2 p-mr-6">
                                    <div className="p-field">
                                        <label htmlFor="name">Funil de Origem</label>
                                        <Dropdown
                                            style={{ minWidth: "20rem" }}
                                            id="funnelOrigin"
                                            name="funnelOrigin"
                                            filter={true}
                                            emptyMessage="Não há dados"
                                            emptyFilterMessage="Não há dados"
                                            options={funnels}
                                            value={createValues.funnelOrigin}
                                            onChange={(e) => onChangeCreateAutomation(e)}
                                            optionLabel="name"
                                            placeholder="Selecionar"
                                        />
                                    </div>
                                    {!isEmptyObj(createValues.funnelOrigin) && (
                                        <div className="p-field">
                                            <label htmlFor="name">Etapa de Origem</label>
                                            <Dropdown
                                                style={{ minWidth: "20rem" }}
                                                id="stageOrigin"
                                                name="stageOrigin"
                                                emptyMessage="Não há dados"
                                                emptyFilterMessage="Não há dados"
                                                filter={true}
                                                options={createValues.funnelOrigin.stages}
                                                value={createValues.stageOrigin}
                                                onChange={(e) => onChangeCreateAutomation(e)}
                                                optionLabel="name"
                                                placeholder="Selecionar"
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="p-mb-2 p-mr-6">
                                    {!isEmptyObj(createValues.stageOrigin) && (
                                        <div className="p-field">
                                            <label htmlFor="name">Funil de Destino</label>
                                            <Dropdown
                                                style={{ minWidth: "20rem" }}
                                                id="funnelDestin"
                                                name="funnelDestin"
                                                emptyMessage="Não há dados"
                                                emptyFilterMessage="Não há dados"
                                                filter={true}
                                                options={funnels}
                                                value={createValues.funnelDestin}
                                                onChange={(e) => onChangeCreateAutomation(e)}
                                                optionLabel="name"
                                                placeholder="Selecionar"
                                            />
                                        </div>
                                    )}

                                    {!isEmptyObj(createValues.funnelDestin) && (
                                        <div className="p-field">
                                            <label htmlFor="name">Etapa de Destino</label>
                                            <Dropdown
                                                style={{ minWidth: "20rem" }}
                                                id="stageDestin"
                                                name="stageDestin"
                                                emptyMessage="Não há dados"
                                                emptyFilterMessage="Não há dados"
                                                filter={true}
                                                options={createValues.funnelDestin.stages}
                                                value={createValues.stageDestin}
                                                onChange={(e) => onChangeCreateAutomation(e)}
                                                optionLabel="name"
                                                placeholder="Selecionar"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Dialog>

                        {/* Delete Method */}
                        <ConfirmDialog
                            style={{ width: "400px", borderRadius: "16px" }}
                            draggable={false}
                            visible={deleteDialog}
                            onHide={hideDialog}
                            message={deleteMessage}
                            acceptLabel={"Sim"}
                            rejectLabel={"Não"}
                            header="Confirmação de exclusão"
                            icon="pi pi-exclamation-triangle"
                            accept={onDeleteAutomatization}
                            acceptIcon="pi pi-check"
                            rejectIcon="pi pi-times"
                            reject={hideDialog}
                            dismissableMask={true}
                            footer={deleteDialogFooter}
                        />

                        {/* HotKeys */}
                        <Dialog
                            visible={hotKeysDialog}
                            style={{ width: "450px" }}
                            modal
                            className="p-fluid"
                            onHide={hideDialog}
                            header={`Atalhos - Tela de Interesses`}
                            draggable={false}
                        >
                            <HotKeys create={"Criar uma nova Automação"} />
                        </Dialog>
                    </div>
                </div>
            </div>
        </App>
    )
}
