import { Button } from "primereact/button"
import { Accordion, AccordionTab } from "primereact/accordion"
import React from "react"
import { OpportunityInterface } from "../../../../interfaces/OpportunityInterface"
import { ContactInterface } from "../../../../interfaces/ContactInterface"
import { Card } from "primereact/card"
import { Dropdown } from "primereact/dropdown"
import { InputNumber } from "primereact/inputnumber"
import { OpportunityMachineInterface } from "../../../../interfaces/OpportunityMachineInterface"
import { ScrollPanel } from "primereact/scrollpanel"
import { OpportunityProposalOptionalInterface } from "../../../../interfaces/OpportunityProposalOptionalInterface"
import { PriceTableInterface } from "../../../../interfaces/PriceTableInterface"
import { typeProducts } from "../../../../listsArrays/ListsArrays"
import { OpportunityMachineTotvsInterface } from "../../../../interfaces/OpportunityMachineTotvsInterface"
import { LayoutsProductsInterface } from "../../../../interfaces/LayoutsProductsInterface"
import { Optional } from "../../../crm/machineOptions/Optional"

interface I_TabPanelProducts {
    opportunity: OpportunityInterface
    loadingSyncTotvs: boolean
    priceTable: PriceTableInterface[]
    selectedTablePrice: PriceTableInterface
    typeProduct: { id: string; name: string; code: string }
    machines: OpportunityMachineTotvsInterface[]
    opportunityMachine: OpportunityMachineInterface
    loadingHandleAddProductOnOpportunity: boolean
    listLayoutsProduct: LayoutsProductsInterface[]
    optionalsSelectedMachine: OpportunityProposalOptionalInterface[]
    onchangeAddOptionalMachine: OpportunityProposalOptionalInterface[]
    optional: OpportunityProposalOptionalInterface
    createProposal: any
    machine: OpportunityMachineInterface
    generalDiscountCalculate: number

    syncProtheus: () => void
    setSelectedTablePrice: (e: any) => void
    Machine: (e: any) => void
    allMachines: (e: any) => void
    setTypeProduct: (e: any) => void
    handleAddProductOnOpportunity: (e: any) => void
    onChangeAlloSelectedMachine: (e: any) => void
    handleListLayoutsProduct: (e: any) => void
    setDialogListLayoutsProducts: (e: any) => void
    setMachine: (e: any) => void
    setDialogEditOpportunityMachine: (e: any) => void
    setCreateProposal: (e: any) => void
    setDialogCreateProposalMachineOpportunity: (e: any) => void
    allApresentations: (e: any, i: any) => void
    setConfirmDialogDeletedMachineOpportunity: (e: any) => void
    setGeneralDiscountCalculate: (e: any) => void
    setOnchangeAddOptionalMachine: (e: any) => void
}

const TabPanelProducts = ({
    opportunity,
    loadingSyncTotvs,
    priceTable,
    selectedTablePrice,
    typeProduct,
    machines,
    opportunityMachine,
    loadingHandleAddProductOnOpportunity,
    listLayoutsProduct,
    optionalsSelectedMachine,
    onchangeAddOptionalMachine,
    optional,
    createProposal,
    machine,
    generalDiscountCalculate,

    syncProtheus,
    setSelectedTablePrice,
    Machine,
    allMachines,
    setTypeProduct,
    handleAddProductOnOpportunity,
    onChangeAlloSelectedMachine,
    handleListLayoutsProduct,
    setDialogListLayoutsProducts,
    setMachine,
    setDialogEditOpportunityMachine,
    setCreateProposal,
    setDialogCreateProposalMachineOpportunity,
    allApresentations,
    setGeneralDiscountCalculate,
    setOnchangeAddOptionalMachine,
    setConfirmDialogDeletedMachineOpportunity,
}: I_TabPanelProducts) => {
    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    const totalSumMachineOpportunity = (machine: OpportunityMachineInterface) => {
        let totalMachine: number = 0
        let totalOptional: number = 0
        let total: number = 0

        if (!isEmptyObj(machine.optionals)) {
            totalOptional = totalSumMachineOptionalsDiscountOpportunity(machine)
        }

        totalMachine = totalPriceMachineOpportunity(machine)

        total = totalMachine + totalOptional

        return total.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        })
    }

    const totalPriceMachineOpportunity = (machine: OpportunityMachineInterface) => {
        let total = 0

        total = machine.price * machine.quantity - (machine.price * machine.quantity * machine.discount) / 100

        return total
    }

    const totalPriceOptionalMachineOpportunity = (optional: OpportunityProposalOptionalInterface) => {
        let totalOptional = 0

        if (optional.discount !== undefined) {
            totalOptional = optional.price * optional.quantity - (optional.price * optional.discount) / 100
        } else {
            totalOptional = optional.price * optional.quantity
        }

        return totalOptional
    }

    const totalSumMachineOptionalsDiscountOpportunity = (machine: OpportunityMachineInterface) => {
        let totalOptionals = 0

        machine.optionals.forEach((optional) => {
            if (optional.discount !== undefined) {
                totalOptionals += optional.price * optional.quantity - (optional.price * optional.discount) / 100
            } else {
                totalOptionals += optional.price * optional.quantity
            }
        })

        return totalOptionals
    }

    const totalSumMachineOptionalsPrimaryOpportunity = (machine: OpportunityMachineInterface) => {
        let total = 0
        machine.optionals.forEach((optional) => {
            total += optional.price * optional.quantity
        })

        return total
    }

    const calculateTheDiscountOfEquipament = (rowData: OpportunityMachineInterface) => {
        machine.discount = 0
        let discount
        switch (opportunity.company.state) {
            case "Pará":
            case "Tocantins":
            case "Alagoas":
            case "Bahia":
            case "Ceara":
            case "Maranhão":
            case "Piauí":
            case "Pernambuco":
            case "Paraíba":
            case "Rio Grande do Norte":
            case "Sergipe":
            case "Goiás":
            case "Mato Grosso":
            case "Mato Grosso do Sul":
            case "Distrito Federal":
            case "Espírito Santo":
                discount = rowData.price - (rowData.price - rowData.price * 0.1315 + rowData.price * 0.0915)
                generalDiscountCalculate = parseFloat(((discount * 100) / rowData.price).toFixed(2))
                break
            case "Roraima":
            case "Rondônia":
            case "Amazonas":
            case "Amapá":
            case "Acre":
                discount = rowData.price - (rowData.price - rowData.price * 0.1315 + rowData.price * 0.055)
                generalDiscountCalculate = parseFloat(((discount * 100) / rowData.price).toFixed(2))
                break
        }
        setGeneralDiscountCalculate(generalDiscountCalculate)
    }

    const equipmentsTemplateItems = (rowData: OpportunityMachineTotvsInterface) => {
        return (
            <>
                <span>{rowData.id}</span>
                <span>{rowData.description}</span>
            </>
        )
    }

    const onChangeCreateOpportunityMachineTotvs = (event: any) => {
        let auxOpportunityMachine = { ...opportunityMachine }
        auxOpportunityMachine[`price`] = event.value.price
        auxOpportunityMachine[`description`] = event.value.description
        auxOpportunityMachine[`code`] = event.value.id
        auxOpportunityMachine[`full_description`] = event.value.full_description
        auxOpportunityMachine[`ncm`] = event.value.ncm
        auxOpportunityMachine[`finame`] = event.value.finame
        auxOpportunityMachine[`deadline`] = event.value.deadline
        auxOpportunityMachine[`quantity`] = 1

        Machine(auxOpportunityMachine)
    }

    const totalMachinePriceCreate = () => {
        return opportunityMachine.price * opportunityMachine.quantity + totalMachineOptionalsPriceCreate()
    }

    const totalMachineOptionalsPriceCreate = () => {
        let valueTotal = 0
        if (!isEmptyObj(onchangeAddOptionalMachine)) {
            onchangeAddOptionalMachine.forEach((optional: OpportunityProposalOptionalInterface) => {
                valueTotal += optional.price
            })
        }

        return valueTotal
    }

    const totalGrandOpcional = () => {
        let totalPriceOptional = 0

        if (!isEmptyObj(opportunityMachine.optionals)) {
            opportunityMachine.optionals.forEach((optionalItem) => {
                totalPriceOptional += optionalItem.price * opportunityMachine.quantity
            })
        }

        return totalPriceOptional
    }

    const checkEmailContact = (item: ContactInterface) => {
        if (!isEmptyObj(item.emails) && !isEmptyObj(item.phones)) {
            if (item.emails !== null && item.phones !== null) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const handleDeleteMachineOpportunity = (opportunityMachine: OpportunityMachineInterface) => {
        setMachine(opportunityMachine)
        setConfirmDialogDeletedMachineOpportunity(true)
    }

    return (
        <>
            <Card>
                <div
                    className="p-d-flex p-jc-end"
                    style={{
                        marginBottom: "-1.4rem",
                    }}
                >
                    <Button
                        loading={loadingSyncTotvs}
                        label="Sincronizar com o TOTVS"
                        onClick={() => {
                            syncProtheus()
                        }}
                        icon="pi pi-sync"
                        className="p-button-outlined p-button-info"
                    />
                </div>

                <div className="p-grid">
                    <div className="p-col-12 p-md-6 p-lg-6">
                        <div className="p-field">
                            <label htmlFor="tablePrice">Tabela de Preço:</label>
                            <span style={{ color: "red" }}> * </span>
                            <Dropdown
                                style={{
                                    width: "100%",
                                }}
                                id="tablePrice"
                                name="tablePrice"
                                options={priceTable}
                                disabled={priceTable.length === 0 || opportunity.status === "gain" ? true : false}
                                emptyMessage="Não há dados"
                                emptyFilterMessage="Não há dados"
                                optionLabel="id"
                                required
                                value={selectedTablePrice || ""}
                                onChange={(e: any) => {
                                    setSelectedTablePrice(e.value)
                                    Machine({} as OpportunityMachineInterface)
                                    allMachines(e.value)
                                }}
                                placeholder="Selecione a tabela de preço"
                            />
                        </div>
                    </div>

                    {!isEmptyObj(selectedTablePrice) && (
                        <div className="p-col-12 p-md-6 p-lg-6">
                            <div className="p-field">
                                <label htmlFor="typeProducts">Tipo do Produto:</label>
                                <span style={{ color: "red" }}> * </span>
                                <Dropdown
                                    style={{
                                        width: "100%",
                                    }}
                                    id="typeProducts"
                                    name="type"
                                    options={typeProducts}
                                    emptyMessage="Não há dados"
                                    emptyFilterMessage="Não há dados"
                                    optionLabel="name"
                                    required
                                    value={typeProduct || ""}
                                    onChange={(e: any) => {
                                        setTypeProduct(e.value)
                                        Machine({} as OpportunityMachineInterface)
                                    }}
                                    placeholder="Selecione o tipo"
                                />
                            </div>
                        </div>
                    )}
                </div>

                {typeProduct.id === "558877663322558" && (
                    <form onSubmit={handleAddProductOnOpportunity}>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-4 p-lg-4">
                                <div className="p-field">
                                    <label htmlFor="machineDescription">Equipmento:</label>
                                    <span style={{ color: "red" }}> * </span>
                                    <Dropdown
                                        style={{
                                            width: "100%",
                                        }}
                                        id="machineDescription"
                                        name="machine"
                                        options={machines}
                                        emptyMessage="Não há dados"
                                        emptyFilterMessage="Não há dados"
                                        itemTemplate={(e) => equipmentsTemplateItems(e)}
                                        filter={true}
                                        filterBy="id,description"
                                        value={opportunityMachine.machine}
                                        placeholder={!isEmptyObj(opportunityMachine.description) ? opportunityMachine.description : "Selecione seu equipamento"}
                                        onChange={(e: any) => {
                                            onChangeAlloSelectedMachine(e)
                                            onChangeCreateOpportunityMachineTotvs(e)
                                            handleListLayoutsProduct(e.value.id)
                                        }}
                                    />
                                </div>

                                {!isEmptyObj(opportunityMachine) && (
                                    <>
                                        <div className="p-field">
                                            <label htmlFor="machineQuantity">Quantidade do Equipamento:</label>
                                            <span style={{ color: "red" }}> * </span>
                                            <InputNumber
                                                style={{
                                                    width: "100%",
                                                }}
                                                id="machineQuantity"
                                                name="quantity"
                                                value={opportunityMachine.quantity || 1}
                                                min={1}
                                                onChange={(e) => Machine({ ...opportunityMachine, [`quantity`]: e.value || 0 })}
                                            />
                                        </div>

                                        <div className="p-field">
                                            <label htmlFor="machinePrice">Preço do Equipamento:</label>
                                            <InputNumber
                                                style={{
                                                    width: "100%",
                                                }}
                                                id="machinePrice"
                                                name="price"
                                                value={opportunityMachine.price * opportunityMachine.quantity}
                                                disabled
                                                prefix="R$ "
                                            />
                                        </div>

                                        <div className="p-field">
                                            <label htmlFor="machineTotal">Preço dos Opcionais:</label>
                                            <InputNumber
                                                style={{
                                                    width: "100%",
                                                }}
                                                id="machineTotal"
                                                name="machineTotal"
                                                value={totalMachineOptionalsPriceCreate()}
                                                prefix="R$ "
                                                disabled={true}
                                            />
                                        </div>

                                        <div className="p-field">
                                            <label htmlFor="machineTotal">Total Equipamento + Opcionais:</label>
                                            <InputNumber
                                                style={{
                                                    width: "100%",
                                                }}
                                                id="machineTotal"
                                                name="machineTotal"
                                                value={totalMachinePriceCreate()}
                                                prefix="R$ "
                                                disabled={true}
                                            />
                                        </div>
                                    </>
                                )}

                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6 p-lg-6">
                                        <Button
                                            type="submit"
                                            loading={loadingHandleAddProductOnOpportunity}
                                            style={{ width: "100%" }}
                                            label="Adicionar Equipamento"
                                            className="p-button-success"
                                        />
                                    </div>

                                    <div className="p-col-12 p-md-6 p-lg-6">
                                        <Button
                                            type="button"
                                            style={{ width: "100%" }}
                                            label="Layouts"
                                            className="p-button"
                                            disabled={listLayoutsProduct.length >= 1 ? false : true}
                                            onClick={() => setDialogListLayoutsProducts(true)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-md-8 p-lg-8">
                                {!isEmptyObj(opportunityMachine) && (
                                    <div className="p-field">
                                        <label htmlFor="optionalsSelectedMachine">Opcionais do Equipamento:</label>

                                        <div className="p-col-12">
                                            <Optional
                                                style={{ width: "100%" }}
                                                listStyle={{ maxHeight: "20rem" }}
                                                listOptional={optionalsSelectedMachine}
                                                onChangeOpportunityMachine={(item) => setOnchangeAddOptionalMachine(item)}
                                                valueListOptional={onchangeAddOptionalMachine}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                )}

                {typeProduct.id === "565651658435743" && (
                    <form onSubmit={handleAddProductOnOpportunity}>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6 p-lg-6">
                                <div className="p-field">
                                    <label htmlFor="machineDescription">Equipmento:</label>
                                    <span style={{ color: "red" }}> * </span>
                                    <Dropdown
                                        style={{
                                            width: "100%",
                                        }}
                                        id="machineDescription"
                                        name="machine"
                                        options={machines}
                                        emptyMessage="Não há dados"
                                        emptyFilterMessage="Não há dados"
                                        optionLabel="description"
                                        itemTemplate={(e) => equipmentsTemplateItems(e)}
                                        filter={true}
                                        value={opportunityMachine.machine}
                                        placeholder={!isEmptyObj(opportunityMachine.description) ? opportunityMachine.description : "Selecione seu equipamento"}
                                        onChange={(e: any) => {
                                            onChangeAlloSelectedMachine(e)
                                            onChangeCreateOpportunityMachineTotvs(e)
                                            handleListLayoutsProduct(e.value.id)
                                        }}
                                    />
                                </div>

                                {!isEmptyObj(opportunityMachine) && (
                                    <>
                                        <div className="p-field">
                                            <label htmlFor="machineQuantity">Quantidade:</label>
                                            <span style={{ color: "red" }}> * </span>
                                            <InputNumber
                                                style={{
                                                    width: "100%",
                                                }}
                                                id="machineQuantity"
                                                name="quantity"
                                                value={opportunityMachine.quantity || 1}
                                                min={1}
                                                onChange={(e) => Machine({ ...opportunityMachine, [`quantity`]: e.value || 0 })}
                                            />
                                        </div>

                                        <div className="p-field">
                                            <label htmlFor="optionalTotal">Total Geral:</label>
                                            <InputNumber
                                                style={{
                                                    width: "100%",
                                                }}
                                                id="optionalTotal"
                                                name="optionalTotal"
                                                value={totalGrandOpcional()}
                                                prefix="R$ "
                                                disabled={true}
                                            />
                                        </div>
                                    </>
                                )}

                                <div className="p-grid">
                                    <div className="p-col-12 p-md-5 p-lg-4">
                                        <Button
                                            type="submit"
                                            style={{ width: "100%" }}
                                            loading={loadingHandleAddProductOnOpportunity}
                                            label="Adicionar Equipamento"
                                            className="p-button-success"
                                        />
                                    </div>

                                    <div className="p-col-12 p-md-5 p-lg-4">
                                        <Button
                                            type="button"
                                            style={{ width: "100%" }}
                                            label="Layouts"
                                            className="p-button"
                                            disabled={listLayoutsProduct.length >= 1 ? false : true}
                                            onClick={() => setDialogListLayoutsProducts(true)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-md-6 p-lg-6">
                                {!isEmptyObj(opportunityMachine) && (
                                    <>
                                        <div className="p-field">
                                            <label htmlFor="optionalsSelectedMachine">Opcional do Equipamento:</label>
                                            <span style={{ color: "red" }}> * </span>
                                            <br />
                                            <Dropdown
                                                style={{
                                                    width: "100%",
                                                }}
                                                value={!isEmptyObj(opportunityMachine.optionals) ? opportunityMachine.optionals[0] : 0}
                                                options={optionalsSelectedMachine}
                                                emptyMessage="Não há dados"
                                                emptyFilterMessage="Não há dados"
                                                optionLabel="description"
                                                placeholder="Selecione o Opcional"
                                                filter={true}
                                                onChange={(e) =>
                                                    Machine({
                                                        ...opportunityMachine,
                                                        [`optionals`]: [e.value],
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="p-field">
                                            <label htmlFor="optionalTotal">Valor do Opcional:</label>
                                            <InputNumber
                                                style={{
                                                    width: "100%",
                                                }}
                                                id="optionalTotal"
                                                name="optionalTotal"
                                                value={!isEmptyObj(opportunityMachine.optionals) ? opportunityMachine.optionals[0].price : 0}
                                                prefix="R$ "
                                                disabled={true}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </form>
                )}
            </Card>

            <br />

            <Card>
                <div className="p-grid">
                    <h3> &nbsp;&nbsp; Produtos</h3>
                    {opportunity.machines.map((machine: OpportunityMachineInterface, indexMachine: number) => (
                        <div className="p-col-12 p-md-12 p-lg-12" key={indexMachine}>
                            <Accordion>
                                <AccordionTab
                                    header={
                                        <>
                                            <div className="p-d-flex">
                                                <div className="p-mr-2"></div>

                                                <div className="p-mr-4">
                                                    <i className={machine.type === "machine" ? `pi pi-briefcase` : `pi pi-box`}></i>
                                                </div>

                                                <div className="p-mr-4">
                                                    <span>
                                                        <strong>{machine.description}</strong>
                                                    </span>
                                                </div>

                                                <div className="p-mr-4">
                                                    <span>
                                                        <strong>{totalSumMachineOpportunity(machine)}</strong>
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    }
                                >
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6 p-lg-6">
                                            <h5>{machine.type === "machine" ? `Equipamento` : `Opcional`}</h5>

                                            <div
                                                style={{
                                                    marginLeft: "1rem",
                                                }}
                                            >
                                                <span>
                                                    <strong>Nome - </strong>
                                                    {machine.description}
                                                </span>

                                                <br />
                                                <br />

                                                <span>
                                                    <strong>Descrição - </strong>
                                                    {machine.full_description}
                                                </span>

                                                <br />
                                                <br />

                                                <span>
                                                    <strong>Quantidade - </strong>
                                                    {machine.quantity}
                                                </span>

                                                <br />
                                                <br />

                                                <span>
                                                    <strong>Ncm - </strong>
                                                    {machine.ncm}
                                                </span>

                                                <br />
                                                <br />

                                                <span>
                                                    <strong>Dias de Entrega - </strong>
                                                    {machine.deadline}
                                                </span>

                                                <br />
                                                <br />

                                                <div className="p-mb-1 p-mr-1">
                                                    <strong>Desconto - </strong>
                                                    <span>{((machine.price * machine.discount) / 100).toFixed(2)}</span>
                                                </div>

                                                <br />
                                                <br />

                                                <span>
                                                    <strong>Preço Unitario - </strong>
                                                    {machine.price.toLocaleString("pt-br", {
                                                        style: "currency",
                                                        currency: "BRL",
                                                    })}
                                                </span>

                                                <br />
                                                <br />

                                                {machine.discount_type !== "value" && (
                                                    <>
                                                        <span>
                                                            <strong>Preço Desconto - </strong>
                                                            {Math.round((machine.price * machine.discount) / 100).toLocaleString("pt-br", {
                                                                style: "currency",
                                                                currency: "BRL",
                                                            })}
                                                        </span>

                                                        <br />
                                                        <br />
                                                    </>
                                                )}

                                                <span>
                                                    <strong>Total Equipamento - </strong>
                                                    {totalPriceMachineOpportunity(machine).toLocaleString("pt-br", {
                                                        style: "currency",
                                                        currency: "BRL",
                                                    })}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-md-6 p-lg-6">
                                            <h5>{machine.type === "machine" ? (!isEmptyObj(machine.optionals) ? `Opcionais` : ``) : ``}</h5>

                                            <ScrollPanel
                                                className="custombar2"
                                                style={{
                                                    height: "23rem",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        marginLeft: "1rem",
                                                    }}
                                                >
                                                    {machine.optionals.map((optional: OpportunityProposalOptionalInterface, indexOptional: number) => (
                                                        <div key={indexOptional}>
                                                            <div className="p-d-flex p-flex-column">
                                                                <div className="p-mb-1 p-mr-1">
                                                                    <strong>Nome - </strong>
                                                                    <span>{optional.description}</span>
                                                                </div>

                                                                <div className="p-mb-1 p-mr-1">
                                                                    <strong>Quantidade - </strong>
                                                                    <span>{optional.quantity}</span>
                                                                </div>

                                                                <div className="p-mb-1 p-mr-1">
                                                                    <strong>Desconto - </strong>
                                                                    <span>{((optional.price * optional.discount) / 100).toFixed(2)}</span>
                                                                </div>

                                                                <div className="p-mb-1 p-mr-1">
                                                                    <strong>Preço Unitario - </strong>
                                                                    <span>
                                                                        {optional.price.toLocaleString("pt-br", {
                                                                            style: "currency",
                                                                            currency: "BRL",
                                                                        })}
                                                                    </span>
                                                                </div>

                                                                {optional.discount_type !== "value" && (
                                                                    <div className="p-mb-1 p-mr-1">
                                                                        <strong>Preço Desconto - </strong>
                                                                        <span>
                                                                            {Math.round((optional.price * optional.discount) / 100).toLocaleString("pt-br", {
                                                                                style: "currency",
                                                                                currency: "BRL",
                                                                            })}
                                                                        </span>
                                                                    </div>
                                                                )}

                                                                <div className="p-mb-1 p-mr-1">
                                                                    <strong>Preço Total- </strong>
                                                                    <span>
                                                                        {totalPriceOptionalMachineOpportunity(optional).toLocaleString("pt-br", {
                                                                            style: "currency",
                                                                            currency: "BRL",
                                                                        })}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                        </div>
                                                    ))}
                                                </div>
                                            </ScrollPanel>

                                            {!isEmptyObj(machine.optionals) && (
                                                <>
                                                    <br />

                                                    <div style={{ marginLeft: "1rem" }}>
                                                        <span>
                                                            <strong>Preço Opcionais - </strong>
                                                            {totalSumMachineOptionalsPrimaryOpportunity(machine).toLocaleString("pt-br", {
                                                                style: "currency",
                                                                currency: "BRL",
                                                            })}
                                                        </span>

                                                        <br />
                                                        <br />

                                                        <span>
                                                            <strong>Preço Desconto - </strong>
                                                            {(
                                                                totalSumMachineOptionalsPrimaryOpportunity(machine) -
                                                                totalSumMachineOptionalsDiscountOpportunity(machine)
                                                            ).toLocaleString("pt-br", {
                                                                style: "currency",
                                                                currency: "BRL",
                                                            })}
                                                        </span>

                                                        <br />
                                                        <br />

                                                        <span>
                                                            <strong>Total Opcionais - </strong>
                                                            {totalSumMachineOptionalsDiscountOpportunity(machine).toLocaleString("pt-br", {
                                                                style: "currency",
                                                                currency: "BRL",
                                                            })}
                                                        </span>
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        <div className="p-col-12">
                                            <div
                                                className="p-grid"
                                                style={{
                                                    marginLeft: "1rem",
                                                }}
                                            >
                                                <div className="p-col-12 p-md-2 p-lg-2">
                                                    <Button
                                                        style={{ width: "100%" }}
                                                        disabled={opportunity.status === "gain" ? true : false}
                                                        label="Editar"
                                                        icon="pi pi-pencil"
                                                        onClick={() => {
                                                            setMachine(machine)
                                                            calculateTheDiscountOfEquipament(machine)
                                                            setDialogEditOpportunityMachine(true)
                                                        }}
                                                    />
                                                </div>

                                                <div className="p-col-12 p-md-2 p-lg-2">
                                                    <Button
                                                        style={{ width: "100%" }}
                                                        disabled={opportunity.status === "gain" ? true : false}
                                                        className="p-button-secondary"
                                                        label="Criar Proposta"
                                                        icon="pi pi-credit-card"
                                                        onClick={() => {
                                                            createProposal.payment_method = "30% SINAL + LIBERACAO PARA RETIRADA OU SINAL + FINAME EVENTOS"

                                                            createProposal.contact_id = opportunity.company.contacts.filter(checkEmailContact)[0]
                                                            setCreateProposal({
                                                                ...createProposal,
                                                                [`contact_id`]: opportunity.company.contacts.filter(checkEmailContact)[0],
                                                            })
                                                            setDialogCreateProposalMachineOpportunity(true)
                                                            setMachine(machine)
                                                            allApresentations(machine.price_table, machine.code)
                                                        }}
                                                    />
                                                </div>

                                                <div className="p-col-12 p-md-2 p-lg-2">
                                                    <Button
                                                        style={{ width: "100%" }}
                                                        disabled={opportunity.status === "gain" ? true : false}
                                                        className="p-button-danger"
                                                        label="Excluir"
                                                        icon="pi pi-times"
                                                        onClick={() => handleDeleteMachineOpportunity(machine)}
                                                    />
                                                </div>

                                                <div className="p-col-12 p-md-2 p-lg-2">
                                                    <Button
                                                        type="button"
                                                        disabled={opportunity.status === "gain" ? true : false}
                                                        style={{ width: "100%" }}
                                                        label="Layouts"
                                                        className="p-button"
                                                        icon="pi pi-external-link"
                                                        onClick={() => {
                                                            setDialogListLayoutsProducts(true)
                                                            handleListLayoutsProduct(machine.code)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionTab>
                            </Accordion>
                        </div>
                    ))}
                </div>
            </Card>
        </>
    )
}

export default React.memo(TabPanelProducts)