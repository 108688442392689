
import React from "react"

//PrimeReact
import { Avatar } from "primereact/avatar"
import { Button } from "primereact/button"
import { Rating } from "primereact/rating"
import { useEffect } from "react"
export const ClientInformation = (props: any) => {
    let noPictureURL = "/assets/pages/icons/no-avatar.png"

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    //Edit Visualization
    const colorStatusOpportunity = (rowData: string) => {
        const statusColors: any = {
            open: "#03A9F4",
            gain: "#689F38",
            loss: "#D32F2F",
            stopped: "#FBC02D",
            deleted: "#607D8B",
        }

        return statusColors[rowData]
    }

    const replaceQualification = (qualification: number): string => {
        const qualificationMap: Record<number, string> = {
            1: "baixa",
            2: "média baixa",
            3: "média",
            4: "média alta",
            5: "alta",
        }

        return qualificationMap[qualification]
    }

    const colorSelectedCard = (companyName: any) => {
        if (!isEmptyObj(props.conversationSelected)) {
            if (props.conversationSelected.id === companyName.id) {
                return "#f0f2f5"
            } else {
                return ""
            }
        } else {
            return ""
        }
    }

    const replaceStatus = (status: string): string => {
        const statusMap: Record<string, string> = {
            open: "ABERTA",
            gain: "GANHA",
            loss: "PERDIDA",
            stopped: "PAUSADA",
            deleted: "EXCLUÍDA",
            disqualified: "DESQUALIFICADA",
        }

        return statusMap[status] || ""
    }

    const getAvatar = (): string => {
        const selectedConversation = props.conversationSelected
        const companyPictureUrl = selectedConversation?.contact?.company?.picture_url
        const profilePictureUrl = selectedConversation?.profile_picture_url

        if (profilePictureUrl) {
            return profilePictureUrl
        }

        if (companyPictureUrl) {
            return companyPictureUrl
        }

        return noPictureURL
    }

    const handleKeyDown = (event: any) => {
        if (event.key === "Escape") {
            props.setNameClassAvatarDialog("noDialogShowClientInformation")
            props.setCompaniesOpportunity([])
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown, true)
    }, [])

    return (
        <>
            <div className="dialogShowClientInformation" style={{ padding: "2rem 2rem 2rem 2rem" }}>
                <div className="p-d-flex p-jc-end">
                    <Button
                        icon="pi pi-times"
                        className="p-button-rounded p-button-danger p-button-text p-d-flex p-jc-end"
                        onClick={() => {
                            props.setNameClassAvatarDialog("noDialogShowClientInformation")
                            props.setCompaniesOpportunity([])
                        }}
                    />
                </div>
                <div className="avatarDialogInformation p-d-flex p-jc-center  p-mb-4">
                    <Avatar
                        image={getAvatar()}
                        onClick={() => {
                            if (getAvatar() !== noPictureURL) {
                                window.open(getAvatar())
                            }
                        }}
                        className="p-mr-2 "
                        shape="circle"
                        size="large"
                        style={{ width: "200px", height: "200px", cursor: getAvatar() !== noPictureURL ? "pointer" : "default" }}
                    />
                </div>
                <div className="p-d-flex p-jc-center p-mb-3" style={{ fontSize: "1.6rem", fontWeight: "600", textAlign: "center" }}>
                    {!isEmptyObj(props.conversationSelected.contact) ? (
                        <>{props.conversationSelected.contact.name}</>
                    ) : (
                        <>
                            <br />+ {props.conversationSelected.phone_number}
                        </>
                    )}
                </div>
                <div className="p-d-flex p-jc-center p-mb-3" style={{ fontSize: "1rem", fontWeight: "500", textAlign: "center" }}>
                    {!isEmptyObj(props.conversationSelected.contact) ? <>+{props.conversationSelected.phone_number}</> : <></>}
                </div>
                <br />
                {!isEmptyObj(props.conversationSelected.contact) && props.conversationSelected.contact.company.address !== null && (
                    <div style={{ fontSize: "0.9rem", fontWeight: "500", textAlign: "left" }}>
                        {!isEmptyObj(props.conversationSelected.contact) ? (
                            <>
                                <strong> EMPRESA: </strong>
                                {props.conversationSelected.contact.company.name}
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                )}
                <br />
                {!isEmptyObj(props.conversationSelected.contact) && props.conversationSelected.contact.job !== null && (
                    <div className="p-d-flex" style={{ fontSize: "0.9rem", fontWeight: "500", textAlign: "left" }}>
                        {!isEmptyObj(props.conversationSelected.contact) ? (
                            <>
                                <strong> CARGO: </strong>
                                {props.conversationSelected.contact.job}
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                )}
                <br />
                {props.companyInformation?.type !== null && (
                    <div style={{ fontSize: "0.9rem", fontWeight: "500", textAlign: "left" }}>
                        {!isEmptyObj(props.conversationSelected.contact) ? (
                            <>
                                {" "}
                                <strong> TIPO: </strong>
                                {props.conversationSelected.contact.company.type}
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                )}
                <br />
                {props.companyInformation?.cnpj !== null && (
                    <div style={{ fontSize: "0.9rem", fontWeight: "500", textAlign: "left" }}>
                        {!isEmptyObj(props.conversationSelected.contact) ? (
                            <>
                                <strong> CNPJ:</strong>

                                {props.conversationSelected.contact.company.cnpj}
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                )}
                <br />
                {!isEmptyObj(props.conversationSelected.contact) &&
                    props.conversationSelected.contact.company.address !== null &&
                    props.conversationSelected.contact.company.city !== null &&
                    props.conversationSelected.contact.company.state !== null && (
                        <div
                            style={{
                                fontSize: "0.9rem",
                                fontWeight: "500",
                                textTransform: "uppercase",
                                textAlign: "left",
                            }}
                        >
                            <strong> Endereço: </strong> <br /> {props.conversationSelected.contact.company.address} -{" "}
                            {props.conversationSelected.contact.company.city}, {props.conversationSelected.contact.company.state}
                        </div>
                    )}
                <br />
                {!isEmptyObj(props.conversationSelected.contact) && props.conversationSelected.contact.company.main_activity_description !== null && (
                    <div
                        style={{
                            fontSize: "0.9rem",
                            fontWeight: "500",
                            textTransform: "uppercase",
                            textAlign: "left",
                        }}
                    >
                        <strong> ATIVIDADE PRINCIPAL: </strong>

                        {props.conversationSelected.contact.company.main_activity_description}
                    </div>
                )}{" "}
                <br /> <br />
                <div className="scrollShowClientInformation">
                    {props.companiesOpportunity.map((opportunities: any) => {
                        let linkOpportunities = `/crm/leads/${opportunities.id}`
                        let statusClass = ""
                        let name = ""
                        switch (opportunities.late) {
                            case false:
                                statusClass = "instock"
                                name = "Em dia"
                                break

                            case true:
                                statusClass = "outofstock"
                                name = `HÁ ${Math.round(opportunities.delayedDays)} DIA(S)`
                                break
                        }

                        return (
                            <div
                                className="oportunitiesCompany"
                                style={{
                                    backgroundColor: `${colorSelectedCard(opportunities)}`,
                                    border: "solid 1px #e6e6e6",
                                }}
                            >
                                <div className="p-grid">
                                    <div className="p-col-10 p-md-10 p-lg-10">
                                        <div className="p-d-flex p-ai-center" style={{ userSelect: "none" }}>
                                            <Avatar
                                                icon={opportunities.user.avatar_url === null ? "pi pi-user" : ""}
                                                image={opportunities.user.avatar_url}
                                                className="p-mr-2 "
                                                shape="circle"
                                                size="large"
                                            />
                                            <div>
                                                <a href={linkOpportunities} target="_blank" rel="noreferrer">
                                                    <p
                                                        style={{
                                                            fontWeight: "600",
                                                            fontSize: "1.1rem",
                                                            color: "rgba(41, 50, 65, 0.8)",
                                                        }}
                                                    >
                                                        {opportunities.number} - {opportunities.name} -{" "}
                                                        {opportunities.totalValueMachines.toLocaleString("pt-br", {
                                                            style: "currency",
                                                            currency: "BRL",
                                                        })}
                                                    </p>
                                                </a>
                                                <div>
                                                    <Rating
                                                        value={opportunities.qualification}
                                                        cancel={false}
                                                        tooltip={replaceQualification(opportunities.qualification)?.toUpperCase()}
                                                        onChange={(e: any) => {}}
                                                    />
                                                </div>
                                                <div style={{ fontSize: "0.9rem", color: "#444445" }}>
                                                    <p className="textMessageClient p-text-nowrap p-text-truncate" style={{}}>
                                                        <span className={`product-badge status-${statusClass}`}>{name}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-2 p-md-2 p-lg-2 p-d-flex p-ai-center p-jc-end">
                                        <div style={{ color: `${colorStatusOpportunity(opportunities.status)}` }}>{replaceStatus(opportunities.status)}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}
