
import React, { useRef, useState, useEffect } from "react"
import * as yup from "yup"
import api from "../../services/api"
import App from "../../layout/App"
import ReactTooltip from "react-tooltip"
import { useHotkeys } from "react-hotkeys-hook"
import { v4 as uuidv4 } from "uuid"

//PrimeReact Components
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { Chip } from "primereact/chip"
import { Column } from "primereact/column"
import { ConfirmDialog } from "primereact/confirmdialog"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { MultiSelect } from "primereact/multiselect"
import { Toast } from "primereact/toast"

//Components
import { Contact as ContactComponent } from "../../components/crm/forms/Contact"
import { CrmRecords } from "../../components/crm/CrmRecords"
import { DDICountries } from "../../listsArrays/ListsArrays"

//Interface
import { ContactInterface } from "../../interfaces/ContactInterface"
import { FunnelInterface } from "../../interfaces/FunnelInterface"
import { OpportunityInterface } from "../../interfaces/OpportunityInterface"

//Styles
import "../../styles/Contact.scss"
import { useParams } from "react-router-dom"
import { CompanyOpportunity } from "../../components/crm/opportunity/CompanyOpportunity"
import { CompanyInterface } from "../../interfaces/CompanyInterface"

export const Contact = () => {
    let toast = useRef<Toast>(null)
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`
    let { param }: any = useParams()

    let [contacts, setContacts] = useState<ContactInterface[]>([])
    let [auxContactsFiltered, setAuxContactsFiltered] = useState<ContactInterface[]>([])

    //Loading
    let [loadingList, setLoadingList] = useState<boolean>(true)
    let [loadingDelete, setLoadingDelete] = useState<boolean>(false)
    let [loadingCleanFilters, setLoadingCleanFilters] = useState(false)
    let [loadingCreateContact, setLoadingContact] = useState(false)

    //Dialog
    let [companyOpportunityDialog, setCompanyOpportunityDialog] = useState(false)
    let [deleteDialog, setDeleteDialog] = useState(false)
    let [dialogContact, setDialogContact] = useState(false)
    let [displayBasicMobile, setDisplayBasicMobile] = useState(false)
    let [egoiDialog, setEgoiDialog] = useState(false)

    //HotKeys
    let [hotKeysDialog, setHotKeysDialog] = useState(false)
    useHotkeys("shift+i", () => setHotKeysDialog(true))
    useHotkeys("shift+f", () => autoFocusAndCleanInputSearchBar())

    let [companyList, setCompanyList] = useState<any[]>([])
    let [selectedCompany, setSelectedCompany] = useState<any[]>([])
    let [advancedSearchFilter, setAdvancedSearchFilter] = useState("")

    let [dateFinal, setDateFinal] = useState<any>(new Date())
    let [dateInit, setDateInit] = useState<any>("")
    let [listOfStatus, setListOfStatus] = useState<any[]>([])
    let [selectedStatusFilter, setSelectedStatusFilter] = useState<any[]>([])

    let [newEmailField, setNewEmailField] = useState([{ id: uuidv4(), emails: "", name: "email" }])
    let [newPhoneField, setNewPhoneField] = useState([{ id: uuidv4(), telephone: "", name: "phone", ddi: { ddi: "+055", country: "Brasil" } }])
    let [formContact, setFormContact] = useState<ContactInterface>({} as ContactInterface)
    let [contact, setContact] = useState<ContactInterface>({} as ContactInterface)

    //Opportunities
    let [opportunities, setOpportunities] = useState<OpportunityInterface[]>([])
    let [companiesOpportunity, setCompaniesOpportunity] = useState<OpportunityInterface[]>([])
    let [funnels, setFunnels] = useState<FunnelInterface[]>([])
    let [funnel, setFunnel] = useState<FunnelInterface>({} as FunnelInterface)
    let [loadingOppotunity, setLoadingOppotunity] = useState<boolean>(false)

    const header = (
        <div>
            {/* Desktop */}
            <div className="desktop-opportunity-filter-menu">
                <div className="p-d-flex p-jc-between">
                    <h5 className="">Contatos </h5>
                    <div className="p-d-flex p-jc-end">
                        <span className="p-input-icon-left p-mr-2">
                            <i className="pi pi-search" />
                            <InputText
                                id="autofocs"
                                className="searchFilter"
                                placeholder="PESQUISAR..."
                                value={advancedSearchFilter}
                                onKeyUp={() => (advancedSearchFilter === "" ? filter() : "" || advancedSearchFilter !== "" ? filter() : "")}
                                onChange={(e) => {
                                    setAdvancedSearchFilter(e.target.value)
                                    filter()
                                }}
                            />
                        </span>
                    </div>
                </div>
            </div>
            {/* Mobile */}
            <div className="mobile-opportunity-filter-menu">
                <div className="p-d-flex">
                    <div className="table-header">
                        <h5 className="p-mb-2">Contatos</h5>
                        <span className="p-input-icon-left p-mr-2">
                            <i className="pi pi-search" />
                            <InputText
                                className="searchFilter"
                                placeholder="PESQUISAR..."
                                value={advancedSearchFilter}
                                onKeyUp={() => (advancedSearchFilter === "" ? filter() : "" || advancedSearchFilter !== "" ? filter() : "")}
                                onChange={(e) => {
                                    setAdvancedSearchFilter(e.target.value)
                                    filter()
                                }}
                            />
                        </span>

                        <i className="iconForMobileFilter pi pi-filter" style={{ fontSize: "1.5em" }} onClick={() => setDisplayBasicMobile(true)}></i>
                    </div>
                </div>
            </div>
        </div>
    )

    const onLoadingCleanFilters = () => {
        companyList = []
        setCompanyList(companyList)
        advancedSearchFilter = ""
        setAdvancedSearchFilter(advancedSearchFilter)
        dateInit = ""
        setDateInit(dateInit)
        dateFinal = new Date()
        setDateFinal(dateFinal)
        selectedCompany = []
        setSelectedCompany(selectedCompany)
        selectedStatusFilter = []
        setSelectedStatusFilter(selectedStatusFilter)
        loadingCleanFilters = true
        setLoadingCleanFilters(loadingCleanFilters)
        filter()
        setTimeout(() => {
            setLoadingCleanFilters(false)
        }, 1000)
    }

    //Requisitions
    const allContacts = () => {
        try {
            let header = {
                headers: {
                    route: "list_contacts",
                },
            }

            api.get("contacts", header)
                .then((res) => {
                    setLoadingList(false)
                    contacts = res.data
                    setContacts(contacts)
                    auxContactsFiltered = res.data
                    setAuxContactsFiltered(auxContactsFiltered)
                    paramSearch()
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const allOpportunity = async (companyParam: CompanyInterface): Promise<void> => {
        opportunities = []
        setOpportunities([])

        try {
            const header = {
                headers: {
                    route: "list_company_opportunity",
                },
            }

            const response = await api.get(`companies/opportunities/${companyParam.id}`, header)
            const data = response.data
            opportunities = data
            setOpportunities(opportunities)
            setCompaniesOpportunity(opportunities)
        } catch (error: any) {
            console.error(error)
            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar!",
                detail: error.response?.data?.message || "Erro desconhecido",
                life: 3000,
            })
        }
    }

    const allFunnels = async () => {
        if (funnels.length === 0) {
            try {
                let header = {
                    headers: {
                        route: "list_funnels",
                    },
                }

                const { data: funnels } = await api.get("funnels", header)
                setFunnels(funnels)
                let funnel = funnels.find((item: any) => item.is_default)
                if (funnel) setFunnel(funnel)
            } catch (error: any) {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao listar!",
                    detail: error.response.data.message,
                    life: 3000,
                })
            }
        }
    }

    const updateContacts = (item: any) => {
        setContacts([...contacts, item])
    }

    const handleEditContact = async (event: any) => {
        event.preventDefault()
        let formContact: ContactInterface = handleCreateContactOpportunity()

        if (!(await validateCreateContact())) {
            toast.current?.show({
                severity: "error",
                summary: "Dados Invalidos!",
                detail: "Preencha todos os dados obrigatorios!",
                life: 6000,
            })
        } else {
            setLoadingContact(true)

            if (formContact.job === undefined || formContact.job === "") {
                formContact.job = null
            }

            if (isEmptyObj(formContact.phones)) {
                formContact.phones = []
            }

            if (isEmptyObj(formContact.emails)) {
                formContact.emails = []
            }

            delete formContact.company
            formContact.id = contact.id

            let header = {
                headers: {
                    route: "create_contact",
                },
            }
            api.put("contacts", formContact, header)
                .then((res) => {
                    if (res.status === 200) {
                        let _contacts = [...auxContactsFiltered]
                        let _contact = res.data
                        let index = findIndexById(res.data.id)
                        _contacts[index] = _contact

                        contacts = _contacts
                        auxContactsFiltered = _contacts

                        setContacts(_contacts)
                        setAuxContactsFiltered(_contacts)
                        filter()
                        hideDialog()

                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "Contato cadastrado com Sucesso",
                            life: 3000,
                        })
                    } else {
                        setLoadingContact(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao cadastar o contato!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingContact(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao cadastar o contato!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        }
    }

    const handleUpdateContact = (item: ContactInterface) => {
        setFormContact(item)
    }

    const handleUpdateFieldEmail = (item: any) => {
        setNewEmailField(item)
    }

    const handleUpdateFieldPhone = (item: any) => {
        setNewPhoneField(item)
    }

    const onClickEditContact = (contact: ContactInterface) => {
        setContact(contact)
        setDialogContact(true)
        let auxEmail: any = []
        let auxPhone: any = []

        if (contact.emails !== null) {
            contact.emails.forEach((email: any) => {
                if (!isEmptyObj(email)) {
                    auxEmail.push({
                        id: email.id,
                        emails: email.email,
                        name: "email",
                    })
                }
            })
        }

        if (contact.phones !== null) {
            contact.phones.forEach((phone: any) => {
                if (!isEmptyObj(phone)) {
                    let auxPhoneNumber = phone.number.split("-", 2)
                    auxPhone.push({
                        id: phone.id,
                        telephone: auxPhoneNumber[1],
                        name: "phone",
                        ddi: DDICountries.filter((a: any) => {
                            return a.ddi === `+${auxPhoneNumber[0]}`
                        })[0],
                    })
                }
            })
        }

        setNewEmailField(auxEmail)
        setNewPhoneField(auxPhone)

        if (isEmptyObj(contact.phones)) {
            let auxPhone = []

            auxPhone.push({
                id: uuidv4(),
                telephone: "",
                name: "phone",
                ddi: { ddi: "+055", country: "Brasil" },
            })

            setNewPhoneField(auxPhone)
        }

        if (isEmptyObj(contact.emails)) {
            let auxEmail = []

            auxEmail.push({
                id: uuidv4(),
                emails: "",
                name: "email",
            })

            setNewEmailField(auxEmail)
        }
    }

    const deleteContact = (item: ContactInterface) => {
        setContact(item)
        setDeleteDialog(true)
    }

    const onDeleteContact = () => {
        setLoadingDelete(true)
        try {
            api.delete("contacts", {
                headers: {
                    route: "delete_contact",
                },
                data: {
                    id: contact.id,
                },
            })
                .then((res) => {
                    setLoadingDelete(false)
                    if (res.status === 200) {
                        let auxDeleteContact = auxContactsFiltered.filter((item) => contact.id !== item.id)
                        contacts = auxDeleteContact
                        auxContactsFiltered = auxDeleteContact
                        hideDialog()
                        setContacts(auxDeleteContact)
                        setAuxContactsFiltered(auxDeleteContact)
                        filter()

                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na exclusão",
                            detail: "Contato excluído com sucesso!",
                            life: 3000,
                        })
                    } else {
                        setLoadingDelete(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao excluir!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingDelete(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao excluir!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const sendContactEgoi = (item: ContactInterface) => {
        setContact(item)
        setEgoiDialog(true)
    }

    const onSendContactEgoi = async () => {
        setLoadingDelete(true)
        try {
            let header = {
                headers: {
                    route: "send_contact_egoi",
                },
            }

            let auxContactId = {
                id: contact.id,
            }

            await api
                .post("contacts/egoi", auxContactId, header)
                .then((res) => {
                    setLoadingDelete(false)
                    if (res.status === 200) {
                        let _contacts = [...contacts]
                        let _contact = { ...res.data }
                        hideDialog()

                        const index = findIndexById(res.data.id)
                        _contacts[index] = _contact
                        setContacts(_contacts)
                        setLoadingDelete(false)
                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso!",
                            detail: "Contato enviado!",
                            life: 3000,
                        })
                    } else {
                        setLoadingDelete(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao excluir!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingDelete(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao excluir!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleCreateContactOpportunity = () => {
        let auxFieldEmail: any = []
        let auxFieldPhones: any = []

        newEmailField.forEach((auxNewEmailField) => {
            auxFieldEmail.push({
                email: auxNewEmailField.emails,
            })
        })

        newPhoneField.forEach((auxNewContactField) => {
            if (auxNewContactField.telephone !== "") {
                auxFieldPhones.push({
                    number:
                        auxNewContactField.ddi.ddi.replaceAll("+", "") +
                        "-" +
                        auxNewContactField.telephone.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replaceAll("+", ""),
                })
            }
        })

        let auxCreateContact: ContactInterface = {
            name: formContact.name,
            job: formContact.job !== "" ? formContact.job : null,
            company_id: formContact.company !== undefined ? formContact.company.id : "",
            authorization: "LI",
            authorization_status: true,
            phones: auxFieldPhones,
            emails: auxFieldEmail,
            company: formContact.company,
        }

        return auxCreateContact
    }

    async function validateCreateContact() {
        let schema = yup.object().shape({
            name: yup.string().required(),
            company: yup.object().required(),
        })

        try {
            await schema.validate(formContact)
            return true
        } catch (error) {
            return false
        }
    }

    const findIndexById = (id: string) => {
        let index = -1
        for (let i = 0; i < auxContactsFiltered.length; i++) {
            if (auxContactsFiltered[i].id === id) {
                index = i
                break
            }
        }

        return index
    }

    const textMenssageEgoi = () => {
        return (
            <>
                <h4>
                    <strong>Confirmação de Envio: {contact.name}</strong>
                </h4>
                <h5>O erro {contact.egoi_error} foi corrigido ?</h5>
            </>
        )
    }

    //Datatable formater
    const company = (rowData: ContactInterface) => {
        return rowData.company?.name === null ? <>-</> : rowData.company?.name
    }

    const job = (rowData: ContactInterface) => {
        return rowData.job === null || rowData.job === "" ? <>-</> : rowData.job
    }

    const adress = (rowData: ContactInterface) => {
        return rowData.company?.address === null ? <>-</> : rowData.company?.address
    }

    const egoiStatus = (rowData: ContactInterface) => {
        let statusClass = ""
        let name = ""
        switch (rowData.registered_egoi) {
            case true:
                statusClass = "instock"
                name = "Enviado"
                break

            case false:
                statusClass = "outofstock"
                name = "Não Enviado"
                break
        }

        return <span className={`product-badge status-${statusClass}`}>{name}</span>
    }

    const ConcatenateEmail = (rowData: any) => {
        let cell = []
        let vazio = "-"
        if (rowData.emails.email !== "") {
            for (let email of rowData.emails) {
                cell.push(<Chip style={{ margin: "5px" }} label={email.email} key={email.id} />)
            }
        } else {
            cell = [vazio]
        }
        return cell
    }

    const ConcatenateTelephone = (rowData: any) => {
        let cell = []
        let vazio = "-"
        if (rowData.phones.number !== "") {
            for (let phone of rowData.phones) {
                cell.push(<Chip style={{ margin: "5px" }} label={phone.number} key={phone.id} />)
            }
        } else {
            cell = [vazio]
        }
        return cell
    }

    const dataTableButtons = (rowData: ContactInterface) => {
        return (
            <div className="actions">
                <Button
                    data-tip
                    data-for="editButton"
                    icon="pi pi-user-edit"
                    className="p-button-rounded p-button"
                    onClick={() => {
                        onClickEditContact(rowData)
                        setFormContact(rowData)
                    }}
                />
                <ReactTooltip className="toolTip" id="editButton" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Editar Contato</p>
                </ReactTooltip>
                &nbsp;&nbsp;
                <Button
                    data-tip
                    data-for="deleteButton"
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => deleteContact(rowData)}
                />
                <ReactTooltip className="toolTip" id="deleteButton" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Apagar Contato</p>
                </ReactTooltip>
                &nbsp;&nbsp;
                <Button
                    data-tip
                    data-for="opportunityButton"
                    icon="pi pi-list"
                    className="p-button-rounded p-button-success"
                    onClick={() => {
                        showCompanyOpportunities(rowData)
                    }}
                />
                <ReactTooltip className="toolTip" id="opportunityButton" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Oportunidades do Contato</p>
                </ReactTooltip>
                &nbsp;&nbsp;
                {rowData.registered_egoi === false && (
                    <Button
                        icon="pi pi-reply"
                        data-tip
                        data-for="egoiButton"
                        className="p-button-rounded p-button-warning"
                        onClick={() => sendContactEgoi(rowData)}
                    />
                )}
                {rowData.registered_egoi !== false && (
                    <Button data-tip data-for="egoiButton" disabled icon="pi pi-reply" className="p-button-rounded p-button-warning p-button-disabled" />
                )}
                <ReactTooltip className="toolTip" id="egoiButton" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Egoi</p>
                </ReactTooltip>
            </div>
        )
    }

    //Mobile Dialogs
    const onHideDialogMobile = (name: string) => {
        dialogFuncMapMobile[`${"displayBasic"}`](false)
    }

    const dialogFuncMapMobile = {
        displayBasic: setDisplayBasicMobile,
    }

    let autoFocusAndCleanInputSearchBar = () => {
        var hh = document.getElementById("autofocs")
        setTimeout(() => {
            hh?.focus()
        }, 500)
    }

    const hideDialog = () => {
        setDeleteDialog(false)
        setEgoiDialog(false)
        setHotKeysDialog(false)
        setCompanyOpportunityDialog(false)
        setCompaniesOpportunity([])
        setDialogContact(false)
        setLoadingContact(false)
        setDisplayBasicMobile(false)
        setFormContact({} as ContactInterface)
        setNewEmailField([{ id: uuidv4(), emails: "", name: "email" }])
        setNewPhoneField([{ id: uuidv4(), telephone: "", name: "phone", ddi: { ddi: "+55", country: "Brasil" } }])
    }

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    const filter = () => {
        if (dateInit !== "" || selectedCompany.length > 0 || selectedStatusFilter.length > 0 || advancedSearchFilter !== "") {
            var listContactsFiltered: any[] = []
            for (let atualLine of auxContactsFiltered) {
                var addLine = true
                let phone
                let email

                if (selectedCompany.length > 0 && addLine) {
                    const validateSearch = selectedCompany.findIndex((filter) => filter.code === atualLine.company?.id)
                    if (validateSearch === -1) {
                        addLine = false
                    }
                }

                if (dateFinal !== "" && dateInit !== "" && addLine) {
                    let newDateInitGetTime = new Date(dateInit).getTime()
                    let newDateFinalGetTime = new Date(dateFinal).getTime()
                    let newAtualLineGetTime = atualLine.created_at !== undefined ? new Date(atualLine.created_at).getTime() : 0

                    if (
                        (newAtualLineGetTime > 0 &&
                            newAtualLineGetTime >= newDateInitGetTime &&
                            newAtualLineGetTime <= newDateFinalGetTime) ||
                        dateInit === undefined
                    ) {
                    } else {
                        addLine = false
                    }
                }

                if (selectedStatusFilter.length > 0 && addLine) {
                    const validateSearch = selectedStatusFilter.findIndex((filter) => filter.code === atualLine.registered_egoi)
                    if (validateSearch === -1) {
                        addLine = false
                    }
                }

                if (atualLine.phones !== null && atualLine.phones.length > 0) {
                    atualLine.phones.map((phones) => {
                        phone = phones.number
                    })
                }

                if (atualLine.emails !== null && atualLine.emails.length > 0) {
                    atualLine.emails.map((emails) => {
                        email = emails.email
                    })
                }

                if (advancedSearchFilter !== "" && addLine) {
                    addLine = false
                    var word = advancedSearchFilter.toLowerCase().split(" ")
                    if (advancedSearchFilter === "") {
                        setContacts(auxContactsFiltered)
                    }
                    if (!atualLine.id) atualLine.id = ""
                    if (!atualLine.name) atualLine.name = ""
                    if (!atualLine.company?.name) atualLine.company!.name = ""
                    if (!atualLine.company?.id) atualLine.company!.id = ""
                    if (!atualLine.company?.cnpj) atualLine.company!.cnpj = ""
                    if (!atualLine.job) atualLine.job = ""
                    if (!atualLine.company?.fantasy_name) atualLine.company!.fantasy_name = ""
                    if (!phone) phone = ""
                    if (!email) email = ""

                    for (var oneWord in word) {
                        oneWord = oneWord.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                        if (
                            !atualLine.id.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.name.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.company?.name.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.company?.id.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.company?.cnpj.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.job.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !phone.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !email.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.company?.fantasy_name.toLowerCase().includes(advancedSearchFilter.toLowerCase())
                        ) {
                            addLine = false
                            break
                        } else {
                            addLine = true
                        }
                    }
                }

                if (addLine) {
                    listContactsFiltered.push(atualLine)
                }
            }
            setContacts(listContactsFiltered)
        } else {
            setContacts(auxContactsFiltered)
        }
    }

    //Oportunity
    const showCompanyOpportunities = (contact: ContactInterface) => {
        if (contact && contact.company) {
            allFunnels()
            allOpportunity(contact.company)
            setCompanyOpportunityDialog(true)
        }

        setLoadingOppotunity(false)
    }

    //Footers
    const deleteDialogFooter = () => {
        return (
            <>
                <Button label="Não" icon="pi pi-times" className="p-button-success p-button-text" onClick={hideDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-danger p-button-text" loading={loadingDelete} onClick={onDeleteContact} />
            </>
        )
    }

    const deleteMessage = () => {
        return (
            <>
                {" "}
                Deseja realmente excluir o contato <strong>{contact.name}</strong>
            </>
        )
    }

    const deleteDialogFooterEgoi = () => {
        return (
            <>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                <Button label="Enviar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingDelete} onClick={onSendContactEgoi} />
            </>
        )
    }

    const renderFooter = (name: any) => {
        return (
            <div>
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    onClick={() => {
                        onHideDialogMobile(name)
                        onLoadingCleanFilters()
                    }}
                    className="p-button-danger p-button-text"
                />
                <Button label="Aplicar" icon="pi pi-check" onClick={() => onHideDialogMobile(name)} autoFocus className="p-button-success p-button-text" />
            </div>
        )
    }

    const paramSearch = () => {
        if (param) {
            advancedSearchFilter = param
            setAdvancedSearchFilter(advancedSearchFilter)
            filter()
        }
    }

    //UseEffect
    useEffect(() => {
        const statusList: any[] = []
        const companyList: any[] = []
        contacts.forEach((item, index) => {
            const findCompany = companyList.find((user) => user.name === item.company?.name)
            const findStatus = statusList.find((user) => user.code === item.registered_egoi)
            //responsible filter
            if (!findCompany) {
                companyList.push({ name: item.company?.name, code: item.company?.id })
            }
            if (!findStatus) {
                if (item.registered_egoi === true) statusList.push({ name: "ENVIADO", code: item.registered_egoi })
                if (item.registered_egoi === false) statusList.push({ name: "NÃO ENVIADO", code: item.registered_egoi })
            }
        })
        setListOfStatus(statusList)
        setCompanyList(companyList)
    }, [contacts])

    useEffect(() => {
        allContacts()
    }, [])

    return (
        <App>
            <CrmRecords updateContact={updateContacts} />
            <Toast ref={toast} />

            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="desktop-opportunity-filter-menu">
                        <div className="card" style={{ height: "auto" }}>
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="p-d-flex p-jc-between">
                                    <div>
                                        <Calendar
                                            style={{ width: "13rem" }}
                                            className="p-mr-3"
                                            dateFormat="dd/mm/yy"
                                            id="icon"
                                            value={new Date(dateInit)}
                                            onMonthChange={() => ""}
                                            locale="pt"
                                            onChange={(e) => {
                                                dateInit = e.value
                                                setDateInit(e.value)
                                                filter()
                                            }}
                                            showIcon
                                            placeholder={"INÍCIO"}
                                        />
                                        <Calendar
                                            style={{ width: "13rem" }}
                                            className="p-mr-4"
                                            dateFormat="dd/mm/yy"
                                            id="icon"
                                            value={dateFinal}
                                            locale="pt"
                                            onMonthChange={() => ""}
                                            onChange={(e) => {
                                                dateFinal = e.value
                                                setDateFinal(e.value)
                                                filter()
                                            }}
                                            showIcon
                                            placeholder={"FIM"}
                                        />

                                        <MultiSelect
                                            className="p-mr-4 p-mb-1"
                                            value={selectedCompany}
                                            options={companyList}
                                            disabled={!isEmptyObj(companyList) ? false : true}
                                            style={{ width: "20rem" }}
                                            filter={true}
                                            emptyFilterMessage={"Não há dados"}
                                            onChange={(e) => {
                                                selectedCompany = e.value
                                                setSelectedCompany(e.value)
                                                filter()
                                            }}
                                            optionLabel="name"
                                            placeholder={!isEmptyObj(companyList) ? "EMPRESA/CLIENTE" : "NÃO HÁ EMPRESA/CLIENTE"}
                                        />
                                        <MultiSelect
                                            style={{ width: "19rem" }}
                                            className="p-mr-2"
                                            value={selectedStatusFilter}
                                            options={listOfStatus}
                                            onChange={(e) => {
                                                selectedStatusFilter = e.value
                                                setSelectedStatusFilter(e.value)

                                                filter()
                                            }}
                                            emptyFilterMessage={"Não há dados"}
                                            filter={true}
                                            optionLabel="name"
                                            placeholder="STATUS"
                                            selectedItemsLabel="{0} itens selecionados"
                                        />
                                    </div>

                                    <div className="">
                                        <Button
                                            className="p-button-rounded p-jc-end"
                                            label="LIMPAR FILTROS"
                                            icon="pi pi-times"
                                            style={{ width: "13rem" }}
                                            loading={loadingCleanFilters}
                                            onClick={onLoadingCleanFilters}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br />

                    <div className="card">
                        <DataTable
                            value={contacts}
                            dataKey="id"
                            loading={loadingList}
                            paginator
                            stripedRows
                            rows={10}
                            rowsPerPageOptions={[10, 15, 20]}
                            // className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} contatos"
                            emptyMessage="Não há Contatos"
                            selectionMode="checkbox"
                            responsiveLayout="scroll"
                            // ref={dt}
                            header={header}
                        >
                            <Column sortable field="name" header="Nome do Contato" align="center" alignHeader="center" />
                            <Column sortable align="center" alignHeader="center" body={company} header="Empresa/Cliente" sortField="company.name" />
                            <Column sortable align="center" alignHeader="center" body={adress} sortField="company.address" header="Endereço" />
                            <Column sortable align="center" alignHeader="center" sortField="job" body={job} header="Cargo" />
                            <Column sortable sortField="emails" body={ConcatenateEmail} header="Emails" align="center" alignHeader="center" />
                            <Column
                                sortable
                                sortField="registered_egoi"
                                body={egoiStatus}
                                header="Status (Egoi)"
                                align="center"
                                alignHeader="center"
                                style={{ width: "12rem" }}
                            />
                            <Column body={ConcatenateTelephone} header="Telefone" align="center" alignHeader="center" />
                            <Column body={dataTableButtons} align="center" alignHeader="center" style={{ width: "15rem" }}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

            <div className="p-col-12">
                {/* Edit Contact Method */}
                <Dialog
                    visible={dialogContact}
                    breakpoints={{ "960px": "75vw", "640px": "100vw" }}
                    style={{ minWidth: "40vw" }}
                    modal
                    className="p-fluid"
                    onHide={hideDialog}
                    header={`Editar Contato ${contact.name}`}
                    draggable={false}
                >
                    <form onSubmit={handleEditContact}>
                        <ContactComponent
                            formContact={formContact}
                            setFormContact={handleUpdateContact}
                            setNewPhoneField={handleUpdateFieldPhone}
                            setNewEmailField={handleUpdateFieldEmail}
                            newEmailField={newEmailField}
                            newPhoneField={newPhoneField}
                            insideCompany={contact.company}
                        />

                        <div className="p-dialog-footer">
                            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                            <Button type="submit" loading={loadingCreateContact} label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" />
                        </div>
                    </form>
                </Dialog>

                {/* Delete Dialog Method */}

                <ConfirmDialog
                    style={{ width: "400px", borderRadius: "16px" }}
                    draggable={false}
                    visible={deleteDialog}
                    onHide={hideDialog}
                    message={deleteMessage}
                    acceptLabel={"Sim"}
                    rejectLabel={"Não"}
                    header="Confirmação de exclusão"
                    icon="pi pi-exclamation-triangle"
                    accept={onDeleteContact}
                    acceptIcon="pi pi-check"
                    rejectIcon="pi pi-times"
                    reject={hideDialog}
                    dismissableMask={true}
                    footer={deleteDialogFooter}
                />

                {/* Egoi Method */}
                <ConfirmDialog
                    visible={egoiDialog}
                    style={{ width: "40rem" }}
                    message={textMenssageEgoi}
                    footer={deleteDialogFooterEgoi}
                    onHide={hideDialog}
                ></ConfirmDialog>

                {/* Filters */}
                <Dialog
                    header="Filtros"
                    footer={renderFooter("displayBasic")}
                    visible={displayBasicMobile}
                    style={{ width: "90%", height: "90%" }}
                    onHide={hideDialog}
                    draggable={false}
                >
                    <div className="p-col-12 p-md-12 p-lg-12">
                        <div className="p-d-flex p-flex-column">
                            <MultiSelect
                                className="p-mb-3"
                                value={selectedCompany}
                                options={companyList}
                                disabled={!isEmptyObj(companyList) ? false : true}
                                style={{ width: "100%" }}
                                emptyFilterMessage={"Não há dados"}
                                onChange={(e) => {
                                    selectedCompany = e.value
                                    setSelectedCompany(e.value)
                                    filter()
                                }}
                                filter={true}
                                optionLabel="name"
                                placeholder={!isEmptyObj(companyList) ? "EMPRESA/CLIENTE" : "NÃO HÁ EMPRESA/CLIENTE"}
                            />

                            <Button
                                className="p-button-rounded p-jc-end"
                                label="LIMPAR FILTROS"
                                icon="pi pi-times"
                                style={{ width: "100%" }}
                                loading={loadingCleanFilters}
                                onClick={onLoadingCleanFilters}
                            />
                        </div>
                    </div>
                </Dialog>

                {/* HotKeys Dialog */}
                <Dialog
                    visible={hotKeysDialog}
                    style={{ width: "450px" }}
                    modal
                    className="p-fluid"
                    onHide={hideDialog}
                    header={`Atalhos - Empresa / Cliente`}
                    draggable={false}
                >
                    <p>
                        <strong>SHIFT + O</strong> - Criar uma nova oportunidade
                    </p>
                    <p>
                        <strong>SHIFT + C</strong> - Criar um novo contato
                    </p>
                    <p>
                        <strong>SHIFT + E</strong> - Criar uma nova empresa
                    </p>
                    <p>
                        <strong>SHIFT + T</strong> - Criar uma nova tarefa
                    </p>
                </Dialog>
            </div>

            <Dialog visible={companyOpportunityDialog} modal className="p-fluid" onHide={hideDialog} draggable={false}>
                <CompanyOpportunity
                    companiesOpportunity={companiesOpportunity}
                    funnels={funnels}
                    loadingOppotunity={loadingOppotunity}
                    toast={toast}
                    opportunities={opportunities}
                />
            </Dialog>
        </App>
    )
}
