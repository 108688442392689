
import React, { useRef, useState, useEffect } from "react"
import App from "../../../layout/App"
import api from "../../../services/api"
import classNames from "classnames"
import Moment from "react-moment"
import Dropzone, { IDropzoneProps } from "react-dropzone-uploader"
import { useHotkeys } from "react-hotkeys-hook"

import { Toast } from "primereact/toast"
import { Button } from "primereact/button"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { InputText } from "primereact/inputtext"
import { Dialog } from "primereact/dialog"
import { ConfirmDialog } from "primereact/confirmdialog"
import { TabPanel, TabView } from "primereact/tabview"
import { Dropdown } from "primereact/dropdown"

//Import files
import { CrmRecords } from "../../../components/crm/CrmRecords"
import { fileType } from "../../../listsArrays/ListsArrays"

//CSS
import "react-dropzone-uploader/dist/styles.css"

//Interfaces
import { OpportunityInterface } from "../../../interfaces/OpportunityInterface"
import { FileTypeInterface } from "../../../interfaces/FileTypeInterface"
import { ChatSubgroupInterface } from "../../../interfaces/ChatSubgroupInterface"
import { GropuInterface } from "../../../interfaces/GroupInterface"
import { FilterMatchMode, FilterOperator } from "primereact/api"
import { MultiSelect } from "primereact/multiselect"
import { HotKeys } from "../../../components/crm/HotKeys"
import { InputSwitch } from "primereact/inputswitch"
import { Checkbox } from "primereact/checkbox"

interface AttachmentInterface {
    id: string
    name: string
    description: string
    opportunity_id: string
    opportunity: OpportunityInterface
    file: string
    created_at: Date
    updated_at: Date
    file_url: string
    type: FileTypeInterface
    type_filter: string
    group: GropuInterface
    chat_group_id: string
    chat_subgroup_id: string
    text: string
    active: true
    chatSubgroup: ChatSubgroupInterface
}

interface ListOfTypesFilter {
    name: string
    id: string
}

export const Attachments = () => {
    let dt: any = useRef(null)
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`
    let toast = useRef<Toast>(null)

    let [attachments, setAttachments] = useState<AttachmentInterface[]>([])

    let [groupsAttachments, setGroupsAttachments] = useState<GropuInterface[]>([])
    let [checked, setChecked] = useState<boolean>(false)
    let [attachment, setAttachment] = useState<AttachmentInterface>({} as AttachmentInterface)
    let [selectedGroup, setSelectedGroup] = useState<GropuInterface | null>(null)
    let [submitted, setSubmitted] = useState<boolean>(false)
    let [createValues, setCreateValues] = useState<any>({} as any)
    let [tableSubPannel, setSubTablePannel] = useState<AttachmentInterface[]>([])
    let [file, setFile] = useState<any>([])

    //Loading
    let [loadingList, setLoadingList] = useState<boolean>(true)
    let [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    let [loadingDelete, setLoadingDelete] = useState<boolean>(false)
    let [activeIndex1, setActiveIndex1] = useState(0)
    let [activeIndex2, setActiveIndex2] = useState(0)

    //Dialog
    //Attachment
    let [attachmentsEditDialog, setAttachmentsEditDialog] = useState(false)
    let [deleteAttachmentDialog, setDeleteAttachmentDialog] = useState(false)
    let [sendLargeFileDialog, setSendLargeFileDialog] = useState(false)
    let [hotKeysDialog, setHotKeysDialog] = useState(false)

    //Dropzone
    let [uploadFileImageDialog, setUploadFileImageDialog] = useState(false)
    let [uploadFileStickerDialog, setUploadFileStickerDialog] = useState(false)
    let [uploadVideoDialog, setUploadVideoDialog] = useState(false)
    let [uploadAudioDialog, setUploadFileAudioDialog] = useState(false)
    let [documentDialog, setUploadDocumentDialog] = useState(false)
    let [subGroups, setSubGroups] = useState<AttachmentInterface[]>([])

    //Groups
    let [attachmentsDialog, setAttachmentsDialog] = useState<boolean | undefined>(false)
    let [tablePannel, setTablePannel] = useState<GropuInterface[]>([])

    //Filters
    let [auxAttachmentsFilter, setAuxAttchmentsFilter] = useState<AttachmentInterface[]>([])
    let [globalFilterValue, setGlobalFilterValue] = useState("")

    let [listOfTypeAttachments, setListOfTypeAttachments] = useState<ListOfTypesFilter[]>([])
    let [selectedAttachmentTypeFilter, setSelectedAttachmentTypeFilter] = useState<ListOfTypesFilter[]>([])
    let [auxAttachments, setAuxAttachments] = useState<AttachmentInterface[]>([])

    let [checked1, setChecked1] = useState(false)

    //Hotkeys
    useHotkeys("shift+n", () => openNewDialog())
    useHotkeys("shift+i", () => setHotKeysDialog(true))

    //Filter
    let [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
    })

    let onGlobalFilterChange = (e: any) => {
        const value = e.target.value
        let _filters = { ...filters }
        _filters["global"].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    //OnChange
    const onChangeCreateAttachmentsNameAndType = (event: any) => {
        const { value, name } = event.target

        setCreateValues({
            ...createValues,
            [name]: value,
        })
    }

    const onChangeCreateAttachments = (event: any) => {
        if (createValues.chat_subgroup_id) {
            let auxCreateValues = { ...createValues }
            auxCreateValues.chat_subgroup_id = undefined

            createValues = auxCreateValues
            setCreateValues(createValues)
        }

        const { value, name } = event.target

        setCreateValues({
            ...createValues,
            [name]: value,
        })
    }

    const onChangeEdit = (event: any) => {
        const { value, name } = event.target
        setAttachment({
            ...attachment,
            [name]: value,
        })
    }

    //Dialogs
    const openNewDialog = () => {
        setAttachmentsDialog(true)
    }

    const deleteAttachmentDialogs = (item: AttachmentInterface) => {
        setAttachment(item)
        setDeleteAttachmentDialog(true)
    }

    const hideDialog = () => {
        setCreateValues({} as AttachmentInterface)
        setSubmitted(false)
        setAttachmentsDialog(false)
        setAttachmentsEditDialog(false)
        setDeleteAttachmentDialog(false)
        setHotKeysDialog(false)
        setUploadFileImageDialog(false)
        setUploadFileStickerDialog(false)
        setUploadVideoDialog(false)
        setUploadFileAudioDialog(false)
        setSendLargeFileDialog(false)
        setUploadDocumentDialog(false)
        setSelectedGroup({} as GropuInterface)
    }

    const dialogEditAttachment = (attachment: AttachmentInterface) => {
        setAttachment(attachment)
        setAttachmentsEditDialog(true)

        if (attachment.chatSubgroup) {
            const group = groupsAttachments.find((group) => group.id === attachment.chatSubgroup.chat_group_id)
            setSelectedGroup(group ? group : null)
            console.log(selectedGroup)
        }
    }

    const sendFileSubType = () => {
        return (
            <>
                <h4>
                    <strong>O Arquivo excedeu o limite de tamanho:</strong>
                </h4>
                <br />
                <h5>Ele será enviado ao cliente como documento</h5>
            </>
        )
    }

    const deleteMessage = () => {
        return (
            <>
                {" "}
                Deseja realmente excluir o arquivo <strong>{attachment.name}</strong>
            </>
        )
    }

    //Headers
    const header = (item: any) => {
        return (
            <div className="table-header">
                <h5 style={{ paddingLeft: "5px" }} className="p-m-0">
                    {item.name}
                </h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Pesquisar..." />
                </span>
            </div>
        )
    }

    //Button
    const dataTableButton = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-user-edit" className="p-button-rounded p-button" onClick={() => dialogEditAttachment(rowData)} />
                &nbsp;&nbsp;
                {rowData.file !== null && (
                    <Button icon="pi pi-upload" className="p-button-rounded p-button-warning" onClick={() => updateUploadPresentaiton(rowData)} />
                )}
                {rowData.file === null && (
                    <Button icon="pi pi-upload" className="p-button-rounded p-button-warning" disabled onClick={() => updateUploadPresentaiton(rowData)} />
                )}
                &nbsp;&nbsp;
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => deleteAttachmentDialogs(rowData)} />
                &nbsp;&nbsp;
            </div>
        )
    }

    //Filters
    const findIndexByIdFilter = (id: string) => {
        let index = -1
        for (let i = 0; i < attachments.length; i++) {
            if (attachments[i].id === id) {
                index = i
                break
            }
        }

        return index
    }

    let isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    const allGroupsAttachments = async () => {
        try {
            let header = {
                headers: {
                    route: "list_chat_groups",
                },
            }

            await api
                .get("chat_groups", header)
                .then((res) => {
                    setLoadingList(false)
                    groupsAttachments = res.data

                    let auxGroup: GropuInterface = {} as GropuInterface

                    auxGroup.name = "SEM CATEGORIA"
                    auxGroup.id = "000001"

                    setGroupsAttachments([...groupsAttachments, auxGroup])
                    setTablePannel([...groupsAttachments, auxGroup])
                    setActiveIndex1(groupsAttachments.length)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const allAttachments = async () => {
        try {
            let header = {
                headers: {
                    route: "list_global_attachments",
                },
            }

            await api.get("attachments/global", header).then((res) => {
                setLoadingList(false)

                let auxtest: AttachmentInterface[] = []
                res.data.forEach((attachment: AttachmentInterface) => {
                    if (attachment.chat_group_id === null) {
                        attachment.chat_group_id = "000001"
                    }
                    auxtest.push(attachment)
                })
                attachments = auxtest
                setAttachments(attachments)
                setAuxAttachments(attachments)
                setAuxAttchmentsFilter(auxtest)
                listTypeOfAttachments()
            })
        } catch (error) {
            console.log(error)
        }
    }

    const allSubGroups = async () => {
        try {
            let header = {
                headers: {
                    route: "list_chat_subgroups",
                },
            }

            await api
                .get("chat_subgroups", header)
                .then((res) => {
                    setLoadingList(false)
                    subGroups = res.data
                    setSubGroups(subGroups)
                    setSubTablePannel(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const updateUploadPresentaiton = (rowData: any) => {
        setAttachment(rowData);

        switch (rowData.type) {
            case "image":
            case "images":
                setUploadFileImageDialog(true);
                break;
            case "sticker":
                setUploadFileStickerDialog(true);
                break;
            case "video":
                setUploadVideoDialog(true);
                break;
            case "audio":
                setUploadFileAudioDialog(true);
                break;
            default:
                setUploadDocumentDialog(true);
        }
    };

    const onUploadFile: IDropzoneProps["onSubmit"] = (files) => {
        const data = new FormData()
        data.append("file", files[0].file)
        hideDialog()
        setLoadingList(true)

        toast.current?.show({
            severity: "info",
            summary: "Enviando arquivo, aguarde...",
            life: 3000,
        })

        let header = {
            headers: {
                route: "update_file_global_attachment",
            },
        }

        api.patch(`/attachments/file/global/${attachment.id}`, data, header)

            .then((res) => {
                if (res.status === 200) {
                    setLoadingList(false)
                    let _presentations = [...attachments]
                    let _presentation = res.data
                    let index = findIndexByIdFilter(res.data.id)
                    _presentations[index] = _presentation
                    attachments = _presentations
                    auxAttachmentsFilter = _presentations
                    setAttachments(_presentations)
                    hideDialog()
                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Arquivo enviado com sucesso",
                        life: 3000,
                    })
                } else {
                    setLoadingList(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao enviar arquivo!",
                        detail: res.data.message,
                        life: 3000,
                    })
                }
            })

            .catch((error) => {
                setLoadingList(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao enviar arquivo!",
                    detail: error.response.data.message,
                    life: 3000,
                })
            })
    }

    const onDeleteAttachments = () => {
        setLoadingDelete(true)
        try {
            api.delete("attachments/global", {
                headers: {
                    route: "delete_global_attachment",
                },
                data: {
                    id: attachment.id,
                },
            })

                .then((res) => {
                    setLoadingDelete(false)
                    if (res.status === 200) {
                        let auxDeleteAttachament = attachments.filter((item) => attachment.id !== item.id)
                        attachments = auxDeleteAttachament
                        auxAttachmentsFilter = auxDeleteAttachament

                        hideDialog()
                        setAttachments(auxDeleteAttachament)
                        setAuxAttchmentsFilter(auxDeleteAttachament)
                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na exclusão",
                            detail: "Arquivo de atendimento excluído com sucesso!",
                            life: 3000,
                        })
                    } else {
                        setLoadingDelete(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao excluir!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingDelete(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao excluir!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmitAttachments: IDropzoneProps["onSubmit"] = (files) => {
        if (createValues.group.name === "SEM CATEGORIA" || createValues.chat_subgroup_id !== undefined) {
            if (createValues.name === undefined || createValues.name === "") {
                toast.current?.show({
                    severity: "warn",
                    summary: "Erro ao cadastar !",
                    detail: "O nome do Arquivo é um campo obrigátorio",
                    life: 3000,
                })
            } else {
                let auxAttachmentsRequisition
                const data = new FormData()
                data.append("file", files[0].file)
                if (createValues.type.type === "image" && files[0].file.size > 5242880) {
                    setSendLargeFileDialog(true)
                    setAttachmentsDialog(false)
                    setFile(data)
                } else if (createValues.type.type === "video" && files[0].file.size > 16777216) {
                    setSendLargeFileDialog(true)
                    setAttachmentsDialog(false)
                    setFile(data)
                } else if (createValues.type.type === "sticker" && files[0].file.size > 10485760) {
                    setSendLargeFileDialog(true)
                    setAttachmentsDialog(false)
                    setFile(data)
                } else if (createValues.type.type === "audio" && files[0].file.size > 16777216) {
                    setSendLargeFileDialog(true)
                    setAttachmentsDialog(false)
                    setFile(data)
                } else {
                    let id = createValues.chat_subgroup_id?.id

                    if (createValues.group === undefined && createValues.name !== null && files[0].file) {
                        auxAttachmentsRequisition = {
                            type: createValues.type.type,
                            name: createValues.name,
                            chat_subgroup_id: null,
                        }
                    } else if (createValues.chat_group_id === "000001" || createValues.group.id === "000001") {
                        auxAttachmentsRequisition = {
                            type: createValues.type.type,
                            name: createValues.name,
                            chat_subgroup_id: null,
                        }
                    } else {
                        auxAttachmentsRequisition = {
                            type: createValues.type.type,
                            name: createValues.name,
                            chat_subgroup_id: id,
                        }
                    }
                    try {
                        if (createValues.name === "") {
                            setSubmitted(true)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao cadastrar",
                                detail: "Por favor, verificar os dados preenchidos!",
                                life: 3000,
                            })
                        } else {
                            let header = {
                                headers: {
                                    route: "create_global_attachment",
                                },
                            }

                            api.post("attachments/global", auxAttachmentsRequisition, header)
                                .then((res) => {
                                    if (res.status === 200) {
                                        const data = new FormData()
                                        data.append("file", files[0].file)
                                        setLoadingList(true)
                                        setLoadingSubmit(false)
                                        let _interests = [...attachments]
                                        let _interest = res.data
                                        let index = findIndexByIdFilter(res.data.id)
                                        _interests[index] = _interest
                                        attachments = _interests
                                        auxAttachmentsFilter = _interests
                                        setAttachments(_interests)
                                        setAuxAttchmentsFilter(_interests)
                                        hideDialog()

                                        let header = {
                                            headers: {
                                                route: "update_file_global_attachment",
                                            },
                                        }

                                        api.patch(`attachments/file/global/${res.data.id}`, data, header)

                                            .then((res) => {
                                                if (res.status === 200) {
                                                    toast.current?.show({
                                                        severity: "success",
                                                        summary: "Sucesso",
                                                        detail: "Arquivo de Atendimento criado com sucesso",
                                                        life: 3000,
                                                    })
                                                    setLoadingList(false)
                                                    allAttachments()
                                                } else {
                                                    setLoadingList(false)
                                                    toast.current?.show({
                                                        severity: "error",
                                                        summary: "Erro ao enviar arquivo!",
                                                        detail: res.data.message,
                                                        life: 3000,
                                                    })
                                                }
                                            })

                                            .catch((error) => {
                                                setLoadingList(false)
                                                toast.current?.show({
                                                    severity: "error",
                                                    summary: "Erro ao enviar arquivo!",
                                                    detail: error.response.data.message,
                                                    life: 3000,
                                                })
                                            })

                                        hideDialog()
                                        setAttachments([...attachments, res.data])
                                    } else {
                                        toast.current?.show({
                                            severity: "error",
                                            summary: "Erro ao cadastar!",
                                            detail: res.data.message,

                                            life: 3000,
                                        })
                                    }
                                })
                                .catch((error) => {
                                    toast.current?.show({
                                        severity: "error",
                                        summary: "Erro ao cadastar!",

                                        detail: error.response.data.message,

                                        life: 3000,
                                    })
                                })
                        }
                    } catch (error) {
                        console.log(error)
                    }
                }
            }
        } else {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao Editar",
                detail: "Por favor verifique os dados inseridos",
                life: 3000,
            })
        }
    }

    const onSubmitLargeAttachments = () => {
        let id = createValues.chat_subgroup_id?.id
        let auxAttachmentsRequisition
        if (createValues.name !== undefined || createValues.name !== null) {
            if (createValues.chat_group_id === "000001" || createValues.group.id === "000001") {
                auxAttachmentsRequisition = {
                    type: "document",
                    name: createValues.name,
                    chat_subgroup_id: null,
                    type_filter: createValues.type.type,
                }
            } else {
                auxAttachmentsRequisition = {
                    type: "document",
                    name: createValues.name,
                    chat_subgroup_id: id,
                    type_filter: createValues.type.type,
                }
            }
            try {
                if (createValues.name === "") {
                    setSubmitted(true)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao cadastrar",
                        detail: "Por favor, verificar os dados preenchidos!",
                        life: 3000,
                    })
                } else {
                    let header = {
                        headers: {
                            route: "create_global_attachment",
                        },
                    }

                    api.post("attachments/global", auxAttachmentsRequisition, header)
                        .then((res) => {
                            if (res.status === 200) {
                                setLoadingList(true)
                                setLoadingSubmit(false)
                                let _interests = [...attachments]
                                let _interest = res.data
                                let index = findIndexByIdFilter(res.data.id)
                                _interests[index] = _interest
                                attachments = _interests
                                auxAttachmentsFilter = _interests
                                setAttachments(_interests)
                                setAuxAttchmentsFilter(_interests)
                                hideDialog()

                                let header = {
                                    headers: {
                                        route: "update_file_global_attachment",
                                    },
                                }

                                api.patch(`attachments/file/global/${res.data.id}`, file, header)

                                    .then((res) => {
                                        if (res.status === 200) {
                                            toast.current?.show({
                                                severity: "success",
                                                summary: "Sucesso",
                                                detail: "Arquivo de Atendimento criado com sucesso",
                                                life: 3000,
                                            })
                                            setLoadingList(false)
                                            allAttachments()
                                        } else {
                                            setLoadingList(false)
                                            toast.current?.show({
                                                severity: "error",
                                                summary: "Erro ao enviar arquivo!",
                                                detail: res.data.message,
                                                life: 3000,
                                            })
                                        }
                                    })

                                    .catch((error) => {
                                        setLoadingList(false)
                                        toast.current?.show({
                                            severity: "error",
                                            summary: "Erro ao enviar arquivo!",
                                            detail: error.response.data.message,
                                            life: 3000,
                                        })
                                    })

                                hideDialog()
                                setAttachments([...attachments, res.data])
                            } else {
                                toast.current?.show({
                                    severity: "error",
                                    summary: "Erro ao cadastar!",
                                    detail: res.data.message,

                                    life: 3000,
                                })
                            }
                        })
                        .catch((error) => {
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao cadastar!",

                                detail: error.response.data.message,

                                life: 3000,
                            })
                        })
                }
            } catch (error) {
                console.log(error)
            }
        } else {
            toast.current?.show({
                severity: "warn",
                summary: "Erro ao cadastar !",
                detail: "O nome do Arquivo é um campo obrigátorio",
                life: 3000,
            })
        }
    }

    const onSubmitEditAttchments = () => {
        let finalArray
        if (
            (attachment.chatSubgroup?.id === null || attachment.chatSubgroup?.id === undefined) &&
            !isEmptyObj(selectedGroup) &&
            selectedGroup?.name !== "SEM CATEGORIA"
        ) {
            setLoadingSubmit(false)
            toast.current?.show({
                severity: "error",
                summary: "Erro ao Editar",
                detail: "Revise os dados inseridos",
                life: 3000,
            })
        } else {
            setLoadingSubmit(true)

            if (attachment.name === null || attachment.name === "") {
                setLoadingSubmit(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao Editar",
                    detail: "Revise os dados inseridos",
                    life: 3000,
                })
            } else {
                setLoadingSubmit(true)
                if (isEmptyObj(selectedGroup) || selectedGroup?.name === "sem CATEGORIA") {
                    finalArray = {
                        id: attachment.id,
                        name: attachment.name,
                        chat_subgroup_id: null,
                        type: attachment.type,
                        active: attachment.active,
                    }
                } else {
                    finalArray = {
                        id: attachment.id,
                        name: attachment.name,
                        chat_subgroup_id: attachment.chatSubgroup.id,
                        type: attachment.type,
                        active: attachment.active,
                    }
                }
                try {
                    let header = {
                        headers: {
                            route: "update_global_attachment",
                        },
                    }

                    api.put("attachments/global", finalArray, header)

                        .then((res) => {
                            if (res.status === 200) {
                                toast.current?.show({
                                    severity: "success",
                                    summary: "Sucesso na alteração",
                                    detail: "Apresetação alterada com sucesso!",
                                    life: 3000,
                                })

                                setLoadingSubmit(false)
                                let _presentations = [...attachments]
                                let _presentation = res.data
                                let index = findIndexByIdFilter(res.data.id)
                                _presentations[index] = _presentation
                                attachments = _presentations
                                auxAttachmentsFilter = _presentations
                                setAttachments(_presentations)

                                hideDialog()
                            } else {
                                setLoadingSubmit(false)
                                toast.current?.show({
                                    severity: "error",
                                    summary: "Erro ao Editar",
                                    detail: res.data.message,
                                    life: 3000,
                                })
                            }
                        })
                        .catch((error) => {
                            setLoadingSubmit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao Editar",
                                detail: error.response.data.message,

                                life: 3000,
                            })
                        })
                } catch (error) {
                    console.log(error)
                }
            }
        }
    }

    //Datatble formater
    const dateFormatCreate = (rowData: any) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.created_at}</Moment>
            </>
        )
    }

    const filterSubGroups = (rowData: any) => {
        let auxSub: any = []
        subGroups.forEach((sub) => {
            if (sub.chat_group_id === rowData?.id) {
                auxSub.push(sub)
            }
        })
        return auxSub
    }

    const dateFormatUpdate = (rowData: AttachmentInterface) => {
        return (
            <>
                <InputSwitch
                    checked={rowData.active}
                    onChange={() => {
                        checked1 = !rowData.active
                        setChecked1(checked1)
                        attachment = rowData
                        setAttachment(attachment)
                        changeStatusAttachment()
                    }}
                />
            </>
        )
    }

    const replaceFileType = (rowData: any) => {
        const typeTask = rowData.type
        let nameAuthorization = ""

        switch (typeTask) {
            case "image":
            case "images":
                nameAuthorization = "Imagem"
                break
            case "video":
                nameAuthorization = "Vídeo"
                break
            case "audio":
                nameAuthorization = "Áudio"
                break
            case "sticker":
                nameAuthorization = "Sticker"
                break
            case "document":
                nameAuthorization = "Documento"
                break
        }

        return nameAuthorization
    }

    const fileURLView = (rowData: any) => {
        const linkId = `${rowData.file_url}`
        return (
            <>
                <div>
                    <a href={linkId} rel="noreferrer" target="_blank" style={{ fontWeight: "600", fontSize: "1.1rem", color: "rgba(1, 1, 122, 1)" }}>
                        {rowData.file}
                    </a>
                </div>
            </>
        )
    }

    const formaterFile = (rowData: any) => {
        let icon = ""
        let name = ""
        switch (rowData.type_filter) {
            case "image":
                icon = "pi-image"
                name = "Imagem"
                break

            case "document":
                icon = "pi-file-pdf                "
                name = "Documento"
                break

            case "video":
                icon = "pi-video"
                name = "Vídeo"
                break

            case "audio":
                icon = "pi-volume-down"
                name = "Áudio"
                break

            case "sticker":
                icon = "pi-stop"
                name = "Figurinha"
                break
        }

        return (
            <span>
                {" "}
                <h6 style={{ paddingTop: "1rem" }}>
                    <i className={`pi ${icon}`} /> {name}
                </h6>
            </span>
        )
    }

    const changeStatusAttachment = () => {
        let group
        if (attachment?.chatSubgroup?.id === null || attachment?.chatSubgroup?.id === undefined) {
            group = null
        } else {
            group = attachment.chatSubgroup.id
        }

        let finalArray: any = {}
        finalArray = {
            id: attachment.id,
            name: attachment.name,
            chat_subgroup_id: group,
            type: attachment.type,
            active: checked1,
        }

        try {
            let header = {
                headers: {
                    route: "update_global_attachment",
                },
            }

            api.put("attachments/global", finalArray, header)

                .then((res) => {
                    if (res.status === 200) {
                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na alteração",
                            detail: "Status da Apresentação alterado com sucesso!",
                            life: 3000,
                        })

                        setLoadingSubmit(false)
                        let _presentations = [...attachments]
                        let _presentation = res.data
                        let index = findIndexByIdFilter(res.data.id)
                        _presentations[index] = _presentation
                        attachments = _presentations
                        auxAttachmentsFilter = _presentations
                        setAttachments(_presentations)

                        hideDialog()
                    } else {
                        setLoadingSubmit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Editar",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingSubmit(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao Editar",
                        detail: error.response.data.message,

                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    //Footers
    const deleteDialogFooter = () => {
        return (
            <>
                <Button label="Não" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingDelete} onClick={onDeleteAttachments} />
            </>
        )
    }

    const onSendLargeFileFooter = () => {
        return (
            <>
                <Button label="Não" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingDelete} onClick={onSubmitLargeAttachments} />
            </>
        )
    }

    const attachmentsEditDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingSubmit} onClick={onSubmitEditAttchments} />
        </>
    )

    const listTypeOfAttachments = () => {
        let auxListType: ListOfTypesFilter[] = []
        attachments.map((attachment) => {
            let index = auxListType.findIndex((type) => type.id === attachment.type_filter)

            if (index === -1) {
                let value: ListOfTypesFilter = {} as ListOfTypesFilter

                switch (attachment.type_filter) {
                    case "image":
                        value.name = "Imagem"
                        value.id = "image"
                        auxListType.push(value)
                        break
                    case "sticker":
                        value.name = "Figurinha"
                        value.id = "sticker"
                        auxListType.push(value)
                        break
                    case "document":
                        value.name = "Documento"
                        value.id = "document"
                        auxListType.push(value)
                        break
                    case "audio":
                        value.name = "Áudio"
                        value.id = "audio"
                        auxListType.push(value)
                        break
                    case "video":
                        value.name = "Vídeo"
                        value.id = "video"
                        auxListType.push(value)
                        break
                }
            }
        })
        listOfTypeAttachments = auxListType
        setListOfTypeAttachments(listOfTypeAttachments)
    }

    const filter = () => {
        let localStorageFilters = {
            selectedAttachmentTypeFilter: selectedAttachmentTypeFilter,
        }
        localStorage.setItem("@esquadros:filtersToAttachmentPage", JSON.stringify(localStorageFilters))

        if (selectedAttachmentTypeFilter.length > 0) {
            var listOfAttachmentsFiltered: AttachmentInterface[] = []

            for (let atualLine of auxAttachments) {
                if (selectedAttachmentTypeFilter.length > 0) {
                    var addLine = true

                    if (selectedAttachmentTypeFilter.length > 0 && addLine) {
                        const validateSearch = selectedAttachmentTypeFilter.findIndex((type) => type.id === atualLine.type_filter)
                        if (validateSearch === -1) {
                            addLine = false
                        }
                    }
                    if (addLine) {
                        listOfAttachmentsFiltered.push(atualLine)
                    }
                    attachments = listOfAttachmentsFiltered
                    setAttachments(attachments)
                }
            }
        } else {
            attachments = auxAttachments
            setAttachments(attachments)
        }
    }

    const setValueNewAttachment = () => {
        createValues.group = tablePannel[activeIndex1]
        createValues.chat_subgroup_id = subGroups[activeIndex2]
        setCreateValues(createValues)
    }

    //localStorage Filters
    let dataLocalStorage: any

    useEffect(() => {
        allGroupsAttachments()
        allAttachments()
        allSubGroups()
    }, [])

    useEffect(() => {
        if (localStorage.getItem("@esquadros:filtersToAttachmentPage") !== null) {
            dataLocalStorage = JSON.parse(localStorage.getItem("@esquadros:filtersToAttachmentPage") || "") || []
            if (dataLocalStorage.selectedAttachmentTypeFilter.length > 0) {
                selectedAttachmentTypeFilter = dataLocalStorage.selectedAttachmentTypeFilter
                setSelectedAttachmentTypeFilter(selectedAttachmentTypeFilter)
            }
        }
    }, [])

    useEffect(() => {
        const typeTaskList: any[] = []
        auxAttachmentsFilter.forEach((item, index) => {
            const findtypeTask = typeTaskList.find((type) => type.id === item.type)
            if (!findtypeTask) {
                typeTaskList.push({ name: replaceFileType(item.type), code: item.type })
            }
        })
    }, [auxAttachmentsFilter])

    return (
        <App>
            <CrmRecords />
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <div className="p-d-flex p-jc-between">
                            <div>
                                {" "}
                                <Button
                                    label="Novo"
                                    icon="pi pi-plus"
                                    className="p-button p-mr-2"
                                    onClick={() => {
                                        openNewDialog()
                                        setValueNewAttachment()
                                    }}
                                />
                                <MultiSelect
                                    className="p-mr-2"
                                    value={selectedAttachmentTypeFilter}
                                    style={{ width: "12rem" }}
                                    options={listOfTypeAttachments}
                                    onChange={(e: any) => {
                                        selectedAttachmentTypeFilter = e.value
                                        setSelectedAttachmentTypeFilter(selectedAttachmentTypeFilter)
                                        filter()
                                    }}
                                    emptyFilterMessage={"Não há dados"}
                                    optionLabel="name"
                                    placeholder="TIPO DE ARQUIVO"
                                    selectedItemsLabel="{0} itens selecionados"
                                />
                            </div>
                            <div>
                                <Checkbox
                                    inputId="binary"
                                    checked={checked}
                                    onChange={(e) => {
                                        checked = e.checked
                                        setChecked(checked)
                                        console.log(checked)
                                    }}
                                />
                                <label htmlFor="binary" style={{ fontSize: "18px", paddingLeft: "1rem" }}>
                                    Visualizar Arquivos Inativos
                                </label>
                            </div>
                        </div>
                        <br />

                        {/* DataTable Attachmets*/}
                        <TabView activeIndex={activeIndex1} onTabChange={(e) => setActiveIndex1(e.index)} scrollable={true}>
                            {tablePannel.map((group: GropuInterface, indexgroup: number) => {
                                if (group.id !== "000001") {
                                    return (
                                        <TabPanel key={indexgroup} header={group.name}>
                                            <br />
                                            {!isEmptyObj(groupsAttachments) && (
                                                <TabView activeIndex={activeIndex2} onTabChange={(e) => setActiveIndex2(e.index)} scrollable={true}>
                                                    {tableSubPannel.map((subgroup: AttachmentInterface, indexPriceTable: number) => {
                                                        if (group.id !== "000001") {
                                                            if (subgroup.chat_group_id === group.id) {
                                                                return (
                                                                    <TabPanel key={indexPriceTable} header={subgroup.name}>
                                                                        <DataTable
                                                                            ref={dt}
                                                                            value={attachments.filter(
                                                                                (a: AttachmentInterface) =>
                                                                                    a.chat_subgroup_id === subgroup.id && (checked || a.active)
                                                                            )}
                                                                            loading={loadingList}
                                                                            globalFilterFields={["name"]}
                                                                            sortField={"id"}
                                                                            stripedRows
                                                                            selectionMode="checkbox"
                                                                            dataKey="id"
                                                                            filters={filters}
                                                                            rows={10}
                                                                            rowsPerPageOptions={[10, 15, 20]}
                                                                            className="datatable-responsive"
                                                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} Arquivos padrão"
                                                                            paginator
                                                                            header={() => header(subgroup)}
                                                                            emptyMessage="Não há Arquivos"
                                                                        >
                                                                            <Column
                                                                                style={{ width: "29rem" }}
                                                                                field="name"
                                                                                header="Arquivos"
                                                                                sortField="text"
                                                                                sortable
                                                                                align="center"
                                                                                alignHeader="center"
                                                                            />

                                                                            <Column
                                                                                style={{ width: "29rem" }}
                                                                                body={formaterFile}
                                                                                header="TIPO"
                                                                                sortField="type_filter"
                                                                                sortable
                                                                                align="center"
                                                                                alignHeader="center"
                                                                            />

                                                                            <Column
                                                                                body={fileURLView}
                                                                                header="ARQUIVO"
                                                                                sortable
                                                                                align="center"
                                                                                sortField="file_url"
                                                                                alignHeader="center"
                                                                            />

                                                                            <Column
                                                                                style={{ width: "29rem" }}
                                                                                body={dateFormatUpdate}
                                                                                sortField="active"
                                                                                header="Ativo"
                                                                                sortable
                                                                                align="center"
                                                                                alignHeader="center"
                                                                            />

                                                                            <Column align="center" alignHeader="center" body={dataTableButton}></Column>
                                                                        </DataTable>
                                                                    </TabPanel>
                                                                )
                                                            }
                                                        }
                                                    })}
                                                </TabView>
                                            )}
                                        </TabPanel>
                                    )
                                } else {
                                    return (
                                        <TabPanel header={group.name}>
                                            <DataTable
                                                ref={dt}
                                                value={attachments.filter((a: AttachmentInterface) => a.chat_subgroup_id == null && (checked || a.active))}
                                                loading={loadingList}
                                                globalFilterFields={["name"]}
                                                stripedRows
                                                selectionMode="checkbox"
                                                dataKey="id"
                                                filters={filters}
                                                header={() => header("SEM GRUPO")}
                                                rows={10}
                                                rowsPerPageOptions={[10, 15, 20]}
                                                className="datatable-responsive"
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} Arquivos padrão"
                                                paginator
                                                emptyMessage="Não há Arquivos"
                                            >
                                                <Column
                                                    style={{ width: "29rem" }}
                                                    field="name"
                                                    header="ARQUIVOS"
                                                    sortField="text"
                                                    sortable
                                                    align="center"
                                                    alignHeader="center"
                                                />

                                                <Column
                                                    style={{ width: "29rem" }}
                                                    field="type_filter"
                                                    body={formaterFile}
                                                    sortField="type_filter"
                                                    sortable
                                                    header="TIPO"
                                                    align="center"
                                                    alignHeader="center"
                                                />

                                                <Column body={fileURLView} header="ARQUIVO" sortable align="center" sortField="file_url" alignHeader="center" />

                                                <Column
                                                    body={dateFormatCreate}
                                                    header="Data de Criação"
                                                    sortable
                                                    sortField="created_at"
                                                    align="center"
                                                    alignHeader="center"
                                                />

                                                <Column
                                                    body={dateFormatCreate}
                                                    header="Data de Atualização"
                                                    sortable
                                                    sortField="update_at"
                                                    align="center"
                                                    alignHeader="center"
                                                />

                                                <Column
                                                    style={{ width: "29rem" }}
                                                    body={dateFormatUpdate}
                                                    sortField="active"
                                                    header="ATIVO"
                                                    sortable
                                                    align="center"
                                                    alignHeader="center"
                                                />

                                                <Column align="center" alignHeader="center" body={dataTableButton}></Column>
                                            </DataTable>
                                        </TabPanel>
                                    )
                                }
                            })}
                        </TabView>

                        {/* Create Attachments Method */}
                        <Dialog
                            visible={attachmentsDialog}
                            style={{ width: "600px" }}
                            header="Adicionar Anexo"
                            modal
                            className="p-fluid"
                            draggable={false}
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <label htmlFor="name">Nome:</label>
                                <InputText
                                    id="name"
                                    name="name"
                                    required
                                    type="String"
                                    maxLength={120}
                                    style={{ minWidth: "20rem" }}
                                    className={
                                        "p-field p-text-capitalize " +
                                        classNames({
                                            "p-invalid": submitted && createValues.name === "",
                                        })
                                    }
                                    value={createValues.name || ""}
                                    onChange={onChangeCreateAttachmentsNameAndType}
                                />
                            </div>

                            <div className="p-field">
                                <label htmlFor="description">Categoria:</label>
                                <Dropdown
                                    id="group"
                                    name="group"
                                    required
                                    options={groupsAttachments}
                                    optionLabel="name"
                                    type="String"
                                    style={{ minWidth: "20rem" }}
                                    maxLength={45}
                                    className={
                                        "p-field p-text-capitalize " +
                                        classNames({
                                            "p-invalid": submitted && createValues.description === "",
                                        })
                                    }
                                    value={createValues.group}
                                    onChange={onChangeCreateAttachments}
                                    placeholder="SEM CATEGORIA"
                                />
                            </div>

                            {createValues.group?.id !== undefined && createValues.group?.id !== "000001" && (
                                <>
                                    <div className="p-field">
                                        <label htmlFor="description">Subcategoria:</label>
                                        <Dropdown
                                            id="group"
                                            name="chat_subgroup_id"
                                            required
                                            options={filterSubGroups(createValues.group)}
                                            optionLabel="name"
                                            style={{ minWidth: "20rem" }}
                                            onChange={onChangeCreateAttachments}
                                            value={createValues.chat_subgroup_id || ""}
                                            placeholder="Selecionar"
                                        />
                                    </div>
                                </>
                            )}

                            <div className="p-field">
                                <label htmlFor="type">Tipo:</label>
                                <Dropdown
                                    id="type"
                                    name="type"
                                    required
                                    type="String"
                                    emptyMessage="Não há dados"
                                    emptyFilterMessage="Não há dados"
                                    style={{ minWidth: "20rem" }}
                                    maxLength={45}
                                    value={createValues.type}
                                    options={fileType}
                                    optionLabel="name"
                                    onChange={onChangeCreateAttachmentsNameAndType}
                                />
                            </div>

                            {createValues.type !== undefined && createValues.type.name === "Vídeo" && (
                                <div className="p-field">
                                    <label htmlFor="file">Arquivo:</label>
                                    <Dropzone
                                        styles={{
                                            dropzone: { maxWidth: "60rem", height: "15rem", overflow: "hidden" },
                                            dropzoneActive: { borderColor: "red", pointerEvents: "none" },
                                            submitButton: { backgroundColor: "#5E8F32", color: "white" },
                                        }}
                                        onSubmit={onSubmitAttachments}
                                        maxSizeBytes={100000000}
                                        maxFiles={1}
                                        inputContent="📁 Arraste ou selecione um arquivo"
                                        submitButtonContent="Enviar"
                                        accept=".mp4, .avi, .mkv"
                                    />
                                </div>
                            )}

                            {createValues.type !== undefined && createValues.type.name === "Imagem" && (
                                <div className="p-field">
                                    <label htmlFor="file">Arquivo:</label>
                                    <Dropzone
                                        styles={{
                                            dropzone: { maxWidth: "60rem", height: "15rem", overflow: "hidden" },
                                            dropzoneActive: { borderColor: "red", pointerEvents: "none" },
                                            submitButton: { backgroundColor: "#5E8F32", color: "white" },
                                        }}
                                        onSubmit={onSubmitAttachments}
                                        maxSizeBytes={100000000}
                                        maxFiles={1}
                                        inputContent="📁 Arraste ou selecione um arquivo"
                                        submitButtonContent="Enviar"
                                        accept=".png, .jpg, .jpeg"
                                    />
                                </div>
                            )}

                            {createValues.type !== undefined && createValues.type.name === "Áudio" && (
                                <div className="p-field">
                                    <label htmlFor="file">Arquivo:</label>
                                    <Dropzone
                                        styles={{
                                            dropzone: { maxWidth: "60rem", height: "15rem", overflow: "hidden" },
                                            dropzoneActive: { borderColor: "red", pointerEvents: "none" },
                                            submitButton: { backgroundColor: "#5E8F32", color: "white" },
                                        }}
                                        onSubmit={onSubmitAttachments}
                                        maxSizeBytes={100000000} //16mb
                                        maxFiles={1}
                                        accept=".mp3, .ogg, .mpeg, .amr"
                                        inputContent="📁 Arraste ou selecione um arquivo"
                                        submitButtonContent="Enviar"
                                    />
                                </div>
                            )}

                            {createValues.type !== undefined && createValues.type.name === "Documento" && (
                                <div className="p-field">
                                    <label htmlFor="file">Arquivo:</label>
                                    <Dropzone
                                        styles={{
                                            dropzone: { maxWidth: "60rem", height: "15rem", overflow: "hidden" },
                                            dropzoneActive: { borderColor: "red", pointerEvents: "none" },
                                            submitButton: { backgroundColor: "#5E8F32", color: "white" },
                                        }}
                                        onSubmit={onSubmitAttachments}
                                        maxSizeBytes={100000000}
                                        maxFiles={1}
                                        inputContent="📁 Arraste ou selecione um arquivo"
                                        submitButtonContent="Enviar"
                                        accept=" .mp4, .mkv, .avi,  .png, .jpg, .jpeg, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .odt, .ods, .odp, .odg, .odc, .odf, .odb, .odi, .odm, .webm, .mp3, .wav, .mp4, .m4a, .m4v, .ogg, .ogv, .ogm, .ogx, .wmv, .wma, .wax, .wvx, .mov, .qt, .3gp, .3g2, .flv, .swf, .svg, .svgz, .ogg, .aac, .flac, .wav, .mid, .midi, .woff, .woff2, .eot, .ttf, .otf, .ico, .icns, .png, .jpg, .jpeg, .gif, .bmp, .tiff, .tif, .svg, .svgz, .webp, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .odt, .ods, .odp, .odg, .odc, .odf, .odb, .odi, .odm, .webm, .mp3, .wav, .mp4, .m4a, .m4v, .ogg, .ogv, .ogm, .ogx, .wmv, .wma, .wax, .wvx, .mov, .qt, .3gp, .3g2, .flv, .swf, .svg, .svgz, .ogg, .aac, .flac, .wav, .mid, .midi, .woff, .woff2, .eot, .ttf, .otf, .ico, .icns, .png, .jpg, .jpeg, .gif, .bmp, .tiff, .tif, .svg, .svgz, .zip"
                                    />
                                </div>
                            )}

                            {createValues.type !== undefined && createValues.type.name === "Sticker" && (
                                <div className="p-field">
                                    <label htmlFor="file">Arquivo:</label>
                                    <Dropzone
                                        styles={{
                                            dropzone: { maxWidth: "60rem", height: "15rem", overflow: "hidden" },
                                            dropzoneActive: { borderColor: "red", pointerEvents: "none" },
                                            submitButton: { backgroundColor: "#5E8F32", color: "white" },
                                        }}
                                        onSubmit={onSubmitAttachments}
                                        maxSizeBytes={100000000} //100kb
                                        maxFiles={1}
                                        inputContent="📁 Arraste ou selecione um arquivo"
                                        submitButtonContent="Enviar"
                                        accept=".webp"
                                    />
                                </div>
                            )}
                        </Dialog>

                        {/* Edit Attachemnts  Dialog */}
                        <Dialog
                            visible={attachmentsEditDialog}
                            style={{ width: "450px" }}
                            header={"Editar Arquivo de Atendimento "}
                            modal
                            className="p-fluid"
                            footer={attachmentsEditDialogFooter}
                            draggable={false}
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <label htmlFor="name">Nome:</label>
                                <InputText
                                    required
                                    id="name"
                                    name="name"
                                    autoFocus
                                    type="String"
                                    maxLength={120}
                                    onChange={(e: any) => onChangeEdit(e)}
                                    value={attachment.name}
                                />
                                <br /> <br />
                                <div className="p-field">
                                    <label htmlFor="attachment_group_id">Categoria:</label>
                                    <Dropdown
                                        id="chatGroup"
                                        name="chatGroup"
                                        required
                                        type="String"
                                        emptyMessage="Não há dados"
                                        emptyFilterMessage="Não há dados"
                                        style={{ minWidth: "20rem" }}
                                        maxLength={45}
                                        options={groupsAttachments}
                                        dataKey="id"
                                        optionLabel="name"
                                        onChange={(e: any) => {
                                            setSelectedGroup(e.value)
                                        }}
                                        value={selectedGroup}
                                        placeholder="SEM CATEGORIA"
                                    />
                                </div>
                                <br />
                                {!isEmptyObj(selectedGroup) && selectedGroup?.id !== "000001" && (
                                    <div className="p-field">
                                        <label htmlFor="description">Subcategoria:</label>
                                        <Dropdown
                                            id="chatSubgroup"
                                            name="chatSubgroup"
                                            required
                                            options={subGroups.filter((a) => {
                                                return a.chat_group_id === selectedGroup?.id
                                            })}
                                            dataKey="id"
                                            optionLabel="name"
                                            style={{ minWidth: "20rem" }}
                                            onChange={(e: any) => setAttachment({ ...attachment, [`chatSubgroup`]: e.target.value })}
                                            value={attachment.chatSubgroup}
                                            placeholder="Selecionar"
                                        />
                                    </div>
                                )}
                            </div>
                        </Dialog>

                        <Dialog
                            visible={uploadFileImageDialog}
                            style={{ width: "750px" }}
                            header={"Atualizar arquivo de: " + attachment.name}
                            modal
                            className="p-fluid"
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <label htmlFor="file">Arquivo:</label>
                                <Dropzone
                                    styles={{
                                        dropzone: { maxWidth: "60rem", height: "15rem", overflow: "hidden" },
                                        dropzoneActive: { borderColor: "red", pointerEvents: "none" },
                                        submitButton: { backgroundColor: "#5E8F32", color: "white" },
                                    }}
                                    onSubmit={onUploadFile}
                                    maxSizeBytes={5242880} //5mb
                                    maxFiles={1}
                                    inputContent="📁 Arraste ou selecione um arquivo"
                                    submitButtonContent="Enviar"
                                    accept=".png, .jpg, .jpeg"
                                />
                            </div>
                        </Dialog>

                        <Dialog
                            visible={uploadFileStickerDialog}
                            style={{ width: "750px" }}
                            header={"Atualizar arquivo de: " + attachment.name}
                            modal
                            className="p-fluid"
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <label htmlFor="file">Arquivo:</label>
                                <Dropzone
                                    styles={{
                                        dropzone: { maxWidth: "60rem", height: "15rem", overflow: "hidden" },
                                        dropzoneActive: { borderColor: "red", pointerEvents: "none" },
                                        submitButton: { backgroundColor: "#5E8F32", color: "white" },
                                    }}
                                    onSubmit={onUploadFile}
                                    maxSizeBytes={100000} //100kb
                                    maxFiles={1}
                                    inputContent="📁 Arraste ou selecione um arquivo"
                                    submitButtonContent="Enviar"
                                    accept=".webp"
                                />
                            </div>
                        </Dialog>

                        <Dialog
                            visible={uploadVideoDialog}
                            style={{ width: "750px" }}
                            header={"Atualizar arquivo de: " + attachment.name}
                            modal
                            className="p-fluid"
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <label htmlFor="file">Arquivo:</label>
                                <Dropzone
                                    styles={{
                                        dropzone: { maxWidth: "60rem", height: "15rem", overflow: "hidden" },
                                        dropzoneActive: { borderColor: "red", pointerEvents: "none" },
                                        submitButton: { backgroundColor: "#5E8F32", color: "white" },
                                    }}
                                    onSubmit={onUploadFile}
                                    maxSizeBytes={16777216} //16mb
                                    maxFiles={1}
                                    inputContent="📁 Arraste ou selecione um arquivo"
                                    submitButtonContent="Enviar"
                                    accept=".mp4, .avi, .mkv"
                                />
                            </div>
                        </Dialog>

                        <Dialog
                            visible={uploadAudioDialog}
                            style={{ width: "750px" }}
                            header={"Atualizar arquivo de: " + attachment.name}
                            modal
                            className="p-fluid"
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <label htmlFor="file">Arquivo:</label>
                                <Dropzone
                                    styles={{
                                        dropzone: { maxWidth: "60rem", height: "15rem", overflow: "hidden" },
                                        dropzoneActive: { borderColor: "red", pointerEvents: "none" },
                                        submitButton: { backgroundColor: "#5E8F32", color: "white" },
                                    }}
                                    onSubmit={onUploadFile}
                                    maxSizeBytes={16777216} //16mb
                                    maxFiles={1}
                                    inputContent="📁 Arraste ou selecione um arquivo"
                                    submitButtonContent="Enviar"
                                    accept=".mp3, .ogg, .mpeg, .amr"
                                />
                            </div>
                        </Dialog>

                        <Dialog
                            visible={documentDialog}
                            style={{ width: "750px" }}
                            header={"Atualizar arquivo de: " + attachment.name}
                            modal
                            className="p-fluid"
                            onHide={hideDialog}
                        >
                            <div className="p-field">
                                <label htmlFor="file">Arquivo:</label>
                                <Dropzone
                                    styles={{
                                        dropzone: { maxWidth: "60rem", height: "15rem", overflow: "hidden" },
                                        dropzoneActive: { borderColor: "red", pointerEvents: "none" },
                                        submitButton: { backgroundColor: "#5E8F32", color: "white" },
                                    }}
                                    onSubmit={onUploadFile}
                                    maxSizeBytes={10485760} //100mb
                                    maxFiles={1}
                                    inputContent="📁 Arraste ou selecione um arquivo"
                                    submitButtonContent="Enviar"
                                    accept=" .mp4, .mkv, .avi,  .png, .jpg, .jpeg, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .odt, .ods, .odp, .odg, .odc, .odf, .odb, .odi, .odm, .webm, .mp3, .wav, .mp4, .m4a, .m4v, .ogg, .ogv, .ogm, .ogx, .wmv, .wma, .wax, .wvx, .mov, .qt, .3gp, .3g2, .flv, .swf, .svg, .svgz, .ogg, .aac, .flac, .wav, .mid, .midi, .woff, .woff2, .eot, .ttf, .otf, .ico, .icns, .png, .jpg, .jpeg, .gif, .bmp, .tiff, .tif, .svg, .svgz, .webp, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .odt, .ods, .odp, .odg, .odc, .odf, .odb, .odi, .odm, .webm, .mp3, .wav, .mp4, .m4a, .m4v, .ogg, .ogv, .ogm, .ogx, .wmv, .wma, .wax, .wvx, .mov, .qt, .3gp, .3g2, .flv, .swf, .svg, .svgz, .ogg, .aac, .flac, .wav, .mid, .midi, .woff, .woff2, .eot, .ttf, .otf, .ico, .icns, .png, .jpg, .jpeg, .gif, .bmp, .tiff, .tif, .svg, .svgz, .zip"
                                />
                            </div>
                        </Dialog>

                        {/* HotKeys Dialog */}
                        <Dialog
                            visible={hotKeysDialog}
                            style={{ width: "800px" }}
                            modal
                            className="p-fluid"
                            onHide={hideDialog}
                            header={`Atalhos - Tela de Apresentações de Anexos`}
                            draggable={false}
                        >
                            <HotKeys create={"Criar um novo Anexo"} />
                        </Dialog>

                        {/* Delete Attachments Dialog */}

                        <ConfirmDialog
                            style={{ width: "400px", borderRadius: "16px" }}
                            draggable={false}
                            visible={deleteAttachmentDialog}
                            onHide={hideDialog}
                            message={deleteMessage}
                            acceptLabel={"Sim"}
                            rejectLabel={"Não"}
                            header="Confirmação de exclusão"
                            icon="pi pi-exclamation-triangle"
                            accept={onDeleteAttachments}
                            acceptIcon="pi pi-check"
                            rejectIcon="pi pi-times"
                            reject={hideDialog}
                            dismissableMask={true}
                            footer={deleteDialogFooter}
                        />

                        {/* Send Large Dialog */}
                        <ConfirmDialog
                            visible={sendLargeFileDialog}
                            style={{ width: "40rem" }}
                            message={sendFileSubType}
                            footer={onSendLargeFileFooter}
                            onHide={hideDialog}
                        ></ConfirmDialog>
                    </div>
                </div>
            </div>
        </App>
    )
}
