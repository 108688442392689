import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import React, { useEffect, useState } from "react"
import { FunnelInterface } from "../../../interfaces/FunnelInterface"
import { IEmpresaInterface } from "../../../interfaces/IEmpresaInterface"
import { MediaInterface } from "../../../interfaces/MediaInterface"
import api from "../../../services/api"

interface PropsInterface {
    allFunnels: FunnelInterface[]
    allMidias: MediaInterface[]
    createOpportunity?: OpportunityInterface
    opportunityName: string
    opportunityFunnel_stage: string
    opportunityMedia_id: string
    onChangeCreateOpportunity: (event: any) => void
    existingCompany: any
    saveCompanyData: any
    requestApiCompany: any
}

interface OpportunityInterface {
    name: string
    funnel: Funnel
    stage: Stage
    media: Midia
    campaign: IEmpresaInterface
    contact: Contact
    id: string
}

interface Contact {
    name: string
    job: string
    company: IEmpresaInterface
    authorization_status: Data
    phone: Phones[]
    email: Email[]
    authorization: Data
}

interface Email {
    email: string
}

interface Phones {
    number: string
}

interface Data {
    name: string
    code: string | boolean
}

interface Midia {
    id: string
    name: string
}

interface Funnel {
    id: string
    name: string
    stages: Stage[]
    is_default: boolean
}

interface Stage {
    id: string
    name: string
    funnel_id: string
    sequence: number
    created_at: string
    updated_at: string
}

interface NameFunellInterface {
    name: string
}

export const Opportunity = (props: PropsInterface) => {
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    let [funnelStages, setFunnelStages] = useState<Stage[]>([])
    let [nameFunnelStages, setNameFunnelStages] = useState<NameFunellInterface[]>([])
    let [nameOpportunity, setNameOpportunity] = useState<string>("")

    let defaultFunnel: any = props.allFunnels.find((funnel) => funnel.is_default === true)
    let defaultMidia: any = props.allMidias.find((midia) => midia.id === "9097ab5e-4931-4b9f-a00f-14c5debd3ca6")

    // if (defaultFunnel && props.createOpportunity) {
    //     if (nameFunnelStages.length === 0) {
    //         setNameFunnelStages(defaultFunnel)
    //         setFunnelStages(defaultFunnel.stages)
    //         props.createOpportunity.stage = defaultFunnel.stages.find((stage: any) => stage.id === "8ee86d5d-1dae-444f-996d-93dcb54fc17a")
    //     }
    // }

    // if (defaultMidia) {
    //     const createOpportunity = props.createOpportunity ?? { media: defaultMidia }
    //     if (createOpportunity.media === undefined || createOpportunity.media === null) {
    //         createOpportunity.media = defaultMidia
    //     }
    // }

    // useEffect(() => {
    //     if (props.createOpportunity) {
    //         if ((props.createOpportunity.stage === undefined || props.createOpportunity.stage === null) && defaultFunnel) {
    //             console.log("props.createOpportunity.stage", props.createOpportunity.stage)
    //             props.createOpportunity.stage = defaultFunnel.stages.find((stage: any) => stage.id === "8ee86d5d-1dae-444f-996d-93dcb54fc17a")
    //         }
    //     }
    // }, [defaultFunnel, props.createOpportunity])

    useEffect(() => {
        //Esperando o interesse ser selecionado para criar o nome da oportunidade
        if (
            props.requestApiCompany?.estabelecimento?.cnpj !== null &&
            props.requestApiCompany?.estabelecimento?.cnpj !== undefined &&
            props.createOpportunity
        ) {
            let textOpportunity

            if (props.requestApiCompany.estabelecimento.cnpj.length === 14) {
                if (props.requestApiCompany.estabelecimento.nome_fantasia !== null) {
                    textOpportunity = props.requestApiCompany.estabelecimento.nome_fantasia
                } else {
                    textOpportunity = props.requestApiCompany.razao_social
                }
                if (textOpportunity) {
                    if (props.saveCompanyData.interests !== undefined && props.saveCompanyData.interests.length > 0) {
                        let name = `${textOpportunity} - ${props.saveCompanyData.interests[0].name}`
                        nameOpportunity = name
                        setNameOpportunity(name)
                        props.createOpportunity.name = name
                    } else {
                        let name = `${textOpportunity}`
                        nameOpportunity = name
                        setNameOpportunity(name)
                        props.createOpportunity.name = name
                    }
                }
            }
        }
    }, [props.requestApiCompany, props.saveCompanyData.interests])
    
    return (
        <>
            <div className="p-field">
                <label htmlFor="nameOpportunity">Descrição:</label>
                <span style={{ color: "red" }}> * </span>
                <InputText
                    style={{ minWidth: "20rem", textTransform: "uppercase" }}
                    id="nameOpportunity"
                    name="name"
                    maxLength={120}
                    value={nameOpportunity ?? props.createOpportunity?.name}
                    onChange={(e: any) => {
                        setNameOpportunity(e.target.value)
                        props.onChangeCreateOpportunity(e)
                    }}
                />
            </div>

            <div className="p-field">
                <label htmlFor="salesFunnelOpportunity">Funil de Vendas:</label>
                <span style={{ color: "red" }}> * </span>
                <Dropdown
                    style={{
                        minWidth: "20rem",
                    }}
                    id="salesFunnelOpportunity"
                    name="funnel"
                    filter={true}
                    value={nameFunnelStages}
                    options={props.allFunnels}
                    onChange={(e: any) => {
                        setNameFunnelStages(e.value)
                        props.onChangeCreateOpportunity(e)
                        setFunnelStages(e.value.stages)
                    }}
                    optionLabel="name"
                    placeholder="Selecionar"
                />
            </div>

            <div className="p-field">
                <label htmlFor="salesFunnelOpportunity">Etapa de Atendimento:</label>
                <span style={{ color: "red" }}> * </span>
                <Dropdown
                    style={{
                        minWidth: "20rem",
                    }}
                    id="salesFunnelOpportunity"
                    name="stage"
                    filter={true}
                    value={props.createOpportunity?.stage}
                    options={funnelStages}
                    onChange={(e: any) => props.onChangeCreateOpportunity(e)}
                    optionLabel="name"
                    placeholder="Selecionar"
                />
            </div>

            <div className="p-field">
                <label htmlFor="sourceOpportunity">Midias:</label>
                <span style={{ color: "red" }}> * </span>
                <Dropdown
                    style={{
                        minWidth: "20rem",
                    }}
                    id="sourceOpportunity"
                    name="media"
                    filter={true}
                    value={props.createOpportunity?.media || ""}
                    options={props.allMidias}
                    onChange={(e: any) => props.onChangeCreateOpportunity(e)}
                    optionLabel="name"
                    placeholder="Selecionar"
                />
            </div>
        </>
    )
}
