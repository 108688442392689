import { Toast } from "primereact/toast"
import React, { useRef, useState } from "react"
import App from "../../../layout/App"
import api from "../../../services/api"
import { MetaPreview } from "../../../components/Meta/MetaPreview"
import { MetaForms } from "../../../components/Meta/MetaForms"

//PrimeReact

//Components

//Interfaces

export const MetaTeamplate = () => {
    const toast: any = useRef(null)
    const token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    const [selectedHeader, setSelectedHeader] = useState<any>(null)

    const [nameModel, setNameModel] = useState<any>("")

    const [selectedAction, setSelectedAction] = useState<any>(null)
    const [selectedAction2, setSelectedAction2] = useState<any>(null)

    const [headerText, setHeadertext] = useState("")
    const [footerText, setFooterText] = useState("")
    const [bodyText, setBodyText] = useState<any>("")

    const [firstButtonText, setFirstButtonText] = useState("")
    const [secondButtonText, setSecondButtonText] = useState("")

    const [firstButton, setFirstButton] = useState(false)
    const [secondButton, setSecondButton] = useState(false)

    const [firstButtonActionText, setFirstButtonAtcion] = useState("")
    const [secondButtonActionText, setSecondButtonAtcion] = useState("")

    const onCityChange = (e: { value: any }) => {
        setSelectedHeader(e.value)
    }

    const onFirstButtonChange = (e: { value: any }) => {
        setFirstButtonAtcion("")
        setSelectedAction(e.value)
    }

    const onSecondButtonChange = (e: { value: any }) => {
        setSecondButtonAtcion("")
        setSelectedAction2(e.value)
    }

    const metaFinalText = (text: any) => {
        var replaced = text.replace(/<br>/g, "\n")
        replaced = replaced.replace(/<\/strong>/g, "*")
        replaced = replaced.replace(/<strong>/g, "*")
        replaced = replaced.replace(/<\/i>/g, "_")
        replaced = replaced.replace(/<i>/g, "_")
        return replaced
    }

    return (
        <App>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <div className="p-d-flex p-flex-column p-flex-md-row">
                            <MetaForms
                                nameModel={nameModel}
                                setNameModel={setNameModel}
                                onCityChange={onCityChange}
                                selectedHeader={selectedHeader}
                                setHeadertext={setHeadertext}
                                headerText={headerText}
                                setBodyText={setBodyText}
                                bodyText={bodyText}
                                setFooterText={setFooterText}
                                footerText={footerText}
                                firstButtonText={firstButtonText}
                                setFirstButtonText={setFirstButtonText}
                                secondButtonText={secondButtonText}
                                setSecondButtonText={setSecondButtonText}
                                firstButton={firstButton}
                                setFirstButton={setFirstButton}
                                secondButton={secondButton}
                                setSecondButton={setSecondButton}
                                firstButtonActionText={firstButtonActionText}
                                setFirstButtonAtcion={setFirstButtonAtcion}
                                secondButtonActionText={secondButtonActionText}
                                setSecondButtonAtcion={setSecondButtonAtcion}
                                onFirstButtonChange={onFirstButtonChange}
                                selectedAction={selectedAction}
                                onSecondButtonChange={onSecondButtonChange}
                                selectedAction2={selectedAction2}
                            />

                            <div className="preview-section p-mr-2" style={{ borderLeft: "0.2px solid #cccccc" }}>
                                <MetaPreview bodyText={bodyText} footerText={footerText} headerText={headerText} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </App>
    )
}
