import { memo, useCallback, useRef, useState } from "react"
import { useEffect } from "react"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Toast } from "primereact/toast"
import { AttachmentInterface } from "../../../../interfaces/AttachmentInterface"
import api from "../../../../services/api"
import "../../../../styles/DialogToDataAllGroups.scss"
import { MultiSelect } from "primereact/multiselect"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import ReactPlayer from "react-player"
import { Avatar } from "primereact/avatar"
import { subGroupInterface } from "../../../../interfaces/subGroupInterface"
import { SelectButton } from "primereact/selectbutton"
import { DefaultMessageInterface } from "../../../../interfaces/DefaultMessageInterface"
import { useAuth } from "../../../../hooks/AuthContext"
import { RoadMapInterface } from "../../../../interfaces/RoadMapInterface"
import { Card } from "primereact/card"
import moment from "moment"
import { Image } from "primereact/image"
import { MessagesRoadMapInterface } from "../../../../interfaces/MessagesRoadMapInterface"
import { UserInterface } from "../../../../interfaces/UserInterface"

const DialogToDataAllGroups = (props: any) => {
    let noPictureURL = "/assets/pages/icons/no-avatar.png"
    let widthWindow = window.innerWidth
    let toast = useRef<Toast>(null)
    const { user } = useAuth()
    let auxBoolean = false
    let [attachments, setAttachments] = useState<AttachmentInterface[]>([])
    let [auxAttachments, setAuxAttachments] = useState<AttachmentInterface[]>([])

    let [roadMaps, setRoadMaps] = useState<RoadMapInterface[]>([])
    let [auxRoadMaps, setAuxRoadMaps] = useState<RoadMapInterface[]>([])
    let [roadMapSelectVisualization, setRoadMapSelectVisualization] = useState<RoadMapInterface>({} as RoadMapInterface)
    let [viewImageMessage, setViewImageMessage] = useState(false)
    let [profileUser, setProfileUser] = useState<UserInterface>({} as UserInterface)

    const [typeOfAttachment, setTypeOfAttachment] = useState("image")

    let [defaultMessages, setDefaultMessages] = useState<DefaultMessageInterface[]>([])
    let [auxDefaultMessage, setAuxDefaultMessage] = useState<DefaultMessageInterface[]>([])

    let [groupsAttachments, setGroupsAttachments] = useState<AttachmentInterface[]>([])
    let [subGroups, setSubGroups] = useState<subGroupInterface[]>([])
    let [auxSubGroups, setAuxSubGroups] = useState<subGroupInterface[]>([])
    let [idSubgroupToMap, setIdSubgroupToMap] = useState<subGroupInterface>({} as subGroupInterface)

    let [selectBoxArrayAttachments, setSelectBoxArrayAttachments] = useState<string[]>([])

    let [selectedAttachment, setSelectedAttachment] = useState<AttachmentInterface[]>([])
    let [selectedAttachmentArray, setSelectedAttachmentArray] = useState<AttachmentInterface[]>([])
    let [roadMapView, setRoadMapView] = useState(false)

    //MOBILE
    let [mobileDisplay, setMobileDisplay] = useState("none")

    //FILTER
    let [filterGroups, setFilterGroups] = useState([])
    let [advancedSearchFilter, setAdvancedSearchFilter] = useState("")
    let [advancedSearchFilterOnFile, setAdvancedSearchFilterOnFile] = useState("")

    //LOADING
    const [loading, setLoading] = useState(false)
    let [loadingRigthContent, setLoadingRigthContent] = useState(false)

    const allRoadmap = async () => {
        try {
            let header = {
                headers: {
                    route: "list_roadmaps",
                },
            }

            const res = await api.get("roadmap", header)

            let auxRoadMap: RoadMapInterface[] = []
            res.data.forEach((attachment: RoadMapInterface) => {
                if (attachment.chat_group_id === null) {
                    attachment.chat_group_id = "000001"
                }
                auxRoadMap.push(attachment)
            })

            roadMaps = auxRoadMap
            setRoadMaps(roadMaps)
            auxRoadMaps = roadMaps
            setAuxRoadMaps(auxRoadMaps)
        } catch (error) {
            console.log(error)
        }
    }

    const allAttachments = async () => {
        try {
            let header = {
                headers: {
                    route: "list_global_attachments",
                },
            }

            const res = await api.get("attachments/global", header)
            let auxiliarAttachments: AttachmentInterface[] = []

            res.data.forEach((attachment: AttachmentInterface) => {
                if (attachment.active) {
                    if (attachment.chat_group_id === null) {
                        attachment.chat_group_id = "000001"
                    }
                    auxiliarAttachments.push(attachment)
                }
            })

            attachments = auxiliarAttachments
            auxAttachments = auxiliarAttachments

            setAuxAttachments(auxAttachments)
            setAttachments(attachments)
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    const allDefaultMessages = async () => {
        try {
            let header = {
                headers: {
                    route: "list_chat_groups",
                },
            }

            const res = await api.get("default_message", header)
            const auxtest: DefaultMessageInterface[] = res.data.map((defaultMessage: DefaultMessageInterface) => {
                defaultMessage.chat_group_id = defaultMessage.chat_group_id === null ? "000001" : defaultMessage.chat_group_id
                return defaultMessage
            })

            setDefaultMessages(auxtest)
            setAuxDefaultMessage(auxtest)
        } catch (error) {
            console.log(error)
        }
    }

    const allGroupsAttachments = async () => {
        try {
            let header = {
                headers: {
                    route: "list_chat_groups",
                },
            }

            const res = await api.get("chat_groups", header)

            groupsAttachments = res.data
            let auxGroup: AttachmentInterface = {} as AttachmentInterface
            auxGroup.name = "SEM CATEGORIA"
            auxGroup.id = "000001"
            setGroupsAttachments([...groupsAttachments, auxGroup])
        } catch (error: any) {
            toast.current?.show({
                severity: "error",
                summary: `Erro ao listar list_chat_groups!`,
                detail: error.response.data.message,
                life: 3000,
            })
        }
    }

    const allSubGroups = async () => {
        try {
            let header = {
                headers: {
                    route: "list_chat_subgroups",
                },
            }

            const res = await api.get("chat_subgroups", header)

            let auxSubGroup: subGroupInterface = {
                id: "000001",
                name: "SEM CATEGORIA",
                chat_group_id: "000001",
                created_at: "",
                updated_at: "",
            }

            const updatedSubGroups = [...res.data, auxSubGroup]
            setSubGroups(updatedSubGroups)
            setAuxSubGroups(res.data)
        } catch (error: any) {
            toast.current?.show({
                severity: "error",
                summary: `Erro ao listar list_chat_subgroups!`,
                detail: error.response.data.message,
                life: 3000,
            })
        }
    }

    const filterSubgroups = () => {
        if (filterGroups.length > 0 || advancedSearchFilter !== "") {
            var listOpportunitiesFiltered: any[] = []
            for (let atualLine of auxSubGroups) {
                var addLine = true

                if (filterGroups.length > 0 && addLine) {
                    const validateSearch = filterGroups.findIndex((group: AttachmentInterface) => group.id === atualLine.chat_group_id)
                    if (validateSearch === -1) {
                        addLine = false
                    }
                }

                if (!(advancedSearchFilter === "") && addLine) {
                    addLine = false

                    var word = advancedSearchFilter.toLowerCase().split(" ")
                    if (!atualLine.name) atualLine.name = ""

                    for (var oneWord in word) {
                        oneWord = oneWord.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

                        if (!atualLine.name.toLowerCase().includes(advancedSearchFilter.toLowerCase())) {
                            addLine = false
                            break
                        } else {
                            addLine = true
                        }
                    }
                }

                if (addLine) {
                    listOpportunitiesFiltered.push(atualLine)
                }
            }
            subGroups = listOpportunitiesFiltered
            setSubGroups(subGroups)
        } else {
            subGroups = auxSubGroups
            setSubGroups(subGroups)
        }
    }

    const searchGroup = (subGroupId: string) => groupsAttachments.find((group) => group.id === subGroupId)?.name || "Sem Grupo"

    const bodyToAttachment = (rowData: subGroupInterface) => {
        return (
            <>
                <div
                    className="desktopTag"
                    style={{ width: "100%" }}
                    onClick={() => {
                        selectedAttachment = []
                        selectedAttachmentArray = []
                        setSelectedAttachment(selectedAttachment)
                        setSelectedAttachmentArray(selectedAttachmentArray)
                    }}
                >
                    <div className="p-d-flex p-ai-center">
                        <div>
                            <i className="pi pi-folder p-mr-3"></i>
                        </div>
                        <div>
                            <span style={{ fontSize: "1.2rem", fontWeight: "500" }}>{rowData.name}</span>
                            <br />
                            <span style={{ fontSize: "0.8rem", fontWeight: "400" }}>{searchGroup(rowData.chat_group_id)} </span>
                        </div>
                    </div>
                </div>

                <div
                    className="mobileTag"
                    style={{ width: "100%" }}
                    onClick={() => {
                        selectedAttachment = []
                        selectedAttachmentArray = []
                        setSelectedAttachment(selectedAttachment)
                        setSelectedAttachmentArray(selectedAttachmentArray)

                        setLoadingRigthContent(true)
                    }}
                >
                    <div className="p-d-flex p-ai-center">
                        <div>
                            <i className="pi pi-folder p-mr-3"></i>
                        </div>
                        <div>
                            <span style={{ fontSize: "1.2rem", fontWeight: "500" }}>{rowData.name}</span>
                            <br />
                            <span style={{ fontSize: "0.8rem", fontWeight: "400" }}>{searchGroup(rowData.chat_group_id)} </span>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const selectBoxFunction = (id: string) => {
        let findOpportunity = selectBoxArrayAttachments.findIndex((attachmentsId) => attachmentsId === id)
        let auxToPush = [...selectBoxArrayAttachments]

        if (findOpportunity === -1) {
            auxToPush.push(id)
            selectBoxArrayAttachments = auxToPush
            setSelectBoxArrayAttachments(selectBoxArrayAttachments)
        }

        if (findOpportunity >= 0) {
            let aux = selectBoxArrayAttachments.filter((idAttachments) => idAttachments !== id)
            selectBoxArrayAttachments = aux
            setSelectBoxArrayAttachments(selectBoxArrayAttachments)
        }
    }

    const typeOfBody = (rowData: any) => {
        if (props.type === "attachments") {
            return bodyToAttachment(rowData)
        }
    }

    const selectedBox = (attachment: AttachmentInterface) => {
        let findOpportunity = selectBoxArrayAttachments.findIndex((attachmentsId) => attachmentsId === attachment.id)

        if (findOpportunity >= 0) {
            return (
                <b>
                    <i className="pi pi-check" style={{ fontSize: "1.2em", color: "green" }}></i>
                </b>
            )
        }
    }

    const buttonSelectedOne = (attachment: AttachmentInterface) => {
        selectedAttachment = [attachment]
        setSelectedAttachment(selectedAttachment)

        if (typeOfAttachment === "image") {
            props.setImagePreviewDialog(true)
            props.sendFile(selectedAttachment)
            props.setImageSelected(selectedAttachment[0])
        }

        if (typeOfAttachment === "video") {
            props.sendFile(selectedAttachment)
            props.setVideoPreviewDialog(true)
            props.setVideoSelected(selectedAttachment)
        }

        if (typeOfAttachment === "document" || typeOfAttachment === "audio" || typeOfAttachment === "sticker") {
            props.sendFileDocument(selectedAttachment)
            props.setConfirmUploadFileDialog(true)
        }
    }

    const buttonSelectedArray = (attachment: AttachmentInterface) => {
        let auxPush: any = [...selectedAttachmentArray]
        let findOpportunity = selectedAttachmentArray.findIndex((opportunity) => opportunity.id === attachment.id)

        if (findOpportunity === -1) {
            auxPush.push(attachment)
            selectedAttachmentArray = auxPush
            setSelectedAttachmentArray(selectedAttachmentArray)
        } else if (findOpportunity >= 0) {
            let removeItem = auxPush.filter((aux: AttachmentInterface) => aux.id !== attachment.id)
            selectedAttachmentArray = removeItem
            setSelectedAttachmentArray(selectedAttachmentArray)
        }

        if (props.type === "attachments") {
            props.sendFile(selectedAttachmentArray)
            props.setImageSelected(selectedAttachmentArray[0])
        }
    }

    const roadMapDocumentDialog = (attachment: AttachmentInterface) => {
        if (attachment.type === "document") {
            const splitArchive = attachment.file.split(".")
            const fileExtension = splitArchive[splitArchive.length - 1]

            if (fileExtension === "pdf") {
                return (
                    <div style={{ width: "auto", height: "20vh" }}>
                        <iframe
                            src={attachment.file_url || ""}
                            frameBorder="0"
                            seamless={false}
                            title="document"
                            scrolling="no"
                            width="100%"
                            style={{ border: "none", overflow: "hidden" }}
                            height="98%"
                        />
                    </div>
                )
            } else {
                return (
                    <div style={{ width: "auto", height: "20vh" }}>
                        <br />
                        <div className="p-d-flex p-jc-center">
                            <i className="pi pi-file" style={{ fontSize: "15vh" }}></i>
                        </div>
                        <br />
                    </div>
                )
            }
        }

        return null
    }

    const messageTextVisualization = (contactMessageWhatsapp: MessagesRoadMapInterface, classNameForAnimation: string, colorBackground: string) => {
        return (
            <>
                <div
                    id={contactMessageWhatsapp.id}
                    key={contactMessageWhatsapp.id}
                    style={{
                        padding: "0.8rem 0rem 0.8rem 0rem",
                    }}
                >
                    <div className={`${classNameForAnimation}`} style={{ marginLeft: "0rem", marginTop: "1rem" }}>
                        <div className="p-grid p-d-flex p-jc-end" style={{ marginRight: "1rem" }}>
                            <div className="" key="1">
                                <p className="p-d-flex p-jc-end" style={{ marginBottom: "-0.1rem", fontSize: "0.8rem" }}>
                                    {profileUser.name} &nbsp;&nbsp;&nbsp;
                                </p>
                                <div className="p-d-flex">
                                    <div className="p-d-flex p-jc-end">
                                        <div
                                            style={{
                                                backgroundColor: `${colorBackground}`,
                                                border: "0.01rem solid #e4e5e7",
                                                borderRadius: "0.8rem 0.8rem 0rem 0.8rem",
                                                boxShadow: "0.04rem 0.04rem 0.06rem #d7d8db",
                                            }}
                                        >
                                            <div className="p-d-flex p-jc-end p-ai-center">
                                                <div
                                                    className="Container"
                                                    style={{
                                                        fontSize: "1.1rem",
                                                        textRendering: "optimizeLegibility",
                                                        maxWidth: `${widthWindow <= 414 ? "15rem" : "45rem"}`,
                                                        marginBottom: "0.5rem",
                                                        marginTop: "0.3rem",
                                                        marginLeft: "0.5rem",
                                                        marginRight: "0.5rem",
                                                        wordBreak: "normal",
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            contactMessageWhatsapp.defaultMessage !== undefined
                                                                ? contactMessageWhatsapp.defaultMessage.text !== undefined
                                                                    ? contactMessageWhatsapp.defaultMessage.text
                                                                    : ""
                                                                : "",
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                    &nbsp;
                                    <Avatar
                                        image={profileUser.avatar_url !== null ? profileUser.avatar_url : noPictureURL}
                                        className="p-mr-2"
                                        shape="circle"
                                        style={{ position: "unset" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const typeOfRoadMap = (attachment: AttachmentInterface) => {
        if (attachment.type === "image") {
            return (
                <>
                    <Image
                        src={attachment !== undefined ? (attachment.file_url !== undefined ? attachment.file_url : "") : ""}
                        width={`${widthWindow <= 414 ? "200" : "300"}`}
                        height="auto"
                        alt="Imagem"
                    />
                </>
            )
        }
        if (attachment.type === "sticker") {
            return <Image src={attachment !== undefined ? (attachment.file_url !== undefined ? attachment.file_url : "") : ""} width="170" alt="Imagem" />
        }

        if (attachment.type === "document") {
            let splitArchive = attachment.file.split(".")
            let lengthSplit = splitArchive.length
            if (splitArchive[lengthSplit - 1] === "pdf") {
                return (
                    <iframe
                        src={attachment !== undefined ? (attachment.file_url !== undefined ? attachment.file_url : "") : ""}
                        frameBorder="0"
                        seamless={false}
                        title="document"
                        scrolling="no"
                        width="100%"
                        style={{ border: "none", overflow: "hidden" }}
                        height="98%"
                    />
                )
            } else {
                return (
                    <div>
                        <br />
                        <div className="p-d-flex p-jc-center">
                            <i className="pi pi-file" style={{ fontSize: "3em", color: "#54656f" }}></i>
                        </div>
                        <br />
                        <div className="p-d-flex p-jc-center" style={{ color: "#54656f" }}>
                            {attachment.name}
                        </div>
                    </div>
                )
            }
        }

        if (attachment.type === "video") {
            return (
                <ReactPlayer
                    styles={{ backgroundColor: "red" }}
                    autoPlay
                    url={attachment !== undefined ? (attachment.file_url !== undefined ? attachment.file_url : "") : ""}
                    width="100%"
                    height="90%"
                    controls={true}
                />
            )
        }

        if (attachment.type === "audio") {
            return (
                <div style={{ width: "25rem" }}>
                    <audio
                        src={attachment !== undefined ? (attachment.file_url !== undefined ? attachment.file_url : "") : ""}
                        className="audio-1"
                        controls
                        playsInline={true}
                        style={{
                            width: "100%",
                            resize: "none",
                            outline: "none",
                            borderRadius: "5px",
                            textDecoration: "bold",
                            fontSize: "1rem",
                        }}
                    />
                </div>
            )
        }
    }

    const messageImageVisualization = (contactMessageWhatsapp: MessagesRoadMapInterface, classNameForAnimation: string) => {
        return (
            <>
                <div id={contactMessageWhatsapp.id} key={contactMessageWhatsapp.id} style={{ padding: "0.8rem 0rem 0.8rem 0rem" }}>
                    <div className={`${classNameForAnimation}`} style={{ marginLeft: "0rem", marginTop: "1rem" }}>
                        <div className="p-grid p-d-flex p-jc-end" style={{ marginBottom: "1.5rem", marginRight: "1rem" }}>
                            <div className="" key="1">
                                <p className="p-d-flex p-jc-end" style={{ marginBottom: "-0.1rem", fontSize: "0.8rem" }}>
                                    {profileUser.name} &nbsp;&nbsp;&nbsp;
                                </p>
                                <div className="p-d-flex">
                                    <div className="p-d-flex p-jc-end">
                                        {widthWindow <= 454 ? (
                                            <>
                                                <a onClick={() => { }} style={{ cursor: "pointer" }}>
                                                    <i className="pi pi-arrow-circle-down" style={{ opacity: "0.4", margin: "0.3rem" }} />
                                                </a>
                                            </>
                                        ) : (
                                            <div></div>
                                        )}
                                        <div
                                            style={{
                                                backgroundColor: "white",
                                                border: "0.01rem solid #e4e5e7",
                                                borderRadius: "0.5rem 0.5rem 0rem 0.5rem",
                                                boxShadow: "0.04rem 0.04rem 0.06rem #d7d8db",
                                            }}
                                        >
                                            <div className="p-d-flex p-jc-end p-ai-center">
                                                <div className="p-d-flex p-jc-end p-ai-center">
                                                    <div
                                                        className="Container"
                                                        style={{
                                                            maxWidth: `${widthWindow <= 414 ? "20rem" : "35rem"}`,
                                                            marginBottom: "0.3rem",
                                                            marginTop: "0.3rem",
                                                            marginLeft: "0.3rem",
                                                            marginRight: "0.3rem",
                                                            wordBreak: "normal",
                                                        }}
                                                    >
                                                        <a
                                                            onClick={() => {
                                                                setViewImageMessage(true)
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <>
                                                                {contactMessageWhatsapp.attachment !== undefined
                                                                    ? typeOfRoadMap(contactMessageWhatsapp.attachment)
                                                                    : ""}
                                                            </>
                                                        </a>

                                                        <div className="p-d-flex p-jc-end p-as-end" style={{ fontSize: "0.65rem", userSelect: "none" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    &nbsp;
                                    <Avatar
                                        icon={profileUser.avatar_url === null ? "pi pi-user" : ""}
                                        image={profileUser.avatar_url}
                                        className="p-mr-2"
                                        shape="circle"
                                        style={{ position: "unset" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const mapOfMessagesRoadMap = () => {
        let dataRoadMap = roadMapSelectVisualization.messages.sort((a: MessagesRoadMapInterface, b: MessagesRoadMapInterface) =>
            a.sequence > b.sequence ? 1 : -1
        )
        let classNameForAnimation = "notAnimated"
        let colorBackground = "white"

        return dataRoadMap.map((roadMap, index) => {
            return <div>{typeOfMessageSentVisualization(roadMap, index, classNameForAnimation, colorBackground)}</div>
        })
    }

    const typeOfMessageSentVisualization = (
        contactMessageWhatsapp: MessagesRoadMapInterface,
        index: number,
        classNameForAnimation: string,
        colorBackground: string
    ) => {
        if (contactMessageWhatsapp.type === "text") {
            return messageTextVisualization(contactMessageWhatsapp, classNameForAnimation, colorBackground)
        }

        if (contactMessageWhatsapp.type === "attachment") {
            return messageImageVisualization(contactMessageWhatsapp, classNameForAnimation)
        }
    }

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    const attachmentOptionsMessages = [
        { name: "Roteiro", value: "roadmap" },
        { name: "Mensagem padrão", value: "default_message" },
    ]

    const attachmentOptions = [
        { name: "Imagens", value: "image" },
        { name: "Videos", value: "video" },
        { name: "Áudios", value: "audio" },
        { name: "Documentos", value: "document" },
        { name: "Sticker", value: "sticker" },
    ]

    const attachmentOptionsVerification24 = [
        { name: "Imagens", value: "image" },
        { name: "Videos", value: "video" },
        { name: "Documentos", value: "document" },
    ]

    const filterAdvancedSearchFilterOnFile = (arrayAux: any[]) => {
        const filteredArray = arrayAux.filter((atualLine) => {
            var word = advancedSearchFilterOnFile.split(" ")
            for (var oneWord of word) {
                let strSearchNormalize = oneWord.normalize("NFD").replace(/[\u0300-\u036f]/g, " ")
                let strSearch = strSearchNormalize.replace(/[\s_-]+/g, "").toLowerCase()

                let atualLineNameNormalize =
                    atualLine?.name?.normalize("NFD").replace(/[\u0300-\u036f]/g, "") || atualLine?.text?.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                let atualLineName = atualLineNameNormalize.replace(/[\s_-]+/g, "").toLowerCase()

                return atualLineName.includes(strSearch)
            }
        })

        return filteredArray
    }

    const searchFilterAdvancedSearchFilterOnFile = useCallback(() => {
        switch (typeOfAttachment) {
            case "document":
            case "video":
            case "audio":
            case "image":
                if (advancedSearchFilterOnFile.length > 0) {
                    attachments = filterAdvancedSearchFilterOnFile(auxAttachments)
                    setAttachments(attachments)
                } else {
                    attachments = auxAttachments
                    setAttachments(attachments)
                }
                return
            case "default_message":
                if (advancedSearchFilterOnFile.length > 0) {
                    defaultMessages = filterAdvancedSearchFilterOnFile(auxDefaultMessage)
                    setDefaultMessages(defaultMessages)
                } else {
                    defaultMessages = auxDefaultMessage
                    setDefaultMessages(defaultMessages)
                }
                return
            case "roadmap":
                if (advancedSearchFilterOnFile.length > 0) {
                    roadMaps = filterAdvancedSearchFilterOnFile(auxRoadMaps)
                    setRoadMaps(roadMaps)
                } else {
                    roadMaps = auxRoadMaps
                    setRoadMaps(roadMaps)
                }
                return
            default:
                return
        }
    }, [advancedSearchFilterOnFile])

    useEffect(() => {
        setLoading(true)
        if (props.type === "attachments") {
            allSubGroups()
            allGroupsAttachments()
            allAttachments()
            allDefaultMessages()
            allRoadmap()
        }
    }, [])

    useEffect(() => {
        selectedAttachment = []
        setSelectedAttachment(selectedAttachment)

        selectedAttachmentArray = []
        setSelectedAttachmentArray(selectedAttachmentArray)

        selectBoxArrayAttachments = []
        setSelectBoxArrayAttachments(selectBoxArrayAttachments)
    }, [idSubgroupToMap])

    return (
        <>
            <Toast ref={toast} />
            <div className="desktopTag">
                <div style={{ width: "95vw", height: "85vh", display: "flex" }}>
                    <div style={{ minWidth: "25rem", width: "30%", height: "100%" }}>
                        <div style={{ height: "fit-content" }}>
                            <div className="p-mb-2 p-mt-2">
                                <SelectButton
                                    value={typeOfAttachment}
                                    options={!props.verification24 ? attachmentOptions : attachmentOptionsVerification24}
                                    onChange={(e) => setTypeOfAttachment(e.value)}
                                    optionLabel="name"
                                />

                                <SelectButton
                                    className="p-mb-2 p-mt-1"
                                    value={typeOfAttachment}
                                    options={attachmentOptionsMessages}
                                    onChange={(e) => setTypeOfAttachment(e.value)}
                                    optionLabel="name"
                                    style={{
                                        margin: "auto",
                                        width: "30rem",
                                        maxWidth: "100%",
                                    }}
                                />
                            </div>

                            <div>
                                <MultiSelect
                                    className="p-mb-2"
                                    value={filterGroups}
                                    options={groupsAttachments}
                                    onChange={(e: any) => {
                                        filterGroups = e.value
                                        setFilterGroups(filterGroups)
                                        filterSubgroups()
                                    }}
                                    emptyFilterMessage={"Não há dados"}
                                    filter={true}
                                    optionLabel="name"
                                    selectedItemsLabel="{0} itens selecionados"
                                    placeholder="GRUPO"
                                />
                                <InputText
                                    className="p-mb-2"
                                    value={advancedSearchFilter}
                                    onChange={(e: any) => {
                                        advancedSearchFilter = e.target.value
                                        setAdvancedSearchFilter(advancedSearchFilter)
                                        filterSubgroups()
                                    }}
                                    id="autofocs"
                                    placeholder="PESQUISA AVANÇADA..."
                                />
                            </div>
                        </div>
                        <DataTable
                            loading={loading}
                            scrollable
                            scrollHeight="calc(100vh - 244px)"
                            selection={idSubgroupToMap}
                            selectionMode="single"
                            onSelectionChange={(e) => {
                                idSubgroupToMap = e.value
                                setIdSubgroupToMap(idSubgroupToMap)
                            }}
                            value={props.type === "attachments" ? subGroups : []}
                            responsiveLayout="scroll"
                            stripedRows
                        >
                            <Column className="columnsDataTable--DialogToDataAllGroups" field="name" header="Subgrupos" sortable body={typeOfBody} />
                        </DataTable>
                    </div>

                    <div style={{ width: "70%", height: "100%", padding: "1rem", display: "flex", position: "relative" }} className="p-d-flex p-flex-wrap">
                        <div style={{ width: "100%", height: "fit-content", display: "grid", gridTemplateColumns: "50% 50%" }}>
                            <div style={{ width: "100%" }}>
                                <div style={{ fontWeight: "600", fontSize: "2rem", userSelect: "none" }}>{idSubgroupToMap.name}</div>
                            </div>
                            <div style={{ width: "100%" }}>
                                {!isEmptyObj(idSubgroupToMap) && (
                                    <InputText
                                        className="p-ml-2"
                                        value={advancedSearchFilterOnFile}
                                        onChange={(e: any) => {
                                            advancedSearchFilterOnFile = e.target.value
                                            setAdvancedSearchFilterOnFile(advancedSearchFilterOnFile)
                                            searchFilterAdvancedSearchFilterOnFile()
                                        }}
                                        id="autofocs"
                                        placeholder="PESQUISAR..."
                                    />
                                )}
                            </div>
                        </div>
                        <>
                            {idSubgroupToMap.id !== "" && (
                                <>
                                    <div className="rightSideOfPage">
                                        {typeOfAttachment !== "roadmap" && typeOfAttachment !== "default_message" && (
                                            <div className=" p-d-flex p-flex-wrap p-jc-center">
                                                {attachments.map((attachment) => {
                                                    if (attachment.chat_subgroup_id === null) {
                                                        attachment.chat_subgroup_id = "000001"
                                                    }

                                                    if (props.type === "attachments") {
                                                        if (
                                                            attachment.chat_subgroup_id === idSubgroupToMap.id &&
                                                            attachment.type === "image" &&
                                                            typeOfAttachment === "image"
                                                        ) {
                                                            auxBoolean = true
                                                            return (
                                                                <div className="cardOfAttachment p-ml-1 p-mr-2 p-mb-3 p-mt-3">
                                                                    <div
                                                                        className="selectBox p-d-flex p-jc-center p-ai-center"
                                                                        onClick={() => {
                                                                            selectBoxFunction(attachment.id)
                                                                            buttonSelectedArray(attachment)
                                                                        }}
                                                                    >
                                                                        {selectedBox(attachment)}
                                                                    </div>

                                                                    <img
                                                                        className="imageContent"
                                                                        src={attachment.file_url}
                                                                        alt="Image"
                                                                        width="230"
                                                                        onClick={() => {
                                                                            buttonSelectedOne(attachment)
                                                                        }}
                                                                    />
                                                                    <br />
                                                                    {attachment.name}
                                                                    <br />
                                                                    <span style={{ fontSize: "0.8rem", fontWeight: "500" }}>
                                                                        {attachment.chat_subgroup_id !== "000001"
                                                                            ? attachment.chatSubgroup.chatGroup.name
                                                                            : "Sem categoria"}
                                                                    </span>
                                                                </div>
                                                            )
                                                        }

                                                        if (
                                                            attachment.chat_subgroup_id === idSubgroupToMap.id &&
                                                            attachment.type === "video" &&
                                                            typeOfAttachment === "video"
                                                        ) {
                                                            auxBoolean = true
                                                            return (
                                                                <div className="cardOfAttachment p-ml-1  p-mr-2 p-mb-3 p-mt-3">
                                                                    <div
                                                                        className="selectBox-video p-d-flex p-jc-center p-ai-center"
                                                                        onClick={() => {
                                                                            selectBoxFunction(attachment.id)
                                                                            buttonSelectedArray(attachment)
                                                                        }}
                                                                    >
                                                                        {selectedBox(attachment)}
                                                                    </div>

                                                                    <div className="p-mb-2">
                                                                        <ReactPlayer
                                                                            className="videoContent"
                                                                            autoplay
                                                                            url={attachment.file_url}
                                                                            width="20rem"
                                                                            height="10rem"
                                                                            controls={true}
                                                                        />
                                                                    </div>
                                                                    {attachment.name}
                                                                    <br />
                                                                    <span style={{ fontSize: "0.8rem", fontWeight: "500" }}>
                                                                        {attachment.chat_subgroup_id !== "000001"
                                                                            ? attachment.chatSubgroup.chatGroup.name
                                                                            : "Sem categoria"}
                                                                    </span>
                                                                    <div className="p-d-flex p-jc-center p-mt-2 p-mb-2">
                                                                        <Button
                                                                            className=""
                                                                            label="Enviar"
                                                                            icon="pi pi-send"
                                                                            style={{
                                                                                width: "100px",
                                                                            }}
                                                                            onClick={() => {
                                                                                buttonSelectedOne(attachment)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                        if (
                                                            attachment.chat_subgroup_id === idSubgroupToMap.id &&
                                                            attachment.type === "audio" &&
                                                            typeOfAttachment === "audio"
                                                        ) {
                                                            auxBoolean = true
                                                            return (
                                                                <div className="cardOfAttachment p-ml-1  p-mr-2 p-mb-3 p-mt-3">
                                                                    <div
                                                                        className="selectBox-video p-d-flex p-jc-center p-ai-center"
                                                                        onClick={() => {
                                                                            selectBoxFunction(attachment.id)
                                                                            buttonSelectedArray(attachment)
                                                                        }}
                                                                    >
                                                                        {selectedBox(attachment)}
                                                                    </div>
                                                                    <div className="p-mb-3" key={attachment.id}>
                                                                        <div style={{ margin: "1rem" }}>
                                                                            <br /> <br />
                                                                            <Avatar
                                                                                style={{
                                                                                    width: "20rem",
                                                                                    marginTop: "20px",
                                                                                    height: "20rem",
                                                                                    margin: "8px",
                                                                                    backgroundColor: "#c2d6f0",
                                                                                    textAlign: "center",
                                                                                    fontSize: "20px",
                                                                                }}
                                                                                className="p-overlay-badge"
                                                                                label={attachment.name}
                                                                                size="xlarge"
                                                                                shape="square"
                                                                            ></Avatar>
                                                                        </div>
                                                                        <audio controls style={{ marginLeft: "12px" }}>
                                                                            <source src={attachment.file_url} />
                                                                        </audio>
                                                                        <br />
                                                                        <span style={{ fontSize: "0.8rem", fontWeight: "500" }}>
                                                                            {attachment.chat_subgroup_id !== "000001"
                                                                                ? attachment.chatSubgroup.chatGroup.name
                                                                                : "Sem categoria"}
                                                                        </span>
                                                                        <br /> <br />
                                                                        <div className="p-d-flex p-jc-center p-mt-2 p-mb-2">
                                                                            <Button
                                                                                className=""
                                                                                label="Enviar"
                                                                                icon="pi pi-send"
                                                                                style={{
                                                                                    width: "100px",
                                                                                }}
                                                                                onClick={() => {
                                                                                    buttonSelectedOne(attachment)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                        if (
                                                            attachment.chat_subgroup_id === idSubgroupToMap.id &&
                                                            attachment.type === "document" &&
                                                            typeOfAttachment === "document"
                                                        ) {
                                                            auxBoolean = true
                                                            return (
                                                                <div className="cardOfAttachment p-ml-1  p-mr-2 p-mb-3 p-mt-3">
                                                                    <div className="p-mb-3" key={attachment.id}>
                                                                        <div
                                                                            className="selectBox-video p-d-flex p-jc-center p-ai-center"
                                                                            onClick={() => {
                                                                                selectBoxFunction(attachment.id)
                                                                                buttonSelectedArray(attachment)
                                                                            }}
                                                                        >
                                                                            {selectedBox(attachment)}
                                                                        </div>

                                                                        <div className="p-mb-3" key={attachment.id}>
                                                                            <div style={{ margin: "1rem" }}>
                                                                                <br /> <br />
                                                                                {roadMapDocumentDialog(attachment)}
                                                                                <h6 style={{ margin: "0.4rem", textAlign: "center" }}>{attachment.name}</h6>
                                                                                <br />
                                                                                <span style={{ fontSize: "0.8rem", fontWeight: "500" }}>
                                                                                    {attachment.chat_subgroup_id !== "000001"
                                                                                        ? attachment.chatSubgroup.chatGroup.name
                                                                                        : "Sem categoria"}
                                                                                </span>
                                                                            </div>
                                                                            <br />
                                                                            <div className="p-d-flex p-jc-center p-mt-2 p-mb-2">
                                                                                <Button
                                                                                    className=""
                                                                                    label="Enviar"
                                                                                    icon="pi pi-send"
                                                                                    style={{
                                                                                        width: "100px",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        buttonSelectedOne(attachment)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                        if (
                                                            attachment.chat_subgroup_id === idSubgroupToMap.id &&
                                                            attachment.type === "sticker" &&
                                                            typeOfAttachment === "sticker"
                                                        ) {
                                                            auxBoolean = true
                                                            return (
                                                                <div className="cardOfAttachment p-ml-1  p-mr-2 p-mb-3 p-mt-3">
                                                                    <div
                                                                        className="selectBox-video p-d-flex p-jc-center p-ai-center"
                                                                        onClick={() => {
                                                                            selectBoxFunction(attachment.id)
                                                                            buttonSelectedArray(attachment)
                                                                        }}
                                                                    >
                                                                        {selectedBox(attachment)}
                                                                    </div>

                                                                    <Avatar
                                                                        style={{ width: "20rem", height: "20rem", margin: "8px" }}
                                                                        className="p-overlay-badge"
                                                                        image={attachment.file_url}
                                                                        size="xlarge"
                                                                        shape="square"
                                                                    ></Avatar>
                                                                    <h6 style={{ margin: "0.4rem", textAlign: "center" }}>{attachment.name}</h6>
                                                                    <br />
                                                                    <span style={{ fontSize: "0.8rem", fontWeight: "500" }}>
                                                                        {attachment.chat_subgroup_id !== "000001"
                                                                            ? attachment.chatSubgroup.chatGroup.name
                                                                            : "Sem categoria"}
                                                                    </span>
                                                                    <div className="p-d-flex p-jc-center p-mt-2 p-mb-2">
                                                                        <Button
                                                                            className=""
                                                                            label="Enviar"
                                                                            icon="pi pi-send"
                                                                            style={{
                                                                                width: "100px",
                                                                            }}
                                                                            onClick={() => {
                                                                                buttonSelectedOne(attachment)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    }
                                                })}
                                                {!auxBoolean && <div>Nenhum arquivo foi encontrado</div>}
                                            </div>
                                        )}

                                        {typeOfAttachment === "default_message" && (
                                            <>
                                                {defaultMessages.map((defaultMessage) => {
                                                    if (defaultMessage.chat_subgroup_id === null) {
                                                        defaultMessage.chat_subgroup_id = "000001"
                                                    }

                                                    if (defaultMessage.chat_subgroup_id === idSubgroupToMap.id && typeOfAttachment === "default_message") {
                                                        auxBoolean = true
                                                        return (
                                                            <div className="" style={{ paddingTop: "1rem", marginLeft: "auto", marginRight: "auto" }}>
                                                                <div className="p-d-flex" style={{ display: "flex", justifyContent: "center" }}>
                                                                    <div className="">
                                                                        <div
                                                                            style={{
                                                                                border: "0.01rem solid #e4e5e7",
                                                                                borderRadius: "0.8rem 0.8rem 0rem 0.8rem",
                                                                                boxShadow: "0.04rem 0.04rem 0.06rem #d7d8db",
                                                                            }}
                                                                        >
                                                                            {defaultMessage.text.includes("https://") && (

                                                                                <iframe src={defaultMessage.text} width="100%" height="100%" />
                                                                            )}

                                                                            <div className="p-d-flex p-jc-end p-ai-center">
                                                                                <div
                                                                                    className="Container"
                                                                                    style={{
                                                                                        fontSize: "1.1rem",
                                                                                        textRendering: "optimizeLegibility",
                                                                                        maxWidth: `${widthWindow <= 414 ? "15rem" : "45rem"}`,
                                                                                        marginBottom: "0.5rem",
                                                                                        marginLeft: "0.5rem",
                                                                                        marginRight: "0.5rem",
                                                                                        wordBreak: "normal",
                                                                                    }}
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html:
                                                                                            defaultMessage !== undefined
                                                                                                ? defaultMessage.text !== undefined
                                                                                                    ? defaultMessage.text
                                                                                                    : ""
                                                                                                : "",
                                                                                    }}
                                                                                ></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    &nbsp;
                                                                    <Avatar
                                                                        image={user.avatar_url !== null ? user.avatar_url : noPictureURL}
                                                                        className="p-mr-2"
                                                                        shape="circle"
                                                                        style={{ position: "unset" }}
                                                                    />
                                                                </div>
                                                                <br />
                                                                {props.firstDefaultMessage === false && (
                                                                    <Button
                                                                        label="Selecionar"
                                                                        className="p-button-rounded"
                                                                        style={{ width: "90px", display: "block", marginLeft: "auto", marginRight: "auto" }}
                                                                        onClick={() => {
                                                                            props.setDefaultMessageItem(defaultMessage)
                                                                            props.setImageUploadDialog(false)
                                                                        }}
                                                                    />
                                                                )}
                                                                {props.firstDefaultMessage === true && (
                                                                    <Button
                                                                        label="Selecionar"
                                                                        className="p-button-rounded"
                                                                        style={{ width: "90px", display: "block", marginLeft: "auto", marginRight: "auto" }}
                                                                        onClick={() => {
                                                                            props.setDefaultMessageItem(defaultMessage)
                                                                            props?.onSendFirstContactDefaultMessage()
                                                                            props.setImageUploadDialog(false)
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        )
                                                    }
                                                })}
                                                {!auxBoolean && <div className="p-d-flex p-jc-center">Nenhum arquivo foi adicionado nesse subgrupo</div>}
                                            </>
                                        )}

                                        {typeOfAttachment === "roadmap" && (
                                            <div className="p-d-flex">
                                                {roadMaps.map((roadMap) => {
                                                    if (roadMap.chat_subgroup_id === null) {
                                                        roadMap.chat_subgroup_id = "000001"
                                                    }
                                                    if (roadMap.chat_subgroup_id === idSubgroupToMap.id && typeOfAttachment === "roadmap") {
                                                        auxBoolean = true
                                                        return (
                                                            <div className="p-d-flex">
                                                                <div className="p-mb-3" key={roadMap.id}>
                                                                    <Card
                                                                        style={{
                                                                            width: "13rem",
                                                                            height: "13rem",
                                                                            margin: "8px",
                                                                            textAlign: "center",
                                                                            marginTop: "1.5rem",
                                                                            paddingTop: "15%",
                                                                            borderStyle: "solid",
                                                                            borderRadius: "10px",
                                                                            borderWidth: "1px",
                                                                        }}
                                                                        className="p-overlay-badge"
                                                                    >
                                                                        <p style={{ fontSize: "18px" }}>{roadMap.name}</p>
                                                                        <p style={{ fontSize: "10px" }}>
                                                                            {moment(roadMap.created_at).format("DD/MM/YYYY HH:mm")}
                                                                        </p>
                                                                    </Card>
                                                                    <br />
                                                                    <Button
                                                                        label="Visualizar"
                                                                        className="p-button-rounded"
                                                                        style={{
                                                                            width: "90px",
                                                                            textAlign: "center",
                                                                            margin: 0,
                                                                            marginLeft: "29%",
                                                                        }}
                                                                        onClick={() => {
                                                                            props.setRoadMapSelectVisualization(roadMap)
                                                                            props.setOpenModalVisualizationRoudMap(true)
                                                                            props.setRoadMapDialog(true)
                                                                        }}
                                                                    />
                                                                    <br /> <br />
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                    if (roadMapView) {
                                                        ; <div>
                                                            {!isEmptyObj(roadMapSelectVisualization) && (
                                                                <div>
                                                                    <div>{mapOfMessagesRoadMap()}</div>
                                                                </div>
                                                            )}
                                                            {isEmptyObj(roadMapSelectVisualization.messages) && (
                                                                <div style={{ userSelect: "none" }}>*Este roteiro ainda não possui mensagens</div>
                                                            )}
                                                        </div>
                                                    }
                                                })}
                                                {!auxBoolean && <div className="p-d-flex p-jc-center">Nenhum arquivo foi adicionado nesse subgrupo</div>}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {selectedAttachmentArray.length > 0 && (
                                            <Button
                                                className="buttonToSendArray"
                                                label="Enviar"
                                                icon="pi pi-send"
                                                style={{
                                                    width: "120px",
                                                    position: "fixed",
                                                }}
                                                onClick={() => {
                                                    if (typeOfAttachment === "image") {
                                                        props.setImagePreviewDialog(true)
                                                        props.sendFile(selectedAttachmentArray)
                                                        props.imageUploadDialog(false)
                                                        props.setImageSelected(selectedAttachmentArray[0])
                                                    }
                                                    if (typeOfAttachment === "video") {
                                                        props.sendFile(selectedAttachmentArray)
                                                        props.setVideoPreviewDialog(true)
                                                        props.setVideoSelected(selectedAttachmentArray[0])
                                                    }
                                                    if (typeOfAttachment === "document") {
                                                        props.sendFile(selectedAttachmentArray)
                                                        props.sendFileDocument(selectedAttachmentArray)
                                                        props.setConfirmUploadFileDialog(true)
                                                    }

                                                    if (typeOfAttachment === "audio" || typeOfAttachment === "sticker") {
                                                        props.sendFileDocument(selectedAttachmentArray)
                                                        props.setConfirmUploadFileDialog(true)
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                        </>
                    </div>
                </div>
            </div>

            <div className="mobileTag">
                <div style={{ height: "100%", display: "flex", justifyContent: "center" }}>
                    <div style={{ minWidth: "25rem", width: "100%", height: "100%", display: `${mobileDisplay === "none" ? "" : "none"}` }}>
                        <div className="p-mb-2 p-mt-2">
                            <SelectButton
                                value={typeOfAttachment}
                                options={!props.verification24 ? attachmentOptions : attachmentOptionsVerification24}
                                onChange={(e) => setTypeOfAttachment(e.value)}
                                optionLabel="name"
                            />

                            <SelectButton
                                className="p-mb-2 p-mt-1"
                                value={typeOfAttachment}
                                options={attachmentOptionsMessages}
                                onChange={(e) => setTypeOfAttachment(e.value)}
                                optionLabel="name"
                                style={{
                                    margin: "auto",
                                    width: "30rem",
                                    maxWidth: "100%",
                                }}
                            />
                        </div>

                        <div style={{ height: "fit-content" }}>
                            <MultiSelect
                                className="p-mb-2"
                                value={filterGroups}
                                options={groupsAttachments}
                                onChange={(e: any) => {
                                    filterGroups = e.value
                                    setFilterGroups(filterGroups)
                                    filterSubgroups()
                                }}
                                emptyFilterMessage={"Não há dados"}
                                filter={true}
                                optionLabel="name"
                                selectedItemsLabel="{0} itens selecionados"
                                placeholder="GRUPO"
                            />
                            <InputText
                                className="p-mb-2"
                                value={advancedSearchFilter}
                                onChange={(e: any) => {
                                    advancedSearchFilter = e.target.value
                                    setAdvancedSearchFilter(advancedSearchFilter)
                                    filterSubgroups()
                                }}
                                id="autofocs"
                                placeholder="PESQUISA AVANÇADA..."
                            />
                        </div>

                        <DataTable
                            loading={loading}
                            scrollable
                            scrollHeight="calc(100vh - 360px)"
                            selectionMode="single"
                            onSelectionChange={(e) => {
                                idSubgroupToMap = e.value
                                setIdSubgroupToMap(idSubgroupToMap)

                                setMobileDisplay("flex")
                            }}
                            value={props.type === "attachments" ? subGroups : []}
                            responsiveLayout="scroll"
                            stripedRows
                        >
                            <Column className="columnsDataTable--DialogToDataAllGroups" field="name" header="Subgrupos" sortable body={typeOfBody} />
                        </DataTable>
                    </div>

                    <div style={{ display: `${mobileDisplay}` }}>
                        <div style={{ width: "100%", height: "100%", padding: "1rem", position: "relative" }} className="p-d-flex p-flex-wrap">
                            <Button
                                style={{
                                    position: "fixed",
                                    width: "300px",
                                    top: "90%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    zIndex: "99",
                                }}
                                label="Retornar aos subgrupos"
                                aria-label="Submit"
                                onClick={() => {
                                    setMobileDisplay("none")
                                }}
                            ></Button>

                            <>
                                {idSubgroupToMap.id !== "" && (
                                    <>
                                        <div className="rightSideOfPage">
                                            {typeOfAttachment !== "roadmap" && typeOfAttachment !== "default_message" && (
                                                <div className=" p-d-flex p-flex-wrap p-jc-center">
                                                    {attachments.map((attachment) => {
                                                        if (attachment.chat_subgroup_id === null) {
                                                            attachment.chat_subgroup_id = "000001"
                                                        }
                                                        if (props.type === "attachments") {
                                                            if (
                                                                attachment.chat_subgroup_id === idSubgroupToMap.id &&
                                                                attachment.type === "image" &&
                                                                typeOfAttachment === "image"
                                                            ) {
                                                                auxBoolean = true
                                                                return (
                                                                    <div className="cardOfAttachment p-ml-1 p-mr-2 p-mb-3 p-mt-3">
                                                                        <div
                                                                            className="selectBox p-d-flex p-jc-center p-ai-center"
                                                                            onClick={() => {
                                                                                selectBoxFunction(attachment.id)
                                                                                buttonSelectedArray(attachment)
                                                                            }}
                                                                        >
                                                                            {selectedBox(attachment)}
                                                                        </div>

                                                                        <img
                                                                            className="imageContent"
                                                                            src={attachment.file_url}
                                                                            alt="Image"
                                                                            width="230"
                                                                            onClick={() => {
                                                                                buttonSelectedOne(attachment)
                                                                            }}
                                                                        />
                                                                        <br />
                                                                        {attachment.name}
                                                                        <br />
                                                                        <span style={{ fontSize: "0.8rem", fontWeight: "500" }}>
                                                                            {attachment.chat_subgroup_id !== "000001"
                                                                                ? attachment.chatSubgroup.chatGroup.name
                                                                                : "Sem categoria"}
                                                                        </span>
                                                                    </div>
                                                                )
                                                            }

                                                            if (
                                                                attachment.chat_subgroup_id === idSubgroupToMap.id &&
                                                                attachment.type === "video" &&
                                                                typeOfAttachment === "video"
                                                            ) {
                                                                auxBoolean = true
                                                                return (
                                                                    <div className="cardOfAttachment p-ml-1  p-mr-2 p-mb-3 p-mt-3">
                                                                        <div
                                                                            className="selectBox-video p-d-flex p-jc-center p-ai-center"
                                                                            onClick={() => {
                                                                                selectBoxFunction(attachment.id)
                                                                                buttonSelectedArray(attachment)
                                                                            }}
                                                                        >
                                                                            {selectedBox(attachment)}
                                                                        </div>

                                                                        <div className="p-mb-2">
                                                                            <ReactPlayer
                                                                                className="videoContent"
                                                                                autoplay
                                                                                url={attachment.file_url}
                                                                                width="20rem"
                                                                                height="10rem"
                                                                                controls={true}
                                                                            />
                                                                        </div>
                                                                        {attachment.name}
                                                                        <br />
                                                                        <span style={{ fontSize: "0.8rem", fontWeight: "500" }}>
                                                                            {attachment.chat_subgroup_id !== "000001"
                                                                                ? attachment.chatSubgroup.chatGroup.name
                                                                                : "Sem categoria"}
                                                                        </span>
                                                                        <div className="p-d-flex p-jc-center p-mt-2 p-mb-2">
                                                                            <Button
                                                                                className=""
                                                                                label="Enviar"
                                                                                icon="pi pi-send"
                                                                                style={{
                                                                                    width: "100px",
                                                                                }}
                                                                                onClick={() => {
                                                                                    buttonSelectedOne(attachment)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                            if (
                                                                attachment.chat_subgroup_id === idSubgroupToMap.id &&
                                                                attachment.type === "audio" &&
                                                                typeOfAttachment === "audio"
                                                            ) {
                                                                auxBoolean = true
                                                                return (
                                                                    <div className="cardOfAttachment p-ml-1  p-mr-2 p-mb-3 p-mt-3">
                                                                        <div
                                                                            className="selectBox-video p-d-flex p-jc-center p-ai-center"
                                                                            onClick={() => {
                                                                                selectBoxFunction(attachment.id)
                                                                                buttonSelectedArray(attachment)
                                                                            }}
                                                                        >
                                                                            {selectedBox(attachment)}
                                                                        </div>
                                                                        <div className="p-mb-3" key={attachment.id}>
                                                                            <div style={{ margin: "1rem" }}>
                                                                                <br /> <br />
                                                                                <Avatar
                                                                                    style={{
                                                                                        width: "20rem",
                                                                                        marginTop: "20px",
                                                                                        height: "20rem",
                                                                                        margin: "8px",
                                                                                        backgroundColor: "#c2d6f0",
                                                                                        textAlign: "center",
                                                                                        fontSize: "20px",
                                                                                    }}
                                                                                    className="p-overlay-badge"
                                                                                    label={attachment.name}
                                                                                    size="xlarge"
                                                                                    shape="square"
                                                                                ></Avatar>
                                                                            </div>
                                                                            <audio controls style={{ marginLeft: "12px" }}>
                                                                                <source src={attachment.file_url} />
                                                                            </audio>
                                                                            <br />
                                                                            <span style={{ fontSize: "0.8rem", fontWeight: "500" }}>
                                                                                {attachment.chat_subgroup_id !== "000001"
                                                                                    ? attachment.chatSubgroup.chatGroup.name
                                                                                    : "Sem categoria"}
                                                                            </span>
                                                                            <br /> <br />
                                                                            <div className="p-d-flex p-jc-center p-mt-2 p-mb-2">
                                                                                <Button
                                                                                    className=""
                                                                                    label="Enviar"
                                                                                    icon="pi pi-send"
                                                                                    style={{
                                                                                        width: "100px",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        buttonSelectedOne(attachment)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                            if (
                                                                attachment.chat_subgroup_id === idSubgroupToMap.id &&
                                                                attachment.type === "document" &&
                                                                typeOfAttachment === "document"
                                                            ) {
                                                                auxBoolean = true
                                                                return (
                                                                    <div className="cardOfAttachment p-ml-1  p-mr-2 p-mb-3 p-mt-3">
                                                                        <div className="p-mb-3" key={attachment.id}>
                                                                            <div
                                                                                className="selectBox-video p-d-flex p-jc-center p-ai-center"
                                                                                onClick={() => {
                                                                                    selectBoxFunction(attachment.id)
                                                                                    buttonSelectedArray(attachment)
                                                                                }}
                                                                            >
                                                                                {selectedBox(attachment)}
                                                                            </div>

                                                                            <div className="p-mb-3" key={attachment.id}>
                                                                                <div style={{ margin: "1rem" }}>
                                                                                    <br /> <br />
                                                                                    {roadMapDocumentDialog(attachment)}
                                                                                    <h6 style={{ margin: "0.4rem", textAlign: "center" }}>{attachment.name}</h6>
                                                                                    <br />
                                                                                    <span style={{ fontSize: "0.8rem", fontWeight: "500" }}>
                                                                                        {attachment.chat_subgroup_id !== "000001"
                                                                                            ? attachment.chatSubgroup.chatGroup.name
                                                                                            : "Sem categoria"}
                                                                                    </span>
                                                                                </div>
                                                                                <br />
                                                                                <div className="p-d-flex p-jc-center p-mt-2 p-mb-2">
                                                                                    <Button
                                                                                        className=""
                                                                                        label="Enviar"
                                                                                        icon="pi pi-send"
                                                                                        style={{
                                                                                            width: "100px",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            buttonSelectedOne(attachment)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                            if (
                                                                attachment.chat_subgroup_id === idSubgroupToMap.id &&
                                                                attachment.type === "sticker" &&
                                                                typeOfAttachment === "sticker"
                                                            ) {
                                                                auxBoolean = true
                                                                return (
                                                                    <div className="cardOfAttachment p-ml-1  p-mr-2 p-mb-3 p-mt-3">
                                                                        <div
                                                                            className="selectBox-video p-d-flex p-jc-center p-ai-center"
                                                                            onClick={() => {
                                                                                selectBoxFunction(attachment.id)
                                                                                buttonSelectedArray(attachment)
                                                                            }}
                                                                        >
                                                                            {selectedBox(attachment)}
                                                                        </div>

                                                                        <Avatar
                                                                            style={{ width: "20rem", height: "20rem", margin: "8px" }}
                                                                            className="p-overlay-badge"
                                                                            image={attachment.file_url}
                                                                            size="xlarge"
                                                                            shape="square"
                                                                        ></Avatar>
                                                                        <h6 style={{ margin: "0.4rem", textAlign: "center" }}>{attachment.name}</h6>
                                                                        <br />
                                                                        <span style={{ fontSize: "0.8rem", fontWeight: "500" }}>
                                                                            {attachment.chat_subgroup_id !== "000001"
                                                                                ? attachment.chatSubgroup.chatGroup.name
                                                                                : "Sem categoria"}
                                                                        </span>
                                                                        <div className="p-d-flex p-jc-center p-mt-2 p-mb-2">
                                                                            <Button
                                                                                className=""
                                                                                label="Enviar"
                                                                                icon="pi pi-send"
                                                                                style={{
                                                                                    width: "100px",
                                                                                }}
                                                                                onClick={() => {
                                                                                    buttonSelectedOne(attachment)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                    })}
                                                    {!auxBoolean && <div>Nenhum arquivo foi adicionado nesse subgrupo</div>}
                                                </div>
                                            )}

                                            {typeOfAttachment === "default_message" && (
                                                <>
                                                    {defaultMessages.map((defaultMessage) => {
                                                        if (defaultMessage.chat_subgroup_id === null) {
                                                            defaultMessage.chat_subgroup_id = "000001"
                                                        }

                                                        if (defaultMessage.chat_subgroup_id === idSubgroupToMap.id && typeOfAttachment === "default_message") {
                                                            auxBoolean = true
                                                            return (
                                                                <div className="" style={{ paddingTop: "1rem", marginLeft: "auto", marginRight: "auto" }}>
                                                                    <div className="p-d-flex" style={{ display: "flex", justifyContent: "center" }}>
                                                                        <div className="">
                                                                            <div
                                                                                style={{
                                                                                    border: "0.01rem solid #e4e5e7",
                                                                                    borderRadius: "0.8rem 0.8rem 0rem 0.8rem",
                                                                                    boxShadow: "0.04rem 0.04rem 0.06rem #d7d8db",
                                                                                }}
                                                                            >
                                                                                {defaultMessage.text.includes("https://") && (

                                                                                    <iframe src={defaultMessage.text} width="100%" height="100%" />
                                                                                )}

                                                                                <div className="p-d-flex p-jc-end p-ai-center">
                                                                                    <div
                                                                                        className="Container"
                                                                                        style={{
                                                                                            fontSize: "1.1rem",
                                                                                            textRendering: "optimizeLegibility",
                                                                                            maxWidth: `${widthWindow <= 414 ? "15rem" : "45rem"}`,
                                                                                            marginBottom: "0.5rem",
                                                                                            marginLeft: "0.5rem",
                                                                                            marginRight: "0.5rem",
                                                                                            wordBreak: "normal",
                                                                                        }}
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html:
                                                                                                defaultMessage !== undefined
                                                                                                    ? defaultMessage.text !== undefined
                                                                                                        ? defaultMessage.text
                                                                                                        : ""
                                                                                                    : "",
                                                                                        }}
                                                                                    ></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        &nbsp;
                                                                        <Avatar
                                                                            image={user.avatar_url !== null ? user.avatar_url : noPictureURL}
                                                                            className="p-mr-2"
                                                                            shape="circle"
                                                                            style={{ position: "unset" }}
                                                                        />
                                                                    </div>
                                                                    <br />
                                                                    {props.firstDefaultMessage === false && (
                                                                        <Button
                                                                            label="Selecionar"
                                                                            className="p-button-rounded"
                                                                            style={{ width: "90px", display: "block", marginLeft: "auto", marginRight: "auto" }}
                                                                            onClick={() => {
                                                                                props.setDefaultMessageItem(defaultMessage)
                                                                                props.setImageUploadDialog(false)
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {props.firstDefaultMessage === true && (
                                                                        <Button
                                                                            label="Selecionar"
                                                                            className="p-button-rounded"
                                                                            style={{ width: "90px", display: "block", marginLeft: "auto", marginRight: "auto" }}
                                                                            onClick={() => {
                                                                                props.setDefaultMessageItem(defaultMessage)
                                                                                props?.onSendFirstContactDefaultMessage()
                                                                                props.setImageUploadDialog(false)
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                    {!auxBoolean && <div className="p-d-flex p-jc-center">Nenhum arquivo foi adicionado nesse subgrupo</div>}
                                                </>
                                            )}

                                            {typeOfAttachment === "roadmap" && (
                                                <div className="p-d-flex">
                                                    {roadMaps.map((roadMap) => {
                                                        if (roadMap.chat_subgroup_id === null) {
                                                            roadMap.chat_subgroup_id = "000001"
                                                        }
                                                        if (roadMap.chat_subgroup_id === idSubgroupToMap.id && typeOfAttachment === "roadmap") {
                                                            auxBoolean = true
                                                            return (
                                                                <div className="p-d-flex">
                                                                    <div className="p-mb-3" key={roadMap.id}>
                                                                        <Card
                                                                            style={{
                                                                                width: "13rem",
                                                                                height: "13rem",
                                                                                margin: "8px",
                                                                                textAlign: "center",
                                                                                marginTop: "1.5rem",
                                                                                paddingTop: "15%",
                                                                                borderStyle: "solid",
                                                                                borderRadius: "10px",
                                                                                borderWidth: "1px",
                                                                            }}
                                                                            className="p-overlay-badge"
                                                                        >
                                                                            <p style={{ fontSize: "18px" }}>{roadMap.name}</p>
                                                                            <p style={{ fontSize: "10px" }}>
                                                                                {moment(roadMap.created_at).format("DD/MM/YYYY HH:mm")}
                                                                            </p>
                                                                        </Card>
                                                                        <br />
                                                                        <Button
                                                                            label="Visualizar"
                                                                            className="p-button-rounded"
                                                                            style={{
                                                                                width: "90px",
                                                                                textAlign: "center",
                                                                                margin: 0,
                                                                                marginLeft: "29%",
                                                                            }}
                                                                            onClick={() => {
                                                                                props.setRoadMapSelectVisualization(roadMap)
                                                                                props.setOpenModalVisualizationRoudMap(true)
                                                                                props.setRoadMapDialog(true)
                                                                            }}
                                                                        />
                                                                        <br /> <br />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                        if (roadMapView) {
                                                            ; <div>
                                                                {!isEmptyObj(roadMapSelectVisualization) && (
                                                                    <div>
                                                                        <div>{mapOfMessagesRoadMap()}</div>
                                                                    </div>
                                                                )}
                                                                {isEmptyObj(roadMapSelectVisualization.messages) && (
                                                                    <div style={{ userSelect: "none" }}>*Este roteiro ainda não possui mensagens</div>
                                                                )}
                                                            </div>
                                                        }
                                                    })}
                                                    {!auxBoolean && <div className="p-d-flex p-jc-center">Nenhum arquivo foi adicionado nesse subgrupo</div>}
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            {selectedAttachmentArray.length > 0 && (
                                                <Button
                                                    className="buttonToSendArray"
                                                    label="Enviar"
                                                    icon="pi pi-send"
                                                    style={{
                                                        width: "120px",
                                                        position: "fixed",
                                                    }}
                                                    onClick={() => {
                                                        if (typeOfAttachment === "image") {
                                                            props.setImagePreviewDialog(true)
                                                            props.sendFile(selectedAttachmentArray)
                                                            props.imageUploadDialog(false)
                                                            props.setImageSelected(selectedAttachmentArray[0])
                                                        }
                                                        if (typeOfAttachment === "video") {
                                                            props.sendFile(selectedAttachmentArray)
                                                            props.setVideoPreviewDialog(true)
                                                            props.setVideoSelected(selectedAttachmentArray[0])
                                                        }
                                                        if (typeOfAttachment === "document") {
                                                            props.sendFile(selectedAttachmentArray)
                                                            props.sendFileDocument(selectedAttachmentArray)
                                                            props.setConfirmUploadFileDialog(true)
                                                        }

                                                        if (typeOfAttachment === "audio") {
                                                            props.sendFileDocument(selectedAttachmentArray)
                                                            props.setConfirmUploadFileDialog(true)
                                                        }

                                                        if (typeOfAttachment === "sticker") {
                                                            props.sendFileDocument(selectedAttachmentArray)
                                                            props.setConfirmUploadFileDialog(true)
                                                        }
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </>
                                )}
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(DialogToDataAllGroups)
