import { io } from "socket.io-client"

const auxUser = localStorage.getItem("@esquadros:user")
var user: any = ""

if (auxUser) {
    user = JSON.parse(auxUser)
}

export const socket = io(`${process.env.REACT_APP_CONNECTION}`, {
    transports: ["websocket", "polling", "flashsocket"],
    reconnection: true,
    secure: true,
    query: {
        user: user.name,
    },
})
