import { Avatar } from "primereact/avatar"
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Rating } from "primereact/rating"
import { Toast } from "primereact/toast"
import { memo } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import Moment from "react-moment"
import { Funnel } from "../../funnelStages/Funnel"
import { Chip } from "primereact/chip"

const Opportunities = (props: any) => {
    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    const funnelStageTotalValue = (id: any) => {
        var totalValue = 0
        props.auxOpportunities.forEach((opportunityItem: any) => {
            if (id === opportunityItem.funnel_stages_id) {
                if (opportunityItem.status === "deleted") {
                    return
                }
                totalValue += opportunityItem.totalValueMachines
            }
        })
        return (
            <>
                <div style={{ fontSize: "0.85rem" }}>
                    {totalValue.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    })}{" "}
                    <i className="pi pi-chart-bar" style={{ marginLeft: "0.2rem", fontSize: "0.6rem" }}></i>{" "}
                </div>
            </>
        )
    }

    const funnelStageTotalValueByQualification = (qualification: number) => {
        var totalValue = 0

        props.opportunities.forEach((opportunityItem: any) => {
            if (qualification === opportunityItem.qualification) {
                totalValue += opportunityItem.totalValueMachines
            }
        })

        return (
            <>
                <div style={{ fontSize: "0.85rem" }}>
                    {totalValue.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    })}{" "}
                    <i className="pi pi-chart-bar" style={{ marginLeft: "0.2rem", fontSize: "0.6rem" }}></i>{" "}
                </div>
            </>
        )
    }

    const nameOpportunity = (rowData: any) => {
        const linkId = `/crm/leads/${rowData.id}`
        return (
            <>
                <div className="p-d-flex">
                    <div className="p-d-flex p-as-center">
                        <a
                            href="#"
                            onClick={() => {
                                props.setDisplayDialogCompanyInformationsAvatar(true)
                                props.opportunity = rowData
                                props.setOpportunity(rowData)
                            }}
                        >
                            <Avatar
                                icon={rowData.company.picture_url === null ? "pi pi-user" : ""}
                                image={rowData.company.picture_url}
                                className="p-mr-2"
                                size="large"
                                shape="circle"
                            />
                        </a>
                    </div>
                    &nbsp;&nbsp;
                    <div>
                        <div>
                            <div>
                                {rowData.new === true || rowData.new_on_funnel === true ? (
                                    <div
                                        className="p-d-flex p-jc-center"
                                        style={{
                                            color: "#689F38",
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: "0.7rem",
                                                fontWeight: "600",
                                            }}
                                        >
                                            NOVA OPORTUNIDADE!&nbsp;
                                        </div>

                                        <i
                                            className="pi pi-star-fill"
                                            style={{
                                                fontSize: "0.7rem",
                                            }}
                                        ></i>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                                <div className="numberCompany p-d-flex p-text-nowrap p-text-truncate">
                                    <a href={linkId} target="_blank" rel="noreferrer">
                                        <p
                                            className="p-mb-0"
                                            style={{
                                                color: `${props.colorStatusOpportunity(rowData.status)}`,
                                            }}
                                        >
                                            <strong>{("000000" + rowData.number).slice(-6)} - </strong>
                                        </p>
                                    </a>
                                    &nbsp;
                                    <a href={linkId} target="_blank" rel="noreferrer">
                                        <p
                                            className="p-text-nowrap p-text-truncate"
                                            style={{ fontWeight: "600", fontSize: "1.1rem", color: "rgba(41, 50, 65, 0.8)", maxWidth: "18rem" }}
                                        >
                                            {rowData.name}
                                        </p>
                                    </a>
                                </div>
                            </div>
                            <a href={linkId}>
                                <div className="nameCompany p-text-nowrap p-text-truncate" style={{ maxWidth: "18rem" }}>
                                    {rowData.company.name}
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const cnpjOpportunityColumns = (rowData: any) => {
        if (rowData.company.cnpj !== null) {
            return (
                <>
                    <div>{rowData.company.cnpj}</div>
                </>
            )
        } else {
            return <div>-</div>
        }
    }

    const statusOpportunityColumns = (rowData: any) => {
        return (
            <>
                <div style={{ color: `${props.colorStatusOpportunity(rowData.status)}` }}>{props.replaceStatus(rowData.status)}</div>
            </>
        )
    }

    const delayedDaysOfListining = (rowData: any) => {
        let statusClass = ""
        let name = ""
        switch (rowData.late) {
            case false:
                statusClass = "instock"
                name = "Em dia"
                break

            case true:
                statusClass = "outofstock"
                name = `HÁ ${Math.round(rowData.delayedDays)} DIA(S)`
                break
        }

        return <span className={`product-badge status-${statusClass}`}>{name}</span>
    }

    const lastInterationOfListining = (rowData: any) => {
        if (rowData.dateLastInteraction !== null) {
            return (
                <>
                    {rowData.daysWithoutInteraction !== null ? (
                        <div>
                            <div>
                                <Moment format="DD/MM/YYYY">{rowData.dateLastInteraction}</Moment>
                            </div>
                            <div style={{ fontSize: "0.85rem" }}> (HÁ {Math.round(rowData.daysWithoutInteraction)} DIA(S)) </div>
                        </div>
                    ) : (
                        ""
                    )}
                </>
            )
        } else {
            return <div>-</div>
        }
    }

    const createdAtFormater = (rowData: any) => {
        return (
            <>
                {rowData.daysWithoutInteraction !== null ? (
                    <div>
                        <div>
                            <Moment format="DD/MM/YYYY">{rowData.created_at}</Moment>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </>
        )
    }

    const replaceFunnelLevel = (rowData: any) => {
        switch (rowData.funnel_level) {
            case "bottom":
                return "Fundo"

            case "middle":
                return "Meio"

            case "top":
                return "Topo"
            default:
                return "-"
        }
    }

    const timelineFunnelListing = (rowData: any) => {
        const funnelStage = rowData.funnelStage
        let chipColor

        switch (funnelStage.name) {
            case "Primeiro Contato":
                chipColor = "#E3455E" // Laranja
                break
            case "Atendimento / Proposta":
                chipColor = "#FDAB3F" // Verde
                break
            case "Engenharia de Aplicação":
                chipColor = "#FFC900" // Amarelo
                break
            case "Follow-UP":
                chipColor = "#569BFA" // Rosa
                break
            case "Formalização de Pedido":
                chipColor = "#01C877" // Roxo
                break
            default:
                chipColor = "#03A9F4" // Azul (cor padrão)
                break
        }
        let chipStyle = {
            backgroundColor: chipColor,
            marginRight: "8px",
            color: "#fff",
            fontSize: "0.9rem",
            fontWeight: "600",
            padding: "0.5rem",
            borderRadius: "0.5rem",
        }

        return (
            <div className="funnelOnTable">
                <span style={chipStyle}>{funnelStage.name}</span>
            </div>
        )
    }

    const valueProductsAndServices = (rowData: any) => {
        return (
            <>
                <div>
                    <div className="p-d-flex" style={{ fontSize: "1rem", display: "flex", justifyContent: "center" }}>
                        <div>
                            {rowData.totalValueMachines !== null
                                ? rowData.totalValueMachines.toLocaleString("pt-br", {
                                      style: "currency",
                                      currency: "BRL",
                                  })
                                : ""}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const informationsOpportunityColumns = (rowData: any) => {
        return (
            <>
                <Button
                    icon="pi pi-exclamation-circle"
                    className="p-button-rounded p-button-text"
                    onClick={() => {
                        props.onClickDialogOpportunityInformation("displayPosition", "bottom")
                        props.showOpportunityDetail(rowData)

                        props.setOpportunity(rowData)
                    }}
                />
                {rowData.nextTask !== null ? props.informationYesTask(rowData) : props.informationNoTask(rowData)}

                <Button
                    icon="pi pi-reply"
                    className="p-button-rounded p-button-text"
                    tooltip="Alterar Etapa de atendimento"
                    onClick={() => {
                        const { funnel, ...funnelStageWithoutFunnel } = rowData.funnelStage;
                        props.setOpportunity(rowData)
                        props.setDisplayFulfillmentStepDialogBox(true)
                        props.setFunnelStage(funnelStageWithoutFunnel)
                    }}
                />

                <Button
                    icon="pi pi-bars"
                    className="p-button-rounded p-button-text"
                    tooltip="Alterar Nível de Consciência"
                    disabled={rowData.funnelStage.sequence === 1 ? true : false}
                    onClick={() => {
                        props.setOpportunity(rowData)
                        props.setDisplayDialogChangeFunnel(true)
                    }}
                />
            </>
        )
    }

    const countOpportunityQualification = (qualification: any) => {
        var countOpportunitiesQualification = 0
        props.opportunities.forEach((opportunities: any) => {
            if (opportunities.qualification === qualification) {
                countOpportunitiesQualification++
            }
        })
        return <div style={{ fontSize: "0.8rem" }}>{countOpportunitiesQualification} OPORTUNIDADES</div>
    }

    return (
        <>
            {props.workViewFilter.code === 1 && !isEmptyObj(props.funnel) && (
                <div className="opportunityContent_Cards">
                    <div className="opportunityContent_Cards_ScrollHorizontal">
                        <div className={`${props.nameOFclassCentralizeFromDesktop}`}>
                            <DragDropContext onDragEnd={props.handleOnDragEnd}>
                                {props.funnel.stages.map((funnelItem: any, indexFunnelItem: any) => {
                                    return (
                                        <Droppable droppableId={funnelItem.id} key={indexFunnelItem}>
                                            {(provided, snapshot) => (
                                                <div key={funnelItem.id} className="cardOfFunnel p-mr-3">
                                                    <div style={{ width: "auto", height: "auto" }}>
                                                        <div className="p-d-flex p-jc-center">
                                                            <p style={{ fontSize: "1.2rem", fontWeight: "600", padding: "0.5rem" }}>
                                                                {funnelItem.name.toUpperCase()}
                                                            </p>
                                                        </div>
                                                        <div style={{ height: "2.5rem" }}>
                                                            <div className="p-d-flex p-jc-center">
                                                                <div>{funnelStageTotalValue(funnelItem.id)}</div>
                                                            </div>
                                                            <div className="p-d-flex p-jc-center">
                                                                <div>{props.countOpportunity(funnelItem.id)}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="cardFunnelColumns" key={funnelItem.id} {...provided.droppableProps} ref={provided.innerRef}>
                                                        <div className="opportunityContent_CardsInCard_Scroll">
                                                            <div>
                                                                {props.auxOpportunities.map((opportunityItem: any, indexOpportunityItem: any) => {
                                                                    if (opportunityItem.funnel_stages_id === funnelItem.id) {
                                                                        return (
                                                                            <Draggable
                                                                                key={indexOpportunityItem}
                                                                                draggableId={opportunityItem.id}
                                                                                index={indexOpportunityItem}
                                                                            >
                                                                                {(provided, snapshot) => (
                                                                                    <div
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                    >
                                                                                        <div className="p-d-flex p-jc-center">
                                                                                            <div
                                                                                                className="cardOpportunity"
                                                                                                key={indexOpportunityItem}
                                                                                                style={{
                                                                                                    boxShadow: `${
                                                                                                        opportunityItem.late === true
                                                                                                            ? "0 8px 8px -0.72rem red"
                                                                                                            : ""
                                                                                                    }`,
                                                                                                    opacity: `${
                                                                                                        opportunityItem.status === "deleted" ? "0.6" : "1"
                                                                                                    }`,
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="p-d-flex"
                                                                                                    style={{
                                                                                                        width: "100%",
                                                                                                        height: "11rem",
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="colorStatusOpportunityXX"
                                                                                                        style={{
                                                                                                            width: "1.5%",
                                                                                                            height: "auto",
                                                                                                            backgroundColor: `${props.colorStatusOpportunity(
                                                                                                                opportunityItem.status
                                                                                                            )}`,
                                                                                                        }}
                                                                                                    />

                                                                                                    <div
                                                                                                        style={{
                                                                                                            width: "98.5%",
                                                                                                            height: "auto",
                                                                                                            position: "relative",
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            style={{
                                                                                                                marginLeft: "0.3rem",
                                                                                                                marginTop: "0.3rem",
                                                                                                            }}
                                                                                                        >
                                                                                                            <div className="p-d-flex" style={{ width: "100%" }}>
                                                                                                                <div className="p-mr-2">
                                                                                                                    <a
                                                                                                                        href="#"
                                                                                                                        onClick={() => {
                                                                                                                            props.setDisplayDialogCompanyInformationsAvatar(
                                                                                                                                true
                                                                                                                            )
                                                                                                                            props.opportunity = opportunityItem
                                                                                                                            props.setOpportunity(
                                                                                                                                opportunityItem
                                                                                                                            )
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <Avatar
                                                                                                                            icon={
                                                                                                                                opportunityItem.company
                                                                                                                                    .picture_url === null
                                                                                                                                    ? "pi pi-user"
                                                                                                                                    : ""
                                                                                                                            }
                                                                                                                            image={
                                                                                                                                opportunityItem.company
                                                                                                                                    .picture_url
                                                                                                                            }
                                                                                                                            className="p-mr-2"
                                                                                                                            shape="circle"
                                                                                                                        />
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                                <a
                                                                                                                    href={`/crm/leads/${opportunityItem.id}`}
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                        fontWeight: "600",
                                                                                                                        fontSize: "1.3rem",
                                                                                                                        color: "rgba(41, 50, 65, 0.8)",
                                                                                                                        marginBottom: "0rem",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <div
                                                                                                                        className="p-mr-2"
                                                                                                                        style={{
                                                                                                                            width: "95%",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <div className="p-d-flex p-jc-between">
                                                                                                                            <div className="p-d-flex p-jc-start p-ai-center">
                                                                                                                                <p
                                                                                                                                    style={{
                                                                                                                                        fontSize: "0.8rem",
                                                                                                                                        color: `${props.colorStatusOpportunity(
                                                                                                                                            opportunityItem.status
                                                                                                                                        )}`,
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <strong>
                                                                                                                                        {(
                                                                                                                                            "000000" +
                                                                                                                                            opportunityItem.number
                                                                                                                                        ).slice(-6)}
                                                                                                                                    </strong>
                                                                                                                                </p>
                                                                                                                            </div>

                                                                                                                            {opportunityItem.new === true ||
                                                                                                                            opportunityItem.new_on_funnel ===
                                                                                                                                true ? (
                                                                                                                                <div
                                                                                                                                    className="p-d-flex p-jc-end p-ai-center"
                                                                                                                                    style={{
                                                                                                                                        color: "#689F38",
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            fontSize: "0.7rem",
                                                                                                                                            fontWeight: "600",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        {opportunityItem.new ===
                                                                                                                                            true && (
                                                                                                                                            <>
                                                                                                                                                NOVA
                                                                                                                                                OPORTUNIDADE!&nbsp;&nbsp;{" "}
                                                                                                                                            </>
                                                                                                                                        )}
                                                                                                                                        {opportunityItem.new_on_funnel ===
                                                                                                                                            true && (
                                                                                                                                            <>
                                                                                                                                                NOVA
                                                                                                                                                OPORTUNIDADE NO
                                                                                                                                                FUNIL!&nbsp;&nbsp;
                                                                                                                                            </>
                                                                                                                                        )}
                                                                                                                                    </div>

                                                                                                                                    <i
                                                                                                                                        className="pi pi-star-fill"
                                                                                                                                        style={{
                                                                                                                                            fontSize: "0.7rem",
                                                                                                                                        }}
                                                                                                                                    ></i>
                                                                                                                                </div>
                                                                                                                            ) : (
                                                                                                                                <div></div>
                                                                                                                            )}
                                                                                                                        </div>

                                                                                                                        <p
                                                                                                                            className="p-text-nowrap p-text-truncate"
                                                                                                                            style={{
                                                                                                                                width: "15rem",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {opportunityItem.name}
                                                                                                                        </p>

                                                                                                                        <div
                                                                                                                            className="p-text-nowrap p-text-truncate"
                                                                                                                            style={{
                                                                                                                                marginTop: "-1rem",
                                                                                                                                width: "13rem",
                                                                                                                                fontSize: "1rem",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {opportunityItem.company.name}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div
                                                                                                            className="p-d-flex p-jc-center"
                                                                                                            style={{
                                                                                                                margin: "0.3rem",
                                                                                                                height: "3rem",
                                                                                                            }}
                                                                                                        >
                                                                                                            <div className="p-d-flex p-as-center">
                                                                                                                {props.rating(opportunityItem)}
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div>
                                                                                                            <div className="p-d-flex p-jc-between">
                                                                                                                <div
                                                                                                                    className="p-d-flex  p-as-center"
                                                                                                                    style={{
                                                                                                                        position: "absolute",
                                                                                                                        bottom: "0",
                                                                                                                        marginLeft: "0.4rem",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <div
                                                                                                                        className="p-mb-2 p-as-center"
                                                                                                                        style={{
                                                                                                                            width: "0.6rem",
                                                                                                                            height: "0.6rem",
                                                                                                                            borderRadius: "90%",
                                                                                                                            backgroundColor: "#03A9F4",
                                                                                                                            marginRight: "0.2em",
                                                                                                                            marginLeft: "0.5rem",
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    <div className="p-mb-2">
                                                                                                                        {opportunityItem.totalValueMachines !==
                                                                                                                        null
                                                                                                                            ? opportunityItem.totalValueMachines.toLocaleString(
                                                                                                                                  "pt-br",
                                                                                                                                  {
                                                                                                                                      style: "currency",
                                                                                                                                      currency: "BRL",
                                                                                                                                  }
                                                                                                                              )
                                                                                                                            : ""}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    className="adjustIconsForMobile"
                                                                                                                    style={{
                                                                                                                        position: "absolute",
                                                                                                                        bottom: "0",
                                                                                                                        right: "0",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {opportunityItem.nextTask !== null
                                                                                                                        ? props.informationYesTask(
                                                                                                                              opportunityItem
                                                                                                                          )
                                                                                                                        : props.informationNoTask(
                                                                                                                              opportunityItem
                                                                                                                          )}

                                                                                                                    <Button
                                                                                                                        icon="pi pi-exclamation-circle"
                                                                                                                        className=" p-button-rounded p-button-text "
                                                                                                                        onClick={() => {
                                                                                                                            props.onClickDialogOpportunityInformation(
                                                                                                                                "displayPosition",
                                                                                                                                "bottom"
                                                                                                                            )
                                                                                                                            props.showOpportunityDetail(
                                                                                                                                opportunityItem
                                                                                                                            )

                                                                                                                            props.setOpportunity(
                                                                                                                                opportunityItem
                                                                                                                            )
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        )
                                                                    }
                                                                })}
                                                            </div>
                                                        </div>
                                                        {provided.placeholder}
                                                    </div>
                                                </div>
                                            )}
                                        </Droppable>
                                    )
                                })}
                            </DragDropContext>
                        </div>
                    </div>
                </div>
            )}

            {props.workViewFilter.code === 2 && (
                <div className="thiscardForMobile card">
                    <div className="dataTableDesktop">
                        <div className="dataTable">
                            <Toast ref={props.toast} />
                            <DataTable
                                ref={props.dt}
                                value={props.auxOpportunities}
                                dataKey="id"
                                loading={props.loadingList}
                                paginator
                                stripedRows
                                rows={10}
                                size="small"
                                rowsPerPageOptions={[5, 10, 25]}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} oportunidades"
                                emptyMessage="Não há Oportunidades"
                                style={{ width: "100%" }}
                                responsiveLayout="scroll"
                            >
                                {/* AVATAR */}

                                <Column align="center" alignHeader="center" body={props.imageBodyTemplate}></Column>

                                {/* OPORTUNIDADES */}
                                <Column
                                    //align="center"
                                    alignHeader="center"
                                    sortField="name"
                                    sortable
                                    header="OPORTUNIDADES"
                                    body={nameOpportunity}
                                ></Column>

                                {/* CNPJ */}
                                <Column
                                    align="center"
                                    alignHeader="center"
                                    header="CNPJ"
                                    sortField="company.cnpj"
                                    sortable
                                    body={cnpjOpportunityColumns}
                                ></Column>

                                {/* OPORTUNIDADES */}
                                <Column
                                    align="center"
                                    alignHeader="center"
                                    header="STATUS"
                                    sortField="status"
                                    sortable
                                    body={statusOpportunityColumns}
                                ></Column>

                                <Column align="center" alignHeader="center" header="CRIAÇÃO" sortField="created_at" sortable body={createdAtFormater}></Column>

                                {/*  DIAS EM ATRASO */}
                                <Column
                                    align="center"
                                    alignHeader="center"
                                    header="ATRASO"
                                    sortField="delayedDays"
                                    sortable
                                    body={delayedDaysOfListining}
                                ></Column>

                                {/* DATA DA ULTIMA INTERAÇÃO */}
                                <Column
                                    align="center"
                                    alignHeader="center"
                                    header="ULT. INTERAÇÃO"
                                    sortField="dateLastInteraction"
                                    sortable
                                    body={lastInterationOfListining}
                                ></Column>

                                {/* QUALIFICAÇÃO */}
                                <Column
                                    align="center"
                                    alignHeader="center"
                                    body={props.rating}
                                    header="TEMPERATURA"
                                    sortField="qualification"
                                    sortable
                                ></Column>

                                {/* <Column
                                    align="center"
                                    alignHeader="center"
                                    header="ETAPA DO FUNIL"
                                    sortField="funnel_level"
                                    sortable
                                    field="funnel_level"
                                    body={replaceFunnelLevel}
                                ></Column> */}

                                {/* ETAPA FUNIL */}
                                <Column
                                    align="center"
                                    alignHeader="center"
                                    field=""
                                    body={timelineFunnelListing}
                                    header="ETAPA DE ATENDIMENTO"
                                    sortable
                                    sortField="funnelStage.sequence"
                                ></Column>

                                {/* VALORES */}
                                <Column
                                    align="center"
                                    alignHeader="center"
                                    body={valueProductsAndServices}
                                    header="VALORES"
                                    sortField="totalValueMachines"
                                    sortable
                                ></Column>

                                {/* INFORMAÇÕES */}
                                <Column align="center" alignHeader="center" body={informationsOpportunityColumns}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            )}

            {props.workViewFilter.code === 3 && !isEmptyObj(props.funnel) && (
                <div className="opportunityContent_Cards">
                    <div className="opportunityContent_Cards_ScrollHorizontal">
                        <div className={`${props.nameOFclassCentralizeFromDesktop}`}>
                            {props.count.map((number: number) => (
                                <div key={number} className="cardOfFunnel p-mr-3">
                                    <div>
                                        <p
                                            className="m-0"
                                            style={{
                                                marginTop: "0.5rem",
                                                fontSize: "1rem",
                                                lineHeight: "1.5",
                                                fontWeight: "700",
                                            }}
                                        ></p>

                                        <div className="p-d-flex p-jc-center">
                                            <div>
                                                <p style={{ fontSize: "1.2rem", fontWeight: "600" }}>
                                                    <Rating readOnly value={number} cancel={false} />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="p-d-flex p-jc-center">{funnelStageTotalValueByQualification(1)}</div>
                                        <div className="p-d-flex p-jc-center">
                                            <div>{countOpportunityQualification(number)}</div>
                                        </div>
                                    </div>
                                    <div className="cardsFilter ">{props.filterByQualification(number)}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default memo(Opportunities)
