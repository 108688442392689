import React, { useEffect, useRef, useState } from "react"
import * as yup from "yup"
import api from "../../services/api"
import { Toast } from "primereact/toast"
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import { SpeedDial } from "primereact/speeddial"
import { Tooltip } from "primereact/tooltip"
import { v4 as uuidv4 } from "uuid"
import { Contact } from "./forms/Contact"
import { Task } from "./forms/tasks/Task"
import { TaskInterface } from "../../interfaces/TaskInterface"
import { UserInterface } from "../../interfaces/UserInterface"
import { Company } from "./forms/Company"
import { ContactInterface } from "../../interfaces/ContactInterface"
import { CompanyInterface } from "../../interfaces/CompanyInterface"
import { InterestInterface } from "../../interfaces/InterestInterface"
import { IEmpresaInterface } from "../../interfaces/IEmpresaInterface"
import { Opportunity } from "./forms/Opportunity"
import { FunnelInterface } from "../../interfaces/FunnelInterface"
import { MediaInterface } from "../../interfaces/MediaInterface"
import { useHotkeys } from "react-hotkeys-hook"
import { Panel } from "primereact/panel"
import { ScrollPanel } from "primereact/scrollpanel"
import { ContactPhoneInterface } from "../../interfaces/ContactPhoneInterface"
import { ContactEmailInterface } from "../../interfaces/ContactEmailInterface"

interface Contact {
    name: string
    job: string
    company: IEmpresaInterface
    authorization_status: Data
    phone: Phones[]
    email: Email[]
    authorization: Data
}

interface ClientCompany {
    id: string
    cnpj: string
    name: string
    interest: Data[]
    companyRequest: any
}

interface InterfaceOpportunity {
    name: string
    funnel: Funnel
    stage: Stage
    media: Midia
    campaign: IEmpresaInterface
    contact: Contact
    id: string
}

interface Data {
    name: string
    code: string | boolean
}

interface Interests {
    id: string
    name: string
    status: number
    created_at: string
    updated_at: string
}

interface Email {
    email: string
}

interface Phones {
    number: string
}

interface Funnel {
    id: string
    name: string
    stages: Stage[]
    is_default: boolean
}

interface Stage {
    id: string
    name: string
    funnel_id: string
    sequence: number
    created_at: string
    updated_at: string
}

interface Midia {
    id: string
    name: string
}

interface PropsInterface {
    updateContact?: (item: Contact) => void
    updateTask?: (item: TaskInterface) => void
    updateCompany?: (item: ClientCompany) => void
}

// ------------------------------- Inicio Empresa -----------------------------------
interface SaveCompanyData {
    name: string
    cnpj: string
    interests: InterestInterface[]
}
// ------------------------------- Fim Empresa -----------------------------------

export const CrmRecords = (props: PropsInterface) => {
    let toast = useRef<Toast>(null)
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    let [createClientCompany, setCreateClientCompany] = useState<ClientCompany>({} as ClientCompany)
    let [interests, setInterests] = useState<Interests[]>([])
    let [companies, setCompanies] = useState<CompanyInterface[]>([])
    let [company, setCompany] = useState<IEmpresaInterface>({} as IEmpresaInterface)
    let [funnels, setFunnels] = useState<FunnelInterface[]>([])
    let [funnelStages, setFunnelStages] = useState<Stage[]>([])
    let [medias, setMidias] = useState<MediaInterface[]>([])
    let [contactsDialog, setContactsDialog] = useState(false)
    let [clientCompanyDialog, setClientCompanyDialog] = useState(false)
    let [opportunityDialog, setOpportunityDialog] = useState(false)
    let [loadingCreateOpportunity, setLoadingCreateOpportunity] = useState(false)
    let [countries, setCountries] = useState<any>([])

    // ============================================================ SHORTCUTS

    useHotkeys("shift+o", () => {
        setOpportunityDialog(true)
        allFunnels()
        allMedias()
        setCountries(companies)
        allInterests()
        setNewPhoneField([{ id: uuidv4(), telephone: "", name: "phone", ddi: { ddi: "+055", country: "Brasil" } }])
    })
    useHotkeys("shift+e", () => {
        setClientCompanyDialog(true)
        allInterests()
        allCompanies()
    })
    useHotkeys("shift+t", () => {
        setTasksDialog(true)
    })
    useHotkeys("shift+c", () => {
        setContactsDialog(true)
        allCompanies()
        setNewPhoneField([{ id: uuidv4(), telephone: "", name: "phone", ddi: { ddi: "+055", country: "Brasil" } }])
    })

    useHotkeys("esc", () => {
        hideDialog()
    })
    // ============================================================ SHORTCUTS

    async function validateCreateOpportunity() {
        let schema = yup.object().shape({
            name: yup.string().required(),
            funnel: yup.object().required(),
            stage: yup.object().required(),
            media: yup.object().required(),
        })

        try {
            await schema.validate(createOpportunity)
            return true
        } catch (error) {
            return false
        }
    }

    const handleCreateOpportunity = async (event: any) => {
        event.preventDefault()
        setLoadingCreateOpportunity(true)

        let headerCompany = {
            headers: {
                route: "create_company",
            },
        }

        let headerUpdateCompany = {
            headers: {
                route: "update_company",
            },
        }

        let headerOpportunity = {
            headers: {
                route: "create_opportunity",
            },
        }

        let headerContact = {
            headers: {
                route: "create_contact",
            },
        }

        if (!(await validateCreateOpportunity())) {
            setLoadingCreateOpportunity(false)
            toast.current?.show({
                severity: "error",
                summary: "Dados Invalidos!",
                detail: "Verifique os dados da Oportunidade!",
                life: 6000,
            })
        } else if (!(await validateCreateContact()) && isEmptyObj(existingCompany)) {
            setLoadingCreateOpportunity(false)
            toast.current?.show({
                severity: "error",
                summary: "Dados Invalidos!",
                detail: "Verifique os dados do contato!",
                life: 6000,
            })
        } else if (await validateCreateCompany()) {
            setLoadingCreateOpportunity(false)
            toast.current?.show({
                severity: "error",
                summary: "Dados Invalidos!",
                detail: "Preencha todos os dados obrigatorios!",
                life: 6000,
            })
        } else {
            // Nova empresa com CNPJ.
            if (isEmptyObj(existingCompany) && !isEmptyObj(requestApiCompany)) {
                let auxContact = handleCreateContactOpportunity()

                if (!isEmptyObj(auxContact.emails) || !isEmptyObj(auxContact.phones)) {
                    setLoadingCreateOpportunity(true)

                    let auxSaveCompanyData = {
                        interests: saveCompanyData.interests,
                        company: requestApiCompany,
                    }

                    let resultCreateCompany = await api.post("companies", auxSaveCompanyData, headerCompany)

                    if (resultCreateCompany.status === 200) {
                        setLoadingCreateOpportunity(false)
                        let auxRequestCreateOpportunity = {
                            name: createOpportunity.name,
                            funnel_stages_id: createOpportunity.stage.id,
                            media_id: createOpportunity.media.id,
                            company_id: resultCreateCompany.data.id,
                        }

                        auxContact.company_id = resultCreateCompany.data.id
                        delete auxContact.company

                        await api.post("contacts", auxContact, headerContact)

                        let resultCreateOpportunity = await api.post("opportunities", auxRequestCreateOpportunity, headerOpportunity)

                        if (resultCreateOpportunity.status === 200) {
                            setLoadingCreateOpportunity(false)
                            hideDialog()

                            window.location.href = `/crm/leads/${resultCreateOpportunity.data.id}/produtos`
                        } else {
                            hideDialog()
                            toast.current?.show({
                                severity: "error",
                                summary: "Ops!",
                                detail: "Tente novamente em alguns instantes!",
                                life: 3000,
                            })
                        }
                    } else {
                        hideDialog()
                        setLoadingCreateOpportunity(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "OPS",
                            detail: resultCreateCompany.data.message,
                            life: 3000,
                        })
                    }
                } else {
                    setLoadingCreateOpportunity(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "OPS",
                        detail: "Preencha um email ou um contato para continuar!",
                        life: 3000,
                    })
                }
            }

            // Nova empresa sem CNPJ.
            else if (isEmptyObj(existingCompany) && isEmptyObj(requestApiCompany)) {
                let auxContact = handleCreateContactOpportunity()

                if (saveCompanyData.name !== undefined) {
                    if (!isEmptyObj(auxContact.emails) || !isEmptyObj(auxContact.phones)) {
                        setLoadingCreateOpportunity(true)

                        let auxSaveCompanyData = {
                            interests: saveCompanyData.interests,
                            company: {
                                razao_social: saveCompanyData.name,
                                estabelecimento: {
                                    cnpj: null,
                                },
                            },
                        }

                        let resultCreateCompany = await api.post("companies", auxSaveCompanyData, headerCompany)

                        if (resultCreateCompany.status === 200) {
                            setLoadingCreateOpportunity(false)
                            let auxRequestCreateOpportunity = {
                                name: createOpportunity.name.toUpperCase(),
                                funnel_stages_id: createOpportunity.stage.id,
                                media_id: createOpportunity.media.id,
                                company_id: resultCreateCompany.data.id,
                            }

                            auxContact.company_id = resultCreateCompany.data.id
                            delete auxContact.company

                            await api.post("contacts", auxContact, headerContact)

                            let resultCreateOpportunity = await api.post("opportunities", auxRequestCreateOpportunity, headerOpportunity)

                            if (resultCreateOpportunity.status === 200) {
                                setLoadingCreateOpportunity(false)
                                hideDialog()

                                window.location.href = `/crm/leads/${resultCreateOpportunity.data.id}/produtos`
                            } else {
                                setLoadingCreateOpportunity(false)
                                hideDialog()
                                toast.current?.show({
                                    severity: "error",
                                    summary: "Ops!",
                                    detail: "Tente novamente em alguns instantes!",
                                    life: 3000,
                                })
                            }
                        } else {
                            setLoadingCreateOpportunity(false)
                            hideDialog()
                            toast.current?.show({
                                severity: "error",
                                summary: "OPS",
                                detail: resultCreateCompany.data.message,
                                life: 3000,
                            })
                        }
                    } else {
                        setLoadingCreateOpportunity(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "OPS",
                            detail: "Preencha um email ou um contato para continuar!",
                            life: 3000,
                        })
                    }
                } else {
                    setLoadingCreateOpportunity(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "OPS",
                        detail: "Preencha o nome da empresa para continuar!",
                        life: 3000,
                    })
                }
            }

            // Empresa Existente.
            else if (!isEmptyObj(existingCompany)) {
                let auxSaveCompanyData = {
                    interests: existingCompany.interests,
                    id: existingCompany.id,
                    company: {
                        razao_social: existingCompany.name,
                        estabelecimento: {
                            cnpj: existingCompany.cnpj !== null ? existingCompany.cnpj.replaceAll("-", "").replaceAll("/", "").replaceAll(".", "") : null,
                        },
                    },
                }

                await api.put("companies", auxSaveCompanyData, headerUpdateCompany)

                // let auxContact = handleCreateContactOpportunity()
                if (!isEmptyObj(saveCompanyData)) {
                    setLoadingCreateOpportunity(false)
                    let auxRequestCreateOpportunity = {
                        name: createOpportunity.name.toUpperCase(),
                        funnel_stages_id: createOpportunity.stage.id,
                        media_id: createOpportunity.media.id,
                        company_id: existingCompany.id,
                    }

                    // auxContact.company_id = existingCompany.id
                    // delete auxContact.company

                    // await api.post("contacts", auxContact, headerContact)

                    let resultCreateOpportunity = await api.post("opportunities", auxRequestCreateOpportunity, headerOpportunity)

                    if (resultCreateOpportunity.status === 200) {
                        setLoadingCreateOpportunity(false)
                        hideDialog()
                        window.location.href = `/crm/leads/${resultCreateOpportunity.data.id}/produtos`
                    } else {
                        setLoadingCreateOpportunity(false)
                        hideDialog()
                        toast.current?.show({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Tente novamente em alguns instantes!",
                            life: 3000,
                        })
                    }
                } else {
                    setLoadingCreateOpportunity(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "OPS",
                        detail: "Preencha um email ou um contato para continuar!",
                        life: 3000,
                    })
                }
            }
        }
    }

    const hideDialog = () => {
        setLoadingCreateCompany(false)
        setContactsDialog(false)
        setClientCompanyDialog(false)
        setOpportunityDialog(false)
        setCreateClientCompany({} as ClientCompany)
        setCreateOpportunity({} as InterfaceOpportunity)
        setCompany({} as IEmpresaInterface)

        // ------------------------------- Inicio Contato -----------------------------------
        setLoadingContact(false)
        setFormContact({} as ContactInterface)
        setNewEmailField([{ id: uuidv4(), emails: "", name: "email" }])
        setNewPhoneField([{ id: uuidv4(), telephone: "", name: "phone", ddi: { ddi: "+55", country: "Brasil" } }])
        // ------------------------------- Fim Contato -----------------------------------

        // ------------------------------ Inicio Tarefas ---------------------------------------

        setTasksDialog(false)
        setSaveTaskData({} as TaskInterface)
        setLoadingCreateTask(false)

        // ------------------------------- Inicio Empresa -----------------------------------
        setSaveCompanyData({} as SaveCompanyData)
        setRequestApiCompany({} as IEmpresaInterface)
        setExistingCompany({} as CompanyInterface)
        setLoadingCreateCompany(false)
        // ------------------------------- Fim Empresa -----------------------------------
    }

    const allInterests = async () => {
        try {
            let header = {
                headers: {
                    route: "list_interests",
                },
            }

            const response = await api.get("interests", header)
            setInterests(response.data)
        } catch (error: any) {
            console.log(error)
            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar os interesses!",
                detail: error.response?.data?.message || "Ocorreu um erro desconhecido.",
                life: 3000,
            })
        }
    }

    const allCompanies = async () => {
        try {
            const header = {
                headers: {
                    route: "list_companies",
                },
            }

            const response = await api.get("companies", header)
            setCompanies(response.data)
        } catch (error: any) {
            console.error(error)

            const errorMessage = error.response?.data?.message || "Ocorreu um erro desconhecido."

            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar empresas!",
                detail: errorMessage,
                life: 3000,
            })
        }
    }

    const allMedias = async () => {
        try {
            const header = {
                headers: {
                    route: "list_medias",
                },
            }

            const response = await api.get("medias", header)
            setMidias(response.data)
        } catch (error: any) {
            console.error(error)

            const errorMessage = error.response?.data?.message || "Ocorreu um erro desconhecido."

            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar Mídias!",
                detail: errorMessage,
                life: 3000,
            })
        }
    }

    const allFunnels = async () => {
        try {
            const header = {
                headers: {
                    route: "list_funnels",
                },
            }

            const response = await api.get("funnels", header)
            setFunnels(response.data)

            const defaultFunnel = response.data.find((response: Funnel) => response.is_default)
            if (defaultFunnel) {
                createOpportunity.funnel = defaultFunnel
                setCreateOpportunity((prevOpportunity) => ({
                    ...prevOpportunity,
                    funnel: defaultFunnel,
                }))
                setFunnelStages(defaultFunnel.stages)
            }
        } catch (error: any) {
            console.error(error)
            const errorMessage = error.response?.data?.message || "Ocorreu um erro desconhecido."

            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar Funis!",
                detail: errorMessage,
                life: 3000,
            })
        }
    }

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    const items = [
        {
            label: "Oportunidades",
            icon: "pi pi-briefcase",
            command: () => {
                setOpportunityDialog(true)
                allFunnels()
                allMedias()
                setCountries(companies)
                allInterests()
                setNewPhoneField([{ id: uuidv4(), telephone: "", name: "phone", ddi: { ddi: "+055", country: "Brasil" } }])
            },
        },
        {
            label: "Empresa / Cliente",
            icon: "pi pi-building",
            command: () => {
                setClientCompanyDialog(true)
                allInterests()
                allCompanies()
            },
        },
        {
            label: "Contato",
            icon: "pi pi-users",
            command: () => {
                setContactsDialog(true)
                allCompanies()
                setNewPhoneField([{ id: uuidv4(), telephone: "", name: "phone", ddi: { ddi: "+055", country: "Brasil" } }])
            },
        },
        {
            label: "Tarefas",
            icon: "pi pi-book",
            command: () => {
                setTasksDialog(true)
            },
        },
    ]

    // ------------------------------- Inicio Contato -----------------------------------
    let [newEmailField, setNewEmailField] = useState([{ id: uuidv4(), emails: "", name: "email" }])
    let [newPhoneField, setNewPhoneField] = useState([{ id: uuidv4(), telephone: "", name: "phone", ddi: { ddi: "+055", country: "Brasil" } }])
    let [formContact, setFormContact] = useState<ContactInterface>({} as ContactInterface)
    let [loadingCreateContact, setLoadingContact] = useState(false)

    async function validateCreateContact() {
        let schema = yup.object().shape({
            name: yup.string().required(),
            job: yup.string(),
            company: yup.object().required(),
        })

        try {
            await schema.validate(formContact)
            return true
        } catch (error) {
            return false
        }
    }

    const handleCreateContactOpportunity = () => {
        let auxFieldEmail: any = []
        let auxFieldPhones: any = []

        newEmailField.forEach((auxNewEmailField) => {
            if (auxNewEmailField.emails !== "") {
                auxFieldEmail.push({
                    email: auxNewEmailField.emails,
                })
            }
        })

        newPhoneField.forEach((auxNewContactField) => {
            if (auxNewContactField.telephone !== "") {
                auxFieldPhones.push({
                    number:
                        auxNewContactField.ddi.ddi.replaceAll("+", "") +
                        "-" +
                        auxNewContactField.telephone.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replaceAll("+", ""),
                })
            }
        })

        let auxCreateContact: ContactInterface = {
            name: formContact.name,
            job: formContact.job,
            company_id: formContact.company !== undefined ? formContact.company.id : "",
            authorization: "LI",
            authorization_status: true,
            phones: auxFieldPhones,
            emails: auxFieldEmail,
            company: formContact.company,
        }

        return auxCreateContact
    }

    const handleCreateContact = async (event: any) => {
        event.preventDefault()

        let auxCreateContact: ContactInterface = handleCreateContactOpportunity()
        formContact = auxCreateContact

        if (!(await validateCreateContact())) {
            toast.current?.show({
                severity: "error",
                summary: "Dados Invalidos!",
                detail: "Preencha todos os dados obrigatorios!",
                life: 6000,
            })
        } else {
            setLoadingContact(true)

            if (auxCreateContact.job === undefined) {
                delete auxCreateContact.job
            }

            if (isEmptyObj(auxCreateContact.phones)) {
                auxCreateContact.phones = []
            }

            if (isEmptyObj(auxCreateContact.emails)) {
                auxCreateContact.emails = []
            }

            delete auxCreateContact.company

            let header = {
                headers: {
                    route: "create_contact",
                },
            }

            await api
                .post("contacts", auxCreateContact, header)
                .then((res) => {
                    if (res.status === 200) {
                        hideDialog()

                        if (props.updateContact) {
                            props.updateContact(res.data)
                        }

                        setLoadingContact(false)
                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "Contato cadastrado com Sucesso",
                            life: 3000,
                        })
                    } else {
                        setLoadingContact(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao cadastar o contato!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingContact(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao cadastar o contato!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        }
    }

    const handleUpdateContact = (item: any) => {
        setFormContact(item)
    }

    const handleUpdateFieldEmail = (item: any) => {
        setNewEmailField(item)
    }

    const handleUpdateFieldPhone = (item: any) => {
        setNewPhoneField(item)
    }

    // ------------------------------- Fim Contato -----------------------------------

    // ------------------------------- Início Tarefas -----------------------------------

    let [saveTaskData, setSaveTaskData] = useState<TaskInterface>({} as TaskInterface)
    let [tasksDialog, setTasksDialog] = useState(false)
    let [loadingCreateTask, setLoadingCreateTask] = useState(false)

    async function validateCreateTask() {
        let schema = yup.object().shape({
            date: yup.date().required(),
            type: yup.string().required(),
            subject: yup.string().required(),
            opportunity_id: yup.string().required(),
            users: yup.array().required(),
        })

        try {
            await schema.validate(saveTaskData)
            return true
        } catch (error) {
            return false
        }
    }

    const handleSetSaveTaskData = (name: any, value: any) => {
        setSaveTaskData({ ...saveTaskData, [name]: value })
    }

    const handleCreateTask = async (event: any) => {
        event.preventDefault()
        let idUsers: any = []

        if (!isEmptyObj(saveTaskData.users)) {
            saveTaskData.users.forEach((user: UserInterface) => {
                idUsers.push(user.id)
            })
        }

        if (!(await validateCreateTask())) {
            toast.current?.show({
                severity: "error",
                summary: "Dados Invalidos!",
                detail: "Preencha todos os dados obrigatorios!",
                life: 6000,
            })
        } else {
            try {
                setLoadingCreateTask(true)
                let auxTask = {
                    type: saveTaskData.type,
                    subject: saveTaskData.subject,
                    date: saveTaskData.date,
                    opportunity_id: saveTaskData.opportunity_id,
                    users_id: idUsers,
                }
                let header = {
                    headers: {
                        route: "create_task",
                    },
                }
                api.post("tasks", auxTask, header)
                    .then((res) => {
                        if (res.status === 200) {
                            hideDialog()
                            if (props.updateTask) {
                                props.updateTask(res.data)
                            }
                            setLoadingCreateTask(false)
                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso",
                                detail: "Tarefa cadastrado com Sucesso",
                                life: 3000,
                            })
                        } else {
                            setLoadingCreateTask(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao cadastar a tarefa!",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingCreateTask(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao cadastar a tarefa!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        }
    }

    // ------------------------------- Fim Tarefas -----------------------------------

    // ------------------------------- Inicio Empresa ---------------------------------

    let [saveCompanyData, setSaveCompanyData] = useState<SaveCompanyData>({} as SaveCompanyData)
    let [requestApiCompany, setRequestApiCompany] = useState<IEmpresaInterface>({} as IEmpresaInterface)
    let [existingCompany, setExistingCompany] = useState<CompanyInterface>({} as CompanyInterface)
    let [loadingCreateCompany, setLoadingCreateCompany] = useState(false)

    async function validateCreateCompany() {
        let schema = yup.object().shape({
            name: yup.string().required(),
        })

        try {
            await schema.validate(createClientCompany)
            return true
        } catch (error) {
            return false
        }
    }

    const handleSaveCompanyData = (item: any) => {
        const { value, name } = item.target

        setSaveCompanyData({ ...saveCompanyData, [name]: value })
    }

    const handleRequestApiCompany = (item: IEmpresaInterface) => {
        if (!isEmptyObj(item) && item.simples !== null) {
            setRequestApiCompany({ ...requestApiCompany, [`razao_social`]: item.estabelecimento.nome_fantasia })
        }

        setRequestApiCompany(item)
    }

    const handleExistingCompany = (item: CompanyInterface) => {
        setExistingCompany(item)
    }

    const handleProcessCreateCompany = async (auxSaveCompanyData: any) => {
        let header = {
            headers: {
                route: "create_company",
            },
        }
        setLoadingCreateCompany(true)
        await api
            .post("companies", auxSaveCompanyData, header)
            .then((res) => {
                if (res.status === 200) {
                    allCompanies()
                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso!",
                        detail: "Sucesso na alteração da empresa",
                        life: 3000,
                    })
                    hideDialog()
                } else {
                    hideDialog()
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao editar empresa!",
                        detail: res.data.message,
                        life: 3000,
                    })
                }
            })
            .catch((error) => {
                hideDialog()
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao editar empresa!",
                    detail: error.response.data.message,
                    life: 3000,
                })
            })
    }

    const handleCreatedCompanyComponent = (event: any) => {
        event.preventDefault()
        setLoadingCreateCompany(true)

        if (!isEmptyObj(requestApiCompany)) {
            let auxSaveCompanyData = {
                interests: saveCompanyData.interests,
                company: requestApiCompany,
            }
            hideDialog()
            handleProcessCreateCompany(auxSaveCompanyData)
        } else if (!isEmptyObj(existingCompany)) {
            setLoadingCreateCompany(false)
            setSaveCompanyData({} as SaveCompanyData)
            setRequestApiCompany({} as IEmpresaInterface)
            setExistingCompany({} as CompanyInterface)
            toast.current?.show({
                severity: "warn",
                summary: "OPS!",
                detail: "Empresa já está cadastrada.",
                life: 3000,
            })
        } else if (!isEmptyObj(saveCompanyData)) {
            if (saveCompanyData.name !== undefined) {
                let auxSaveCompanyData = {
                    interests: saveCompanyData.interests,
                    company: {
                        razao_social: saveCompanyData.name,
                        estabelecimento: {
                            cnpj: null,
                        },
                    },
                }
                hideDialog()
                handleProcessCreateCompany(auxSaveCompanyData)
            } else {
                toast.current?.show({
                    severity: "warn",
                    summary: "OPS!",
                    detail: "Não é possível cadastrar empresa sem nome.",
                    life: 3000,
                })
            }
        }
        setLoadingCreateCompany(false)
    }

    // ------------------------------- Fim Empresa -----------------------------------

    // ------------------------------- Inicio Oportunidade ---------------------------------

    let [createdOpportunityName, setCreatedOpportunityName] = useState("")
    let [createdOpportunityFunnel_stage, setCreatedOpportunityFunnel_stage] = useState("")
    let [createdOpportunityMedia_id, setCreatedOpportunityMedia_id] = useState("")
    let [createOpportunity, setCreateOpportunity] = useState<InterfaceOpportunity>({} as InterfaceOpportunity)

    const onChangeCreateOpportunity = (event: any) => {
        const { value, name } = event.target

        setCreateOpportunity({
            ...createOpportunity,
            [name]: value,
        })
    }
    // ------------------------------- Fim Oportunidade -----------------------------------

    // Set os dados da empresa.
    useEffect(() => {
        setSaveCompanyData((prevState) => ({
            ...prevState,
            internationalCompany: { name: "Não" },
            country: { name: "Brasil", code: "BR" }
        }))
    }, [])

    return (
        <>
            <div className="speeddial-tooltip-demo">
                <Tooltip target=".speeddial-tooltip-demo .speeddial-left .p-speeddial-action" style={{ display: "flex", position: "fixed" }} position="left" />
                <SpeedDial className="content-plus-crm speeddial-left" model={items} direction="up" style={{ position: "fixed" }} />
            </div>

            <Toast ref={toast} />

            {/* Dialog Contatos */}
            <Dialog style={{ minWidth: "40vw" }} visible={contactsDialog} modal className="p-fluid" onHide={hideDialog} header="Novo Contato" draggable={false}>
                <form onSubmit={handleCreateContact}>
                    <Contact
                        formContact={formContact}
                        setFormContact={handleUpdateContact}
                        setNewPhoneField={handleUpdateFieldPhone}
                        setNewEmailField={handleUpdateFieldEmail}
                        newEmailField={newEmailField}
                        newPhoneField={newPhoneField}
                    />

                    <div className="p-dialog-footer">
                        <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                        <Button type="submit" loading={loadingCreateContact} label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" />
                    </div>
                </form>
            </Dialog>

            {/* Dialog Tarefas */}
            <Dialog
                visible={tasksDialog}
                breakpoints={{ "960px": "75vw", "640px": "100vw'1" }}
                style={{ minWidth: "35vw" }}
                modal
                className="p-fluid"
                onHide={hideDialog}
                header="Nova Tarefa"
                draggable={false}
            >
                <form onSubmit={handleCreateTask}>
                    <Task saveTaskData={saveTaskData} type="create" setSaveTaskData={handleSetSaveTaskData} />

                    <div className="p-dialog-footer">
                        <Button type="button" label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                        <Button type="submit" label="Salvar" loading={loadingCreateTask} icon="pi pi-check" className="p-button-success p-button-text" />
                    </div>
                </form>
            </Dialog>

            {/* Dialog Cliente Empresa */}
            <Dialog
                visible={clientCompanyDialog}
                breakpoints={{ "960px": "75vw", "640px": "100vw" }}
                style={{ width: "60vw" }}
                modal
                className="p-fluid"
                onHide={hideDialog}
                header="Nova Empresa / Cliente"
                draggable={false}
            >
                <form onSubmit={handleCreatedCompanyComponent}>
                    <Company
                        allCompanies={companies}
                        existingCompany={existingCompany}
                        saveCompanyData={saveCompanyData}
                        requestApiCompany={requestApiCompany}
                        setSaveCompanyData={handleSaveCompanyData}
                        setRequestApiCompany={handleRequestApiCompany}
                        setExistingCompany={handleExistingCompany}
                        type="created"
                        //company={opportunity.company}
                    />

                    <div className="p-dialog-footer">
                        <Button label="Cancelar" type="button" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                        <Button label="Salvar" type="submit" loading={loadingCreateCompany} icon="pi pi-check" className="p-button-success p-button-text" />
                    </div>
                </form>
            </Dialog>

            {/* Dialog Oportunidades */}
            <Dialog
                visible={opportunityDialog}
                breakpoints={{ "960px": "75vw", "640px": "100vw" }}
                modal
                className="p-fluid"
                onHide={hideDialog}
                header="Nova Oportunidade"
                draggable={false}
            >
                <form onSubmit={handleCreateOpportunity}>
                    <div className="p-d-flex p-flex-column p-flex-md-row">
                        <div className="p-mb-2 p-mr-4">
                            <h5>Oportunidade</h5>
                            <Opportunity
                                allFunnels={funnels}
                                allMidias={medias}
                                createOpportunity={createOpportunity}
                                opportunityName={createdOpportunityName}
                                opportunityFunnel_stage={createdOpportunityFunnel_stage}
                                opportunityMedia_id={createdOpportunityMedia_id}
                                onChangeCreateOpportunity={onChangeCreateOpportunity}
                                existingCompany={existingCompany}
                                requestApiCompany={requestApiCompany}
                                saveCompanyData={saveCompanyData}
                            ></Opportunity>
                        </div>

                        <div className="p-mb-2 p-mr-4">
                            <h5>Empresa</h5>
                            <Company
                                existingCompany={existingCompany}
                                saveCompanyData={saveCompanyData}
                                requestApiCompany={requestApiCompany}
                                setSaveCompanyData={handleSaveCompanyData}
                                setRequestApiCompany={handleRequestApiCompany}
                                setExistingCompany={handleExistingCompany}
                                type="created_opportunity"
                            />
                        </div>

                        <div className="p-mb-2 p-mr-4">
                            <h5>Contato</h5>

                            {isEmptyObj(existingCompany) && (
                                <Contact
                                    formContact={formContact}
                                    setFormContact={handleUpdateContact}
                                    setNewPhoneField={handleUpdateFieldPhone}
                                    setNewEmailField={handleUpdateFieldEmail}
                                    newEmailField={newEmailField}
                                    newPhoneField={newPhoneField}
                                    type="opportunnityCreate"
                                />
                            )}

                            {!isEmptyObj(existingCompany) && (
                                <>
                                    <p style={{ color: "red" }}>Contatos cadastrados na empresa.</p>

                                    <ScrollPanel style={{ width: "100%", height: "300px" }}>
                                        {existingCompany.contacts ? (
                                            existingCompany.contacts.map((contact: ContactInterface, indexContact: number) => {
                                                return (
                                                    <>
                                                        <Panel style={{ minWidth: "200px" }} header={contact.name.toUpperCase()} collapsed={true} toggleable>
                                                            <div className="p-grid">
                                                                <div className="p-col-12 p-md-12 p-lg-12">
                                                                    <p>
                                                                        <strong>Telefones</strong>
                                                                    </p>
                                                                    {contact.phones?.map((phone: ContactPhoneInterface) => (
                                                                        <p>{phone.number}</p>
                                                                    ))}
                                                                </div>

                                                                <div className="p-col-12 p-md-12 p-lg-12">
                                                                    <p>
                                                                        <strong>Emails</strong>
                                                                    </p>
                                                                    {contact.emails?.map((email: ContactEmailInterface) => (
                                                                        <p>{email.email}</p>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </Panel>
                                                        <br />
                                                    </>
                                                )
                                            })
                                        ) : (
                                            <h5 style={{ color: "rgb(169 164 164 / 70%)", textAlign: "center" }}>
                                                Não há contatos <br /> cadastrados nesta empresa!
                                            </h5>
                                        )}
                                    </ScrollPanel>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="p-dialog-footer">
                        <Button label="Cancelar" type="button" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                        <Button label="Salvar" type="submit" loading={loadingCreateOpportunity} icon="pi pi-check" className="p-button-success p-button-text" />
                    </div>
                </form>
            </Dialog>
        </>
    )
}
