import React from "react"
import { Dropdown } from "primereact/dropdown"
import { TaskInterface } from "../../../../interfaces/TaskInterface"
import api from "../../../../services/api"

interface PropsInterface {
    type?: string
    postPone: TaskInterface
    setSavePostPoneData: (e: any) => void
}

export const PostPoneTasks = (props: PropsInterface) => {
    const token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    const postpone = [
        { name: "1 Hora", hour: 1 },
        { name: "3 Horas", hour: 3 },
        { name: "Amanhã", hour: 24 },
        { name: "Dois Dias", hour: 48 },
        { name: "Próxima Semana", hour: 168 },
        { name: "Próximo Mês", hour: 720 },
    ]

    return (
        <>
            <div className="p-d-flex p-flex-column p-flex-md-row">
                <div className="p-mb-2 p-mr-6">
                    <div className="p-field">
                        <label htmlFor="postPone">Adiar em:</label>
                        <Dropdown
                            style={{ minWidth: "25rem" }}
                            id="postPone"
                            emptyMessage="Não há dados"
                            emptyFilterMessage="Não há dados"
                            name="postPone"
                            value={props.postPone}
                            options={postpone}
                            filter={true}
                            optionLabel="name"
                            placeholder="Selecionar"
                            onChange={(e: any) => {
                                props.setSavePostPoneData(e.target.value)
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
