import React from "react"
import { Card } from "primereact/card"
import Moment from "react-moment"
import moment from "moment"
import { Button } from "primereact/button"
export const MetaPreview = (props: any) => {
    let dataAtual = moment()

    const renderHTML = () => {
        if (props.bodyText.length > 1024) {
            return (
                <div style={{ fontWeight: "bold" }}>
                    <h6>Limite de caracteres excedido</h6>
                    <p>Insira entre 1 a 1024 caracteres</p>
                </div>
            )
        } else {
            return <div dangerouslySetInnerHTML={{ __html: props.bodyText }} />
        }
    }

    return (
        <div className="preview-content" style={{ paddingLeft: "2rem" }}>
            <div className="p-d-flex">
                <div className="p-mr-2">
                    <h4 className="preview-title" style={{ fontWeight: "bold" }}>
                        Pré-visualização
                    </h4>
                </div>
                <div className="p-mr-2">
                    {props.bodyText && (
                        <Button style={{ marginLeft: "2rem" }} label="Enviar para Aprovação" className="p-button p-mr-2" icon="pi pi-facebook" />
                    )}
                </div>
            </div>

            <p className="preview-description">Veja como sua mensagem ficará no WhatsApp</p>
            <div className="preview-card-container p-d-flex">
                <div className="preview-card-wrapper p-mr-2">
                    <div className="card-inner-container p-d-flex p-jc-center">
                        <div style={{ position: "absolute" }}>
                            {(props.headerText || props.bodyText || props.footerText) && (
                                <Card
                                    className="preview-card"
                                    style={{
                                        backgroundColor: "#e1ffc7",
                                        marginTop: "0.3rem",
                                        borderRadius: " 5px 0px 5px 5px",
                                        float: "right",
                                        maxWidth: "25rem",
                                        maxHeight: "90rem",
                                        overflowWrap: "break-word",
                                    }}
                                >
                                    <div className="preview-card-content" style={{ marginTop: "-1rem", marginBottom: "-1rem" }}>
                                        {props.headerText && (
                                            <h5 className="preview-card-header" style={{ fontWeight: "bold" }}>
                                                {props.headerText}
                                            </h5>
                                        )}
                                        {props.bodyText && renderHTML()}
                                        {props.footerText && (
                                            <h6 className="preview-card-footer" style={{ color: "gray", fontWeight: "lighter" }}>
                                                {props.footerText}
                                            </h6>
                                        )}
                                        <br />
                                        <div className="time-container p-d-flex p-jc-end">
                                            <Moment
                                                className="time-text"
                                                style={{
                                                    userSelect: "none",
                                                    color: "rgba(141, 142, 143, 1)",
                                                }}
                                                format="HH:mm"
                                            >
                                                {dataAtual}
                                            </Moment>
                                        </div>
                                    </div>
                                </Card>
                            )}
                        </div>
                        <img
                            src="https://i.pinimg.com/736x/8c/98/99/8c98994518b575bfd8c949e91d20548b.jpg"
                            className="preview-card-img"
                            style={{ width: "30rem", height: "60rem" }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
