import React from "react"
import { AllProposals } from "./../pages/crm/AllProposals"
import { Attachments } from "../pages/configurations/Attendance/Attachments"
import { Attendance } from "../pages/crm/Attendance"
import { Avatars } from "../pages/configurations/Avatars"
import { ClientCompany } from "./../pages/crm/ClientCompany"
import { Contact } from "./../pages/crm/Contact"
import { CrudGroups } from "../pages/administration/CrudGroups"
import { CrudRotinas } from "../pages/administration/CrudRotinas"
import { CrudUser } from "../pages/administration/CrudUser"
import { DealsLost } from "../pages/configurations/DealsLost"
import { DealsSources } from "../pages/configurations/DealsSources"
import { DefaultMessages } from "../pages/configurations/Attendance/DefaultMessages"
import { FillingQuotes } from "../pages/purchases/FillingQuotes"
import { Funnel } from "../pages/configurations/Funnel"
import { FunnelAutomatization } from "../pages/configurations/FunnelAutomatization"
import { Groups } from "../pages/configurations/Attendance/Groups"
import { Interests } from "../pages/configurations/Interests"
import { Layouts } from "../pages/configurations/Layouts"
import { Leads } from "./../pages/crm/Leads"
import { Live } from "./../pages/crm/Live"
import { Login } from "../pages/Login"
import { MetaTeamplate } from "../pages/configurations/Attendance/MetaTeamplate"
import { NotFound } from "./../pages/NotFound"
import { OpenQuotes } from "../pages/purchases/OpenQuotes"
import { Opportunity } from "./../pages/crm/Opportunity"
import { ClearOpportunity } from "../pages/crm/OpportunityClear"
import { Preferences } from "../pages/configurations/Preferences"
import { Presentation } from "../pages/configurations/Presentation"
import { Products } from "../pages/purchases/Products"
import { ProductsCRM } from "../pages/configurations/ProductsCRM"
import { Concurrent } from "../pages/configurations/Concurrent"
import { Profile } from "./../pages/user/Profile"
import { Proposal } from "./../pages/crm/Proposal"
import { PurchaseOrders } from "../pages/purchases/PurchaseOrders"
import { Quotes } from "../pages/purchases/Quotes"
import { ResetPassword } from "../pages/ResetPassword"
import { RoadMap } from "../pages/configurations/Attendance/RoadMap"
import { Solicitation } from "../pages/purchases/Solicitation"
import { SurpriseOpportunity } from "../components/crm/redirect/SurpriseOpportunity"
import { Switch as RoutesDom } from "react-router-dom"
import { Tasks } from "./../pages/crm/Tasks"
import { ToAnalyze } from "./../pages/crm/ToAnalyze"
import { Criacao } from "../pages/Criacao"
import Route from "./AuthRoutes"
const Routes: React.FC = () => {
    return (
        <>
            <RoutesDom>
                <Route path="/" exact component={ClearOpportunity} isPrivate />

                {/* Inicio Administration */}
                <Route path="/administrador/usuarios" component={CrudUser} isPrivate />
                <Route path="/administrador/rotinas" component={CrudRotinas} isPrivate />
                <Route path="/administrador/grupos" component={CrudGroups} isPrivate />
                {/* Fim Administration */}

                {/* Inicio Configurações do Sistema */}
                <Route path="/configuracoes/avatars" component={Avatars} isPrivate />

                {/* Inicio Purchases */}
                <Route path="/compras/produtos" component={Products} isPrivate />
                <Route path="/compras/enviar_cotacao" component={OpenQuotes} isPrivate />
                <Route path="/compras/solicitacao" component={Solicitation} isPrivate />
                <Route path="/compras/cotacao" component={Quotes} isPrivate />
                <Route path="/compras/pedidos_compras" component={PurchaseOrders} isPrivate />
                <Route path="/criacao" component={Criacao} isPrivate />

                {/* Fim Purchases */}

                {/* Inicio CRM */}
                <Route path="/crm/oportunidades" component={Opportunity} isPrivate />
                <Route path="/crm/oportunidades_limpa" component={ClearOpportunity} isPrivate />
                <Route path="/crm/leads/:lead/:parameter?" component={Leads} isPrivate />
                <Route path="/crm/proposta/:proposal/:password?" component={Proposal} />
                <Route path="/crm/empresa-cliente/:companyParam?/:informationParam?" component={ClientCompany} isPrivate />
                <Route path="/crm/tarefas" component={Tasks} isPrivate />
                <Route path="/crm/analisar" component={ToAnalyze} isPrivate />
                <Route path="/crm/contatos/:param?" component={Contact} isPrivate />
                <Route path="/crm/atendimento/:paramId?/:phone?" component={Attendance} isPrivate />
                <Route path="/crm/crmlive" component={Live} isPrivate />
                <Route path="/crm/relatorios" component={ToAnalyze} isPrivate />
                <Route path="/crm/todas/propostas" component={AllProposals} isPrivate />
                <Route path="/crm/surpreenda-me" component={SurpriseOpportunity} isPrivate />
                {/* Fim CRM */}

                {/* Inicio Configurações CRM */}
                <Route path="/crm/configuracoes/funis-vendas" component={Funnel} isPrivate />
                <Route path="/crm/configuracoes/interesses" component={Interests} isPrivate />
                <Route path="/crm/configuracoes/Fontes" component={DealsSources} isPrivate />
                <Route path="/crm/configuracoes/motivo_perda" component={DealsLost} isPrivate />
                <Route path="/crm/configuracoes/produtos" component={ProductsCRM} isPrivate />
                <Route path="/crm/configuracoes/concorrentes" component={Concurrent} isPrivate />


                <Route path="/crm/configuracoes/automatizacao-etapas" component={FunnelAutomatization} isPrivate />
                <Route path="/crm/configuracoes/preferencias" component={Preferences} isPrivate />
                <Route path="/crm/configuracoes/apresentacoes" component={Presentation} isPrivate />
                <Route path="/crm/configuracoes/layouts" component={Layouts} isPrivate />
                <Route path="/crm/configuracoes/atendimento/anexos" component={Attachments} isPrivate />
                <Route path="/crm/configuracoes/atendimento/grupos" component={Groups} isPrivate />
                <Route path="/crm/configuracoes/atendimento/mensagem-padrao" component={DefaultMessages} isPrivate />
                <Route path="/crm/configuracoes/atendimento/roteiro" component={RoadMap} isPrivate />
                <Route path="/crm/configuracoes/atendimento/template" component={MetaTeamplate} isPrivate />
                {/* Fim Configurações CRM */}

                {/* Inicio User */}
                <Route path="/perfil" component={Profile} isPrivate />
                {/* Fim User */}

                <Route path="/login" component={Login} />
                <Route path="/reset_password" component={ResetPassword} />
                <Route path="/quote_filling/:token" component={FillingQuotes} />

                <Route path="*" component={NotFound} isPrivate />
            </RoutesDom>
        </>
    )
}

export default Routes
