
import React, { useRef, useState, useEffect } from "react"
import App from "../../../layout/App"
import api from "../../../services/api"
import moment from "moment"
import classNames from "classnames"
import Moment from "react-moment"
import { useHotkeys } from "react-hotkeys-hook"

//PrimeReact components
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { ConfirmDialog } from "primereact/confirmdialog"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Toast } from "primereact/toast"

//Import files
import { CrmRecords } from "../../../components/crm/CrmRecords"

//CSS
import "react-dropzone-uploader/dist/styles.css"

//Interfaces
import { AttachmentInterface } from "../../../interfaces/AttachmentInterface"
import { FilterMatchMode, FilterOperator } from "primereact/api"
import { HotKeys } from "../../../components/crm/HotKeys"

export const Groups = () => {
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`
    let toast = useRef<Toast>(null)

    let [groupsAttachments, setGroupsAttachments] = useState<AttachmentInterface[]>([])
    let [subGroups, setSubGroups] = useState<AttachmentInterface[]>([])

    let [groupAttachment, setGroupAttachment] = useState<AttachmentInterface>({} as AttachmentInterface)
    let [subGroup, setSubGroup] = useState<AttachmentInterface>({} as AttachmentInterface)
    let [submitted, setSubmitted] = useState<boolean>(false)
    let [createValues, setCreateValues] = useState<AttachmentInterface>({} as AttachmentInterface)

    //Loading
    let [loadingList, setLoadingList] = useState<boolean>(true)
    let [loadingEdit, setLoadingEdit] = useState<boolean>(false)
    let [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    let [loadingDelete, setLoadingDelete] = useState<boolean>(false)

    //Dialog
    //Attachment
    let [groupsDialogEdit, setGroupsDialogEdit] = useState(false)
    let [subGroupsDialogEdit, setSubGroupsDialogEdit] = useState(false)
    let [hotKeysDialog, setHotKeysDialog] = useState(false)

    //Groups
    let [deleteGroupExcludeDialog, setDeleteGroupExcludeDialog] = useState(false)
    let [deleSubGroupDialog, setDeleSubGroupDialog] = useState(false)
    let [createGroupDialog, setCreateGroupDialog] = useState(false)
    let [subGroupDialog, setSubGroupDialog] = useState(false)
    let [creatSubeGroupDialog, setSubCreateGroupDialog] = useState(false)

    //Filters
    let [auxGroupFilter, setAuxGroupFilter] = useState<AttachmentInterface[]>([])
    let [auxSubGroupFilter, setSubAuxGroupFilter] = useState<AttachmentInterface[]>([])
    let [globalFilterValue, setGlobalFilterValue] = useState("")
    let [globalSubGroupFilterValue, setSubGroupGlobalFilterValue] = useState("")

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    })

    const [subFilters, setSubFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    })

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value
        let _filters = { ...filters }
        _filters["global"].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    const onGlobalSubGroupFilterChange = (e: any) => {
        const value = e.target.value
        let _filters = { ...subFilters }
        _filters["global"].value = value

        setSubFilters(_filters)
        setSubGroupGlobalFilterValue(value)
    }

    const header = (item: any) => {
        return (
            <div className="table-header">
                <h5 style={{ paddingLeft: "5px" }} className="p-m-0">
                    {item.name}
                </h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Pesquisar..." />
                </span>
            </div>
        )
    }

    const headerSubGroup = (item: any) => {
        return (
            <div className="table-header">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalSubGroupFilterValue} onChange={onGlobalSubGroupFilterChange} placeholder="Pesquisar ..." />
                </span>
            </div>
        )
    }

    //Hotkeys
    useHotkeys("shift+n", () => openNewDialog())
    useHotkeys("shift+i", () => setHotKeysDialog(true))

    //List Method
    const allGroupsAttachments = async () => {
        try {
            let header = {
                headers: {
                    route: "list_chat_groups",
                },
            }

            await api
                .get("chat_groups", header)
                .then((res) => {
                    setLoadingList(false)
                    groupsAttachments = res.data

                    let auxGroup: AttachmentInterface = {} as AttachmentInterface

                    auxGroup.name = "SEM CATEGORIA"
                    auxGroup.id = "000001"

                    setGroupsAttachments([...groupsAttachments, auxGroup])
                    setAuxGroupFilter([...groupsAttachments, auxGroup])
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const allSubGroups = async (item: any) => {
        setLoadingList(true)
        let auxSubGroups: any = []
        try {
            let header = {
                headers: {
                    route: "list_chat_subgroups",
                },
            }

            await api
                .get("chat_subgroups", header)
                .then((res) => {
                    setLoadingList(false)
                    res.data.forEach((resData: any) => {
                        if (item.id === resData.chat_group_id) {
                            auxSubGroups.push(resData)
                        }
                    })
                    subGroups = auxSubGroups
                    setSubGroups(subGroups)
                })
                .catch((error) => {
                    setLoadingList(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            setLoadingList(false)
            console.log(error)
        }
    }

    //Filters

    const findIndexByIdGroup = (id: string) => {
        let index = -1
        for (let i = 0; i < groupsAttachments.length; i++) {
            if (groupsAttachments[i].id === id) {
                index = i
                break
            }
        }

        return index
    }

    const findIndexByIdSubGroup = (id: string) => {
        let index = -1
        for (let i = 0; i < subGroups.length; i++) {
            if (subGroups[i].id === id) {
                index = i
                break
            }
        }

        return index
    }

    //OnChange
    const onChangeCreateAttachments = (event: any) => {
        const { value, name } = event.target

        setCreateValues({
            ...createValues,
            [name]: value,
        })
    }

    const onChangeEditGroup = (e: any, name: string) => {
        const val = (e.target && e.target.value) || ""
        let _group = { ...groupAttachment }

        switch (name) {
            case "name":
                _group["name"] = val
                break

            default:
                break
        }

        setGroupAttachment(_group)
    }

    const onChangeEditSubGroup = (e: any, name: string) => {
        const val = (e.target && e.target.value) || ""
        let _group = { ...subGroup }

        switch (name) {
            case "name":
                _group["name"] = val
                break

            default:
                break
        }

        setSubGroup(_group)
    }

    //Requisitions
    const onDeleteGroup = () => {
        setLoadingDelete(true)
        if (groupAttachment.id === "000001") {
            setLoadingDelete(false)
            setDeleteGroupExcludeDialog(false)

            toast.current?.show({
                severity: "error",
                summary: "Erro ao excluir!",
                detail: "Você não pode excluir esta Categorias",
                life: 3000,
            })
        } else {
            try {
                api.delete("chat_groups", {
                    headers: {
                        route: "delete_chat_group",
                    },
                    data: {
                        id: groupAttachment.id,
                    },
                })

                    .then((res) => {
                        setLoadingDelete(false)
                        if (res.status === 200) {
                            let auxDeleteAttachments = groupsAttachments.filter((item) => groupAttachment.id !== item.id)
                            groupsAttachments = auxDeleteAttachments
                            auxGroupFilter = auxDeleteAttachments
                            setDeleteGroupExcludeDialog(false)
                            setGroupsAttachments(auxDeleteAttachments)
                            setAuxGroupFilter(auxDeleteAttachments)

                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso na exclusão",
                                detail: "Categoria excluída com sucesso!",
                                life: 3000,
                            })
                        } else {
                            setLoadingDelete(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao excluir!",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingDelete(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao excluir!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        }
    }

    const onDeleteSubGroup = () => {
        try {
            api.delete("chat_subgroups", {
                headers: {
                    route: "delete_chat_subgroup",
                },
                data: {
                    id: groupAttachment.id,
                },
            })

                .then((res) => {
                    setLoadingDelete(false)
                    if (res.status === 200) {
                        let auxDeleteAttachments = subGroups.filter((item) => groupAttachment.id !== item.id)
                        subGroups = auxDeleteAttachments
                        auxSubGroupFilter = auxDeleteAttachments
                        setDeleSubGroupDialog(false)
                        setSubGroups(auxDeleteAttachments)
                        setSubAuxGroupFilter(auxDeleteAttachments)

                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na exclusão",
                            detail: "Subcategoria excluída com sucesso!",
                            life: 3000,
                        })
                    } else {
                        setLoadingDelete(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao excluir!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingDelete(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao excluir!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmitGroup = (event: any) => {
        setLoadingEdit(true)
        event.preventDefault()
        setSubmitted(false)
        const { name } = createValues

        if (name === null || name === "" || name === undefined) {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao Cadastrar",
                detail: "O nome da categoria não pode ser  vazio",
                life: 3000,
            })
            setLoadingEdit(false)
        } else {
            try {
                if (name === "") {
                    setLoadingEdit(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao cadastrar",
                        detail: "Por favor, verificar os dados preenchidos!",
                        life: 3000,
                    })
                } else {
                    let header = {
                        headers: {
                            route: "create_chat_group",
                        },
                    }

                    api.post(
                        "chat_groups",
                        {
                            name,
                        },
                        header
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                setCreateGroupDialog(false)
                                setCreateValues({} as AttachmentInterface)
                                setLoadingEdit(false)
                                setGroupsAttachments([...groupsAttachments, res.data])
                                toast.current?.show({
                                    severity: "success",
                                    summary: "Sucesso",
                                    detail: "Categoria criada com sucesso",
                                    life: 3000,
                                })
                            } else {
                                setLoadingEdit(false)
                                toast.current?.show({
                                    severity: "error",
                                    summary: "Erro ao cadastar!",
                                    detail: res.data.message,
                                    life: 3000,
                                })
                            }
                        })
                        .catch((error) => {
                            setLoadingEdit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao cadastar!",
                                detail: error.response.data.message,
                                life: 3000,
                            })
                        })
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const onSubmintSubGroup = (event: any) => {
        setLoadingEdit(true)
        event.preventDefault()
        setSubmitted(false)

        const { name } = createValues

        if (name === null || name === "" || name === undefined) {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao Cadastrar",
                detail: "O nome da categoria não pode ser  vazio",
                life: 3000,
            })
            setLoadingEdit(false)
        } else {
            try {
                let auxCreateSubGroup = {
                    name: name,
                    chat_group_id: subGroup.id,
                }

                if (name === "") {
                    setLoadingEdit(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao cadastrar",
                        detail: "Por favor, verificar os dados preenchidos!",
                        life: 3000,
                    })
                } else {
                    let header = {
                        headers: {
                            route: "create_chat_subgroup",
                        },
                    }

                    api.post(
                        "chat_subgroups",

                        auxCreateSubGroup,
                        header
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                setSubCreateGroupDialog(false)
                                setCreateValues({} as AttachmentInterface)
                                setLoadingEdit(false)
                                setSubGroups([...subGroups, res.data])
                                toast.current?.show({
                                    severity: "success",
                                    summary: "Sucesso",
                                    detail: "Subcategoria criada com sucesso",
                                    life: 3000,
                                })
                            } else {
                                setLoadingEdit(false)
                                toast.current?.show({
                                    severity: "error",
                                    summary: "Erro ao cadastar!",
                                    detail: res.data.message,
                                    life: 3000,
                                })
                            }
                        })
                        .catch((error) => {
                            setLoadingEdit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao cadastar!",
                                detail: error.response.data.message,
                                life: 3000,
                            })
                        })
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const onSubmitEditGroup = () => {
        const { name, id } = groupAttachment
        setLoadingSubmit(true)
        if (name === null || name === "") {
            setLoadingSubmit(false)
            toast.current?.show({
                severity: "error",
                summary: "Erro ao Editar",
                detail: "O nome da categoria não pode ser  vazio",
                life: 3000,
            })
        } else {
            try {
                let header = {
                    headers: {
                        route: "update_chat_group",
                    },
                }

                api.put("chat_groups", { id, name }, header)

                    .then((res) => {
                        if (res.status === 200) {
                            let _interests = [...groupsAttachments]
                            let _interest = res.data
                            let index = findIndexByIdGroup(res.data.id)
                            _interests[index] = _interest
                            groupsAttachments = _interests
                            auxGroupFilter = _interests
                            setGroupsAttachments(_interests)
                            setAuxGroupFilter(_interests)
                            setGroupsDialogEdit(false)
                            setLoadingSubmit(false)
                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso na alteração",
                                detail: "Categoria alterado com sucesso!",
                                life: 3000,
                            })
                        } else {
                            setLoadingSubmit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao Editar",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingSubmit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Editar",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        }
    }

    const onSubmitEdiSubtGroup = () => {
        const { name, id } = subGroup

        if (name === null || name === "") {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao Editar",
                detail: "O nome da subcategoria não pode ser  vazio",
                life: 3000,
            })
        } else {
            try {
                let auxGroup = {
                    chat_group_id: subGroup.chat_group_id,
                    name: name,
                    id: id,
                }

                let header = {
                    headers: {
                        route: "update_chat_subgroup",
                    },
                }

                api.put("chat_subgroups", auxGroup, header)

                    .then((res) => {
                        if (res.status === 200) {
                            let _interests = [...subGroups]
                            let _interest = res.data
                            let index = findIndexByIdSubGroup(res.data.id)
                            _interests[index] = _interest
                            subGroups = _interests
                            auxSubGroupFilter = _interests
                            setSubGroups(_interests)
                            setSubAuxGroupFilter(_interests)
                            setSubGroupsDialogEdit(false)

                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso na alteração",
                                detail: "Categoria alterado com sucesso!",
                                life: 3000,
                            })
                        } else {
                            setLoadingSubmit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao Editar",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingSubmit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Editar",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        }
    }

    //Datatble formater
    const dateFormatCreate = (rowData: any) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.created_at}</Moment>
            </>
        )
    }

    const dateFormatUpdate = (rowData: any) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.updated_at}</Moment>
            </>
        )
    }

    const dataTableButtons = (rowData: any) => {
        return (
            <div className="actions">
                {rowData.id !== "000001" && (
                    <>
                        <Button icon="pi pi-user-edit" className="p-button-rounded p-button" onClick={() => editGroupDialog(rowData)} />
                        &nbsp;&nbsp;
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => deleteGroupDialog(rowData)} />
                        &nbsp;&nbsp;
                        <Button icon="pi pi-list" className="p-button-rounded p-button-warning" onClick={() => subGroupDialogOpen(rowData)} />
                    </>
                )}
            </div>
        )
    }

    const dataTableSubGroupsButtons = (rowData: any) => {
        return (
            <div className="actions">
                {rowData.id !== "000001" && (
                    <>
                        <Button icon="pi pi-user-edit" className="p-button-rounded p-button" onClick={() => editSubGroupDialog(rowData)} />
                        &nbsp;&nbsp;
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => deleteSubGroupDialog(rowData)} />
                    </>
                )}
            </div>
        )
    }

    //Dialogs
    const hideDialog = () => {
        setCreateValues({} as AttachmentInterface)
        setSubGroups([])
        setSubmitted(false)
        setHotKeysDialog(false)
        setSubGroupDialog(false)
        setDeleSubGroupDialog(false)
    }

    const deleteGroupMessage = () => {
        return (
            <>
                <h4>
                    <strong>Confirmação de Exclusão:</strong>
                </h4>
                <br />
                <h5>
                    <strong>Categoria: </strong>
                    <span>{groupAttachment.name}</span>
                </h5>
                <h5>
                    <strong>Data Inclusão: </strong>
                    <span>
                        <Moment format="DD-MM-YYYY">{groupAttachment.created_at}</Moment>
                    </span>
                </h5>
                <h5>
                    <strong>Data Exclusão: </strong>
                    <span>
                        <Moment format="DD-MM-YYYY">{moment()}</Moment>
                    </span>
                </h5>
            </>
        )
    }

    const openNewDialog = () => {
        setCreateGroupDialog(true)
    }

    const editGroupDialog = (item: AttachmentInterface) => {
        setGroupsDialogEdit(true)
        setGroupAttachment(item)
    }

    const editSubGroupDialog = (item: AttachmentInterface) => {
        setSubGroupsDialogEdit(true)
        setSubGroup(item)
    }

    const deleteGroupDialog = (item: AttachmentInterface) => {
        setGroupAttachment(item)
        setDeleteGroupExcludeDialog(true)
    }

    const deleteSubGroupDialog = (item: AttachmentInterface) => {
        setGroupAttachment(item)
        setDeleSubGroupDialog(true)
    }

    const subGroupDialogOpen = (item: AttachmentInterface) => {
        allSubGroups(item)
        setSubGroup(item)
        setGroupAttachment(item)
        setSubGroupDialog(true)
    }

    //Footers
    const groupsCreateDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={() => setCreateGroupDialog(false)} />

            <Button label="Salvar" loading={loadingEdit} icon="pi pi-check" className="p-button-success p-button-text" onClick={onSubmitGroup} />
        </>
    )

    const subgroupsCreateDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={() => setSubCreateGroupDialog(false)} />

            <Button label="Salvar" loading={loadingEdit} icon="pi pi-check" className="p-button-success p-button-text" onClick={onSubmintSubGroup} />
        </>
    )

    const groupsEditDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={() => setGroupsDialogEdit(false)} />

            <Button label="Salvar" loading={loadingSubmit} icon="pi pi-check" className="p-button-success p-button-text" onClick={onSubmitEditGroup} />
        </>
    )

    const subGroupsEditDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={() => setSubGroupsDialogEdit(false)} />

            <Button label="Salvar" loading={loadingSubmit} icon="pi pi-check" className="p-button-success p-button-text" onClick={onSubmitEdiSubtGroup} />
        </>
    )

    const deleteDialogMessageFooter = () => {
        return (
            <>
                <Button label="Não" icon="pi pi-times" className="p-button-danger p-button-text" onClick={() => setDeleteGroupExcludeDialog(false)} />
                <Button label="Sim" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingDelete} onClick={onDeleteGroup} />
            </>
        )
    }

    const deleteMessage = () => {
        return (
            <>
                {" "}
                Deseja realmente excluir o grupo <strong>{groupAttachment.name}</strong>
            </>
        )
    }

    const deleteSubGroupDialogMessageFooter = () => {
        return (
            <>
                <Button label="Não" icon="pi pi-times" className="p-button-danger p-button-text" onClick={() => setDeleSubGroupDialog(false)} />
                <Button label="Sim" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingDelete} onClick={onDeleteSubGroup} />
            </>
        )
    }

    const deleteMessageGrup = () => {
        return (
            <>
                {" "}
                Deseja realmente excluir a Subcategoria <strong>{groupAttachment.name}</strong>
            </>
        )
    }

    //UseEffect
    useEffect(() => {
        allGroupsAttachments()
    }, [])

    return (
        <App>
            <CrmRecords />
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />

                        {/* Create group button */}
                        <div className="p-d-flex p-jc-between">
                            <Button label="Novo" icon="pi pi-plus" className="p-button" onClick={openNewDialog} />
                        </div>

                        <br />

                        {/* DataTable Attachmets*/}
                        <DataTable
                            value={groupsAttachments}
                            loading={loadingList}
                            stripedRows
                            selectionMode="checkbox"
                            dataKey="id"
                            rows={10}
                            rowsPerPageOptions={[10, 15, 20]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} Categorias"
                            paginator
                            filters={filters}
                            globalFilterFields={["name"]}
                            header={header}
                            emptyMessage="Não há Categorias"
                        >
                            <Column field="name" header="Categoria" sortable align="center" alignHeader="center" />
                            <Column body={dateFormatCreate} header="Data de Criação" sortable sortField="created_at" align="center" alignHeader="center" />
                            <Column body={dateFormatUpdate} sortField="update_at" header="Data de Atualização" sortable align="center" alignHeader="center" />
                            <Column align="center" alignHeader="center" body={dataTableButtons}></Column>
                        </DataTable>

                        {/* Subgroup DataTable */}
                        <Dialog
                            visible={subGroupDialog}
                            style={{ width: "1200px" }}
                            modal
                            className="p-fluid"
                            onHide={hideDialog}
                            header={`Cadastro de Subcategorias: ${groupAttachment.name}`}
                            draggable={false}
                        >
                            <Button
                                label="Criar Subcategoria"
                                icon="pi pi-user-plus"
                                style={{ width: "12rem" }}
                                onClick={() => setSubCreateGroupDialog(true)}
                            />
                            <br />
                            <br />
                            <DataTable
                                value={subGroups}
                                loading={loadingList}
                                stripedRows
                                selectionMode="checkbox"
                                dataKey="id"
                                rows={10}
                                rowsPerPageOptions={[10, 15, 20]}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} Subcategoria"
                                paginator
                                filters={subFilters}
                                globalFilterFields={["name"]}
                                header={headerSubGroup}
                                emptyMessage="Não há Subcategorias"
                            >
                                <Column field="name" header="Subcategoria" sortable align="center" alignHeader="center" />
                                <Column body={dateFormatCreate} header="Data de Criação" sortable sortField="created_at" align="center" alignHeader="center" />
                                <Column
                                    body={dateFormatUpdate}
                                    sortField="update_at"
                                    header="Data de Atualização"
                                    sortable
                                    align="center"
                                    alignHeader="center"
                                />
                                <Column align="center" alignHeader="center" body={dataTableSubGroupsButtons}></Column>
                            </DataTable>
                        </Dialog>

                        {/* Create group  Dialog */}
                        <Dialog
                            visible={createGroupDialog}
                            style={{ width: "450px" }}
                            header="Criar Categoria"
                            modal
                            className="p-fluid"
                            draggable={false}
                            footer={groupsCreateDialogFooter}
                            onHide={() => {
                                setCreateGroupDialog(false)
                                setCreateValues({} as AttachmentInterface)
                            }}
                        >
                            <div className="p-field">
                                <label htmlFor="name">Categoria:</label>
                                <InputText
                                    required
                                    id="name"
                                    name="name"
                                    autoFocus
                                    type="String"
                                    maxLength={35}
                                    value={createValues.name || ""}
                                    onChange={onChangeCreateAttachments}
                                    className={
                                        "p-field " +
                                        classNames({
                                            "p-invalid": submitted,
                                        })
                                    }
                                />
                            </div>
                        </Dialog>

                        {/* Create subgroup  Dialog */}
                        <Dialog
                            visible={creatSubeGroupDialog}
                            style={{ width: "450px" }}
                            header="Criar Categoria"
                            modal
                            className="p-fluid"
                            draggable={false}
                            footer={subgroupsCreateDialogFooter}
                            onHide={() => {
                                setSubCreateGroupDialog(false)
                                setCreateValues({} as AttachmentInterface)
                            }}
                        >
                            <div className="p-field">
                                <label htmlFor="name">Subcategoria:</label>
                                <InputText
                                    required
                                    id="name"
                                    name="name"
                                    autoFocus
                                    type="String"
                                    maxLength={35}
                                    value={createValues.name || ""}
                                    onChange={onChangeCreateAttachments}
                                    className={
                                        "p-field " +
                                        classNames({
                                            "p-invalid": submitted,
                                        })
                                    }
                                />
                            </div>
                        </Dialog>

                        {/* Edit group  Dialog */}
                        <Dialog
                            visible={groupsDialogEdit}
                            style={{ width: "450px" }}
                            header="Editar Categoria"
                            modal
                            draggable={false}
                            className="p-fluid"
                            footer={groupsEditDialogFooter}
                            onHide={() => setGroupsDialogEdit(false)}
                        >
                            <div className="p-field">
                                <label htmlFor="name">Categoria:</label>
                                <InputText
                                    required
                                    id="name"
                                    name="name"
                                    autoFocus
                                    type="String"
                                    maxLength={35}
                                    onChange={(e: any) => onChangeEditGroup(e, "name")}
                                    value={groupAttachment.name}
                                    className={
                                        "p-field " +
                                        classNames({
                                            "p-invalid": submitted,
                                        })
                                    }
                                />
                            </div>
                        </Dialog>

                        {/* Edit subgroup  Dialog */}
                        <Dialog
                            visible={subGroupsDialogEdit}
                            style={{ width: "450px" }}
                            header="Editar SubCategoria"
                            draggable={false}
                            modal
                            className="p-fluid"
                            footer={subGroupsEditDialogFooter}
                            onHide={() => setSubGroupsDialogEdit(false)}
                        >
                            <div className="p-field">
                                <label htmlFor="name">Subcategoria:</label>
                                <InputText
                                    required
                                    id="name"
                                    name="name"
                                    autoFocus
                                    type="String"
                                    maxLength={35}
                                    onChange={(e: any) => onChangeEditSubGroup(e, "name")}
                                    value={subGroup.name}
                                    className={
                                        "p-field " +
                                        classNames({
                                            "p-invalid": submitted,
                                        })
                                    }
                                />
                            </div>
                        </Dialog>

                        {/* HotKeys Dialog */}
                        <Dialog
                            visible={hotKeysDialog}
                            style={{ width: "550px" }}
                            modal
                            className="p-fluid"
                            onHide={hideDialog}
                            header={`Atalhos - Tela de Cateogrias de Atendimentos`}
                            draggable={false}
                        >
                            <HotKeys create={"Criar uma nova Categoria"} />
                        </Dialog>

                        {/* Delete group Dialog */}

                        <ConfirmDialog
                            style={{ width: "400px", borderRadius: "16px" }}
                            draggable={false}
                            visible={deleteGroupExcludeDialog}
                            onHide={() => setDeleteGroupExcludeDialog(false)}
                            message={deleteMessage}
                            acceptLabel={"Sim"}
                            rejectLabel={"Não"}
                            header="Confirmação de exclusão"
                            icon="pi pi-exclamation-triangle"
                            accept={onDeleteGroup}
                            acceptIcon="pi pi-check"
                            rejectIcon="pi pi-times"
                            reject={hideDialog}
                            dismissableMask={true}
                            footer={deleteDialogMessageFooter}
                        />

                        {/* Delete subgrup Dialog */}
                        <ConfirmDialog
                            style={{ width: "400px", borderRadius: "16px" }}
                            draggable={false}
                            visible={deleSubGroupDialog}
                            onHide={hideDialog}
                            message={deleteMessageGrup}
                            acceptLabel={"Sim"}
                            rejectLabel={"Não"}
                            header="Confirmação de exclusão"
                            icon="pi pi-exclamation-triangle"
                            accept={onDeleteSubGroup}
                            acceptIcon="pi pi-check"
                            rejectIcon="pi pi-times"
                            reject={hideDialog}
                            dismissableMask={true}
                            footer={deleteSubGroupDialogMessageFooter}
                        />
                    </div>
                </div>
            </div>
        </App>
    )
}
