import Moment from "react-moment"
import ReactPlayer from "react-player"
import { MessagesWhatsappConversation } from "../../../../interfaces/MessagesWhatsappConversation"
import { UserInterface } from "../../../../interfaces/UserInterface"

type PropsInterface = {
    selectReplyMessageChat: MessagesWhatsappConversation
    profileUser: UserInterface
    setSelectReplyMessageChat: () => void
}

export const ReplyMessageChat = ({ selectReplyMessageChat, profileUser, setSelectReplyMessageChat }: PropsInterface) => {
    const React = require("react")

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    const archiveTypeForDocuments = (contactMessageWhatsapp: MessagesWhatsappConversation) => {
        let splitArchive = contactMessageWhatsapp.attachment.file.split(".")
        let lengthSplit = splitArchive.length
        if (splitArchive[lengthSplit - 1] === "pdf") {
            return (
                <iframe
                    src={contactMessageWhatsapp.attachment !== null ? contactMessageWhatsapp.attachment.file_url : ""}
                    frameBorder="0"
                    seamless={false}
                    title="document"
                    scrolling="no"
                    width="100%"
                    style={{ border: "none", overflow: "hidden" }}
                    height="98%"
                />
            )
        } else {
            return (
                <div>
                    <br />
                    <div className="p-d-flex p-jc-center">
                        <i className="pi pi-file" style={{ fontSize: "3em", color: "#54656f" }}></i>
                    </div>
                    <br />
                    <div className="p-d-flex p-jc-center" style={{ color: "#54656f" }}>
                        {contactMessageWhatsapp.attachment.name}
                    </div>
                </div>
            )
        }
    }

    return (
        <div>
            <div
                className="replyMessageBox"
                style={{
                    marginLeft: "0.5rem",
                    width: "100%",
                    maxHeight: "4rem",
                    backgroundColor: "rgba(100, 105, 98, 0.2)",
                    borderRadius: "1rem 0rem 0rem 1rem",
                    marginTop: "0.6rem",
                    marginBottom: "0.6rem",
                    padding: "0.2rem 2rem 1rem 1rem",
                }}
            >
                <div>
                    <div
                        style={{
                            cursor: "pointer",
                            position: "fixed",
                            right: "7%",
                            marginTop: "0.3rem",
                        }}
                    >
                        <a onClick={() => setSelectReplyMessageChat()}>
                            <i className="pi pi-times" style={{ color: "rgba(255, 0, 0, 0.5)" }}></i>
                        </a>
                    </div>
                    <div>
                        <p>
                            {selectReplyMessageChat.status !== "received" ? profileUser.name : "Cliente"}
                            &nbsp; &nbsp;
                            <Moment
                                style={{
                                    userSelect: "none",
                                    color: "rgba(141, 142, 143, 1)",
                                }}
                                format="HH:SS DD/MM/YYYY "
                            >
                                {selectReplyMessageChat.created_at}
                            </Moment>
                        </p>
                        {selectReplyMessageChat.type === "text" ? <div> {selectReplyMessageChat.text} </div> : <></>}
                        {selectReplyMessageChat.type === "image" ? (
                            <div>
                                <img
                                    src={!isEmptyObj(selectReplyMessageChat.attachment) ? selectReplyMessageChat.attachment.file_url : ""}
                                    width="100"
                                    height="auto"
                                    alt="Imagem"
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                        {selectReplyMessageChat.type === "document" ? <div>{archiveTypeForDocuments(selectReplyMessageChat)}</div> : <></>}
                        {selectReplyMessageChat.type === "audio" ? (
                            <div style={{ marginTop: "-0.7rem" }}>
                                <audio
                                    src={selectReplyMessageChat.attachment !== null ? selectReplyMessageChat.attachment.file_url : ""}
                                    className="audio-1"
                                    controls
                                    playsInline={true}
                                    style={{
                                        width: "30%",
                                        height: "2.3rem",
                                        resize: "none",
                                        outline: "none",
                                        borderRadius: "5px",
                                        textDecoration: "bold",
                                        fontSize: "1rem",
                                        marginLeft: "1rem",
                                        marginRight: "1rem",
                                    }}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                        {selectReplyMessageChat.type === "video" ? (
                            <div>
                                <ReactPlayer
                                    styles={{ backgroundColor: "red" }}
                                    autoPlay
                                    url={selectReplyMessageChat.attachment !== null ? selectReplyMessageChat.attachment.file_url : ""}
                                    width="20%"
                                    height="20%"
                                    controls={true}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                        {selectReplyMessageChat.type === "sticker" ? (
                            <img
                                src={!isEmptyObj(selectReplyMessageChat.attachment) ? selectReplyMessageChat.attachment.file_url : ""}
                                width="100"
                                alt="Imagem"
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
