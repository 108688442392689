import React, { useRef, useState, useEffect } from "react"
import App from "../../layout/App"
import api from "../../services/api"
import moment from "moment"
import Moment from "react-moment"
import * as yup from "yup"
import ReactTooltip from "react-tooltip"
import { Rating } from "primereact/rating"

//PrimeReact
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { Chip } from "primereact/chip"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { MultiSelect } from "primereact/multiselect"
import { Toast } from "primereact/toast"

//Components
import { ConfirmDialog } from "primereact/confirmdialog"
import { CompleteTask } from "../../components/crm/forms/tasks/CompleteTask"
import { CrmRecords } from "../../components/crm/CrmRecords"
import { PostPoneTasks } from "../../components/crm/forms/tasks/PostPoneTasks"
import { Task } from "../../components/crm/forms/tasks/Task"
import { taskType } from "../../listsArrays/ListsArrays"

//Interface
import { TaskInterface } from "../../interfaces/TaskInterface"
import { UserInterface } from "../../interfaces/UserInterface"
import { OpportunityInterface } from "../../interfaces/OpportunityInterface"

//CSS
import "../../styles/Tasks.scss"
interface TaskInterfaceDefault {
    id: string
    subject: string
    date: Date
    responsible: string
    related_company: string
    linked_opportunity: string
    users: UserInterface[]
    type: string
    annotation: string
    opportunity: OpportunityInterface
    status: string
}

export const Tasks: React.FC = () => {
    let token: string | null = localStorage.getItem("@esquadros:token")
    let toast = useRef<Toast>(null)
    api.defaults.headers.authorization = `Bearer ${token}`

    let [tasks, setTasks] = useState<TaskInterfaceDefault[]>([])
    let [task, setTask] = useState<TaskInterface>({} as TaskInterface)
    let [users, setUsers] = useState<TaskInterfaceDefault[]>([])

    //Componentes
    let [saveTaskData, setSaveTaskData] = useState<TaskInterface>({} as TaskInterface)
    let [savePostPoneData, setSavePostPoneData] = useState<any>(null)
    let [saveAnnotation, setSaveAnnotation] = useState("")

    let [auxTasksFiltered, setAuxTasksFiltered] = useState<TaskInterfaceDefault[]>([])
    let [postPoneActionValue, setPostPoneActionValue] = useState<any>(null)
    let [responsible, setResponsibles] = useState<any>(null)
    let [selectedTaks, setSelectedTaks] = useState<TaskInterfaceDefault[]>([])

    //Loading
    let [loadingCreateTask, setLoadingCreateTask] = useState(false)
    let [loadingList, setLoadingList] = useState<boolean>(true)
    let [loadingConclude, setLoadingConclude] = useState<boolean>(false)
    let [loadingPostPone, setLoadingPostPone] = useState<boolean>(false)
    let [loadingResponsability, setLoadingResponsability] = useState<boolean>(false)
    let [loadingOpen, setLoadingOpen] = useState<boolean>(false)

    //Dialogs
    let [tasksEditDialog, setTasksEditDialog] = useState(false)
    let [confirmConclude, setConfirmConclude] = useState(false)
    let [reOpenTask, setOpenTask] = useState(false)
    let [changeDate, setChangeDate] = useState(false)
    let [changeActionDate, setChangeActionDate] = useState(false)
    let [responsibleChange, setResponsibleChange] = useState(false)

    //Filters
    let [typeTask, setTypeTask] = useState<any[]>([])
    let [selectedTaskType, setSelectedTaskType] = useState<any[]>([])
    let [dateInit, setDateInit] = useState<any>("")
    let [dateFinal, setDateFinal] = useState<any>("")
    let [loadingCleanFilters, setLoadingCleanFilters] = useState(false)
    let [advancedSearchFilter, setAdvancedSearchFilter] = useState("")
    let [selectedPriorityFilter, setSelectedPriorityFilter] = useState<any[]>([])
    let [accountable, setAccountable] = useState<any[]>([])
    let [listOfStatusTask, setListOfStatusTask] = useState<any[]>([])
    let [selectedStatus, setSelectedStatus] = useState<any[]>([])
    let [selectedAccountable, setSelectedAccountable] = useState<any[]>([])

    //Mobile
    const [displayBasicMobile, setDisplayBasicMobile] = useState(false)

    async function validateCreateTask() {
        let schema = yup.object().shape({
            date: yup.date().required(),
            type: yup.string().required(),
            subject: yup.string().required(),
            opportunity_id: yup.string().required(),
            users: yup.array().required(),
        })

        try {
            await schema.validate(saveTaskData)
            return true
        } catch (error) {
            return false
        }
    }

    const allTasks = async () => {
        try {
            let header = {
                headers: {
                    route: "list_tasks",
                },
            }
            setLoadingList(true)
            const response = await api.get("tasks", header)
            setTasks(response.data)
            setAuxTasksFiltered(response.data)
        } catch (error: any) {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar!",
                detail: error.response.data.message,
                life: 3000,
            })
        } finally {
            setLoadingList(false)
        }
    }

    const allUsers = async () => {
        try {
            let header = {
                headers: {
                    route: "show_crm_configuration_sellers",
                },
            }

            const response = await api.get("crm_configurations/sellers", header)
            setUsers(response.data)
        } catch (error: any) {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar usuários!",
                detail: error.response.data.message,
                life: 3000,
            })
        }
    }

    const hideDialog = () => {
        setConfirmConclude(false)
        setOpenTask(false)
        setChangeDate(false)
        setChangeActionDate(false)
        setResponsibleChange(false)
        setPostPoneActionValue(null)
        setResponsibles(null)
        setTasksEditDialog(false)
        setSavePostPoneData({} as TaskInterface)
        setSaveAnnotation("")
    }

    const dateFormatUpdate = (rowData: TaskInterfaceDefault) => {
        return (
            <>
                <Moment format="DD/MM/YYYY HH:mm">{rowData.date}</Moment>
            </>
        )
    }

    //Dialogs

    const confirmDialogFooter = () => {
        return (
            <>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text p-button-danger" onClick={hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text p-button-success" loading={loadingConclude} onClick={onConfirmTask} />
            </>
        )
    }

    const openDialogFooter = () => {
        return (
            <>
                <Button label="Não" icon="pi pi-times" className="p-button-text p-button-danger" onClick={hideDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text p-button-success" loading={loadingOpen} onClick={onOpenTask} />
            </>
        )
    }

    const postPoneMensage = () => {
        return (
            <>
                <h4>
                    <strong>Confirmação de Adiamento:</strong>
                </h4>
                <br />
                <h5>
                    <strong>Deseja Adidar: {selectedTaks.length} Tarefas </strong>
                </h5>
            </>
        )
    }

    const onClickMobileDialog = () => {
        dialogFuncMapMobile[`${"displayBasic"}`](true)
    }

    const dialogFuncMapMobile = {
        displayBasic: setDisplayBasicMobile,
    }

    const openMensage = () => {
        return (
            <>
                <h5>
                    <strong>Tem certeza que deseja reabrir está tarefa? </strong>
                </h5>
            </>
        )
    }

    //OnChange
    const changeDataAction = () => {
        return (
            <>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text p-button-danger" onClick={hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text p-button-success" loading={loadingPostPone} onClick={onPostPoneActionTask} />
            </>
        )
    }

    const changeUserResponsability = () => {
        return (
            <>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text p-button-danger" onClick={hideDialog} />
                <Button
                    label="Salvar"
                    icon="pi pi-check"
                    className="p-button-text p-button-success"
                    loading={loadingResponsability}
                    onClick={onResponsibleTransfer}
                />
            </>
        )
    }

    //Requisitions

    const confirmTask = (item: TaskInterface) => {
        setTask(item)
        setConfirmConclude(true)
    }

    const onConfirmTask = async () => {
        if (saveAnnotation !== null) {
            setLoadingConclude(true)

            const auxConfirmTask = {
                id: task.id,
                annotation: saveAnnotation,
            }

            const header = {
                headers: {
                    route: "complete_task",
                },
            }

            try {
                const response = await api.put("tasks/complete", auxConfirmTask, header)
                const { status, data } = response

                if (status === 200) {
                    setLoadingConclude(false)
                    setTasks((prevState) => {
                        const index = findIndexById(response.data.id)
                        prevState[index].status = response.data.status
                        return prevState
                    })

                    hideDialog()

                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso na Conclusão",
                        detail: "Tarefa concluída com sucesso!",
                        life: 3000,
                    })
                } else {
                    setLoadingConclude(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao concluir!",
                        detail: data.message,
                        life: 3000,
                    })
                }
            } catch (error: any) {
                setLoadingConclude(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao concluir!",
                    detail: error.response.data.message,
                    life: 3000,
                })
            }
        } else {
            setLoadingConclude(false)
            toast.current?.show({
                severity: "warn",
                summary: "OPS!",
                detail: "Você deve adicionar uma anotação para concluir esta tarefa.",
                life: 8000,
            })
        }
    }

    const openTask = (item: TaskInterface) => {
        setTask(item)
        setOpenTask(true)
    }

    const onOpenTask = async () => {
        const auxOpenTask = {
            id: task.id,
        }

        setLoadingOpen(true)

        const header = {
            headers: {
                route: "reopen_task",
            },
        }

        try {
            const response = await api.put("tasks/reopen", auxOpenTask, header)
            const { status, data } = response

            if (status === 200) {
                setLoadingOpen(false)
                setTasks((prevState) => {
                    const index = findIndexById(response.data.id)
                    prevState[index].status = response.data.status
                    return prevState
                })

                hideDialog()

                toast.current?.show({
                    severity: "success",
                    summary: "Sucesso na Reabertura",
                    detail: "Tarefa reaberta com sucesso!",
                    life: 3000,
                })
            } else {
                setLoadingOpen(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao reabrir a tarefa!",
                    detail: data.message,
                    life: 3000,
                })
            }
        } catch (error: any) {
            setLoadingOpen(false)
            toast.current?.show({
                severity: "error",
                summary: "Erro ao reabrir tarefa!",
                detail: error.response.data.message,
                life: 3000,
            })
        }
    }

    const postPoneTask = (item: TaskInterface) => {
        setTask(item)
        setChangeDate(true)
    }

    const onPostPoneTask = async () => {
        if (!isEmptyObj(savePostPoneData)) {
            let newdDate = moment(task.date).add(savePostPoneData.hour, "h").toDate()
            let auxConfirmTask = {
                id: task.id,
                date: newdDate,
            }
            setLoadingPostPone(true)

            let header = {
                headers: {
                    route: "postpone_task",
                },
            }

            try {
                await api
                    .put("tasks/postpone", auxConfirmTask, header)
                    .then((res) => {
                        if (res.status === 200) {
                            setLoadingPostPone(false)
                            const index = findIndexById(res.data.id)

                            let _tasks = [...tasks]

                            _tasks[index] = res.data
                            setTasks(_tasks)

                            hideDialog()
                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso na Conclusão",
                                detail: "Tarefa adiada com sucesso!",
                                life: 3000,
                            })
                        } else {
                            setLoadingPostPone(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao concluir!",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingPostPone(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao concluir!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        } else {
            toast.current?.show({
                severity: "warn",
                summary: "Atenção!",
                detail: "Selecione um período para continuar",
                life: 3000,
            })
        }
    }

    const responsibleTransfer = (item: TaskInterface) => {
        setTask(item)
    }

    const onResponsibleTransfer = () => {
        if (!isEmptyObj(responsible)) {
            setLoadingResponsability(true)
            let auxTransferResponsibleID: any = []
            let auxTransferUserID: any = []

            selectedTaks.forEach((item) => {
                auxTransferUserID.push(item.id)
            })

            responsible.forEach((item: any) => {
                auxTransferResponsibleID.push(item.id)
            })

            let auxTask = {
                tasks_id: auxTransferUserID,
                users_id: auxTransferResponsibleID,
            }

            let header = {
                headers: {
                    route: "update_users_tasks",
                },
            }

            try {
                api.put("tasks/users", auxTask, header)
                    .then((res) => {
                        if (res.status === 200) {
                            setLoadingResponsability(false)
                            setTasks((prevState) => {
                                selectedTaks.forEach((item) => {
                                    res.data.forEach((task: TaskInterface) => {
                                        const index = findIndexById(item.id)
                                        if (task.id === item.id) {
                                            prevState[index].users = task.users
                                        }
                                    })
                                })
                                return prevState
                            })

                            hideDialog()
                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso na Transferência de Responsável",
                                detail: "Tarefa transferida com sucesso!",
                                life: 3000,
                            })
                        } else {
                            setLoadingResponsability(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao Transferir!",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingResponsability(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Transferir!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        } else {
            toast.current?.show({
                severity: "warn",
                summary: "Atenção!",
                detail: "Selecione um responsavél para continuar",
                life: 3000,
            })
        }
    }

    const postPoneActionTask = (item: TaskInterface) => {
        setTask(item)
        setChangeActionDate(true)
    }

    const onPostPoneActionTask = () => {
        if (!isEmptyObj(postPoneActionValue)) {
            setLoadingPostPone(true)

            const auxPostPoneAction = selectedTaks.map((item) => ({
                id: item.id,
                date: moment(item.date).add(postPoneActionValue.hour, "h").toDate(),
            }))

            const header = {
                headers: {
                    route: "postpone_tasks",
                },
            }

            try {
                api.put("tasks/postponements", { tasks: auxPostPoneAction }, header)
                    .then((res) => {
                        if (res.status === 200) {
                            setLoadingPostPone(false)

                            setTasks((prevState) => {
                                selectedTaks.forEach((item) => {
                                    res.data.forEach((task: TaskInterface) => {
                                        const index = findIndexById(item.id)
                                        if (task.id === item.id) {
                                            prevState[index].date = task.date
                                        }
                                    })
                                })
                                return prevState
                            })

                            hideDialog()
                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso na Conclusão",
                                detail: "Tarefa adiada com sucesso!",
                                life: 3000,
                            })
                        } else {
                            setLoadingPostPone(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao concluir!",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingPostPone(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao concluir!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        } else {
            toast.current?.show({
                severity: "warn",
                summary: "Atenção!",
                detail: "Selecione um período para continuar",
                life: 3000,
            })
        }
    }

    const updateTasks = (item: any) => {
        setTasks([...tasks, item])
    }

    const handleEditTask = async (event: any) => {
        event.preventDefault()
        let idUsers: any = []

        if (!isEmptyObj(saveTaskData.users)) {
            saveTaskData.users.forEach((user: UserInterface) => {
                idUsers.push(user.id)
            })
        }

        if (!(await validateCreateTask())) {
            toast.current?.show({
                severity: "error",
                summary: "Dados Invalidos!",
                detail: "Preencha todos os dados obrigatorios!",
                life: 4000,
            })
        } else {
            try {
                setLoadingCreateTask(true)
                let auxTask = {
                    id: saveTaskData.id,
                    type: saveTaskData.type,
                    subject: saveTaskData.subject,
                    date: saveTaskData.date,
                    users_id: idUsers,
                }
                let header = {
                    headers: {
                        route: "update_task",
                    },
                }
                api.put("tasks", auxTask, header)
                    .then((res) => {
                        if (res.status === 200) {
                            setTasks((prevState) => {
                                const index = findIndexById(res.data.id)
                                prevState[index].type = res.data.type
                                prevState[index].subject = res.data.subject
                                prevState[index].date = res.data.date
                                prevState[index].users = res.data.users
                                return prevState
                            })

                            setAuxTasksFiltered((prevState) => {
                                const updatedTasks = [...prevState]
                                selectedTaks.forEach((item) => {
                                    const index = findIndexById(item.id)
                                    updatedTasks[index].type = res.data.type
                                    updatedTasks[index].subject = res.data.subject
                                    updatedTasks[index].date = res.data.date
                                    updatedTasks[index].users = res.data.users
                                })
                                return updatedTasks
                            })

                            setLoadingCreateTask(false)

                            taskFilters()
                            hideDialog()

                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso",
                                detail: "Tarefa editada com Sucesso",
                                life: 3000,
                            })
                        } else {
                            setLoadingCreateTask(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao editar a tarefa!",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingCreateTask(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao cadastar a tarefa!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        }
    }

    const setEditTask = (task: TaskInterface) => {
        setTask(task)
        setTasksEditDialog(true)
    }

    const findIndexById = (id: string) => {
        let index = -1
        for (let i = 0; i < auxTasksFiltered.length; i++) {
            if (tasks[i].id === id) {
                index = i
                break
            }
        }

        return index
    }

    const changeResponsabilityMensage = () => {
        return (
            <>
                <h4>
                    <strong>Confirmação de Adiamento:</strong>
                </h4>
                <br />
                <h5>
                    <strong>Deseja transferir: {selectedTaks.length} Tarefas</strong>
                </h5>
            </>
        )
    }

    const activeInactive = (rowData: TaskInterfaceDefault) => {
        let statusClass = ""
        let name = ""
        switch (rowData.status) {
            case "open":
                statusClass = "instock"
                name = "Aberta"
                break

            case "finished":
                statusClass = "outofstock"
                name = "Fechada"
                break
        }

        return <span className={`product-badge status-${statusClass}`}>{name}</span>
    }

    const filterNameAuthorization = (rowData: TaskInterfaceDefault): string => {
        const typeTask = rowData.type
        const matchedItem = taskType.find((item) => item.code === typeTask)

        return matchedItem ? matchedItem.name.toUpperCase() : ""
    }

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    const concatenateTask = (rowData: TaskInterfaceDefault): JSX.Element[] => {
        const vazio = "-"
        if (rowData.users !== null) {
            return rowData.users.map((nameResponsible) => <Chip style={{ margin: "5px" }} label={nameResponsible.name} key={nameResponsible.id} />)
        } else {
            return [<Chip style={{ margin: "5px" }} label={vazio} key={0} />]
        }
    }

    const handleRating = (event: any, rowData: TaskInterfaceDefault) => {
        let id = rowData.opportunity.id
        let qualification = event.value

        try {
            let header = {
                headers: {
                    route: "update_opportunity_qualification",
                },
            }

            api.put("opportunities/qualification", { id, qualification }, header)
                .then((res) => {
                    if (res.status === 200) {
                        setTasks((prevState) => {
                            const index = findIndexById(rowData.id)
                            prevState[index].opportunity.qualification = event.value
                            rating(prevState[index])

                            return prevState
                        })

                        setAuxTasksFiltered((prevState) => {
                            const updatedTasks = [...prevState]
                            selectedTaks.forEach((item) => {
                                const index = findIndexById(item.id)
                                updatedTasks[index].opportunity.qualification = event.value
                                rating(updatedTasks[index])
                            })

                            return updatedTasks
                        })
                    } else {
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro na alteração da qualificação!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro na alteração da qualificação!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const rating = (rowData: TaskInterfaceDefault) => {
        return <Rating onChange={(e: any) => handleRating(e, rowData)} value={rowData.opportunity.qualification} size={20} />
    }

    const buttonsFromDataTable = (rowData: TaskInterface) => {
        return (
            <div className="actions">
                <Button
                    data-tip
                    data-for="editTask"
                    icon="pi pi-user-edit"
                    className="p-button-rounded p-button"
                    onClick={() => setEditTask(rowData)}
                    disabled={rowData.opportunity.status !== "open" || rowData.status === "stopped"}
                />
                &nbsp;&nbsp;
                
                <Button
                    data-tip
                    data-for="op"
                    icon="pi pi-search"
                    className="p-button-rounded p-button"
                    onClick={() => window.open(`/crm/leads/${rowData.opportunity_id}`)}
                />
                
                <ReactTooltip className="toolTip" id="op" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Ir para OP</p>
                </ReactTooltip>
                &nbsp;&nbsp;
                {rowData.status === "open" && (
                    <Button
                        data-tip
                        data-for="concludeTask"
                        icon="pi pi-check"
                        className="p-button-rounded p-button-success"
                        onClick={() => confirmTask(rowData)}
                    />
                )}
                <ReactTooltip className="toolTip" id="concludeTask" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Concluir tarefa</p>
                </ReactTooltip>
                {rowData.status === "finished" && (
                    <Button data-tip data-for="reopentask" icon="pi pi-replay" className="p-button-rounded p-button-danger" onClick={() => openTask(rowData)} />
                )}
                <ReactTooltip className="toolTip" id="reopentask" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Re-abrir tarefa</p>
                </ReactTooltip>
                &nbsp;&nbsp;
                <Button data-tip data-for="menuGroups" icon="pi pi-clock" className="p-button-rounded p-button-warning" onClick={() => postPoneTask(rowData)} />
                <ReactTooltip className="toolTip" id="menuGroups" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Adiar tarefa</p>
                </ReactTooltip>
            </div>
        )
    }

    const taskFilters = () => {
        if (
            dateInit !== "" ||
            dateFinal !== "" ||
            selectedTaskType.length > 0 ||
            selectedAccountable.length > 0 ||
            selectedPriorityFilter.length > 0 ||
            selectedStatus.length > 0 ||
            !(advancedSearchFilter === "")
        ) {
            var listCompaniesFiltered: TaskInterfaceDefault[] = []
            for (let atualLine of auxTasksFiltered) {
                var addLine = true

                let newAtualLineGetTime = new Date(
                    new Date(atualLine.date).getFullYear(),
                    new Date(atualLine.date).getMonth(),
                    new Date(atualLine.date).getDate()
                )
                if (dateInit !== "" && addLine) {
                    let newDateInitGetTime = new Date(moment(dateInit).format())
                    if (newAtualLineGetTime.getTime() >= newDateInitGetTime.getTime()) {
                    } else {
                        addLine = false
                    }
                }
                if (dateFinal !== "" && addLine) {
                    let newDateFinalGetTime = new Date(moment(dateFinal).format())
                    if (newAtualLineGetTime.getTime() <= newDateFinalGetTime.getTime()) {
                    } else {
                        addLine = false
                    }
                }

                if (selectedAccountable.length > 0 && addLine) {
                    let validateSearch
                    selectedAccountable.findIndex((filter) => {
                        if (atualLine.users.length > 0) {
                            validateSearch = atualLine.users.findIndex((user) => filter.name === user.name)
                        }
                    })

                    if (validateSearch === -1) {
                        addLine = false
                    }
                }

                if (selectedTaskType.length > 0 && addLine) {
                    const validateSearch = selectedTaskType.findIndex((filter) => filter.code === atualLine.type)
                    if (validateSearch === -1) {
                        addLine = false
                    }
                }

                if (selectedStatus.length > 0 && addLine) {
                    const validateSearch = selectedStatus.findIndex((filter) => filter.code === atualLine.status)
                    if (validateSearch === -1) {
                        addLine = false
                    }
                }

                if (selectedPriorityFilter.length > 0 && addLine) {
                    const validateSearch = selectedPriorityFilter.findIndex((filter) => filter.code === atualLine.opportunity.qualification)
                    if (validateSearch === -1) {
                        addLine = false
                    }
                }

                if (!(advancedSearchFilter === "") && addLine) {
                    addLine = false
                    var word = advancedSearchFilter.toLowerCase().split(" ")
                    if (advancedSearchFilter === "") {
                        setAuxTasksFiltered(tasks)
                    }
                    if (!atualLine.subject) atualLine.subject = ""
                    if (!atualLine.opportunity.name) atualLine.opportunity.name = ""
                    if (!atualLine.opportunity.status) atualLine.opportunity.status = ""
                    if (!atualLine.id) atualLine.id = ""
                    if (!atualLine.type) atualLine.type = ""
                    if (!atualLine.responsible) atualLine.responsible = ""
                    if (!atualLine.opportunity.company.name) atualLine.opportunity.company.name = ""
                    if (!atualLine.opportunity.company.fantasy_name) atualLine.opportunity.company.fantasy_name = ""
                    if (!atualLine.opportunity.company.address) atualLine.opportunity.company.address = ""
                    if (!atualLine.opportunity.company.cnpj) atualLine.opportunity.company.cnpj = ""
                    if (!atualLine.opportunity.company.city) atualLine.opportunity.company.city = ""

                    for (var oneWord in word) {
                        oneWord = oneWord.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                        if (
                            !atualLine.subject.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.opportunity.name.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.opportunity.status.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.id.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.responsible.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.opportunity.company.name.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.opportunity.company.fantasy_name.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.opportunity.company.address.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.opportunity.company.cnpj.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.opportunity.company.city.toLowerCase().includes(advancedSearchFilter.toLowerCase()) &&
                            !atualLine.type.toLowerCase().includes(advancedSearchFilter.toLowerCase())
                        ) {
                            addLine = false
                            break
                        } else {
                            addLine = true
                        }
                    }
                }

                if (addLine) {
                    listCompaniesFiltered.push(atualLine)
                }
            }

            setTasks(listCompaniesFiltered)
        } else {
            setTasks(auxTasksFiltered)
        }
    }

    const replaceType = (type: string) => {
        switch (type) {
            case "LG":
                return "LIGAÇÃO"
            case "EM":
                return "E-MAIL"
            case "RE":
                return "REUNIÃO"
            case "VI":
                return "VISITA"
            case "TA":
                return "TAREFA"
            case "AL":
                return "ALMOÇO"
            case "WP":
                return "WHATSAPP"
            default:
                return type
        }
    }

    const replaceStatus = (status: string) => {
        switch (status) {
            case "open":
                return "ABERTA"
            case "finished":
                return "FECHADA"
            default:
                return status
        }
    }

    const onLoadingCleanFilters = () => {
        dateInit = ""
        setDateInit(dateInit)
        dateFinal = ""
        setDateFinal(dateFinal)
        selectedTaskType = []
        setSelectedTaskType(selectedTaskType)
        typeTask = []
        setTypeTask(typeTask)
        advancedSearchFilter = ""
        setAdvancedSearchFilter(advancedSearchFilter)
        selectedAccountable = []
        setSelectedAccountable(selectedAccountable)
        listOfStatusTask = []
        setListOfStatusTask(listOfStatusTask)
        selectedStatus = []
        setSelectedStatus(selectedStatus)
        selectedPriorityFilter = []
        setSelectedPriorityFilter(selectedPriorityFilter)
        loadingCleanFilters = true
        setLoadingCleanFilters(loadingCleanFilters)
        taskFilters()

        setTimeout(() => {
            setLoadingCleanFilters(false)
        }, 1000)
    }

    const groupedItemTemplate = (option: any) => {
        return (
            <div className="p-d-flex p-ai-center country-item">
                <Rating value={option.code} size={20} />
            </div>
        )
    }

    const handleSetUpdateTaskData = (item: TaskInterface) => {
        setSaveTaskData(item)
    }

    const handleSetSaveTaskData = (name: any, value: any) => {
        setSaveTaskData({ ...saveTaskData, [name]: value })
    }

    const handleSetPostPoneTaskData = (item: any) => {
        setSavePostPoneData(item)
    }

    const handleSetConcludeTaskData = (item: string) => {
        setSaveAnnotation(item)
    }

    const onHideDialogMobile = (name: any) => {
        dialogFuncMapMobile[`${"displayBasic"}`](false)
    }

    //Footers
    const renderFooter = (name: any) => {
        return (
            <div>
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    onClick={() => {
                        onHideDialogMobile(name)
                        onLoadingCleanFilters()
                    }}
                    className="p-button-danger p-button-text"
                />
                <Button label="Aplicar" icon="pi pi-check" onClick={() => onHideDialogMobile(name)} autoFocus className="p-button-success p-button-text" />
            </div>
        )
    }

    const changeDateFooter = () => {
        return (
            <>
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text p-button-danger" onClick={hideDialog} />
                <Button label="Salvar" icon="pi pi-check" className="p-button-text p-button-success" loading={loadingPostPone} onClick={onPostPoneTask} />
            </>
        )
    }

    //Arrays
    const postpone = [
        { name: "1 Hora", hour: 1 },
        { name: "3 Horas", hour: 3 },
        { name: "Amanhã", hour: 24 },
        { name: "Dois Dias", hour: 48 },
        { name: "Próxima Semana", hour: 168 },
        { name: "Próximo Mês", hour: 720 },
    ]

    const selectionPriorities = [
        {
            name: "ALTA",
            code: 5,
        },
        {
            name: "MÉDIA ALTA",
            code: 4,
        },
        {
            name: "MÉDIA",
            code: 3,
        },
        {
            name: "MÉDIA BAIXA",
            code: 2,
        },
        {
            name: "BAIXA",
            code: 1,
        },
    ]

    const header = (
        <div>
            {/* Desktop */}
            <div className="desktop-opportunity-filter-menu">
                <div className="p-d-flex p-jc-between">
                    <h5 className="">Tarefas </h5>
                    <div className="p-d-flex p-jc-end">
                        <span className="p-input-icon-left p-mr-2">
                            <i className="pi pi-search" />
                            <InputText
                                className="searchFilter"
                                placeholder="PESQUISAR..."
                                value={advancedSearchFilter}
                                onKeyUp={() => (advancedSearchFilter === "" ? taskFilters() : "" || advancedSearchFilter !== "" ? taskFilters() : "")}
                                onChange={(e) => {
                                    setAdvancedSearchFilter(e.target.value)
                                    taskFilters()
                                }}
                            />
                        </span>
                    </div>
                </div>
            </div>
            {/* Mobile */}
            <div className="mobile-opportunity-filter-menu">
                <div className="p-d-flex">
                    <div className="table-header">
                        <h5 className="p-mb-2">Tarefas </h5>
                        <span className="p-input-icon-left p-mr-2">
                            <i className="pi pi-search" />
                            <InputText
                                className="searchFilter"
                                placeholder="PESQUISAR..."
                                value={advancedSearchFilter}
                                onKeyUp={() => (advancedSearchFilter === "" ? taskFilters() : "" || advancedSearchFilter !== "" ? taskFilters() : "")}
                                onChange={(e) => {
                                    setAdvancedSearchFilter(e.target.value)
                                    taskFilters()
                                }}
                            />
                        </span>
                        <i className="iconForMobileFilter pi pi-filter" style={{ fontSize: "1.5em" }} onClick={() => onClickMobileDialog()}></i>
                    </div>
                </div>
            </div>
        </div>
    )

    useEffect(() => {
        const typeTaskList: any[] = []
        const accountableList: any[] = []
        const statusTask: any[] = []

        auxTasksFiltered.forEach((item, index) => {
            const findtypeTask = typeTaskList.find((user) => user.code === item.type)

            if (!findtypeTask) {
                typeTaskList.push({ name: replaceType(item.type), code: item.type })
            }

            const findStatus = statusTask.find((user) => user.code === item.status)

            if (!findStatus) {
                statusTask.push({ name: replaceStatus(item.status), code: item.status })
            }

            item.users.forEach((auxUsers) => {
                const findAccountable = accountableList.find((user) => user.name === auxUsers.name)
                if (!findAccountable) {
                    accountableList.push({
                        name: auxUsers.name,
                        code: auxUsers.id,
                    })
                }
            })
        })

        setListOfStatusTask((prevStatusTask) => [...prevStatusTask, ...statusTask])
        setAccountable((prevAccountable) => [...prevAccountable, ...accountableList])
        setTypeTask((prevTypeTask) => [...prevTypeTask, ...typeTaskList])
    }, [auxTasksFiltered])

    useEffect(() => {
        allTasks()
        allUsers()
    }, [])

    return (
        <App>
            <Toast ref={toast} />
            <CrmRecords updateTask={updateTasks} />

            <div className="p-grid crud-demo ">
                <div className="p-col-12 p-md-12 p-lg-12" style={{ marginBottom: "-1rem" }}>
                    <div className="desktop-opportunity-filter-menu">
                        <div className="card" style={{ height: "auto" }}>
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="p-d-flex p-jc-between">
                                    <div>
                                        <Calendar
                                            className="calendarTask p-mr-2 p-mb-1"
                                            dateFormat="dd/mm/yy"
                                            id="icon"
                                            value={dateInit}
                                            onMonthChange={() => ""}
                                            onChange={(e) => {
                                                dateInit = e.value
                                                setDateInit(e.value)
                                                taskFilters()
                                            }}
                                            showIcon
                                            placeholder={"INÍCIO"}
                                            locale="pt"
                                        />
                                        <Calendar
                                            className="calendarTask p-mr-2 p-mb-1"
                                            dateFormat="dd/mm/yy"
                                            id="icon"
                                            value={dateFinal}
                                            onMonthChange={() => ""}
                                            onChange={(e) => {
                                                dateFinal = e.value
                                                setDateFinal(e.value)
                                                taskFilters()
                                            }}
                                            showIcon
                                            placeholder={"FIM"}
                                            locale="pt"
                                        />
                                        <MultiSelect
                                            className="multiselectTask p-mr-2 p-mb-1"
                                            value={selectedAccountable}
                                            disabled={!isEmptyObj(accountable) ? false : true}
                                            options={accountable}
                                            onChange={(e) => {
                                                selectedAccountable = e.value
                                                setSelectedAccountable(e.value)
                                                taskFilters()
                                            }}
                                            emptyFilterMessage={"Não há dados"}
                                            filter={true}
                                            optionLabel="name"
                                            placeholder={!isEmptyObj(accountable) ? "RESPONSÁVEL" : "NÃO HÁ RESPONSÁVEL"}
                                        />
                                        <MultiSelect
                                            className="p-mr-2"
                                            value={selectedPriorityFilter}
                                            style={{}}
                                            options={selectionPriorities}
                                            onChange={(e) => {
                                                selectedPriorityFilter = e.value
                                                setSelectedPriorityFilter(e.value)
                                                taskFilters()
                                            }}
                                            emptyFilterMessage={"Não há dados"}
                                            itemTemplate={groupedItemTemplate}
                                            optionLabel="name"
                                            placeholder="TEMPERATURA"
                                        />
                                        <MultiSelect
                                            className="multiselectTask p-mr-2 p-mb-1"
                                            value={selectedTaskType}
                                            disabled={!isEmptyObj(typeTask) ? false : true}
                                            options={typeTask}
                                            onChange={(e) => {
                                                selectedTaskType = e.value
                                                setSelectedTaskType(e.value)
                                                taskFilters()
                                            }}
                                            emptyFilterMessage={"Não há dados"}
                                            filter={true}
                                            optionLabel="name"
                                            placeholder={!isEmptyObj(accountable) ? "TIPO DE TAREFA" : "NÃO HÁ TAREFA"}
                                        />
                                        <MultiSelect
                                            className="p-mr-2"
                                            value={selectedStatus}
                                            disabled={!isEmptyObj(listOfStatusTask) ? false : true}
                                            options={listOfStatusTask}
                                            onChange={(e) => {
                                                selectedStatus = e.value
                                                setSelectedStatus(selectedStatus)
                                                taskFilters()
                                            }}
                                            emptyFilterMessage={"Não há dados"}
                                            filter={true}
                                            optionLabel="name"
                                            placeholder={!isEmptyObj(listOfStatusTask) ? "STATUS" : "NÃO HÁ TAREFA"}
                                        />
                                    </div>

                                    <div className="">
                                        <Button
                                            className="p-button-rounded p-jc-end"
                                            label="LIMPAR FILTROS"
                                            icon="pi pi-times"
                                            style={{ width: "13rem" }}
                                            loading={loadingCleanFilters}
                                            onClick={onLoadingCleanFilters}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-12 p-lg-12">
                    {!isEmptyObj(selectedTaks) && (
                        <div className="card">
                            <React.Fragment>
                                <div className="p-d-flex p-flex-column p-flex-md-row">
                                    <div className="p-mb-2 p-mr-6">
                                        <MultiSelect
                                            style={{ minWidth: "20rem" }}
                                            id="responsible"
                                            name="responsible"
                                            filter={true}
                                            value={!isEmptyObj(responsible) ? responsible : ""}
                                            options={users}
                                            onChange={(e: any) => {
                                                setResponsibles(e.value)
                                                responsibleTransfer(e.value)
                                            }}
                                            emptyFilterMessage={"Não há dados"}
                                            optionLabel="name"
                                            placeholder="Transferir para:"
                                            selectedItemsLabel="{0} itens selecionados"
                                        />
                                        &nbsp;
                                        {!isEmptyObj(responsible) && (
                                            <Button icon="pi pi-check" onClick={() => setResponsibleChange(true)} className="p-button-rounded" />
                                        )}
                                    </div>
                                    &nbsp;&nbsp;
                                    <div className="p-mb-2 p-mr-6">
                                        <Dropdown
                                            style={{ minWidth: "20rem" }}
                                            id="postPone"
                                            name="postPone"
                                            emptyMessage="Não há dados"
                                            emptyFilterMessage="Não há dados"
                                            filter={true}
                                            options={postpone}
                                            value={!isEmptyObj(postPoneActionValue) ? postPoneActionValue : ""}
                                            onChange={(e: any) => {
                                                setPostPoneActionValue(e.value)
                                                postPoneActionTask(e.value)
                                            }}
                                            optionLabel="name"
                                            placeholder="Adiar tarefa em:"
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    )}

                    <div className="p-grid crud-demo">
                        <div className="p-col-12">
                            <div className="card">
                                <DataTable
                                    style={{ paddingBottom: "70px" }}
                                    value={tasks}
                                    loading={loadingList}
                                    stripedRows
                                    header={header}
                                    selection={selectedTaks}
                                    onSelectionChange={(e) => setSelectedTaks(e.value)}
                                    dataKey="id"
                                    selectionMode="radiobutton"
                                    rows={10}
                                    rowsPerPageOptions={[10, 15, 20]}
                                    // className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} Tarefas"
                                    paginator
                                    emptyMessage="Não há Tarefas"
                                    responsiveLayout="scroll"
                                >
                                    <Column
                                        selectionMode="multiple"
                                        headerStyle={{
                                            width: "3em",
                                        }}
                                        field="id"
                                    ></Column>

                                    <Column sortable align="center" alignHeader="center" field="subject" header="Tarefa" />
                                    <Column sortable align="center" alignHeader="center" field="opportunity.number" header="Número" />
                                    <Column sortable align="center" alignHeader="center" field="opportunity.name" header="Oportunidade" />
                                    <Column sortable align="center" field="type" alignHeader="center" body={filterNameAuthorization} header="Tipo" />
                                    <Column
                                        align="center"
                                        alignHeader="center"
                                        body={rating}
                                        sortable
                                        sortField="opportunity.qualification"
                                        header="Avaliação"
                                    />
                                    <Column
                                        sortable
                                        body={concatenateTask}
                                        sortField="users"
                                        align="center"
                                        alignHeader="center"
                                        style={{ width: "12rem" }}
                                        header="Responsável"
                                    />
                                    <Column
                                        sortable
                                        sortField="date"
                                        align="center"
                                        alignHeader="center"
                                        body={dateFormatUpdate}
                                        header="Data de Finalização"
                                    />
                                    <Column sortable sortField="status" align="center" alignHeader="center" body={activeInactive} header="Status" />

                                    <Column
                                        headerStyle={{
                                            textAlign: "center",
                                        }}
                                        bodyStyle={{ textAlign: "center" }}
                                        body={buttonsFromDataTable}
                                    ></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmDialog
                visible={changeActionDate}
                style={{ width: "30rem" }}
                footer={changeDataAction}
                onHide={hideDialog}
                message={postPoneMensage}
            ></ConfirmDialog>

            <ConfirmDialog
                visible={responsibleChange}
                style={{ width: "30rem" }}
                footer={changeUserResponsability}
                onHide={hideDialog}
                message={changeResponsabilityMensage}
            ></ConfirmDialog>

            <ConfirmDialog visible={reOpenTask} style={{ width: "30rem" }} footer={openDialogFooter} onHide={hideDialog} message={openMensage}></ConfirmDialog>

            <Dialog
                visible={changeDate}
                breakpoints={{ "960px": "75vw", "640px": "100vw" }}
                modal
                footer={changeDateFooter}
                className="p-fluid"
                onHide={hideDialog}
                header="Adiar tarefa"
                draggable={false}
            >
                <PostPoneTasks postPone={savePostPoneData} setSavePostPoneData={handleSetPostPoneTaskData} />
            </Dialog>

            <Dialog
                visible={confirmConclude}
                style={{ width: "30rem" }}
                modal
                footer={confirmDialogFooter}
                header="Concluir Tarefa"
                className="p-fluid"
                onHide={hideDialog}
                draggable={false}
            >
                <CompleteTask annotation={saveAnnotation} setSaveAnnotationData={handleSetConcludeTaskData} />
            </Dialog>

            <Dialog
                visible={tasksEditDialog}
                breakpoints={{ "960px": "75vw", "640px": "100vw'1" }}
                style={{ minWidth: "35vw" }}
                modal
                className="p-fluid"
                onHide={hideDialog}
                header="Editar Tarefa"
                draggable={false}
            >
                <form onSubmit={handleEditTask}>
                    <Task
                        saveTaskData={saveTaskData}
                        type="update"
                        setSaveTaskData={handleSetSaveTaskData}
                        createTaskIdInOpportunity={saveTaskData.opportunity_id}
                        updateTaskData={task}
                        setUpdateTaskData={handleSetUpdateTaskData}
                    />
                    <div className="p-dialog-footer">
                        <Button type="button" label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                        <Button type="submit" label="Salvar" loading={loadingCreateTask} icon="pi pi-check" className="p-button-success p-button-text" />
                    </div>
                </form>
            </Dialog>

            <Dialog
                header="Filtros"
                footer={renderFooter("displayBasic")}
                visible={displayBasicMobile}
                style={{ width: "90%", height: "90%" }}
                onHide={() => onHideDialogMobile("displayBasic")}
            >
                <div className="p-col-12 p-md-12 p-lg-12">
                    <div className="p-d-flex p-flex-column p-jc-center">
                        <Calendar
                            className="p-mb-2"
                            dateFormat="dd/mm/yy"
                            id="icon"
                            value={dateInit}
                            onMonthChange={() => ""}
                            onChange={(e) => {
                                dateInit = e.value
                                setDateInit(e.value)
                                taskFilters()
                            }}
                            showIcon
                            placeholder={"INÍCIO"}
                        />
                        <Calendar
                            className="p-mb-2"
                            dateFormat="dd/mm/yy"
                            id="icon"
                            value={dateFinal}
                            onMonthChange={() => ""}
                            onChange={(e) => {
                                dateFinal = e.value
                                setDateFinal(e.value)
                                taskFilters()
                            }}
                            showIcon
                            placeholder={"FIM"}
                        />
                        <MultiSelect
                            className="p-mb-2"
                            value={selectedAccountable}
                            disabled={!isEmptyObj(accountable) ? false : true}
                            options={accountable}
                            onChange={(e) => {
                                selectedAccountable = e.value
                                setSelectedAccountable(e.value)
                                taskFilters()
                            }}
                            emptyFilterMessage={"Não há dados"}
                            filter={true}
                            optionLabel="name"
                            placeholder={!isEmptyObj(accountable) ? "RESPONSÁVEL" : "NÃO HÁ RESPONSÁVEL"}
                        />

                        <MultiSelect
                            className="p-mb-2"
                            value={selectedTaskType}
                            disabled={!isEmptyObj(typeTask) ? false : true}
                            options={typeTask}
                            onChange={(e) => {
                                selectedTaskType = e.value
                                setSelectedTaskType(e.value)
                                taskFilters()
                            }}
                            emptyFilterMessage={"Não há dados"}
                            filter={true}
                            optionLabel="name"
                            placeholder={!isEmptyObj(accountable) ? "TIPO DE TAREFA" : "NÃO HÁ TAREFA"}
                        />

                        <MultiSelect
                            className="p-mb-2"
                            value={selectedPriorityFilter}
                            options={selectionPriorities}
                            disabled={!isEmptyObj(tasks.length === 0) ? false : true}
                            onChange={(e) => {
                                selectedPriorityFilter = e.value
                                setSelectedPriorityFilter(e.value)
                                taskFilters()
                            }}
                            emptyFilterMessage={"Não há dados"}
                            filter={true}
                            itemTemplate={groupedItemTemplate}
                            optionLabel="name"
                            placeholder={!isEmptyObj(tasks.length === 0) ? "PRIORIDADE" : "NÃO HÁ PRIORIDADE"}
                        />

                        <InputText
                            className="p-mb-2"
                            placeholder="ENDEREÇO"
                            value={advancedSearchFilter}
                            onChange={(e) => {
                                setAdvancedSearchFilter(e.target.value)
                                taskFilters()
                            }}
                        />

                        <Button
                            className="p-button-rounded p-jc-end"
                            label="LIMPAR FILTROS"
                            icon="pi pi-times"
                            loading={loadingCleanFilters}
                            onClick={onLoadingCleanFilters}
                        />
                    </div>
                </div>
            </Dialog>
        </App>
    )
}
