import React, { useState, useRef, useEffect } from "react"
import api from "../services/api"
import "../styles/Login.scss"

//PrimeReact
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Password } from "primereact/password"
import { Toast } from "primereact/toast"
import { useAuth } from "../hooks/AuthContext"
import { useHistory } from "react-router-dom"

interface AuthUser {
    email: string
    password: string
}

interface Forget {
    emailPassword: string
}

export const Login: React.FC = () => {
    let toast: any = useRef()
    let history = useHistory()
    let [values, setValues] = useState<AuthUser>({} as AuthUser)
    let [forget, setForget] = useState<Forget>({} as Forget)
    let [modal, setModal] = useState(false)
    let [modalPassword, setModalPassword] = useState(false)
    let [loadingLogin, setLoadingLogin] = useState<boolean>(false)

    let { signIn } = useAuth()

    const onChange = (event: any) => {
        const { value, name } = event.target

        setValues({
            ...values,
            [name]: value,
        })
    }

    const onChangeForgotPassword = (event: any) => {
        const emailPassword = event.target.value

        setForget(emailPassword)
    }

    const validateEmail = (email: any) => {
        var re = /\S+@\S+\.\S+/
        return re.test(email)
    }

    const forgetPassword = async () => {
        const email: any = forget

        setLoadingLogin(true)

        if (!validateEmail(email)) {
            setLoadingLogin(false)
            toast.current?.show({
                severity: "error",
                summary: "Erro ao Editar",
                detail: "Email invalido!",
                life: 3000,
            })
        } else {
            try {
                let header = {
                    headers: {
                        route: "forgot_password",
                    },
                }

                await api
                    .post("password/forgot", { email }, header)

                    .then((res) => {
                        setLoadingLogin(false)
                        setModalPassword(!modalPassword)
                        setModal(!modal)
                    })

                    .catch((error) => {
                        setLoadingLogin(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro, Email não enviado!!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        }
    }

    const onSubmit = async (event: any) => {
        event.preventDefault()
        setLoadingLogin(true)
        const { email, password } = values
        const url = localStorage.getItem("@esquadros:url")

        try {
            await signIn({ email, password })
            url !== null ? history.push(url) : history.push("/")
            setLoadingLogin(false)
        } catch (error) {
            setLoadingLogin(false)
            console.log(error)
        }
    }

    useEffect(() => {
        const token = localStorage.getItem("@esquadros:token")
        const url = localStorage.getItem("@esquadros:url")
        if (token !== null) {
            url !== null ? history.push(url) : history.push("/")
        } else {
            localStorage.removeItem("@esquadros:token")
            localStorage.removeItem("@esquadros:user")
            localStorage.removeItem("@esquadros:passwordProposal")
            localStorage.removeItem("@esquadros:contactCompanySelected")
        }
    }, [])

    return (
        <div className="banner">
            <video autoPlay muted loop className="image">
                <source src="https://esquadros-bi.s3.amazonaws.com/front-end-crm/video_Trim.mp4" type="video/mp4" />
            </video>

            <div className="animationBackground login-body" id="animationBackground">
                <Toast ref={toast} />

                <div className="leftContent">
                    <div className="animatedLeft" style={{}}>
                        <img className="logoEsq" src="/assets/layout/images/logoWt.png" alt="Logotipo Esquadros" />
                    </div>
                </div>

                <div className="login-wrapper">
                    <div>
                        <form className="login-panel" onSubmit={onSubmit}>
                            <img className="iconLogo" src="/assets/layout/images/esq-white-logo.svg" alt="Logotipo Esquadros" />
                            <br />

                            <InputText
                                className="contentLogin"
                                id="email"
                                onChange={onChange}
                                name="email"
                                placeholder="Email"
                                required
                                value={values.email || ""}
                            />

                            <Password
                                className="contentLogin"
                                id="password"
                                onChange={onChange}
                                name="password"
                                placeholder="Senha"
                                required
                                value={values.password || ""}
                                feedback={false}
                            />
                            <Button className="contentLoginButton" loading={loadingLogin} label="Acessar" type="submit"></Button>

                            <br />

                            <button
                                type="button"
                                className="p-link forget-password"
                                onClick={() => {
                                    setModalPassword(!modalPassword)
                                }}
                            >
                                <h6 style={{ color: "white" }}>Esqueceu a senha?</h6>
                            </button>
                        </form>
                    </div>
                    <div className="login-footer">
                        <h4 style={{ fontSize: "1rem" }}>Esquadros</h4>
                        <h6 style={{ fontSize: "0.8rem ", color: "white" }}>Copyright Ⓒ Inovação tecnológica</h6>
                    </div>
                </div>

                <Dialog
                    visible={modalPassword}
                    style={{ width: "450px" }}
                    header="Redefinir sua senha"
                    modal
                    draggable={false}
                    className="p-fluid"
                    onHide={() => {
                        setModalPassword(!modalPassword)
                    }}
                >
                    <div className="p-field">
                        <p>Digite o endereço de e-mail da sua conta de usuário e enviaremos um link de redefinição de senha. </p>
                        <label htmlFor="emailPassword">E-mail:</label>
                        <InputText
                            required
                            id="emailPassword"
                            name="emailPassword"
                            autoFocus
                            type="String"
                            maxLength={35}
                            onChange={onChangeForgotPassword}
                        />{" "}
                        <br />
                        <br />
                        <Button label="Enviar e-mail de redefinição de senha" className="p-text" loading={loadingLogin} onClick={forgetPassword} />
                    </div>
                </Dialog>

                <Dialog
                    header="Redefinir sua senha:"
                    visible={modal}
                    style={{ width: "450px" }}
                    draggable={false}
                    className="p-fluid"
                    onHide={() => {
                        setModal(!modal)
                    }}
                >
                    <p>Verifique seu e-mail para obter o link para redefinir sua senha. </p>
                    <p> Se ele não aparecer em poucos minutos, verifique a caixa de spam.</p>
                    <Button
                        label="Retornar para o Login"
                        className="p-text"
                        onClick={() => {
                            setModal(!modal)
                        }}
                    />
                </Dialog>
            </div>
        </div>
    )
}
