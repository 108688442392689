import React, { useRef, useState, useEffect } from "react"
import App from "../../layout/App"
import api from "../../services/api"
import classNames from "classnames"
import Moment from "react-moment"

//PrimeReact
import { Avatar } from "primereact/avatar"
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { ConfirmDialog } from "primereact/confirmdialog"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { FilterMatchMode, FilterOperator } from "primereact/api"
import { Image } from "primereact/image"
import { InputText } from "primereact/inputtext"
import { Toast } from "primereact/toast"
import { Toolbar } from "primereact/toolbar"

//Components
import { LocalUpload } from "../../components/crm/whatsapp/LocalUpload"

//Interface
import { AttachmentInterface } from "../../../src/interfaces/AttachmentInterface"

export const Avatars = () => {
    let toast = useRef<Toast>(null)
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    let [avatars, setAvatars] = useState<AttachmentInterface[]>([])
    let [avatar, setAvatar] = useState<AttachmentInterface>({} as AttachmentInterface)
    let [submitted, setSubmitted] = useState<boolean>(false)
    let [createValues, setCreateValues] = useState<AttachmentInterface>({} as AttachmentInterface)

    //Loadings
    let [loadingDelete, setLoadingDelete] = useState<boolean>(false)
    let [loadingEdit, setLoadingEdit] = useState<boolean>(false)
    let [loadingList, setLoadingList] = useState<boolean>(true)
    let [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    //Dialogs
    let [avatarDialog, setAvatarDialog] = useState<boolean | undefined>(false)
    let [avatarsEditDialog, setAvatarEditDialog] = useState(false)
    let [deleteDialog, setDeleteDialog] = useState(false)
    let [uploadFileDialog, setUploadFileDialog] = useState(false)
    let [userSignDialog, setUserSignDialog] = useState<boolean>(false)

    //Filters
    let [auxGroupsFiltered, setAuxGroupsFiltered] = useState<AttachmentInterface[]>([])

    //Filters
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        file: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    })

    let [globalFilterValue, setGlobalFilterValue] = useState("")

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value
        let _filters = { ...filters }
        _filters["global"].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    //Headers
    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Avatares Padrão</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Pesquisar..." />
            </span>
        </div>
    )

    const findIndexById = (id: string) => {
        let index = -1
        for (let i = 0; i < avatars.length; i++) {
            if (avatars[i].id === id) {
                index = i
                break
            }
        }

        return index
    }

    //Datatable field Formater
    const dateFormatCreate = (rowData: AttachmentInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.created_at}</Moment>
            </>
        )
    }

    const dateFormatUpdate = (rowData: AttachmentInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.updated_at}</Moment>
            </>
        )
    }

    const allAvatars = async () => {
        try {
            let header = {
                headers: {
                    route: "list_avatars",
                },
            }

            const response = await api.get("avatars", header)
            setLoadingList(false)
            setAvatars(response.data)
            setAuxGroupsFiltered(response.data)
        } catch (error: any) {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar!",
                detail: error.response ? error.response.data.message : error,
                life: 3000,
            })
        }
    }

    const openNew = () => {
        setAvatarDialog(true)
    }

    const deleteMessage = () => {
        return (
            <>
                {" "}
                Deseja realmente excluir o avatar <strong>{avatar.name}</strong>
            </>
        )
    }

    const onUploadFile = (files: any) => {
        const data = new FormData()
        data.append("file", files[0].file)
        hideDialog()
        setLoadingList(true)

        toast.current?.show({
            severity: "info",
            summary: "Enviando arquivo, aguarde...",
            life: 3000,
        })

        let header = {
            headers: {
                route: "update_file_avatar",
            },
        }

        api.patch(`avatars/file/${avatar.id}`, data, header)

            .then((res) => {
                if (res.status === 200) {
                    setLoadingList(false)
                    let _presentations = [...avatars]
                    const index = findIndexById(avatar.id)

                    _presentations[index] = res.data
                    setAvatars(_presentations)

                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Arquivo enviado com sucesso",
                        life: 3000,
                    })
                } else {
                    setLoadingList(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao enviar arquivo!",
                        detail: res.data.message,
                        life: 3000,
                    })
                }
            })

            .catch((error) => {
                setLoadingList(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao enviar arquivo!",
                    detail: error.response.data.message,
                    life: 3000,
                })
            })
    }

    const onChangeCreateAvatar = (event: any) => {
        const { value, name } = event.target

        setCreateValues({
            ...createValues,
            [name]: value,
        })
    }

    const onSubmitAvatars = async (event: any) => {
        setLoadingEdit(true)
        event.preventDefault()
        setSubmitted(false)

        try {
            const { name } = createValues
            if (name === "") {
                setSubmitted(true)
                setLoadingEdit(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao cadastrar",
                    detail: "Por favor, verificar os dados preenchidos!",
                    life: 3000,
                })
            } else {
                let header = {
                    headers: {
                        route: "create_avatar",
                    },
                }

                await api
                    .post(
                        "avatars",
                        {
                            name,
                        },
                        header
                    )
                    .then((res) => {
                        if (res.status === 200) {
                            hideDialog()
                            setLoadingEdit(false)
                            setAvatars([...avatars, res.data])
                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso",
                                detail: "Avatar criado com sucesso",
                                life: 3000,
                            })
                        } else {
                            setLoadingEdit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao cadastar!",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingEdit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao cadastar!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmitEditAvatars = () => {
        setLoadingSubmit(true)
        let auxCOnfirmAvatar = {
            id: avatar.id,
            name: avatar.name,
        }

        if (avatar.name !== undefined || avatar.name !== null || avatar.name !== "") {
            try {
                let header = {
                    headers: {
                        route: "update_avatar",
                    },
                }

                if (avatar.name === "" || avatar.name === undefined || avatar.name === null) {
                }
                api.put("avatars", auxCOnfirmAvatar, header)

                    .then((res) => {
                        if (res.status === 200) {
                            hideDialog()
                            setLoadingSubmit(false)
                            let _avatars = [...avatars]
                            let _avatar = { ...avatar }
                            const index = findIndexById(avatar.id)
                            _avatars[index] = _avatar
                            setAvatars(_avatars)

                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso na alteração",
                                detail: "Avatar alterado com sucesso!",
                                life: 3000,
                            })
                        } else {
                            setUploadFileDialog(false)
                            setLoadingSubmit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao Editar",
                                detail: "Por favor verifique os dados inseridos",
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setUploadFileDialog(false)
                        setLoadingSubmit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Editar",
                            detail: "Por favor verifique os dados inseridos",
                            life: 3000,
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        } else {
            setLoadingSubmit(false)
            toast.current?.show({
                severity: "error",
                summary: "Erro ao Editar",
                detail: "Por favor verifique os dados inseridos",
                life: 3000,
            })
        }
    }

    const deleteAvatars = (item: AttachmentInterface) => {
        setAvatar(item)
        setDeleteDialog(true)
    }

    const onDeleteAvatar = () => {
        setLoadingDelete(true)
        try {
            api.delete("avatars", {
                headers: {
                    route: "delete_avatar",
                },
                data: {
                    id: avatar.id,
                },
            })

                .then((res) => {
                    setLoadingDelete(false)
                    if (res.status === 200) {
                        let auxDeleteLayout = auxGroupsFiltered.filter((item) => avatar.id !== item.id)
                        avatars = auxDeleteLayout
                        auxGroupsFiltered = auxDeleteLayout
                        hideDialog()
                        setAvatars(auxDeleteLayout)
                        setAuxGroupsFiltered(auxDeleteLayout)

                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na exclusão",
                            detail: "Avatar excluído com sucesso!",
                            life: 3000,
                        })
                    } else {
                        setLoadingDelete(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao excluir!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingDelete(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao excluir!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    //Dialogs
    const dialogEditAvatars = (presentated: AttachmentInterface) => {
        setAvatar(presentated)
        setAvatarEditDialog(true)
    }

    const onChangeEdit = (event: any) => {
        const { value, name } = event.target
        setAvatar({
            ...avatar,
            [name]: value,
        })
    }

    const hideDialog = () => {
        setCreateValues({} as AttachmentInterface)
        setSubmitted(false)
        setAvatarDialog(false)
        setAvatarEditDialog(false)
        setDeleteDialog(false)
        setUploadFileDialog(false)
        setUserSignDialog(false)
    }

    const actionUserSign = (avatar: AttachmentInterface) => {
        setAvatar({ ...avatar })
        setUserSignDialog(true)
    }

    const actionUserSignWithOut = (avatar: AttachmentInterface) => {
        setAvatar({ ...avatar })
        setUserSignDialog(false)
        setUploadFileDialog(true)
    }

    const openNewSign = () => {
        setUploadFileDialog(true)
        setUserSignDialog(false)
    }

    //Table Buttons
    const buttonsDataTable = (rowData: AttachmentInterface) => {
        return (
            <div className="actions">
                <Button icon="pi pi-user-edit" className="p-button-rounded p-button" onClick={() => dialogEditAvatars(rowData)} />
                &nbsp;&nbsp;
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => deleteAvatars(rowData)} />
                &nbsp;&nbsp;
                {rowData.file === null && (
                    <Button icon="pi pi-upload" className="p-button-rounded p-button-success" onClick={() => actionUserSignWithOut(rowData)} />
                )}
                {rowData.file != null && <Button icon="pi pi-upload" className="p-button-rounded p-button-warning" onClick={() => actionUserSign(rowData)} />}
            </div>
        )
    }

    const imageAvatarDatable = (rowData: AttachmentInterface) => {
        return (
            <>
                <span className="p-column-title">Imagem</span>
                <Avatar
                    icon={rowData.file_url === null ? "pi pi-user" : ""}
                    image={rowData.file_url}
                    className="p-mr-2"
                    size="xlarge"
                    onClick={rowData.file_url === null ? () => actionUserSignWithOut(rowData) : () => actionUserSign(rowData)}
                    shape="circle"
                />
            </>
        )
    }

    const buttonCreateAvatar = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-user-plus" className="p-button" onClick={openNew} />
            </React.Fragment>
        )
    }

    const renderFooter = () => {
        return (
            <div>
                <Button label="Fechar" icon="pi pi-times" onClick={hideDialog} className="p-button-danger" />
                <Button label="Mudar Avatar" icon="pi pi-check" onClick={openNewSign} autoFocus />
            </div>
        )
    }

    //Footers
    const avatarDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" autoFocus icon="pi pi-check" className="p-button-success p-button-text" loading={loadingEdit} onClick={onSubmitAvatars} />
        </>
    )

    const avatarsEditDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingSubmit} onClick={onSubmitEditAvatars} />
        </>
    )

    const deleteDialogFooter = () => {
        return (
            <>
                <Button label="Não" icon="pi pi-times" className="p-button-text  " onClick={hideDialog} style={{ color: "red" }} />
                <Button label="Sim" icon="pi pi-check" className="p-button-text" style={{ color: "green" }} loading={loadingDelete} onClick={onDeleteAvatar} />
            </>
        )
    }

    useEffect(() => {
        allAvatars()
    }, [])

    return (
        <App>
            {/* DataTable */}
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="p-mb-4 p-toolbar" left={buttonCreateAvatar}></Toolbar>

                        <DataTable
                            value={avatars}
                            loading={loadingList}
                            stripedRows
                            selectionMode="checkbox"
                            dataKey="id"
                            rows={10}
                            filters={filters}
                            globalFilterFields={["name", "file"]}
                            rowsPerPageOptions={[10, 15, 20]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} avatares"
                            paginator
                            header={header}
                            emptyMessage="Não há Avatares Cadastrados"
                        >
                            <Column body={imageAvatarDatable} header="Avatar" align="center" alignHeader="center" />
                            <Column field="name" header="Nome" sortable align="center" alignHeader="center" />
                            <Column body={dateFormatCreate} header="Data de Criação" sortable sortField="created_at" align="center" alignHeader="center" />
                            <Column body={dateFormatUpdate} sortField="update_at" header="Data de Atualização" sortable align="center" alignHeader="center" />
                            <Column align="center" alignHeader="center" body={buttonsDataTable}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

            {/* Create Method */}
            <Dialog
                visible={avatarDialog}
                style={{ width: "450px" }}
                header="Adicionar Avatar"
                modal
                draggable={false}
                className="p-fluid"
                footer={avatarDialogFooter}
                onHide={hideDialog}
            >
                <div className="p-field">
                    <label htmlFor="name">Nome</label>
                    <InputText
                        id="name"
                        name="name"
                        required
                        type="String"
                        maxLength={45}
                        style={{ minWidth: "20rem" }}
                        className={
                            "p-field p-text-capitalize " +
                            classNames({
                                "p-invalid": submitted && createValues.name === "",
                            })
                        }
                        value={createValues.name || ""}
                        onChange={onChangeCreateAvatar}
                    />
                </div>
            </Dialog>

            {/* Edit Method */}
            <Dialog
                visible={avatarsEditDialog}
                style={{ width: "450px" }}
                header={`Editar Avatar:`}
                modal
                draggable={false}
                className="p-fluid"
                footer={avatarsEditDialogFooter}
                onHide={hideDialog}
            >
                <div className="p-field">
                    <label htmlFor="name">Nome</label>
                    <InputText
                        style={{ minWidth: "20rem" }}
                        id="name"
                        name="name"
                        maxLength={120}
                        onChange={(e: any) => onChangeEdit(e)}
                        value={avatar.name || ""}
                    />
                </div>
            </Dialog>

            {/* Delete Method */}
            <ConfirmDialog
                style={{ width: "400px", borderRadius: "16px" }}
                draggable={false}
                visible={deleteDialog}
                onHide={hideDialog}
                message={deleteMessage}
                acceptLabel={"Sim"}
                rejectLabel={"Não"}
                header="Confirmação de exclusão"
                icon="pi pi-exclamation-triangle"
                accept={onDeleteAvatar}
                acceptIcon="pi pi-check"
                rejectIcon="pi pi-times"
                reject={hideDialog}
                dismissableMask={true}
                footer={deleteDialogFooter}
            />

            {/* Upload Method */}
            <Dialog
                visible={uploadFileDialog}
                style={{ width: "750px" }}
                header={"Enviar arquivos de: " + avatar.name}
                modal
                className="p-fluid"
                draggable={false}
                onHide={hideDialog}
            >
                <div className="p-field">
                    <LocalUpload onSubmit={onUploadFile} types={".png, .jpg, .jpeg, .gif"} size={100000000} />
                </div>
            </Dialog>

            {/* Avatar visualization */}
            <Dialog
                visible={userSignDialog}
                modal
                className="p-fluid"
                draggable={false}
                onHide={hideDialog}
                header={`Assinatura -  ${avatar.name}`}
                footer={renderFooter}
            >
                <Image src={avatar.file_url} alt="Image Text" width="100%" />
            </Dialog>
        </App>
    )
}
