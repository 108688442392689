import React, { useState, useEffect, useRef } from "react"
import api from "../../services/api"
import App from "../../layout/App"
import classNames from "classnames"
import Moment from "react-moment"
import ReactTooltip from "react-tooltip"

//PrimeReact componets
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { RadioButton } from "primereact/radiobutton"
import { Toast } from "primereact/toast"
import { Toolbar } from "primereact/toolbar"

//Interface
import { DataRoutines } from "../../interfaces/DataRoutinesInterface"
import { FilterMatchMode, FilterOperator } from "primereact/api"

export const CrudRotinas: React.FC = () => {
    let [routines, setRoutines] = useState<DataRoutines[]>([])
    let [routine, setRoutine] = useState<DataRoutines>({} as DataRoutines)
    let [routinesDialog, setRoutinesDialog] = useState<boolean>(false)
    let [routinesDialogEdit, setRoutinesDialogEdit] = useState<boolean>(false)
    let [createValues, setCreateValues] = useState<DataRoutines>({} as DataRoutines)
    let [submitted, setSubmitted] = useState<boolean>(false)

    //Loading
    let [loadingList, setLoadingList] = useState<boolean>(true)
    let [loadingCreate, setLoadingCreate] = useState<boolean>(false)
    let [loadingEdit, setLoadingEdit] = useState<boolean>(false)

    //Fiter
    let [globalFilter, setGlobalFilter] = useState<string | null>(null)

    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    let toast = useRef<Toast>(null)

    //Filters
    let [globalFilterValue, setGlobalFilterValue] = useState("")
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        description: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    })

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value
        let _filters = { ...filters }
        _filters["global"].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    const allRoutines = async () => {
        try {
            let header = {
                headers: {
                    route: "list_routes",
                },
            }

            const res = await api.get("routes", header)
            setLoadingList(false)
            setRoutines(res.data)
        } catch (error: any) {
            console.log(error)
            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar!",
                detail: error.response.data.message,
                life: 3000,
            })
        }
    }

    // Post Create Routines
    const onSubmitCreateRoutines = async (event: any) => {
        event.preventDefault()
        setSubmitted(false)
        setLoadingCreate(true)

        try {
            const { name, description } = createValues

            if (name === "" || description === "") {
                setLoadingCreate(false)
                setSubmitted(true)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao cadastrar",
                    detail: "Por favor, verificar os dados preenchidos!",
                    life: 3000,
                })
            } else {
                let header = {
                    headers: {
                        route: "create_route",
                    },
                }

                await api
                    .post(
                        "routes",
                        {
                            name,
                            description,
                        },
                        header
                    )
                    .then((res) => {
                        if (res.status === 200) {
                            setLoadingCreate(false)
                            hideDialog()
                            setCreateValues({} as DataRoutines)
                            setRoutines((prevState) => [...prevState, res.data])

                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso",
                                detail: "Rotina Criada",
                                life: 3000,
                            })
                        } else {
                            setLoadingCreate(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao cadastar!",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingCreate(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao cadastar!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            }
        } catch (error) {
            console.log(error)
        }
    }

    //Update routines
    const onSubmitEditRoutine = (event: any) => {
        event.preventDefault()
        setSubmitted(false)
        setLoadingEdit(true)

        try {
            const { description, status, name, id } = routine

            if (description === "") {
                setLoadingEdit(false)
                setSubmitted(true)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao Editar",
                    detail: "Preencha todos os campos para continuar!",
                    life: 3000,
                })
            } else {
                let header = {
                    headers: {
                        route: "update_routes",
                    },
                }

                api.put(
                    "routes",
                    {
                        id,
                        name,
                        description,
                        status,
                    },
                    header
                )
                    .then((res) => {
                        if (res.status === 200) {
                            setLoadingEdit(false)

                            setRoutines((prevState) => {
                                const data = [...prevState]
                                const index = data.findIndex((item) => item.id === routine.id)
                                data[index] = res.data
                                return data
                            })

                            hideDialog()

                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso na alteração",
                                detail: "Rotina alterado com sucesso!",
                                life: 3000,
                            })
                        } else {
                            setLoadingEdit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao Editar",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingEdit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Editar",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const openNew = () => {
        setRoutinesDialog(true)
    }

    const hideDialog = () => {
        setCreateValues({} as DataRoutines)
        setSubmitted(false)
        setRoutinesDialog(false)
        setRoutinesDialogEdit(false)
        setGlobalFilter(null)
    }

    const hideDialogEditRoutines = () => {
        setRoutinesDialogEdit(false)
    }

    const routinesEditDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialogEditRoutines} />

            <Button label="Salvar" icon="pi pi-check" loading={loadingEdit} className="p-button-success p-button-text" onClick={onSubmitEditRoutine} />
        </>
    )

    const routinesCreateDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />

            <Button label="Salvar" icon="pi pi-check" loading={loadingCreate} className="p-button-success p-button-text" onClick={onSubmitCreateRoutines} />
        </>
    )

    const actionBodyTemplate = (rowData: DataRoutines) => {
        return (
            <div className="actions">
                <Button data-tip data-for="menuGroups" icon="pi pi-pencil" className="p-button-rounded p-button p-mr-2" onClick={() => editRoutines(rowData)} />
                <ReactTooltip className="toolTip" id="menuGroups" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Editar Rotinas</p>
                </ReactTooltip>
            </div>
        )
    }

    const editRoutines = (routine: any) => {
        setRoutine({ ...routine })
        setRoutinesDialogEdit(true)
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2 p-mb-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const dateFormatCreate = (rowData: DataRoutines) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.created_at}</Moment>
            </>
        )
    }

    const dateFormatUpdate = (rowData: DataRoutines) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.updated_at}</Moment>
            </>
        )
    }

    const activeInative = (rowData: DataRoutines) => {
        const statusClass = rowData.status ? "instock" : "outofstock"
        return (
            <>
                <span className={`product-badge status-${statusClass}`}>{rowData.status ? "Ativo" : "Inativo"}</span>
            </>
        )
    }

    const onChangeCreateRoutines = (event: any) => {
        const { value, name } = event.target

        setCreateValues({
            ...createValues,
            [name]: value,
        })
    }

    const onStatusChange = (e: any) => {
        let _routine = { ...routine }
        _routine["status"] = e.value
        setRoutine(_routine)
    }

    const onInputChange = (e: any, name: string) => {
        const val = (e.target && e.target.value) || ""
        let _routine = { ...routine }

        switch (name) {
            case "description":
                _routine["description"] = val
                break

            default:
                break
        }

        setRoutine(_routine)
    }

    //Cabeçalho da tabela
    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Gerenciar Rotinas</h5>
            <span className="p-input-icon-left p-mr-2">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Pesquisar..." />
            </span>
        </div>
    )

    useEffect(() => {
        allRoutines()
    }, [])

    return (
        <App>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />

                        <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}></Toolbar>

                        {/* Criação da tabela */}
                        <DataTable
                            value={routines}
                            dataKey="id"
                            paginator
                            stripedRows
                            rows={10}
                            loading={loadingList}
                            rowsPerPageOptions={[5, 10, 25]}
                            globalFilter={globalFilter}
                            // className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} rotinas"
                            emptyMessage="Não há rotinas cadastrados"
                            header={header}
                            filters={filters}
                            globalFilterFields={["name", "description"]}
                            responsiveLayout="scroll"
                        >
                            {/* Criação coluna name */}
                            <Column align="center" alignHeader="center" header="Nome" sortable field="name"></Column>

                            {/* Criação coluna description*/}
                            <Column align="center" alignHeader="center" header="Descrição" sortable field="description"></Column>

                            {/* Criação coluna description*/}
                            <Column align="center" alignHeader="center" header="Status" body={activeInative} sortField="status" sortable></Column>

                            {/* Criação coluna creation date*/}
                            <Column
                                align="center"
                                alignHeader="center"
                                header="Data de Criação"
                                body={dateFormatCreate}
                                sortField="created_at"
                                sortable
                            ></Column>

                            {/* Criação coluna alternate date */}
                            <Column
                                align="center"
                                alignHeader="center"
                                header="Data Alteração"
                                body={dateFormatUpdate}
                                sortField="updated_at"
                                sortable
                            ></Column>

                            <Column align="center" alignHeader="center" body={actionBodyTemplate}></Column>
                        </DataTable>

                        <Dialog
                            visible={routinesDialog}
                            style={{ width: "450px" }}
                            header="Adicionar Rota"
                            modal
                            className="p-fluid"
                            draggable={false}
                            footer={routinesCreateDialogFooter}
                            onHide={hideDialog}
                        >
                            <form onSubmit={onSubmitCreateRoutines}>
                                <div className="p-field">
                                    <label htmlFor="name">Nome</label>
                                    <InputText
                                        id="name"
                                        name="name"
                                        type="String"
                                        maxLength={50}
                                        autoFocus
                                        className={
                                            "p-field p-text-lowercase " +
                                            classNames({
                                                "p-invalid": submitted && createValues.name === "",
                                            })
                                        }
                                        value={createValues.name}
                                        onChange={onChangeCreateRoutines}
                                    />
                                </div>

                                <div className="p-field">
                                    <label htmlFor="description">Descrição</label>
                                    <InputText
                                        id="description"
                                        name="description"
                                        className={
                                            "p-field " +
                                            classNames({
                                                "p-invalid": submitted && createValues.description === "",
                                            })
                                        }
                                        value={createValues.description}
                                        onChange={onChangeCreateRoutines}
                                    />
                                </div>
                            </form>
                        </Dialog>

                        <Dialog
                            visible={routinesDialogEdit}
                            style={{ width: "450px" }}
                            header="Editar descrição"
                            modal
                            draggable={false}
                            className="p-fluid"
                            footer={routinesEditDialogFooter}
                            onHide={hideDialogEditRoutines}
                        >
                            <form>
                                <div className="p-field">
                                    <label htmlFor="description">Descrição</label>
                                    <InputText
                                        id="description"
                                        name="description"
                                        style={{ minWidth: "20rem" }}
                                        type="String"
                                        maxLength={100}
                                        className={
                                            "p-field " +
                                            classNames({
                                                "p-invalid": submitted && routine.description === "",
                                            })
                                        }
                                    />
                                </div>
                            </form>
                        </Dialog>

                        <Dialog
                            visible={routinesDialogEdit}
                            style={{ width: "450px" }}
                            header="Editar descrição"
                            modal
                            className="p-fluid"
                            draggable={false}
                            footer={routinesEditDialogFooter}
                            onHide={hideDialogEditRoutines}
                        >
                            <div className="p-field">
                                <label htmlFor="description">Descrição</label>
                                <InputText
                                    id="description"
                                    name="description"
                                    autoFocus
                                    type="String"
                                    maxLength={100}
                                    onChange={(e) => onInputChange(e, "description")}
                                    style={{ minWidth: "20rem" }}
                                    value={routine.description}
                                    className={
                                        "p-field " +
                                        classNames({
                                            "p-invalid": submitted && routine.description === "",
                                        })
                                    }
                                />
                            </div>

                            <div className="p-field">
                                <label className="p-mb-3">Status</label>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field-radiobutton p-col-6">
                                        <RadioButton
                                            inputId="ativado"
                                            name="status"
                                            onChange={(e) => onStatusChange(e)}
                                            value={true}
                                            checked={routine.status}
                                        />
                                        <label htmlFor="ativado">Ativo</label>
                                    </div>
                                    <div className="p-field-radiobutton p-col-6">
                                        <RadioButton
                                            inputId="desativado"
                                            name="status"
                                            onChange={(e) => onStatusChange(e)}
                                            value={false}
                                            checked={!routine.status}
                                        />
                                        <label htmlFor="desativado">Inativo</label>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </App>
    )
}
