
import React, { useState, useEffect, useRef } from "react"
import api from "../../../services/api"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { Toast } from "primereact/toast"
import { v4 as uuidv4 } from "uuid"
import { DDICountries } from "../../../listsArrays/ListsArrays"
import { ContactInterface } from "../../../interfaces/ContactInterface"
import { CompanyInterface } from "../../../interfaces/CompanyInterface"
import { InputMask } from "primereact/inputmask"

interface PropsInterface {
    formContact: ContactInterface
    setFormContact: (e: any) => void
    setNewPhoneField: (e: any) => void
    setNewEmailField: (e: any) => void
    newPhoneField: any
    newEmailField: any
    insideCompany?: CompanyInterface
    type?: string
}

export const Contact = (props: PropsInterface) => {
    const toast: any = useRef(null)
    const token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    const [companies, setCompanies] = useState<CompanyInterface[]>([])

    const handleGetAllCompanies = async () => {
        try {
            let header = {
                headers: {
                    route: "list_companies",
                },
            }

            await api
                .get("companies", header)
                .then((res) => {
                    setCompanies(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar empresas!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleAddNewFieldContact = () => {
        if (!(props.newPhoneField.length >= 4)) {
            props.setNewPhoneField([...props.newPhoneField, { id: uuidv4(), telephone: "", name: "phone", ddi: { ddi: "+55", country: "Brasil" } }])
        }
    }

    const handleRemoveFieldContact = (id: string) => {
        const values = [...props.newPhoneField]
        values.splice(
            values.findIndex((value) => value.id === id),
            1
        )
        props.setNewPhoneField(values)
    }

    const handleAddNewFieldEmail = () => {
        if (!(props.newEmailField.length >= 4)) {
            props.setNewEmailField([...props.newEmailField, { id: uuidv4(), emails: "", name: "email" }])
        }
    }

    const handleRemoveFieldEmail = (id: string) => {
        const values = [...props.newEmailField]
        values.splice(
            values.findIndex((value) => value.id === id),
            1
        )
        props.setNewEmailField(values)
    }

    const itemsDDITemplate = (item: any) => {
        return (
            <>
                <span>
                    {item.ddi} - {item.country}
                </span>
            </>
        )
    }

    const handleOnChangeForm = (value: any, name: any) => {
        props.setFormContact({
            ...props.formContact,
            [name]: value,
        })
    }

    const handleOnChangeFieldEmail = (id: any, item: any) => {
        const newInputFieldEmail = props.newEmailField.map((i: any) => {
            if (id === i.id) {
                i.emails = item.target.value
            }
            return i
        })

        props.setNewEmailField(newInputFieldEmail)
    }

    const handleOnChangeFieldPhone = (id: any, item: any) => {
        const newInputFieldContact = props.newPhoneField.map((i: any) => {
            if (id === i.id) {
                if (item.target.name === "telephone") {
                    i.telephone = item.target.value
                }

                if (item.target.name === "ddi") {
                    i.ddi = item.target.value
                }
            }
            return i
        })

        props.setNewPhoneField(newInputFieldContact)
    }

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    useEffect(() => {
        if (props.type !== "opportunnityCreate") {
            if (isEmptyObj(props.insideCompany)) {
                handleGetAllCompanies()
            }

            if (!isEmptyObj(props.insideCompany)) {
                handleOnChangeForm(props.insideCompany, "company")
            }

            props.formContact.authorization = "LI"
            props.formContact.authorization_status = true
        } else {
            props.formContact.authorization = "LI"
            props.formContact.authorization_status = true
            props.formContact.company = {} as CompanyInterface
        }
    }, [])

    return (
        <>
            <Toast ref={toast} />

            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-field">
                        <label htmlFor="name">
                            Nome: <span style={{ color: "red" }}>*</span>
                        </label>
                        <InputText
                            style={{ minWidth: "20rem", textTransform: "capitalize" }}
                            id="name"
                            maxLength={120}
                            name="name"
                            required
                            onChange={(e: any) => {
                                handleOnChangeForm(e.target.value, e.target.name)
                            }}
                            value={props.formContact.name || ""}
                        />
                    </div>

                    <div className="p-field">
                        <label htmlFor="job">Cargo:</label>
                        <InputText
                            style={{ minWidth: "20rem", textTransform: "capitalize" }}
                            id="job"
                            name="job"
                            maxLength={120}
                            onChange={(e: any) => {
                                handleOnChangeForm(e.target.value, e.target.name)
                            }}
                            value={props.formContact.job || ""}
                        />
                    </div>

                    {props.type !== "opportunnityCreate" && (
                        <>
                            {isEmptyObj(props.insideCompany) && (
                                <div className="p-field">
                                    <label htmlFor="company">
                                        Empresa / Cliente: <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Dropdown
                                        style={{ minWidth: "20rem" }}
                                        id="company"
                                        filter={true}
                                        name="company"
                                        options={companies}
                                        optionLabel="name"
                                        placeholder="Selecionar"
                                        onChange={(e: any) => {
                                            handleOnChangeForm(e.target.value, e.target.name)
                                        }}
                                        value={props.formContact.company || ""}
                                    />
                                </div>
                            )}

                            {!isEmptyObj(props.insideCompany) && (
                                <div className="p-field">
                                    <label htmlFor="company">
                                        Empresa / Cliente: <span style={{ color: "red" }}>*</span>
                                    </label>

                                    <InputText value={props.insideCompany?.name} disabled />
                                </div>
                            )}
                        </>
                    )}

                    <div className="p-field">
                        <label htmlFor="emailContact">Telefone:</label>
                        {props.newPhoneField.map((auxNewContactField: any) => (
                            <div className="p-grid" key={auxNewContactField.id}>
                                <div className="p-col-3 p-md-3 p-lg-3">
                                    <Dropdown
                                        style={{ width: "100%" }}
                                        id="ddi"
                                        filter={true}
                                        filterBy="ddi,country"
                                        name="ddi"
                                        options={DDICountries}
                                        optionLabel="country"
                                        itemTemplate={(e) => itemsDDITemplate(e)}
                                        value={auxNewContactField.ddi}
                                        onChange={(e: any) => {
                                            handleOnChangeFieldPhone(auxNewContactField.id, e)
                                        }}
                                    />
                                </div>

                                <div className="p-col-5 p-md-6 p-lg-6">
                                    <InputMask
                                        mask="99999999?99999999"
                                        id={auxNewContactField.id}
                                        name="telephone"
                                        value={auxNewContactField.telephone}
                                        onChange={(e: any) => {
                                            handleOnChangeFieldPhone(auxNewContactField.id, e)
                                        }}
                                    />
                                </div>

                                <div className="p-col p-md-1 p-lg-1">
                                    <Button icon="pi pi-plus" type="button" className="p-button-text p-button-success" onClick={handleAddNewFieldContact} />
                                </div>

                                <div className="p-col p-md-1 p-lg-1">
                                    <Button
                                        icon="pi pi-minus"
                                        type="button"
                                        className="p-button-text p-button-danger"
                                        disabled={props.newPhoneField.length === 1}
                                        onClick={() => {
                                            handleRemoveFieldContact(auxNewContactField.id)
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="p-field">
                        <label htmlFor="emailContact">Email:</label>
                        {props.newEmailField.map((auxNewEmailField: any) => (
                            <div className="p-grid" key={auxNewEmailField.id}>
                                <div className="p-col-8 p-md-9 p-lg-9">
                                    <InputText
                                        type="email"
                                        id={auxNewEmailField.id}
                                        name="email"
                                        maxLength={70}
                                        minLength={3}
                                        value={auxNewEmailField.emails}
                                        onChange={(e: any) => {
                                            handleOnChangeFieldEmail(auxNewEmailField.id, e)
                                        }}
                                    />
                                </div>

                                <div className="p-col p-md-1 p-lg-1">
                                    <Button icon="pi pi-plus" type="button" className="p-button-text p-button-success" onClick={handleAddNewFieldEmail} />
                                </div>

                                <div className="p-col p-md-1 p-lg-1">
                                    <Button
                                        icon="pi pi-minus"
                                        type="button"
                                        className="p-button-text p-button-danger"
                                        disabled={props.newEmailField.length === 1}
                                        onClick={() => {
                                            handleRemoveFieldEmail(auxNewEmailField.id)
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
