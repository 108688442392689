import React from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App"

const html = document.getElementById("root")!

createRoot(html).render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
)
