import React, { useRef, useState, useEffect } from "react"
import App from "../../layout/App"
import api from "../../services/api"
import Moment from "react-moment"
import moment from "moment"
import { selectionPeriod } from "../../listsArrays/ListsArrays"
import { Avatar } from "primereact/avatar"
import { Card } from "primereact/card"
import { Chart } from "primereact/chart"
import { Dialog } from "primereact/dialog"
import { Divider } from "primereact/divider"
import { Dropdown } from "primereact/dropdown"
import { Rating } from "react-simple-star-rating"
import { ScrollPanel } from "primereact/scrollpanel"
import { TabPanel, TabView } from "primereact/tabview"
import { useHotkeys } from "react-hotkeys-hook"
import { FunnelInterface } from "../../interfaces/FunnelInterface"
import { OpportunityInterface } from "../../interfaces/OpportunityInterface"
import { OpportunityProposalInterface } from "../../interfaces/OpportunityProposalInterface"
import { OpportunityProposalOptionalInterface } from "../../interfaces/OpportunityProposalOptionalInterface"
import { PeriodFilterInterface } from "../../interfaces/PeriodFilterInterface"
import { TaskInterface } from "../../interfaces/TaskInterface"
import { UserInterface } from "../../interfaces/UserInterface"
import { UserTotalInterface } from "../../interfaces/UserTotalInterface"
import { HotKeys } from "../../components/crm/HotKeys"
import { Toast } from "primereact/toast"

export const Live = () => {
    let toast = useRef<Toast>(null)
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    let [oportunities, setOportunities] = useState<OpportunityInterface[]>([])
    let [oportunitiesValue, setOportunitiesValue] = useState<OpportunityInterface[]>([])
    let [funnels, setFunnels] = useState<FunnelInterface[]>([])
    let [tasks, setTasks] = useState<TaskInterface[]>([])
    let [funnel, setFunnel] = useState<FunnelInterface>({} as FunnelInterface)

    //Counter functions
    let [mostOpportunity, setMostOpportunity] = useState(0)
    let [mostOpportunityUser, setMostOpportunityUser] = useState<UserInterface>({} as UserInterface)
    let [auxUsersOpportunities, setAuxUsersOpportunities] = useState<UserTotalInterface[]>([])
    let [mostSellsUnities, setMostSellsUnities] = useState(0)
    let [mostSellsUnitiesUser, setMostSellsUnitiesUser] = useState<UserInterface>({} as UserInterface)
    let [mostSellsMoney, setMostSellsMoney] = useState(0)
    let [mostSellsMoneyUser, setMostSellsMoneyUser] = useState<UserInterface>({} as UserInterface)
    let [inProgressOpportunity, setInProgressOpportunity] = useState(0)
    let [newOpportunity, setNewOpportunity] = useState(0)
    let [beforePeriodNewOpportunity, setBeforePeriodNewOpportunity] = useState(0)

    //Stars
    let [auxOneStar, setAuxOneStar] = useState(0)
    let [auxTwoStar, setAuxTwoStar] = useState(0)
    let [auxThreeStar, setAuxThreeStar] = useState(0)
    let [auxFourStar, setAuxFourStar] = useState(0)
    let [auxFiveStar, setAuxFiveStar] = useState(0)
    let [oneStar, setOneStar] = useState(0)
    let [twoStar, setTwoStar] = useState(0)
    let [threeStar, setThreeStar] = useState(0)
    let [fourStar, setFourStar] = useState(0)
    let [fiveStar, setFiveStar] = useState(0)

    //Before period filter
    let [sellsInActualPeriod, setSellsInActualPeriod] = useState(0)
    let [beforePeriodSells, setBeforePeriodSells] = useState(0)
    let [countsPeriodSells, setCountsPeriodSells] = useState(0)
    let [countBeforePeriod, setCountBeforePeriod] = useState(0)
    let [conversionActualPeriod, setConversionActualPeriod] = useState(0)
    let [lostOpportunityPercente, setLostOpportunityPercente] = useState(0)
    let [beforePeriodLostOpportunityPercente, setBeforePeriodLostOpportunityPercente] = useState(0)
    let [inProgressValue, setInProgressValue] = useState(0)

    let [usersOpportunities, setUsersOpportunities] = useState<OpportunityInterface[]>([])
    let [inOpenTasks, setInOpenTasks] = useState<TaskInterface[]>([])

    //Auxiliars
    let [auxLostReasonDescription, setAuxLostReasonDescription] = useState<any[]>([])
    let [auxLostReasonCount, setAuxLostReasonCount] = useState<any[]>([])

    //Counters
    let countOpportunity = 0
    let priceOpportunity = 0
    let [datePeriod, setDatePeriod] = useState<PeriodFilterInterface>({
        name: "MÊS",
        daysNumber: 30,
    })

    //HotKeys
    let [hotKeysDialog, setHotKeysDialog] = useState(false)
    useHotkeys("shift+i", () => setHotKeysDialog(true))

    //Metódos get
    const allFunnels = () => {
        try {
            let header = {
                headers: {
                    route: "list_funnels",
                },
            }

            api.get("funnels", header)
                .then((res) => {
                    setFunnels(res.data)

                    res.data.forEach((response: FunnelInterface) => {
                        if (response.is_default) {
                            funnel = response
                            setFunnel(response)
                            filter()
                            filterClosed()
                        }
                    })
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar oportunidade!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const allTasks = () => {
        try {
            let header = {
                headers: {
                    route: "list_tasks",
                },
            }

            api.get("tasks", header)
                .then((res) => {
                    setTasks(res.data)
                    tasks = res.data
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const allOpportunities = () => {
        try {
            let header = {
                headers: {
                    route: "list_opportunities_full",
                },
            }

            api.get("opportunities/full", header)
                .then((res) => {
                    setOportunities(res.data)
                    oportunities = res.data
                    allFunnels()
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar oportunidade!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const allOpportunitiesValue = () => {
        try {
            let header = {
                headers: {
                    route: "list_opportunities",
                },
            }

            api.get("opportunities", header)
                .then((res) => {
                    setOportunitiesValue(res.data)
                    oportunitiesValue = res.data
                    allFunnels()
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar oportunidade!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const replaceType = (type: string) => {
        switch (type) {
            case "LG":
                return "Ligação"
            case "EM":
                return "E-mail"
            case "RE":
                return "Reunião"
            case "VI":
                return "Visita"
            case "TA":
                return "Tarefa"
            case "AL":
                return "Almoço"
            case "WP":
                return "WhatsApp"
            default:
                return type
        }
    }

    const isEmptyObj = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return true
    }

    //Dialog
    let hideDialog = () => {
        setHotKeysDialog(false)
    }

    //Proposal sums functions
    const sumValueProposal = (proposal: OpportunityProposalInterface) => {
        let sumOptional = 0
        let sumMachine = 0

        proposal.optionals.forEach((optional: OpportunityProposalOptionalInterface) => {
            if (optional.discount_type === "percentage") {
                sumOptional += optional.price - (optional.price * optional.discount) / 100
            }

            if (optional.discount_type === "value") {
                sumOptional += optional.price - optional.discount
            }
        })

        if (proposal.machine_discount_type === "value") {
            sumMachine = proposal.machine_price - proposal.machine_discount
        }

        if (proposal.machine_discount_type === "percentage") {
            sumMachine = proposal.machine_price - (proposal.machine_price * proposal.machine_discount) / 100
        }

        return sumMachine + sumOptional
    }

    //Chart options
    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: "#495057",
                },
            },
        },
    })

    //Chart configuration
    const [chartData, setChartData] = useState({
        labels: new Array(),
        datasets: [
            {
                data: new Array(),
                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            },
        ],
    })

    //Filters and operations
    const filter = () => {
        if (!isEmptyObj(oportunities)) {
            let auxInProgressValue = 0
            let auxOneStar = 0
            let auxTwoStar = 0
            let auxThreeStar = 0
            let auxFourStar = 0
            let auxinProgressOpportunity = 0
            inProgressValue = 0
            oneStar = 0
            twoStar = 0
            threeStar = 0

            setInOpenTasks([])
            setOneStar(0)
            setTwoStar(0)
            setThreeStar(0)
            setFourStar(0)
            setFiveStar(0)

            oportunitiesValue.forEach((oportunities) => {
                if (funnel.id === oportunities.funnelStage.funnel_id) {
                    auxInProgressValue += oportunities.totalValueMachines
                    setInProgressValue(auxInProgressValue)

                    if (oportunities.status === "open") {
                        auxinProgressOpportunity = auxinProgressOpportunity + 1
                        setInProgressOpportunity(auxinProgressOpportunity)
                    }

                    // Todas as tasks mais proximas.
                    tasks.forEach((task) => {
                        if (task.opportunity.id === oportunities.id && task.status === "open") {
                            inOpenTasks.push(task)
                            setInOpenTasks(inOpenTasks)
                        }
                    })

                    if (oportunities.qualification === 1) {
                        oneStar++
                        setOneStar(oneStar)
                        auxOneStar = oportunities.totalValueMachines + auxOneStar
                        setAuxOneStar(auxOneStar)
                    }

                    if (oportunities.qualification === 2) {
                        twoStar++
                        setTwoStar(twoStar)
                        auxTwoStar = oportunities.totalValueMachines + auxTwoStar
                        setAuxTwoStar(auxTwoStar)
                    }

                    if (oportunities.qualification === 3) {
                        threeStar++
                        setThreeStar(threeStar)
                        auxThreeStar = oportunities.totalValueMachines + auxThreeStar
                        setAuxThreeStar(auxThreeStar)
                    }

                    if (oportunities.qualification === 4) {
                        fourStar++
                        setFourStar(fourStar)
                        auxFourStar = oportunities.totalValueMachines + auxFourStar
                        setAuxFourStar(auxFourStar)
                    }

                    if (oportunities.qualification === 5) {
                        fiveStar++
                        setFiveStar(fiveStar)
                        auxFiveStar = oportunities.totalValueMachines + auxFiveStar
                        setAuxFiveStar(auxFiveStar)
                    }
                }
            })
        }
    }

    const filterClosed = async () => {
        if (!isEmptyObj(oportunities)) {
            let auxBeforePeriodSells = 0
            let auxSellsInActualPeriod = 0
            let auxCountBeforePeriod = 0
            let AuxConversion = 0
            let countLoss = 0
            let countLossBeforePeriod = 0
            let countOpportunity = 0

            inOpenTasks = []
            newOpportunity = 0
            countsPeriodSells = 0
            countBeforePeriod = 0
            lostOpportunityPercente = 0
            conversionActualPeriod = 0
            mostOpportunity = 0
            mostSellsUnities = 0
            mostSellsMoney = 0

            auxUsersOpportunities = []

            auxLostReasonDescription = []
            auxLostReasonCount = []

            oportunities.forEach((opportunity) => {
                if (funnel.id === opportunity.funnelStage.funnel_id) {
                    countOpportunity++
                    let newDate = moment(new Date()).subtract(datePeriod.daysNumber, "d").format()
                    let beforePeriodDate = moment(new Date())
                        .subtract(datePeriod.daysNumber * 2, "d")
                        .format()
                    if (moment(opportunity.created_at).format() >= beforePeriodDate && moment(opportunity.created_at).format() <= newDate) {
                        if (opportunity.status === "loss") {
                            countLossBeforePeriod++
                            setBeforePeriodLostOpportunityPercente((countLossBeforePeriod * 100) / countOpportunity)
                        }

                        beforePeriodNewOpportunity++

                        opportunity.proposals.forEach((proposal: OpportunityProposalInterface) => {
                            if (!isEmptyObj(proposal.order)) {
                                auxBeforePeriodSells += sumValueProposal(proposal)
                                auxCountBeforePeriod++
                                setCountBeforePeriod(auxCountBeforePeriod)
                            }
                        })

                        setBeforePeriodSells(auxBeforePeriodSells)
                        setBeforePeriodNewOpportunity(beforePeriodNewOpportunity)
                    }

                    ///////////////////////////////////////////////////////////////////////////////////////////////////

                    if (moment(opportunity.created_at).format() >= newDate) {
                        //Oportunidades Novas
                        newOpportunity++
                        setNewOpportunity(newOpportunity)

                        if (opportunity.lossreason !== null) {
                            const existLossReasonDescription = auxLostReasonDescription.findIndex((item) => item === opportunity.lossreason.name)
                            if (existLossReasonDescription >= 0) {
                                auxLostReasonCount[existLossReasonDescription] += 1
                            } else {
                                auxLostReasonDescription.push(opportunity.lossreason.name)
                                auxLostReasonCount.push(1)
                            }
                        }

                        if (opportunity.user.name != null) {
                            usersOpportunities.push(opportunity)
                            setUsersOpportunities(usersOpportunities)
                        }

                        //Oportunidades Perdidas
                        if (opportunity.status === "loss") {
                            countLoss++
                            setLostOpportunityPercente((countLoss * 100) / countOpportunity)
                        }

                        let index = auxUsersOpportunities.findIndex((auxUsersOpportunity) => auxUsersOpportunity.user.id === opportunity.user.id)

                        if (index < 0) {
                            index =
                                auxUsersOpportunities.push({
                                    created_quantity: 0,
                                    sales_quantity: 0,
                                    user: opportunity.user,
                                    sales_value: 0,
                                }) - 1
                        }

                        auxUsersOpportunities[index].created_quantity += 1

                        if (opportunity.proposals) {
                            opportunity.proposals.forEach((proposal) => {
                                if (proposal.order !== null) {
                                    auxUsersOpportunities[index].sales_quantity = auxUsersOpportunities[index].sales_quantity + proposal.machine_quantity
                                    auxUsersOpportunities[index].sales_value += proposal.machine_price
                                }

                                if (proposal.order !== null) {
                                    proposal.optionals.forEach((optional) => {
                                        auxUsersOpportunities[index].sales_value += optional.price
                                    })
                                }
                            })
                        }

                        auxUsersOpportunities.forEach((auxUsersOportunity) => {
                            if (auxUsersOportunity.created_quantity > mostOpportunity) {
                                mostOpportunity = auxUsersOportunity.created_quantity
                                mostOpportunityUser = auxUsersOportunity.user
                            }
                        })

                        auxUsersOpportunities.forEach((auxUsersOportunity) => {
                            opportunity.proposals.forEach((proposal) => {
                                if (proposal.order !== null) {
                                    if (auxUsersOportunity.sales_quantity > mostSellsUnities) {
                                        mostSellsUnities = auxUsersOportunity.sales_quantity
                                        mostSellsUnitiesUser = auxUsersOportunity.user
                                    }

                                    if (auxUsersOportunity.sales_value > mostSellsMoney) {
                                        mostSellsMoney = auxUsersOportunity.sales_value
                                        mostSellsMoneyUser = auxUsersOportunity.user
                                    }
                                }
                            })
                        })

                        setMostOpportunity(mostOpportunity)
                        setMostSellsUnities(mostSellsUnities)
                        setMostSellsMoney(mostSellsMoney)
                        setMostOpportunityUser(mostOpportunityUser)
                        setMostSellsUnitiesUser(mostSellsUnitiesUser)
                        setMostSellsMoneyUser(mostSellsMoneyUser)
                    } else {
                        setNewOpportunity(0)
                        setLostOpportunityPercente(0)
                        setBeforePeriodLostOpportunityPercente(0)
                        setConversionActualPeriod(0)
                        setBeforePeriodNewOpportunity(0)
                        setCountBeforePeriod(0)
                        setMostOpportunity(0)
                        setMostSellsUnities(0)
                        setCountsPeriodSells(0)
                        inOpenTasks = []
                    }
                }
                opportunity.proposals.forEach((proposal: OpportunityProposalInterface) => {
                    if (!isEmptyObj(proposal.order)) {
                        conversionActualPeriod++
                        setCountsPeriodSells(conversionActualPeriod)
                        auxSellsInActualPeriod += sumValueProposal(proposal)
                        AuxConversion = (conversionActualPeriod * 100) / countOpportunity
                        setSellsInActualPeriod(auxSellsInActualPeriod)
                        setConversionActualPeriod(AuxConversion)
                    }
                })
            })

            setAuxLostReasonDescription(auxLostReasonDescription)
            setAuxLostReasonCount(auxLostReasonCount)

            setChartData({
                labels: auxLostReasonDescription,
                datasets: [
                    {
                        data: auxLostReasonCount,
                        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                    },
                ],
            })
        }
    }

    //UseEffect
    useEffect(() => {
        allOpportunities()
        allTasks()
        allOpportunitiesValue()
    }, [])

    useEffect(() => {
        setAuxUsersOpportunities(auxUsersOpportunities)
    }, [auxUsersOpportunities])

    return (
        <App>
            <TabView>
                <TabPanel header="TODOS">
                    <div className="viewFilter p-col-0 p-md-0 p-lg-0">
                        <br />
                        <div className="p-d-flex p-jc-end p-ai-center">
                            <div className="p-mr-4">
                                <div className="p-d-flex p-ai-center p-flex-column">
                                    <span
                                        style={{
                                            fontSize: "1.2rem",
                                            marginBottom: "0.5rem",
                                        }}
                                    >
                                        FUNIL DE VENDAS
                                    </span>

                                    <Dropdown
                                        id="WorkViewFilterId"
                                        value={funnel || ""}
                                        options={funnels}
                                        emptyMessage="Não há dados"
                                        emptyFilterMessage="Não há dados"
                                        onChange={(e) => {
                                            funnel = e.value
                                            setFunnel(e.value)
                                            filterClosed()
                                        }}
                                        filter={true}
                                        optionLabel="name"
                                        placeholder="Funil de vendas"
                                    />
                                </div>
                            </div>
                            <div className="p-mr-4">
                                <div className="p-d-flex p-ai-center p-flex-column">
                                    <span
                                        style={{
                                            fontSize: "1.2rem",
                                            marginLeft: "0rem",
                                            marginBottom: "0.5rem",
                                        }}
                                    >
                                        PERÍODO
                                    </span>
                                    <Dropdown
                                        id="WorkViewFilterId"
                                        value={datePeriod || ""}
                                        options={selectionPeriod}
                                        emptyMessage="Não há dados"
                                        emptyFilterMessage="Não há dados"
                                        onChange={(e) => {
                                            datePeriod = e.value
                                            setDatePeriod(e.value)
                                            filterClosed()
                                        }}
                                        filter={true}
                                        optionLabel="name"
                                        placeholder=" Período"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="" style={{ margin: "0" }}>
                        <div className="p-d-flex p-flex-column p-flex-md-row">
                            <Card style={{ marginTop: "1vh", minWidth: "27.8rem", width: "32vw" }} className="p-mr-2">
                                <div style={{ height: "auto", minWidth: "18rem", marginTop: "1vh" }}>
                                    <p style={{ color: "#03A9F4", fontSize: "1.5rem", fontWeight: "700", textAlign: "center" }}>
                                        QUEM MAIS POSSUI OPORTUNIDADES
                                    </p>
                                    <Avatar
                                        className="p-overlay-badge"
                                        image={mostOpportunityUser.avatar_url}
                                        style={{
                                            width: "5vw",
                                            height: "auto",
                                            marginRight: "auto",
                                            marginLeft: "auto",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    ></Avatar>
                                    <p style={{ fontSize: "1.5rem", textAlign: "center", marginTop: "1vh" }}>{mostOpportunityUser.name}</p>
                                    <p style={{ fontSize: "2rem", fontWeight: "700", textAlign: "center", marginTop: "2.5vh" }}>{mostOpportunity}</p>
                                </div>
                            </Card>

                            <Card style={{ marginTop: "1vh", minWidth: "27.8rem", width: "32vw" }} className="p-mr-2">
                                <div style={{ height: "auto", minWidth: "18rem", marginTop: "1vh" }}>
                                    <p style={{ color: "#1BBE83", fontSize: "1.5rem", fontWeight: "700", textAlign: "center" }}>QUEM MAIS VENDEU (UN)</p>
                                    <Avatar
                                        className="p-overlay-badge"
                                        image={mostSellsMoneyUser.avatar_url}
                                        style={{
                                            width: "5vw",
                                            height: "auto",
                                            marginRight: "auto",
                                            marginLeft: "auto",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    ></Avatar>
                                    <p style={{ fontSize: "1.5rem", textAlign: "center", marginTop: "1vh" }}>{mostSellsMoneyUser.name}</p>
                                    <p style={{ fontSize: "2rem", fontWeight: "700", textAlign: "center", marginTop: "2.5vh" }}>{mostSellsUnities}</p>
                                </div>
                            </Card>

                            <Card style={{ marginTop: "1vh", minWidth: "27.8rem", width: "32vw" }} className="p-mr-2">
                                <div style={{ height: "auto", minWidth: "18rem", marginTop: "1vh" }}>
                                    <p style={{ color: "#1BBE83", fontSize: "1.5rem", fontWeight: "700", textAlign: "center" }}>QUEM MAIS VENDEU (R$)</p>
                                    <Avatar
                                        className="p-overlay-badge"
                                        image={mostSellsMoneyUser.avatar_url}
                                        style={{
                                            width: "5vw",
                                            height: "auto",
                                            marginRight: "auto",
                                            marginLeft: "auto",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    ></Avatar>
                                    <p style={{ fontSize: "1.5rem", textAlign: "center", marginTop: "1vh" }}>{mostSellsMoneyUser.name}</p>
                                    <div className="p-d-flex p-jc-center">
                                        <div
                                            className="p-mb-2 p-as-center"
                                            style={{
                                                width: "1rem",
                                                height: "1rem",
                                                borderRadius: "90%",
                                                backgroundColor: "#03A9F4",
                                                marginRight: "0.2em",
                                            }}
                                        />
                                        <div className="p-mb-2" style={{ fontSize: "1.5rem" }}>
                                            {mostSellsMoney.toLocaleString("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                            })}
                                        </div>
                                    </div>
                                    <div className="p-d-flex p-jc-center"></div>
                                </div>
                            </Card>
                        </div>

                        <div>
                            <div className="p-d-flex p-flex-column p-flex-md-row" style={{ marginTop: "" }}>
                                <Card style={{ width: "25vw", marginTop: "1vh", minWidth: "27.8rem" }} className="p-mr-2">
                                    <div className="p-d-flex">
                                        <div style={{}}>
                                            <p style={{ fontSize: "1.4rem", color: "#03A9F4" }}>OPORTUNIDADES NOVAS</p>
                                            <p style={{ fontSize: "2rem" }}>{newOpportunity}</p>
                                        </div>
                                        <Divider layout="vertical" style={{ paddingLeft: "3rem" }} />
                                        <div style={{}}>
                                            <p style={{ fontSize: "1.4rem", color: "#03A9F4" }}> PERÍODO ANTERIOR</p>
                                            <p style={{ fontSize: "2rem" }}>{beforePeriodNewOpportunity}</p>
                                        </div>
                                    </div>
                                </Card>

                                <Card style={{ width: "25vw", marginTop: "1vh", minWidth: "27.8rem" }} className="p-mr-2">
                                    <div className="p-d-flex">
                                        <div style={{}}>
                                            <p style={{ fontSize: "1.4rem", color: "#1BBE83" }}>VENDAS NO PERÍODO</p>
                                            <p style={{ marginTop: "-1.5vh", fontWeight: "100" }}>(UN)</p>
                                            <p style={{ fontSize: "2rem" }}>{countsPeriodSells}</p>
                                        </div>
                                        <Divider layout="vertical" style={{ paddingLeft: "3rem" }} />
                                        <div style={{}}>
                                            <p style={{ fontSize: "1.4rem", color: "#1BBE83" }}> PERÍODO ANTERIOR</p>
                                            <p style={{ marginTop: "-1.5vh", fontWeight: "100" }}>(un)</p>
                                            <p style={{ fontSize: "2rem" }}>{countBeforePeriod}</p>
                                        </div>
                                    </div>
                                </Card>

                                <Card style={{ width: "45vw", marginTop: "1vh", minWidth: "27.8rem" }} className="p-mr-2">
                                    <div className="p-d-flex">
                                        <div style={{}}>
                                            <p style={{ fontSize: "1.4rem", color: "#1BBE83" }}>VENDAS NO PERÍODO</p>
                                            <div>
                                                <div className="p-d-flex p-jc-center  ">
                                                    <div
                                                        className="p-mb-2 p-as-center"
                                                        style={{
                                                            width: "0.8rem",
                                                            height: "0.8rem",
                                                            borderRadius: "90%",
                                                            backgroundColor: "#03A9F4",
                                                            marginRight: "0.2em",
                                                        }}
                                                    />
                                                    <div className="p-mb-2" style={{ fontSize: "1.8rem" }}>
                                                        {sellsInActualPeriod.toLocaleString("pt-BR", {
                                                            style: "currency",
                                                            currency: "BRL",
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="p-d-flex p-jc-center"></div>
                                            </div>
                                        </div>
                                        <Divider layout="vertical" style={{ paddingLeft: "3rem" }} />
                                        <div style={{}}>
                                            <p style={{ fontSize: "1.4rem", color: "#1BBE83" }}> PERÍODO ANTERIOR</p>
                                            <div>
                                                <div className="p-d-flex p-jc-center  ">
                                                    <div
                                                        className="p-mb-2 p-as-center"
                                                        style={{
                                                            width: "0.8rem",
                                                            height: "0.8rem",
                                                            borderRadius: "90%",
                                                            backgroundColor: "#03A9F4",
                                                            marginRight: "0.2em",
                                                        }}
                                                    />
                                                    <div className="p-mb-2" style={{ fontSize: "2rem" }}>
                                                        {beforePeriodSells.toLocaleString("pt-BR", {
                                                            style: "currency",
                                                            currency: "BRL",
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="p-d-flex p-jc-center"></div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>

                        <div className="p-d-flex p-flex-column p-flex-md-row">
                            <div className="p-d-flex p-flex-column">
                                <div>
                                    <Card style={{ marginTop: "3vh", width: "54vw", minWidth: "27.8rem" }}>
                                        <p style={{ fontSize: "1.8rem", fontWeight: "700", color: "#417BB0" }}>COVERSÃO:</p> <br />
                                        <p style={{ marginTop: "-2vh" }}>(CRIADOS NO PERÍODO / VENDAS NO PERÍODO )</p>
                                        <p style={{ fontSize: "2.2rem", fontWeight: "700" }}>{conversionActualPeriod.toFixed(2)}%</p>
                                    </Card>
                                </div>
                                <Card style={{ marginTop: "4vh", width: "54vw", minWidth: "27.8rem", marginRight: "0.5rem" }}>
                                    <div className="p-d-flex p-flex-column p-flex-md-row">
                                        <div>
                                            <p style={{ fontSize: "1.8rem", fontWeight: "700", color: "#E34A4A" }}>OPORTUNIDADES PERDIDAS</p>
                                            <p style={{ fontSize: "2.2rem", fontWeight: "700" }}>{lostOpportunityPercente.toFixed(2)} % </p>
                                        </div>

                                        <Divider layout="vertical" style={{ paddingLeft: "3rem" }} />
                                        <div style={{ marginLeft: "4rem" }}>
                                            <p style={{ fontSize: "1.8rem", fontWeight: "700", color: "#E34A4A" }}>PERÍODO ANTEIROR</p>
                                            <p style={{ fontSize: "2.2rem", fontWeight: "700" }}>{beforePeriodLostOpportunityPercente.toFixed(2)} %</p>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                            <Card style={{ marginTop: "1vh", width: "41.2vw", minHeight: "18rem", minWidth: "27.8rem" }}>
                                <div className="flex justify-content-center flex-wrap card-container yellow-container">
                                    <p style={{ fontSize: "1.8rem", fontWeight: "700", color: "#E34A4A" }}>MOTIVOS DE PERDA</p>
                                    <Chart type="doughnut" data={chartData} options={lightOptions} style={{ position: "relative", width: "40%" }} />
                                </div>
                            </Card>
                        </div>
                    </div>
                </TabPanel>

                <TabPanel header="EM ANDAMENTO">
                    <div className="viewFilter p-col-0 p-md-0 p-lg-0">
                        <br />
                        <div className="p-d-flex p-jc-end p-ai-center">
                            <div className="p-mr-4">
                                <div className="p-d-flex p-ai-center p-flex-column">
                                    <span
                                        style={{
                                            fontSize: "1.2rem",
                                            marginBottom: "0.5rem",
                                        }}
                                    >
                                        Funil de Vendas
                                    </span>

                                    <Dropdown
                                        id="WorkViewFilterId"
                                        value={funnel || ""}
                                        options={funnels}
                                        emptyMessage="Não há dados"
                                        emptyFilterMessage="Não há dados"
                                        onChange={(e) => {
                                            funnel = e.value
                                            setFunnel(e.value)
                                            filter()
                                        }}
                                        filter={true}
                                        optionLabel="name"
                                        placeholder="Funil de vendas"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="" style={{ margin: "0" }}>
                        <div className="p-d-flex p-flex-column p-flex-md-row" style={{}}>
                            <Card style={{ marginTop: "1vh", width: "52.0vw", minHeight: "18rem", minWidth: "30.8rem" }}>
                                <div className="p-d-flex">
                                    <div>
                                        <p style={{ fontSize: "2.0rem", color: "#03A9F4", paddingLeft: "50px" }}>
                                            OPORTUNIDADES
                                            <br /> EM ANDAMENTO
                                        </p>
                                        <p
                                            style={{
                                                fontSize: "6.5rem",
                                                textAlign: "center",
                                                textDecoration: "bold",
                                                color: "#4d4d4d",
                                                fontWeight: "700",
                                            }}
                                        >
                                            {inProgressOpportunity}
                                        </p>
                                    </div>

                                    <Divider layout="vertical" style={{ paddingLeft: "10vh" }} />

                                    <div style={{}}>
                                        <p style={{ fontSize: "2.0rem", color: "#03A9F4" }}>VALORES EM ANDAMENTO</p>
                                        <div className="p-d-flex p-jc-center  ">
                                            <div
                                                className="p-mb-2 p-as-center"
                                                style={{
                                                    width: "0.8rem",
                                                    height: "0.8rem",
                                                    borderRadius: "90%",
                                                    backgroundColor: "#03A9F4",
                                                    marginRight: "0.2em",
                                                }}
                                            />
                                            <div className="p-mb-2" style={{ fontSize: "1.8rem" }}>
                                                {inProgressValue.toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>

                            <Card style={{ marginTop: "1vh", width: "60.3vw", minHeight: "18rem", minWidth: "37.8rem" }}>
                                <p style={{ fontSize: "1.8rem", fontWeight: "700", color: "#03A9F4" }}>FEED DE TAREFAS</p>
                                <ScrollPanel style={{ width: "100%", height: "200px" }} className="custom">
                                    {inOpenTasks.map((task, indexTask) => (
                                        <h5 key={indexTask}>
                                            <strong>
                                                <Moment format="DD-MM-YYYY HH:mm">{task.date}</Moment> - {replaceType(task.type)}: {task.subject}
                                            </strong>
                                        </h5>
                                    ))}
                                </ScrollPanel>
                            </Card>
                        </div>

                        <div>
                            <div className="p-d-flex p-flex-column p-flex-md-row" style={{ marginTop: "" }}>
                                {funnel.stages != null
                                    ? funnel.stages.map((stage, indexStage) => {
                                          countOpportunity = 0
                                          priceOpportunity = 0
                                          return (
                                              <Card key={indexStage} style={{ width: "19.8vw", marginTop: "1vh", minWidth: "12.8rem" }} className="p-mr-2">
                                                  <div>
                                                      <p style={{ fontSize: "1.4rem", textAlign: "center" }}>{stage.name.toUpperCase()}</p>
                                                      <p
                                                          style={{
                                                              fontWeight: "700",
                                                              fontSize: "6.5rem",
                                                              textAlign: "center",
                                                              textDecoration: "bold",
                                                              color: "#4d4d4d",
                                                          }}
                                                      >
                                                          <>
                                                              {oportunitiesValue.map((opportunity) => {
                                                                  if (opportunity.funnel_stages_id === stage.id) {
                                                                      countOpportunity++
                                                                      priceOpportunity += opportunity.totalValueMachines
                                                                  }
                                                              })}

                                                              {countOpportunity}
                                                          </>
                                                      </p>
                                                      <div className="p-d-flex p-jc-center  ">
                                                          <div
                                                              className="p-mb-2 p-as-center"
                                                              style={{
                                                                  width: "0.8rem",
                                                                  height: "0.8rem",
                                                                  borderRadius: "90%",
                                                                  marginRight: "0.2em",
                                                              }}
                                                          />
                                                      </div>
                                                      <br />
                                                      <div className="p-d-flex p-jc-center  ">
                                                          <div
                                                              className="p-mb-2 p-as-center"
                                                              style={{
                                                                  width: "0.8rem",
                                                                  height: "0.8rem",
                                                                  borderRadius: "90%",
                                                                  backgroundColor: "#03A9F4",
                                                                  marginRight: "0.2em",
                                                              }}
                                                          />
                                                          <div className="p-mb-2" style={{ fontSize: "1.8rem" }}>
                                                              {priceOpportunity.toLocaleString("pt-BR", {
                                                                  style: "currency",
                                                                  currency: "BRL",
                                                              })}
                                                          </div>
                                                      </div>
                                                  </div>
                                              </Card>
                                          )
                                      })
                                    : ""}
                            </div>
                        </div>

                        <div>
                            <div className="p-d-flex p-flex-column p-flex-md-row" style={{ marginTop: "" }}>
                                <Card style={{ width: "19.8vw", marginTop: "1vh", minWidth: "2rem" }} className="p-mr-2">
                                    <div style={{}}>
                                        <p
                                            style={{
                                                fontSize: "6.5rem",
                                                textAlign: "center",
                                                textDecoration: "bold",
                                                color: "#4d4d4d",
                                                fontWeight: "700",
                                                paddingTop: "-40px",
                                            }}
                                        >
                                            {oneStar}
                                        </p>
                                        <div className="p-d-flex p-jc-center  ">
                                            <div
                                                className="p-mb-2 p-as-center"
                                                style={{
                                                    width: "0.8rem",
                                                    height: "0.8rem",
                                                    borderRadius: "90%",
                                                    marginRight: "0.2em",
                                                }}
                                            />

                                            <Rating size={25} transition fillColor="#03A9F4" iconsCount={5} readonly={true} style={{ height: "2rem" }} />
                                        </div>
                                        <br />
                                        <div className="p-d-flex p-jc-center  ">
                                            <div
                                                className="p-mb-2 p-as-center"
                                                style={{
                                                    width: "0.8rem",
                                                    height: "0.8rem",
                                                    borderRadius: "90%",
                                                    backgroundColor: "#03A9F4",
                                                    marginRight: "0.2em",
                                                }}
                                            />
                                            <div className="p-mb-2" style={{ fontSize: "1.8rem" }}>
                                                {auxOneStar.toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </Card>

                                <Card style={{ width: "19.8vw", marginTop: "1vh", minWidth: "2rem" }} className="p-mr-2">
                                    <div style={{}}>
                                        <p
                                            style={{
                                                fontSize: "6.5rem",
                                                textAlign: "center",
                                                textDecoration: "bold",
                                                color: "#4d4d4d",
                                                fontWeight: "700",
                                                paddingTop: "-40px",
                                            }}
                                        >
                                            {twoStar}
                                        </p>
                                        <div className="p-d-flex p-jc-center  ">
                                            <div
                                                className="p-mb-2 p-as-center"
                                                style={{
                                                    width: "0.8rem",
                                                    height: "0.8rem",
                                                    borderRadius: "90%",
                                                    marginRight: "0.2em",
                                                }}
                                            />

                                            <Rating size={25} transition fillColor="#03A9F4" iconsCount={5} readonly={true} style={{ height: "2rem" }} />
                                        </div>
                                        <br />
                                        <div className="p-d-flex p-jc-center  ">
                                            <div
                                                className="p-mb-2 p-as-center"
                                                style={{
                                                    width: "0.8rem",
                                                    height: "0.8rem",
                                                    borderRadius: "90%",
                                                    backgroundColor: "#03A9F4",
                                                    marginRight: "0.2em",
                                                }}
                                            />
                                            <div className="p-mb-2" style={{ fontSize: "1.8rem" }}>
                                                {auxTwoStar.toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </Card>

                                <Card style={{ width: "19.8vw", marginTop: "1vh", minWidth: "15.8rem" }} className="p-mr-2">
                                    <div style={{}}>
                                        <p
                                            style={{
                                                fontSize: "6.5rem",
                                                textAlign: "center",
                                                textDecoration: "bold",
                                                color: "#4d4d4d",
                                                fontWeight: "700",
                                                paddingTop: "-40px",
                                            }}
                                        >
                                            {threeStar}
                                        </p>
                                        <div className="p-d-flex p-jc-center  ">
                                            <div
                                                className="p-mb-2 p-as-center"
                                                style={{
                                                    width: "0.8rem",
                                                    height: "0.8rem",
                                                    borderRadius: "90%",
                                                    marginRight: "0.2em",
                                                }}
                                            />

                                            <Rating size={25} transition fillColor="#03A9F4" iconsCount={5} readonly={true} style={{ height: "2rem" }} />
                                        </div>
                                        <br />
                                        <div className="p-d-flex p-jc-center  ">
                                            <div
                                                className="p-mb-2 p-as-center"
                                                style={{
                                                    width: "0.8rem",
                                                    height: "0.8rem",
                                                    borderRadius: "90%",
                                                    backgroundColor: "#03A9F4",
                                                    marginRight: "0.2em",
                                                }}
                                            />
                                            <div className="p-mb-2" style={{ fontSize: "1.8rem" }}>
                                                {auxThreeStar.toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </Card>

                                <Card style={{ width: "19.8vw", marginTop: "1vh", minWidth: "15.8rem" }} className="p-mr-2">
                                    <div style={{}}>
                                        <p
                                            style={{
                                                fontSize: "6.5rem",
                                                textAlign: "center",
                                                textDecoration: "bold",
                                                color: "#4d4d4d",
                                                fontWeight: "700",
                                                paddingTop: "-40px",
                                            }}
                                        >
                                            {" "}
                                            {fourStar}
                                        </p>
                                        <div className="p-d-flex p-jc-center  ">
                                            <div
                                                className="p-mb-2 p-as-center"
                                                style={{
                                                    width: "0.8rem",
                                                    height: "0.8rem",
                                                    borderRadius: "90%",
                                                    marginRight: "0.2em",
                                                }}
                                            />

                                            <Rating size={25} transition fillColor="#03A9F4" iconsCount={5} readonly={true} style={{ height: "2rem" }} />
                                        </div>
                                        <br />
                                        <div className="p-d-flex p-jc-center  ">
                                            <div
                                                className="p-mb-2 p-as-center"
                                                style={{
                                                    width: "0.8rem",
                                                    height: "0.8rem",
                                                    borderRadius: "90%",
                                                    backgroundColor: "#03A9F4",
                                                    marginRight: "0.2em",
                                                }}
                                            />
                                            <div className="p-mb-2" style={{ fontSize: "1.8rem" }}>
                                                {auxFourStar.toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </Card>

                                <Card style={{ width: "19.8vw", marginTop: "1vh", minWidth: "15.8rem" }} className="p-mr-2">
                                    <div style={{}}>
                                        <p
                                            style={{
                                                fontSize: "6.5rem",
                                                textAlign: "center",
                                                textDecoration: "bold",
                                                color: "#4d4d4d",
                                                fontWeight: "700",
                                                paddingTop: "-40px",
                                            }}
                                        >
                                            {" "}
                                            {fiveStar}{" "}
                                        </p>
                                        <div className="p-d-flex p-jc-center  ">
                                            <div
                                                className="p-mb-2 p-as-center"
                                                style={{
                                                    width: "0.8rem",
                                                    height: "0.8rem",
                                                    borderRadius: "90%",
                                                    marginRight: "0.2em",
                                                }}
                                            />

                                            <Rating size={25} transition fillColor="#03A9F4" iconsCount={5} readonly={true} style={{ height: "2rem" }} />
                                        </div>
                                        <br />
                                        <div className="p-d-flex p-jc-center  ">
                                            <div
                                                className="p-mb-2 p-as-center"
                                                style={{
                                                    width: "0.8rem",
                                                    height: "0.8rem",
                                                    borderRadius: "90%",
                                                    backgroundColor: "#03A9F4",
                                                    marginRight: "0.2em",
                                                }}
                                            />
                                            <div className="p-mb-2" style={{ fontSize: "1.8rem" }}>
                                                {auxFiveStar.toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })}
                                            </div>
                                        </div>
                                        <div className="p-d-flex p-jc-center"></div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                </TabPanel>
            </TabView>

            {/* HotKeys Dialog */}
            <Dialog
                visible={hotKeysDialog}
                style={{ width: "450px" }}
                modal
                className="p-fluid"
                onHide={hideDialog}
                header={`Atalhos - Tela de Produtos`}
                draggable={false}
            >
                <HotKeys />
            </Dialog>
        </App>
    )
}
