import React, { useRef, useState, useEffect } from "react"
import App from "../../layout/App"
import api from "../../services/api"
import Moment from "react-moment"
import classNames from "classnames"
import { Toast } from "primereact/toast"
import { Toolbar } from "primereact/toolbar"
import { Button } from "primereact/button"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { CrmRecords } from "../../components/crm/CrmRecords"
import { InputText } from "primereact/inputtext"
import { Dialog } from "primereact/dialog"
import { ConfirmDialog } from "primereact/confirmdialog"
import { useHotkeys } from "react-hotkeys-hook"
import { MediaInterface } from "../../interfaces/MediaInterface"
import { FilterMatchMode, FilterOperator } from "primereact/api"
import { HotKeys } from "../../components/crm/HotKeys"
import ReactTooltip from "react-tooltip"
import { set } from "date-fns"

export const DealsSources = () => {
    let token: string | null = localStorage.getItem("@esquadros:token")
    let toast = useRef<Toast>(null)
    api.defaults.headers.authorization = `Bearer ${token}`

    let [medias, setMedias] = useState<MediaInterface[]>([])
    let [media, setMedia] = useState<MediaInterface>({} as MediaInterface)
    let [createValues, setCreateValues] = useState<MediaInterface>({} as MediaInterface)
    let [submitted, setSubmitted] = useState<boolean>(false)

    //Loadings
    let [loadingDelete, setLoadingDelete] = useState<boolean>(false)
    let [loadingEdit, setLoadingEdit] = useState<boolean>(false)
    let [loadingList, setLoadingList] = useState<boolean>(true)
    let [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    //Dialogs
    let [deleteDialog, setDeleteDialog] = useState(false)
    let [hotKeysDialog, setHotKeysDialog] = useState(false)
    let [mediaDialog, setmediaDialog] = useState<boolean | undefined>(false)
    let [mediastEditDialog, setMediasEditDialog] = useState(false)

    //Filters
    let [globalFilterValue, setGlobalFilterValue] = useState("")
    let [auxMediaFiltered, setAuxMediaFiltered] = useState<MediaInterface[]>([])

    //HotKeys
    useHotkeys("shift+n", () => setmediaDialog(true))
    useHotkeys("shift+i", () => setHotKeysDialog(true))

    //Filters
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    })

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value
        let _filters = { ...filters }
        _filters["global"].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    const allMedias = async() => {
        try {
            let header = {
                headers: {
                    route: "list_medias",
                },
            }

            await api.get("medias", header)
                .then((res) => {
                    setLoadingList(false)
                    setMedias(res.data)
                    setAuxMediaFiltered(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const findIndexById = (id: string) => {
        let index = -1
        for (let i = 0; i < auxMediaFiltered.length; i++) {
            if (auxMediaFiltered[i].id === id) {
                index = i
                break
            }
        }

        return index
    }

    //Datatable Fields Formater
    const dateFormatCreate = (rowData: MediaInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.created_at}</Moment>
            </>
        )
    }

    const dateFormatUpdate = (rowData: MediaInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.updated_at}</Moment>
            </>
        )
    }

    //Buttons
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-user-plus" className="p-button" onClick={openNew} />
            </React.Fragment>
        )
    }

    const dataTableButtons = (rowData: MediaInterface) => {
        return (
            <div className="actions">
                <Button icon="pi pi-user-edit" className="p-button-rounded p-button" onClick={() => dialogEditMedia(rowData)} />
                &nbsp;&nbsp;
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => deleteMedia(rowData)} />
                &nbsp;&nbsp;
                <Button
                    data-tip
                    data-for="deleteInterests"
                    icon="pi pi-copy"
                    className="p-button-rounded p-button-warning"
                    onClick={() => {
                        navigator.clipboard.writeText(rowData.id)
                        toast.current?.show({
                            severity: "success",
                            summary: "Copiado!",
                            detail: "ID copiado com sucesso!",
                            life: 2000,
                        })
                    }}
                />
                <ReactTooltip className="toolTip" id="deleteInterests" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Copiar ID</p>
                </ReactTooltip>
            </div>
        )
    }

    //OnChange
    const onChangeCreateInterest = (event: any) => {
        const { value, name } = event.target

        setCreateValues({
            ...createValues,
            [name]: value,
        })
    }

    const onChangeEdit = (e: any, name: string) => {
        const val = (e.target && e.target.value) || ""
        let _interest = { ...media }

        switch (name) {
            case "name":
                _interest["name"] = val
                break

            default:
                break
        }

        setMedia(_interest)
    }

    const onSubitInterest = (event: any) => {
        setLoadingEdit(true)
        event.preventDefault()
        setSubmitted(false)

        try {
            const { name } = createValues
            if (name === "") {
                setLoadingEdit(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao cadastrar",
                    detail: "Por favor, verificar os dados preenchidos!",
                    life: 3000,
                })
            } else {
                let header = {
                    headers: {
                        route: "create_media",
                    },
                }

                api.post(
                    "medias",
                    {
                        name,
                    },
                    header
                )
                    .then((res) => {
                        if (res.status === 200) {
                            hideDialog()
                            setLoadingEdit(false)
                            setMedias((prevState) => [...prevState, res.data])
                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso",
                                detail: "Fonte Criada",
                                life: 3000,
                            })
                        } else {
                            setLoadingEdit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao cadastar!",
                                detail: "Por favor verifique os dados inseridos",
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingEdit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao cadastar!",
                            detail: "Por favor verifique os dados inseridos",
                            life: 3000,
                        })
                    })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmitEditDealLost = () => {
        setLoadingSubmit(true)
        try {
            const { name, id } = media

            let header = {
                headers: {
                    route: "create_media",
                },
            }

            let idAux = id

            api.put("medias", { id, name }, header)

                .then((res) => {
                    if (res.status === 200) {
                        setLoadingSubmit(false)

                        setMedias((prevState) =>
                            prevState.map((item) => {
                                if (item.id === idAux) {
                                    return res.data
                                }
                                return item
                            })
                        )


                        hideDialog()

                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na alteração",
                            detail: "Fonte alterada com sucesso!",
                            life: 3000,
                        })
                    } else {
                        setLoadingSubmit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Editar",
                            detail: "Por favor verifique os dados inserido",
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingSubmit(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao Editar",
                        detail: "Por favor verifique os dados inserido",
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const deleteMedia = (item: MediaInterface) => {
        setMedia(item)
        setDeleteDialog(true)
    }

    const onDeleteMedia = () => {
        setLoadingDelete(true)
        try {
            api.delete("medias", {
                headers: {
                    route: "delete_media",
                },
                data: {
                    id: media.id,
                },
            })

                .then((res) => {
                    setLoadingDelete(false)
                    if (res.status === 200) {
                        hideDialog()
                        setMedias(medias.filter((item) => media.id !== item.id))
                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na exclusão",
                            detail: "Fonte / Campanha excluído com sucesso!",
                            life: 3000,
                        })
                    } else {
                        setLoadingDelete(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao excluir!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingDelete(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao excluir!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const openNew = () => {
        setCreateValues({} as MediaInterface)
        setmediaDialog(true)
    }

    //Dialogs
    const dialogEditMedia = (segmemt: MediaInterface) => {
        setMedia(segmemt)
        setMediasEditDialog(true)
    }
    const deleteMessage = () => {
        return (
            <>
                {" "}
                Deseja realmente excluir a fonte de chegada: <strong>{media.name}</strong>
            </>
        )
    }

    const hideDialog = () => {
        setSubmitted(false)
        setmediaDialog(false)
        setMediasEditDialog(false)
        setDeleteDialog(false)
        setHotKeysDialog(false)
        setCreateValues({} as MediaInterface)
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Fontes de Chegada </h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Pesquisar..." />
            </span>
        </div>
    )

    //footers
    const interestDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingEdit} onClick={onSubitInterest} />
        </>
    )

    const mediaEditFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingSubmit} onClick={onSubmitEditDealLost} />
        </>
    )

    const deleteDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingDelete} onClick={onDeleteMedia} />
        </>
    )

    useEffect(() => {
        allMedias()
    }, [])

    return (
        <App>
            <CrmRecords />

            {/* DataTable */}
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}></Toolbar>

                        <DataTable
                            value={medias}
                            loading={loadingList}
                            stripedRows
                            selectionMode="checkbox"
                            dataKey="id"
                            rows={10}
                            rowsPerPageOptions={[10, 15, 20]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} fontes de chegada"
                            paginator
                            header={header}
                            filters={filters}
                            globalFilterFields={["name"]}
                            emptyMessage="Não há fontes de chegada"
                            responsiveLayout="scroll"
                        >
                            <Column field="name" header="Fonte" sortable align="center" alignHeader="center" />
                            <Column body={dateFormatCreate} header="Data de Criação" sortable sortField="created_at" align="center" alignHeader="center" />
                            <Column body={dateFormatUpdate} sortField="update_at" header="Data de Atualização" sortable align="center" alignHeader="center" />
                            <Column align="center" alignHeader="center" body={dataTableButtons}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

            {/* Create Method */}
            <Dialog
                visible={mediaDialog}
                style={{ width: "450px" }}
                header="Adicionar Fonte"
                modal
                draggable={false}
                className="p-fluid"
                footer={interestDialogFooter}
                onHide={hideDialog}
            >
                <div className="p-field">
                    <label htmlFor="name">Nome</label>
                    <InputText
                        id="name"
                        name="name"
                        required
                        maxLength={45}
                        style={{ minWidth: "20rem" }}
                        className={
                            "p-field p-text-capitalize " +
                            classNames({
                                "p-invalid": submitted && createValues.name === "",
                            })
                        }
                        value={createValues.name || ""}
                        onChange={onChangeCreateInterest}
                    />
                </div>
            </Dialog>

            {/* Update Method */}
            <Dialog
                visible={mediastEditDialog}
                style={{ width: "450px" }}
                header={`Editar Fonte de Chegada`}
                modal
                className="p-fluid"
                footer={mediaEditFooter}
                draggable={false}
                onHide={hideDialog}
            >
                <div className="p-field">
                    <label htmlFor="name">Nome</label>
                    <InputText
                        style={{ minWidth: "20rem" }}
                        id="name"
                        name="name"
                        maxLength={120}
                        onChange={(e: any) => onChangeEdit(e, "name")}
                        value={media.name}
                    />
                </div>
            </Dialog>

            {/* Delete Method */}
            <ConfirmDialog
                style={{ width: "400px", borderRadius: "16px" }}
                draggable={false}
                visible={deleteDialog}
                onHide={hideDialog}
                message={deleteMessage}
                acceptLabel={"Sim"}
                rejectLabel={"Não"}
                header="Confirmação de exclusão"
                icon="pi pi-exclamation-triangle"
                accept={onDeleteMedia}
                acceptIcon="pi pi-check"
                rejectIcon="pi pi-times"
                reject={hideDialog}
                dismissableMask={true}
                footer={deleteDialogFooter}
            />
            {/* HotKeys Dialog */}
            <Dialog
                visible={hotKeysDialog}
                style={{ width: "450px" }}
                modal
                className="p-fluid"
                onHide={hideDialog}
                header={`Atalhos - Tela de Fontes`}
                draggable={false}
            >
                <HotKeys create={"Criar uma nova Fonte de Chegada"} />
            </Dialog>
        </App>
    )
}
