import moment from "moment"
import { useEffect, useRef, useState } from "react"
import App from "../../layout/App"
import api from "../../services/api"
import "../../styles/ToAnalyze.scss"

//Components PrimeReact
import { Card } from "primereact/card"
import { Chart } from "primereact/chart"
import { Dropdown } from "primereact/dropdown"
import { SelectButton } from "primereact/selectbutton"
import { Skeleton } from "primereact/skeleton"
import { Toast } from "primereact/toast"

//Interfaces
import { FunnelInterface } from "../../interfaces/FunnelInterface"
import { MachineInterface } from "../../interfaces/MachineInterface"
import { MediaInterface } from "../../interfaces/MediaInterface"
import { OpportunityInterface } from "../../interfaces/OpportunityInterface"
import { UserInterface } from "../../interfaces/UserInterface"

interface DatasetInterface {
    label: string
    data: number[]
    fill: boolean
    tension: number
    borderColor: string
}

interface LineStylesDataInterface {
    labels: string[]
    datasets: DatasetInterface[]
}

interface PeriodInterface {
    name: string
    type: "hour" | "day" | "week" | "month" | "year"
    hours: number
    chartPeriod: number
    format: string
}

let opportunities: OpportunityInterface[]
let filteredOpportunities: OpportunityInterface[]

export const ToAnalyze = () => {
    let toast = useRef<Toast>(null)
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    let [funnels, setFunnels] = useState<FunnelInterface[]>([])
    let [selectedFunnel, setSelectedFunnel] = useState<FunnelInterface | null>(null)
    let [periods, setPeriods] = useState<PeriodInterface[]>([])
    let [selectedPeriod, setSelectedPeriod] = useState<PeriodInterface>({} as PeriodInterface)
    let [users, setUsers] = useState<UserInterface[]>([])
    let [selectedUser, setSelectedUser] = useState<UserInterface | null>(null)
    let [medias, setMedias] = useState<MediaInterface[]>([])
    let [selectedMedia, setSelectedMedia] = useState<MediaInterface | null>(null)
    let [machines, setMachines] = useState<MachineInterface[]>([])
    let [selectedMachine, setSelectedMachine] = useState<MachineInterface | null>(null)
    let [createdOpportunities, setCreatedOpportunities] = useState<number>({} as number)
    let [createdOrders, setCreatedOrders] = useState<number>({} as number)
    let [lossedOpportunities, setLossedOpportunities] = useState<number>({} as number)

    // Reports
    let [lineStylesData, setLineStylesData] = useState<LineStylesDataInterface | null>(null)
    let [chartLineLabels, setChartLineLabels] = useState<string[]>([])
    let [chartLineDatasets, setChartLineDatasets] = useState<DatasetInterface[]>([])

    //Get methods
    const allMachines = async () => {
        try {
            let header = {
                headers: {
                    route: "list_machines",
                },
            }

            await api
                .get("machines/505", header)
                .then((res) => {
                    setMachines(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const allUsers = async () => {
        try {
            let header = {
                headers: {
                    route: "list_users",
                },
            }

            await api
                .get("users", header)
                .then((res) => {
                    setUsers(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const allMedias = async () => {
        try {
            let header = {
                headers: {
                    route: "list_medias",
                },
            }

            await api
                .get("medias", header)
                .then((res) => {
                    setMedias(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const allFunnels = async () => {
        try {
            let header = {
                headers: {
                    route: "list_funnels",
                },
            }

            await api
                .get("funnels", header)
                .then((res) => {
                    setFunnels(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const allOpportunities = async () => {
        let header = {
            headers: {
                route: "list_opportunities_full",
            },
        }

        try {
            const response = await api.get("opportunities/full", header)
            opportunities = response.data
            filteredOpportunities = opportunities
            filter()
            generateReports()
        } catch (error: any) {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar oportunidade!",
                detail: error.response.data.message,
                life: 3000,
            })
        }
    }

    const allPeriods = () => {
        let listPeriods: PeriodInterface[] = [
            { name: "Hora", type: "hour", hours: 1, chartPeriod: 0.0166666666666667, format: "HH:mm:ss" },
            { name: "Dia", type: "day", hours: 24, chartPeriod: 1, format: "HH:mm" },
            { name: "Semana", type: "week", hours: 168, chartPeriod: 7, format: "DD/MM HH:mm" },
            { name: "Mês", type: "month", hours: 720, chartPeriod: 30, format: "DD/MM/yyyy" },
            { name: "Ano", type: "year", hours: 8760, chartPeriod: 365, format: "MM/yyyy" },
        ]

        periods = listPeriods
        setPeriods(listPeriods)

        selectedPeriod = periods[1]
        setSelectedPeriod(listPeriods[1])
    }

    //Filters
    const filter = () => {
        // Filtros de Funil
        const funnel_id = selectedFunnel ? selectedFunnel.id : null

        // Filtros de Fonte
        const media_id = selectedMedia ? selectedMedia.id : null

        // Filtros de Produtos
        const machine_code = selectedMachine ? selectedMachine.id : null

        // Filtro do Responsável
        const user_id = selectedUser ? selectedUser.id : null

        // Filtros de Data
        let startDate = new Date()
        startDate.setTime(startDate.getTime() - selectedPeriod.hours * 3600000)

        filteredOpportunities = []

        for (let opportunity of opportunities) {
            let addLine = true

            if (funnel_id) {
                if (funnel_id !== opportunity.funnelStage.funnel_id) {
                    addLine = false
                }
            }

            if (addLine && media_id) {
                if (media_id !== opportunity.media_id) {
                    addLine = false
                }
            }

            if (addLine && machine_code) {
                if (opportunity.machines) {
                    let existMachineOnMachines = false
                    for (let machine of opportunity.machines) {
                        if (machine.code === machine_code) {
                            existMachineOnMachines = true
                            break
                        }
                    }
                    if (!existMachineOnMachines) {
                        addLine = false
                    }
                } else {
                    addLine = false
                }
            }

            if (addLine && user_id) {
                if (user_id !== opportunity.user_id) {
                    addLine = false
                }
            }

            if (addLine && startDate) {
                if (new Date(opportunity.created_at) <= startDate) {
                    addLine = false
                }
            }

            if (addLine) {
                filteredOpportunities.push(opportunity)
            }
        }
    }

    const generateReports = () => {
        setLineStylesData(null)

        // Criadas Ganhas Perdidas
        createdOpportunities = 0
        createdOrders = 0
        lossedOpportunities = 0

        let createdOpportunitiesByPeriod = 0
        let createdOrdersByPeriod = 0
        let lossedOpportunitiesByPeriod = 0
        let atualDate = null

        chartLineLabels = []
        chartLineDatasets = []

        let dataCreatedOpportunities: number[] = []
        let dataCreatedOrders: number[] = []
        let dataLossedOpportunities: number[] = []

        for (let opportunity of filteredOpportunities) {
            if (!atualDate) {
                atualDate = opportunity.created_at
                createdOpportunitiesByPeriod = 0
                createdOrdersByPeriod = 0
                createdOpportunitiesByPeriod = 0
            }

            const dateDiff = moment(opportunity.created_at).diff(moment(atualDate))

            if (moment.duration(dateDiff).asHours() > selectedPeriod.chartPeriod) {
                dataCreatedOpportunities.push(createdOpportunitiesByPeriod)
                dataCreatedOrders.push(createdOrdersByPeriod)
                dataLossedOpportunities.push(lossedOpportunitiesByPeriod)

                chartLineLabels.push(moment(atualDate).format(selectedPeriod.format))

                atualDate = opportunity.created_at
                createdOpportunitiesByPeriod = 0
                createdOrdersByPeriod = 0
                createdOpportunitiesByPeriod = 0
            }

            createdOpportunities++
            createdOpportunitiesByPeriod++

            if (opportunity.proposals) {
                for (let proposal of opportunity.proposals) {
                    if (proposal.order) {
                        createdOrders++
                        createdOrdersByPeriod++
                        break
                    }
                }
            }

            if (opportunity.status === "loss") {
                lossedOpportunities++
                lossedOpportunitiesByPeriod++
            }
        }

        chartLineDatasets.push({
            label: "Oportunidades Criadas",
            data: dataCreatedOpportunities,
            fill: false,
            tension: 0.4,
            borderColor: "#03A9F4",
        })

        chartLineDatasets.push({
            label: "Vendas",
            data: dataCreatedOrders,
            fill: false,
            tension: 0.4,
            borderColor: "#1BBE83",
        })

        chartLineDatasets.push({
            label: "Oportunidades Perdidas",
            data: dataLossedOpportunities,
            fill: false,
            tension: 0.4,
            borderColor: "#E34A4A",
        })

        setChartLineLabels(chartLineLabels)
        setChartLineDatasets(chartLineDatasets)
        setLineStylesData({
            datasets: chartLineDatasets,
            labels: chartLineLabels,
        })

        setCreatedOpportunities(createdOpportunities)
        setCreatedOrders(createdOrders)
        setLossedOpportunities(lossedOpportunities)
    }

    const basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: 1,
        plugins: {
            legend: {
                labels: { color: "#495057" },
            },
        },
        scales: {
            x: {
                ticks: { color: "#495057" },
                grid: { color: "#ebedef" },
            },
            y: {
                ticks: { color: "#495057" },
                grid: { color: "#ebedef" },
            },
        },
    }

    //UseEffect
    useEffect(() => {
        allFunnels()
        allUsers()
        allMedias()
        allMachines()
        allPeriods()
        allOpportunities()
    }, [])

    return (
        <App>
            <Toast ref={toast} />
            <div className="filters card">
                <div className="leftFilters">
                    <Dropdown
                        className="filter"
                        id="listFunnelsdw"
                        value={selectedFunnel}
                        options={funnels}
                        emptyMessage="Não há dados"
                        emptyFilterMessage="Não há dados"
                        onChange={(e) => {
                            setSelectedFunnel(e.value)
                            selectedFunnel = e.value
                            filter()
                            generateReports()
                        }}
                        filter={true}
                        optionLabel="name"
                        placeholder="Funil de Vendas"
                        showClear
                    />
                    <Dropdown
                        className="filter"
                        id="listUsersdw"
                        value={selectedUser}
                        options={users}
                        emptyMessage="Não há dados"
                        emptyFilterMessage="Não há dados"
                        onChange={(e) => {
                            setSelectedUser(e.value)
                            selectedUser = e.value
                            filter()
                            generateReports()
                        }}
                        filter={true}
                        optionLabel="name"
                        placeholder="Vendedor"
                        showClear
                    />
                    <Dropdown
                        className="filter"
                        id="listMediasdw"
                        value={selectedMedia}
                        options={medias}
                        emptyMessage="Não há dados"
                        emptyFilterMessage="Não há dados"
                        onChange={(e) => {
                            setSelectedMedia(e.value)
                            selectedMedia = e.value
                            filter()
                            generateReports()
                        }}
                        filter={true}
                        optionLabel="name"
                        placeholder="Mídia"
                        showClear
                    />
                    <Dropdown
                        className="filter"
                        id="listMachinesdw"
                        value={selectedMachine}
                        options={machines}
                        emptyMessage="Não há dados"
                        emptyFilterMessage="Não há dados"
                        onChange={(e) => {
                            setSelectedMachine(e.value)
                            selectedMachine = e.value
                            filter()
                            generateReports()
                        }}
                        filter={true}
                        optionLabel="id"
                        placeholder="Produto"
                        showClear
                    />
                </div>

                <SelectButton
                    className="rigthFilter"
                    id="listPeriodsSB"
                    value={selectedPeriod}
                    options={periods}
                    onChange={(e) => {
                        setSelectedPeriod(e.value)
                        selectedPeriod = e.value
                        filter()
                        generateReports()
                    }}
                    optionLabel="name"
                />
            </div>

            <div
                className="card"
                style={{
                    marginTop: "1rem",
                    height: "auto",
                }}
            >
                {!lineStylesData && (
                    <div style={{ width: "100%" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <Skeleton width="32%" height="110px" />
                            <Skeleton width="32%" height="110px" />
                            <Skeleton width="32%" height="110px" />
                        </div>
                        <br />
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: "-10px" }}>
                            <Skeleton width="2%" height="20px" />
                            <div style={{ width: "3px" }}></div>
                            <Skeleton width="12%" height="20px" />
                            <div style={{ width: "10px" }}></div>
                            <Skeleton width="2%" height="20px" />
                            <div style={{ width: "3px" }}></div>
                            <Skeleton width="12%" height="20px" />
                            <div style={{ width: "10px" }}></div>
                            <Skeleton width="2%" height="20px" />
                            <div style={{ width: "3px" }}></div>
                            <Skeleton width="12%" height="20px" />
                            <div style={{ width: "10px" }}></div>
                        </div>
                        <br />
                        <Skeleton width="100%" height="265px" />
                    </div>
                )}

                {lineStylesData && (
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6 p-lg-4">
                            <Card>
                                <p
                                    className="m-0"
                                    style={{
                                        marginTop: "-1rem",
                                        fontSize: "1rem",
                                        color: "#03A9F4",
                                    }}
                                >
                                    Oportunidades Criadas
                                </p>
                                <strong
                                    className="m-0"
                                    style={{
                                        lineHeight: "1.5",
                                        fontSize: "1.5rem",
                                    }}
                                >
                                    {createdOpportunities | 0}
                                </strong>
                            </Card>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-4">
                            <Card>
                                <p
                                    className="m-0"
                                    style={{
                                        marginTop: "-1rem",
                                        fontSize: "1rem",
                                        color: "#1BBE83",
                                    }}
                                >
                                    Vendas
                                </p>
                                <strong
                                    className="m-0"
                                    style={{
                                        lineHeight: "1.5",
                                        fontSize: "1.5rem",
                                    }}
                                >
                                    {createdOrders | 0}
                                </strong>
                            </Card>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-4">
                            <Card>
                                <p
                                    className="m-0"
                                    style={{
                                        marginTop: "-1rem",
                                        fontSize: "1rem",
                                        color: "#E34A4A",
                                    }}
                                >
                                    Oportunidades Perdidas
                                </p>
                                <strong
                                    className="m-0"
                                    style={{
                                        lineHeight: "1.5",
                                        fontSize: "1.5rem",
                                    }}
                                >
                                    {lossedOpportunities | 0}
                                </strong>
                            </Card>
                        </div>

                        <Chart type="line" data={lineStylesData} options={basicOptions} style={{ width: "95vw" }} />
                    </div>
                )}

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "auto",
                    }}
                ></div>
            </div>
            <div
                style={{
                    height: "5vw",
                    minHeight: "10vh",
                    display: "grid",
                    justifyContent: "center",
                    backgroundColor: "#fafafa",
                    alignItems: "center",
                    marginTop: "-1.5rem",
                }}
            >
                <p
                    style={{
                        fontWeight: "900",
                        fontSize: "1.1rem",
                        color: "#606060",
                    }}
                >
                    Valor x Volume
                </p>
            </div>
            <div
                className="card"
                style={{
                    marginTop: "0.5rem",
                }}
            >
                <div className="p-d-flex p-jc-center"></div>
                <div
                    className="p-grid"
                    style={{
                        marginTop: "0.2rem",
                    }}
                >
                    <div className="p-col-12 p-md-6 p-lg-4">
                        <Card>
                            <p
                                className="m-0"
                                style={{
                                    marginTop: "-1rem",
                                    fontSize: "1rem",
                                    color: "#1BBE83",
                                }}
                            >
                                Valor Total
                            </p>
                            <strong
                                className="m-0"
                                style={{
                                    lineHeight: "1.5",
                                    fontSize: "1.5rem",
                                }}
                            >
                                1
                            </strong>
                        </Card>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-4">
                        <Card>
                            <p
                                className="m-0"
                                style={{
                                    marginTop: "-1rem",
                                    fontSize: "1rem",
                                    color: "#1BBE83",
                                }}
                            >
                                Unidades
                            </p>
                            <strong
                                className="m-0"
                                style={{
                                    lineHeight: "1.5",
                                    fontSize: "1.5rem",
                                }}
                            >
                                0
                            </strong>
                        </Card>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-4">
                        <Card>
                            <p
                                className="m-0"
                                style={{
                                    marginTop: "-1rem",
                                    fontSize: "1rem",
                                    color: "#1BBE83",
                                }}
                            >
                                Ticket Médio
                            </p>
                            <strong
                                className="m-0"
                                style={{
                                    lineHeight: "1.5",
                                    fontSize: "1.5rem",
                                }}
                            >
                                1
                            </strong>
                        </Card>
                    </div>
                    {/* <Chart type="line" data={lineStylesData} options={basicOptions} style={{ width: "95vw" }} /> */}
                </div>
            </div>
            <div
                style={{
                    height: "5vw",
                    minHeight: "10vh",
                    display: "grid",
                    justifyContent: "center",
                    backgroundColor: "#fafafa",
                    width: "100%",
                    alignItems: "center",
                    marginTop: "-1.5rem",
                }}
            >
                <p
                    style={{
                        fontWeight: "900",
                        fontSize: "1.1rem",
                        color: "#606060",
                    }}
                >
                    Gráficos de desempenho
                </p>
            </div>

            <div
                className="card"
                style={{
                    marginTop: "0.6rem",
                }}
            >
                <p
                    style={{
                        textAlign: "center",
                        fontWeight: "900",
                        fontSize: "1.1rem",
                        color: "#606060",
                    }}
                >
                    Visão Geral
                </p>

                <div className="p-d-flex p-jc-between">
                    <div className="p-mr-2 p-mb-2">
                        <div
                            style={{
                                height: "3.5rem",
                                backgroundColor: "#f9f9f9",
                                width: "45vw",
                                marginTop: "1rem",
                            }}
                        >
                            <div className="p-d-flex p-jc-between">
                                <p
                                    className="p-mr-2"
                                    style={{
                                        marginTop: "1rem",
                                        marginLeft: "1rem",
                                    }}
                                >
                                    Sem Próxima Tarefa
                                </p>
                                <p
                                    className="p-mr-2"
                                    style={{
                                        marginTop: "1rem",
                                        fontWeight: "900",
                                        fontSize: "1.2rem",
                                    }}
                                >
                                    0
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: "3.5rem",
                                backgroundColor: "#f9f9f9",
                                width: "45vw",
                                marginTop: "1rem",
                            }}
                        >
                            <div className="p-d-flex p-jc-between">
                                <p
                                    className="p-mr-2"
                                    style={{
                                        marginTop: "1rem",
                                        marginLeft: "1rem",
                                    }}
                                >
                                    Sem Interações
                                </p>
                                <p
                                    className="p-mr-2"
                                    style={{
                                        marginTop: "1vh",
                                        fontWeight: "900",
                                        fontSize: "1.2rem",
                                    }}
                                >
                                    0
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: "3.5rem",
                                backgroundColor: "#f9f9f9",
                                width: "45vw",
                                marginTop: "1rem",
                            }}
                        >
                            <div className="p-d-flex p-jc-between">
                                <p
                                    className="p-mr-2"
                                    style={{
                                        marginTop: "1rem",
                                        marginLeft: "1rem",
                                    }}
                                >
                                    Recém Alocadas
                                </p>
                                <p
                                    className="p-mr-2"
                                    style={{
                                        marginTop: "1vh",
                                        fontWeight: "900",
                                        fontSize: "1.2rem",
                                    }}
                                >
                                    0
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <Chart type="line" data={lineStylesData} options={basicOptions} style={{ position: "relative", width: "45vw" }} /> */}
                </div>
            </div>
        </App>
    )
}
