import React, { useRef, useEffect } from "react"
import classNames from "classnames"
import AppMenu from "./AppMenu"

import { useHistory } from "react-router-dom"
import { Avatar } from "primereact/avatar"
import { useAuth } from "../hooks/AuthContext"
import { Button } from "primereact/button"
import { Badge } from "primereact/badge"
import { Toast } from "primereact/toast"

const AppTopbar = (props) => {
    let toast = useRef(null)
    let history = useHistory()

    let searchPanelDesktop = useRef(null)
    let searchPanelMobile = useRef(null)
    let { user, signOut, validateSession } = useAuth()

    const avatar = () => {
        if (user.avatar != null) {
            let avatar = <Avatar image={user.avatar_url} className="p-mr-2" size="large" shape="circle" />
            return avatar
        } else {
            let avatar = <Avatar icon="pi pi-user" className="p-mr-2" size="large" shape="circle" />
            return avatar
        }
    }

    useEffect(() => {
        if (props.searchActive) {
            if (window.innerWidth >= 576) searchPanelDesktop.current.focus()
            else searchPanelMobile.current.focus()
        }

        validateSession()
    }, [props.searchActive, validateSession])

    return (
        <>
            <Toast ref={toast} />

            <div className="layout-topbar">
                <div className="layout-topbar-wrapper">
                    <div className="layout-topbar-left">
                        <button tabIndex="0" className="menu-button p-link" onClick={props.onMenuButtonClick}>
                            <i className="pi pi-bars"></i>
                        </button>

                        <button tabIndex="0" id="logo-link" onClick={() => history.push("/crm/oportunidades")} className="layout-topbar-logo p-link">
                            <img src={`/assets/layout/images/${props.topbarScheme === "dark" ? "esq-white-logo" : "esq-black-logo"}.svg`} alt="Esquadros" />
                        </button>
                    </div>

                    <AppMenu
                        menuMode={props.menuMode}
                        sidebarActive={props.sidebarActive}
                        sidebarStatic={props.sidebarStatic}
                        model={props.menu}
                        menuActive={props.menuActive}
                        onRootMenuItemClick={props.onRootMenuItemClick}
                        onMobileMenuActive={props.onMobileMenuActive}
                        onMenuItemClick={props.onMenuItemClick}
                        onSidebarMouseOver={props.onSidebarMouseOver}
                        onSidebarMouseLeave={props.onSidebarMouseLeave}
                        onToggleMenu={props.onToggleMenu}
                        resetActiveIndex={props.resetActiveIndex}
                        onMenuClick={props.onMenuClick}
                    />

                    <div className="layout-topbar-right">
                        <ul className="layout-topbar-actions">
                            <li>
                                {user.whatsapp_phone_number && (
                                    <a href="/crm/atendimento">
                                        <button tabIndex="0" style={{ cursor: "pointer" }} className="layout-rightpanel-button p-link">
                                            <i
                                                className="pi pi-whatsapp mr-4 p-text-secondary p-overlay-badge"
                                                style={{ fontSize: "1.6rem", color: "white" }}
                                            ></i>
                                        </button>
                                    </a>
                                )}
                                {user.whatsapp_phone_number === null && (
                                    <div
                                        onClick={() =>
                                            toast.current?.show({
                                                severity: "warn",
                                                summary: "Erro !",
                                                detail: "Você não possui um número de whatsapp cadastrado",
                                                life: 3000,
                                            })
                                        }
                                    >
                                        <button tabIndex="0" style={{ cursor: "pointer" }} className="layout-rightpanel-button p-link">
                                            <i
                                                className="pi pi-whatsapp mr-4 p-text-secondary p-overlay-badge"
                                                style={{ fontSize: "1.6rem", color: "white" }}
                                            ></i>
                                        </button>
                                    </div>
                                )}
                            </li>
                            <li>
                                <button
                                    tabIndex="0"
                                    style={{ cursor: "pointer" }}
                                    className="layout-rightpanel-button p-link"
                                    onClick={props.onRightPanelButtonClick}
                                >
                                    <i className="pi pi-bell mr-4 p-text-secondary p-overlay-badge" style={{ fontSize: "1.6rem", color: "white" }}>
                                        {props.notifications.length === 0 && props.notifications.length !== null ? (
                                            ""
                                        ) : (
                                            <Badge value={props.notifications.length} className="mr-2"></Badge>
                                        )}
                                    </i>
                                </button>
                            </li>

                            <li
                                className={classNames("topbar-item user-profile", {
                                    "active-topmenuitem fadeInDown": props.topbarUserMenuActive,
                                })}
                            >
                                <button className="p-link" style={{ cursor: "pointer" }} onClick={props.onTopbarUserMenuClick}>
                                    {avatar()}
                                </button>

                                <ul className="fadeInDown">
                                    <li onClick={() => history.push("/perfil")}>
                                        <Button label={user.name} icon="pi pi-user" className="p-button-text" />
                                    </li>

                                    <li onClick={signOut}>
                                        <Button label="Sair" icon="pi pi-sign-out" className="p-button-text" />
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AppTopbar
