
import React, { useState, useEffect, useRef } from "react"
import classNames from "classnames"
import Moment from "react-moment"
import App from "../../layout/App"
import api from "../../services/api"

//Prime React
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { FilterMatchMode, FilterOperator } from "primereact/api"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import { Toast } from "primereact/toast"
import { Toolbar } from "primereact/toolbar"

import ReactTooltip from "react-tooltip"

interface DataGroups {
    id: string
    name: string
    created_at: Date | null
    updated_at: Date | null
}

const dataGroups: DataGroups = {
    id: "",
    name: "",
    created_at: null,
    updated_at: null,
}

interface Permission {
    route_id: string
    name: string
    description: string
    status: boolean
    have_permissions: boolean
    group_id: string
}

export const CrudGroups: React.FC = () => {
    let toast = useRef<Toast>(null)
    let token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    //Primeiro a várivael | Depois a função para atualizar a váriavel
    let [groups, setGroups] = useState<DataGroups[]>([])
    let [group, setGroup] = useState<DataGroups>(dataGroups)
    let [groupsDialog, setGroupsDialog] = useState<boolean>(false)
    let [groupsDialogEdit, setGroupsDialogEdit] = useState<boolean>(false)

    let [createValues, setCreateValues] = useState<DataGroups>(dataGroups)
    let [submitted, setSubmitted] = useState<boolean>(false)

    let [loadingList, setLoadingList] = useState<boolean>(true)
    let [loadingRoutines, setLoadingRoutines] = useState<boolean>(true)
    let [loadingCreate, setLoadingCreate] = useState<boolean>(false)
    let [loadingEdit, setLoadingEdit] = useState<boolean>(false)

    let [permissions, setPermissions] = useState<Permission[]>([])
    let [groupPermissionDialog, setGroupPermissionDialog] = useState<boolean>(false)

    let [globalFilterPermissions, setGlobalFilterPermissions] = useState<string | null>(null)
    let [auxGroupsFiltered, setAuxGroupsFiltered] = useState<DataGroups[]>([])

    let [globalFilterValue, setGlobalFilterValue] = useState("")
    let [globalFilterGroupsValue, setGlobalFilterGroupsValue] = useState("")

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    })

    const [filtersGroups, setFiltersGroups] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        description: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    })

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value
        let _filters = { ...filters }
        _filters["global"].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    const onGlobalFilterGroupsChange = (e: any) => {
        const value = e.target.value
        let _filters = { ...filtersGroups }
        _filters["global"].value = value

        setFiltersGroups(_filters)
        setGlobalFilterGroupsValue(value)
    }

    const allGroups = async () => {
        try {
            const header = {
                headers: {
                    route: "list_groups",
                },
            }

            const response = await api.get("groups", header)
            const groupData = response.data

            setLoadingList(false)
            setGroups(groupData)
            setAuxGroupsFiltered(groupData)
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao listar!",
                    detail: error.response.data.message,
                    life: 3000,
                })
            } else {
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao listar!",
                    detail: "Erro desconhecido ao obter a lista de grupos.",
                    life: 3000,
                })
            }
        }
    }

    // Post Create Groups
    const onSubmitCreateGroups = (event: any) => {
        event.preventDefault()
        setSubmitted(false)
        setLoadingCreate(true)

        try {
            const { name } = createValues

            if (name === "") {
                setLoadingCreate(false)
                setSubmitted(true)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao cadastrar",
                    detail: "Por favor, verificar os dados preenchidos!",
                    life: 3000,
                })
            } else {
                let header = {
                    headers: {
                        route: "create_group",
                    },
                }

                api.post(
                    "groups",
                    {
                        name,
                    },
                    header
                )
                    .then((res) => {
                        if (res.status === 200) {
                            setLoadingCreate(false)
                            hideDialog()
                            setCreateValues(dataGroups)
                            setGroups([...groups, res.data])

                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso",
                                detail: "Grupo Criado",
                                life: 3000,
                            })
                        } else {
                            setLoadingCreate(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao cadastar!",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingCreate(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao cadastar!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            }
        } catch (error) {
            console.log(error)
        }
    }

    //Update groups
    const onSubmitEditGroup = (event: any) => {
        event.preventDefault()
        setSubmitted(false)
        setLoadingEdit(true)

        try {
            const { name, id } = group
            let idAux = id
            if (id === "") {
                setSubmitted(true)
                setLoadingEdit(false)
                toast.current?.show({
                    severity: "error",
                    summary: "Erro ao Editar",
                    detail: "Preencha todos os campos para continuar!",
                    life: 3000,
                })
            } else {
                let header = {
                    headers: {
                        route: "update_group",
                    },
                }

                api.put(
                    "groups",
                    {
                        id,
                        name,
                    },
                    header
                )
                    .then((res) => {
                        if (res.status === 200) {
                            setLoadingEdit(false)
                            setGroups((prevState) =>
                                prevState.map((item) => {
                                    if (item.id === idAux) {
                                        return res.data
                                    }
                                    return item
                                })
                            )

                            hideDialog()

                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso na alteração",
                                detail: "Grupo alterado com sucesso!",
                                life: 3000,
                            })
                        } else {
                            setLoadingEdit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao Editar",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingEdit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Editar",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const openNew = () => {
        setGroupsDialog(true)
    }

    const hideDialog = () => {
        setCreateValues(dataGroups)
        setSubmitted(false)
        setGroupsDialog(false)
        setGroupsDialogEdit(false)
        setGroupPermissionDialog(false)
        setGlobalFilterPermissions(null)
    }

    const hideDialogEditGroups = () => {
        setGroupsDialogEdit(false)
    }

    const activeInactivePermission = (rowData: any) => {
        return (
            <>
                <InputSwitch checked={rowData.have_permissions} onChange={() => updatePermissionGroup(rowData)} />
            </>
        )
    }

    const groupsEditDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialogEditGroups} />

            <Button label="Salvar" loading={loadingEdit} icon="pi pi-check" className="p-button-success p-button-text" onClick={onSubmitEditGroup} />
        </>
    )

    const findIndexByIdPermission = (id: string) => {
        let index = -1
        for (let i = 0; i < permissions.length; i++) {
            if (permissions[i].route_id === id) {
                index = i
                break
            }
        }

        return index
    }

    const headerUpdatePermission = (
        <div className="table-header">
            <span className="p-input-icon-left p-mr-2">
                <i className="pi pi-search" />
                <InputText value={globalFilterGroupsValue} onChange={onGlobalFilterGroupsChange} placeholder="Pesquisar..." />
            </span>
        </div>
    )

    const groupsCreateDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingCreate} onClick={onSubmitCreateGroups} />
        </>
    )

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-user-edit"
                    data-tip
                    data-for="editGroups"
                    className="p-button-rounded p-button p-mr-2"
                    onClick={() => editGroups(rowData)}
                />
                <ReactTooltip className="toolTip" id="editGroups" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Editar Grupo</p>
                </ReactTooltip>
                <Button
                    icon="pi pi-sliders-v"
                    data-tip
                    data-for="actionPermissionGroup"
                    className="p-button-rounded p-button-success"
                    onClick={() => actionPermissionGroup(rowData)}
                />
                <ReactTooltip className="toolTip" id="actionPermissionGroup" place="right" effect="solid">
                    <p style={{ fontSize: "1rem", fontWeight: "400" }}>Permissões do Grupo</p>
                </ReactTooltip>
            </div>
        )
    }

    const actionPermissionGroup = (group: DataGroups) => {
        setGroup({ ...group })

        try {
            let header = {
                headers: {
                    route: "show_group_routes_with_routes",
                },
            }

            api.get(`permission_group/routes/${group.id}`, header)
                .then((res) => {
                    setLoadingRoutines(false)
                    let permissionsAll: Permission[] = new Array()
                    res.data.forEach((p: any) => {
                        permissionsAll.push({
                            route_id: p.route.id,
                            group_id: group.id,
                            name: p.route.name,
                            description: p.route.description,
                            status: p.route.status,
                            have_permissions: p.havePermission,
                        })
                    })
                    setPermissions(permissionsAll)
                })
                .catch((error) => {})
        } catch (error) {
            console.log(error)
        }

        setGroupPermissionDialog(true)
    }

    const updatePermissionGroup = async (permissionGroup: Permission) => {
        try {
            let header = {
                headers: {
                    route: "change_group_route",
                },
            }

            await api
                .post(
                    `permission_group`,
                    {
                        group_id: permissionGroup.group_id,
                        route_id: permissionGroup.route_id,
                    },
                    header
                )
                .then((res) => {
                    let _permissions = [...permissions]
                    let index = findIndexByIdPermission(permissionGroup.route_id)
                    _permissions[index].have_permissions = !permissionGroup.have_permissions

                    setPermissions(_permissions)
                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Permissão alterada com sucesso!",
                        life: 4000,
                    })
                })

                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro!!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const editGroups = (group: any) => {
        setGroup({ ...group })
        setGroupsDialogEdit(true)
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2 p-mb-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const dateFormatCreate = (rowData: any) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.created_at}</Moment>
            </>
        )
    }

    const dateFormatUpdate = (rowData: any) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.updated_at}</Moment>
            </>
        )
    }

    const onChangeCreateGroups = (event: any) => {
        const { value, name } = event.target

        setCreateValues({
            ...createValues,
            [name]: value,
        })
    }

    const onInputChange = (e: any, name: string) => {
        const val = (e.target && e.target.value) || ""
        let _group = { ...group }

        switch (name) {
            case "name":
                _group["name"] = val
                break

            default:
                break
        }

        setGroup(_group)
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Gerenciar Grupos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Pesquisar..." />
            </span>
        </div>
    )

    useEffect(() => {
        allGroups()
    }, [])

    useEffect(() => {}, [groups])

    return (
        <App>
            <Toast ref={toast} />
            <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}></Toolbar>

            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <DataTable
                            value={groups}
                            dataKey="id"
                            paginator
                            loading={loadingList}
                            rows={10}
                            stripedRows
                            rowsPerPageOptions={[5, 10, 25]}
                            // className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total: {totalRecords} Grupos"
                            emptyMessage="Não há grupos cadastrados"
                            header={header}
                            filters={filters}
                            globalFilterFields={["name"]}
                            responsiveLayout="scroll"
                        >
                            <Column align="center" alignHeader="center" header="Nome" sortable field="name"></Column>

                            <Column
                                align="center"
                                alignHeader="center"
                                header="Data de Criação"
                                body={dateFormatCreate}
                                sortField="created_at"
                                sortable
                            ></Column>

                            <Column
                                align="center"
                                alignHeader="center"
                                header="Data Alteração"
                                body={dateFormatUpdate}
                                sortField="updated_at"
                                sortable
                            ></Column>

                            <Column align="center" alignHeader="center" body={actionBodyTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

            <Dialog
                visible={groupsDialog}
                style={{ width: "450px" }}
                header="Adicionar Grupo"
                modal
                className="p-fluid"
                footer={groupsCreateDialogFooter}
                draggable={false}
                onHide={hideDialog}
            >
                <form onSubmit={onSubmitCreateGroups}>
                    <div className="p-field">
                        <label htmlFor="name">Nome</label>
                        <InputText
                            id="name"
                            name="name"
                            type="String"
                            maxLength={35}
                            required
                            autoFocus
                            className={
                                "p-field p-text " +
                                classNames({
                                    "p-invalid": submitted && createValues.name === "",
                                })
                            }
                            value={createValues.name}
                            onChange={onChangeCreateGroups}
                        />
                    </div>
                </form>
            </Dialog>

            <Dialog
                visible={groupPermissionDialog}
                style={{ width: "700px" }}
                modal
                draggable={false}
                className="p-fluid"
                onHide={hideDialog}
                header={"Permissões - " + group.name}
            >
                <DataTable
                    value={permissions}
                    dataKey="id"
                    stripedRows
                    paginator
                    loading={loadingRoutines}
                    rows={4}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} de {last} Total {totalRecords} permissões"
                    globalFilter={globalFilterPermissions}
                    emptyMessage="Não há rotas cadastrados."
                    header={headerUpdatePermission}
                    filters={filtersGroups}
                    globalFilterFields={["name", "description"]}
                >
                    <Column align="center" alignHeader="center" field="name" header="Rotina" sortable></Column>

                    <Column align="center" alignHeader="center" field="description" header="Descrição" sortable></Column>

                    <Column
                        align="center"
                        alignHeader="center"
                        header="Ativo / Inativo"
                        sortable
                        sortField="have_permissions"
                        body={activeInactivePermission}
                    ></Column>
                </DataTable>
            </Dialog>

            <Dialog
                visible={groupsDialogEdit}
                style={{ width: "450px" }}
                header="Editar descrição"
                modal
                draggable={false}
                className="p-fluid"
                footer={groupsEditDialogFooter}
                onHide={hideDialogEditGroups}
            >
                <div className="p-field">
                    <label htmlFor="name">Nome:</label>
                    <InputText
                        required
                        id="name"
                        name="name"
                        autoFocus
                        type="String"
                        maxLength={35}
                        onChange={(e) => onInputChange(e, "name")}
                        value={group.name}
                        className={
                            "p-field " +
                            classNames({
                                "p-invalid": submitted,
                            })
                        }
                    />
                </div>
            </Dialog>
        </App>
    )
}
