import React, { useRef, useState, useEffect } from "react"
import App from "../../layout/App"
import api from "../../services/api"
import Moment from "react-moment"
import classNames from "classnames"

//PrimeReact
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { ConfirmDialog } from "primereact/confirmdialog"
import { CrmRecords } from "../../components/crm/CrmRecords"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { FilterMatchMode, FilterOperator } from "primereact/api"
import { InputText } from "primereact/inputtext"
import { Toast } from "primereact/toast"
import { Toolbar } from "primereact/toolbar"
import { useHotkeys } from "react-hotkeys-hook"

//Interface
import { LossReasonInterface } from "../../interfaces/LossReasonInterface"
import { HotKeys } from "../../components/crm/HotKeys"

export const DealsLost = () => {
    const toast: any = useRef(null)
    const token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    let [createValues, setCreateValues] = useState<LossReasonInterface>({} as LossReasonInterface)
    let [lostReasons, setLostreasons] = useState<LossReasonInterface[]>([])
    let [lostReason, setLostReason] = useState<LossReasonInterface>({} as LossReasonInterface)
    let [submitted, setSubmitted] = useState<boolean>(false)

    //Loadings
    let [loadingDelete, setLoadingDelete] = useState<boolean>(false)
    let [loadingEdit, setLoadingEdit] = useState<boolean>(false)
    let [loadingList, setLoadingList] = useState<boolean>(true)
    let [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    //Dialogs
    let [deleteDialog, setDeleteDialog] = useState(false)
    let [hotKeysDialog, setHotKeysDialog] = useState(false)
    let [lostReasonDialog, setReasonDialog] = useState<boolean | undefined>(false)
    let [reasonEditDialog, setReasonEditDialog] = useState(false)

    //Filtros
    let [auxGroupsFiltered, setAuxGroupsFiltered] = useState<LossReasonInterface[]>([])

    //Hotkeys
    useHotkeys("shift+n", () => setReasonDialog(true))
    useHotkeys("shift+i", () => setHotKeysDialog(true))

    const findIndexById = (id: string) => {
        let index = -1
        for (let i = 0; i < lostReasons.length; i++) {
            if (lostReasons[i].id === id) {
                index = i
                break
            }
        }

        return index
    }

    //Buttons
    const ButtonsFromTable = (rowData: LossReasonInterface) => {
        return (
            <div className="actions">
                <Button icon="pi pi-user-edit" className="p-button-rounded p-button" onClick={() => dialogEditDealsLost(rowData)} />
                &nbsp;&nbsp;
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => deleteDealsLost(rowData)} />
            </div>
        )
    }

    const ButtonCreateDealsLost = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-user-plus" className="p-button" onClick={openNew} />
            </React.Fragment>
        )
    }

    //Datatable field Formater
    const dateFormatCreate = (rowData: LossReasonInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.created_at}</Moment>
            </>
        )
    }

    const dateFormatUpdate = (rowData: LossReasonInterface) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.updated_at}</Moment>
            </>
        )
    }

    //Requisitions
    const allLostReason = async () => {
        try {
            let header = {
                headers: {
                    route: "list_loss_reasons",
                },
            }

            await api.get("loss_reasons", header)
                .then((res) => {
                    setLoadingList(false)
                    setLostreasons(res.data)
                    setAuxGroupsFiltered(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const openNew = () => {
        setReasonDialog(true)
    }

    const onSubitDealsLost = (event: any) => {
        setLoadingEdit(true)
        event.preventDefault()
        setSubmitted(false)

        try {
            const { name } = createValues
            if (name === "" || name === undefined || name === null) {
                setSubmitted(true)
                setLoadingEdit(false)
                toast.current?.show({
                    severity: "warn",
                    summary: "Erro ao cadastrar",
                    detail: "Por favor, verfifique os dados inseridos!",
                    life: 3000,
                })
            } else {
                let header = {
                    headers: {
                        route: "create_loss_reason",
                    },
                }

                api.post(
                    "loss_reasons",
                    {
                        name,
                    },
                    header
                )
                    .then((res) => {
                        if (res.status === 200) {
                            hideDialog()
                            setLoadingEdit(false)
                            setLostreasons((prevState) => [...prevState, res.data])
                            toast.current?.show({
                                severity: "success",
                                summary: "Sucesso",
                                detail: "Motivo de Perda Criado",
                                life: 3000,
                            })
                        } else {
                            setLoadingSubmit(false)
                            setLoadingEdit(false)
                            toast.current?.show({
                                severity: "error",
                                summary: "Erro ao cadastar!",
                                detail: res.data.message,
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoadingSubmit(false)
                        setLoadingEdit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao cadastar!",
                            detail: error.response.data.message,
                            life: 3000,
                        })
                    })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmitEditDealLost = () => {
        setLoadingSubmit(true)
        try {
            const { name, id } = lostReason

            let header = {
                headers: {
                    route: "update_loss_reason",
                },
            }

            api.put("loss_reasons", { id, name }, header)

                .then((res) => {
                    if (res.status === 200) {
                        let _lostReasons = [...lostReasons]
                        let _lostReason = { ...lostReason }
                        hideDialog()
                        setLoadingSubmit(false)

                        const index = findIndexById(lostReason.id)
                        _lostReasons[index] = _lostReason

                        setLostreasons(_lostReasons)

                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na alteração",
                            detail: "Motivo alterado com sucesso!",
                            life: 3000,
                        })
                    } else {
                        setLoadingSubmit(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao Editar",
                            detail: "Por favor verifique os dados inseridos",
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingSubmit(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao Editar",
                        detail: "Por favor verifique os dados inseridos",
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const deleteDealsLost = (item: LossReasonInterface) => {
        setLostReason(item)
        setDeleteDialog(true)
    }

    const onDeletDealsLost = () => {
        setLoadingDelete(true)
        try {
            api.delete("loss_reasons", {
                headers: {
                    route: "delete_loss_reason",
                },
                data: {
                    id: lostReason.id,
                },
            })

                .then((res) => {
                    setLoadingDelete(false)
                    if (res.status === 200) {
                        let auxDeleteLossReason = auxGroupsFiltered.filter((item) => lostReason.id !== item.id)
                        lostReasons = auxDeleteLossReason
                        auxGroupsFiltered = auxDeleteLossReason
                        hideDialog()
                        setLostreasons(auxDeleteLossReason)
                        setAuxGroupsFiltered(auxDeleteLossReason)

                        toast.current?.show({
                            severity: "success",
                            summary: "Sucesso na exclusão",
                            detail: "Motivo de Perda excluído com sucesso!",
                            life: 3000,
                        })
                    } else {
                        setLoadingDelete(false)
                        toast.current?.show({
                            severity: "error",
                            summary: "Erro ao excluir!",
                            detail: res.data.message,
                            life: 3000,
                        })
                    }
                })
                .catch((error) => {
                    setLoadingDelete(false)
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao excluir!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    //OnChange
    const onChangeEdit = (e: any, name: string) => {
        const val = (e.target && e.target.value) || ""
        let _lostReason = { ...lostReason }

        switch (name) {
            case "name":
                _lostReason["name"] = val
                break

            default:
                break
        }

        setLostReason(_lostReason)
    }

    const onChangeCreateDealsLost = (event: any) => {
        const { value, name } = event.target

        setCreateValues({
            ...createValues,
            [name]: value,
        })
    }

    //Dialogs
    const hideDialog = () => {
        setCreateValues({} as LossReasonInterface)
        setSubmitted(false)
        setReasonDialog(false)
        setReasonEditDialog(false)
        setDeleteDialog(false)
        setHotKeysDialog(false)
    }

    const dialogEditDealsLost = (reason: LossReasonInterface) => {
        setLostReason(reason)
        setReasonEditDialog(true)
    }

    const deleteMessage = () => {
        return (
            <>
                {" "}
                Deseja realmente excluir o motivo: <strong>{lostReason.name}</strong>
            </>
        )
    }

    //Filters
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    })

    let [globalFilterValue, setGlobalFilterValue] = useState("")

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value
        let _filters = { ...filters }
        _filters["global"].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Motivos de Perda </h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Pesquisar..." />
            </span>
        </div>
    )

    // Footers
    const lostReasonsDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button label="Salvar" autoFocus icon="pi pi-check" className="p-button-success p-button-text" loading={loadingEdit} onClick={onSubitDealsLost} />
        </>
    )

    const lostReasonEditFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
            <Button
                label="Salvar"
                autoFocus
                icon="pi pi-check"
                className="p-button-success p-button-text"
                loading={loadingSubmit}
                onClick={onSubmitEditDealLost}
            />
        </>
    )

    const deleteDialogFooter = () => {
        return (
            <>
                <Button label="Não" icon="pi pi-times" className="p-button-danger p-button-text" onClick={hideDialog} />
                <Button label="Sim" icon="pi pi-check" className="p-button-success p-button-text" loading={loadingDelete} onClick={onDeletDealsLost} />
            </>
        )
    }

    useEffect(() => {
        allLostReason()
    }, [])

    return (
        <App>
            <CrmRecords />
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="p-mb-4 p-toolbar" left={ButtonCreateDealsLost}></Toolbar>

                        {/* DataTable */}
                        <DataTable
                            value={lostReasons}
                            loading={loadingList}
                            stripedRows
                            selectionMode="checkbox"
                            dataKey="id"
                            rows={10}
                            rowsPerPageOptions={[10, 15, 20]}
                            // className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} Motivos de Perda"
                            paginator
                            header={header}
                            filters={filters}
                            globalFilterFields={["name"]}
                            emptyMessage="Não há Motivos de Perda cadastrados"
                            responsiveLayout="scroll"
                        >
                            <Column field="name" header="Motivo" sortable align="center" alignHeader="center" />
                            <Column
                                body={dateFormatCreate}
                                header="Data de Criação"
                                sortable
                                sortField="created_at"
                                align="center"
                                alignHeader="center"
                            />
                            <Column
                                body={dateFormatUpdate}
                                sortField="update_at"
                                header="Data de Atualização"
                                sortable
                                align="center"
                                alignHeader="center"
                            />
                            <Column
                                headerStyle={{
                                    width: "12rem",
                                    textAlign: "center",
                                }}
                                bodyStyle={{ textAlign: "center" }}
                                body={ButtonsFromTable}
                            ></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

            {/* Create Method */}
            <Dialog
                visible={lostReasonDialog}
                style={{ width: "450px" }}
                header="Adicionar Motivo de Perda"
                modal
                draggable={false}
                className="p-fluid"
                footer={lostReasonsDialogFooter}
                onHide={hideDialog}
            >
                <div className="p-field">
                    <label htmlFor="name">Nome</label>
                    <InputText
                        id="name"
                        name="name"
                        required
                        type="String"
                        maxLength={45}
                        className={
                            "p-field p-text-capitalize " +
                            classNames({
                                "p-invalid": submitted && createValues.name === "",
                            })
                        }
                        style={{ minWidth: "20rem" }}
                        value={createValues.name || ""}
                        onChange={onChangeCreateDealsLost}
                    />
                </div>
            </Dialog>

            {/* Delete Method */}

            <ConfirmDialog
                style={{ width: "400px", borderRadius: "16px" }}
                draggable={false}
                visible={deleteDialog}
                onHide={hideDialog}
                message={deleteMessage}
                acceptLabel={"Sim"}
                rejectLabel={"Não"}
                header="Confirmação de exclusão"
                icon="pi pi-exclamation-triangle"
                accept={onDeletDealsLost}
                acceptIcon="pi pi-check"
                rejectIcon="pi pi-times"
                reject={hideDialog}
                dismissableMask={true}
                footer={deleteDialogFooter}
            />

            {/* Edit Method */}
            <Dialog
                visible={reasonEditDialog}
                style={{ width: "450px" }}
                header={`Editar Razão de Perda`}
                modal
                className="p-fluid"
                footer={lostReasonEditFooter}
                draggable={false}
                onHide={hideDialog}
            >
                <div className="p-field">
                    <label htmlFor="name">Nome</label>
                    <InputText
                        style={{ minWidth: "20rem" }}
                        id="name"
                        name="name"
                        maxLength={120}
                        onChange={(e: any) => onChangeEdit(e, "name")}
                        value={lostReason.name || ""}
                    />
                </div>
            </Dialog>

            {/* HotKeys Dialog */}
            <Dialog
                visible={hotKeysDialog}
                style={{ width: "800px" }}
                modal
                className="p-fluid"
                onHide={hideDialog}
                header={`Atalhos - Tela de Motivos de Perda`}
                draggable={false}
            >
                <HotKeys create={"Criar um motivo de perda"} />
            </Dialog>
        </App>
    )
}
