import { InputText } from "primereact/inputtext"
import { OpportunityInterface } from "../../../../interfaces/OpportunityInterface"
import { Dropdown } from "primereact/dropdown"

interface PropsInterface {
    onChangeEditOpportunity: any
    opportunity: OpportunityInterface
    stages: string[]
    stage: string
}

export const MarketingLeads = ({ onChangeEditOpportunity, opportunity, stages, stage }: PropsInterface) => {
    return (
        <div className="p-grid">
            <div className="p-col-12 p-md-6 p-lg-6">
                <div className="p-grid">
                    <div className="p-col-12 p-md-6 p-lg-6">
                        <br />
                        <div className="p-field">
                            <label htmlFor="nameOpportunity">Origem:</label>
                            <InputText
                                style={{ width: "100%" }}
                                id="nameOpportunity"
                                disabled
                                name="name"
                                maxLength={120}
                                value={opportunity.utm_source ? opportunity.utm_source : "SEM INFORMAÇÃO"}
                                onChange={(e: any) => onChangeEditOpportunity(e)}
                            />
                        </div>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6">
                        <div className="p-field">
                            <br />
                            <label htmlFor="responsible">Campanha:</label>

                            <InputText
                                style={{ width: "100%" }}
                                id="nameOpportunity"
                                disabled
                                name="name"
                                maxLength={120}
                                value={opportunity.utm_campaign ? opportunity.utm_campaign : "SEM INFORMAÇÃO"}
                                onChange={(e: any) => onChangeEditOpportunity(e)}
                            />
                        </div>
                    </div>
                </div>

                <div className="p-grid">
                    <div className="p-col-12 p-md-6 p-lg-6">
                        <div className="p-field">
                            <label htmlFor="salesFunnelOpportunity">Mídia:</label>
                            <InputText
                                style={{ width: "100%" }}
                                id="nameOpportunity"
                                disabled
                                name="name"
                                maxLength={120}
                                value={opportunity.utm_medium ? opportunity.utm_medium : "SEM INFORMAÇÃO"}
                                onChange={(e: any) => onChangeEditOpportunity(e)}
                            />
                        </div>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6">
                        <div className="p-field">
                            <label htmlFor="stateFunnel">Termo:</label>
                            <InputText
                                style={{ width: "100%" }}
                                id="nameOpportunity"
                                disabled
                                name="name"
                                maxLength={120}
                                value={opportunity.utm_term ? opportunity.utm_term : "SEM INFORMAÇÃO"}
                                onChange={(e: any) => onChangeEditOpportunity(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-col-12 p-md-6 p-lg-6">
                <div className="p-grid">
                    <div className="p-col-12 p-md-6 p-lg-6">
                        <br />
                        <div className="p-field">
                            <label htmlFor="sourceOpportunity">Contéudo:</label>
                            <InputText
                                style={{ width: "100%" }}
                                id="nameOpportunity"
                                disabled
                                name="name"
                                maxLength={120}
                                value={opportunity.utm_content ? opportunity.utm_content : "SEM INFORMAÇÃO"}
                                onChange={(e: any) => onChangeEditOpportunity(e)}
                            />
                        </div>
                    </div>
                </div>

                <div className="p-grid">
                    <div className="p-col-12 p-md-6 p-lg-6">
                        <div className="p-field">
                            <label htmlFor="salesFunnelOpportunity">Nível de Consciência:</label>
                            <br />
                            <Dropdown id="funnel" disabled={true} style={{ width: "100%" }} value={stage} options={stages} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
