import React, { useRef, useState, useEffect, useCallback } from "react"
import api from "../../services/api"

//PrimeReact
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Toast } from "primereact/toast"
import { Toolbar } from "primereact/toolbar"

//Interface
import { QuoteInterface } from "../../interfaces/QuotesInterfaces"

export const FillingQuotes: React.FC = () => {
    const toast: any = useRef(null)
    const token: string | null = localStorage.getItem("@esquadros:token")
    const [quotes, setQuotes] = useState<QuoteInterface[]>([])
    const [quote, setQuote] = useState<QuoteInterface>({} as QuoteInterface)
    const [editQuoteDialog, setEditQuoteDialog] = useState<boolean>(false)

    //Loading
    const [loadingList, setLoadingList] = useState<boolean>(true)
    //Filter
    const [globalFilter, setGlobalFilter] = useState<null>(null)

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Preencimento da Cotação</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e: any) => setGlobalFilter(e.target.value)} placeholder="Pesquisar..." />
            </span>
        </div>
    )

    const editQuotesDialog = (data: any) => {
        setQuote(data)
        setEditQuoteDialog(true)
    }

    const editQuotes = (rowData: QuoteInterface) => {
        return (
            <>
                <Button label="Editar" className="p-button-rounded" onClick={() => editQuotesDialog(rowData)} />
            </>
        )
    }

    const leftToolbarTemplate = () => {
        let totalIpi = 0
        let totalIcms = 0
        let valueTotal = 0

        quotes.forEach((q: QuoteInterface) => {
            totalIpi += (q.quantity * q.unitary_price * q.ipi) / 100
            totalIcms += (q.quantity * q.unitary_price * q.icms) / 100
            valueTotal += q.quantity * q.unitary_price + (q.quantity * q.unitary_price * q.ipi) / 100 + (q.quantity * q.unitary_price * q.icms) / 100
        })

        return (
            <React.Fragment>
                <div className="p-d-flex p-flex-column p-flex-md-row">
                    <div className="p-mr-6 p-as-start">
                        <span>
                            <strong>ICMS:</strong> &nbsp;
                            {new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            }).format(totalIcms)}
                        </span>
                    </div>

                    <div className="p-mr-6 p-as-start">
                        <span>
                            <strong>IPI:</strong> &nbsp;
                            {new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            }).format(totalIpi)}
                        </span>
                    </div>

                    <div className="p-mr-6 p-as-start">
                        <span>
                            <strong>Valor Total: </strong> &nbsp;
                            {new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            }).format(valueTotal)}
                        </span>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Enviar Cotação" className="p-button-rounded p-button-success" onClick={actionSaveQuote} />
            </React.Fragment>
        )
    }

    const formatPrice = (rowData: QuoteInterface) => {
        return (
            <span>
                {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                }).format(rowData.unitary_price)}
            </span>
        )
    }

    const hideDialog = () => {
        setEditQuoteDialog(false)
    }

    const onChangeEditQuotes = (event: any) => {
        const { value, name } = event.target

        setQuote({
            ...quote,
            [name]: value,
        })
    }

    const totalQuoteLine = (rowData: QuoteInterface) => {
        let total = rowData.quantity * rowData.unitary_price
        let totalEnd = total

        if (rowData.ipi !== 0 && rowData.icms !== 0) {
            totalEnd += (total * rowData.ipi) / 100
            totalEnd += (total * rowData.icms) / 100
        } else {
            if (rowData.icms !== 0) {
                totalEnd += (total * rowData.icms) / 100
            }

            if (rowData.ipi !== 0) {
                totalEnd += (total * rowData.ipi) / 100
            }
        }

        return (
            <span>
                {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                }).format(totalEnd)}
            </span>
        )
    }

    const saveQuotesLine = () => {
        if (quote.deadline < 0 || quote.unitary_price < 0 || quote.ipi < 0 || quote.icms < 0) {
            toast.current?.show({
                severity: "error",
                summary: "Erro na Inclusão!",
                detail: "Não é permitido números negativos.",
                life: 3000,
            })
        } else if (quote.ipi > 30) {
            toast.current?.show({
                severity: "error",
                summary: "Erro na Inclusão!",
                detail: "O valor maximo para o IPI é 30%.",
                life: 3000,
            })
        } else if (quote.icms > 18) {
            toast.current?.show({
                severity: "error",
                summary: "Erro na Inclusão!",
                detail: "O valor maximo para o ICMS é 18%.",
                life: 3000,
            })
        } else {
            let _quotes = [...quotes]
            const index = findIndexByIdQuotes(quote.product_id)
            _quotes[index] = quote
            setQuotes(_quotes)

            setEditQuoteDialog(false)
        }
    }

    const findIndexByIdQuotes = (id: string) => {
        let index = -1
        for (let i = 0; i < quotes.length; i++) {
            if (quotes[i].product_id === id) {
                index = i
                break
            }
        }

        return index
    }

    const buttonsFooterDialog = <Button type="submit" label="Salvar" className="p-button-success p-button-text" onClick={saveQuotesLine} />

    const allQuotes = useCallback(() => {
        try {
            let header = {
                headers: {
                    route: "list_quote_provider_products",
                },
            }

            api.get(`quotes/products/provider/${token}`, header)
                .then((response) => {
                    setLoadingList(false)
                    setQuotes(response.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })

                    window.location.href = "https://esquadros.com.br"
                })
        } catch (error) {
            console.log(error)
        }
    }, [token])

    const actionSaveQuote = useCallback(() => {
        let _quotes: any = []

        quotes.forEach((q) => {
            _quotes.push({
                product_id: q.product_id,
                unitPrice: q.unitary_price,
                quantity: q.quantity,
                brand: q.brand,
                ipi: q.ipi,
                icms: q.icms,
                deadline: q.deadline,
            })
        })

        try {
            let header = {
                headers: {
                    route: "update_quote_price",
                },
            }
            api.post("quotes/update", { token: token, items: _quotes }, header)
                .then((response) => {
                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso no envio da cotação!",
                        detail: "A Esquadros agradece seus serviços!!",
                        life: 3000,
                    })

                    window.location.href = "https://esquadros.com.br"
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao enviar cotação!",
                        detail: "Verifique todos os dados, caso esteja tudo certo entre em contato com a Esquadros!!",
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }, [token, quotes])

    useEffect(() => {
        allQuotes()
    }, [allQuotes])

    window.onbeforeunload = function () {
        return ""
    }

    return (
        <div className="layout-content">
            <div className="p-grid crud-demo">
                <Toast ref={toast} />
                <div className="p-col-12">
                    <div className="card">
                        <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                        <DataTable
                            value={quotes}
                            dataKey="id"
                            paginator
                            loading={loadingList}
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} Cotação"
                            emptyMessage="Não há cotações cadastradas"
                            header={header}
                            globalFilter={globalFilter}
                            showGridlines
                        >
                            <Column align="center" alignHeader="center" header="Cod.Produto" field="product_id"></Column>

                            <Column align="center" alignHeader="center" header="Descrição" field="product_description"></Column>

                            <Column align="center" alignHeader="center" header="Obs" field="product_especification"></Column>

                            <Column align="center" alignHeader="center" header="Marca" field="brand"></Column>

                            <Column align="center" alignHeader="center" header="Entrega (em dias)" field="deadline"></Column>

                            <Column align="center" alignHeader="center" header="Qtd." field="quantity"></Column>

                            <Column align="center" alignHeader="center" header="Un.Med." field="um"></Column>

                            <Column align="center" alignHeader="center" header="Prc.Unit" body={formatPrice}></Column>

                            <Column align="center" alignHeader="center" header="IPI %" field="ipi"></Column>

                            <Column align="center" alignHeader="center" header="ICMS %" field="icms"></Column>

                            <Column align="center" alignHeader="center" header="V.Total" body={totalQuoteLine}></Column>

                            <Column align="center" alignHeader="center" header="" body={editQuotes}></Column>
                        </DataTable>
                    </div>
                </div>

                <div className="p-col-12">
                    <Dialog
                        visible={editQuoteDialog}
                        style={{ maxWidth: "100rem", minWidth: "50vw" }}
                        header={`${quote.product_description}`}
                        modal
                        className="p-fluid"
                        onHide={hideDialog}
                        footer={buttonsFooterDialog}
                        draggable={false}
                    >
                        <div className="p-field">
                            <label htmlFor="name">Marca</label>
                            <InputText name="brand" autoFocus type="string" maxLength={45} value={quote.brand || ""} onChange={onChangeEditQuotes} />
                        </div>

                        <div className="p-field">
                            <label htmlFor="name">Entrega (em dias)</label>
                            <InputText name="deadline" type="number" maxLength={50} value={quote.deadline || ""} onChange={onChangeEditQuotes} />
                        </div>

                        <div className="p-field">
                            <label htmlFor="name">Preço Unitario</label>
                            <InputText name="unitary_price" type="number" value={quote.unitary_price || ""} onChange={onChangeEditQuotes} />
                        </div>

                        <div className="p-field">
                            <label htmlFor="name">IPI %</label>
                            <InputText name="ipi" type="number" value={quote.ipi || ""} onChange={onChangeEditQuotes} />
                        </div>

                        <div className="p-field">
                            <label htmlFor="name">ICMS %</label>
                            <InputText name="icms" type="number" value={quote.icms || ""} onChange={onChangeEditQuotes} />
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}
