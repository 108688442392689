import React, { useEffect } from "react"
import api from "../../../services/api"

export const SurpriseOpportunity = () => {
    const token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    const handleSurpriseOpportunity = async () => {
        let header = {
            headers: {
                route: "show_surprise_opportunity",
            },
        }

        await api
            .get(`opportunities/surprise`, header)
            .then((res) => {
                if (res.status === 200) {
                    window.location.href = `/crm/leads/${res.data}`
                } else {
                }
            })
            .catch((error) => {})
    }

    useEffect(() => {
        handleSurpriseOpportunity()
    }, [])

    return <></>
}
