import { useEffect, useRef, useState } from "react"
import App from "../../layout/App"
import api from "../../services/api"

//PrimeReact
import { Toast } from "primereact/toast"
import { CrmRecords } from "../../components/crm/CrmRecords"

//CSS
import "react-dropzone-uploader/dist/styles.css"
import "../../components/PDF/all-pages"
import "../../components/PDF/index.scss"

//Componentes
import ClearOpportunitiesDataTable from "../../components/pages/ClearOpportunities/DataTable"
import ClearOpportunitiesFilters from "../../components/pages/ClearOpportunities/Filters"

//Interfaces
import { FunnelInterface } from "../../interfaces/FunnelInterface"
import { OpportunityInterface } from "../../interfaces/OpportunityInterface"
import { UserInterface } from "../../interfaces/UserInterface"

export const ClearOpportunity = () => {
    const toast: any = useRef(null)
    let [opportunities, setOpportunities] = useState<OpportunityInterface[]>([])
    let [opportunity, setOpportunity] = useState<OpportunityInterface>({} as OpportunityInterface)
    let [auxOpportunities, setAuxOpportunities] = useState<OpportunityInterface[]>([])

    let [funnels, setFunnels] = useState<FunnelInterface[]>([])
    let [funnel, setFunnel] = useState<FunnelInterface>({} as FunnelInterface)

    let [listOfSellers, setSellerList] = useState<UserInterface[]>([])

    let [loadingUpdateAsOpportunities, setLoadingUpdateAsOpportunities] = useState(false)
    let [loadingList, setLoadingList] = useState<boolean>(true)

    let dataLocalStorage: any

    const token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    const isEmptyObj = (obj: Record<string, unknown>): boolean => {
        for (const prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                return false
            }
        }
        return true
    }

    const allFunnels = async () => {
        try {
            let header = {
                headers: {
                    route: "list_funnels",
                },
            }

            await api
                .get("funnels", header)
                .then((res: any) => {
                    funnels = res.data
                    setFunnels(funnels)

                    if (localStorage.getItem("@esquadros:filterOpportunity") === null || isEmptyObj(dataLocalStorage.funnel)) {
                        res.data.forEach((item: any, index: number) => {
                            if (item.is_default) {
                                funnel = item
                                setFunnel(item)
                            }
                        })
                    }
                })

                .catch((error: any) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const allUsers = async () => {
        try {
            const header = {
                headers: {
                    route: "show_crm_configuration_sellers",
                },
            }

            const response = await api.get("crm_configurations/sellers", header)
            const listOfSellers = response.data
            setSellerList(listOfSellers)
        } catch (error: any) {
            toast.current?.show({
                severity: "error",
                summary: "Erro ao listar usuários!",
                detail: error.response?.data?.message || "Erro desconhecido",
                life: 3000,
            })
        }
    }

    const allClearOpportunity = async () => {
        try {
            const header = {
                headers: {
                    route: "list_opportunities",
                },
            }

            const response = await api.get("opportunities/clean", header)
            setLoadingList(false)
            setLoadingUpdateAsOpportunities(false)
            var opportunitiesNotDeleted = response.data.filter((op: any) => op.status !== "deleted")
            opportunities = opportunitiesNotDeleted
            setOpportunities(opportunities)
            setAuxOpportunities(opportunitiesNotDeleted)
        } catch (error: any) {
            console.error(error)
        }
    }

    useEffect(() => {
        allClearOpportunity()
        allFunnels()
        allUsers()
    }, [])

    return (
        <App>
            <CrmRecords />

            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <ClearOpportunitiesFilters
                            allClearOpportunity={allClearOpportunity}
                            auxOpportunities={auxOpportunities}
                            funnel={funnel}
                            setLoadingUpdateAsOpportunities={setLoadingUpdateAsOpportunities}
                            loadingUpdateAsOpportunities={loadingUpdateAsOpportunities}
                            setAuxOpportunities={setAuxOpportunities}
                            listOfSellers={listOfSellers}
                            opportunities={opportunities}
                            toast={toast}
                        />
                        <ClearOpportunitiesDataTable
                            auxOpportunities={auxOpportunities}
                            loadingList={loadingList}
                            opportunities={opportunities}
                            setOpportunity={setOpportunity}
                            opportunity={opportunity}
                            setOpportunities={setOpportunities}
                        />
                    </div>
                </div>
            </div>
        </App>
    )
}
