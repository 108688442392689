import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { InputText } from "primereact/inputtext"
import { useRef, useState } from "react"
import Moment from "react-moment"
import { FilterMatchMode, FilterOperator } from "primereact/api"
import { OpportunityInterface } from "../../../../../interfaces/OpportunityInterface"
import { MachineApresentationInterface } from "../../../../../interfaces/MachineApresentationInterface"
import { Toolbar } from "primereact/toolbar"

interface PropsInterface {
    setPresentation: React.Dispatch<React.SetStateAction<MachineApresentationInterface>>
    setUpdateFileDialog: React.Dispatch<React.SetStateAction<boolean>>
    opportunity: OpportunityInterface
    loadingOpen: boolean
    setPresentationEditDialog: React.Dispatch<React.SetStateAction<boolean>>
    setPresentationDialog: React.Dispatch<React.SetStateAction<boolean>>
}

export const AttachmentsLeadsDataTable = ({
    setPresentationDialog,
    setPresentation,
    setUpdateFileDialog,
    opportunity,
    loadingOpen,
    setPresentationEditDialog,
}: PropsInterface) => {
    let dt: any = useRef(null)

    let [globalFilterValue, setGlobalFilterValue] = useState("")
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        email: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        phone: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    })

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value
        let _filters = { ...filters }
        _filters["global"].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    const linkPDF = (rowData: any) => {
        const linkId = `${rowData.file_url}`
        return (
            <>
                <div>
                    <a href={linkId} rel="noreferrer" target="_blank" style={{ fontWeight: "600", fontSize: "1.1rem", color: "rgba(1, 1, 122, 1)" }}>
                        {rowData.file}
                    </a>
                </div>
            </>
        )
    }

    const headerAttachments = (
        <div className="table-header">
            <div className="p-d-flex p-jc-between">
                <div>
                    {" "}
                    <h5 className="p-m-0">Anexos</h5>
                </div>
                <div>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Pesquisar..." />
                    </span>
                </div>
            </div>
        </div>
    )

    const attachmentsUploadButtons = (rowData: any) => {
        const showUploadButton = rowData.file === null

        return (
            <div className="actions">
                <Button icon="pi pi-user-edit" className="p-button-rounded p-button" onClick={() => dialogEditPresentation(rowData)} />
                &nbsp;&nbsp;
                <Button
                    icon="pi pi-upload"
                    className={`p-button-rounded ${showUploadButton ? "p-button-success" : "p-button-warning"}`}
                    onClick={() => uploadPresentaiton(rowData)}
                />
            </div>
        )
    }

    const dialogEditPresentation = (presentated: MachineApresentationInterface) => {
        setPresentation(presentated)
        setPresentationEditDialog(true)
    }

    const uploadPresentaiton = (presentated: MachineApresentationInterface) => {
        setPresentation(presentated)
        setUpdateFileDialog(true)
    }

    return (
        <>
            <Toolbar
                className="p-mb-4 p-toolbar"
                left={() => {
                    return (
                        <Button
                            label="Novo Anexo"
                            icon="pi pi-plus"
                            className="p-button"
                            disabled={opportunity.status === "gain" ? true : false}
                            onClick={() => setPresentationDialog(true)}
                        />
                    )
                }}
            ></Toolbar>

            <DataTable
                ref={dt}
                value={opportunity.attachments}
                loading={loadingOpen}
                stripedRows
                selectionMode="checkbox"
                dataKey="id"
                rows={10}
                filters={filters}
                globalFilterFields={["name", "file"]}
                rowsPerPageOptions={[10, 15, 20]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} apresentações"
                paginator
                header={headerAttachments}
                emptyMessage="Não há Apresentações"
            >
                <Column field="name" header="Nome" sortable align="center" alignHeader="center" />
                <Column
                    body={(rowData) => {
                        return <Moment format="DD-MM-YYYY">{rowData.created_at}</Moment>
                    }}
                    header="Data de Criação"
                    sortable
                    sortField="created_at"
                    align="center"
                    alignHeader="center"
                />
                <Column
                    body={(rowData) => {
                        return <Moment format="DD-MM-YYYY">{rowData.updated_at}</Moment>
                    }}
                    sortField="update_at"
                    header="Data de Atualização"
                    sortable
                    align="center"
                    alignHeader="center"
                />
                <Column body={linkPDF} header="Arquivo" sortable align="center" alignHeader="center" sortField="file" />
                <Column align="center" alignHeader="center" body={attachmentsUploadButtons}></Column>
            </DataTable>
        </>
    )
}
