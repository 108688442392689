import styled from "styled-components"

export const container = styled.section`
    height: 30px;
`

export const buttonStartAudio = styled.button`
    position: relative;
    border: 0;
    cursor: pointer;
    width: 45px;
    height: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
`
export const micIcon = styled.img`
    position: "absolute";
    transform: "translate(-20%, -0%)";
    width: 15px;
    margin-right: 0.6rem;
    margin-top: 0.3rem;

    @media (max-width: 768px) {
        width: 15px;
    }
`

export const containerCurrentRecording = styled.div`
    display: flex;
    align-items: center;
    align-content: end;
`

export const containerButtonsCurrentRecording = styled.div`
    height: max-content;
    display: flex;
    align-items: center;
    align-content: center;
`

export const titleCurrentRecording = styled.span`
    font-size: 1.2rem;
    color: green;

    @media (max-width: 768px) {
        font-size: 1rem;
    }
`
export const soundGif = styled.img`
    width: 200px;
    height: 40px;
    margin: 0 2rem;

    @media (max-width: 768px) {
        width: 80px;
        height: 30px;
        margin: 0 1rem;
    }
`

export const audioView = styled.audio`
    width: 700px;
    height: 40px;
    margin: 0 2rem;

    @media (max-width: 768px) {
        width: 300px;
        height: 30px;
        margin: 0 1rem;
    }
`
