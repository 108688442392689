import React from "react"
import { Button } from "primereact/button"
import { useHistory } from "react-router-dom"

export const NotFound = () => {
    const history = useHistory()

    const goOpportunity = () => {
        history.push("/")
    }

    return (
        <div className="exception-body notfound">
            <div className="exception-wrapper">
                <div className="exception-content">
                    <span>404</span>
                    <span className="exception-subtitle">Parece que você está perdido.</span>
                    <Button type="button" label="Voltar para Home" onClick={goOpportunity}></Button>
                </div>
                <div className="exception-footer p-mt-4">
                    <h4>Esquadros</h4>
                    <h6>Copyright Ⓒ Inovação tecnológica</h6>
                </div>
            </div>
        </div>
    )
}
