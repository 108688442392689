import "../../../../styles/Attendance.scss"

export const HotKeys = () => {
    return (
        <>
            <br />
            <div className="p-d-flex p-jc-between">
                <div style={{ borderRight: "solid 0.6px", paddingRight: "8rem" }}>
                    <p>
                        <strong className="keyboard-key">SHIFT</strong> <strong className="keyboard-key">C</strong> - Criar Contato
                    </p>
                    <br />
                    <p>
                        <strong className="keyboard-key">SHIFT</strong> <strong className="keyboard-key">T</strong> - Criar Tarefa
                    </p>
                    <br />
                    <p>
                        <strong className="keyboard-key">SHIFT</strong> <strong className="keyboard-key">E</strong> - Criar Empresa
                    </p>
                    <br />
                    <p>
                        <strong className="keyboard-key">SHIFT</strong> <strong className="keyboard-key">O</strong> - Criar Oportunidade
                    </p>
                    <br />
                </div>
                <br />

                <div>
                    <p>
                        <strong className="keyboard-key">CTRL</strong> <strong className="keyboard-key">ALT</strong> <strong className="keyboard-key">A</strong>{" "}
                        - Informações de Atalho
                    </p>
                    <br />

                    <p>
                        <strong className="keyboard-key">CTRL</strong> <strong className="keyboard-key">ALT</strong>{" "}
                        <strong className="keyboard-key">SHIFT</strong> <strong className="keyboard-key">N</strong> - Nova Conversa
                    </p>
                    <br />
                    <p>
                        <strong className="keyboard-key">CTRL</strong> <strong className="keyboard-key">ALT</strong>
                        <strong className="keyboard-key">SHIFT</strong> <strong className="keyboard-key">F</strong> - Pesquisar Conversa
                    </p>

                    <br />

                    <p>
                        <strong className="keyboard-key">CTRL</strong> <strong className="keyboard-key">ALT</strong>{" "}
                        <strong className="keyboard-key">SHIFT</strong> <strong className="keyboard-key">U</strong> - Upload de Arquivos Locais
                    </p>
                </div>
            </div>
        </>
    )
}
