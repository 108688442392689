import React from "react"
import api from "../../../services/api"

//PrimeReact
import { InputNumber } from "primereact/inputnumber"
import { ListBox } from "primereact/listbox"

//Interfaces
import { OpportunityProposalOptionalInterface } from "../../../interfaces/OpportunityProposalOptionalInterface"
interface Props {
    machine?: OpportunityMachine
    listOptional: OpportunityProposalOptionalInterface[]
    onChangeOpportunityMachine: (item: OpportunityProposalOptionalInterface[]) => void
    valueListOptional: any[]
    style?: object
    listStyle?: object
}

interface OpportunityMachine {
    id: string
    code: string
    description: string
    full_description: string | null
    type: string
    ncm: string | null
    deadline: string | null
    opportunity_id: string
    quantity: number
    price: number
    price_table: string
    optionals: OpportunityProposalOptionalInterface[]
    discount: number
    discount_type: any
    created_at?: string
    updated_at?: string
    machine_code?: string | null
    machine_description?: string | null
}

export const Optional = (props: Props) => {
    const token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    const templateOptionalsSelectedMachine = (optional: OpportunityMachine) => {
        return (
            <>
                <div className="product-item">
                    <div className="p-grid p-ai-center">
                        <div className="p-col-12 p-md-4 p-lg-4">
                            <span>
                                <strong>{optional.description}</strong>
                            </span>
                        </div>

                        <div className="p-col-12 p-md-2 p-lg-2">
                            <InputNumber inputStyle={{ width: "100%" }} value={optional.quantity} name="quantity" disabled />
                        </div>

                        <div className="p-col-12 p-md-3 p-lg-3">
                            <InputNumber
                                inputStyle={{ width: "100%" }}
                                value={optional.price}
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                minFractionDigits={2}
                                name="price"
                                disabled
                            />
                        </div>

                        <div className="p-col-12 p-md-3 p-lg-3">
                            <InputNumber
                                inputStyle={{ width: "100%" }}
                                value={optional.quantity * optional.price}
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                minFractionDigits={2}
                                name="price"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <ListBox
                style={props.style}
                listStyle={props.listStyle}
                value={props.valueListOptional}
                options={props.listOptional}
                itemTemplate={templateOptionalsSelectedMachine}
                multiple={true}
                filter={true}
                optionLabel="description"
                onChange={(e) => props.onChangeOpportunityMachine(e.value)}
            />
        </>
    )
}
