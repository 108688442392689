import React, { useState, useRef, useEffect } from "react"
import App from "../../layout/App"
import api from "../../services/api"
import { Checkbox } from "primereact/checkbox"
import { Toast } from "primereact/toast"
import { Toolbar } from "primereact/toolbar"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"

import Moment from "react-moment"

interface ProductData {
    id: string
    description: string
    type: string
    group_id: string
    group_description: string
    ncm: string
    um: string
    um2: string
    last_purchase_price: number
    last_purchase_date: string
    last_purchase_invoice: string
    last_purchase_provider: string
}

interface LastPurchaseData {
    product: string
    amount: number
    um: string
    amount2: string
    um2: string
    value: number
    total_value: number
    purchase_date: string
    provider: string
    invoice: string
    requester: string
}

export const Products: React.FC = () => {
    const [products, setProducts] = useState<ProductData[]>([])
    const [product, setProduct] = useState<ProductData>({} as ProductData)
    const [auxProducts, setAuxProducts] = useState<ProductData[]>([])

    const [listLastPurchaseDates, setListLastPurchaseDates] = useState<LastPurchaseData[]>([])
    const [acronymFilter] = useState<any>([])

    const [loadingList, setLoading] = useState<boolean>(true)
    const [loadingListLastPurchaseDates, setLoadingListLastPurchaseDates] = useState<boolean>(true)

    const [fixedAsset, setFixedAsset] = useState<boolean>(false)
    const [beneficiation, setBeneficiation] = useState<boolean>(false)
    const [feedstock, setFeedstock] = useState<boolean>(false)
    const [finalProduct, setFinalProduct] = useState<boolean>(false)
    const [otherProducts, setOtherProducts] = useState<boolean>(false)
    const [intermediateProduct, setIntermediateProduct] = useState<boolean>(false)

    const [lastPurchaseDateModal, setLastPurchaseDateModal] = useState<boolean>(false)

    let [globalFilter, setGlobalFilter] = useState<string>("")

    const toast: any = useRef(null)
    const token: string | null = localStorage.getItem("@esquadros:token")
    api.defaults.headers.authorization = `Bearer ${token}`

    const allProducts = () => {
        try {
            let header = {
                headers: {
                    route: "list_products",
                },
            }

            api.get("products", header)
                .then((res) => {
                    setLoading(false)
                    setProducts(res.data)
                    setAuxProducts(res.data)
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro ao listar!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    // Filtro dos checkbox
    const checkSearchFilter = (data: string, filter: string) => {
        let type = data
        let status = true
        let _products: ProductData[] = []
        globalFilter = filter.toUpperCase()
        setGlobalFilter(globalFilter)

        if (data !== "") {
            let exists = acronymFilter.find((p: string) => {
                return p === type
            })

            !exists ? acronymFilter.push(type) : acronymFilter.splice(acronymFilter.indexOf(type), 1)
        }

        if (acronymFilter.length > 0 || globalFilter.length > 0) {
            auxProducts.forEach((p: ProductData) => {
                if (acronymFilter.length > 0) {
                    if (p.type === "BN" && !acronymFilter.includes("BN")) {
                        status = false
                    } else if (p.type === "MP" && !acronymFilter.includes("MP")) {
                        status = false
                    } else if (p.type === "AI" && !acronymFilter.includes("AI")) {
                        status = false
                    } else if (p.type === "PI" && !acronymFilter.includes("PI")) {
                        status = false
                    } else if (p.type === "PA" && !acronymFilter.includes("PA")) {
                        status = false
                    } else if (!["AI", "BN", "PA", "PI", "MP"].includes(p.type) && !acronymFilter.includes("OU")) {
                        status = false
                    }
                }

                if (status) {
                    if (globalFilter.length > 0 && globalFilter !== "") {
                        let words = globalFilter.split(" ")

                        words.forEach((word) => {
                            if (
                                !p.id.includes(word) &&
                                !p.description.includes(word) &&
                                !p.group_id.includes(word) &&
                                !p.ncm.includes(word) &&
                                !p.group_description.includes(word) &&
                                !p.last_purchase_provider.includes(word) &&
                                !p.last_purchase_invoice.includes(word)
                            ) {
                                status = false
                            }
                        })
                    }
                }

                if (status) {
                    _products.push(p)
                }

                status = true
            })

            setProducts(_products)
        } else {
            setProducts(auxProducts)
        }
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="p-grid p-align-center">
                    <div className="p-col-12 p-md-6 p-lg-4">
                        <Checkbox
                            inputId="c1"
                            value="AI"
                            onChange={(e: any) => {
                                checkSearchFilter(e.target.value, globalFilter)

                                setFixedAsset(!fixedAsset)
                            }}
                            checked={fixedAsset}
                        ></Checkbox>

                        <label htmlFor="c1" className="p-checkbox-label">
                            &nbsp;&nbsp;AI - Ativo Fixo
                        </label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-4">
                        <Checkbox
                            inputId="c2"
                            value="BN"
                            onChange={(e: any) => {
                                checkSearchFilter(e.target.value, globalFilter)
                                setBeneficiation(!beneficiation)
                            }}
                            checked={beneficiation}
                        ></Checkbox>

                        <label htmlFor="c2" className="p-checkbox-label">
                            &nbsp;&nbsp;BN - Beneficiamento
                        </label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-4">
                        <Checkbox
                            inputId="c3"
                            value="MP"
                            onChange={(e: any) => {
                                checkSearchFilter(e.target.value, globalFilter)
                                setFeedstock(!feedstock)
                            }}
                            checked={feedstock}
                        ></Checkbox>

                        <label htmlFor="c3" className="p-checkbox-label">
                            &nbsp;&nbsp;MP - Matéria-Prima
                        </label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-4">
                        <Checkbox
                            inputId="c4"
                            value="PA"
                            onChange={(e: any) => {
                                checkSearchFilter(e.target.value, globalFilter)
                                setFinalProduct(!finalProduct)
                            }}
                            checked={finalProduct}
                        ></Checkbox>

                        <label htmlFor="c4" className="p-checkbox-label">
                            &nbsp;&nbsp;PA - Produto Acabado
                        </label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-4">
                        <Checkbox
                            inputId="c5"
                            value="PI"
                            onChange={(e: any) => {
                                checkSearchFilter(e.target.value, globalFilter)
                                setIntermediateProduct(!intermediateProduct)
                            }}
                            checked={intermediateProduct}
                        ></Checkbox>

                        <label htmlFor="c5" className="p-checkbox-label">
                            &nbsp;&nbsp;PI - Produto Intermediário
                        </label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-4">
                        <Checkbox
                            inputId="c6"
                            value="OU"
                            onChange={(e: any) => {
                                checkSearchFilter(e.target.value, globalFilter)
                                setOtherProducts(!otherProducts)
                            }}
                            checked={otherProducts}
                        ></Checkbox>

                        <label htmlFor="c6" className="p-checkbox-label">
                            &nbsp;&nbsp;Outros
                        </label>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const lastPurchaseDate = (rowData: any) => {
        setProduct(rowData)
        setLastPurchaseDateModal(true)

        let header = {
            headers: {
                route: "list_entry_invoice_items",
            },
        }

        try {
            api.get(`entry_invoice_items/${rowData.id.replace("/", "&")}`, header)
                .then((res) => {
                    setLoadingListLastPurchaseDates(false)
                    setListLastPurchaseDates(res.data)
                    toast.current?.show({
                        severity: "success",
                        summary: "Sucesso!",
                        detail: "Tabela listada com sucesso!",
                        life: 1000,
                    })
                })
                .catch((error) => {
                    toast.current?.show({
                        severity: "error",
                        summary: "Erro!",
                        detail: error.response.data.message,
                        life: 3000,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Listagem de Produtos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onChange={(e: any) => {
                        checkSearchFilter("", e.target.value)
                    }}
                    placeholder="Pesquisar..."
                />
            </span>
        </div>
    )

    const formatDateLastPurchases = (rowData: ProductData) => {
        return rowData.last_purchase_date !== null ? (
            <>
                <Moment format="DD-MM-YYYY">{rowData.last_purchase_date}</Moment>
            </>
        ) : (
            <p>-</p>
        )
    }

    const UnityMeasurementr2 = (rowData: LastPurchaseData) => {
        return rowData.um2 === null ? <>-</> : rowData.um2
    }

    const group = (rowData: any) => {
        return rowData.group === null ? <>-</> : rowData.group_id + " - " + rowData.group_description
    }

    const Providers = (rowData: ProductData) => {
        return rowData.last_purchase_provider === "" ? <>-</> : rowData.last_purchase_provider
    }

    const Requester = (rowData: LastPurchaseData) => {
        return rowData.requester === null ? <> - </> : rowData.requester
    }

    const Totals = (rowData: LastPurchaseData) => {
        if (rowData.amount2 !== null) {
            return (
                <div>
                    <p>{rowData.amount + " " + rowData.um}</p>
                    <p>{rowData.amount2 + " " + rowData.um2}</p>
                </div>
            )
        } else {
            return <p>{rowData.amount + " " + rowData.um}</p>
        }
    }

    const MoneyConvert = (rowData: any) => {
        return rowData.last_purchase_price === " " ? (
            <>-</>
        ) : (
            (rowData.last_purchase_price = rowData.last_purchase_price.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            }))
        )
    }

    const MoneyConvertNF = (rowData: any) => {
        return rowData.total_value === " " ? (
            <>-</>
        ) : (
            (rowData.total_value = rowData.total_value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            }))
        )
    }

    const searchInvoices = (rowData: LastPurchaseData) => {
        return (
            <>
                <Button className="p-button" icon="p-button-icon p-c pi pi-search" iconPos="left" onClick={() => lastPurchaseDate(rowData)} />
            </>
        )
    }

    const redirectInvoice = (rowData: LastPurchaseData) => {
        return (
            <>
                <Button
                    className="p-button"
                    icon="p-button-icon p-c pi pi-search"
                    label={rowData.invoice}
                    iconPos="left"
                    onClick={() => window.open(`https://drive.google.com/drive/u/0/search?q=${rowData.invoice}`, "_blank")}
                />
            </>
        )
    }

    const dateFormatListLastPurchaseDates = (rowData: LastPurchaseData) => {
        return (
            <>
                <Moment format="DD-MM-YYYY">{rowData.purchase_date}</Moment>
            </>
        )
    }

    const hideLastPurchaseDateModal = () => {
        setLastPurchaseDateModal(false)
    }

    useEffect(() => {
        allProducts()
    }, [])

    return (
        <App>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />

                        <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}></Toolbar>
                    </div>
                </div>

                <div className="p-col-12">
                    <div className="card">
                        <DataTable
                            value={products}
                            dataKey="id"
                            paginator
                            stripedRows
                            loading={loadingList}
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} produtos"
                            emptyMessage="Não há Produtos"
                            header={header}
                        >
                            <Column align="center" alignHeader="center" header="Código" field="id"></Column>

                            <Column align="center" alignHeader="center" header="Descrição" field="description"></Column>

                            <Column align="center" alignHeader="center" header="Tipo" field="type"></Column>

                            <Column align="center" alignHeader="center" header="Grupo" body={group}></Column>

                            <Column align="center" alignHeader="center" header="NCM" field="ncm"></Column>

                            <Column align="center" alignHeader="center" header="U.M" field="um"></Column>

                            <Column align="center" alignHeader="center" header="2°U.M" body={UnityMeasurementr2}></Column>

                            <Column align="center" alignHeader="center" header="Ult.Preço" body={MoneyConvert}></Column>

                            <Column align="center" alignHeader="center" header="Ult.Compra" body={formatDateLastPurchases}></Column>

                            <Column align="center" alignHeader="center" header="Fornecedores" body={Providers}></Column>

                            <Column align="center" alignHeader="center" header="N.F." body={searchInvoices}></Column>
                        </DataTable>
                    </div>
                </div>

                <div className="p-col-12">
                    <Dialog
                        visible={lastPurchaseDateModal}
                        style={{ maxWidth: "90rem" }}
                        header={`${product.id} - ${product.description}`}
                        modal
                        className="p-fluid"
                        onHide={hideLastPurchaseDateModal}
                        draggable={false}
                    >
                        <DataTable
                            value={listLastPurchaseDates}
                            dataKey="id"
                            paginator
                            rows={5}
                            loading={loadingListLastPurchaseDates}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} - Total {totalRecords} produtos"
                            className="datatable-responsive"
                            emptyMessage="Não há ùltimas compras."
                            header="Tabela de Produtos"
                            showGridlines
                        >
                            <Column align="center" alignHeader="center" header="Data" body={dateFormatListLastPurchaseDates}></Column>
                            <Column align="center" alignHeader="center" body={Totals} header="Totais"></Column>
                            <Column align="center" alignHeader="center" field="provider" header="Fornecedor"></Column>
                            <Column align="center" alignHeader="center" header="Solicitante" body={Requester}></Column>
                            <Column align="center" alignHeader="center" body={MoneyConvertNF} header="Valor Total"></Column>
                            <Column align="center" alignHeader="center" header="Nota Fiscal" body={redirectInvoice}></Column>
                        </DataTable>

                        <br />
                    </Dialog>
                </div>
            </div>
        </App>
    )
}
