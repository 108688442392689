import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { Toast } from "primereact/toast"
import { Toolbar } from "primereact/toolbar"
import { useRef, useState } from "react"

//Interfaces
import { MachineApresentationInterface } from "../../../../../interfaces/MachineApresentationInterface"
import { OpportunityInterface } from "../../../../../interfaces/OpportunityInterface"

//Services and components
import { CreateAttachmentsDialog } from "./CreateAttachmentsDialog"
import { AttachmentsLeadsDataTable } from "./DataTable"
import { UpdateAttachmentName } from "./UpdateAttachmentName"
import { UpdateAttachmentsDialog } from "./UpdateAttachmentsDialog"

interface PropsInterface {
    opportunity: OpportunityInterface
    setOpportunity: React.Dispatch<React.SetStateAction<OpportunityInterface>>
    loadingOpen: boolean
    setLoadingOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const AttachmentsLeads = ({ opportunity, loadingOpen, setOpportunity, setLoadingOpen }: PropsInterface) => {
    let toast = useRef<Toast>(null)

    let [updateFileDialog, setUpdateFileDialog] = useState(false)

    let [presentationDialog, setPresentationDialog] = useState<boolean>(false)
    let [presentationEditDialog, setPresentationEditDialog] = useState(false)

    let [presentation, setPresentation] = useState<MachineApresentationInterface>({} as MachineApresentationInterface)

    return (
        <>
            <Toast ref={toast} />

            <AttachmentsLeadsDataTable
                setPresentationDialog={setPresentationDialog}
                loadingOpen={loadingOpen}
                opportunity={opportunity}
                setPresentation={setPresentation}
                setUpdateFileDialog={setUpdateFileDialog}
                setPresentationEditDialog={setPresentationEditDialog}
            />

            <Dialog
                visible={presentationDialog}
                style={{ width: "750px" }}
                header="Adicionar anexo de Equipamento"
                draggable={false}
                modal
                className="p-fluid"
                onHide={() => {
                    setPresentationDialog(false)
                }}
            >
                <CreateAttachmentsDialog
                    setLoadingOpen={setLoadingOpen}
                    opportunity={opportunity}
                    setOpportunity={setOpportunity}
                    setPresentationDialog={setPresentationDialog}
                    toast={toast}
                />
            </Dialog>

            <Dialog
                visible={presentationEditDialog}
                style={{ width: "950px" }}
                header={`Editar Anexo:`}
                modal
                draggable={false}
                className="p-fluid"
                onHide={() => {
                    setPresentationEditDialog(false)
                }}
            >
                <UpdateAttachmentName
                    opportunity={opportunity}
                    setPresentationEditDialog={setPresentationEditDialog}
                    setPresentation={setPresentation}
                    toast={toast}
                    presentation={presentation}
                    setOpportunity={setOpportunity}
                />
            </Dialog>

            <Dialog
                visible={updateFileDialog}
                style={{ width: "750px" }}
                header={"Atualizar arquivo de: " + presentation.name}
                modal
                draggable={false}
                className="p-fluid"
                onHide={() => {
                    setUpdateFileDialog(false)
                }}
            >
                <UpdateAttachmentsDialog
                    toast={toast}
                    opportunity={opportunity}
                    presentation={presentation}
                    setUpdateFileDialog={setUpdateFileDialog}
                    setOpportunity={setOpportunity}
                />
            </Dialog>
        </>
    )
}
