import React from "react"
import { useAuth } from "../hooks/AuthContext"
import { Route as ReactDOMRoute, RouteProps as ReactDOMRouterProps, Redirect } from "react-router-dom"

interface RouteProps extends ReactDOMRouterProps {
    isPrivate?: boolean
    component: React.ComponentType
}

const AuthRoutes: React.FC<RouteProps> = ({ isPrivate = false, component: Component, ...rest }) => {
    const { user } = useAuth()

    return (
        <ReactDOMRoute
            {...rest}
            render={({ location }) => {
                return isPrivate === !!user ? (
                    <Component />
                ) : isPrivate ? (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location },
                        }}
                    />
                ) : (
                    <Component />
                )
            }}
        />
    )
}

export default AuthRoutes
